import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { HiveRetireAction, HiveRetireActionType, SuccessResponse } from './types';

const makeRetireHiveActionStart = (): HiveRetireAction => ({
  type: HiveRetireActionType.HIVE_RETIRE_FETCH_START,
});

const makeHiveRetireSuccessAction = (): HiveRetireAction => ({
  type: HiveRetireActionType.HIVE_RETIRE_FETCH_SUCCESS,
});

const makeHiveRetireFailureAction = (): HiveRetireAction => ({
  type: HiveRetireActionType.HIVE_RETIRE_FETCH_FAILURE,
});

export const makeRetireHiveThunk = Thunk.createTakeFirst(
  ({ status, ids }: { status: 'deadout' | 'removed'; ids: number[] }) => {
    return async (dispatch) => {
      dispatch(makeRetireHiveActionStart());

      const response = await Api.post(URLUtil.buildURL(ENDPOINTS.hhtHivesRetire), {
        status,
        hive_identity_ids: ids,
      });

      if (response.error) {
        dispatch(makeHiveRetireFailureAction());
        response.error && dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
        throw new Error(`${response.status}`);
      } else {
        const data: SuccessResponse = await response.json();

        const totalHives = ids.length;
        let snackBarMessage = '';
        if (status === 'deadout') {
          if (totalHives === 1) snackBarMessage = 'flag_hive_deadout_success';
          else if (totalHives === 0) snackBarMessage = 'flag_hives_deadout_success_all';
          else snackBarMessage = 'flag_hives_deadout_success';
        } else if (status === 'removed') {
          if (totalHives === 1) snackBarMessage = 'remove_hive_success';
          else if (totalHives === 0) snackBarMessage = 'remove_hives_success_all';
          else snackBarMessage = 'remove_hives_success';
        }

        dispatch(makeHiveRetireSuccessAction());
        dispatch(
          makeShowSnackbarAction({
            messageTranslation: snackBarMessage,
            messageTranslationParams: { count: `${totalHives}` },
            type: 'success',
          })
        );
        return data;
      }
    };
  }
);
