import React from 'react';

import { Box } from '@components/common/Box';
import { InfoCircle } from '@components/common/Icon/presets/InfoCircle';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';

import { MetricCardLoading } from './MetricCardLoading';
import { StyledMetricCardContainer } from './style';
import { MetricCardProps } from './types';

export const MetricCard: React.FC<MetricCardProps> = ({ title, tooltip, isFetching, onClick, children }) => {
  const cardTop = (
    <Box alignItems={'center'} justifyContent={'space-between'}>
      <Text typography={'Heading3'} weight={'600'} color={'grey08'}>
        {title}
      </Text>

      {!!tooltip && (
        <span>
          <InfoCircle color={'grey05'} />
          <Tooltip>
            <Text typography={'CaptionSmall'} color={'white'} dangerouslySetInnerHTML={{ __html: tooltip }} />
          </Tooltip>
        </span>
      )}
    </Box>
  );

  if (isFetching) {
    return (
      <StyledMetricCardContainer disabled>
        {cardTop}
        <MetricCardLoading fit visible={isFetching} marginTop_050 />
      </StyledMetricCardContainer>
    );
  }

  return (
    <StyledMetricCardContainer $clickable={!!onClick} onClick={onClick}>
      {cardTop}
      <Box marginTop_050 fit>
        {children}
      </Box>
    </StyledMetricCardContainer>
  );
};
