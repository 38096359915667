import { YardStatusKey, YardStatusValueConfig } from './types';

export const YardStatusConfig = {
  active: {
    key: YardStatusKey.ACTIVE,
    labelKey: 'active',
    color: 'contentPositive',
    alternativeColor: 'contentPositive',
    priority: 1,
    order: 0,
  },
  inactive: {
    key: YardStatusKey.INACTIVE,
    labelKey: 'inactive',
    color: 'contentTertiary',
    alternativeColor: 'contentSecondary',
    priority: 0,
    order: 1,
  },
} as Record<YardStatusKey, YardStatusValueConfig>;
