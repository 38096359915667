export interface CacheEntry {
  data: any;
  timestamp: number;
}

const DEFAULT_CACHE_MAX_AGE = 10000;
const REQUEST_CACHE = {} as Record<string, CacheEntry>;

export const CachedRequest = {
  performRequest,
};

async function performRequest<T extends (url: string) => any>(
  url: string,
  handler: T,
  maxAge: number = DEFAULT_CACHE_MAX_AGE
): Promise<ReturnType<T>> {
  const requestKey = url;
  const cachedResult = REQUEST_CACHE[requestKey];
  let result;

  if (cachedResult && +new Date() - cachedResult.timestamp <= maxAge) {
    result = cachedResult.data;
  } else {
    result = await handler(url);

    if (!result.error) {
      REQUEST_CACHE[requestKey] = {
        data: result,
        timestamp: +new Date(),
      };
    }
  }

  return result;
}

/**
 * const response = CachedRequest.performRequest(url, url => await API.get(url));
 * */
