import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ColDef, GridApi, IDatasource, IGetRowsParams, SortModelItem } from 'ag-grid-community';
import * as uuid from 'uuid';

import { useGridApi } from '@components/common/AgGrid';
import { DEF_FALLBACK_COLUMN_TYPE } from '@components/common/DynamicTable/constants';
import { useDynamicTableUtil } from '@components/common/DynamicTable/hooks/useDynamicTableUtil';
import {
  ColDefWithMetadata,
  Column,
  ColumnKey,
  DynamicTableConfig,
  DynamicTableInternalQueryParams,
} from '@components/common/DynamicTable/types';
import { ENDPOINTS } from '@config/api';
import { ApiClient } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

type DynamicTableProviderArgs = {
  endpoint: keyof typeof ENDPOINTS;
  columnDefGenerator?: (gridApi: GridApi, column: Column) => Array<ColDef>;
  apiParams?: any;
  config?: DynamicTableConfig;
};

interface DynamicTableAPIResponse {
  columns: any[];
  pagination: {
    count: number;
    limit: number;
    offset: number;
  };
  rows: any[];
}

export function useDynamicTableDataProvider({
  apiParams,
  endpoint,
  config,
  columnDefGenerator,
}: DynamicTableProviderArgs) {
  const { gridApi } = useGridApi();
  const [api] = useState(() => new ApiClient());
  const tableUtil = useDynamicTableUtil(config);
  const dispatch = useDispatch();
  const fetchFromApi = useCallback(
    async (queryParams: DynamicTableInternalQueryParams) => {
      const url = URLUtil.buildURL(ENDPOINTS[endpoint], { ...queryParams });
      const response = await api.get(url);

      let error: ApiResponseError | null = null;
      let data: any | null = null;

      if (response.error) {
        error = response.error;
      } else {
        data = CaseAdapter.objectToCamelCase<DynamicTableAPIResponse>(await response.json(), ['key']);
      }
      return { data, error };
    },
    [api, endpoint]
  );

  const getOrderBy = useCallback(
    (sortingItems: Array<SortModelItem>) => {
      const orderBy: Array<string> = [];
      for (const sortingItem of sortingItems) {
        const columns = tableUtil.getFlattenColumnDefs(gridApi?.getColumnDefs());
        const sortingColumnDef = columns.find((column) => sortingItem.colId === column.colId);

        if (sortingColumnDef) {
          const sortingColumn = tableUtil.getColumnDefWithMetadata(sortingColumnDef).metadata.column;
          const view = sortingColumn ? tableUtil.getActiveView(sortingColumnDef) : null;

          if (view) {
            const sortingPrefix = sortingItem.sort === 'asc' ? '' : '-';
            const sortingKey = view.sortableKey;
            orderBy.push(`${sortingPrefix}${sortingKey}`);
          }
        }
      }
      return {
        order_by: orderBy,
      };
    },
    [gridApi, tableUtil]
  );

  const dataSource = useMemo<IDatasource>(
    () => ({
      async getRows({ startRow, endRow, sortModel, successCallback }: IGetRowsParams) {
        api.cancelPendingRequests();

        // Clears all table rows and fires the skeleton loading animation.
        gridApi?.getRenderedNodes().forEach((node) => node.setData(undefined));
        const orderBy = getOrderBy(sortModel);
        const params = {
          ...apiParams,
          limit: endRow - startRow,
          offset: startRow,
          // todo: add the ability to specify which columns to show when
          // the endpoint is updated on the backend to handle that
          // columns: firstBatchColumns,
          ...orderBy,
          // ...appliedFilters,
        };

        const { data, error } = await fetchFromApi(params);
        if (!data) {
          if (error && !error?.isAborted) {
            dispatch(makeShowSnackbarAction(error.snackbarOptions));
          }
          throw error;
        }
        updateAvailableColumns(data.columns);
        successCallback(data.rows, data.pagination.count);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridApi]
  );

  const getColumns = useCallback(() => {
    return tableUtil.getFlattenColumnDefs(gridApi?.getColumnDefs());
  }, [gridApi, tableUtil]);

  const getVisibleColumns = useCallback(() => {
    return getColumns().filter((def) => !def.hide);
  }, [getColumns]);

  const getVisibleColumnIds = useCallback(() => {
    return tableUtil.getFlattenColumnIds(getVisibleColumns());
  }, [getVisibleColumns, tableUtil]);

  const defaultColumnDefGenerator = useCallback(
    (gridApi, column) => {
      let columnDefs = tableUtil
        .getFlattenColumnDefs(gridApi?.getColumnDefs())
        .filter((columnDef) => columnDef.field === column.key);

      if (columnDefs.length === 0) {
        columnDefs.push({ field: column.key, metadata: { column } } as ColDefWithMetadata);
      } else {
        columnDefs = tableUtil.getPatchedColumnDefsUsingColumnKey(columnDefs, {
          field: column.key,
          metadata: {
            column,
          },
        } as ColDefWithMetadata);
      }

      return columnDefs.map((columnDef) => {
        const activeView = tableUtil.getActiveView(columnDef);
        const sortable = Boolean(activeView?.sortableKey);

        const columnDefinition: ColDefWithMetadata = {
          colId: uuid.v4(),
          field: column.key,
          hide: false,
          sortable,
          headerName: column.key,
          metadata: {
            column,
            type: 'default',
            activeView: 'default',
          },
        };
        return columnDefinition;
      });
    },
    [tableUtil]
  );

  const updateAvailableColumns = useCallback(
    (columnsFromApi: Record<ColumnKey, Column>) => {
      if (gridApi) {
        const visibleColumnIds = getVisibleColumnIds();
        const availableColumnsKeys = Object.keys(columnsFromApi);
        const orderedColumns = Object.values(columnsFromApi);
        let patchedColumns: Array<ColDef> = getColumns();

        for (const column of orderedColumns) {
          if (!column.type) {
            column.type = DEF_FALLBACK_COLUMN_TYPE;
          }
          const generator = columnDefGenerator ?? defaultColumnDefGenerator;
          const columnDefinitions = generator(gridApi, column);
          for (const columnDef of columnDefinitions) {
            patchedColumns = tableUtil.getPatchedColumnDefsUsingColumnKey(patchedColumns, columnDef);
          }
        }
        // Remove stale columns and update hide property.
        patchedColumns = patchedColumns.filter((columnDef) => availableColumnsKeys.includes(columnDef.field as string));
        patchedColumns = patchedColumns.map((columnDef) => ({
          ...columnDef,
          hide: !visibleColumnIds.includes(columnDef.colId as string),
        }));
        tableUtil.updateGridColumns(gridApi, patchedColumns);
      }
    },
    [columnDefGenerator, defaultColumnDefGenerator, getColumns, getVisibleColumnIds, gridApi, tableUtil]
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, dataSource]);
}
