import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ModalHeader } from '@components/common/ModalBase';
import { Text } from '@components/common/Text';
import { ContentAppearingTransition } from '@components/common/Transition/ContentAppearingTransition';
import { InputBase } from '@components/form/core/InputBase';
import { from } from '@style/mediaQueries';

export const StyledDesktopDropdown = styled(Box).attrs({
  column: true,
  stretch: true,
  shadow: 'boxShadow04',
  backgroundColor: 'surfaceSecondary',
})(
  () => css`
    overflow: hidden auto;
    width: 0; // Will be set dynamically.
    max-height: 286px;
  `
);

export const StyledOptionContainer = styled(ContentAppearingTransition)(
  ({ theme }) => css`
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const StyledOption = styled(Button)<{ $visible?: boolean }>(
  ({ theme }) => css`
    overflow: hidden;
    display: grid;

    &:not(:first-child) {
      border-top: ${theme.colors.borderSecondary} 1px solid;
    }

    ${from('tablet')} {
      &:not(:first-child) {
        border-top: none;
      }
    }

    &:hover {
      background-color: ${theme.colors.surfaceSecondaryHover};
    }

    ${StyledOptionVisibilityStyles}
  `
);

export const StyledOptionChipContainerWrapper = styled(Box)<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    background-color: ${theme.colors.surfaceSecondary};
    z-index: ${theme.getZIndexOf('content')};

    ${from('tablet')} {
      position: sticky;
      top: 0;
    }

    ${from('desktopSM')} {
      ${$isVisible &&
      css`
        border-bottom: ${theme.colors.borderSecondary} 1px solid;
      `}
    }
  `
);

export const StyledOptionChipContainer = styled(Box)(
  () => css`
    overflow: auto hidden;

    & > div {
      flex-wrap: nowrap;
    }
  `
);

export const StyledOptionChip = styled(Button).attrs({ suppressPadding: true })<{ $visible?: boolean }>(
  ({ theme, $visible }) => css`
    transition: ${theme.animations.transitionFast('opacity', 'max-width', 'max-height', 'margin', 'padding')};
    overflow: hidden;
    border-radius: 192px;
    border: ${theme.colors.borderSecondary} 1px solid;

    p {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: ${theme.animations.transitionFast('max-width')};
    }

    svg {
      transition: ${theme.animations.transitionFast('scale')};
    }

    ${$visible
      ? css`
          opacity: 1;
          max-width: 192px;
          max-height: 36px;
          padding: ${theme.spacing._025} ${theme.spacing._050};
          margin: ${theme.spacing._075} ${theme.spacing._025} ${theme.spacing._075} ${theme.spacing._025};

          p {
            max-width: calc(192px - ${theme.spacing._300});
          }
          svg {
            transform: scale(1);
          }
        `
      : css`
          opacity: 0;
          max-width: 0;
          max-height: 0;
          border: none;
          margin: 0;
          padding: 0;

          p {
            max-width: 0;
          }
          svg {
            transform: scale(0);
          }
        `}
  `
);

export const StyledOptionContent = styled(Box)(
  ({ theme }) => css`
    overflow: hidden;
    padding: ${theme.spacing._100} ${theme.spacing._075};
    ${from('tablet')} {
      padding: ${theme.spacing._050} ${theme.spacing._075};
    }

    // To avoid inconsistent behaviors, only
    // the parent button component is going
    // to receive clicks.
    input {
      pointer-events: none;
    }
  `
);

export const StyledOptionText = styled(Text)(
  () => css`
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);

export const StyledEmptyFilter = styled(Box)<{ $visible?: boolean }>(
  () => css`
    ${StyledOptionVisibilityStyles}
  `
);

const StyledOptionVisibilityStyles = css<{ $visible?: boolean }>(
  ({ theme, $visible }) => css`
    overflow: hidden;
    transition: ${theme.animations.transitionFast('max-height', 'opacity')};

    ${$visible
      ? css`
          opacity: 1;
          max-height: ${theme.spacing._350};
        `
      : css`
          opacity: 0;
          max-height: 0;
          border: none !important;
          padding-top: 0;
          padding-bottom: 0;

          ${from('tablet')} {
            padding-top: 0;
            padding-bottom: 0;
          }
        `}
  `
);

export const StyledMobileFilterInputWrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius._025};
    border: ${theme.colors.borderSecondary} 1px solid;

    &:focus-within {
      border: ${theme.colors.borderFocus} 1px solid;
    }
  `
);

export const StyledMobileFilterInput = styled(InputBase)(
  ({ theme }) => css`
    flex: 1;
    height: ${theme.spacing._150};
    border: none;
    outline: none;
  `
);

export const StyledInputWrapper = styled(Box).attrs({
  column: true,
  stretch: true,
})(
  () => css`
    & * {
      pointer-events: none;
      ${from('desktopSM')} {
        pointer-events: all;
      }
    }
  `
);

export const StyledDropdownFooter = styled(Box).attrs({
  paddingVertical_050: true,
  paddingHorizontal_100: true,
  justifyContent: 'flex-end',
  backgroundColor: 'surfaceSecondary',
})(
  ({ theme }) => css`
    position: sticky;
    bottom: 0;
    border-top: ${theme.colors.borderSecondary} 1px solid;
  `
);

export const StyledMobileModalHeader = styled(ModalHeader)(
  () => css`
    display: grid;
    padding-bottom: 0;
  `
);

export const StyledMobileModalHeaderContent = styled(Box)(
  ({ theme }) => css`
    overflow: hidden;
    margin: 0 -${theme.spacing._100};

    ${from('tablet')} {
      margin: 0 -${theme.spacing._150};
    }
  `
);

export const StyledMobileModalContent = styled(Box)(() => css``);
