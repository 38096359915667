import React from 'react';

import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';
import { StyledActionPillView } from '@components/operation/ActionPill/styles';

export const ActionPillSlider: React.FC<BoxProps<'div'>> = ({ children, ...props }) => {
  return (
    <StyledActionPillView alignItems={'center'} {...props}>
      <Text typography={'SmallParagraph'}>{children}</Text>
    </StyledActionPillView>
  );
};
