import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Inspection } from '@components/common/Icon/presets/Inspection';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { LastInspectionsConfig } from '@config/LastInspectionConfig';
import { useLastInspectionSpecGetter } from '@config/LastInspectionConfig/hooks';
import { LastInspectionIntervalConfig, LastInspectionIntervalKey } from '@config/LastInspectionConfig/types';
import { YardAttributeConfig } from '@config/types';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { YardsFiltersCategoryKey } from '@redux/YardsFilters/types';

import { YardsMapCardContent } from './YardsMapCardContent';

export const LastInspectionsStats: React.VFC = () => {
  const { yards } = useYardsMap();
  const { t } = useTranslation();

  const getLastInspectionsColorSpec = useLastInspectionSpecGetter();

  const yardsColorSpecs = useMemo(() => yards.map(getLastInspectionsColorSpec), [getLastInspectionsColorSpec, yards]);

  const shouldShowLastInspectionIntervalStats = useCallback(
    ({ key }: YardAttributeConfig, appliedLastInspectionFilters: string[]) =>
      appliedLastInspectionFilters.length === 0 || appliedLastInspectionFilters.includes(key),
    []
  );
  const getLastInspectionIntervalStats = useCallback(
    ({ key, color, labelKey }: YardAttributeConfig, emptyMessage: string) => {
      const count = yardsColorSpecs.length ? yardsColorSpecs.filter((spec) => spec.key === key).length : null;
      const lastInspectionConfigSpec = LastInspectionsConfig.INTERVALS_SPECS[key as LastInspectionIntervalKey];
      const {
        interval: [from, to],
      } = lastInspectionConfigSpec;
      return {
        key,
        color,
        labelLeft: t(labelKey, { from, to }),
        labelRight: count === null ? emptyMessage : maybePluralize(count, 'yard', t),
        progress: count === null ? null : count / yardsColorSpecs.length,
      };
    },
    [t, yardsColorSpecs]
  );
  const getToggledFilters = useCallback(
    (currentLastInspectionIntervalFilters: string[], selectedFilter: string, isAlreadyApplied: boolean) =>
      isAlreadyApplied
        ? currentLastInspectionIntervalFilters.filter((filter: string) => filter !== selectedFilter)
        : [...currentLastInspectionIntervalFilters, selectedFilter],
    []
  );

  return (
    <YardsMapCardContent
      title={t('last_visits')}
      icon={<Inspection size={16} />}
      filterCategoryKey={YardsFiltersCategoryKey.LAST_INSPECTION_INTERVAL}
      yardAttributeConfigs={Object.values(LastInspectionsConfig.INTERVALS_SPECS) as LastInspectionIntervalConfig[]}
      getYardAttributeStats={getLastInspectionIntervalStats}
      shouldShowYardAttributeStats={shouldShowLastInspectionIntervalStats}
      getToggledFilters={getToggledFilters}
    />
  );
};
