import styled from 'styled-components';

const L = styled.span`
  background-color: ${({ theme }) => theme.primitives.colors.grey03};
`;

export const HLine = styled(L)`
  width: 100%;
  height: 1px;
  min-height: 1px;
`;

export const VLine = styled(L)`
  width: 1px;
  min-width: 1px;
  height: 100%;
`;
