import { Reducer } from 'redux';

export type HiveRetireReducer = Reducer<HiveRetireState, HiveRetireAction>;

export type HiveRetireAction = {
  type:
    | HiveRetireActionType.HIVE_RETIRE_FETCH_START
    | HiveRetireActionType.HIVE_RETIRE_FETCH_SUCCESS
    | HiveRetireActionType.HIVE_RETIRE_FETCH_FAILURE;
};

export interface HiveRetireState {
  isFetchingHiveRetire: boolean;
}

export enum HiveRetireActionType {
  HIVE_RETIRE_FETCH_START = 'HIVE_RETIRE_FETCH_START',
  HIVE_RETIRE_FETCH_SUCCESS = 'HIVE_RETIRE_FETCH_SUCCESS',
  HIVE_RETIRE_FETCH_FAILURE = 'HIVE_RETIRE_FETCH_FAILURE',
}

export interface SuccessResponse {
  status: string;
  request_id: number;
}
