import React from 'react';

import { Box } from '@components/common/Box';
import { useHealthScoreStats } from '@components/common/HealthScore/hooks';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { useTranslation } from '@hooks/useTranslation';

export const HealthScoreTooltip: React.FC<{
  data: BeeHealthScore | null;
}> = ({ data }) => {
  const t = useTranslation();

  const { noData, noHives, noScore, usingPartialHives } = useHealthScoreStats(data);

  if (noData) {
    return null;
  }

  if (noHives) {
    return (
      <Tooltip>
        <Text typography={'TooltipSmall'} dangerouslySetInnerHTML={{ __html: t('health_score_no_hives_tooltip') }} />
      </Tooltip>
    );
  }

  if (noScore) {
    return (
      <Tooltip>
        <Text typography={'TooltipSmall'} dangerouslySetInnerHTML={{ __html: t('health_score_no_score_tooltip') }} />
      </Tooltip>
    );
  }

  if (usingPartialHives) {
    return (
      <Tooltip>
        <Box stretch column padding_025>
          <Text
            typography={'CaptionSmall'}
            dangerouslySetInnerHTML={{
              __html: t('health_score_value_tooltip', {
                ignored: data?.usedNbHives,
                total: data?.totalNbHives ?? 0,
              }),
            }}
          />
        </Box>
      </Tooltip>
    );
  }

  return null;
};
