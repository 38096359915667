/**
 * Helper dedicated to handle global properties.
 *
 * Why not context?
 * - These properties are used by other helpers and services that work outside
 *   the React life-cycle. E.g., the API caller and the URL builder.
 */
import { GlobalsUtil } from '@helpers/Globals/util';

const defaultGlobals = {
  /**
   * Currently selected operation ID, used for
   * API cals and in the current screen path.
   * */
  operationId: null as null | number,

  /**
   * Registers a listener for global properties.
   * */
  addListener,
};

restoreGlobals();
function restoreGlobals() {
  defaultGlobals.operationId = GlobalsUtil.getOperationId();
}

function persistGlobals(data: GlobalsObject) {
  GlobalsUtil.setOperationIdToLocalStorage(data.operationId);
}

function callListeners(property: any, value: any) {
  listeners.filter((listener) => listener.property === property).forEach(({ onChange }) => onChange(value));
}

export type GlobalsObject = typeof defaultGlobals;
export type GlobalsProperty = keyof Omit<GlobalsObject, 'addListener'>;

interface GlobalsListener<P extends GlobalsProperty, V = GlobalsObject[P]> {
  property: P;
  onChange: (value: V) => void;
}

const globalsProxyHandler = {
  set(object: any, property: string, value: any): boolean {
    if (property === 'addListener') {
      throw new Error(`The prop 'addListener' from Globals is read only.`);
    }
    object[property] = value;
    callListeners(property, value);
    persistGlobals(object);
    return true;
  },
};

const listeners: Array<GlobalsListener<any>> = [];

function addListener<P extends GlobalsProperty>(listener: GlobalsListener<P>) {
  listeners.push(listener);
  return () => {
    listeners.splice(listeners.indexOf(listener), 1);
  };
}

export const Globals = new Proxy<typeof defaultGlobals>(defaultGlobals, globalsProxyHandler);
