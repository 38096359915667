import produce from 'immer';

import { AuthActionType, AuthReducer, AuthState } from '@redux/Auth/types';

const defaultState: AuthState = {
  user: null,
  isFetchingUser: true /** Starting it true will avoid an initial animation glitch. */,
  isFetchingSignIn: false,
  isFetchingSignOut: false,
};

export const authReducer: AuthReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case AuthActionType.AUTH_FETCH_USER_START:
        state.isFetchingUser = true;
        break;

      case AuthActionType.AUTH_FETCH_USER_FINISH:
        state.isFetchingUser = false;
        state.user = action.payload.user;
        break;

      case AuthActionType.AUTH_FETCH_SIGN_IN_START:
        state.isFetchingSignIn = true;
        break;

      case AuthActionType.AUTH_FETCH_SIGN_IN_FINISH:
        state.isFetchingSignIn = false;
        state.user = action.payload.user;
        break;

      case AuthActionType.AUTH_FETCH_SIGN_OUT_START:
        state.isFetchingSignOut = true;
        break;

      case AuthActionType.AUTH_FETCH_SIGN_OUT_FINISH:
        state.isFetchingUser = false;
        state.isFetchingSignOut = false;
        state.user = null;
        break;
    }

    return state;
  });
};
