import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGridApi } from '@components/common/AgGrid';
import { AlertCard } from '@components/common/AlertCard';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { useYardsListSelection } from '@components/yard/YardsList/hooks/useYardsListSelectionContext';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeBulkDeleteYardsModalCloseAction, makeBulkDeleteYardsThunk } from '@redux/BulkDeleteYards/actions';

export const BulkDeleteYardsModal: React.VFC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { isModalOpen } = useSelector((state) => state.bulkDeleteYardsReducer);
  const { selectedYards } = useYardsListSelection();
  const { gridApi } = useGridApi();

  const selectedYardsList = Object.values(selectedYards);
  const activeYardsCount = selectedYardsList.filter((yard) => yard.meta.isActive).length;
  const inactiveYardsCount = selectedYardsList.filter((yard) => !yard.meta.isActive).length;
  const showAlert = activeYardsCount > 0;

  const t = useTranslation();
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(makeBulkDeleteYardsModalCloseAction());
  }, [dispatch]);

  const attemptToDeleteYards = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(makeBulkDeleteYardsThunk(selectedYardsList.map(({ meta }) => meta.id)));
      closeModal();
      gridApi?.refreshInfiniteCache();

      Analytics.sendEvent({ event: AnalyticsEventType.YARD_BULK_DELETE });
    } finally {
      setIsLoading(false);
    }
  }, [closeModal, dispatch, gridApi, selectedYardsList]);

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      <ModalHeader
        title={t(inactiveYardsCount === 1 ? 'delete_x_yards_modal_title_single' : 'delete_x_yards_modal_title', {
          count: inactiveYardsCount,
        })}
        subtitle={inactiveYardsCount === 1 ? t('delete_x_yards_modal_text_single') : t('delete_x_yards_modal_text')}
        alert={
          showAlert ? (
            <AlertCard fit warning>
              {t(activeYardsCount === 1 ? 'delete_x_yards_modal_alert_single' : 'delete_x_yards_modal_alert', {
                count: activeYardsCount,
              })}
            </AlertCard>
          ) : undefined
        }
        suppressCloseButton={showAlert}
      />
      <ModalFooter
        autoCloseOnReject
        rejectText={t('cancel')}
        rejectButtonProps={{ disabled: isLoading }}
        acceptText={t('delete')}
        onAcceptClick={attemptToDeleteYards}
        acceptButtonProps={{ danger: true, disabled: isLoading }}
      />

      <Loading visible={isLoading} whiteBackground roundedCorners />
    </Modal>
  );
};
