import { makeUnauthorizedFetchFailureAction } from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

// fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
// Instead, it will resolve normally (with ok status set to false),
export const parseRawResponseToJson = (response) => {
  if (!response.ok) {
    if (400 === response.status) return { error: 'snack_non_field_errors' };

    if (401 === response.status) return { error: 'unauthorized' };

    if (504 === response.status) return { error: 'snack_timeout_error' };

    if (500 === response.status) return { error: 'snack_network_error' };
    else return { error: 'snack_default_msg', status: ` (error #${response.status})` };
  }
  // 200 Ok
  // parse the json payload:
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) return response.json();
};
// fetch() will only reject on network failure or if anything prevented the request from completing
// @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
export const parseRejectedToJson = (error) => {
  // console.log('NECTAR: rejected fetch');
  console.error(error);
  // a network error occurred:
  return { error: 'snack_network_error' };
};

export const parseJsonObjError = ({ dispatch, jsonObj, failure }) => {
  dispatch(failure());
  if ('error' in jsonObj) {
    if ('unauthorized' === jsonObj.error) return dispatch(makeUnauthorizedFetchFailureAction());
    else return dispatch(makeShowSnackbarAction({ messageTranslation: jsonObj.error, type: 'error' }));
  }
  if ('errors' in jsonObj) {
    const [key, value] = Object.entries(jsonObj.errors)[0];
    if (key) return dispatch(makeShowSnackbarAction({ messageTranslation: value, type: 'error' }));
    else return dispatch(makeShowSnackbarAction({ messageTranslation: jsonObj.errors, type: 'error' }));
  }
};
