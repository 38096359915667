import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Menu } from '@components/common/Icon/presets/Menu';
import { Search } from '@components/common/Icon/presets/Search';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { useTranslation } from '@hooks/useTranslation';
import { from } from '@style/mediaQueries';

import { useLayoutContext } from '../context/hooks';
import { GlobalSearch } from '../GlobalSearch';

const StyledHeader = styled.header(
  ({ theme }) => css`
    background-color: ${theme.primitives.colors.grey08};
    height: ${theme.layout.siteHeaderHeight}px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: ${theme.getZIndexOf('siteHeader')};

    ${from('desktopSM')} {
      padding: ${theme.spacing._050} ${theme.spacing._150};
    }
  `
);

const StyledMobileActionBox = styled(Box)(
  ({}) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  `
);

export const SiteHeader: React.VFC = () => {
  const t = useTranslation();
  const { expandSideNav, expandSearch } = useLayoutContext();
  return (
    <StyledHeader>
      <ResponsiveRender until="tablet">
        <StyledMobileActionBox>
          <Button iconOnly aria-label={t('menu_open')} onClick={expandSideNav}>
            <Menu color="contentPrimaryReverse" size={24} />
          </Button>
          <Button iconOnly aria-label={t('search_open')} onClick={expandSearch}>
            <Search color="contentPrimaryReverse" size={24} />
          </Button>
        </StyledMobileActionBox>
      </ResponsiveRender>
      <GlobalSearch />
    </StyledHeader>
  );
};
