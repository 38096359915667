import TagManager from 'react-gtm-module';

import config from '@config/config';
import {
  AnalyticsEvent,
  AnalyticsEventType,
  AnalyticsPerformanceMetricEventType,
  PageData,
} from '@helpers/Analytics/types';
import { DateUtil } from '@helpers/Date';
import { QueryParams } from '@helpers/QueryParams';

/**
 * Stores the start timestamp of each registered event.
 * Multiple calls for the same event might be done, in
 * which case the stack size increases.
 * */
const performanceMetricQueue: Partial<
  Record<AnalyticsPerformanceMetricEventType, { startTimestamp: number; stackSize: number }>
> = {};

export const Analytics = {
  initialize,
  sendEvent,
  getPageData,
  startPerformanceMetric,
  commitPerformanceMetric,
  cancelPerformanceMetric,
};

function initialize() {
  TagManager.initialize({
    gtmId: config.REACT_APP_GTM_ID as string,
  });
  initializePageViewListeners();
  return Analytics;
}

function sendEvent({ event, ...data }: AnalyticsEvent) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...((data as any).eventData ?? {}),
    },
  });
  return Analytics;
}

function initializePageViewListeners() {
  const handlePageView = () => {
    Analytics.sendEvent({
      event: AnalyticsEventType.PAGE_VIEW,
      eventData: getPageData(),
    });
  };
  handlePageView();
  return QueryParams.addListener(handlePageView);
}

function getPageData(): PageData {
  const { href, pathname, search } = window.location;
  return {
    page_url: href,
    page_path: pathname,
    page_query: search,
  };
}

/**
 * Used to start a performance measuring of a feature/page. You may call
 * it multiple times for each component that compounds the same feature.
 * Example:
 *    // Marks whiteboard table and metrics loading start
 *    Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD, {stackSize: 2});
 * */
export function startPerformanceMetric(event: AnalyticsPerformanceMetricEventType, options?: { stackSize?: number }) {
  const { stackSize = 1 } = options ?? {};
  if (!(event in performanceMetricQueue)) {
    performanceMetricQueue[event] = { startTimestamp: +new Date(), stackSize: 0 };
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  performanceMetricQueue[event]!.stackSize += stackSize;
}

/**
 * Used to commit a performance measuring of a feature/page. You may call
 * it multiple times for each component that compounds the same feature.
 * Example:
 *    // Marks whiteboard table loading end
 *    Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD);
 *
 *    // Marks whiteboard metrics loading end
 *    Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD);
 * */
export function commitPerformanceMetric(event: AnalyticsPerformanceMetricEventType) {
  const entry = performanceMetricQueue[event];
  if (entry) {
    if (entry.stackSize === 1) {
      delete performanceMetricQueue[event];
      const elapsed = DateUtil.getElapsedTimeInMilliseconds(entry.startTimestamp) / 1000;
      Analytics.sendEvent({ event, eventData: { elapsed } });
    } else {
      entry.stackSize -= 1;
    }
  }
}

/**
 * Cancels any ongoing performance measurement for the given event.
 * Useful for useEffect statement, if the element gets unmounted before
 * the measure ends.
 * */
export function cancelPerformanceMetric(...events: Array<AnalyticsPerformanceMetricEventType>) {
  events.forEach((event) => delete performanceMetricQueue[event]);
}
