import React, { useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { Tooltip } from '@components/common/Tooltip';
import { groupColorIndexToRealColor } from '@helpers/deprecated/color';

export const StyledGroupItem = styled(Box)<{ $color?: string }>(
  ({ theme, $color }) => css`
    position: relative;
    align-items: center;
    border: 1px solid ${theme.primitives.colors.grey04};
    border-radius: 4px;
    padding-left: ${$color ? theme.spacing._150 : theme.spacing._050};

    ${!!$color &&
    css`
      &::before {
        content: '';
        background-color: ${$color};
        border-radius: ${theme.shape.paperRadius01}px;

        position: absolute;
        left: ${theme.spacing._050};
        width: 8px;
        height: 8px;
      }
    `}
  `
);

export const StyledGroupCount = styled(StyledGroupItem)(
  () => `
  min-width: 33px;
`
);

export const StyledHiddenContent = styled(Box)(
  () => `
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
`
);

export const GroupsCell: React.FC<{ data: Array<BeeGroup> }> = ({ data }) => {
  const [previewSize, setPreviewSize] = useState(0);

  const cellRef = useRef<HTMLDivElement>(null);
  const countRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  const content = data.map((group: BeeGroup) => {
    const name = group?.name;
    const color = groupColorIndexToRealColor(group);
    return (
      <StyledGroupItem key={group.id} $color={color} paddingHorizontal_100 paddingVertical_025>
        <DefaultCellText>{name ?? ''}</DefaultCellText>
      </StyledGroupItem>
    );
  });

  const previewContent = content.slice(0, previewSize);
  const hiddenContent = content.slice(previewSize);
  const hiddenGroupsCount = content.length - previewContent.length;

  useEffect(() => {
    const cellElement = cellRef.current;
    if (!cellElement) {
      return;
    }

    const observer = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        const countElement = countRef.current;
        if (!countElement) {
          return null;
        }

        const cellWidth = cellElement.getBoundingClientRect().width;
        const groupCountWidth = countElement.getBoundingClientRect().width;

        let groupItemsWidth = 0;
        let groupItemsCount = 0;
        const groupItems = Array.from(cellElement.querySelectorAll(`${StyledGroupItem}:not(${StyledGroupCount})`));
        for (const groupItem of groupItems) {
          const itemWidth = groupItem.getBoundingClientRect().width + theme.primitives.px._025;
          const nextGroupItemsWidth = groupItemsWidth + itemWidth;

          if (nextGroupItemsWidth + groupCountWidth > cellWidth) {
            break;
          } else {
            groupItemsWidth = nextGroupItemsWidth;
            groupItemsCount += 1;
          }
        }

        setPreviewSize(groupItemsCount);
      });
    });
    observer.observe(cellElement);

    return () => observer.unobserve(cellElement);
  }, [data.length, theme.primitives.px._025]);

  return (
    <DefaultCellWrapper ref={cellRef}>
      <Box alignItems={'center'} gap_025>
        {previewContent}
        {Boolean(hiddenGroupsCount) && (
          <StyledGroupCount padding_025 ref={countRef}>
            <DefaultCellText>+{hiddenGroupsCount}</DefaultCellText>
          </StyledGroupCount>
        )}
        <Tooltip>{content}</Tooltip>
      </Box>
      <StyledHiddenContent>
        {hiddenContent}
        {Boolean(!hiddenGroupsCount) && (
          <StyledGroupCount padding_025 ref={countRef}>
            <DefaultCellText>+{hiddenGroupsCount}</DefaultCellText>
          </StyledGroupCount>
        )}
      </StyledHiddenContent>
    </DefaultCellWrapper>
  );
};
