import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { BulkDeleteYardsAction, BulkDeleteYardsActionType } from './types';

export const makeBulkDeleteYardsModalOpenAction = (): BulkDeleteYardsAction => ({
  type: BulkDeleteYardsActionType.BULK_DELETE_YARDS_OPEN_MODAL,
});

export const makeBulkDeleteYardsModalCloseAction = (): BulkDeleteYardsAction => ({
  type: BulkDeleteYardsActionType.BULK_DELETE_YARDS_CLOSE_MODAL,
});

export const makeBulkDeleteYardsThunk = Thunk.createTakeFirst((yardIds: Array<number>) => {
  return async (dispatch) => {
    const response = await Api.post(ENDPOINTS.yardsDelete, {
      yard_ids: yardIds,
    });

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw response.error;
    } else {
      dispatch(makeShowSnackbarAction({ messageTranslation: 'delete_x_yards_success_snackbar' }));
    }
  };
});
