import produce from 'immer';

import { PracticesActionType, PracticesReducer, PracticesState } from '@redux/Practices/types';

const defaultState: PracticesState = {
  practiceDefinitions: {},
  isFetching: false,
  hasFetchError: false,
  isFetchingPractice: {},
  isFetchingAction: {},
};

export const practicesReducer: PracticesReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case PracticesActionType.PRACTICES_FETCH_START:
        if (action.payload?.practiceId) {
          state.isFetchingPractice[action.payload.practiceId] = true;
        } else if (action.payload?.actionId) {
          state.isFetchingAction[action.payload.actionId] = true;
        } else {
          state.isFetching = true;
        }
        break;

      case PracticesActionType.PRACTICES_FETCH_FINISH:
        state.practiceDefinitions = action.payload.data ?? {};
        if (action.payload?.practiceId) {
          state.isFetchingPractice[action.payload.practiceId] = false;
        } else if (action.payload?.actionId) {
          state.isFetchingAction[action.payload.actionId] = false;
        } else {
          state.isFetching = false;
        }
        state.hasFetchError = !!action.payload.error;
        break;
    }

    return state;
  });
};
