import React from 'react';

import { GridApiProvider } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { HeaderActions } from '@components/common/HeaderActions';
import { Add } from '@components/common/Icon/presets/Add';
import { PageContent } from '@components/common/PageContent';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { Tab, Tabs } from '@components/common/Tabs';
import { ModalFormContract } from '@components/pollination/FormContract/ModalFormContract';
import { PolliContractsList, PolliContractsListEmpty } from '@components/pollination/PolliContractsList';
import { PolliContractsMetrics } from '@components/pollination/PolliContractsMetrics';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { useContractModalController } from '@redux/Contract/hooks';

export const PolliContractsListPage: React.VFC = () => {
  const t = useTranslation();
  const { openToCreate } = useContractModalController();

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>{t('pollination_contracts')}</PageHeaderTitle>
        <HeaderActions
          actions={[
            {
              title: t('pollination_add_contract'),
              icon: Add,
              onClick: openToCreate,
            },
          ]}
        />
      </PageHeader>

      <Tabs useURLPath>
        <Tab
          id={'ongoing'}
          title={t('pollination_contracts_ongoing_tab')}
          path={APP.routes.pollinationContractsOngoingList}
        >
          <GridApiProvider>
            <PolliContractsMetrics />
            <PageContent paddingTop_100 fullHeight fullWidth relative>
              <PolliContractsList />
              <PolliContractsListEmpty />
            </PageContent>
            <ModalFormContract />
          </GridApiProvider>
        </Tab>
        <Tab
          id={'archived'}
          title={t('pollination_contracts_archived_tab')}
          path={APP.routes.pollinationContractsArchivedList}
        >
          <GridApiProvider>
            <PageContent fullHeight fullWidth relative>
              <PolliContractsList showArchivedContracts />
              <PolliContractsListEmpty showArchivedContracts />
            </PageContent>
          </GridApiProvider>
        </Tab>
      </Tabs>
    </>
  );
};
