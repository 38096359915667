import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { PageContentHeader, PageContentInfo, PageContentSlim, PageContentTitle } from '@components/common/PageContent';
import { Text } from '@components/common/Text';
import { HiveStructureCard } from '@components/operation/HiveStructureCard';
import { SeasonCard } from '@components/operation/SeasonCard';
import { SectionTitle } from '@scenes/admin/Operation/TabProfile/styles';

export const TabProfile: React.FC = () => {
  const { t } = useTranslation();
  const { operation, isFetching } = useSelector((state) => state.operationReducer);

  return isFetching && !operation ? (
    <Loading />
  ) : (
    <PageContentSlim>
      <PageContentHeader>
        <PageContentTitle>{t('operation_profile')}</PageContentTitle>

        <PageContentInfo>{t('operation_profile_help_text')}</PageContentInfo>
      </PageContentHeader>
      <Box paper02 paddingHorizontal_100 paddingVertical_050 marginBottom_150>
        <Box margin_050>
          <SectionTitle typography={'Heading3'} weight={'600'}>
            {t('operation_name')}
          </SectionTitle>
        </Box>
        <Box margin_050 marginLeft_100>
          <Text typography={'SmallParagraph'}>{operation?.name}</Text>
        </Box>
      </Box>

      <SeasonCard />
      <Box marginTop_050 marginBottom_100 />
      <HiveStructureCard />
    </PageContentSlim>
  );
};
