import moment from 'moment';

import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { Api } from '@helpers/Api';
import { ApiResponseErrorDetail } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { removeAllWhiteSpace } from '@helpers/StringTransformers';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { DEF_DROPS_LIST_OPTIONS } from '@redux/Contract/reducer';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import {
  Contract,
  ContractAction,
  ContractActionType,
  ContractFromApi,
  ContractsStats,
  DropsListOptions,
  FetchDropsSuccesPayload,
  PollinationBatch,
} from './types';

/**
 * Contract Modal
 */

export const makeOpenContractModal = (): ContractAction => ({
  type: ContractActionType.OPEN_CONTRACT_MODAL,
});
export const makeCloseContractModal = (): ContractAction => ({
  type: ContractActionType.CLOSE_CONTRACT_MODAL,
});
export const makeDisposeContractAction = (): ContractAction => ({
  type: ContractActionType.DISPOSE_CONTRACT,
});

export const makeFetchContractsStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACTS_START,
});
export const makeFetchContractsFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACTS_FAILURE,
});

export const makeFetchContractsSuccessAction = (payload: {
  contracts: BeeContract[];
  contractsStats: ContractsStats;
  totalContracts: number;
}): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACTS_SUCCESS,
  payload,
});

export const makeFetchContractStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_START,
});
export const makeFetchContractFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_FAILURE,
});

export const makeFetchContractSuccessAction = (payload: BeeContract): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_SUCCESS,
  payload,
});

export const makeFetchContractSummaryStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_SUMMARY_START,
});
export const makeFetchContractSummaryFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_SUMMARY_FAILURE,
});

export const makeFetchContractSummarySuccessAction = (payload: BeeContractSummary[]): ContractAction => ({
  type: ContractActionType.FETCH_CONTRACT_SUMMARY_SUCCESS,
  payload,
});

export const makeCreateContractStartAction = (): ContractAction => ({
  type: ContractActionType.CREATE_START,
});

export const makeCreateContractSuccessAction = (payload: BeeContract): ContractAction => ({
  type: ContractActionType.CREATE_SUCCESS,
  payload,
});

export const makeCreateContractFailureAction = (payload: ApiResponseErrorDetail | null): ContractAction => ({
  type: ContractActionType.CREATE_FAILURE,
  payload,
});

export const makePatchContractStartAction = (): ContractAction => ({
  type: ContractActionType.PATCH_CONTRACT_START,
});

export const makePatchContractSuccessAction = (payload: BeeContract): ContractAction => ({
  type: ContractActionType.PATCH_CONTRACT_SUCCESS,
  payload,
});

export const makePatchContractFailureAction = (payload: ApiResponseErrorDetail | null): ContractAction => ({
  type: ContractActionType.PATCH_CONTRACT_FAILURE,
  payload,
});

export const makeFetchDropsListStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_LIST_START,
});

export const makeFetchDropsListSuccessAction = (payload: FetchDropsSuccesPayload): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_LIST_SUCCESS,
  payload,
});

export const makeFetchDropsListFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_LIST_FAILURE,
});
export const makeFetchActiveDropsStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_ACTIVE_DROPS_START,
});

export const makeFetchActiveDropsSuccessAction = (payload: BeeDrop[]): ContractAction => ({
  type: ContractActionType.FETCH_ACTIVE_DROPS_SUCCESS,
  payload,
});

export const makeFetchActiveDropsFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_ACTIVE_DROPS_FAILURE,
});

export const makeActiveDropsDisposeAction = (): ContractAction => ({
  type: ContractActionType.ACTIVE_DROPS_DISPOSE,
});

export const makeFetchDropsMapStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_MAP_START,
});

export const makeFetchDropsMapSuccessAction = (payload: BeeDrop[]): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_MAP_SUCCESS,
  payload,
});

export const makeFetchDropsMapFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_DROPS_MAP_FAILURE,
});

export const makeFetchBlocksMapStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_BLOCKS_MAP_START,
});

export const makeFetchBlocksMapSuccessAction = (payload: Array<BeeBlock>): ContractAction => ({
  type: ContractActionType.FETCH_BLOCKS_MAP_SUCCESS,
  payload,
});

export const makeFetchBlocksMapFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_BLOCKS_MAP_FAILURE,
});

export const makeFetchPoisMapStartAction = (): ContractAction => ({
  type: ContractActionType.FETCH_POIS_MAP_START,
});

export const makeFetchPoisMapSuccessAction = (payload: Array<BeePointOfInterest>): ContractAction => ({
  type: ContractActionType.FETCH_POIS_MAP_SUCCESS,
  payload,
});

export const makeFetchPoisMapFailureAction = (): ContractAction => ({
  type: ContractActionType.FETCH_POIS_MAP_FAILURE,
});

export const makePatchPollinationBatchStartAction = (): ContractAction => ({
  type: ContractActionType.PATCH_POLLINATION_BATCH_START,
});

export const makePatchPollinationBatchSuccessAction = (): ContractAction => ({
  type: ContractActionType.PATCH_POLLINATION_BATCH_SUCCESS,
});

export const makePatchPollinationBatchFailureAction = (): ContractAction => ({
  type: ContractActionType.PATCH_POLLINATION_BATCH_FAILURE,
});

export const makeFetchContractsThunk = Thunk.createTakeFirst(
  (params: { limit: number; offset: number } = { limit: 20, offset: 0 }) => {
    return async (dispatch) => {
      dispatch(makeFetchContractsStartAction());

      const response = await Api.get(URLUtil.buildURL(ENDPOINTS.contract, params));

      if (response.error) {
        dispatch(makeFetchContractsFailureAction());
      } else {
        const data = await response.json();
        const contracts: BeeContract[] = data.results.map((contract: ContractFromApi) => parseApiToContract(contract));
        const contractsStats = parseApiToContractsStats(data.stats);

        dispatch(makeFetchContractsSuccessAction({ contracts, contractsStats, totalContracts: data.count }));
      }
    };
  }
);

export const makeFetchContractSummaryThunk = Thunk.createTakeLast(() => {
  return async (dispatch) => {
    dispatch(makeFetchContractSummaryStartAction());

    const response = await CachedRequest.performRequest(URLUtil.buildURL(ENDPOINTS.contractSummary), (url: string) =>
      Api.get(url)
    );

    if (response.error) {
      dispatch(makeFetchContractSummaryFailureAction());
    } else {
      const data = await response.json();
      const contractSummaries: BeeContractSummary[] = data.results;

      dispatch(makeFetchContractSummarySuccessAction(contractSummaries));
    }
  };
});

export const makeFetchContractThunk = Thunk.createTakeLast((id: number) => {
  return async (dispatch) => {
    dispatch(makeFetchContractStartAction());

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.contractWithId, { id }));

    if (response.error) {
      dispatch(makeFetchContractFailureAction());
    } else {
      const data = await response.json();
      dispatch(makeFetchContractSuccessAction(parseApiToContract(data)));
    }
  };
});

export const makePatchContractThunk = Thunk.createTakeFirst((payload: BeeContract) => {
  return async (dispatch) => {
    dispatch(makePatchContractStartAction());

    const response = await Api.patch(
      URLUtil.buildURL(ENDPOINTS.contractWithId, { id: payload.id }),
      parseContractToApi(payload)
    );

    if (response.error) {
      const errorDetail = response.error.detail;
      dispatch(makePatchContractFailureAction(errorDetail));
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions)); // todo: get actual error
    } else {
      const data = parseApiToContract(await response.json());
      dispatch(makePatchContractSuccessAction(data));
      dispatch(makeCloseContractModal());
      dispatch(makeShowSnackbarAction({ messageTranslation: 'pollination_contract_update_success' }));
      return data;
    }
  };
});

export const makeCreateContractThunk = Thunk.createTakeFirst((payload: BeeContractForm) => {
  return async (dispatch) => {
    dispatch(makeCreateContractStartAction());

    const response = await Api.post(URLUtil.buildURL(ENDPOINTS.contract), parseContractToApi(payload));

    if (response.error) {
      const errorDetail = response.error.detail;
      dispatch(makeCreateContractFailureAction(errorDetail));
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions)); // todo: get actual error
    } else {
      const data = parseApiToContract(await response.json());
      dispatch(makeCreateContractSuccessAction(data));
      dispatch(
        makeShowSnackbarAction({
          messageTranslation: 'pollination_contract_form_create_success',
        })
      );
      return data;
    }
  };
});

export const makeFetchDropsListThunk = Thunk.createTakeLast(
  (contractId: number, listOptions: DropsListOptions = DEF_DROPS_LIST_OPTIONS) => {
    return async (dispatch) => {
      await dispatch(makeFetchDropsListStartAction());

      const dropsQueryParams = { contract: contractId, ...CaseAdapter.objectToSnakeCase(listOptions) };
      const dropsURL = URLUtil.buildURL(ENDPOINTS.dropsList, dropsQueryParams);
      const response = await Api.get(dropsURL);

      if (response.error) {
        await dispatch(makeFetchDropsListFailureAction());
        await dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      } else {
        const responseData = await response.json();
        const drops = parseApiToDrops(responseData);
        await dispatch(
          makeFetchDropsListSuccessAction({
            drops,
            listOptions: { ...listOptions, count: responseData.count },
          })
        );
      }
    };
  }
);

export const makeFetchDropsMapThunk = Thunk.createTakeFirst((contractId: number) => {
  return async (dispatch) => {
    await dispatch(makeFetchDropsMapStartAction());

    const dropsQueryParams = { contract: contractId };
    const dropsURL = URLUtil.buildURL(ENDPOINTS.dropsMap, dropsQueryParams);
    const response = await Api.get(dropsURL);

    if (response.error) {
      await dispatch(makeFetchDropsMapFailureAction());
      await dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const responseData = await response.json();
      const drops = parseApiToDrops(responseData);
      await dispatch(makeFetchDropsMapSuccessAction(drops));
    }
  };
});

export const makeFetchActiveDropsThunk = Thunk.createTakeFirst((contractId: number) => {
  return async (dispatch) => {
    await dispatch(makeFetchActiveDropsStartAction());
    const queryParams = { contract: contractId, yard_status: 'active' };
    const dropsURL = URLUtil.buildURL(ENDPOINTS.dropsMap, queryParams);
    const response = await Api.get(dropsURL);

    if (response.error) {
      await dispatch(makeFetchActiveDropsFailureAction());
      await dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const responseData = await response.json();
      const drops = parseApiToDrops(responseData);
      await dispatch(makeFetchActiveDropsSuccessAction(drops));
    }
  };
});

export const makeFetchBlocksMapThunk = Thunk.createTakeFirst((contractId: number) => {
  return async (dispatch) => {
    dispatch(makeFetchBlocksMapStartAction());

    const limit = 1000; // TODO: Remove pagination from backend.
    const blocksURL = URLUtil.buildURL(ENDPOINTS.blocks, { contract: contractId, limit });
    const response = await Api.get(blocksURL);

    if (response.error) {
      dispatch(makeFetchBlocksMapFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const data = parseApiToBlocks(await response.json());
      dispatch(makeFetchBlocksMapSuccessAction(data));
    }
  };
});

export const makeFetchPoisMapThunk = Thunk.createTakeFirst((contractId: number) => {
  return async (dispatch) => {
    dispatch(makeFetchPoisMapStartAction());

    const limit = 1000; // TODO: Remove pagination from backend.
    const poisURL = URLUtil.buildURL(ENDPOINTS.pois, { contract: contractId, limit });
    const response = await Api.get(poisURL);

    if (response.error) {
      dispatch(makeFetchPoisMapFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const data = parseApiToPois(await response.json());
      dispatch(makeFetchPoisMapSuccessAction(data));
    }
  };
});

export const makePatchPollinationBatchThunk = Thunk.createTakeFirst((payload: PollinationBatch) => {
  return async (dispatch) => {
    dispatch(makePatchPollinationBatchStartAction());

    const { contract_id, ...data } = parsePollinationBatchToApi(payload);
    const patchURL = URLUtil.buildURL(ENDPOINTS.pollinationBatch, { id: contract_id });
    const response = await Api.post(patchURL, data);

    if (response.error) {
      dispatch(makePatchPollinationBatchFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      dispatch(makePatchPollinationBatchSuccessAction());
      dispatch(makeShowSnackbarAction({ messageTranslation: 'pollination_batch_success' }));
    }
  };
});

function parseContractToApi(data: BeeContractForm): Contract {
  return {
    name: data.name,
    crop_types_ids: data.cropTypesIds,
    bees_in: data.beesIn ? moment(data.beesIn).format(APP.SEASON_START_DATE_FORMAT) : null,
    bees_out: data.beesOut ? moment(data.beesOut).format(APP.SEASON_START_DATE_FORMAT) : null,
    grower: {
      name: data.grower.name,
      contact_name: data.grower.contactName,
      contact_phone_number: data.grower.contactPhoneNumber ? removeAllWhiteSpace(data.grower.contactPhoneNumber) : null,
      contact_email: data.grower.contactEmail ? data.grower.contactEmail : null,
    },
    cost_per_hive: data.costPerHive ? data.costPerHive : null,
    nb_hives: data.nbRequiredHives ? data.nbRequiredHives : null,
    total_price: data.totalPrice ? data.totalPrice : null,
    address: data.address ? data.address : null,
    notes: data.notes ? data.notes : null,
  };
}

export function parseApiToContract(data: ContractFromApi): BeeContract {
  return {
    name: data.name,
    cropTypesIds: data.crop_types_ids,
    beesIn: data.bees_in ? moment(data.bees_in, APP.SEASON_START_DATE_FORMAT).toDate() : null,
    beesOut: data.bees_out ? moment(data.bees_out, APP.SEASON_START_DATE_FORMAT).toDate() : null,
    nbDrops: data.nb_drops,
    nbBlocks: data.nb_blocks,
    grower: {
      name: data.grower.name,
      contactName: data.grower.contact_name,
      contactPhoneNumber: data.grower.contact_phone_number ?? '',
      contactEmail: data.grower.contact_email ?? '',
    },
    contractYear: data.contract_year,
    costPerHive: data.cost_per_hive ?? undefined,
    nbRequiredHives: data.nb_hives ?? undefined,
    nbTrackedHives: data.nb_tracked_hives,
    nbTargetedHives: data.nb_targeted_hives,
    totalAcres: data.total_acres,
    totalPrice: data.total_price ?? undefined,
    address: data.address ?? '',
    notes: data.notes ?? '',
    id: data.id,
    archivedAt: data.archived_at,
  };
}

function parseApiToContractsStats(data: any): ContractsStats {
  return CaseAdapter.objectToCamelCase(data);
}

function parsePollinationBatchToApi(payload: PollinationBatch) {
  return CaseAdapter.objectToSnakeCase(payload);
}

function parseApiToDrops(data: any): Array<BeeDrop> {
  return CaseAdapter.objectToCamelCase(data.results);
}

function parseApiToBlocks(data: any): Array<BeeBlock> {
  return data.results.map(({ id, name, area, geometry }: any) => {
    const polygon = geometry as any;

    return {
      id,
      name,
      area,
      geometry: polygon,
    };
  });
}

function parseApiToPois(data: any): Array<BeePointOfInterest> {
  return CaseAdapter.objectToCamelCase(data.results);
}
