import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

/**
 * action creator
 *
 * @note: using the redux-thunk middleware, an action creator
 * can return a function instead of an action object
 *
 * @returns {{type: string}}
 */
export const makeForgottenPasswordRequestStartAction = () => ({
  type: types.FORGOTTEN_PASSWORD_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeForgottenPasswordRequestSuccessAction = () => ({
  type: types.FORGOTTEN_PASSWORD_SUCCESS,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeForgottenPasswordRequestFailureAction = () => ({
  type: types.FORGOTTEN_PASSWORD_FAILURE,
});

/**
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 *
 * Since reducers are supposed to be “pure” (as in, they don’t change
 * anything outside their scope) we can’t do any API calls or dispatch
 * actions from inside a reducer.
 * the redux-thunk middleware knows how to handle functions
 * It passes the dispatch method as an argument to the function,
 * thus making it able to dispatch actions itself.
 * @see https://github.com/reduxjs/redux-thunk
 *
 * @param email
 * @returns {Function}
 */
export const makeForgottenPasswordRequestThunk = ({ email }) => {
  return async (dispatch) => {
    // show the spinning screen:
    dispatch(makeForgottenPasswordRequestStartAction());

    const response = await Api.post(ENDPOINTS.passwordForgotten, { email });

    if (response.error) {
      dispatch(makeForgottenPasswordRequestFailureAction());
      return response.error;
    } else {
      dispatch(makeForgottenPasswordRequestSuccessAction());
      dispatch(
        makeShowSnackbarAction({
          messageTranslation: 'snack_password_reset_email_sent',
          messageTranslationParams: { email },
        })
      );
    }
  };
};
