import React, { FormEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';
import { InputTextBackwardCompatible } from '@components/form/backwardCompatible/InputTextBackwardCompatible';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import { StyledForm, StyledFormContent, StyledFormFooter, StyledFormHead } from './styles';

export const PolliMapPaneBlockForm: React.VFC = () => {
  const { t } = useTranslation();
  const {
    selectedBlock,
    selectBlock,
    isAdding,
    blocks,
    isManagingABlock,
    isDraggingElements,
    submitBlockChange,
    cancelBlockChange,
  } = usePolliMap();

  const nameValue = useMemo(() => {
    if (selectedBlock && selectedBlock.name !== null) {
      return selectedBlock.name;
    }
    return '';
  }, [selectedBlock]);

  const acresValue = useMemo(() => {
    if (selectedBlock && selectedBlock.area !== null) {
      return String(selectedBlock.area);
    }
    return '';
  }, [selectedBlock]);

  const handleNameChange = useCallback(
    (value: string | number) => {
      if (selectedBlock) {
        const name = String(value) || null;
        selectBlock({ ...selectedBlock, name });
      }
    },
    [selectBlock, selectedBlock]
  );

  const handleAcresChange = useCallback(
    (value: number | string) => {
      if (selectedBlock) {
        const cleanValue = String(value).replace(/[^0-9.]/g, '');
        const acres = cleanValue ? parseFloat(cleanValue) : null;
        selectBlock({ ...selectedBlock, area: acres });
      }
    },
    [selectBlock, selectedBlock]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      selectedBlock &&
        Analytics.sendEvent({
          event: isAdding(selectedBlock, blocks)
            ? AnalyticsEventType.POLLINATION_MAP_BLOCK_ADD
            : AnalyticsEventType.POLLINATION_MAP_BLOCK_EDIT,
        });

      event.preventDefault();
      event.stopPropagation();
      submitBlockChange();
    },
    [blocks, isAdding, selectedBlock, submitBlockChange]
  );

  const handleCancel = useCallback(() => {
    cancelBlockChange();
  }, [cancelBlockChange]);

  if (!(isDraggingElements || isManagingABlock)) {
    return null;
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormHead>
        <Text typography={'Heading3'} weight={'600'}>
          {t('pollination_new_block_name')}
        </Text>
      </StyledFormHead>
      <StyledFormContent>
        <Box marginBottom_100 column stretch>
          <Text typography={'SmallParagraph'}>{t('pollination_new_block_hint')}</Text>
        </Box>

        <Box marginBottom_050 column stretch>
          <InputTextBackwardCompatible
            id="block-name"
            name="block-name"
            type="text"
            label={t('pollination_block_name_label')}
            value={nameValue}
            onTextChange={handleNameChange}
            required
            autoFocus
          />
        </Box>

        <Box marginBottom_050 column stretch>
          <InputTextBackwardCompatible
            id="block-acres"
            name="block-acres"
            type="number"
            label={t('pollination_block_acres_label')}
            value={acresValue}
            step="any"
            onTextChange={handleAcresChange}
          />
        </Box>
      </StyledFormContent>
      <StyledFormFooter>
        <Button secondary grow type={'button'} onClick={handleCancel} withMarginRight>
          {t('cancel')}
        </Button>
        <Button primary grow>
          {t(isAdding(selectedBlock, blocks) ? 'create' : 'done')}
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
