import React from 'react';

import { Box } from '@components/common/Box';

import { StyledStepDot } from './styles';
import { StepsProgressProps } from './types';

export const StepsProgress: React.FC<StepsProgressProps> = ({
  currentStep,
  stepCount,
  defaultStepDotColor,
  markCompletedSteps,
  onStepClick,
}) => {
  return (
    <Box center gap_025>
      {new Array(stepCount).fill(null).map((_, index) => {
        const isActive = index === currentStep;
        const isCompleted = markCompletedSteps ? currentStep >= index : isActive;
        return (
          <StyledStepDot
            key={index}
            $isActive={isActive}
            $isCompleted={isCompleted}
            $isClickable={!!onStepClick}
            $defaultStepDotColor={defaultStepDotColor}
            onClick={() => onStepClick && onStepClick(index)}
          />
        );
      })}
    </Box>
  );
};
