import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { MapPane } from '@components/common/Map';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Animation } from '@helpers/Animation';

export const StyledPane = styled(MapPane).attrs({
  width: 355,
})``;

export const StyledPaneScroll = styled(Box).attrs({ id: 'yard-details-pane-scroll', column: true, stretch: true })`
  flex: 1;
  position: relative;
  overflow: hidden auto;
`;

export const StyledContentTransition = styled(ContentChangeTransition).attrs({
  animationType: 'focus',
  animationDuration: 'fast',
  animationAnchorSelector: '#yard-details-pane-scroll',
  column: true,
  stretch: true,
})`` as typeof ContentChangeTransition;

export const StyledHeader = styled(Box)(
  ({ theme }) => `
  align-items: center;
  padding-left: ${theme.spacing._100};
  border-bottom: ${theme.primitives.colors.grey03} 1px solid;
  
  h3 {
    display: flex;
    flex: 1;
  }
`
);

export const StyledHeaderCloseButton = styled(PrimaryButton).attrs({ flat: true })(
  ({ theme }) => `
  padding: ${theme.spacing._100};
`
);

export const StyledYardTitle = styled(Box)(
  ({ theme }) => `
  height: 125px;
  align-items: center;
  padding: ${theme.spacing._100};
  background-color: ${theme.primitives.colors.orange02};
`
);

export const StyledSection = styled(Box).attrs({ column: true, alignItems: 'stretch' })<{ $last?: boolean }>(
  ({ theme, $last }) => `
  padding: ${theme.spacing._150} ${theme.spacing._100} ${theme.spacing._050} ${theme.spacing._100};
  
  ${!$last ? `border-bottom: ${theme.primitives.colors.grey04} 1px solid;` : ''}
`
);

export const StyledSectionHeader = styled(Text).attrs({
  typography: 'CaptionSmall',
  weight: '600',
  color: 'grey06',
})(
  ({ theme }) => `
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: ${theme.spacing._050};
`
);

export const StyledSectionSubHeader = styled(Text).attrs({
  typography: 'Heading3',
  weight: '600',
})(
  ({ theme }) => `
  white-space: nowrap;
  margin-right: ${theme.spacing._100};
  
  i {
    margin-right: ${theme.spacing._050};
  }
`
);

export const StyledSectionValue = styled(Text).attrs({
  typography: 'Heading3',
  htmlTag: 'p',
  weight: '400',
})(() => ``);

export const StyledSectionRow = styled(Box)(
  ({ theme }) => `
  align-items: flex-start;
  justify-content: space-between;
  padding: ${theme.spacing._100} 0;
  
  &:not(:last-child) {
    border-bottom: ${theme.primitives.colors.grey04} 1px solid;
  }
  
  svg {
    margin-right: ${theme.spacing._050};
  }
`
);

export const StyledFooter = styled(Box)(
  ({ theme }) => `
  border-top: ${theme.primitives.colors.grey03} 1px solid;
  padding: ${theme.spacing._100};
`
);

export const StyledFooterButton = styled(PrimaryButton)(
  ({ theme, flat }) => `
  flex: 1;
  padding: ${theme.spacing._100};
  
  border: ${flat ? `${theme.primitives.colors.grey08} 1px solid` : 'none'};
  
  &:first-child {
    margin-right: ${theme.spacing._050};
  }
`
);

export const StyledLoaderWrapper = styled.div(
  () => `
  ${Animation.createCSSKeyframes('yard-pane-loader-fade', (progress) => `opacity: ${progress}`)}
  animation: yard-pane-loader-fade 400ms linear 0ms 1;
`
);
