import React, { useMemo } from 'react';

import { Box } from '@components/common/Box';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonCircle } from '@components/common/LoadingSkeleton/LoadingSkeletonCircle';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { useHiveDetailsData } from '@redux/HiveDetails/hooks';
import { usePractices } from '@redux/Practices/hooks';

export const HiveModalMainLoading: React.VFC = () => {
  const { isFetchingDetail } = useHiveDetailsData();
  const { isFetching: isFetchingActions } = usePractices();

  const fakeSummary = useMemo(
    () =>
      new Array(5).fill(null).map((_, index) => ({
        key: index,
        widthLeft: 64 + 64 * Math.random(),
        widthRight: 64 + 64 * Math.random(),
      })),
    []
  );

  return (
    <LoadingSkeleton
      column
      stretch
      absolutelyFitParent
      backgroundColor={'white'}
      marginTop_150
      visible={isFetchingDetail || isFetchingActions}
    >
      <Box paddingTop_200 paddingBottom_150 paddingHorizontal_150 gap_050 alignItems={'center'}>
        <LoadingSkeletonRectangle width={164} height={32} />
        <LoadingSkeletonRectangle width={108} height={32} backgroundColor={'grey06'} />
      </Box>
      <Box paddingVertical_100 justifyContent={'space-evenly'}>
        <Box fit center>
          <LoadingSkeletonRectangle width={108} height={24} />
        </Box>
        <Box fit center>
          <LoadingSkeletonRectangle width={108} height={24} />
        </Box>
      </Box>

      <Box column stretch marginTop_150 marginHorizontal_150>
        {fakeSummary.map(({ key, widthLeft, widthRight }) => (
          <Box key={key} gap_050 paddingVertical_050 marginTop_050 alignItems={'center'}>
            <LoadingSkeletonCircle size={22} />
            <LoadingSkeletonRectangle width={widthLeft} height={20} />
            <Box fit />
            <LoadingSkeletonRectangle width={widthRight} height={20} />
          </Box>
        ))}
      </Box>
    </LoadingSkeleton>
  );
};
