import produce from 'immer';

import * as actionTypes from '../actionTypes';

const initState = {
  isFetching: false,
  translations: null,
  fetchedAt: null,
};

export const translationsReducer = (state = initState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.TRANSLATIONS_START:
        st.isFetching = true;
        break;
      case actionTypes.FETCH_TRANSLATIONS_SUCCESS:
        st.isFetching = false;
        st.translations = action.translations;
        st.fetchedAt = new Date().getTime();
        break;
      case actionTypes.UPDATE_SPANISH_TRANSLATIONS_SUCCESS:
        let updatedTranslations = [...(state.translations ?? [])];
        const { translations } = action.practice;
        let i = updatedTranslations.findIndex((v) => v.hht_actions === action.practice.id);
        const spanish_translation = translations.find((tr) => 'ES' === tr.language_code);
        let data = {
          ...spanish_translation,
          hht_actions: action.practice.id,
        };
        if (i >= 0) {
          updatedTranslations.splice(i, 1, data);
        } else updatedTranslations.push(data);
        st.isFetching = false;
        st.translations = updatedTranslations;
        break;
      case actionTypes.TRANSLATIONS_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
