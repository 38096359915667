import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipRef } from '@components/common/Tooltip/types';
import {
  useDropdownDesktopAutoWidth,
  useDropdownDesktopElements,
  useDropdownDesktopEvents,
} from '@components/form/inputs/InputAutocomplete/hooks';
import { StyledDesktopDropdown } from '@components/form/inputs/InputAutocomplete/styles';
import {
  InputAutocompleteDropdownProps,
  InputAutocompleteDropdownRef,
} from '@components/form/inputs/InputAutocomplete/types';
import { useDebounce } from '@helpers/Throttling/hooks';

export const InputAutocompleteDropdownDesktop = React.forwardRef<
  InputAutocompleteDropdownRef,
  InputAutocompleteDropdownProps
>(function InputAutocompleteDropdownDesktop({ target, onShown, onHidden, children }, ref) {
  const theme = useTheme();
  const isOpen = useRef(false);
  const tooltipRef = useRef<TooltipRef>(null);
  const blurDebounce = useDebounce(theme.animations.durationMedium);

  const handleDropdownFocus = useCallback(() => {
    isOpen.current = true;
    tooltipRef.current?.show();
    onShown && onShown();
  }, [onShown]);

  const handleDropdownBlur = useCallback(() => {
    isOpen.current = false;
    tooltipRef.current?.hide();

    // A small delay to wait the dropdown
    // to close completely.
    blurDebounce(() => onHidden && onHidden());
  }, [blurDebounce, onHidden]);

  const dropdownElements = useDropdownDesktopElements(target);
  const dropdownEvents = useDropdownDesktopEvents(dropdownElements, {
    onFocus: handleDropdownFocus,
    onBlur: handleDropdownBlur,
  });
  useDropdownDesktopAutoWidth(dropdownElements);

  useImperativeHandle(ref, () => ({
    isOpen: isOpen.current,
    hide() {
      dropdownEvents.blur();
    },
  }));

  return (
    <>
      <Tooltip
        ref={tooltipRef}
        offset={useMemo(() => [0, 0], [])}
        trigger={'manual'}
        placement={'bottom-start'}
        interactive
        renderContent={(children) => (
          <StyledDesktopDropdown data-dropdown-target={target}>{children}</StyledDesktopDropdown>
        )}
      >
        <Box column stretch>
          {children}
        </Box>
      </Tooltip>
    </>
  );
});
