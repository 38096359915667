import { useEffect } from 'react';

const useInterval = (callback: () => void, delay: number) => {
  useEffect(() => {
    if (delay !== null) {
      const intervalID = setInterval(callback, delay);
      return () => {
        clearInterval(intervalID);
      };
    }
  }, [callback, delay]);
};

export default useInterval;
