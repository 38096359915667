import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Add } from '@components/common/Icon/presets/Add';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import AssignYardsGroupsModal from '@components/deprecated/DynamicModals/AssignYardsGroupsModal';
import { StyledGroupDot } from '@components/yard/YardDetailsDashboard/styles';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { getGroupColor } from '@helpers/deprecated/color';
import { Sorting } from '@helpers/Sorting';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useGroups, useGroupsFetcher } from '@hooks/useGroups';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenAssignYardsGroupsModalAction } from '@redux/deprecated/actions';
import { makeYardDetailsFetchThunk } from '@redux/YardDetails/actions';

export const YardDetailsDashboardGroups: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);
  const showAssignGroupsButton = !yard?.deletedAt;

  const { isFetching: isFetchingGroups } = useGroupsFetcher();
  const groups = useGroups();

  const yardGroups = useMemo(() => {
    return Sorting.sortAlphanumerically(
      groups
        .filter(({ id }) => yard?.groupIds.includes(id))
        .map((group) => ({ ...group, color: getGroupColor(groups ?? [], group.id) })),
      'name'
    );
  }, [yard, groups]);

  const openGroupAssignModal = useCallback(() => {
    if (!yard) {
      return;
    }

    const backwardCompatibleYard = CaseAdapter.objectToSnakeCase(yard);

    dispatch(
      makeOpenAssignYardsGroupsModalAction({
        modalType: 'assign-yard-groups',
        yard: backwardCompatibleYard,
        onAssign: () => dispatch(makeYardDetailsFetchThunk(yard.id)),
      })
    );
  }, [dispatch, yard]);

  if (!yard) {
    return null;
  }

  if (!showAssignGroupsButton && yardGroups.length === 0) {
    return null;
  }

  return (
    <Box relative column paddingVertical_100 paddingHorizontal_150 gap_025>
      <Box alignItems={'center'} justifyContent={'space-between'}>
        <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
          {t('groups')}
        </Text>

        {showAssignGroupsButton && (
          <Button tertiary withLeadingIcon onClick={openGroupAssignModal}>
            <Add />
            {t('assign_group')}
          </Button>
        )}
      </Box>

      {!!yardGroups.length && (
        <Box wrap alignItems={'center'} gap_050>
          {yardGroups.map(({ id, name, color }) => (
            <Box key={id} gap_050 paddingHorizontal_050 borderWidth={1} borderColor={'grey04'} paper01 center>
              <StyledGroupDot $color={color} />
              <Text typography={'SmallParagraph'}>{name}</Text>
            </Box>
          ))}
        </Box>
      )}

      <LoadingSkeleton
        visible={isFetchingGroups}
        backgroundColor={'white'}
        absolutelyFitParent
        paddingHorizontal_150
        paddingVertical_100
        justifyContent={'space-between'}
      >
        <LoadingSkeletonRectangle width={'40%'} height={36} borderRadius={'paperRadius02'} />
        <LoadingSkeletonRectangle width={'30%'} height={36} borderRadius={'paperRadius02'} />
      </LoadingSkeleton>

      <AssignYardsGroupsModal />
    </Box>
  );
};
