import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { Api } from '@helpers/Api';
import { isEmptyArray } from '@helpers/deprecated/array';
import { parseJsonObjError, parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import i18n from '../../../i18n';
import * as types from '../actionTypes';

import { makeCloseUpdateYardModalAction } from './modalActionCreator';

const getLimit = sessionStorage.getItem('limit');

/**
 *
 * @returns {{type: string}}
 * @returns {{order_by: string}}
 *
 *
 */
export const orderYardListBy = (order_by) => ({
  type: types.ORDER_YARD_LIST_BY,
  order_by,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{order_direction: string}}
 *
 *
 */
export const orderYardListByDirection = (order_direction) => ({
  type: types.ORDER_YARD_LIST_BY_DIRECTION,
  order_direction,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{offset: number}}
 *
 *
 */
export const makePaginationAction = (offset) => ({
  type: types.DISPATCH_PAGINATION_ACTION,
  offset,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{limit: number}}
 *
 *
 */
export const makeLimitAction = (limit) => ({
  type: types.DISPATCH_LIMIT_ACTION,
  limit,
});

export const setUpdateMultipleYards = (patch, yards) => ({
  type: types.SET_UPDATE_MULTIPLE_YARDS,
  patch,
  yards,
});

export const setRemoveMultipleYards = (remove, yards) => ({
  type: types.SET_REMOVE_MULTIPLE_YARDS,
  remove,
  yards,
});

export const setAddMultipleYards = (add, yards) => ({
  type: types.SET_ADD_MULTIPLE_YARDS,
  add,
  yards,
});

export const resetYardData = () => ({
  type: types.RESET_YARD_DATA,
});

export const resetYardModalData = () => ({
  type: types.RESET_YARD_MODAL_DATA,
});

/**************** FETCH YARD LIST DATA ****************/
/**
 *
 * @returns {{type: string}}
 *
 */
export const makeFetchYardListStartAction = () => ({
  type: types.FETCH_HHT_YARD_LIST_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{data: object}}
 *
 */
export const makeFetchYardListSuccessAction = (data) => ({
  type: types.FETCH_HHT_YARD_LIST_SUCCESS,
  data,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeFetchYardListFailureAction = () => ({
  type: types.FETCH_HHT_YARD_LIST_FAILURE,
});

/**************** FETCH YARD MAP DATA ****************/
/**
 *
 * @returns {{type: string}}
 *
 */
export const makeFetchYardMapStartAction = () => ({
  type: types.FETCH_HHT_YARD_MAP_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{data: object}}
 *
 */
export const makeFetchYardMapSuccessAction = (data) => ({
  type: types.FETCH_HHT_YARD_MAP_SUCCESS,
  data,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeFetchYardMapFailureAction = () => ({
  type: types.FETCH_HHT_YARD_MAP_FAILURE,
});

/**************** FETCH YARD DATA ****************/

export const makeFetchYardRequestStartAction = () => ({
  type: types.FETCH_HHT_YARD_START,
});

export const makeFetchYardRequestSuccessAction = (yard) => ({
  type: types.FETCH_HHT_YARD_SUCCESS,
  yard,
});

export const makeFetchYardRequestFailureAction = () => ({
  type: types.FETCH_HHT_YARD_FAILURE,
});

/**************** UPDATE YARD DATA ****************/
/**
 *
 * @returns {{type: string}}
 *
 */
export const makeUpdateYardRequestStartAction = () => ({
  type: types.UPDATE_YARD_ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{yard: object}}
 *
 */
export const makeUpdateYardRequestSuccessAction = (yard, id) => ({
  type: types.UPDATE_YARD_ACTION_SUCCESS,
  yard,
  id,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeUpdateYardRequestFailureAction = () => ({
  type: types.UPDATE_YARD_ACTION_FAILURE,
});

/**************** DELETE YARD DATA ****************/

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeDeleteYardRequestStartAction = () => ({
  type: types.DELETE_YARD_ACTION_START,
});

export const makeDeleteYardRequestSuccessAction = (id) => ({
  type: types.DELETE_YARD_ACTION_SUCCESS,
  id,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeDeleteYardRequestFailureAction = () => ({
  type: types.DELETE_YARD_ACTION_FAILURE,
});

export const makeFetchYardListRequestThunk = () => {
  return (dispatch, getState) => {
    const { beeTrackYardListReducer, yardsFiltersReducer } = getState();

    const { limit, offset, order_by, order_direction } = beeTrackYardListReducer;
    const { appliedFilters } = yardsFiltersReducer;

    const url = URLUtil.buildURL(ENDPOINTS.yardsList, {
      limit,
      offset,
      order_by,
      order_direction,
      ...appliedFilters,
    });

    dispatch(makeFetchYardListStartAction());

    fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeFetchYardListFailureAction;
        parseJsonObjError({ dispatch, jsonObj, failure });
        if ('results' in jsonObj) {
          // if the count is not zero but the results array is emtpy, reset
          if (jsonObj.count > 0 && isEmptyArray(jsonObj.results)) {
            const default_limit = getLimit ? parseInt(getLimit) : APP.defaultLimit;
            const default_pagination = 0;
            dispatch(makeLimitAction(default_limit));
            dispatch(makePaginationAction(default_pagination));
            dispatch(makeFetchYardListRequestThunk());
          } else dispatch(makeFetchYardListSuccessAction(jsonObj));
        }
      });
  };
};

export const makeFetchYardMapRequestThunk = () => {
  return (dispatch, getState) => {
    dispatch(makeFetchYardMapStartAction());

    const { appliedFilters } = getState().yardsFiltersReducer;
    const url = URLUtil.buildURL(ENDPOINTS.yardsMap, { ...appliedFilters });

    Api.get(url)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeFetchYardMapFailureAction;
        parseJsonObjError({ dispatch, jsonObj, failure });
        if ('results' in jsonObj) dispatch(makeFetchYardMapSuccessAction(jsonObj));
      });
  };
};

export const makeFetchYardRequestThunk = (id) => {
  return function (dispatch) {
    dispatch(makeFetchYardRequestStartAction());

    return Api.get(`${ENDPOINTS.hhtYards}${id}/`)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeFetchYardRequestFailureAction;
        parseJsonObjError({ dispatch, jsonObj, failure });
        dispatch(makeFetchYardRequestSuccessAction(jsonObj));
      });
  };
};

export const makeUpdateYardRequestThunk = (id, yardData, yard_name) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeUpdateYardRequestStartAction());

    return Api.patch(`${ENDPOINTS.hhtYards}${id}/`, yardData)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeUpdateYardRequestFailureAction;
        dispatch(makeCloseUpdateYardModalAction());
        if ('error' in jsonObj) parseJsonObjError({ dispatch, jsonObj, failure });
        else {
          dispatch(makeShowSnackbarAction(i18n.t('yard_updated', { yard_name }), { icon: true }));
          dispatch(makeUpdateYardRequestSuccessAction(jsonObj, id));
        }
      });
  };
};

export const makeDeleteYardRequestThunk = (yard) => {
  const { id, name } = yard;
  return async function (dispatch) {
    // show the spinning screen:
    dispatch(makeDeleteYardRequestStartAction());

    await fetch(`${ENDPOINTS.hhtYards}${id}/`, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        const failure = makeDeleteYardRequestFailureAction;
        if ('error' in jsonObj) parseJsonObjError({ dispatch, jsonObj, failure });
        else {
          dispatch(makeShowSnackbarAction(i18n.t('yard_deleted', { yard_name: name }), { icon: true }));
          dispatch(makeDeleteYardRequestSuccessAction(id));
        }
      });
  };
};

export const makeAddYardToSearchHistory = (yardId) => {
  return (dispatch, getState) => {
    const fetchYardListState = getState().beeTrackYardListReducer;
    const { yardsSearchHistory } = fetchYardListState;

    if (!yardsSearchHistory.includes(yardId)) {
      const newYardsSearchHistory = [...yardsSearchHistory, yardId];

      if (newYardsSearchHistory.length > 5) {
        newYardsSearchHistory.shift();
      }

      dispatch({
        type: types.SET_SEARCH_HISTORY_LIST,
        yardIds: newYardsSearchHistory,
      });
    }
  };
};
