import React, { useCallback, useEffect, useMemo } from 'react';
import { capitalize } from 'lodash';
import { ColorGlobal } from 'styled-components';

import {
  StyledAlertCard,
  StyledAlertCloseButton,
  StyledAlertDivider,
  StyledAlertIconWrapper,
} from '@components/common/AlertCard/styles';
import { AlertCardProps } from '@components/common/AlertCard/types';
import { Box } from '@components/common/Box';
import { Anchor, Button } from '@components/common/CTA';
import { Close } from '@components/common/Icon';
import { InfoAlert } from '@components/common/Icon/presets/InfoAlert';
import { WarningToast } from '@components/common/Icon/presets/WarningToast';
import { Text } from '@components/common/Text';
import { Children } from '@helpers/Children';
import { useLocalStorageState } from '@helpers/Storage/hooks';
import { useTranslation } from '@hooks/useTranslation';

export const AlertCard: React.FC<AlertCardProps> = ({
  warning,
  error,
  sticky,
  storageKey,
  dismissible,
  permanentlyDismissible,
  linkText,
  linkTo,
  onLinkClick,
  onDismiss,
  children,
  ...props
}) => {
  const t = useTranslation();

  const [state, setState] = useLocalStorageState(storageKey ?? '', { dismissed: false });

  const canBeDismissed = dismissible || permanentlyDismissible;
  const hasLink = !!linkText;

  const dismiss = useCallback(() => {
    setState({ dismissed: true });
    onDismiss && onDismiss();
  }, [setState, onDismiss]);

  useEffect(() => {
    if (permanentlyDismissible && !storageKey) {
      throw new Error('The prop "permanentlyDismissible" muse be used along with "storageKey".');
    }
  }, [permanentlyDismissible, storageKey]);

  useEffect(() => {
    if (!permanentlyDismissible) {
      setState({ dismissed: false });
    }
  }, [permanentlyDismissible, setState]);

  const { title, icon, color, borderColor, backgroundColor } = useMemo<{
    title: string;
    icon: any;
    color: ColorGlobal;
    borderColor: ColorGlobal;
    backgroundColor: ColorGlobal;
  }>(() => {
    switch (true) {
      case warning:
        return {
          title: capitalize(t('warning')),
          icon: <WarningToast color={'white'} size={10} />,
          color: 'borderWarning',
          borderColor: 'borderWarning',
          backgroundColor: 'surfaceWarningLight',
        };
      case error:
        return {
          title: capitalize(t('error')),
          icon: <Close color={'white'} size={10} />,
          color: 'borderDanger',
          borderColor: 'borderDanger',
          backgroundColor: 'surfaceDangerLight',
        };
      default:
        return {
          title: capitalize(t('info')),
          icon: <InfoAlert color={'white'} size={10} />,
          color: 'borderInfo',
          borderColor: 'borderInfo',
          backgroundColor: 'surfaceInfoLight',
        };
    }
  }, [warning, t, error]);

  if (state.dismissed) {
    return null;
  }

  return (
    <StyledAlertCard
      paper01
      paddingHorizontal_100
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={sticky ? undefined : 1}
      alignItems={'stretch'}
      $sticky={sticky}
      {...props}
    >
      <Box fit alignItems={'center'} paddingVertical_050 marginVertical_025>
        <Box marginRight_050>
          <StyledAlertIconWrapper backgroundColor={color} center marginRight_025>
            {icon}
          </StyledAlertIconWrapper>
        </Box>

        {Children.hasOnlyText(children) ? (
          <>
            <Text typography={'CaptionSmall'}>
              <Text typography={'CaptionSmall'} weight={'600'}>
                {title}
              </Text>
              : {children}
            </Text>
          </>
        ) : (
          children
        )}
      </Box>

      {hasLink &&
        (linkTo ? (
          <Anchor href={linkTo}>{linkText}</Anchor>
        ) : (
          <Button tertiary suppressPadding onClick={onLinkClick}>
            {linkText}
          </Button>
        ))}

      {hasLink && canBeDismissed && (
        <Box center>
          <StyledAlertDivider backgroundColor={color} />
        </Box>
      )}

      {canBeDismissed && (
        <Box center marginVertical_050 paddingVertical_025>
          <StyledAlertCloseButton onClick={dismiss} aria-label={t('close')}>
            <Close />
          </StyledAlertCloseButton>
        </Box>
      )}
    </StyledAlertCard>
  );
};
