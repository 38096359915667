import React, { useCallback } from 'react';

import { Button } from '@components/common/CTA';
import {
  DynamicTableEmpty,
  DynamicTableEmptyHeader,
  DynamicTableEmptyText,
} from '@components/common/DynamicTable/DynamicTableEmpty';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeClearAppliedFiltersAction } from '@redux/YardsFilters/actions';

export const YardsListEmpty: React.VFC<{ visible: boolean }> = ({ visible }) => {
  const t = useTranslation();
  return (
    <DynamicTableEmpty visible={visible} backgroundColor="surfaceDefault">
      <DynamicTableEmptyHeader>{t('whiteboard_empty_title')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText dangerouslySetInnerHTML={{ __html: t('whiteboard_empty_text') }} />
    </DynamicTableEmpty>
  );
};

export const YardsListEmptyWithFilters: React.VFC<{ visible: boolean }> = ({ visible }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const clearFilters = useCallback(() => dispatch(makeClearAppliedFiltersAction()), [dispatch]);

  return (
    <DynamicTableEmpty visible={visible}>
      <DynamicTableEmptyHeader>{t('no_yards_msg')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText>
        <Button tertiary onClick={clearFilters} suppressPadding>
          {t('clear_filters')}
        </Button>
        {t('clear_filters_cont')}
      </DynamicTableEmptyText>
    </DynamicTableEmpty>
  );
};
