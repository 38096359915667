import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { BoxRef } from '@components/common/Box/types';
import { Button } from '@components/common/CTA';
import { Hide } from '@components/common/Icon/presets/Hide';
import { Show } from '@components/common/Icon/presets/Show';
import { Loading } from '@components/common/Loading';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputText } from '@components/form/inputs/InputText';
import APP from '@config/constants';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeAuthSignInThunk } from '@redux/Auth/actions';

import { StyledAuthActions, StyledAuthTitle, StyledAuthTitleHighlight, StyledFormWrapper } from './styles';

export const LoginForm: React.VFC<{ onForgotPasswordClick: () => void }> = ({ onForgotPasswordClick }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const letsTalkModal = useDynamicModal();

  const [error, setError] = useState<string | null>(null);

  const { isFetchingSignIn } = useSelector((state) => state.authReducer);

  const formWrapperRef = useRef<BoxRef<'div'>>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [hasBrowserAutoFill, setHasBrowserAutoFill] = useState(false);

  const form = useForm({
    defaultValues: useFormDefaultValues({ username: '', password: '' }),
    resolver: useFormSchemaResolver<{ username: string; password: string }>((schema) => ({
      username: schema.string().required(),
      password: schema.string().required(),
    })).resolver,
  });

  const canSubmit = !isFetchingSignIn && (form.formState.isValid || hasBrowserAutoFill);

  useEffect(() => {
    // After any change, the browser autofill is invalidated.
    setHasBrowserAutoFill(false);
  }, [form.formState.isDirty]);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((curr) => !curr);
  }, []);

  const openLetsTalkModal = useCallback(() => {
    letsTalkModal.open({
      headerProps: {
        title: t('login_lets_talk_modal_title'),
        subtitle: t('login_lets_talk_modal_message'),
      },
      footerProps: {
        acceptText: t('login_lets_talk_modal_button'),
        autoCloseOnAccept: true,
        onAcceptClick: () => {
          window.open(APP.externalLinks.REQUEST_DEMO_PAGE, '_blank');
        },
      },
    });
  }, [letsTalkModal, t]);

  const attemptToSignIn = useCallback(async () => {
    const error = await dispatch(makeAuthSignInThunk(form.getValues()));
    const errorMessage = (error?.detail?.error ?? [])[0];
    if (errorMessage) {
      setError(t(errorMessage));
    }
  }, [dispatch, form, t]);

  // This effect is a workaround for the browser autocomplete
  // behavior. For a detailed explanation, please refer to:
  // https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
  useLayoutEffect(() => {
    setTimeout(() => {
      try {
        const input = formWrapperRef.current?.querySelector('[name="username"]');
        if (input?.matches(':-internal-autofill-selected')) {
          setHasBrowserAutoFill(true);
        }
      } catch (_) {
        /** Ignore if the selector is not available */
      }
    }, 200 /** Arbitrary delay to wait the browser autofill */);
  }, []);

  return (
    <>
      <Box column alignItems={'flex-start'} justifyContent={'center'}>
        <Box relative gap_050 column>
          <ContentChangeTransition content={error}>
            {(error) => (
              <AlertCard marginBottom_150 error>
                {error}
              </AlertCard>
            )}
          </ContentChangeTransition>

          <StyledAuthTitle>
            <span dangerouslySetInnerHTML={{ __html: t('login_title_p1') }} />{' '}
            <StyledAuthTitleHighlight>{t('login_title_p2')}</StyledAuthTitleHighlight>
          </StyledAuthTitle>

          <Text typography={'SmallParagraph'}>{t('login_subtitle')}</Text>
        </Box>

        <StyledFormWrapper ref={formWrapperRef}>
          <Form form={form} onValidSubmit={attemptToSignIn} column marginTop_150 gap_050>
            <InputText
              name={'username'}
              label={t('email')}
              placeholder={t('login_email_placeholder')}
              required
              autoFocus
            />
            <InputText
              name={'password'}
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              placeholder={t('login_password_placeholder')}
              required
              outerRightContent={
                <Box marginLeft_100>
                  <Button type={'button'} suppressPadding onClick={togglePasswordVisibility}>
                    {showPassword ? <Show size={24} /> : <Hide size={24} />}
                  </Button>
                </Box>
              }
            />

            <StyledAuthActions alignItems={'center'} marginTop_150 gap_150>
              <Button type={'submit'} disabled={!canSubmit} primary>
                {t('login_button')}
                <Loading size={'24px'} visible={isFetchingSignIn} blurBackground />
              </Button>
              <Button type={'button'} tertiary suppressPadding onClick={onForgotPasswordClick}>
                {t('login_forgot_password')}
              </Button>
            </StyledAuthActions>
          </Form>
        </StyledFormWrapper>
      </Box>

      <Box alignItems={'flex-end'} gap_025>
        <Text typography={'SmallParagraph'}>{t('login_bottom_message')}</Text>
        <Button onClick={openLetsTalkModal} tertiary suppressPadding>
          {t('login_bottom_message_link')}
        </Button>
      </Box>

      {letsTalkModal.content}
    </>
  );
};
