import styled, { css } from 'styled-components';

import { InputBase } from '@components/form/core/InputBase';

export const StyledLabel = styled.label<{ isRequired?: boolean }>(
  ({ theme, isRequired }) => css`
    line-height: 16px;
    font-size: 0.75rem;
    color: ${theme.colors.contentSecondary};

    &::after {
      ${isRequired && 'content: "*"'}
    }
  `
);

export const InputTextStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.contentPrimary};
    line-height: 22px;
    font-size: 1rem;
    font-weight: 600;
    font-family: inherit;
  `
);
export const InputStyles = css(
  ({ theme }) => css`
    border: none;
    outline: none;
    width: 100%;
    padding: 0;
    background-color: inherit;

    :disabled {
      color: ${theme.colors.contentDisabled};
      background-color: inherit;
    }

    ::placeholder {
      color: ${theme.colors.contentTertiary};
    }
  `
);

export const StyledInput = styled(InputBase<any>)<{ $suppressResizing?: boolean }>(
  ({ $suppressResizing }) => css`
    resize: ${$suppressResizing ? 'none' : 'default'};
    ${InputTextStyles}
    ${InputStyles}
  `
);

export const StyledInputLeftContent = styled.span(
  () => css`
    ${InputTextStyles}
  `
);

export const StyledWrapper = styled.div<{ hasError: boolean; disabled?: boolean }>(
  ({ theme, hasError, disabled }) => css`
    background-color: ${disabled ? theme.colors.surfaceComponentDisabled : theme.colors.surfaceSecondary};
    border: 1px solid ${theme.colors.borderSecondary};
    padding: 10px ${theme.spacing._100};
    ${hasError && `border-color: ${theme.colors.borderDanger};`}

    &:focus,
    &:focus-within {
      border-color: ${theme.colors.borderFocus};
      outline: none;
    }
  `
);

export const StyledHintText = styled.p(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 1rem;
    color: ${theme.colors.contentTertiary};
    margin-top: ${theme.spacing._025};

    &:not(:last-child) {
      margin-bottom: ${theme.spacing._025};
    }
  `
);
export const StyledErrorMessage = styled(StyledHintText)(
  ({ theme }) => `
      color: ${theme.colors.contentDanger};
      margin-top: ${theme.spacing._025};
    `
);
