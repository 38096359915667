import styled from 'styled-components';

import { Button } from '@components/common/CTA';
import { from, until } from '@style/mediaQueries';

export const StyledMapToolbarIconButton = styled.button<{ active?: boolean; disabled?: boolean }>(
  ({ theme, active }) => `
  width: 32px;
  height: 32px;
  border-radius: 4px; /* Design is not following the theme. */
  margin: 0 ${theme.spacing._100};
 
  background-color: ${active ? theme.primitives.colors.grey03 : theme.primitives.colors.white};
  
  &:hover {
    background-color: ${theme.primitives.colors.grey03};
    path {
      fill: ${theme.primitives.colors.grey06};
    }
  }
  
  &[disabled] {
    background-color: transparent;
    path {
      fill: ${theme.primitives.colors.grey05};
    }
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02}; 
  }
`
);

export const StyledMapToolbarButton = styled.button(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 ${theme.spacing._100}; 
  text-transform: uppercase;
  background-color: ${theme.primitives.colors.grey08};
  color: ${theme.primitives.colors.white};
  border-radius: ${theme.shape.paperRadius01}px;
  
  &:hover {
    color: ${theme.primitives.colors.grey05};
  
    path {
      fill: ${theme.primitives.colors.grey05};
    }
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02}; 
  }
  
  &[disabled] {
    background-color: ${theme.primitives.colors.grey05};
    color: ${theme.primitives.colors.grey06};
  }
`
);

export const StyledMapToolbarButtonDone = styled(Button)(
  () => `

  margin-right: 2.5rem; /** Aligned with the NavBar button. */
`
);

export const StyledMapToolbarButtonCancel = styled(StyledMapToolbarButton)(
  ({ theme }) => `
  width: 98px;
  margin-right: ${theme.spacing._100};
  
  background-color: ${theme.primitives.colors.white};
  color: ${theme.primitives.colors.grey08};
  
  &:hover {
    color: ${theme.primitives.colors.grey06};
  }
  
  &[disabled] {
    background-color: ${theme.primitives.colors.white};
    color: ${theme.primitives.colors.grey06};
  }
`
);

export const StyledMapToolbarIconButtonGroup = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  
  height: 100%;
  padding: 0 ${theme.spacing._050};
  
  border-right: 1px solid ${theme.primitives.colors.grey04};
  &:first-child {
    border-left: 1px solid ${theme.primitives.colors.grey04};
  }
`
);

export const StyledMapToolbarContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  height: 100%;
  
  ${until('tablet')} {
    &:nth-child(2) {
      flex: none;
    }
  }
  
  ${from('desktopSM')} {
    &:first-child {
      border-right: 1px solid ${theme.primitives.colors.grey04};
    }
  }
  
  &:last-child {
    justify-content: flex-end;
  }
`
);
