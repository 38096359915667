import { useCallback } from 'react';

import { ColDefWithMetadata, ColumnType } from '@components/yard/YardsList/types';
import { usePractices } from '@redux/Practices/hooks';

export function useColumnVisibilityChecker(showDisabledPractices?: boolean) {
  const { getPractice, getAction } = usePractices();

  return useCallback(
    (colDef: ColDefWithMetadata) => {
      if (showDisabledPractices) {
        return true;
      }
      if (colDef.metadata.column.type === ColumnType.PRACTICE) {
        const action = getAction(colDef.metadata.column.practice.id);
        return !!action && action.isActive && !action.isDeleted;
      } else if (colDef.metadata.column.type === ColumnType.PRACTICE_CATEGORY) {
        const practice = getPractice(colDef.metadata.column.practiceCategory.id);
        return !!practice && practice.isActive && !practice.isDeleted;
      }
      return true;
    },
    [getAction, getPractice, showDisabledPractices]
  );
}
