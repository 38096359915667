import React, { useLayoutEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { BoxProps } from '@components/common/Box/types';
import { Children } from '@helpers/Children';

import { StyledPagesContainer, StyledPagesItem } from './styles';

const HTML_AUTOFOCUSABLE_ELEMENTS = ['input', 'textarea'];

export interface StepsPagesProps extends BoxProps<'div'> {
  currentStep: number;
}

export const StepsPages: React.VFC<StepsPagesProps> = ({ currentStep, children, ...boxProps }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  // This will make sure that elements within disabled
  // pages won't receive focus.
  useLayoutEffect(() => {
    const container = containerRef.current;
    const pages = Array.from(container?.children ?? []);
    if (!container) return;

    const firstfocusableElement = Array.from(
      pages[currentStep].querySelectorAll(HTML_AUTOFOCUSABLE_ELEMENTS.join(','))
    )[0];

    setTimeout(() => {
      firstfocusableElement && (firstfocusableElement as HTMLInputElement).focus();
    }, theme.animations.durationLong);
  }, [currentStep, theme.animations.durationLong]);

  return (
    <StyledPagesContainer ref={containerRef} {...boxProps}>
      {Children.asList(children).map((child, index) => (
        <StyledPagesItem key={index} $index={index} $currentStep={currentStep}>
          {child}
        </StyledPagesItem>
      ))}
    </StyledPagesContainer>
  );
};
