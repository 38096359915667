import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading } from '@components/common/Loading';
import { Modal } from '@components/common/Modal';
import APP from '@config/constants';
import { deepClone } from '@helpers/deprecated/array';
import { URLUtil } from '@helpers/URL';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeCloseDeleteYardModalAction,
  makeDeleteYardRequestThunk,
  resetYardData,
  setEditModeAction,
  setRemoveMultipleYards,
} from '@redux/deprecated/actions';
import theme from '@style/theme';

import i18n from '../../../i18n';

// nectar:
import { DeleteYardModalView } from './DeleteYardModalView';

const modalInitialState = {
  title: 'cancel_changes',
  instructions: i18n.t('cancel_changes_instructions'),
  nextButton: 'yes_cancel',
  backButton: 'keep_editing',
  isLoading: false,
};

class DeleteYardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...modalInitialState,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { contentObj, t } = this.props;

    if (contentObj !== prevProps.contentObj) {
      if (contentObj && contentObj.yard) {
        const { name } = contentObj.yard;
        const { modalType } = contentObj;

        switch (modalType) {
          case 'remove-yard':
          case 'remove-yards':
            return this.setState({
              title: 'remove_yard',
              instructions: (
                <>
                  {t('remove_yard_instructions')}
                  <span style={{ fontWeight: theme.fontWeightBold }}>{name}</span>
                  {t('remove_yard_instructions_cont')}
                </>
              ),
              nextButton: 'delete',
              backButton: 'cancel',
            });
          case 'cancel-changes':
            return this.setState({ ...modalInitialState });
          default:
            break;
        }
      }
    }
  }

  handleClose() {
    const { closeDispatch, history } = this.props;
    const { search } = history.location;
    closeDispatch();
    this.setState({
      ...modalInitialState,
    });
    if (search) {
      history.push({
        pathname: history.location.pathname,
      });
    }
  }

  async handleSubmit() {
    const {
      contentObj,
      closeDispatch,
      setRemoveMultipleYards,
      setEditModeAction,
      resetYardData,
      makeDeleteYardRequestThunk,
      yards,
      history,
    } = this.props;

    let yards_array = [];
    const { modalType, yardIdsToRemove, onDelete } = contentObj;

    yardIdsToRemove &&
      yardIdsToRemove.forEach((removedYardId) => {
        yards_array = yards.filter((y) => y.id !== removedYardId);
      });

    switch (modalType) {
      case 'remove-yards':
        setRemoveMultipleYards(yardIdsToRemove, yards_array);
        closeDispatch();
        break;
      case 'remove-yard':
        this.setState({ isLoading: true });
        await makeDeleteYardRequestThunk(contentObj.yard);
        closeDispatch();
        onDelete && onDelete();
        this.setState({ isLoading: false });
        break;
      case 'cancel-changes':
        resetYardData();
        closeDispatch();
        setEditModeAction(false);
        history.push(URLUtil.buildPagePath(APP.routes.yardsMap));
        break;
      default:
        break;
    }
  }

  handleCancel() {
    const { contentObj, setRemoveMultipleYards, closeDispatch, yards } = this.props;
    const { modalType, yardIdsToRemove, onDelete } = contentObj;

    switch (modalType) {
      case 'remove-yard':
        this.setState({ ...modalInitialState });
        closeDispatch();
        break;
      case 'remove-yards':
        let array = deepClone(yardIdsToRemove);
        array.pop();
        setRemoveMultipleYards(array, yards);
        closeDispatch();
        break;
      case 'cancel-changes':
        closeDispatch();
        break;
      default:
        break;
    }
  }

  render() {
    const { t, isDeleteYardOpen, isMobile, contentObj } = this.props;

    const { title, instructions, nextButton, backButton, isLoading } = this.state;

    const modalObj = {
      title,
      instructions,
      nextButton,
      backButton,
    };

    const isLoadingVisible = !isDeleteYardOpen || !contentObj.yard || isLoading;

    return (
      <Modal isOpen={isDeleteYardOpen} onRequestClose={this.handleClose} nonDismissible>
        <DeleteYardModalView
          t={t}
          modalObj={modalObj}
          isMobile={isMobile()}
          contentObj={{
            modalType: contentObj.modalType,
          }}
          handleSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          isLoading={isLoadingVisible}
        />
        {isLoadingVisible && <Loading whiteBackground />}
      </Modal>
    );
  }
}
/**
 *
 * @param dispatch
 * @returns {{closeDispatch: makeCloseDeleteYardModalAction}}
 * @returns {{setRemoveMultipleYards: setRemoveMultipleYards}}
 * @returns {{setEditModeAction: setEditModeAction}}
 * @returns {{resetYardData: resetYardData}}
 * @returns {{makeDeleteYardRequestThunk: makeDeleteYardRequestThunk}}
 *
 *
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseDeleteYardModalAction());
  },
  setRemoveMultipleYards: (remove, yards) => {
    dispatch(setRemoveMultipleYards(remove, yards));
  },
  setEditModeAction: (editMode) => {
    dispatch(setEditModeAction(editMode));
  },
  resetYardData: () => {
    dispatch(resetYardData());
  },
  makeDeleteYardRequestThunk: (content) => {
    return dispatch(makeDeleteYardRequestThunk(content));
  },
});

/**
 *
 * @param state
 * @returns {{isDeleteYardOpen: (*|boolean)}}
 */
const mapStateToProps = (state) => ({
  isDeleteYardOpen: state.modalReducer.isDeleteYardOpen,
  contentObj: state.modalReducer.contentObj,
  yards: state.beeTrackYardListReducer.yards,
});

DeleteYardModal.propTypes = {
  isDeleteYardOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(DeleteYardModal)))
);
