import { RefObject, useLayoutEffect } from 'react';
import { debounce } from 'lodash';

import { StyledTimelineDayMark } from '@components/timeline/TimelineDayMark/styles';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';

export function useTimelineAnalytics(scrollElementRef: RefObject<HTMLElement>) {
  const dateUtil = useDateUtil();

  // Special analytics event to show how many days the user have scrolled.
  useLayoutEffect(() => {
    let lastSentDaysOffset = -1;
    const handleScroll = debounce(() => {
      if (!scrollElementRef.current) {
        return;
      }

      const scrollRect = scrollElementRef.current.getBoundingClientRect();
      const dayMarks = Array.from<HTMLElement>(
        scrollElementRef.current.querySelectorAll(StyledTimelineDayMark.toString())
      );
      const visibleDayMarks = dayMarks.filter((dayMark) => {
        const dayMarkRect = dayMark.getBoundingClientRect();
        const visibleHeight = Math.max(Math.min(scrollRect.bottom - dayMarkRect.top, dayMarkRect.height), 0);
        return visibleHeight / dayMarkRect.height >= 0.99; // At least 99% of the dayMark should be visible.
      }, []);

      const bottommostVisibleDayMark = visibleDayMarks.pop();
      const bottommostDayMarkTimestamp = bottommostVisibleDayMark?.getAttribute('data-timestamp');
      if (!bottommostDayMarkTimestamp) {
        return;
      }

      const dayOffset = dateUtil
        .getLocalizedDate(new Date())
        .startOf('day')
        .diff(dateUtil.getLocalizedDate(bottommostDayMarkTimestamp).startOf('day'), 'days', false);

      if (dayOffset !== lastSentDaysOffset) {
        lastSentDaysOffset = dayOffset;

        Analytics.sendEvent({
          event: AnalyticsEventType.TIMELINE_DAY_OFFSET_VIEW,
          eventData: {
            day_offset: dayOffset,
            ...Analytics.getPageData(),
          },
        });
      }
    }, 2000);
    scrollElementRef.current?.addEventListener('scroll', handleScroll);
  }, [dateUtil, scrollElementRef]);
}
