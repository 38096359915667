import React from 'react';

import { StyledLoading, StyledLoadingWrapper } from './styles';

export interface LoadingProps {
  visible?: boolean;
  whiteLoader?: boolean;
  blurBackground?: boolean;
  whiteBackground?: boolean;
  defaultBackground?: boolean;
  roundedCorners?: boolean;
  size?: string;
  delay?: number;
}

export const Loading: React.VFC<LoadingProps> = ({
  size = '2.5rem',
  visible,
  whiteLoader,
  blurBackground,
  whiteBackground,
  defaultBackground,
  roundedCorners,
  delay,
}) => {
  return (
    <StyledLoadingWrapper
      $visible={visible}
      $whiteLoader={whiteLoader}
      $blurBackground={blurBackground}
      $whiteBackground={whiteBackground}
      $defaultBackground={defaultBackground}
      $roundedCorners={roundedCorners}
      $size={size}
      $delay={delay}
    >
      <StyledLoading size={size} />
    </StyledLoadingWrapper>
  );
};
