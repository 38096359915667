import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledTabButton = styled.button<{ $active: boolean }>(
  ({ theme, $active }) => css`
    flex: 1;
    height: 56px;
    border-top: ${theme.primitives.colors.grey05} 0.5px solid;

    transition: ${theme.animations.transitionMedium('border')};

    ${$active
      ? css`
          color: ${theme.primitives.colors.grey08};
          border-bottom: ${theme.primitives.colors.grey08} 2px solid;
        `
      : css`
          color: ${theme.primitives.colors.grey06};
          border-bottom: ${theme.primitives.colors.grey05} 0.5px solid;
        `}
    &:hover {
      background-color: ${theme.primitives.colors.grey01};
    }
  `
);

export const StyledTabsWrapper = styled(Box)(
  () => css`
    position: relative;
    overflow: hidden;
  `
);

export const StyledTabContent = styled(Box)<{ $isHiddenAtLeft: boolean; $isHiddenAtRight: boolean }>(
  ({ theme, $isHiddenAtLeft, $isHiddenAtRight }) => css`
    position: absolute;
    inset: 0;
    pointer-events: all;
    transform: translateX(0);
    transition: ${theme.animations.transitionMedium('transform')};

    ${$isHiddenAtLeft &&
    css`
      pointer-events: none;
      transform: translateX(-100%);
    `}
    ${$isHiddenAtRight &&
    css`
      pointer-events: none;
      transform: translateX(100%);
    `}
  `
);
