import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Profile = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <path
        fill={useColor(color)}
        d="M11.7176 5.26759C11.7176 7.44042 9.95615 9.20184 7.78333 9.20184C5.6105 9.20184 3.84908 7.44042 3.84908 5.26759C3.84908 3.09477 5.6105 1.33334 7.78333 1.33334C9.95615 1.33334 11.7176 3.09477 11.7176 5.26759Z"
      />
      <path
        fill={useColor(color)}
        d="M2 14.7098H13.5666C13.0199 12.0161 10.6384 9.98866 7.78332 9.98866C4.92829 9.98866 2.54679 12.0161 2 14.7098Z"
      />
    </IconSVGView>
  );
};
