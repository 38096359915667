import React, { useEffect, useState } from 'react';

import { CheckboxProps } from '../../types';

import { RadioButtonHiddenLabel, RadioButtonHover, RadioButtonInput, RadioButtonWrapper } from './styles';

export const RadioButton: React.FC<CheckboxProps> = ({
  name,
  id,
  value,
  onChange,
  style = {},
  hiddenLabelText,
  disabled,
  disableHoverEffect,
}) => {
  const [isChecked, setIsChecked] = useState(value);
  useEffect(() => {
    if (value !== isChecked) {
      setIsChecked(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <RadioButtonWrapper style={style}>
      {!disabled && !disableHoverEffect && <RadioButtonHover />}
      <RadioButtonInput
        ref={(ref) => {
          if (!ref) return;

          ref.onclick = (e) => {
            e.stopPropagation();
          };
          // @todo fix this 'any' to be the correct type!
          ref.onchange = (e: any) => {
            onChange(e.target.checked, name);
          };
        }}
        id={id}
        name={name}
        type="radio"
        checked={isChecked}
        disabled={disabled}
        onChange={() => {}}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      {hiddenLabelText && <RadioButtonHiddenLabel>{hiddenLabelText}</RadioButtonHiddenLabel>}
    </RadioButtonWrapper>
  );
};
