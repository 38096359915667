import React from 'react';
import { useSelector } from 'react-redux';

import { AlertCard } from '@components/common/AlertCard';
import { DataStatusTabDot } from '@components/common/DataStatusTabDot';
import { Tab, Tabs } from '@components/common/Tabs';
import { YardDetailsActivities } from '@components/yard/YardDetailsActivities';
import { YardDetailsDashboard } from '@components/yard/YardDetailsDashboard';
import APP from '@config/constants';
import { useDateUtil } from '@helpers/Date/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { useYardTimelineDataStatus } from '@redux/DataStatus/hooks';
import { usePractices } from '@redux/Practices/hooks';

import { DeadoutsListPage } from '../HivesList/DeadoutsListPage';
import { HivesListPage } from '../HivesList/HivesListPage';

export const YardDetailsPage: React.VFC = () => {
  const t = useTranslation();

  const { isFetching: isFetchingPractices } = usePractices();

  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const dataStatus = useYardTimelineDataStatus(yard?.id ?? -1);
  const dateUtil = useDateUtil();

  if (!yard || isFetchingPractices) {
    return null;
  }

  const deletedWarning = yard.deletedAt ? (
    <AlertCard sticky warning>
      {t('yard_deleted_warning', { date: dateUtil.getFormattedDate(yard.deletedAt, 'MMM DD, YYYY') })}
    </AlertCard>
  ) : null;

  return (
    <Tabs useURLPath>
      <Tab id={'dashboard'} title={t('dashboard')} path={APP.routes.whiteboardYardsDetailDashboard}>
        {deletedWarning}
        <YardDetailsDashboard />
      </Tab>

      {!yard.deletedAt && (
        <>
          <Tab id={'hive'} title={t('Hives')} path={APP.routes.whiteboardYardsDetailHives}>
            <HivesListPage />
          </Tab>
          <Tab id={'deadout'} title={t('deadouts')} path={APP.routes.whiteboardYardsDetailDeadouts}>
            <DeadoutsListPage />
          </Tab>
        </>
      )}

      <Tab
        id={'activities'}
        title={
          <>
            {t('activities')}
            <DataStatusTabDot tabKey={'activities'} dataStatus={dataStatus} />
          </>
        }
        path={APP.routes.whiteboardYardsDetailActivities}
        preLoad
      >
        {deletedWarning}
        <YardDetailsActivities />
      </Tab>
    </Tabs>
  );
};
