import React from 'react';

import { RadioButtonProps } from '../../types';

import { RadioButtonHover, RadioButtonInput, RadioButtonWrapper } from './styles';

export const RadioButtonV2: React.FC<RadioButtonProps> = ({
  onChange,
  style = {},
  selectedOptionValue,
  value,
  ...radioButtonProps
}) => {
  return (
    <RadioButtonWrapper style={style}>
      {!radioButtonProps.disabled && <RadioButtonHover />}
      <RadioButtonInput
        ref={(ref) => {
          if (!ref) return;

          ref.onclick = (e) => {
            e.stopPropagation();
          };
          // @todo fix this 'any' to be the correct type!
          ref.onchange = (e: any) => {
            onChange(value, radioButtonProps.name);
          };
        }}
        type="radio"
        onChange={() => {}}
        checked={selectedOptionValue === value}
        value={value}
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...radioButtonProps}
      />
    </RadioButtonWrapper>
  );
};
