import { Reducer } from 'redux';

export type DataStatusReducer = Reducer<DataStatusState, DataStatusAction>;

export type DataStatusAction =
  | {
      type: DataStatusActionType.DATA_STATUS_NOTIFY_UPDATED;
      payload: { key: string; timestamp: number };
    }
  | {
      type: DataStatusActionType.DATA_STATUS_NOTIFY_OUTDATED;
      payload: { key: string; timestamp: number };
    };

export interface DataStatusState {
  // Stores the sequence of data statuses by feature/screen.
  statuses: Record<string, Array<DataStatus>>;
}

export interface DataStatus {
  type: DataStatusType;
  timestamp: number;
}

export interface DataStatusFilters {
  yardId?: number;
}

export enum DataStatusType {
  DATA_UPDATED = 'DATA_UPDATED',
  DATA_OUTDATED = 'DATA_OUTDATED',
}

export enum DataStatusActionType {
  DATA_STATUS_NOTIFY_UPDATED = 'DATA_STATUS_NOTIFY_UPDATED',
  DATA_STATUS_NOTIFY_OUTDATED = 'DATA_STATUS_NOTIFY_OUTDATED',
}
