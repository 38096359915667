import { ColDef } from 'ag-grid-community';

import { generateDefaultStaticColDef } from '@components/common/DynamicTable/constants';
import { ColDefWithMetadata } from '@components/common/DynamicTable/types';
import { CaseAdapter } from '@helpers/CaseAdapter';

import { SelectAllCheckbox } from './cells/SelectAllCheckbox';

const DEF_HIVE_NAME_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'hive',
  pinned: 'left',
  maxWidth: 140,
  lockPosition: true,
  metadata: {},
};

const DEF_STATUS_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'status',
  metadata: {},
};
const DEF_HEALTH_SCORE_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'healthScore',
  metadata: {},
};
const DEF_LAST_FEEDING_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'lastFeeding',
  metadata: {},
};
const DEF_LAST_TREATMENT_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'lastTreatment',
  metadata: {},
};
const DEF_GRADING_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'grading',
  maxWidth: 140,
  metadata: {},
};
const DEF_ARRIVED_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'arrived',
  metadata: {},
};
const DEF_DIED_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'died',
  metadata: {},
};
const DEF_LOST_SINCE_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'lostSince',
  maxWidth: 150,
  metadata: {
    activeView: 'default',
    column: {
      views: {
        default: {
          key: 'default',
          sortableKey: 'lost_since',
        },
      },
    },
  },
};
const DEF_LAST_INSPECTED_BY_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'lastInspectedBy',
  metadata: {},
};
const DEF_PREVIOUS_YARD_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'previousYard',
  metadata: {},
};

// this.... is not super user friendly lol.
// todo, make a function for static columns to generate this?

const DEF_MENU_COLUMN_DEF = generateDefaultStaticColDef({
  field: 'menu',
  headerName: '',
  lockPosition: 'right',
  flex: 1,
  suppressAutoSize: true,
});

// Workaround so that the last column spans to the end of the table
const DEF_FILL_TABLE_RIGHT_COLUMN_DEF = generateDefaultStaticColDef({
  field: 'fillTableRight',
  headerName: '',
  lockPosition: 'right',
  flex: 1,
  suppressAutoSize: true,
});

// Workaround so that the last column spans to the end of the table
const DEF_SELECT_COLUMN_DEF = generateDefaultStaticColDef({
  field: 'select',
  pinned: 'left',
  lockPosition: true,
  headerComponent: SelectAllCheckbox,
  headerName: '',
  maxWidth: 52,
  resizable: false,
  suppressAutoSize: true,
});

const HIVE_COLUMNS = [
  DEF_HIVE_NAME_COLUMN_DEF,
  DEF_STATUS_COLUMN_DEF,
  DEF_HEALTH_SCORE_COLUMN_DEF,
  DEF_LAST_FEEDING_COLUMN_DEF,
  DEF_LAST_TREATMENT_COLUMN_DEF,
  DEF_GRADING_COLUMN_DEF,
  DEF_ARRIVED_COLUMN_DEF,
  DEF_PREVIOUS_YARD_COLUMN_DEF,
];

const DEADOUTS_COLUMNS = [
  DEF_HIVE_NAME_COLUMN_DEF,
  DEF_STATUS_COLUMN_DEF,
  DEF_ARRIVED_COLUMN_DEF,
  DEF_DIED_COLUMN_DEF,
  DEF_LAST_FEEDING_COLUMN_DEF,
  DEF_LAST_TREATMENT_COLUMN_DEF,
  DEF_PREVIOUS_YARD_COLUMN_DEF,
];

const LOST_HIVES_COLUMNS = [
  DEF_HIVE_NAME_COLUMN_DEF,
  DEF_LOST_SINCE_COLUMN_DEF,
  DEF_HEALTH_SCORE_COLUMN_DEF,
  DEF_PREVIOUS_YARD_COLUMN_DEF,
  DEF_STATUS_COLUMN_DEF,
  DEF_LAST_FEEDING_COLUMN_DEF,
  DEF_LAST_TREATMENT_COLUMN_DEF,
  DEF_LAST_INSPECTED_BY_COLUMN_DEF,
  DEF_GRADING_COLUMN_DEF,
];

export const HIVE_PERMANENT_RIGHT_COL_DEFS = [DEF_FILL_TABLE_RIGHT_COLUMN_DEF];

export const DEADOUTS_PERMANENT_RIGHT_COL_DEFS = [DEF_FILL_TABLE_RIGHT_COLUMN_DEF];

export const LOST_HIVES_PERMANENT_LEFT_COL_DEFS = [DEF_SELECT_COLUMN_DEF]; // this will be select checkbox
export const LOST_HIVES_PERMANENT_RIGHT_COL_DEFS = [DEF_MENU_COLUMN_DEF];

export const HIVE_COL_DEFS: ColDefWithMetadata<any>[] = [...HIVE_COLUMNS, ...HIVE_PERMANENT_RIGHT_COL_DEFS];
export const DEADOUTS_COL_DEFS: ColDefWithMetadata<any>[] = [...DEADOUTS_COLUMNS, ...DEADOUTS_PERMANENT_RIGHT_COL_DEFS];
export const LOST_HIVES_COL_DEFS: ColDefWithMetadata<any>[] = [
  ...LOST_HIVES_PERMANENT_LEFT_COL_DEFS,
  ...LOST_HIVES_COLUMNS,
  ...LOST_HIVES_PERMANENT_RIGHT_COL_DEFS,
];

export const HIVE_COLS_KEYS = HIVE_COLUMNS.map((col) => CaseAdapter.toSnakeCase(col.field));
export const DEADOUTS_COLS_KEYS = DEADOUTS_COLUMNS.map((col) => CaseAdapter.toSnakeCase(col.field));
export const LOST_HIVES_COLS_KEYS = LOST_HIVES_COLUMNS.map((col) => CaseAdapter.toSnakeCase(col.field));
