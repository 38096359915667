import React, { useLayoutEffect, useRef } from 'react';

/**
 * Shortcut to assign onClick events when you don't want
 * it to be propagated to other elements up in the tree.
 * One good example of usage is the whiteboard, when you
 * have some click inside the cell and don't want it to
 * trigger the 'select row' table event.
 *
 * Usage example:
 *    const myClickHandler = useCallback(() => {...}, []);
 *    const buttonProps = useNonPropagatedOnClick<HTMLButtonElement>(myClickHandler);
 *    return <Button {...buttonProps}/>
 *
 * Note: the '<HTMLButtonElement>' generic type is important to
 * avoid typescript complaints.
 * */
export function useNonPropagatedOnClick<T extends Element = Element>(onClick?: React.MouseEventHandler<T>) {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const buttonElement = ref.current as any;
    if (buttonElement) {
      const handler: React.MouseEventHandler<T> = (e) => {
        e.stopPropagation();
        onClick && onClick(e);
      };
      buttonElement.addEventListener('click', handler);
      return () => buttonElement.removeEventListener('click', handler);
    }
  }, [onClick]);

  return { ref };
}
