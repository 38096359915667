import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { PracticesAction, PracticesActionType } from '@redux/Practices/types';

export const makePracticesFetchStartAction = (options?: {
  practiceId?: number;
  actionId?: number;
}): PracticesAction => ({
  type: PracticesActionType.PRACTICES_FETCH_START,
  payload: options,
});

export const makePracticesFetchFinishAction = (
  data: Record<number, BeePracticeDefinition> | null,
  error: ApiResponseError | null,
  options?: { practiceId?: number; actionId?: number }
): PracticesAction => ({
  type: PracticesActionType.PRACTICES_FETCH_FINISH,
  payload: { data, error, ...options },
});

export const makePracticesFetchOnceThunk = Thunk.createTakeFirst(() => {
  return async (dispatch, getState) => {
    const { isFetching, practiceDefinitions } = getState().practicesReducer;

    const shouldSkipFetch = isFetching || Object.values(practiceDefinitions).length;

    if (shouldSkipFetch) {
      return;
    }

    dispatch(makePracticesFetchStartAction());

    const response = await Api.get(ENDPOINTS.practices);

    if (response.error) {
      dispatch(makePracticesFetchFinishAction(null, response.error));
    } else {
      dispatch(makePracticesFetchFinishAction(parsePracticesFromAPI(await response.json()), null));
    }
  };
});

export const makePracticesFetchThunk = Thunk.createTakeFirst((options?: { practiceId?: number; actionId?: number }) => {
  return async (dispatch) => {
    dispatch(makePracticesFetchStartAction(options));

    const response = await Api.get(ENDPOINTS.practices);

    if (response.error) {
      dispatch(makePracticesFetchFinishAction(null, response.error, options));
    } else {
      dispatch(makePracticesFetchFinishAction(parsePracticesFromAPI(await response.json()), null, options));
    }
  };
});

function parsePracticesFromAPI(data: any): Record<number, BeePracticeDefinition> {
  return data
    .map(
      ({ category_id, category_flag, deleted, hive_data, actions, ...category }: any) =>
        ({
          ...CaseAdapter.objectToCamelCase(category),
          id: category_id,
          flag: category_flag,
          isDeleted: deleted,
          isHiveData: hive_data,
          actions: actions.map(({ action_id, detail, action_flag, deleted, ...action }: any) => ({
            ...CaseAdapter.objectToCamelCase(action),
            id: action_id,
            name: detail,
            flag: action_flag,
            isDeleted: deleted,
          })),
        } as BeePracticeDefinition)
    )
    .reduce(
      (acc: Record<number, BeePracticeDefinition>, item: BeePracticeDefinition) => ({ [item.id]: item, ...acc }),
      {}
    );
}
