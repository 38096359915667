import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import SeasonIcon from '@assets/Small_Hives.svg';
import { Box } from '@components/common/Box';
import { IconIMG } from '@components/common/Icon';
import { Line } from '@components/common/Line';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/inputs/CheckBox';
import { PreferencesSections } from '@components/operation/HiveStructureCard/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { makePatchOperationThunk } from '@redux/Operation/actions';
import { until } from '@style/mediaQueries';

const StyledPreferencesRow = styled(Box)(
  ({ theme }) => css`
    ${until('tablet')} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.spacing._050} 0;
    }
  `
);

export const HiveStructureCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const operation = useSelector((state) => state.operationReducer.operation);
  const preferences = operation?.preferences ?? {};
  const preferencesSections = useMemo<PreferencesSections>(
    () => [
      {
        title: 'hive_structure_frames_per_honey_supers',
        items: [
          'framesPerHoneySupers_Ten',
          'framesPerHoneySupers_Nine',
          'framesPerHoneySupers_Eight',
          'framesPerHoneySupers_Other',
        ],
      },
      {
        title: 'hive_structure_frame_base',
        items: ['frameBase_Plastic', 'frameBase_Wax', 'frameBase_Other'],
      },
      {
        title: 'hive_structure_honey_super_type',
        items: ['honeySuperType_Shallow', 'honeySuperType_Medium', 'honeySuperType_Deep', 'honeySuperType_Other'],
      },
      {
        title: 'hive_structure_brood_boxes',
        items: ['broodBoxes_One', 'broodBoxes_Two', 'broodBoxes_Three', 'broodBoxes_Other'],
      },
      {
        title: 'hive_structure_bottom_boards',
        items: ['bottomBoards_Solid', 'bottomBoards_Screen', 'bottomBoards_Other'],
      },
      {
        title: 'hive_structure_queen_excluder',
        items: ['queenExcluder_Metal', 'queenExcluder_Plastic', 'queenExcluder_None'],
      },
    ],
    []
  );

  const handlePreferenceChange = useCallback(
    (checked: boolean, name: keyof BeeOperationPreferences) => {
      dispatch(makePatchOperationThunk({ preferences: { [name]: checked } }));
    },
    [dispatch]
  );

  return (
    <Box paper02 column stretch>
      <Box paddingHorizontal_100 paddingVertical_050 alignItems={'center'}>
        <IconIMG src={SeasonIcon} size={18} marginLeft_050 />
        <Box margin_050>
          <Text typography={'Heading3'} weight={'600'}>
            {t('hives_structure')}
          </Text>
        </Box>
      </Box>

      <Line />

      <Box column stretch padding_100>
        <Box column stretch margin_050>
          {preferencesSections.map(({ title, items }, idx, arr) => (
            <Box column stretch key={idx}>
              <Text typography={'Heading3'} weight={'600'}>
                {t(title)}
              </Text>
              <StyledPreferencesRow alignItems={'center'} marginTop_050>
                {items.map((item: keyof BeeOperationPreferences) => (
                  <Box key={item} alignItems="center">
                    <Checkbox
                      id={item}
                      name={item}
                      value={preferences[item] ?? false}
                      onChange={(checked) => handlePreferenceChange(checked, item)}
                    />

                    <Box tag={'label'} htmlFor={item} marginLeft_025 marginRight_050 paddingLeft_025 paddingRight_100>
                      <Text htmlTag="span" typography={'SmallParagraph'} style={{ whiteSpace: 'nowrap' }}>
                        {t(`hive_structure_${CaseAdapter.toSnakeCase(item)}`)}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </StyledPreferencesRow>

              {idx < arr.length - 1 ? (
                <Box column stretch marginVertical_100>
                  <Line />
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
