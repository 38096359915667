import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

import { HeaderComponentParams, HeaderTitle } from '@components/common/DynamicTable/types';
import { useTranslation } from '@hooks/useTranslation';

export function useHiveColumnNameGetter(): HeaderComponentParams['getColumnDisplayName'] {
  const t = useTranslation();

  return useCallback(
    (columnDef: ColDef, defaultDisplayName: string) => {
      switch (columnDef.field) {
        case 'hive':
          return t('hive');
        case 'status':
          return t('status');
        case 'healthScore':
          return {
            title: t('health_score'),
            tooltip: t('health_score_hive_modal_header_tooltip'),
          } as HeaderTitle;
        case 'lastFeeding':
          return t('last_feed');
        case 'lastTreatment':
          return t('last_treatment');
        case 'grading':
          return t('grading');
        case 'arrived':
          return t('arrived');
        case 'previousYard':
          return t('previous_yard');
        case 'died':
          return t('died');
        case 'lostSince':
          return t('lost_since');
        case 'lastInspectedBy':
          return t('last_inspected_by');
        default:
          return defaultDisplayName;
      }
    },
    [t]
  );
}
