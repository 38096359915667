import React from 'react';
import { useTheme } from 'styled-components';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const StoragePin = ({ size }: IconSVGProps) => {
  const { colors } = useTheme();
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <rect width="16" height="16" rx="8" fill={colors.surfacePrimary} />
      <path
        d="M11.477 6.963 8.229 4.864a.338.338 0 0 0-.458 0L4.523 6.963a.228.228 0 0 0 .172.392h.724v3.548c0 .179.145.323.323.323h.645a.323.323 0 0 0 .323-.323v-2.58h2.58v2.58c0 .179.145.323.323.323h.645a.323.323 0 0 0 .323-.323V7.355h.724a.228.228 0 0 0 .172-.392Z"
        fill="#fff"
      />
      <path
        d="M7.032 10.42c0-.09.072-.162.162-.162H7.7c.09 0 .162.073.162.162v.507c0 .089-.072.16-.162.16h-.506a.161.161 0 0 1-.162-.16v-.507ZM8.138 10.42c0-.09.072-.162.162-.162h.506c.09 0 .162.073.162.162v.507c0 .089-.072.16-.162.16H8.3a.161.161 0 0 1-.162-.16v-.507ZM7.58 9.29c0-.088.073-.16.162-.16h.507c.089 0 .161.071.161.16v.507c0 .09-.072.162-.161.162h-.507a.161.161 0 0 1-.161-.162v-.506Z"
        fill="#fff"
      />
    </IconSVGView>
  );
};
