import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Item } from '@components/common/InputSelector';
import { InputDropdown } from '@components/common/InputSelector/InputDropdown';
import { LoadingWaves } from '@components/common/LoadingWaves';
import { Fieldset } from '@components/deprecated/Fieldset/Fieldset';
import { useContractSummaries } from '@hooks/useContract';

const StyledLoadingInput = styled.div`
  position: relative;
  height: 68px;
  > div {
    height: 100%;
    width: 100%;
  }
`;

interface InputContractProps {
  name: string;
  id?: string;
  contractId: number | undefined;
  onContractChange: (contractSummary: BeeContractSummary | null | undefined) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  hasError?: boolean;
}

export const InputContractAutocompleteBackwardCompatible = ({
  name,
  id = name,
  contractId,
  onContractChange,
  disabled,
  autoFocus,
  hasError,
}: InputContractProps) => {
  const { t } = useTranslation();
  const contractSummaries = useContractSummaries();
  const noContracts = !contractSummaries?.length;

  const handleContractChange = (key: string) => {
    if (contractSummaries) {
      const selectedContractSummary = contractSummaries.find((contract) => contract.id === Number(key));
      onContractChange(selectedContractSummary);
    }
  };

  if (!contractSummaries) {
    return (
      <StyledLoadingInput>
        <LoadingWaves loading />
      </StyledLoadingInput>
    );
  }

  return (
    <Fieldset error={!!hasError} disabled={disabled || noContracts}>
      <InputDropdown
        label={t('contract_name_input_label')}
        placeholder={t('contract_name_input_placeholder')}
        id={id}
        isDisabled={disabled || noContracts}
        name={name}
        selectedKey={contractId ? contractId.toString() : ''}
        onSelectedKeyChange={handleContractChange}
        autoFocus={autoFocus}
      >
        {contractSummaries.map((contract) => (
          <Item key={contract.id} title={contract.name}>
            {contract.name}
          </Item>
        ))}
      </InputDropdown>
    </Fieldset>
  );
};
