import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { HeaderAction, HeaderActions } from '@components/common/HeaderActions';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { EmptyYard } from '@components/common/Icon/presets/EmptyYard';
import { MenuItem } from '@components/common/Menu/types';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { YardStatusChip } from '@components/common/YardStatusChip';
import { useYardCreateOrUpdateModalOpener } from '@components/yard/YardCreateOrUpdateModal/hooks';
import { YardDetailsClearOutAndDeleteModal } from '@components/yard/YardDetails/YardDetailsClearOutAndDeleteModal';
import { YardName } from '@components/yard/YardName';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeYardDetailsFetchThunk } from '@redux/YardDetails/actions';

export const YardDetailsHeader: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const confirmDeleteModal = useDynamicModal();
  const confirmClearOutModal = useDynamicModal();

  const updateYardModal = useYardCreateOrUpdateModalOpener();

  const { detail } = useSelector((state) => state.yardDetailsReducer);

  const showEditYardButton = detail && !detail.deletedAt;
  const showClearOutYardButton = detail && !detail.deletedAt;
  const disableClearOutYardButton = !!detail && detail.nbHives === 0;
  const showDeleteYardButton = detail && detail.status !== 'UNLOADED' && !detail.deletedAt;

  const openEditYardModal = useCallback(() => {
    if (!detail) {
      return;
    }

    updateYardModal.openForUpdate(detail.id);
  }, [detail, updateYardModal]);

  const openClearOutYardModal = useCallback(() => {
    if (!detail) {
      return;
    }
    confirmClearOutModal.open();
  }, [detail, confirmClearOutModal]);

  const openDeleteYardModal = useCallback(() => {
    if (!detail) {
      return;
    }
    confirmDeleteModal.open();
  }, [detail, confirmDeleteModal]);

  const reloadYardDetails = useCallback(() => {
    if (!detail) {
      return;
    }
    dispatch(makeYardDetailsFetchThunk(detail.id));
  }, [detail, dispatch]);

  const menuItems = useMemo<Array<MenuItem>>(() => {
    const items: Array<MenuItem> = [];
    if (showClearOutYardButton) {
      items.push({
        title: t('clear_out_yard'),
        icon: EmptyYard,
        onClick: openClearOutYardModal,
        disabled: disableClearOutYardButton,
      });
    }
    if (showDeleteYardButton) {
      items.push({ title: t('delete_yard'), icon: Delete, danger: true, onClick: openDeleteYardModal });
    }
    return items;
  }, [
    showClearOutYardButton,
    showDeleteYardButton,
    t,
    openClearOutYardModal,
    disableClearOutYardButton,
    openDeleteYardModal,
  ]);

  const actions = useMemo<Array<HeaderAction>>(() => {
    const items: Array<HeaderAction> = [];
    if (showEditYardButton) {
      items.push({
        title: t('edit_yard'),
        icon: Edit,
        onClick: openEditYardModal,
      });
    }
    if (showClearOutYardButton) {
      items.push({
        title: t('clear_out_yard'),
        icon: EmptyYard,
        onClick: openClearOutYardModal,
        disabled: disableClearOutYardButton,
      });
    }
    if (showDeleteYardButton) {
      items.push({ title: t('delete_yard'), icon: Delete, danger: true, onClick: openDeleteYardModal });
    }
    return items;
  }, [
    showEditYardButton,
    showClearOutYardButton,
    showDeleteYardButton,
    t,
    openEditYardModal,
    openClearOutYardModal,
    disableClearOutYardButton,
    openDeleteYardModal,
  ]);

  if (!detail) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <Box fit alignItems={'center'} overflow={'hidden'} gap_100>
          <PageHeaderTitle fit={false} enableTruncate>
            <YardName name={detail.name} contractName={detail.contractName} suppressStyling />
          </PageHeaderTitle>
          <YardStatusChip {...detail} />
        </Box>
        {!!actions.length && <HeaderActions actions={actions} />}
      </PageHeader>

      <YardDetailsClearOutAndDeleteModal
        yardId={detail.id}
        {...confirmDeleteModal.modalProps}
        primaryAction={'delete'}
        onSuccess={reloadYardDetails}
      />
      <YardDetailsClearOutAndDeleteModal
        yardId={detail.id}
        {...confirmClearOutModal.modalProps}
        primaryAction={'clear-out'}
        onSuccess={reloadYardDetails}
      />
    </>
  );
};
