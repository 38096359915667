import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@components/common/Tooltip';
import { StyledAttentionDot, StyledText } from '@components/yard/AvgVisitsStatusDot/styles';
import { useLastInspectionSpecGetter } from '@config/LastInspectionConfig/hooks';
import { LastInspectionIntervalConfig } from '@config/LastInspectionConfig/types';

interface LastInspectionIntervalDotProps {
  lastInspectionDatetime: string | null;
}

export const LastInspectionIntervalDot: React.VFC<LastInspectionIntervalDotProps> = ({ lastInspectionDatetime }) => {
  const { t } = useTranslation();

  const getLastInspectionSpec = useLastInspectionSpecGetter();

  const spec = useMemo(
    (): LastInspectionIntervalConfig | null => getLastInspectionSpec({ lastInspectionDatetime }),
    [getLastInspectionSpec, lastInspectionDatetime]
  );

  if (!spec) {
    return null;
  }

  return (
    <StyledAttentionDot color={spec.color}>
      <Tooltip>
        <StyledText typography="CaptionSmall">
          {t(spec.tooltipLabelKey, { from: spec.interval[0], to: spec.interval[1] })}
        </StyledText>
      </Tooltip>
    </StyledAttentionDot>
  );
};
