import { ColDef, ColGroupDef, GridApi, SortDirection } from 'ag-grid-community';
import { produce } from 'immer';
import * as uuid from 'uuid';

import { DEF_COLUMN_MIN_WIDTH, DEF_PAGE_PARAM_NAME } from '@components/common/DynamicTable/constants';
import {
  ColDefMetadata,
  ColDefWithMetadata,
  Column,
  DynamicTableConfig,
  View,
  ViewKey,
} from '@components/common/DynamicTable/types';
import { QueryParams } from '@helpers/QueryParams';
import { Sorting } from '@helpers/Sorting';

export class DynamicTableUtil {
  constructor(private config: DynamicTableConfig) {}

  /**
   * Sets the given column definitions to the grid in case there
   * is any change.
   * */
  updateGridColumns(gridApi: GridApi, nextColumnDefs: Array<ColDef | ColGroupDef>) {
    const currentColumnDefs = gridApi.getColumnDefs() ?? [];
    const areColumnsEqual = this.areColumnDefsEqual(currentColumnDefs, nextColumnDefs);

    if (areColumnsEqual) {
      return false;
    }

    nextColumnDefs = this.getDefsWithPermanentColumns(nextColumnDefs);
    nextColumnDefs = this.getDefsWithDefaultProperties(nextColumnDefs);
    nextColumnDefs = this.getDefsWithAppliedSorting(nextColumnDefs);
    nextColumnDefs = this.getDefsWithGroupedColumns(nextColumnDefs);
    nextColumnDefs = this.getDefsWithoutUnnecessarilyGroupedColumns(nextColumnDefs);
    gridApi.setColumnDefs(nextColumnDefs);

    return true;
  }

  getDefsWithPermanentColumns(columnDefs: Array<ColDef>) {
    columnDefs = [...columnDefs];

    const columnDefsKeys = this.getFlattenColumnKeys(columnDefs) as Array<string | undefined>;
    const isColumnAlreadyAppended = (columnDef: ColDef) => columnDefsKeys.includes(columnDef.field);

    if (this.config.permanentLeftColumns) {
      for (const columnDef of this.config.permanentLeftColumns.reverse()) {
        !isColumnAlreadyAppended(columnDef) && columnDefs.unshift(columnDef);
      }
    }

    if (this.config.permanentRightColumns) {
      for (const columnDef of this.config.permanentRightColumns.reverse()) {
        !isColumnAlreadyAppended(columnDef) && columnDefs.push(columnDef);
      }
    }

    return columnDefs;
  }

  getPatchedColumnDefsUsingId(columnDefs: Array<ColDef | ColGroupDef>, patch: ColDef) {
    return this.getPatchedColumnDefs(columnDefs, patch, { patchUsingColumnKey: false });
  }

  getPatchedColumnDefsUsingColumnKey(columnDefs: Array<ColDef | ColGroupDef>, patch: ColDef) {
    return this.getPatchedColumnDefs(columnDefs, patch, { patchUsingColumnKey: true });
  }

  /**
   * Patches a specific column within a list of definitions.
   * */
  getPatchedColumnDefs(
    columnDefs: Array<ColDef | ColGroupDef>,
    patch: ColDef,
    options = { patchUsingColumnKey: false }
  ) {
    columnDefs = this.getColumnDefsCopy(columnDefs);

    const flattenedColumnDefs = this.getColumnDefsWithMetadata(this.getFlattenColumnDefs(columnDefs));
    const patchWithMetadata = this.getColumnDefWithMetadata(patch);

    const columnDefsToPatch: any = options.patchUsingColumnKey
      ? flattenedColumnDefs.filter((def) => def.field === patchWithMetadata.field)
      : flattenedColumnDefs.filter((def) => def.colId === patchWithMetadata.colId);

    if (columnDefsToPatch.length) {
      for (const columnDefToPatch of columnDefsToPatch) {
        const nonPatchableKeys = ['colId'];
        Object.entries(patch).forEach(([key, value]) => {
          if (!nonPatchableKeys.includes(key)) {
            if (key === 'metadata') {
              columnDefToPatch[key] = { ...columnDefToPatch[key], ...value };
            } else {
              columnDefToPatch[key] = value;
            }
          }
        });
      }
    } else {
      flattenedColumnDefs.push(this.getColumnDefWithMetadata(patch));
    }

    return flattenedColumnDefs;
  }

  /**
   * Checks if the given column definitions are equal. Useful to
   * avoid unnecessary changes.
   * */
  areColumnDefsEqual(columnDefs1: Array<ColDef | ColGroupDef>, columnDefs2: Array<ColDef | ColGroupDef>) {
    return this.getColumnDefsHash(columnDefs1) === this.getColumnDefsHash(columnDefs2);
  }

  getColumnDefsHash(columnDefs: Array<ColDef | ColGroupDef>) {
    const defsWithMetadata = this.getFlattenColumnDefs(columnDefs) as Array<ColDefWithMetadata>;
    const prefix = `columns-${defsWithMetadata.length}`;
    const hash = defsWithMetadata
      .map((def, index) => {
        const hashValues = [
          index,
          def.field,
          Boolean(def.hide),
          def.sort ?? null,
          def.metadata?.groupId,
          def.metadata?.activeView,
          def.width,
          def.maxWidth,
          def.minWidth,
        ];

        return hashValues.join('-');
      })
      .sort(Sorting.defaultSortFunc)
      .join('\n');
    return `${prefix}-${hash}`;
  }

  /**
   * Flattens a possibly nested list of column group definitions into
   * a list of column ids.
   * */
  getFlattenColumnIds(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined) {
    return this.getFlattenColumnDefs(columnsOrGroupDefs).map((def) => def.colId ?? '');
  }

  /**
   * Flattens a possibly nested list of column group definitions into
   * a list of column keys.
   * */
  getFlattenColumnKeys(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined) {
    return this.getFlattenColumnDefs(columnsOrGroupDefs).map((def) => def.field ?? '');
  }

  /**
   * Flattens a possibly nested list of column group definitions into
   * a list of column only definitions.
   * */
  getFlattenColumnDefs(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<ColDef> {
    const recursivelyGetColumnDefs = (defs: Array<ColDef | ColGroupDef>): Array<ColDef> => {
      return defs.flatMap((def) => (this.isColumnDef(def) ? def : recursivelyGetColumnDefs(def.children)));
    };
    return this.getColumnDefsCopy(recursivelyGetColumnDefs(columnsOrGroupDefs ?? []));
  }

  getNonAbstractVisibleColumnDefs(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<ColDef> {
    return this.getVisibleColumnDefs(columnsOrGroupDefs).filter(
      (def) => !this.config.abstractColumnKeys?.includes(def.field as string)
    );
  }

  getVisibleColumnDefs(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<ColDef> {
    return this.getFlattenColumnDefs(columnsOrGroupDefs).filter(({ hide }) => !hide);
  }

  getVisibleColumnKeys(columnsOrGroupDefs: Array<ColDef | ColGroupDef> | null | undefined): Array<string> {
    return this.getVisibleColumnDefs(columnsOrGroupDefs).map(({ field }) => field as string);
  }

  /**
   * Groups a list of column definitions based on its metadata.
   * */
  getDefsWithGroupedColumns(columnDefs: Array<ColDef>) {
    const columnDefsWithMetadata = columnDefs as Array<ColDefWithMetadata>;
    const nextColumnDefs: Array<ColGroupDef> = [];

    for (const columnDef of columnDefsWithMetadata) {
      const { groupId, groupName } = columnDef.metadata;
      let columnGroupDef = nextColumnDefs.find((def) => def.groupId === groupId);

      if (!columnGroupDef) {
        columnGroupDef = {
          groupId,
          headerName: groupName,
          headerGroupComponent: null, // TODO: This will be necessary when other tables start having grouped columns.
          children: [],
        };
        nextColumnDefs.push(columnGroupDef);
      }

      columnGroupDef.children.push(columnDef);
    }

    return nextColumnDefs;
  }

  /**
   * Cleans up the given defs by transforming groups with less
   * than two visible child into a simple column.
   * */
  getDefsWithoutUnnecessarilyGroupedColumns(columnDefs: Array<ColDef | ColGroupDef>) {
    return columnDefs.flatMap((def) => {
      if (this.isColumnDef(def)) {
        return def;
      }

      const visibleChildren = this.getFlattenColumnDefs(def.children).filter((def) => !def.hide);
      const column = this.getColumnDefWithMetadata(def.children[0])?.metadata?.column ?? null;

      const hasNoVisibleChildren = visibleChildren.length == 0;
      const hasSingleChildren = visibleChildren.length == 1;
      const isDefaultColumnType = !column?.type || column.type === 'default';

      if (hasNoVisibleChildren || (hasSingleChildren && isDefaultColumnType)) {
        return def.children;
      }

      return def;
    });
  }

  /**
   * Helper to convert the columns type.
   * */
  getColumnDefsWithMetadata(columnDefs: Array<ColDef>) {
    return columnDefs as Array<ColDefWithMetadata>;
  }

  /**
   * Helper to convert the column type.
   * */
  getColumnDefWithMetadata(columnDef: ColDef) {
    return columnDef as ColDefWithMetadata;
  }

  getColumnDefsCopy(columnDefs: Array<ColDef | ColGroupDef>) {
    return produce(columnDefs, (_) => _).map((def) => ({ ...def }));
  }

  getPageFromURLParams(options?: { pageParamName?: string }): number {
    const paramName = options?.pageParamName ?? DEF_PAGE_PARAM_NAME;
    const rawPageFromURLParams = QueryParams.getCurrentQueryParams()[paramName] ?? 0;
    return Math.max(this.urlPageToGrid(rawPageFromURLParams), 0);
  }

  setPageToURLParams(options: { page: number; pageParamName?: string }): void {
    const paramName = options?.pageParamName ?? DEF_PAGE_PARAM_NAME;
    const params = { ...QueryParams.getCurrentQueryParams(), [paramName]: this.gridToUrlPage(options.page) };
    QueryParams.setCurrentQueryParams(params);
  }

  urlPageToGrid(page: string): number {
    return parseInt(page) - 1;
  }

  gridToUrlPage(page: number): string {
    return page === 0 ? '' : String(page + 1);
  }

  getDefaultView(column: Column): View | null {
    const viewKeys = Object.keys(column.views ?? {});
    const indexOfView = (a: ViewKey) => {
      const index = this.config.viewModesPrecedence?.indexOf(a) ?? -1;
      return index === -1 ? Infinity : index;
    };
    const defaultViewKey = viewKeys.sort((a, b) => indexOfView(a) - indexOfView(b))[0];
    return (column.views ?? {})[defaultViewKey];
  }

  getActiveView(columnDef: ColDef): View | null {
    const { metadata } = this.getColumnDefWithMetadata(columnDef);
    const views = metadata?.column?.views ?? {};
    const activeView = metadata?.activeView;
    const defaultView = metadata?.column ? this.getDefaultView(metadata.column) : null;

    if (activeView) {
      return views[activeView] ?? defaultView;
    }

    return defaultView;
  }

  /**
   * Adds missing column properties.
   * Normally these props will be missing if the given columns are
   * default, or if the data comes corrupted from API.
   * */
  getDefsWithDefaultProperties(columnDefs: Array<ColDef | ColGroupDef>) {
    return this.getColumnDefsWithMetadata(this.getFlattenColumnDefs(columnDefs)).map(({ ...def }) => {
      if (!def.colId) {
        const permanentColumnsKeys = [
          ...(this.config.permanentLeftColumns ?? []).map((c) => c.field),
          ...(this.config.permanentRightColumns ?? []).map((c) => c.field),
        ];
        const isPermanentColumn = permanentColumnsKeys.includes(def.field as string);
        def.colId = isPermanentColumn ? def.field : uuid.v4();
      }

      if (typeof def.minWidth === 'number') {
        const defMinWidth = (this.config.columnsMinWidth ?? {})[def.field as string] ?? DEF_COLUMN_MIN_WIDTH;
        def.minWidth = Math.max(defMinWidth, def.minWidth ?? 0);
      }

      if (!def.metadata) {
        def.metadata = {} as ColDefMetadata;
      }

      if (!def.metadata.groupId) {
        def.metadata = {
          ...def.metadata,
          groupId: def.field as string,
        };
      }

      return def;
    });
  }

  /**
   * Normalizes the current sorting columns by:
   * - If the column currently being sorted becomes hidden, the sorting
   *   fallbacks to a default configured sorting column;
   * - Default configured sorting columns may contain also hidden columns.
   *   In that case, the first non-hidden available column is used.
   * */
  getDefsWithAppliedSorting(columnDefs: Array<ColDef | ColGroupDef>) {
    const flattenColumnDefs = this.getFlattenColumnDefs(columnDefs);
    const sortingColumns = flattenColumnDefs.filter((def) => Boolean(def.sort));
    const visibleSortingColumns = sortingColumns.filter((def) => !def.hide);

    if (visibleSortingColumns.length === 0) {
      for (const { field, sort } of this.config.defaultSorting ?? []) {
        const currentColumnDef = flattenColumnDefs.find((def) => field == def.field);
        if (currentColumnDef && !currentColumnDef.hide) {
          visibleSortingColumns.push({ ...currentColumnDef, sort });
          break;
        }
      }
    }

    const sortingByColumn = visibleSortingColumns.reduce(
      (acc, def) => ({ ...acc, [def.colId as string]: def.sort ?? null }),
      {} as Record<string, SortDirection>
    );

    return flattenColumnDefs.map((def) => ({ ...def, sort: sortingByColumn[def.colId as string] ?? null }));
  }

  isColumnDef(columnDef: any): columnDef is ColDef {
    return typeof columnDef.field === 'string';
  }
}
