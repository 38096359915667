import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { ArchiveContractAction, ArchiveContractActionType } from './types';

const makeArchiveContractActionStart = (): ArchiveContractAction => ({
  type: ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_START,
});

const makeArchiveContractSuccessAction = (): ArchiveContractAction => ({
  type: ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_SUCCESS,
});

const makeArchiveContractFailureAction = (): ArchiveContractAction => ({
  type: ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_FAILURE,
});

export const makeArchiveContractThunk = Thunk.createTakeFirst((contractId: number) => {
  return async (dispatch) => {
    dispatch(makeArchiveContractActionStart());
    const response = await Api.patch(URLUtil.buildURL(ENDPOINTS.archiveContract, { contractId }), {});

    if (response.error) {
      dispatch(makeArchiveContractFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw response.error;
    } else {
      dispatch(makeArchiveContractSuccessAction());
      dispatch(makeShowSnackbarAction({ messageTranslation: 'archive_contract_success' }));
      return true;
    }
  };
});
