import React from 'react';

import { StyledFakeRow, StyledGridTable, StyledTableHeightSetter, TableHeaderExtraView } from './styles';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import {
  TableCellRenderOptions,
  TableHeaderKey,
  TableHeaderOptions,
  TableHeaderRenderOptions,
  TableProps,
  TableRenderOptions,
  TableRowRenderOptions,
} from './types';

export class Table<T> extends React.Component<TableProps<T>> {
  getHeadersList(): Array<[TableHeaderKey<T>, TableHeaderOptions]> {
    const { headers } = this.props;
    const headersList = Object.entries<TableHeaderOptions | undefined>(headers);
    return headersList as Array<[TableHeaderKey<T>, TableHeaderOptions]>;
  }

  getSortedHeadersList(): Array<[TableHeaderKey<T>, TableHeaderOptions]> {
    const headersList = this.getHeadersList();
    const headersListSorted = headersList.sort((a, b) => {
      const headerA = a[1] as TableHeaderOptions;
      const headerB = b[1] as TableHeaderOptions;
      if (typeof headerA.order === 'number' && typeof headerB.order === 'number') {
        return headerA.order - headerB.order;
      }
      return headerA.title.localeCompare(headerB.title);
    });
    return headersListSorted as Array<[TableHeaderKey<T>, TableHeaderOptions]>;
  }

  getHeaderOptions(header: TableHeaderKey<T>): TableHeaderOptions | null {
    const { headers } = this.props;
    return headers[header] ?? null;
  }

  getCellOptions(header: TableHeaderKey<T>): TableHeaderOptions | null {
    const options = this.getHeaderOptions(header);
    if (options) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { onClick, ...otherOptions } = options;
      return otherOptions;
    }
    return null;
  }

  getRenderOptions(): TableRenderOptions<T> {
    return {
      items: this.props.items,
    };
  }

  getHeaderRenderOptions(header: TableHeaderKey<T>): TableHeaderRenderOptions<T> {
    const { headers } = this.props;
    const headerOptions = headers[header];

    if (!headerOptions) {
      throw new Error(`Invalid header ${header}`);
    }

    return {
      ...this.getRenderOptions(),
      header,
      headerOptions,
    };
  }

  getRowRenderOptions(index: number): TableRowRenderOptions<T> {
    const { items } = this.props;
    const item = items[index];

    return {
      ...this.getRenderOptions(),
      item,
      index,
    };
  }

  getCellRenderOptions(header: TableHeaderKey<T>, index: number): TableCellRenderOptions<T> {
    const { items } = this.props;
    const item = items[index];
    const value = item[header];
    return {
      ...this.getRenderOptions(),
      ...this.getHeaderRenderOptions(header),
      item,
      index,
      value,
    };
  }

  renderHeader(header: TableHeaderKey<T>, options: TableHeaderOptions, index: number) {
    const { renderHeader, renderHeaderTooltip, sorting } = this.props;
    const renderOptions = this.getHeaderRenderOptions(header);
    const isSorting = sorting?.header === header;
    const sortingDirection = sorting?.direction;

    return (
      <TableHeader key={header} index={index} isSorting={isSorting} sortingDirection={sortingDirection} {...options}>
        {renderHeader ? renderHeader(renderOptions) : options.title}
        {renderHeaderTooltip && renderHeaderTooltip(renderOptions)}
      </TableHeader>
    );
  }

  renderHeaderTop() {
    const { renderHeaderTop } = this.props;
    const renderOptions = this.getRenderOptions();
    const content = renderHeaderTop ? renderHeaderTop(renderOptions) : null;
    return <TableHeaderExtraView>{content}</TableHeaderExtraView>;
  }

  renderHeaderBottom() {
    const { renderHeaderBottom } = this.props;
    const renderOptions = this.getRenderOptions();
    const content = renderHeaderBottom ? renderHeaderBottom(renderOptions) : null;
    return <TableHeaderExtraView>{content}</TableHeaderExtraView>;
  }

  renderHeaders() {
    const headers = this.getSortedHeadersList();
    return <>{headers.map(([header, options], index) => this.renderHeader(header, options, index + 1))}</>;
  }

  renderCell(header: TableHeaderKey<T>, item: T, index: number) {
    const { itemKey, itemSelected, itemDisabled, onItemClick } = this.props;
    const renderProps = this.getRowRenderOptions(index);

    const key = itemKey ? itemKey(renderProps) : `${header}-${index}`;
    const selected = itemSelected && itemSelected(renderProps);
    const disabled = itemDisabled && itemDisabled(renderProps);
    const options = this.getCellOptions(header);
    const isFirstColumn = options?.order === 0;

    const props = { key, selected, disabled, isFirstColumn };

    const { renderCell, renderCellTooltip } = this.props;
    const renderOptions = this.getCellRenderOptions(header, index);

    return (
      <TableCell {...options} onClick={() => onItemClick && onItemClick(renderProps)} {...props}>
        {renderCell(renderOptions)}
        {renderCellTooltip && renderCellTooltip(renderOptions)}
      </TableCell>
    );
  }

  renderBody() {
    const { items } = this.props;
    const headers = this.getSortedHeadersList();
    return (
      <>
        {items.map((item, index) => {
          return (
            <StyledFakeRow key={index}>{headers.map(([header]) => this.renderCell(header, item, index))}</StyledFakeRow>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <StyledTableHeightSetter $maxViewHeightOffset={this.props.maxViewHeightOffset}>
        {this.renderHeaderTop()}
        <StyledGridTable columns={this.getSortedHeadersList().length}>
          {this.renderHeaders()}
          {this.renderHeaderBottom()}
          {this.renderBody()}
        </StyledGridTable>
      </StyledTableHeightSetter>
    );
  }
}
