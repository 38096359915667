import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';

import { Box } from '../../common/Box';

export type YardNameProps = {
  name: string;
  contractName?: string | null;
  maxWidth?: number;
  suppressTruncation?: boolean;
  autoWidth?: boolean;
} & (
  | {
      suppressStyling: true;
    }
  | ({
      suppressStyling?: undefined | false;
    } & Omit<TextProps, 'children'>)
);

const StyledText = styled(Text)`
  display: flex;
`;

const StyledTruncatedText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const YardName: React.VFC<YardNameProps> = ({
  name,
  contractName,
  maxWidth: propMaxWidth,
  suppressTruncation,
  suppressStyling,
  autoWidth,
  ...textProps
}) => {
  const yardNameRef = useRef<HTMLElement>(null);
  const truncatedTextRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const truncatedElement = truncatedTextRef.current;
    const yardNameElement = yardNameRef.current;
    const rootElement = truncatedElement?.parentElement;
    const parentElement = rootElement?.parentElement;

    if (suppressTruncation || !rootElement || !parentElement || !truncatedElement) {
      return;
    }

    const handleTruncation = () => {
      const { width, paddingLeft, paddingRight } = getComputedStyle(parentElement);
      const yardNameWidth = yardNameElement ? getComputedStyle(yardNameElement).width : '0px';

      const availableSpace = autoWidth ? `${width} - ${paddingLeft} - ${paddingRight}` : `${propMaxWidth ?? 0}px`;
      const maxWidth = `calc(${availableSpace} - ${yardNameWidth} - 1px)`; // The -1px at end helps to fix rounding problems.

      truncatedElement.style.maxWidth = `${maxWidth}`;
    };

    handleTruncation();
    const observer = new ResizeObserver(handleTruncation);
    observer.observe(parentElement);
    return () => {
      observer.unobserve(parentElement);
    };
  }, [autoWidth, suppressTruncation, propMaxWidth]);

  const TextComp: any = suppressStyling ? React.Fragment : StyledText;

  if (contractName && suppressTruncation) {
    return (
      <TextComp {...textProps}>
        <span>
          {contractName}: {name}
        </span>
      </TextComp>
    );
  }

  if (contractName) {
    return (
      <TextComp {...textProps}>
        <StyledTruncatedText ref={truncatedTextRef}>{contractName}</StyledTruncatedText>
        <span ref={yardNameRef}>: {name}</span>
      </TextComp>
    );
  }

  if (suppressTruncation) {
    return (
      <TextComp {...textProps}>
        <Box tag={'span'} paddingRight_025>
          {name}
        </Box>
      </TextComp>
    );
  }

  return (
    <TextComp {...textProps}>
      <StyledTruncatedText ref={truncatedTextRef}>{name}</StyledTruncatedText>
    </TextComp>
  );
};
