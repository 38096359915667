import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// vendor:
import { GoogleApiWrapper } from 'google-maps-react';
import { difference, includes, isEqual, keyBy } from 'lodash';
// nectar
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import wkx from 'wkx';

// icons
import hiveIcon from '@assets/marker-hive.png';
import { DEFAULT_GOOGLE_MAPS_API_OPTIONS } from '@components/common/Map/apis/GoogleMaps/constants';
import { MapUtil } from '@components/common/Map/util';
import APP from '@config/constants';
import {
  DEFAULT_MAP_CONFIG,
  DEFAULT_PAN_TO_ZOOM,
  EDITABLE_YARD_BOUNDS_STYLE,
  GMAP_API_KEY,
  GMAP_TYPE,
  HOVERED_YARD_BOUNDS_STYLE,
  MARKER_CLUSTER_STYLES,
  MARKER_MAX_ZOOM,
  SHOW_YARD_BOUNDARIES_ZOOM,
  SHOW_YARD_HIVES_ZOOM,
  YARD_BOUNDS_STYLE,
} from '@config/google';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { deepClone, findReplaceOrAdd, isEmptyArray } from '@helpers/deprecated/array';
import { getUniqId } from '@helpers/deprecated/getUniqId';
import {
  generateMarkerDescriptors,
  getCoordinateListCenter,
  getCoordinatesFromPath,
  getPolygonCenter,
  initYardVertexDelete,
  isCoordinateInsidePolygons,
} from '@helpers/deprecated/map';
import { GeometryAnalyzer, Point, Polygon } from '@helpers/MapOptimizer/GeometryAnalyzer';
import { MapRenderingOptimizer } from '@helpers/MapRenderingOptimizer';
import { URLUtil } from '@helpers/URL';
import {
  makeBatchUpdateYardsRequestThunk,
  makeFetchYardMapRequestThunk,
  makeOpenCreateYardModalAction,
  makeOpenDeleteYardModalAction,
  resetYardData,
  setAddMultipleYards,
  setCenterGlobal,
  setEditModeAction,
  setUpdateMultipleYards,
  setZoomGlobal,
  toggleMapType,
} from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { BeetrackYardMgmtMapView } from './BeeTrackYardMgmtMapView';

import './map-styles.css';

var panPath = []; // An array of points the current panning action will use
var panQueue = []; // An array of subsequent panTo actions to take
var STEPS = 5; // The number of steps that each panTo action will undergo

const POLYGON_CHANGE_DEBOUNCE_DELAY = 350;

class GmapContainer extends React.Component {
  hiveMarkers = null;
  hiveRenderingOptimizer = null;

  analyzer = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      yardBoundaries: [], // google.maps.Polygon[]    - yards displayed on map & used for validation
      markerDescriptors: {}, // Record<yard, google.maps.Marker>     - map of yard displayed markers
      gMarkers: {},

      clicked: null, // nullable string          - either 'create_yard' or 'delete_yard'

      selectedPolygon: null, // google.maps.Polygon      - selected polygon
      selectedYard: null, // Object                   - selected yard from yards reducer
      editedYards: new Set(), // set       - set of edited and initially invalid yard ids (overlap or orphan hives)

      hasCompletedInitialValidation: false, // bool
      orphanHives: [], // string[]                 - array of orphan hive positions in WKT
      intersectingYardIds: [], // number[][]             - list of yard ids which overlap with another yard

      markerClusterer: null, // MarkerClusterer             - clustering library instance

      // TODO: this.map is currently a class attribute and not part of this.state. This could cause problem
      // notably when updating this.map, the component is not rerendered. The old map could then still be displayed.
      // This doesn't happen because we always do useState for other part of the state after updating this.map
      // map: null,             // google.maps.Map          - Map object to which we add event listeners
    };

    this.loadRenderTrack = this.loadRenderTrack.bind(this);
    this.setBounds = this.setBounds.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.trackZoomAndCenter = this.trackZoomAndCenter.bind(this);
    this.handleLayerTypeButtonToggle = this.handleLayerTypeButtonToggle.bind(this);
    this.renderYards = this.renderYards.bind(this);
    this.updateYardMarker = this.updateYardMarker.bind(this);
    this.handleHives = this.handleHives.bind(this);
    this.handleYardsBoundaries = this.handleYardsBoundaries.bind(this);
    this.handleYardBoundaries = this.handleYardBoundaries.bind(this);
    this.shouldShowPolygon = this.shouldShowPolygon.bind(this);
    this.clearYardBoundary = this.clearYardBoundary.bind(this);
    this.initializeYardBoundary = this.initializeYardBoundary.bind(this);
    this.getUpdatedYardBoundaries = this.getUpdatedYardBoundaries.bind(this);
    this.patchNewYardGeometry = this.patchNewYardGeometry.bind(this);
    this.addNewYardGeometry = this.addNewYardGeometry.bind(this);
    this.panTo = this.panTo.bind(this);
    this.doPan = this.doPan.bind(this);
    this.panToProblematicArea = this.panToProblematicArea.bind(this);
    this.editYardHandler = this.editYardHandler.bind(this);
    this.checkOrphanHivesAmongYards = this.checkOrphanHivesAmongYards.bind(this);
    this.checkOrphanHivesAmongHives = this.checkOrphanHivesAmongHives.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.validateAllYardGeometry = this.validateAllYardGeometry.bind(this);
    this.setYardGeometry = this.setYardGeometry.bind(this);
  }

  componentDidMount() {
    this.analyzer = new GeometryAnalyzer(
      this.props.yards.map((yard) => this.yardToAnalyzerPolygon(yard)),
      this.props.yards.flatMap((yard) => yard.hives_position.map((hivePos) => this.hiveToAnalyzerPoint(yard, hivePos)))
    );

    this.loadRenderTrack();
    this.setBounds();
    this.validateAllYardGeometry();
  }

  componentDidUpdate(prevProps) {
    const { yards, type } = this.props;

    if (!isEqual(prevProps.yards, yards)) {
      this.validateAllYardGeometry();
      this.renderYards();
    }

    this.map?.setMapTypeId(type);
  }

  yardToAnalyzerPolygon(yard) {
    return new Polygon(
      yard.id,
      yard.geometry.coordinates[0].map(([x, y], index) => new Point(`${yard.id}-${index}`, x, y))
    );
  }

  hiveToAnalyzerPoint(yard, hivePos) {
    const { x, y } = wkx.Geometry.parse(hivePos);
    return new Point(`${yard.id}-${hivePos}`, x, y, { hivePos });
  }

  loadRenderTrack(fromScratch) {
    this.loadMap();
    this.renderYards(fromScratch);
    this.trackZoomAndCenter();
  }

  validateAllYardGeometry() {
    const intersectionYards = this.analyzer.getNextIntersectingPolygons();
    const intersectingYardIds = (intersectionYards || []).map((yard) => yard.id);

    const orphanHivePoint = this.analyzer.getNextOrphanPoint();
    const orphanHivesPositions = orphanHivePoint ? [orphanHivePoint.properties.hivePos] : [];

    this.setState({
      orphanHives: orphanHivesPositions,
      intersectingYardIds,
      hasCompletedInitialValidation: true,
    });

    return orphanHivesPositions.length === 0 && intersectingYardIds.length === 0;
  }

  setYardGeometry(polygon) {
    const { id, name } = polygon;
    const path = polygon.getPath();

    this.analyzer.updatePolygon(
      new Polygon(
        id,
        path.getArray().map((p, index) => new Point(`${id}-${index}`, p.lng(), p.lat()))
      )
    );

    if (typeof polygon.id === 'string' && polygon.id.startsWith('uid')) this.addNewYardGeometry(path, id, name);
    else {
      this.patchNewYardGeometry(path, id, name);
      Analytics.sendEvent({
        event: AnalyticsEventType.YARD_EDIT,
      });
    }
  }

  loadMap() {
    const { google, type, zoom, lat, lng } = this.props;

    if (this.props && google) {
      // google props is available

      const { maps } = google;

      //the map must have a center (and zoom) to load
      const center = new maps.LatLng(lat, lng);

      const mapConfig = Object.assign(
        {},
        {
          center,
          zoom,
          mapTypeId: GMAP_TYPE.satellite === type ? maps.MapTypeId.SATELLITE : maps.MapTypeId.TERRAIN,
          ...DEFAULT_MAP_CONFIG,
        }
      );

      //load map once DOM is ready
      this.map = new maps.Map(this.mapRef, mapConfig);

      this.map.addListener('click', () => {
        const { selectedPolygon } = this.state;
        selectedPolygon && selectedPolygon.setOptions({ ...YARD_BOUNDS_STYLE });
        this.setState({
          selectedPolygon: null,
          selectedYard: null,
          clicked: null,
        });
      });
    }
  }

  trackZoomAndCenter() {
    this.map.addListener('idle', () => {
      const { setZoomGlobal, setCenterGlobal } = this.props;
      const { lat, lng } = this.map.getCenter().toJSON();
      this.handleHives();
      this.handleYardsBoundaries();
      setZoomGlobal(this.map.getZoom());
      setCenterGlobal(lat, lng);

      // Sets current map state to local storage, so making
      // this old yard management component compatible with the
      // new yards map, which will start in the right map
      // position.
      try {
        const currentMapState = JSON.parse(sessionStorage.getItem('map-storage-state'));
        sessionStorage.setItem(
          'map-storage-state',
          JSON.stringify({
            ...currentMapState,
            zoom: this.map.getZoom(),
            center: this.map.getCenter().toJSON(),
            bounds: this.map.getBounds().toJSON(),
          })
        );
      } catch (error) {
        console.error("Can't update map state to local storage:", error);
      }
    });
  }

  setZoom(zoom) {
    this.map.setZoom(zoom);
  }

  handleLayerTypeButtonToggle() {
    const { toggleMapType } = this.props;
    toggleMapType();
  }

  renderYards(fromScratch) {
    const { google, yards = [] } = this.props;

    if (!google || !this.map) {
      return;
    }

    const { maps } = google;
    const { yardBoundaries, yardBoundariesToAddToMap, yardIdsToRemoveFromMap } =
      this.getUpdatedYardBoundaries(fromScratch);

    yardIdsToRemoveFromMap.forEach((id) => this.analyzer.removePolygon({ id }));
    yardBoundariesToAddToMap.forEach((polygon) => {
      this.analyzer.updatePolygon(
        new Polygon(
          polygon.id,
          polygon
            .getPath()
            .getArray()
            .map((p, index) => new Point(`${polygon.id}-${index}`, p.lng(), p.lat()))
        )
      );
    });

    this.setState(
      ({ markerDescriptors, gMarkers, markerClusterer }) => {
        // markerClusterer?.clearMarkers();
        markerClusterer =
          markerClusterer ||
          new MarkerClusterer(this.map, gMarkers, {
            maxZoom: MARKER_MAX_ZOOM,
            styles: MARKER_CLUSTER_STYLES,
            clusterClass: 'custom-clustericon',
            minimumClusterSize: 3,
            gridSize: 200,
          });

        const yardsDictionary = yards.reduce((map, yard) => ({ ...map, [yard.id]: yard }), {});

        // Clean up removed yard markers.
        for (const marker of Object.values(gMarkers)) {
          if (!yardsDictionary[marker.id]) {
            markerClusterer.removeMarker(marker);
            marker.setMap(null);
            delete markerDescriptors[marker.id];
            delete gMarkers[marker.id];
          }
        }

        // Add/Update yards markers.
        for (const yard of yards) {
          const descriptor = generateMarkerDescriptors(maps, [yard])[0];
          const existingMarker = gMarkers[yard.id];
          const updatedMarker = this.updateYardMarker(maps, descriptor, existingMarker);
          markerDescriptors[yard.id] = descriptor;
          gMarkers[yard.id] = updatedMarker;
          !existingMarker && markerClusterer.addMarker(updatedMarker);
        }

        return {
          markerDescriptors: { ...markerDescriptors },
          gMarkers: { ...gMarkers },
          yardBoundaries,
          markerClusterer,
        };
      },
      () => {
        this.handleHives();
        if (yardIdsToRemoveFromMap.length || yardBoundariesToAddToMap.length) {
          this.validateAllYardGeometry();
        }
      }
    );
  }

  updateYardMarker(maps, descriptor, currentMarker) {
    if (currentMarker) {
      const hasChangedPosition = !MapUtil.areCoordinatesEquals(
        currentMarker.getPosition().toJSON(),
        descriptor.position
      );
      if (hasChangedPosition) {
        currentMarker.setPosition(descriptor.position);
      }
      maps.event.clearInstanceListeners(currentMarker);
    } else {
      currentMarker = new maps.Marker({
        id: descriptor.id,
        map: this.map,
        icon: descriptor.icon,
        position: descriptor.position,
        draggable: false,
      });
    }

    maps.event.addListener(currentMarker, 'mouseover', () => {
      currentMarker.setIcon(descriptor.hoveredSvg);
    });
    maps.event.addListener(currentMarker, 'mouseout', () => {
      currentMarker.setIcon(descriptor.normalSvg);
    });

    maps.event.addListener(currentMarker, 'click', () => {
      const { zoom } = this.props;
      const { id, position } = descriptor;
      if (zoom <= SHOW_YARD_BOUNDARIES_ZOOM) {
        this.setZoom(SHOW_YARD_BOUNDARIES_ZOOM + 1);
        this.panTo(position.lat, position.lng);
      }
      this.editYardHandler(id);
    });

    return currentMarker;
  }

  handleHives() {
    const { google, hives } = this.props;
    const mapBounds = this.map?.getBounds();
    const mapZoom = this.map?.getZoom();

    if (!google?.maps || !mapBounds) return;

    const { maps } = google;

    if (!this.hiveMarkers) {
      this.hiveMarkers = {};
      this.hiveRenderingOptimizer = new MapRenderingOptimizer();

      for (const hive of hives) {
        const { x: lng, y: lat } = wkx.Geometry.parse(hive);
        this.hiveMarkers[hive] = new maps.Marker({
          position: { lng, lat },
          icon: hiveIcon,
        });
        this.hiveRenderingOptimizer.addElement({ id: hive, lat, lng });
      }
    }

    const visibleHives = this.hiveRenderingOptimizer.getVisibleElements(mapBounds.toJSON());

    for (const [hiveId, marker] of Object.entries(this.hiveMarkers)) {
      const isMarkerVisible = mapZoom > SHOW_YARD_HIVES_ZOOM && visibleHives[hiveId];

      if (isMarkerVisible) {
        !marker.getMap() && marker.setMap(this.map);
      } else {
        marker.getMap() && marker.setMap(null);
      }
    }
  }

  handleYardsBoundaries() {
    const { yardBoundaries } = this.state;
    yardBoundaries.forEach((polygon) => this.handleYardBoundaries(polygon));
  }

  handleYardBoundaries(polygon) {
    polygon.setMap(this.shouldShowPolygon(polygon) ? this.map : null);
  }

  shouldShowPolygon(polygon) {
    const mapBounds = this.map.getBounds();
    const polygonPath = polygon.getPath().getArray();
    const projection = this.map.getProjection();
    const numTiles = Math.pow(2, this.map.getZoom() ?? 0);
    const polygonPoints = polygonPath
      .map((p) => projection?.fromLatLngToPoint(p))
      .map((p) => ({ x: (p?.x ?? 0) * numTiles, y: (p?.y ?? 0) * numTiles }));
    const polygonLargerPixelSize = Math.max(
      Math.max(...polygonPoints.map((p) => p.x)) - Math.min(...polygonPoints.map((p) => p.x)),
      Math.max(...polygonPoints.map((p) => p.y)) - Math.min(...polygonPoints.map((p) => p.y))
    );

    const isVisible = polygonPath.some((pos) => mapBounds?.contains(pos));
    const isLargeEnough = polygonLargerPixelSize >= 8;

    return isVisible && isLargeEnough;
  }

  initializeYardBoundary(yard, maps) {
    const { makeShowSnackbarAction } = this.props;
    const { id, name } = yard;
    const { coordinates } = yard.geometry;

    const polygonCoords = coordinates[0].map((c) => {
      return { lat: c[1], lng: c[0] };
    });
    const polygon = new maps.Polygon({
      ...YARD_BOUNDS_STYLE,
      map: null,
      paths: polygonCoords,
      id,
      name,
    });
    this.handleYardBoundaries(polygon);

    maps.event.addListener(polygon, 'click', () => this.editYardHandler(polygon.id));

    maps.event.addListener(polygon, 'mouseover', () => {
      const { selectedPolygon } = this.state;
      selectedPolygon !== polygon && polygon.setOptions({ ...HOVERED_YARD_BOUNDS_STYLE });
    });

    maps.event.addListener(polygon, 'mouseout', () => {
      const { selectedPolygon } = this.state;
      selectedPolygon !== polygon && polygon.setOptions({ ...YARD_BOUNDS_STYLE });
    });

    maps.event.addListener(polygon, 'dragend', () => {
      this.setYardGeometry(polygon);
    });

    const paths = polygon.getPath();

    initYardVertexDelete(polygon, maps, this.map, makeShowSnackbarAction, paths, () => this.setYardGeometry(polygon));

    ['insert_at', 'set_at'].map((listener) =>
      maps.event.addListener(
        paths,
        listener,
        debounce(() => {
          this.setYardGeometry(polygon);
        }, POLYGON_CHANGE_DEBOUNCE_DELAY)
      )
    );

    return polygon;
  }

  clearYardBoundary(boundary) {
    boundary.setMap(null);
    return boundary;
  }

  getUpdatedYardBoundaries(fromScratch) {
    let { yardBoundaries } = this.state;
    const { google, yards = [] } = this.props;
    const { maps } = google;
    let yardIdsToRemoveFromMap = []; // list of yard ids as strings
    let yardBoundariesToAddToMap = []; // list of googlemaps.Polygon for new yards

    if (fromScratch) {
      yardBoundaries = [];
    }

    if (yardBoundaries.length !== yards.length) {
      const displayedYardById = keyBy(yardBoundaries, 'id'); // id in yardBoundaries and boundaries are both yard ids
      const trackedYardById = keyBy(yards, 'id');

      const yardIdsToDisplayOnMap = difference(Object.keys(trackedYardById), Object.keys(displayedYardById));
      yardIdsToRemoveFromMap = difference(Object.keys(displayedYardById), Object.keys(trackedYardById));

      yardIdsToRemoveFromMap.map((idToRemove) => this.clearYardBoundary(displayedYardById[idToRemove]));
      yardBoundariesToAddToMap = yardIdsToDisplayOnMap.map((idToDisplay) =>
        this.initializeYardBoundary(trackedYardById[idToDisplay], maps)
      );
    }

    const updatedYardBoundaries = yardBoundaries
      .filter(({ id }) => !includes(yardIdsToRemoveFromMap, id.toString()))
      .concat(yardBoundariesToAddToMap);

    return { yardBoundaries: updatedYardBoundaries, yardIdsToRemoveFromMap, yardBoundariesToAddToMap };
  }

  handleZoom(zoomIn) {
    let z = this.map.getZoom();
    let form = zoomIn ? z + 1 : z - 1;
    this.setZoom(form);
  }

  /**
   * Note: Assumes google.maps is not null
   * @param {google.maps.Polygon[]} yardBoundaries - List of polygons where hives could be in
   * @param {string[]} hivePositions - List of positions encoded in Well Known Text
   */
  checkOrphanHivesAmongHives(yardBoundaries, hivePositions) {
    const { google } = this.props;
    const { maps } = google;
    const orphanHives = [];

    for (const hivePosition of hivePositions) {
      if (!isCoordinateInsidePolygons(hivePosition, yardBoundaries, maps)) {
        orphanHives.push(hivePosition);
      }
    }

    return orphanHives;
  }

  /**
   * @param {google.maps.Polygon[]} yardBoundaries - List of polygons where hives could be in
   * @param {Set<number>|null} yardIds - Set of yard ids for which we check if their hives are in a yard.
   * If null we check for all yards.
   */
  checkOrphanHivesAmongYards(yardBoundaries, yardIds = null) {
    const { yards } = this.props;
    const orphanHives = [];
    const yardIdsWithOrphanHives = [];

    for (const yard of yards) {
      const { id, hives_position } = yard;
      const shouldVerifyHives = hives_position && (yardIds === null || yardIds.has(id));

      if (shouldVerifyHives) {
        const yardOrphanHives = this.checkOrphanHivesAmongHives(yardBoundaries, hives_position);
        if (!isEmptyArray(yardOrphanHives)) {
          orphanHives.push(...yardOrphanHives);
          yardIdsWithOrphanHives.push(id);
        }
      }
    }

    return { orphanHives, yardIdsWithOrphanHives };
  }

  editYardHandler(id) {
    const { yards } = this.props;
    const { yardBoundaries, selectedPolygon } = this.state;
    selectedPolygon && selectedPolygon.setOptions({ ...YARD_BOUNDS_STYLE });

    const nextSelectedYard = yards.find((yard) => yard.id === id);
    const nextSelectedPolygon = yardBoundaries.find((yard) => yard.id === id);
    nextSelectedPolygon && nextSelectedPolygon.setOptions({ ...EDITABLE_YARD_BOUNDS_STYLE });

    this.setState({ selectedPolygon: nextSelectedPolygon, selectedYard: nextSelectedYard });
  }

  patchNewYardGeometry(path, id, name) {
    const { patch, setUpdateMultipleYards, yards, google } = this.props;
    const { maps } = google;

    const coordinates = getCoordinatesFromPath(path);
    const center = getCoordinateListCenter(coordinates, maps);
    const yardCenterCoordinates = [center.lng(), center.lat()];
    const yard = {
      id,
      name,
      yard_center: {
        type: 'Point',
        coordinates: yardCenterCoordinates,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    };
    const yardUpdateData = findReplaceOrAdd(patch, yard);
    const yardsModified = deepClone(yards);
    const modifiedIndex = yardsModified.findIndex((el) => el.id === id);

    if (yardsModified[modifiedIndex]) {
      yardsModified[modifiedIndex].geometry.coordinates = [coordinates];
      yardsModified[modifiedIndex].yard_center = {
        type: 'Point',
        coordinates: yardCenterCoordinates,
      };
    }

    setUpdateMultipleYards(yardUpdateData, yardsModified);
  }

  addNewYardGeometry(path, id, name) {
    const { add, setAddMultipleYards, yards, google } = this.props;
    const { maps } = google;

    const coordinates = getCoordinatesFromPath(path);
    const yard_center = getCoordinateListCenter(coordinates, maps);
    const yard = {
      id,
      name,
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      yard_center: {
        type: 'Point',
        coordinates: [yard_center.lng(), yard_center.lat()],
      },
    };
    const yardAddData = deepClone(add);
    const yardsModified = deepClone(yards);
    const index = yardAddData.findIndex((el) => el.id === id);
    const modifiedIndex = yardsModified.findIndex((el) => el.id === id);

    if (index !== -1) {
      yardsModified[modifiedIndex] = yard;
      yardAddData[index].geometry.coordinates = [coordinates];
    }

    setAddMultipleYards(yardAddData, yardsModified);
  }

  setBounds() {
    if (this.props && this.props.google) {
      // google props is available
      const { google, yards = [], lat, lng } = this.props;

      const { maps } = google;
      //center map by bounds, not center
      const bounds = new maps.LatLngBounds();

      yards &&
        !isEmptyArray(yards) &&
        yards.forEach((yard) => {
          if (yard.yard_center) {
            const { coordinates } = yard.yard_center;
            bounds.extend({
              lat: coordinates[1],
              lng: coordinates[0],
            });
          }
        });
      //fit the map to the bounds of existing markers on first load
      !lat && !lng && this.map.fitBounds(bounds);
    }
  }

  panTo(newLat, newLng) {
    if (panPath.length > 0) {
      // We are already panning...queue this up for next move
      panQueue.push([newLat, newLng]);
    } else {
      // Lets compute the points we'll use
      panPath.push('LAZY SYNCRONIZED LOCK'); // make length non-zero - 'release' this before calling setTimeout
      var curLat = this.map.getCenter().lat();
      var curLng = this.map.getCenter().lng();
      var dLat = (newLat - curLat) / STEPS;
      var dLng = (newLng - curLng) / STEPS;

      for (var i = 0; i < STEPS; i++) {
        panPath.push([curLat + dLat * i, curLng + dLng * i]);
      }
      panPath.push([newLat, newLng]);
      panPath.shift(); // LAZY SYNCRONIZED LOCK
      setTimeout(this.doPan(), 20);
    }
  }

  doPan() {
    const { google } = this.props;
    const { maps } = google;

    if (!google?.maps) return;

    var next = panPath.shift();
    if (next != null) {
      // Continue our current pan action
      this.map.panTo(new maps.LatLng(next[0], next[1]));
      setTimeout(this.doPan, 20);
    } else {
      // We are finished with this pan - check if there are any queue'd up locations to pan to
      var queued = panQueue.shift();
      if (queued != null) {
        this.panTo(queued[0], queued[1]);
      }
    }
  }

  panToProblematicArea() {
    const { orphanHives, intersectingYardIds, yardBoundaries } = this.state;
    const { google } = this.props;

    if (!google?.maps) return;

    const { maps } = google;
    const first = 0;

    if (!isEmptyArray(orphanHives)) {
      const geometry = wkx.Geometry.parse(orphanHives[first]);
      this.panTo(geometry.y, geometry.x);
    } else if (!isEmptyArray(intersectingYardIds)) {
      const intersectingYardBoundary = yardBoundaries.find((boundary) => boundary.id === intersectingYardIds[first]);
      const yardCenter = getPolygonCenter(intersectingYardBoundary, maps);
      this.panTo(yardCenter.lat(), yardCenter.lng());
    }

    this.setZoom(DEFAULT_PAN_TO_ZOOM);
  }

  async handleSubmit() {
    this.setState(() => ({ loading: true }));

    const {
      user,
      patch,
      add,
      remove,
      batchUpdateYards,
      makeShowSnackbarAction,
      setEditModeAction,
      resetYardData,
      history,
      fetchBeeTrackYardMap,
    } = this.props;

    let deepAdd = deepClone(add);
    let deepRemove = deepClone(remove);

    deepRemove = deepRemove.filter((id) => {
      const stringId = id.toString();
      return !stringId.startsWith('uid');
    });

    let removeArray = deepClone(remove);
    removeArray.forEach((id) => (deepAdd = deepAdd.filter((item) => item.id !== id)));

    try {
      await batchUpdateYards({ add: deepAdd, remove: deepRemove, patch }, user);
      setEditModeAction(false);
      resetYardData();
      fetchBeeTrackYardMap(history);
      history.push(URLUtil.buildPagePath(APP.routes.yardsMap));
    } catch (e) {
      if (this.validateAllYardGeometry()) {
        makeShowSnackbarAction({ messageTranslation: 'snack_default_msg', type: 'error' });
      } else {
        makeShowSnackbarAction({ messageTranslation: 'yards_generic_error_msg', type: 'error', actionRefresh: true });
      }
    } finally {
      this.setState(() => ({ loading: false }));
    }
  }

  handleDelete() {
    const { selectedYard, selectedPolygon } = this.state;
    const { openDeleteYardModal, remove } = this.props;
    const { id } = selectedYard;

    let yardIdsToRemove = deepClone(remove);

    yardIdsToRemove.push(id);

    openDeleteYardModal({
      modalType: 'remove-yards',
      yard: selectedYard,
      yardIdsToRemove,
    });

    selectedPolygon.setOptions({ ...YARD_BOUNDS_STYLE });

    this.setState({
      selectedPolygon: null,
      selectedYard: null,
      clicked: 'delete_yards',
      markerDescriptors: [],
    });
  }

  handleCancel() {
    const { openDeleteYardModal } = this.props;
    openDeleteYardModal({ modalType: 'cancel-changes', yard: {} });
  }

  handleAdd() {
    const { google, openCreateYardModal, makeShowSnackbarAction } = this.props;
    const { maps } = google;

    // if something is selected, clear it
    const { selectedPolygon } = this.state;
    selectedPolygon && selectedPolygon.setOptions({ ...YARD_BOUNDS_STYLE });
    this.setState({
      selectedPolygon: null,
      selectedYard: null,
      clicked: 'create_yard',
    });

    const drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: { ...EDITABLE_YARD_BOUNDS_STYLE },
    });

    drawingManager.setMap(this.map);

    maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      const path = polygon.getPath();

      if (path.length <= 2) {
        makeShowSnackbarAction('unable_create_yard');
        drawingManager.setDrawingMode(null);
        polygon.setMap(null);
        this.setState({
          clicked: null,
        });
        return;
      }

      const coordinates = getCoordinatesFromPath(path);
      const yard_center = getCoordinateListCenter(coordinates, maps);

      let yard = {
        id: getUniqId(),
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
        yard_center: {
          type: 'Point',
          coordinates: [yard_center.lng(), yard_center.lat()],
        },
      };

      openCreateYardModal({ modalType: 'create-yard', yard, validateAllYardGeometry: this.validateAllYardGeometry });
      drawingManager.setDrawingMode(null);
      polygon.setMap(null);
      polygon.setOptions({ id: yard.id });

      this.setState({
        clicked: null,
        markerDescriptors: [],
      });
    });
  }

  /**
   *
   * @returns {*} JSX
   */
  render() {
    const { t, type, zoom } = this.props;
    const { intersectingYardIds, orphanHives, selectedYard, clicked, loading } = this.state;
    const setMapRef = (node) => {
      this.mapRef = node;
    };

    const errorMsgShapes = !isEmptyArray(intersectingYardIds) ? t('yards_intersecting_error_msg') : false;
    const errorMsgHives = !isEmptyArray(orphanHives) ? t('yard_not_all_hives_included_error_msg') : false;

    return (
      <BeetrackYardMgmtMapView
        t={t}
        type={type}
        zoom={zoom}
        loading={loading}
        error={errorMsgHives || errorMsgShapes}
        hasSelectedAYard={!!selectedYard}
        clicked={clicked}
        setMapRef={setMapRef}
        handleAdd={this.handleAdd}
        handleCancel={this.handleCancel}
        handleDelete={this.handleDelete}
        handleLayerTypeButtonToggle={this.handleLayerTypeButtonToggle}
        handleSubmit={this.handleSubmit}
        panToProblematicArea={this.panToProblematicArea}
        handleZoom={this.handleZoom}
      />
    );
  }
}

GmapContainer.propTypes = {
  t: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  patch: PropTypes.array.isRequired,
  add: PropTypes.array.isRequired,
  remove: PropTypes.array.isRequired,

  yards: PropTypes.array,
  hives: PropTypes.array,
  user: PropTypes.object,

  toggleMapType: PropTypes.func.isRequired,
  batchUpdateYards: PropTypes.func.isRequired,
  openCreateYardModal: PropTypes.func.isRequired,
  openDeleteYardModal: PropTypes.func.isRequired,
  setUpdateMultipleYards: PropTypes.func.isRequired,
  setEditModeAction: PropTypes.func.isRequired,
  setZoomGlobal: PropTypes.func.isRequired,
  setCenterGlobal: PropTypes.func.isRequired,
  setAddMultipleYards: PropTypes.func.isRequired,
  resetYardData: PropTypes.func.isRequired,
  makeShowSnackbarAction: PropTypes.func.isRequired,

  fetchBeeTrackYardMap: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const Gmap = GoogleApiWrapper({
  apiKey: GMAP_API_KEY,
  language: 'en',
  region: 'CA',
  libraries: DEFAULT_GOOGLE_MAPS_API_OPTIONS.libraries,
})(GmapContainer);

/**
 *
 * @param dispatch
 * @returns {{toggleMapType: toggleMapType}}
 * @returns {{openCreateYardModal: makeOpenCreateYardModalAction}}
 * @returns {{openDeleteYardModal: makeOpenDeleteYardModalAction}}
 * @returns {{batchUpdateYards: makeBatchUpdateYardsRequestThunk}}
 * @returns {{setUpdateMultipleYards: setUpdateMultipleYards}}
 * @returns {{setEditModeAction: setEditModeAction}}
 * @returns {{resetYardData: resetYardData}}
 * @returns {{makeShowSnackbarAction: makeShowSnackbarAction}}
 *
 *
 */
const mapDispatchToProps = (dispatch) => ({
  toggleMapType: () => {
    dispatch(toggleMapType());
  },
  batchUpdateYards: (detail, user) => {
    return dispatch(makeBatchUpdateYardsRequestThunk(detail, user));
  },
  openCreateYardModal: (content) => {
    dispatch(makeOpenCreateYardModalAction(content));
  },
  openDeleteYardModal: (content) => {
    dispatch(makeOpenDeleteYardModalAction(content));
  },
  setUpdateMultipleYards: (patch, yards) => {
    dispatch(setUpdateMultipleYards(patch, yards));
  },
  setEditModeAction: (editMode) => {
    dispatch(setEditModeAction(editMode));
  },
  setZoomGlobal: (zoom) => {
    dispatch(setZoomGlobal(zoom));
  },
  setCenterGlobal: (lat, lng) => {
    dispatch(setCenterGlobal(lat, lng));
  },
  setAddMultipleYards: (add, yards) => {
    dispatch(setAddMultipleYards(add, yards));
  },
  resetYardData: () => {
    dispatch(resetYardData());
  },
  makeShowSnackbarAction: (msg) => {
    dispatch(makeShowSnackbarAction(msg));
  },
  fetchBeeTrackYardMap: (history) => {
    dispatch(makeFetchYardMapRequestThunk());
  },
});

const mapStateToProps = (state) => ({
  type: state.mapReducer.type,
  zoom: state.mapReducer.zoom,
  lat: state.mapReducer.lat,
  lng: state.mapReducer.lng,
  patch: state.beeTrackYardListReducer.patch,
  add: state.beeTrackYardListReducer.add,
  remove: state.beeTrackYardListReducer.remove,
  user: state.accountReducer.user,
  yards: state.beeTrackYardListReducer.yards,
  hives: state.beeTrackYardListReducer.hives,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gmap));
