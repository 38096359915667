import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Edit } from '@components/common/Icon/presets/Edit';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Page404 } from '@components/common/Page404';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { PageHeaderProvider } from '@components/common/PageHeader/contex';
import { Tab, Tabs } from '@components/common/Tabs';
import { Text } from '@components/common/Text';
import { ModalFormContract } from '@components/pollination/FormContract/ModalFormContract';
import { PolliContractDetails } from '@components/pollination/PolliContractDetails';
import { ContractActions } from '@components/pollination/PolliContractDetails/ContractActions';
import { PolliDropsTable } from '@components/pollination/PolliDropsTable';
import { PolliMap } from '@components/pollination/PolliMap';
import APP from '@config/constants';
import { useDateUtil } from '@helpers/Date/hooks';
import { useContract, useContractFetcher, useContractId } from '@hooks/useContract';

const PolliContractPage: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const uid = useContractId();
  const { contract, isFetchingContract } = useContract(uid);

  return (
    <PageHeaderProvider>
      <PageHeader>
        <Box fit alignItems={'center'} overflow={'hidden'} gap_100>
          {!contract || isFetchingContract ? (
            <LoadingSkeleton margin_050>
              <LoadingSkeletonRectangle width={192} height={36} paper02 backgroundColor={'grey07'} />
            </LoadingSkeleton>
          ) : (
            <PageHeaderTitle enableTruncate fit={false}>
              {t('contract_and_name', { name: contract?.name })}
            </PageHeaderTitle>
          )}
          {!!contract?.archivedAt && (
            <Box paper01 backgroundColor={'grey05'} paddingHorizontal_050 paddingVertical_025>
              <Text typography={'CaptionSmall'} weight={'700'}>
                {t('archived')}
              </Text>
            </Box>
          )}
        </Box>
        {contract && !contract?.archivedAt && <ContractActions />}
      </PageHeader>
      {children}
      <ModalFormContract />
    </PageHeaderProvider>
  );
};

export const PolliContract: React.FC = () => {
  const { t } = useTranslation();
  const uid = useContractId();
  const dateUtil = useDateUtil();
  const { contract, hasFetchError, isFetchingContract } = useContract(uid);

  useContractFetcher();

  const archivedWarning = contract?.archivedAt ? (
    <AlertCard sticky warning>
      {t('pollination_contract_archived_warning', {
        date: dateUtil.getFormattedDate(contract.archivedAt, 'MMM DD, YYYY'),
      })}
    </AlertCard>
  ) : null;

  if (!isFetchingContract && hasFetchError) {
    return <Page404 />;
  }

  return (
    <PolliContractPage>
      <Tabs useURLPath>
        <Tab id={'contract'} title={t('information')} path={APP.routes.pollinationContractsDetailsInformation}>
          {archivedWarning}
          <PolliContractDetails />
        </Tab>

        {!contract?.archivedAt && (
          <Tab id={'drops'} title={t('pollination_drops')} path={APP.routes.pollinationContractsDetailsDrops}>
            <PolliDropsTable />
          </Tab>
        )}

        <Tab
          id={'map'}
          title={t('pollination_map')}
          path={APP.routes.pollinationContractsDetailsPollinationMap}
          suppressScrollY
        >
          {archivedWarning}
          <Box fit column stretch relative>
            <PolliMap readonly={!contract || !!contract.archivedAt} />
          </Box>
        </Tab>
      </Tabs>
    </PolliContractPage>
  );
};
