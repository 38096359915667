import React, { useCallback, useContext, useEffect } from 'react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ModalInnerContext } from '@components/common/ModalBase/context';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';

import { StyledModalFooter } from './styles';
import { ModalFooterProps } from './types';

export const ModalFooter: React.FC<ModalFooterProps> = ({
  acceptText,
  rejectText,
  acceptButtonProps,
  rejectButtonProps,
  autoCloseOnAccept,
  autoCloseOnReject,
  onAcceptClick,
  onRejectClick,
  children,
}) => {
  const innerContext = useContext(ModalInnerContext);

  const { isMobile } = useGetScreenWidth();
  const showRejectButton = !isMobile && !!rejectText;

  const handleAccept = useCallback(() => {
    autoCloseOnAccept && innerContext?.close();
    onAcceptClick && onAcceptClick();
  }, [autoCloseOnAccept, innerContext, onAcceptClick]);

  const handleReject = useCallback(() => {
    autoCloseOnReject && innerContext?.close();
    onRejectClick && onRejectClick();
  }, [autoCloseOnReject, innerContext, onRejectClick]);

  return (
    <StyledModalFooter>
      <Box fit>{children}</Box>

      {(showRejectButton || !!acceptText) && (
        <Box center gap_100>
          {showRejectButton && (
            <Button tertiary onClick={handleReject} {...rejectButtonProps}>
              {rejectText}
            </Button>
          )}
          {!!acceptText && (
            <Button primary onClick={handleAccept} {...acceptButtonProps}>
              {acceptText}
            </Button>
          )}
        </Box>
      )}
    </StyledModalFooter>
  );
};
