import React, { FormEventHandler, useCallback, useMemo, useState } from 'react';
import produce from 'immer';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { useNonPropagatedOnClick } from '@components/common/CTA/hooks';
import { Help } from '@components/common/Icon/presets/Help';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { Checkbox } from '@components/form/inputs/CheckBox';
import { RadioButton } from '@components/form/inputs/RadioButton';
import { useYardsFiltersDefinitions } from '@components/yard/YardsFilters/hooks';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useCropTypeFetcher } from '@hooks/useCropTypes';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useGroupsFetcher } from '@hooks/useGroups';
import { useTranslation } from '@hooks/useTranslation';
import {
  makeClearAppliedFiltersAction,
  makeClearTransientFiltersAction,
  makePatchAppliedFiltersAction,
  makePatchTransientFiltersAction,
} from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';
import { YardsFiltersCategoryKey, YardsFilterValuesKey } from '@redux/YardsFilters/types';

import {
  StyledClearFiltersButton,
  StyledClearFiltersButtonText,
  StyledFilterItem,
  StyledFilterSection,
  StyledFilterSectionContent,
  StyledFilterSectionContentInner,
  StyledFilterSectionHeader,
  StyledFiltersForm,
  StyledFiltersFormContent,
  StyledFiltersFormHeader,
} from './styles';

const DEF_SECTION_PREVIEW_SIZE = 4;

export const YardsFiltersModal: React.VFC<Omit<ModalProps, 'children'>> = ({ ...props }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const filtersDefinitions = useYardsFiltersDefinitions();
  const { isFetching, transientAvailableFilters, transientAppliedFilters } = useYardsFilters();
  const { isFetching: isFetchingGroups } = useGroupsFetcher();
  const { isFetching: isFetchingCropTypes } = useCropTypeFetcher();

  const { isMobile } = useGetScreenWidth();
  const [showMore, setShowMore] = useState<Record<string, boolean>>({});

  const filtersDefinitionsTruncated = useMemo(() => {
    return filtersDefinitions.map((section) => {
      const canTruncate = isMobile && section.values.length > DEF_SECTION_PREVIEW_SIZE;
      const shouldTruncate = !showMore[section.categoryKey] && canTruncate;
      return shouldTruncate
        ? { ...section, truncated: true, canTruncate, values: section.values.slice(0, DEF_SECTION_PREVIEW_SIZE) }
        : { ...section, truncated: false, canTruncate };
    });
  }, [filtersDefinitions, isMobile, showMore]);

  const toggleSectionSeeMore = useCallback((sectionKey: string) => {
    setShowMore((curr) => ({ ...curr, [sectionKey]: !curr[sectionKey] }));
  }, []);

  const onFiltersSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      dispatch(makeClearAppliedFiltersAction());
      dispatch(makePatchAppliedFiltersAction(transientAppliedFilters));

      const filteredSections = Object.entries(transientAppliedFilters)
        .filter(([, sectionItems]) => sectionItems.length > 0)
        .map(([sectionKey]) => sectionKey)
        .join(', ');

      if (filteredSections) {
        Analytics.sendEvent({
          event: AnalyticsEventType.WHITEBOARD_FILTER,
          eventData: {
            sections: filteredSections,
          },
        });
      }

      props.onRequestClose && props.onRequestClose();
    },
    [dispatch, props, transientAppliedFilters]
  );

  const onFilterCancel = useCallback(() => {
    dispatch(makeClearTransientFiltersAction());
    props.onRequestClose && props.onRequestClose();
  }, [dispatch, props]);

  const onFilterChange = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: string, value: boolean) => {
      const newTransientFilters = produce(transientAppliedFilters, (current) => {
        const filterDefinition = filtersDefinitions.find(({ categoryKey: key }) => key === categoryKey);

        if (filterDefinition?.canSelectOnlyOneValue) {
          current[categoryKey] = [valueKey];
        } else {
          const currentValues = new Set(current[categoryKey] ?? []);
          value ? currentValues.add(valueKey) : currentValues.delete(valueKey);
          current[categoryKey] = Array.from(currentValues);
        }
      });
      dispatch(makePatchTransientFiltersAction(newTransientFilters));
    },
    [filtersDefinitions, transientAppliedFilters, dispatch]
  );

  const isFilterActive = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: any) =>
      (transientAppliedFilters[categoryKey] ?? []).includes(valueKey),
    [transientAppliedFilters]
  );

  const isFilterDisabled = useCallback(
    (categoryKey: YardsFiltersCategoryKey, valueKey: YardsFilterValuesKey) => {
      const values = (transientAvailableFilters[categoryKey]?.values ?? {}) as Record<string, boolean>;
      return !values[valueKey];
    },
    [transientAvailableFilters]
  );

  const clearButton = (
    <StyledClearFiltersButton type="button" onClick={() => dispatch(makeClearTransientFiltersAction())}>
      <StyledClearFiltersButtonText>{t('clear')}</StyledClearFiltersButtonText>
    </StyledClearFiltersButton>
  );

  return (
    <Modal useFullHeight {...props}>
      <ModalHeader mobileTitle={t('filters')} mobileLeftContent={<Box marginHorizontal_050>{clearButton}</Box>} />

      <ModalContent>
        <StyledFiltersForm id={'filters-form'} onSubmit={onFiltersSubmit}>
          <ResponsiveRender from={'tablet'}>
            <StyledFiltersFormHeader>
              <Text typography={'Heading2'} weight={'600'}>
                {t('yard_filters')}
              </Text>
              {clearButton}
            </StyledFiltersFormHeader>
          </ResponsiveRender>

          <StyledFiltersFormContent>
            {filtersDefinitionsTruncated.map(
              ({ title, tooltip, categoryKey, values, canSelectOnlyOneValue, canTruncate, truncated }) => (
                <StyledFilterSection key={categoryKey} initiallyExpanded>
                  <StyledFilterSectionHeader>
                    {title && (
                      <Box alignItems={'center'}>
                        <Text typography={'SmallParagraph'} weight={'600'}>
                          {capitalize(title)}
                        </Text>
                        <TitleHintButton tooltip={tooltip} />
                      </Box>
                    )}
                  </StyledFilterSectionHeader>

                  <StyledFilterSectionContent>
                    <StyledFilterSectionContentInner>
                      {values.map(({ label, tooltip, valueKey }) => {
                        const inputKey = `${categoryKey}-${valueKey}`;
                        const value = isFilterActive(categoryKey, valueKey);
                        const isDisabled = isFilterDisabled(categoryKey, valueKey);
                        const onChange = (value: boolean) => onFilterChange(categoryKey, valueKey, value);
                        const inputComponent = canSelectOnlyOneValue ? (
                          <RadioButton
                            name={`${categoryKey}`}
                            id={inputKey}
                            value={value}
                            disabled={isDisabled}
                            onChange={onChange}
                          />
                        ) : (
                          <Checkbox name={inputKey} value={value} disabled={isDisabled} onChange={onChange} />
                        );

                        return (
                          <StyledFilterItem key={inputKey} isDisabled={isDisabled}>
                            <Box marginRight_100>{inputComponent}</Box>
                            <Text typography={'Paragraph'} color={isDisabled ? 'grey05' : 'grey08'} dashed={!!tooltip}>
                              {capitalize(label)}

                              {!!tooltip && (
                                <Tooltip>
                                  <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
                                </Tooltip>
                              )}
                            </Text>
                          </StyledFilterItem>
                        );
                      })}

                      {canTruncate && (
                        <Button
                          type={'button'}
                          tertiary
                          suppressPadding
                          onClick={() => toggleSectionSeeMore(categoryKey)}
                        >
                          {truncated ? t('see_more') : t('see_less')}
                        </Button>
                      )}
                    </StyledFilterSectionContentInner>
                  </StyledFilterSectionContent>
                </StyledFilterSection>
              )
            )}
          </StyledFiltersFormContent>
        </StyledFiltersForm>
      </ModalContent>
      <ModalFooter
        rejectText={t('cancel')}
        rejectButtonProps={{ type: 'button' }}
        acceptText={t('apply')}
        acceptButtonProps={{ type: 'submit', form: 'filters-form' }}
        onRejectClick={onFilterCancel}
      />
      <Loading visible={isFetching || isFetchingGroups || isFetchingCropTypes} whiteBackground />
    </Modal>
  );
};

function TitleHintButton({ tooltip }: { tooltip?: string }) {
  const nonPropagatedClick = useNonPropagatedOnClick<HTMLButtonElement>();

  if (!tooltip) return null;

  return (
    <Box tag={'span'} marginLeft_050>
      <Button {...nonPropagatedClick} suppressPadding type={'button'}>
        <Help size={16} color={'grey06'} />

        <Tooltip>
          <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltip }} />
        </Tooltip>
      </Button>
    </Box>
  );
}
