import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledTitle = styled(Text)(
  () => `
  margin-bottom: 30px;
`
);

export const StyledTableWrapper = styled(Box)(
  ({ theme }) => `
  margin: 74px 0 ${theme.spacing._200};
`
);

export const StyledTableEmptyCardWrapper = styled(Box)(
  () => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
);

export const StyledTableEmptyCard = styled(Box)(
  ({ theme }) => `
  width: 516px;
  height: 236px;
  background-color: ${theme.primitives.colors.white};
  border-radius: ${theme.shape.paperRadius02}px;
`
);

export const GradingCircle = styled(Box)<{ visible?: boolean }>(
  ({ theme, visible }) => `
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    opacity: ${visible ? 1 : 0};
    margin: ${theme.spacing._025};
    padding: 0 ${theme.spacing._025};
    color: rgba(0, 0, 0, 0.7);
  `
);
