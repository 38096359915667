import { useMemo } from 'react';
import { Color, CSSObject, DefaultTheme, Spacing, useTheme } from 'styled-components';

import { BoxDiv, BoxForm, BoxImage, BoxLabel, BoxSpan } from '@components/common/Box/styles';

import {
  BoxDefaultProps,
  BoxFunctionalComponent,
  BoxProps,
  BoxPropsWithRef,
  BoxTag,
  CachedStyleValue,
  PropStyleMap,
  StyleGetter,
} from './types';

const SHORTCUT_PROPS_REGEX =
  /^(debug|relative|fit|center|column|stretch|clickable|paper01|paper02|paper03|block|grid|wrap|fullWidth|fullHeight)$/;
const CSS_SHORTCUT_PROPS_REGEX = /^(flex|flexGrow|alignItems|justifyContent|gap|borderColor|borderWidth|overflow)$/;
const COLOR_PROPS_REGEX = /^(color|backgroundColor|borderColor)$/;
const SHADOW_PROPS_REGEX = /^(shadow)$/;
const SPACING_PROPS_REGEX = /^(gap|padding|margin|borderRadius)(.*?)(_[0-9]{1,5})$/;

const BOX_DEFAULT_STYLE: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  borderStyle: 'solid',
};

const BOX_DEFAULT_TAG_STYLE: Record<BoxTag, CSSObject | null> = {
  div: null,
  span: null,
  img: null,
  form: null,
  label: {
    cursor: 'pointer',
  },
};

const BOX_SHORTCUT_STYLES = (theme: DefaultTheme): PropStyleMap<any> => ({
  relative: { position: 'relative' },
  fit: { flex: 1 },
  center: { alignItems: 'center', justifyContent: 'center' },
  column: { flexDirection: 'column' },
  stretch: { alignItems: 'stretch' },
  clickable: { cursor: 'pointer' },
  paper01: {
    backgroundColor: theme.primitives.colors.white,
    borderRadius: theme.shape.paperRadius01,
  },
  paper02: {
    backgroundColor: theme.primitives.colors.white,
    borderRadius: theme.shape.paperRadius02,
  },
  paper03: {
    backgroundColor: theme.primitives.colors.white,
    borderRadius: theme.shape.paperRadius02,
  },
  debug: {
    borderWidth: 1,
    borderColor: theme.primitives.colors.grey08,
    borderStyle: 'dashed',
  },
  block: {
    display: 'block',
  },
  grid: {
    display: 'grid',
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  wrap: {
    flexWrap: 'wrap',
  },
});

export function useBoxProps<T extends BoxTag>(props: BoxPropsWithRef<T>): any {
  const _props: any = {};
  for (const [prop, value] of Object.entries(props)) {
    _props[prop] = typeof value === 'boolean' ? String(value) : value;
  }
  return _props;
}

export function useBoxComponent<T extends BoxTag>(tag?: T): BoxFunctionalComponent<T> {
  switch (tag) {
    case 'span':
      return BoxSpan as BoxFunctionalComponent<T>;
    case 'label':
      return BoxLabel as BoxFunctionalComponent<T>;
    case 'img':
      return BoxImage as BoxFunctionalComponent<T>;
    case 'form':
      return BoxForm as BoxFunctionalComponent<T>;
    default:
      return BoxDiv as BoxFunctionalComponent<T>;
  }
}

export function useBoxStyle<T extends BoxTag>(tag: BoxTag | undefined, props: BoxProps<T>): CSSObject {
  return {
    ...BOX_DEFAULT_STYLE,
    ...BOX_DEFAULT_TAG_STYLE[tag ?? 'div'],
    ...useBoxShortcutStyles(props),
    ...useBoxCSSShortcutStyles(props),
    ...useBoxSpacingStyles(props),
    ...useBoxColorStyles(props),
    ...useBoxShadowStyles(props),
  };
}

function useBoxShortcutStyles<T extends BoxTag>(props: BoxProps<T>): CSSObject | null {
  const theme = useTheme();

  const boxShortcutStyles = BOX_SHORTCUT_STYLES(theme);
  const styleGetter = buildShortcutStyleGetter(boxShortcutStyles);
  return useCachedStyle(props, SHORTCUT_PROPS_REGEX, styleGetter);
}

function useBoxCSSShortcutStyles<T extends BoxTag>(props: BoxProps<T>): CSSObject | null {
  const styleGetter = buildCSSShortcutStyleGetter();
  return useCachedStyle(props, CSS_SHORTCUT_PROPS_REGEX, styleGetter);
}

function useBoxSpacingStyles<T extends BoxTag>(props: BoxProps<T>): CSSObject | null {
  const theme = useTheme();
  const styleGetter = buildSpacingStyleGetter(theme);
  return useCachedStyle(props, SPACING_PROPS_REGEX, styleGetter);
}

function useBoxColorStyles<T extends BoxTag>(props: BoxProps<T>): CSSObject | null {
  const theme = useTheme();
  const styleGetter = buildColorStyleGetter(theme);
  return useCachedStyle(props, COLOR_PROPS_REGEX, styleGetter);
}

function useBoxShadowStyles<T extends BoxTag>(props: BoxProps<T>): CSSObject | null {
  const theme = useTheme();
  const styleGetter = buildShadowStyleGetter(theme);
  return useCachedStyle(props, SHADOW_PROPS_REGEX, styleGetter);
}

function buildShortcutStyleGetter<T extends BoxTag>(propStyleMap: PropStyleMap<T>): StyleGetter<T> {
  return (props) => {
    debug(props, 'Building shortcut styles...');

    let style: CSSObject = {};
    const mappedProps = Object.keys(propStyleMap) as Array<keyof PropStyleMap<T>>;
    for (const prop of mappedProps) {
      if (props[prop]) {
        style = { ...style, ...propStyleMap[prop] };
      }
    }
    return style;
  };
}

function buildCSSShortcutStyleGetter<T extends BoxTag>(): StyleGetter<T> {
  return (props) => {
    debug(props, 'Building CSS shortcut styles...');

    const style = {} as CSSObject;
    const keys = Object.keys(props);
    for (const key of keys) {
      if (CSS_SHORTCUT_PROPS_REGEX.test(key)) {
        style[key] = (props as any)[key];
      }
    }
    return style;
  };
}

function buildSpacingStyleGetter<T extends BoxTag>(theme: DefaultTheme): StyleGetter<T> {
  return (props) => {
    debug(props, 'Building spacing styles...');

    let style = {} as CSSObject;
    const keys = Object.keys(props) as Array<keyof typeof props>;
    for (const key of keys) {
      const match = key.match(SPACING_PROPS_REGEX);
      if (match && props[key]) {
        const [propPrefix, propVariation, propValue] = match.slice(1);
        const spacing = theme.spacing[propValue.toLowerCase() as Spacing];

        if (propVariation === 'Vertical') {
          if (propPrefix === 'gap') {
            style = { ...style, rowGap: spacing };
          } else {
            style = { ...style, [`${propPrefix}Top`]: spacing, [`${propPrefix}Bottom`]: spacing };
          }
        } else if (propVariation === 'Horizontal') {
          if (propPrefix === 'gap') {
            style = { ...style, columnGap: spacing };
          } else {
            style = { ...style, [`${propPrefix}Left`]: spacing, [`${propPrefix}Right`]: spacing };
          }
        } else {
          style = { ...style, [`${propPrefix}${propVariation}`]: spacing };
        }
      }
    }

    return style;
  };
}

function buildColorStyleGetter<T extends BoxTag>(theme: DefaultTheme): StyleGetter<T> {
  return (props) => {
    debug(props, 'Building color styles...');

    const getColor = (color?: Color | string | null) =>
      color ? (theme.colors as any)[color] ?? (theme.primitives.colors as any)[color] ?? props.color : undefined;

    return {
      color: getColor(props.color),
      backgroundColor: getColor(props.backgroundColor),
      borderColor: getColor(props.borderColor),
    } as CSSObject;
  };
}

function buildShadowStyleGetter<T extends BoxTag>(theme: DefaultTheme): StyleGetter<T> {
  return (props) => {
    debug(props, 'Building shadow styles...');

    return {
      boxShadow: props.shadow ? theme.shadows[props.shadow] : undefined,
    } as CSSObject;
  };
}

function useCachedStyle<T extends BoxTag>(
  props: BoxProps<T>,
  propsRegex: RegExp,
  getStyle: StyleGetter<T>
): CSSObject | null {
  const cache = useMemo<CachedStyleValue<T>>(() => ({ style: null, props: null }), []);

  if (!arePropsEqual(props, cache.props, propsRegex)) {
    cache.style = getStyle(props);
    cache.props = props;
  }

  return cache.style;
}

function arePropsEqual<T extends BoxTag>(
  propsA: BoxDefaultProps<T> | null,
  propsB: BoxDefaultProps<T> | null,
  propsRegex: RegExp
): boolean {
  const pA = propsA || {};
  const pB = propsB || {};
  const keys = [...Object.keys(pA || {}), ...Object.keys(pB || {})] as Array<keyof BoxDefaultProps<T>>;
  const matchingKeys = keys.filter((key) => propsRegex.test(String(key)));
  return matchingKeys.every((key) => pA[key] === pB[key]);
}

function debug<T extends BoxTag>(props: BoxDefaultProps<T>, ...args: Array<any>) {
  if (props.debug) {
    console.log('Box:', ...args);
  }
}
