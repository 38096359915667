import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ButtonProps } from '@components/common/CTA/types';

import { Box } from '../Box';
import { ArrowDropdown } from '../Icon/presets/ArrowDropdown';

import { Button } from './Button';

const StyledMenuTriggerIconWrap = styled.span<{ $isMenuOpen?: boolean }>(
  ({ theme, $isMenuOpen }) => css`
    border-left: 1px solid;
    height: 100%;
    display: block;
    padding: 12px 8px; // TODO: Update along with the new DS tokens
    margin-left: ${theme.spacing._100};
    line-height: 0;

    svg {
      transition: ${theme.animations.transitionMedium('transform')};
      transform: ${$isMenuOpen ? 'rotateX(-180deg)' : '0'};
    }
  `
);

export const MenuTrigger: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const validateComponentUsage = useCallback(() => {
    setTimeout(() => {
      if (buttonRef.current && !buttonRef.current.hasAttribute('aria-expanded')) {
        console.error('MenuTrigger component must be used as a trigger to open a Menu or Tooltip instance.');
      }
    }, 400); // Give time for the tooltip to be mounted.
  }, []);

  useLayoutEffect(() => {
    validateComponentUsage();
  }, [validateComponentUsage]);

  useLayoutEffect(() => {
    const buttonElement = buttonRef.current;
    if (!buttonElement) {
      return;
    }

    const handleAttrsChange: MutationCallback = () =>
      setIsMenuOpen(buttonElement.getAttribute('aria-expanded') === 'true');

    const observer = new MutationObserver(handleAttrsChange);
    observer.observe(buttonElement, { attributes: true, attributeFilter: ['aria-expanded'] });
  }, []);

  return (
    <Button {...props} ref={buttonRef} secondary suppressPadding>
      <Box tag="span" paddingVertical_050 paddingLeft_100>
        {children}
      </Box>
      <StyledMenuTriggerIconWrap $isMenuOpen={isMenuOpen}>
        <ArrowDropdown />
      </StyledMenuTriggerIconWrap>
    </Button>
  );
};
