import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { DataStatusAction, DataStatusActionType, DataStatusFilters, DataStatusType } from '@redux/DataStatus/types';

export const makeDataStatusNotifyUpdatedAction = (key: string, timestamp: number): DataStatusAction => {
  return {
    type: DataStatusActionType.DATA_STATUS_NOTIFY_UPDATED,
    payload: { key, timestamp },
  };
};

export const makeDataStatusNotifyOutdatedAction = (key: string, timestamp: number): DataStatusAction => {
  return {
    type: DataStatusActionType.DATA_STATUS_NOTIFY_OUTDATED,
    payload: { key, timestamp },
  };
};

export const makeDataStatusFetchThunk = Thunk.createTakeFirst(
  (key: string, frozenAt: string, filters?: DataStatusFilters) => {
    return async (dispatch, getState) => {
      const statuses = getState().dataStatusReducer.statuses[key] ?? [];
      const latestStatus = statuses.slice(-1)[0];

      // In case there is no last status, it means the
      // resource is being seen for the first time, thus
      // nothing can be considered 'outdated'.
      if (!latestStatus) {
        dispatch(makeDataStatusNotifyUpdatedAction(key, +new Date()));
        return;
      }

      // In case it's already known that the data is
      // outdated, skip the check.
      if (latestStatus?.type === DataStatusType.DATA_OUTDATED) {
        return;
      }

      const response = await Api.get(
        URLUtil.buildURL(ENDPOINTS.hhtActivitiesStatus, {
          yard_id: filters?.yardId ?? null,
          frozen_at: frozenAt,
        })
      );
      if (!response.error) {
        const { hasNewActivities } = CaseAdapter.objectToCamelCase(await response.json());
        if (hasNewActivities) {
          dispatch(makeDataStatusNotifyOutdatedAction(key, +new Date()));
        }
        return hasNewActivities;
      }
    };
  }
);
