import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { YardCreateOrUpdateAction, YardCreateOrUpdateActionType } from '@redux/YardCreateOrUpdate/types';

export const makeYardCreateOrUpdateDisposeAction = (): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_DISPOSE,
});

export const makeYardCreateOrUpdateOpenModalAction = (): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_OPEN_MODAL,
});

export const makeYardCreateOrUpdateCloseModalAction = (): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_CLOSE_MODAL,
});

export const makeYardCreateOrUpdateFetchDetailStartAction = (): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_START,
});

export const makeYardCreateOrUpdateFetchDetailFinishAction = (
  detail: BeeYardDetails | null
): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_FINISH,
  payload: { detail },
});

export const makeYardCreateOrUpdateSaveDetailStartAction = (): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_START,
});

export const makeYardCreateOrUpdateSaveDetailFinishAction = (
  error: ApiResponseError | null
): YardCreateOrUpdateAction => ({
  type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_FINISH,
  payload: { error },
});

export const makeYardCreateOrUpdateFetchDetailThunk = Thunk.createTakeFirst((id: number) => {
  return async (dispatch) => {
    dispatch(makeYardCreateOrUpdateFetchDetailStartAction());

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.hhtYardDetail, { id }));

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      dispatch(makeYardCreateOrUpdateFetchDetailFinishAction(null));
    } else {
      const data = parseYardDetailsFromAPI(await response.json());
      dispatch(makeYardCreateOrUpdateFetchDetailFinishAction(data));
    }
  };
});

export const makeYardCreateOrUpdateSaveDetailThunk = Thunk.createTakeFirst((data: Partial<BeeYardDetails>) => {
  return async (dispatch) => {
    dispatch(makeYardCreateOrUpdateSaveDetailStartAction());

    const url = data.id
      ? URLUtil.buildURL(ENDPOINTS.hhtYardDetail, { id: data.id })
      : URLUtil.buildURL(ENDPOINTS.hhtYards);
    const method = data.id ? Api.patch : Api.post;
    const response = await method(url, parseYardDetailsToAPI(data));

    dispatch(makeYardCreateOrUpdateSaveDetailFinishAction(response.error));

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      if (data.id) {
        dispatch(
          makeShowSnackbarAction({
            messageTranslation: 'yard_updated',
            messageTranslationParams: { yard_name: data.name ?? '' },
          })
        );
      } else {
        dispatch(makeShowSnackbarAction('yard_created'));
      }
    }

    return response;
  };
});

function parseYardDetailsFromAPI(data: any): BeeYardDetails {
  const { deleted, ...camelCaseData } = CaseAdapter.objectToCamelCase(data);
  return {
    ...camelCaseData,
    isDeleted: deleted,
  };
}

function parseYardDetailsToAPI(data: Partial<BeeYardDetails>): any {
  return CaseAdapter.objectToSnakeCase(data);
}
