import { useMemo } from 'react';
import { FieldValues, Path, Resolver, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { ISchema, Reference } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@hooks/useTranslation';

export function useGlobalFormConfig() {
  const t = useTranslation();

  useMemo(
    () =>
      Yup.setLocale({
        mixed: {
          notType: (params) => {
            if (params.type === 'number') {
              return t('error_message_number');
            }
            const defFunction = Yup.defaultLocale.mixed?.default;
            return typeof defFunction === 'function' ? defFunction(params) : undefined;
          },
          required: t('error_message_required'),
        },
        string: {
          min: ({ min }) => t('error_message_min_length', { min }),
          max: ({ max }) => t('error_message_max_length', { max }),
          email: t('error_message_email'),
        },
        number: {
          min: ({ min }) => t('error_message_min', { min }),
          max: ({ max }) => t('error_message_max', { max }),
          integer: t('error_message_integer'),
          positive: t('error_message_positive'),
        },
      }),
    [t]
  );
}

export function useFormDefaultValues<FV extends FieldValues = FieldValues>(defaultValues: FV) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => defaultValues, []);
}

export function useFormSchemaResolver<FV extends FieldValues = FieldValues>(
  schemaCreator: (schema: typeof Yup) => { [k in keyof FV]: ISchema<any> | Reference }
) {
  const shape = useMemo(() => Yup.object(schemaCreator(Yup)), [schemaCreator]);
  const resolver = useMemo<Resolver<FV>>(() => {
    return yupResolver(shape) as any;
  }, [shape]);

  return { shape, resolver };
}

export function useFormFieldValue<FV extends FieldValues = FieldValues>(name: Path<FV>) {
  const form = useFormContext<FV>();
  const value = form.watch(name);

  return { value };
}

export function useFormFieldError<FV extends FieldValues = FieldValues>(name: Path<FV>) {
  const {
    formState: { errors },
  } = useFormContext<FV>();

  const error = errors[name] ?? null;
  const hasError = !!error?.message;

  return { error, hasError };
}
