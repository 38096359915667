import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button } from '@components/common/CTA';
import { Search } from '@components/common/Icon/presets/Search';
import { Modal, ModalFooter } from '@components/common/Modal';
import { useDropdownMobileElements, useDropdownMobileEvents } from '@components/form/inputs/InputAutocomplete/hooks';
import {
  StyledMobileFilterInput,
  StyledMobileFilterInputWrapper,
  StyledMobileModalContent,
  StyledMobileModalHeader,
  StyledMobileModalHeaderContent,
} from '@components/form/inputs/InputAutocomplete/styles';
import {
  InputAutocompleteDropdownMobileProps,
  InputAutocompleteDropdownRef,
} from '@components/form/inputs/InputAutocomplete/types';
import { useDebounce } from '@helpers/Throttling/hooks';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';

export const InputAutocompleteDropdownMobile = React.forwardRef<
  InputAutocompleteDropdownRef,
  InputAutocompleteDropdownMobileProps
>(function InputAutocompleteDropdownDesktop(
  {
    target,
    title,
    disabled,
    hasSelection,
    headerContent,
    footerVisible,
    filterName,
    filterVisible,
    onFilterChange,
    onClearRequest,
    onShown,
    onHidden,
    children,
  },
  ref
) {
  const t = useTranslation();
  const theme = useTheme();
  const inputWrapperRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const blurDebounce = useDebounce(theme.animations.durationMedium);
  const { isMobile } = useGetScreenWidth();

  const handleDropdownFocus = useCallback(() => {
    if (disabled) return;
    setIsOpen(true);
    onShown && onShown();

    setTimeout(() => inputWrapperRef.current?.querySelector('input')?.focus());
  }, [disabled, onShown]);

  const handleDropdownBlur = useCallback(() => {
    setIsOpen(false);

    // A small delay to wait the modal
    // to close completely.
    blurDebounce(() => onHidden && onHidden());
  }, [blurDebounce, onHidden]);

  const dropdownElements = useDropdownMobileElements(target);
  useDropdownMobileEvents({ ...dropdownElements }, { onFocus: handleDropdownFocus });

  useImperativeHandle(ref, () => ({
    isOpen,
    hide() {
      handleDropdownBlur();
    },
  }));

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={handleDropdownBlur} useFullHeight={filterVisible} alwaysRender>
        <StyledMobileModalHeader
          paddingTop_025={hasSelection}
          title={isMobile ? undefined : title}
          mobileTitle={title}
          mobileLeftContent={
            <Button onClick={onClearRequest} disabled={!hasSelection} tertiary>
              {t('clear')}
            </Button>
          }
        >
          {(filterVisible || hasSelection) && (
            <StyledMobileModalHeaderContent column stretch paddingBottom_100={!hasSelection}>
              {filterVisible && (
                <StyledMobileFilterInputWrapper
                  ref={inputWrapperRef}
                  alignItems={'center'}
                  padding_050
                  marginHorizontal_100
                  gap_050
                >
                  <Search />
                  <StyledMobileFilterInput
                    name={filterName}
                    onKeyUp={onFilterChange}
                    autoFocus={isOpen}
                    placeholder={t('search')}
                  />
                </StyledMobileFilterInputWrapper>
              )}
              {headerContent}
            </StyledMobileModalHeaderContent>
          )}
        </StyledMobileModalHeader>
        <StyledMobileModalContent column stretch paddingVertical_050 paddingHorizontal_050>
          {children}
        </StyledMobileModalContent>
        {footerVisible && <ModalFooter acceptText={t('apply')} onAcceptClick={handleDropdownBlur} />}
      </Modal>
    </>
  );
});
