import React, { useMemo } from 'react';

import { Polygon } from '@components/common/Map';
import { MarkerDescriptor, PolygonProps } from '@components/common/Map/types';

const DEF_YARD_GEOMETRY_PROPS = {
  fillColor: 'focus02',
  strokeWeight: 0,
};

export function YardPolygonRenderer(props: { polygonProps?: PolygonProps } & MarkerDescriptor<BeeYardOnMapInfo>) {
  const { data: yard, polygonProps } = props;

  const yardGeometryCoordinates = useMemo(() => yard.geometry.coordinates[0] ?? [], [yard.geometry.coordinates]);
  const yardGeometryPath = useMemo(
    () => yardGeometryCoordinates.map(([lng, lat]) => ({ lat, lng })),
    [yardGeometryCoordinates]
  );

  return <Polygon path={yardGeometryPath} {...DEF_YARD_GEOMETRY_PROPS} {...polygonProps} />;
}
