import React, { useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { useTheme } from 'styled-components';
import Tippy, { Instance, Props } from 'tippy.js';
import * as uuid from 'uuid';

import { StyledTooltipRoot, StyledTooltipView } from '@components/common/Tooltip/styles';
import { TooltipProps, TooltipRef } from '@components/common/Tooltip/types';
import { QueryParams } from '@helpers/QueryParams';
import { useDebounce } from '@helpers/Throttling/hooks';

import 'tippy.js/animations/scale.css';

export const Tooltip = React.forwardRef<TooltipRef, React.PropsWithChildren<TooltipProps>>(
  (
    {
      target,
      appendTo,
      appendToTarget,
      renderContent,
      interactive,
      hideOnClick,
      toggleMode,
      className,
      placement,
      children,
      disabled,
      onHidden,
      onShown,
      onHide,
      onShow,
      trigger,
      offset,
      arrow,
      light,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const [uid] = useState(() => uuid.v4());
    const [tooltipAnchorId] = useState(() => `tooltip-anchor-${uid}`);
    const [tooltipPortalId] = useState(() => `tooltip-portal-${uid}`);
    const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null);
    const [parentElement, setParentElement] = useState<HTMLElement | null>(null);

    const instance = useRef<Instance | null>(null);
    const rootRef = useRef<HTMLDivElement>(null);

    const [tooltipPortal] = useState(() => {
      const portal = document.createElement('div');
      portal.id = tooltipPortalId;
      return portal;
    });

    // Helper for the design team. If ?sticky-tooltips=true is present in the
    // url query params, all tooltips will appear and keep sticky.
    const enableStickyTooltips = useRef(QueryParams.getCurrentQueryParams()['sticky-tooltips']);

    const tooltipVisibilityDebounce = useDebounce(theme.animations.durationFast);

    const animateTooltipVisibility = useCallback(
      (animationType: 'fade-in' | 'fade-out') => {
        if (!['fade-in', 'fade-out'].includes(animationType)) return;

        const [from, to] = animationType === 'fade-in' ? ['0', '1'] : ['1', '0'];
        const tooltip = instance.current?.popper.firstElementChild as HTMLElement;
        tooltip.style.transition = `opacity ${theme.animations.durationFast}ms ${theme.animations.easing}`;
        tooltip.style.opacity = from;
        setTimeout(() => {
          tooltip.style.opacity = to;
        });
      },
      [theme.animations.durationFast, theme.animations.easing]
    );

    const handleTooltipHide = useCallback(
      (instance: Instance) => {
        onHide && onHide(instance);
        animateTooltipVisibility('fade-out');
        tooltipVisibilityDebounce(() => instance.unmount());
      },
      [animateTooltipVisibility, onHide, tooltipVisibilityDebounce]
    );

    const handleTooltipShow = useCallback(
      (instance: Instance) => {
        onShow && onShow(instance);
        animateTooltipVisibility('fade-in');
        tooltipVisibilityDebounce(() => instance.props.onShown && instance.props.onShown(instance));
      },
      [animateTooltipVisibility, onShow, tooltipVisibilityDebounce]
    );

    const buildTooltipProps = useCallback(() => {
      return {
        allowHTML: true,
        animation: 'fade',
        trigger: trigger ?? (interactive ? 'click focus' : 'mouseenter focus'),
        interactive,
        placement,
        offset,
        hideOnClick: toggleMode ? 'toggle' : trigger != 'manual',
        render: () => ({
          popper: tooltipPortal,
        }),
        onShown,
        onHidden,
        onHide: handleTooltipHide,
        onShow: handleTooltipShow,
        ...props,
      } as Partial<Props>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      handleTooltipHide,
      handleTooltipShow,
      interactive,
      offset,
      onHidden,
      onShown,
      placement,
      toggleMode,
      tooltipPortal,
      trigger,
    ]);

    const updateElements = useCallback(() => {
      const triggerElement = (target ? document.getElementById(target) : rootRef.current?.parentElement) ?? null;
      const parentElement =
        (appendToTarget ? triggerElement : document.getElementById(appendTo) || document.body) ?? null;
      setTriggerElement(triggerElement);
      setParentElement(parentElement);
    }, [appendTo, appendToTarget, target]);

    const updateTooltip = useCallback(() => {
      if (triggerElement && parentElement) {
        if (!instance.current) {
          instance.current = Tippy(triggerElement, { appendTo: parentElement, ...buildTooltipProps() });
        } else {
          instance.current?.setProps({ ...buildTooltipProps() });
        }
      }
    }, [buildTooltipProps, parentElement, triggerElement]);

    const updateStickyTooltips = useCallback(() => {
      if (!enableStickyTooltips.current) return;
      const defProps = { ...(instance.current?.props ?? {}) };

      const _onShown = (instance: Instance) => {
        onShown && onShown(instance);
        instance.setProps({
          interactive: true,
          trigger: 'click',
        });
      };
      const _onHidden = (instance: Instance) => {
        onHidden && onHidden(instance);
        instance.setProps({ ...defProps, onShown: _onShown, onHidden: _onHidden });
      };
      instance.current?.setProps({
        onShown: _onShown,
        onHidden: _onHidden,
      });
    }, [onHidden, onShown]);

    const handleTooltipClick = useCallback(() => {
      hideOnClick && instance.current?.hide();
    }, [hideOnClick]);

    const updateTooltipClickHandler = useCallback(() => {
      const handler = () => handleTooltipClick();
      tooltipPortal.addEventListener('click', handler);
      return () => {
        tooltipPortal.removeEventListener('click', handler);
      };
    }, [handleTooltipClick, tooltipPortal]);

    const destroyTooltip = useCallback(() => {
      instance.current?.destroy();
      instance.current = null;
    }, []);

    useLayoutEffect(() => {
      if (!disabled) {
        updateElements();
        updateTooltip();
        updateStickyTooltips();
        return updateTooltipClickHandler();
      }
    }, [
      destroyTooltip,
      disabled,
      handleTooltipClick,
      tooltipPortal,
      updateElements,
      updateStickyTooltips,
      updateTooltip,
      updateTooltipClickHandler,
    ]);

    useLayoutEffect(() => {
      return () => {
        destroyTooltip();
      };
    }, [destroyTooltip]);

    useImperativeHandle(
      ref,
      () => ({
        show: () => instance.current?.show(),
        hide: () => instance.current?.hide(),
        setProps: (props) => instance.current?.setProps(props),
      }),
      [instance]
    );

    const content = renderContent ? (
      renderContent(children)
    ) : (
      <StyledTooltipView arrow={arrow} light={light} className={className}>
        {children}
      </StyledTooltipView>
    );

    if (!parentElement) {
      return <StyledTooltipRoot ref={rootRef} className="tooltip-anchor" />;
    }

    return (
      <StyledTooltipRoot ref={rootRef} id={tooltipAnchorId}>
        {ReactDom.createPortal(content, tooltipPortal)}
      </StyledTooltipRoot>
    );
  }
);

Tooltip.displayName = 'Tooltip';
