import React from 'react';

import { Children } from '@helpers/Children';

import { StyledOptionText } from './styles';

export const OptionText: React.FC = ({ children }) => {
  const title = Children.hasOnlyText(children) ? String(children) : undefined;

  return (
    <StyledOptionText typography={'SmallParagraph'} weight={'600'} title={title}>
      {children}
    </StyledOptionText>
  );
};
