import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';

export const StyledInspectionCard = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.paperRadius02}px;
    border: ${theme.primitives.colors.grey05} 1px solid;
    color: ${theme.primitives.colors.grey08};
  `
);

export const StyledInspectionCardItem = styled(Box)(
  ({ theme }) => css`
    &:not(:last-child) {
      border-bottom: ${theme.primitives.colors.grey05} 1px solid;
    }
  `
);

export const StyledInspectionHiveLink = styled(Button).attrs({
  tertiary: true,
})(
  ({}) => css`
    display: inline;
    padding: 0;
    font-weight: 400;
  `
);

export const StyledInspectionHiveHighlighted = styled.span(
  ({ theme }) => css`
    color: ${theme.primitives.colors.orange02};
    font-weight: 600;
  `
);
