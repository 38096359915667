import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDynamicTableSelection } from '@components/common/DynamicTable/hooks';
import { DynamicTableRow } from '@components/common/DynamicTable/types';
import { Checkbox } from '@components/form/inputs/CheckBox';

export const SelectCheckbox: React.VFC<DynamicTableRow> = (gridParamData) => {
  const { selectRow, deselectRow, selectedRowIds, hasSelectedAllOnCurrentPage } = useDynamicTableSelection();

  const shouldBeChecked = useMemo(() => {
    if (hasSelectedAllOnCurrentPage || selectedRowIds.includes(gridParamData.meta.id)) return true;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedAllOnCurrentPage, gridParamData.meta.id, selectedRowIds]);

  const [isChecked, setIsChecked] = useState(shouldBeChecked);

  useEffect(() => {
    setIsChecked(shouldBeChecked);
  }, [shouldBeChecked]);

  const handleOnChange = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      if (checked) selectRow(gridParamData);
      else deselectRow(gridParamData);
    },
    [deselectRow, gridParamData, selectRow]
  );

  if (!gridParamData.meta.id) {
    return null;
  }

  return (
    <Checkbox
      style={{ display: 'flex' }}
      value={isChecked}
      name={`select-${gridParamData.meta.id}`}
      onChange={handleOnChange}
    />
  );
};
