import React, { useCallback } from 'react';

import { YardDot } from '@components/common/Icon/dynamic/YardDot';
import { Marker } from '@components/common/Map';
import { useMap } from '@components/common/Map/hooks';
import { MarkerDescriptor } from '@components/common/Map/types';
import { useColor } from '@style/theme/hooks';

export function YardDotRenderer(props: { color?: string } & MarkerDescriptor<BeeYardOnMapInfo>) {
  const { data: yard, color: propColor, ...extraProps } = props;
  const [lng, lat] = yard.yardCenter.coordinates;
  const color = useColor(propColor);
  const map = useMap();

  const onYardDotClicked = useCallback(() => {
    const center = { lat, lng };

    // Makes the zoom increment bigger when zoomed out,
    // and smaller when zoomed in.
    const zoomIncrement = Math.max(5 / Math.pow(map.state.zoom, 0.18), 1);
    const zoom = map.state.zoom + zoomIncrement;

    map.setState((curr) => ({ ...curr, zoom, center }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <Marker
      {...extraProps}
      position={{ lat, lng }}
      onClick={onYardDotClicked}
      icon={YardDot.getImageURI({ color, size: 18 })}
    />
  );
}
