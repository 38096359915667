import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment/moment';

import { Box } from '@components/common/Box';
import { NavRouterLink } from '@components/common/CTA';
import { HealthScore } from '@components/common/HealthScore/HealthScore';
import { HealthScoreTooltip } from '@components/common/HealthScore/HealthScoreTooltip';
import { hiveStatusIcon } from '@components/common/Icon/presets/HiveStatus';
import { Mating } from '@components/common/Icon/presets/Mating';
import { Yard } from '@components/common/Icon/presets/Yard';
import { PracticeIcon } from '@components/common/PracticeIcon';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import {
  StyledSummaryRow,
  StyledSummaryRowText,
  StyledTooltipPractice,
} from '@components/hive/HiveModalTabsSummary/styles';
import { YardName } from '@components/yard/YardName';
import APP from '@config/constants';
import { getDate } from '@helpers/deprecated/time';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { useHiveDetailsData } from '@redux/HiveDetails/hooks';
import { usePractices } from '@redux/Practices/hooks';

export const HiveModalTabSummary: React.VFC = () => {
  const t = useTranslation();
  const { detail } = useHiveDetailsData();

  const { getAction, getActionName } = usePractices();

  const status = getAction(detail?.latestStatusAction ?? null)?.flag as HiveStatus;
  const statusDate = detail?.latestStatusDatetime;

  const grading = getActionName(detail?.latestGradingAction ?? null);
  const gradingDate = detail?.latestGradingDatetime;

  const healthScore = { healthScore: detail?.healthScore ?? null, usedNbHives: 1, totalNbHives: 1 };

  const feeding = getActionName(detail?.latestFeedingAction ?? null);
  const feedingDate = detail?.latestFeedingDatetime;

  const treatment = getActionName(detail?.latestTreatmentAction ?? null);
  const treatmentDate = detail?.latestTreatmentDatetime;

  const renderPracticeTooltip = useCallback((options: { name: string; date: string; icon: any }) => {
    const formattedDate = moment(options.date).format('MMM DD, YYYY [at] HH:mm');
    return (
      <Tooltip placement={'top-end'}>
        <StyledTooltipPractice marginBottom_050 paddingHorizontal_050 paddingVertical_025 gap_050 alignItems={'center'}>
          {options.icon}
          <Text typography={'SmallParagraph'}>{options.name}</Text>
        </StyledTooltipPractice>
        <Text typography={'CaptionSmall'}>{formattedDate}</Text>
      </Tooltip>
    );
  }, []);

  const renderSummaryItem = useCallback(
    (options: { icon: any; title: string; value: any; tooltip?: any; titleTooltip?: any; hyperlink?: string }) => {
      return (
        <StyledSummaryRow gap_050 alignItems={'center'}>
          {options.icon}
          <Box fit>
            <Text typography={'SmallParagraph'} weight={'600'} dashed={!!options.titleTooltip}>
              {options.title}
              {options.titleTooltip}
            </Text>
          </Box>

          <StyledSummaryRowText typography={'SmallParagraph'} dashed={!!options.tooltip}>
            {options.hyperlink ? (
              <NavRouterLink tertiary to={options.hyperlink} target={'_blank'}>
                {options.value}
              </NavRouterLink>
            ) : (
              <>
                {options.value}
                {options.tooltip}
              </>
            )}
          </StyledSummaryRowText>
        </StyledSummaryRow>
      );
    },
    []
  );

  if (!detail) {
    return null;
  }

  const HiveStatusIcon = hiveStatusIcon[status as HiveStatus];

  return (
    <Box fit column stretch padding_150 style={{ overflow: 'hidden' }}>
      {status
        ? renderSummaryItem({
            icon: <HiveStatusIcon />,
            title: `${t(status)} ${t('since')}`,
            value: getDate(statusDate),
          })
        : null}
      {status
        ? renderSummaryItem({
            icon: <Yard />,
            title: capitalize(t('yard')),
            value: <YardName name={detail.yardName ?? '-'} contractName={detail.yardContractName} suppressStyling />,
            hyperlink: detail.yardId
              ? URLUtil.buildPagePath(APP.routes.whiteboardYardsDetail, { pathParams: { uid: detail.yardId } })
              : undefined,
          })
        : null}
      {grading && gradingDate
        ? renderSummaryItem({
            icon: <PracticeIcon usePresetHiveGrading />,
            title: t('hive_grading'),
            value: grading,
            tooltip: renderPracticeTooltip({
              name: grading,
              date: gradingDate,
              icon: <PracticeIcon usePresetHiveGrading />,
            }),
          })
        : null}
      {renderSummaryItem({
        icon: <Mating />,
        title: t('health_score'),
        titleTooltip: (
          <Tooltip>
            <Box stretch column padding_025>
              <Text
                typography={'CaptionSmall'}
                dangerouslySetInnerHTML={{
                  __html: t('health_score_hive_modal_header_tooltip'),
                }}
              />
            </Box>
          </Tooltip>
        ),
        value: (
          <HealthScore data={healthScore} naTextProps={{ dashed: true }}>
            {!healthScore.healthScore && <HealthScoreTooltip data={healthScore} />}
          </HealthScore>
        ),
      })}
      {feeding && feedingDate
        ? renderSummaryItem({
            icon: <PracticeIcon usePresetFeeding />,
            title: t('last_feed'),
            value: feeding,
            tooltip: renderPracticeTooltip({
              name: feeding,
              date: feedingDate,
              icon: <PracticeIcon usePresetFeeding />,
            }),
          })
        : null}
      {treatment && treatmentDate
        ? renderSummaryItem({
            icon: <PracticeIcon usePresetFeeding />,
            title: t('last_treatment'),
            value: treatment,
            tooltip: renderPracticeTooltip({
              name: treatment,
              date: treatmentDate,
              icon: <PracticeIcon usePresetTreatment />,
            }),
          })
        : null}
    </Box>
  );
};
