import React from 'react';

import { Box } from '@components/common/Box';
import { useDynamicTableEmptyState } from '@components/common/DynamicTable/hooks';
import { PageContent } from '@components/common/PageContent';
import AssignYardsGroupsModal from '@components/deprecated/DynamicModals/AssignYardsGroupsModal';
import DeleteYardModal from '@components/deprecated/DynamicModals/DeleteYardModal';
import { BulkClearOutYardsModal } from '@components/yard/BulkClearOutYardsModal';
import { BulkDeleteYardsModal } from '@components/yard/BulkDeleteYardsModal';
import { YardsListSaveViewButton } from '@components/yard/YardsList/YardsListSaveViewButton';

import { BulkAssignContractModal } from '../BulkAssignContractModal';
import MetricCarrousel from '../MetricCarrousel';
import { YardsListBulkActionsNav } from '../YardsListBulkActionsNav';
import { YardsListColumnsModalTrigger } from '../YardsListColumnsModal/YardsListColumnsModalTrigger';

import { YardsListSelectionProvider } from './context/YardsListSelectionProvider';
import { useYardsListDataProvider } from './hooks';
import { YardsList } from './YardsList';

export const YardsListPage: React.VFC = () => {
  const { isFetchingFirstBatch } = useYardsListDataProvider();
  const { isListEmpty } = useDynamicTableEmptyState();

  return (
    <>
      <PageContent paddingTop_100>
        <Box>
          <MetricCarrousel />
        </Box>
        <Box marginTop_050 marginBottom_100 gap_100>
          <Box fit />
          <YardsListSaveViewButton disabled={isFetchingFirstBatch} />
          {!isListEmpty && <YardsListColumnsModalTrigger disabled={isFetchingFirstBatch} />}
        </Box>

        <YardsListSelectionProvider>
          <YardsList />
          <YardsListBulkActionsNav />
          <BulkAssignContractModal />
          <BulkClearOutYardsModal />
          <BulkDeleteYardsModal />
          <AssignYardsGroupsModal />
        </YardsListSelectionProvider>
      </PageContent>

      <DeleteYardModal />
    </>
  );
};
