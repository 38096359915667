import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading } from '@components/common/Loading';
import APP from '@config/constants';
import { isEmptyArray } from '@helpers/deprecated/array';
import { URLUtil } from '@helpers/URL';
import { withGetScreen } from '@HOC/withGetScreen';
// nectar:
import {
  handleSortGroups,
  makeFetchGroupsDetailedListRequestThunk,
  makeNavBarTitleChangeAction,
  makeOpenGroupModalAction,
} from '@redux/deprecated/actions';
import {
  makeClearAppliedFiltersAction,
  makeFetchFiltersAvailabilitiesThunk,
  makePatchAppliedFiltersAction,
} from '@redux/YardsFilters/actions';

import { GroupsView } from './GroupsView';

class Groups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      listRef: null,
    };

    this.handleSort = this.handleSort.bind(this);
    this.ref = createRef();
    this.hideMenu = this.hideMenu.bind(this);
    this.handleDisplayMenu = this.handleDisplayMenu.bind(this);
    this.handleAddGroup = this.handleAddGroup.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
    this.handleRemoveGroup = this.handleRemoveGroup.bind(this);
    this.handleGroupClick = this.handleGroupClick.bind(this);
  }

  componentDidMount() {
    const { dispatchNavBarTitleChange, t, fetchGroups } = this.props;

    fetchGroups();

    // immediately dispatch the nav bar change
    dispatchNavBarTitleChange({
      backBtn: false,
      label: t('groups'),
      yard: null,
      view: 'groups',
    });

    document.addEventListener('mousedown', this.hideMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.hideMenu);
  }

  handleSort(order_by, direction) {
    const { sortGroups, order_direction } = this.props;
    sortGroups(order_by, order_direction);
  }

  handleDisplayMenu(e, id, listRef) {
    e.stopPropagation();

    const { display } = this.state;
    this.setState({
      display: display ? false : id,
      listRef,
    });
  }

  hideMenu(e) {
    const { listRef } = this.state;
    if ((this.ref?.current?.alt !== e.target.alt || !this.ref?.current) && !listRef?.contains(e.target))
      this.setState({ display: false });
  }

  handleAddGroup() {
    const { openGroupsModal } = this.props;
    openGroupsModal({ modalType: 'add-group' });
  }

  handleEditGroup(group) {
    const { openGroupsModal } = this.props;
    openGroupsModal({ modalType: 'update-group', group });
    this.setState({ display: false });
  }

  handleRemoveGroup(group) {
    const { openGroupsModal } = this.props;
    openGroupsModal({ modalType: 'remove-group', group });
    this.setState({ display: false });
  }

  handleGroupClick(id) {
    const { history, dispatchSelectFiltersAction } = this.props;

    history.push(URLUtil.buildPagePath(APP.routes.whiteboard));
    dispatchSelectFiltersAction({ group: [String(id)] });
  }

  render() {
    const { t, isMobile, isTablet, order_by, order_direction, groups, user } = this.props;

    const { display } = this.state;

    const list = groups && !isEmptyArray(groups) && order_direction === 'DESC' ? [...groups].reverse() : groups;

    const sort = {
      order_by,
      order_direction,
    };

    if (!groups) return <Loading />;

    return (
      <GroupsView
        t={t}
        setRef={this.ref}
        sort={sort}
        isMobile={isMobile()}
        isTablet={isTablet()}
        groups={list}
        user={user}
        display={display}
        handleSort={this.handleSort}
        handleDisplayMenu={this.handleDisplayMenu}
        handleEditGroup={this.handleEditGroup}
        handleRemoveGroup={this.handleRemoveGroup}
        handleGroupClick={this.handleGroupClick}
        handleAddGroup={this.handleAddGroup}
      />
    );
  }
}

/**
 *
 * @param dispatch
 * @returns {{sortGroups: handleSortGroups}}
 * @returns {{fetchGroups: makeFetchGroupsRequestThunk}}
 * @returns {{dispatchNavBarTitleChange: makeNavBarTitleChangeAction}}
 * @returns {{openGroupsModal: makeOpenGroupModalAction}}
 */

const mapDispatchToProps = (dispatch) => ({
  sortGroups: (order_by, order_direction) => {
    dispatch(handleSortGroups(order_by, order_direction));
  },
  fetchGroups: () => {
    dispatch(makeFetchGroupsDetailedListRequestThunk());
  },
  dispatchNavBarTitleChange: (content) => {
    dispatch(makeNavBarTitleChangeAction(content));
  },
  openGroupsModal: (content) => {
    dispatch(makeOpenGroupModalAction(content));
  },
  dispatchSelectFiltersAction: async (filters) => {
    await dispatch(makeFetchFiltersAvailabilitiesThunk());
    dispatch(makeClearAppliedFiltersAction());
    dispatch(makePatchAppliedFiltersAction(filters));
  },
});

const mapStateToProps = (state) => ({
  user: state.accountReducer.user,
  groups: state.groupsReducer.sortedGroups,
  order_by: state.groupsReducer.order_by,
  order_direction: state.groupsReducer.order_direction,
});

Groups.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
  isTablet: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dispatchNavBarTitleChange: PropTypes.func.isRequired,
  openGroupsModal: PropTypes.func.isRequired,
  order_direction: PropTypes.string.isRequired,
  order_by: PropTypes.string.isRequired,
  sortGroups: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  user: PropTypes.shape({ season_start: PropTypes.string }).isRequired,
  groups: PropTypes.array,
  dispatchSelectFiltersAction: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(Groups))));
