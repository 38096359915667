import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

import { updateSpanishTranslationsRequestSuccessAction } from './index';
import { makeCloseBeekPracticesModalAction } from './modalActionCreator';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeActionRequestStartAction = () => ({
  type: types.ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeFetchActionRequestSuccessAction = (actionList) => ({
  type: types.FETCH_ACTION_SUCCESS,
  actionList,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeAddActionRequestSuccessAction = (action) => ({
  type: types.ADD_ACTION_SUCCESS,
  action,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeUpdateActionRequestSuccessAction = (action) => ({
  type: types.UPDATE_ACTION_SUCCESS,
  action,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeActionRequestFailureAction = () => ({
  type: types.ACTION_FAILURE,
});

export const makeAddActionRequestThunk = (bodyObj) => {
  return (dispatch) => {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    return Api.post(ENDPOINTS.action, bodyObj)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction({ messageTranslation: 'snack_default_msg', type: 'error' }));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeAddActionRequestSuccessAction(jsonObj));
          dispatch(updateSpanishTranslationsRequestSuccessAction(jsonObj));
          dispatch(makeShowSnackbarAction('action_added', { icon: true }));
        }
      });
  };
};

export const makeUpdateActionRequestThunk = (id, detail) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    return Api.patch(URLUtil.buildURL(ENDPOINTS.actionDetail, { id }), detail)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction({ messageTranslation: 'snack_default_msg', type: 'error' }));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeCloseBeekPracticesModalAction());
          dispatch(makeUpdateActionRequestSuccessAction(jsonObj));
          dispatch(updateSpanishTranslationsRequestSuccessAction(jsonObj));
          dispatch(makeShowSnackbarAction('action_updated', { icon: true }));
        }
      });
  };
};

export const makeEnableDisableActionRequestThunk = (id, bodyObj) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeActionRequestStartAction());

    return Api.patch(URLUtil.buildURL(ENDPOINTS.actionDetail, { id }), bodyObj)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction({ messageTranslation: 'snack_default_msg', type: 'error' }));
          return;
        } else if ('errors' in jsonObj) {
          dispatch(makeActionRequestFailureAction());
          dispatch(makeShowSnackbarAction(jsonObj.errors));
          return;
        } else {
          dispatch(makeCloseBeekPracticesModalAction());
          dispatch(makeUpdateActionRequestSuccessAction(jsonObj));
          const { is_active } = bodyObj;
          if (is_active) {
            dispatch(makeShowSnackbarAction('action_activated', { icon: true }));
          } else dispatch(makeShowSnackbarAction('action_deactivated', { icon: true }));
        }
      });
  };
};
