import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledPagesContainer = styled(Box)(
  () => css`
    position: relative;
    overflow: clip;
  `
);

export const StyledPagesItem = styled(Box)<{ $index: number; $currentStep: number }>(
  ({ theme, $index, $currentStep }) => css`
    position: absolute;
    inset: 0;
    opacity: 1;
    transform: translateX(${$index - $currentStep}00%);
    transition: ${theme.animations.transitionLong('transform', 'opacity', 'visibility')};

    ${$currentStep !== $index &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

    & > * {
      flex: 1;
    }
  `
);
