import React, { useMemo, useState } from 'react';

import { PageHeaderContextValue } from '@components/common/PageHeader/types';

export const PageHeaderContext = React.createContext<PageHeaderContextValue | null>(null);

export const PageHeaderProvider: React.FC = ({ children }) => {
  const [isHeaderVisible, setHeaderVisible] = useState(true);

  const context: PageHeaderContextValue = useMemo(
    () => ({
      isHeaderVisible,
      setHeaderVisible,
    }),
    [isHeaderVisible]
  );

  return <PageHeaderContext.Provider value={context}>{children}</PageHeaderContext.Provider>;
};
