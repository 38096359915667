import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

import { HeaderTitle } from '@components/common/DynamicTable/types';
import { useTranslation } from '@hooks/useTranslation';

export function useContractColumnNameGetter() {
  const t = useTranslation();

  return useCallback(
    (columnDef: ColDef, defaultDisplayName: string): HeaderTitle => {
      switch (columnDef.field) {
        case 'name':
          return t('pollination_contract_name');
        case 'grower':
          return t('grower');
        case 'contact':
          return t('contact');
        case 'hivesRequired':
          return {
            title: t('pollination_hives_required_header'),
            tooltip: t('pollination_hives_required_header_hint')
          };
        case 'hivesOnMap':
          return {
            title: t('pollination_hives_on_map_header'),
            tooltip: t('pollination_hives_on_map_header_hint')
          };
        case 'hivesOnSite':
          return {
            title: t('pollination_hives_on_site_header'),
            tooltip: t('pollination_hives_on_site_header_hint')
          };
        case 'drops':
          return t('pollination_drops_number');
        case 'blocks':
          return t('pollination_blocks_number');
        case 'cropTypes':
          return t('crop_type');
        case 'createdOn':
          return t('created_on');
        case 'archivedAt':
          return t('archived_since');
        case 'beesIn':
          return t('pollination_bees_in');
        case 'beesOut':
          return t('pollination_bees_out');
        case 'price':
          return t('price');
        default:
          return defaultDisplayName;
      }
    },
    [t]
  );
}
