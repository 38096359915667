import { PracticesConfig } from '@config/Practices';

export function useActionConfig(action: BeeAction | null) {
  return action && action.flag in PracticesConfig.ACTION_DEFINITIONS
    ? PracticesConfig.ACTION_DEFINITIONS[action.flag]
    : {};
}

export function usePracticeConfig(practice: BeePracticeDefinition | null) {
  return practice && practice.flag in PracticesConfig.PRACTICE_DEFINITIONS
    ? PracticesConfig.PRACTICE_DEFINITIONS[practice.flag]
    : {};
}
