import { Reducer } from 'redux';

export type BulkDeleteYardsReducer = Reducer<BulkDeleteYardsState, BulkDeleteYardsAction>;

export type BulkDeleteYardsAction =
  | { type: BulkDeleteYardsActionType.BULK_DELETE_YARDS_OPEN_MODAL }
  | { type: BulkDeleteYardsActionType.BULK_DELETE_YARDS_CLOSE_MODAL };

export interface BulkDeleteYardsState {
  isModalOpen: boolean;
}

export enum BulkDeleteYardsActionType {
  BULK_DELETE_YARDS_OPEN_MODAL = 'BULK_DELETE_YARDS_OPEN_MODAL',
  BULK_DELETE_YARDS_CLOSE_MODAL = 'BULK_DELETE_YARDS_CLOSE_MODAL',
}
