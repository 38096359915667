import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as TrendArrow } from '@assets/arrow.svg';
import { ReactComponent as QuestionMark } from '@assets/question-mark.svg';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { useCheckTrendDirectionGetter } from '@config/GlobalMetricsConfig/hooks';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { MetricValue } from '@redux/Yards/types';

import { LoadingMetric } from './LoadingMetric';
import {
  BottomContainer,
  BulletPoint,
  BulletPointContainer,
  BulletText,
  BulletValue,
  CategoriesContainer,
  CenterContainer,
  MetricCardContainer,
  MetricName,
  NAText,
  PreviousMetricValue,
  PreviousValueDelta,
  QuestionMarkContainer,
  RatioValue,
  TopContainer,
  TrendContainer,
  TrendValue,
} from './style';
import { CardType, MetricMetadataProps } from './type';

const MetricCard: React.FC<MetricMetadataProps> = ({
  cardType,
  metricName,
  metricValue,
  previousValueDelta,
  positiveDirection,
  sampleSize,
  isFetching,
  onClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  const checkTrendDirection = useCheckTrendDirectionGetter();

  const toPercentage = useCallback((value: number, total: number, decimalPlaces = 1) => {
    if (total !== 0) {
      return ((value / total) * 100).toFixed(decimalPlaces);
    }
    return null;
  }, []);

  const [trendMeta, setTrendMeta] = useState(() => checkTrendDirection(metricValue, positiveDirection));

  const trendValue = useMemo(() => {
    if (metricValue.length > 0 && metricValue[0].previousValue && metricValue[0].value) {
      return Math.round(Math.abs(metricValue[0].value - metricValue[0].previousValue) * 100) / 100;
    }
    return 0;
  }, [metricValue]);

  useEffect(() => {
    setTrendMeta(checkTrendDirection(metricValue, positiveDirection));
  }, [checkTrendDirection, metricValue, positiveDirection]);

  const topCard = (
    <TopContainer>
      <MetricName>
        {' '}
        <Text typography={'Heading3'} weight={'600'} color={'grey08'}>
          {t(metricName, { year: currentYear })}
        </Text>{' '}
      </MetricName>
      <QuestionMarkContainer id={`metric-description-${metricName}`}>
        <QuestionMark style={{ fill: theme.primitives.colors.grey05 }} />
      </QuestionMarkContainer>
      <Tooltip target={`metric-description-${metricName}`}>
        <Text typography={'CaptionSmall'} color={'white'}>
          {t(metricName + '_description', { sample: sampleSize })}
        </Text>
      </Tooltip>
    </TopContainer>
  );

  if (isFetching) {
    return (
      <MetricCardContainer disabled>
        {topCard}
        <LoadingMetric visible={isFetching} />
      </MetricCardContainer>
    );
  }
  if (cardType == CardType.GLOBAL || cardType == CardType.GLOBAL_WITH_RATIO) {
    return (
      <MetricCardContainer onClick={onClick}>
        {topCard}
        <CenterContainer>
          <span id={`metric-value-${metricName}`}>
            {' '}
            {!metricValue[0].value && <NAText>{t('not_available')}</NAText>}
            {metricValue[0].value > 0 && (
              <Text typography={'Heading2'} weight={'600'} color={'grey08'}>
                {metricValue[0].value}{' '}
              </Text>
            )}
          </span>
          {!metricValue[0].value && (
            <Tooltip target={`metric-value-${metricName}`}>
              <Text typography={'CaptionSmall'} color={'white'}>
                {t('not_available_description')}
              </Text>
            </Tooltip>
          )}
          <TrendContainer>
            {cardType == CardType.GLOBAL_WITH_RATIO && (
              <RatioValue>
                <Text typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
                  {sampleSize ? `(${toPercentage(metricValue[0].value, sampleSize)}%) ` : ''}
                </Text>
              </RatioValue>
            )}
            <TrendValue color={trendMeta.color}>
              {' '}
              <Text typography={'CaptionSmall'} weight={'700'}>
                {trendValue ? trendValue : null}
              </Text>{' '}
            </TrendValue>
            <TrendArrow style={{ fill: trendMeta.color, transform: trendMeta.direction }} />
          </TrendContainer>
        </CenterContainer>
        <BottomContainer>
          {metricValue[0].previousValue !== undefined && (
            <>
              <PreviousMetricValue>
                {' '}
                <Text typography={'CaptionSmall'} weight={'400'} color={'grey06'}>
                  {metricValue[0].previousValue ? metricValue[0].previousValue : 'NA'}
                </Text>{' '}
              </PreviousMetricValue>
              <PreviousValueDelta>
                {' '}
                <Text typography={'CaptionSmall'} weight={'400'} color={'grey06'}>
                  {t(previousValueDelta)}
                </Text>
              </PreviousValueDelta>
            </>
          )}
        </BottomContainer>
      </MetricCardContainer>
    );
  }
  if (cardType == CardType.CATEGORY) {
    const total = metricValue.reduce((a, v) => a + v.value, 0);
    return (
      <MetricCardContainer onClick={onClick}>
        {topCard}
        <CenterContainer>
          <CategoriesContainer>
            {metricValue.map((category: MetricValue, idx: number) => {
              return (
                <BulletPointContainer key={idx + 100}>
                  <BulletPoint color={category?.color ? theme.colors[category.color] : 'inherit'} />

                  <BulletText>
                    <Text typography={'CaptionSmall'} weight={'400'} color={'grey08'}>
                      {t(category.name)}
                    </Text>
                  </BulletText>
                  <BulletValue>
                    <Text typography={'CaptionSmall'} weight={'600'} color={'grey08'}>
                      {total > 0 ? category.value : 'NA'}
                    </Text>

                    <Text typography={'CaptionSmall'} weight={'400'} color={'grey06'}>
                      {maybePluralize(category.value, 'hive', t, 's', false)}
                    </Text>
                    <Text typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
                      {total ? `(${toPercentage(category.value, total)}%) ` : ''}
                    </Text>
                  </BulletValue>
                </BulletPointContainer>
              );
            })}
          </CategoriesContainer>
        </CenterContainer>
      </MetricCardContainer>
    );
  } else {
    console.warn(`${cardType} is not a valid cardType value.`);
    return null;
  }
};

export default MetricCard;
