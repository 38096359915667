import { useContext } from 'react';

import { LayoutContext, LayoutContextValue } from './context';

export function useLayoutContext(): LayoutContextValue {
  const layoutContext = useContext(LayoutContext);

  if (!layoutContext) {
    throw new Error("Can't find a parent Layout context.");
  }

  return layoutContext;
}
