import React from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';

const StyledUL = styled.ul`
  list-style: inside;
`;

export const ReportsPage: React.FC = () => {
  const t = useTranslation();

  return (
    <Box block marginTop_150 paddingHorizontal_150>
      <Text
        typography={'SmallParagraph'}
        dangerouslySetInnerHTML={{ __html: t('reports_elap_description', { linkToElap: APP.externalLinks.ELAP }) }}
      />
      <br />
      <br />

      <Text typography={'SmallParagraph'}>{t('reports_elap_description_list_title')}</Text>
      <StyledUL>
        <li>
          <Text typography={'SmallParagraph'} htmlTag="span">
            {t('reports_elap_description_list_item_1')}
          </Text>
        </li>
        <li>
          <Text typography={'SmallParagraph'} htmlTag="span">
            {t('reports_elap_description_list_item_2')}
          </Text>
        </li>
        <li>
          <Text typography={'SmallParagraph'} htmlTag="span">
            {t('reports_elap_description_list_item_3')}
          </Text>
        </li>
      </StyledUL>
    </Box>
  );
};
