import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { YardVisitCard } from '@components/common/YardVisitCard';
import { Children } from '@helpers/Children';
import { useTranslation } from '@hooks/useTranslation';

const StyledTooltipScroll = styled(Box)(
  ({ theme }) => css`
    max-height: 480px;
    overflow: hidden auto;

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.surfaceSecondary};
    }
  `
);

export const LastVisitTooltipContent: React.FC<{ data: any }> = ({ data }) => {
  const t = useTranslation();

  const workerNames = Children.createWithSeparators(data?.workerNames ?? [], {
    lastSeparator: ` ${t('and')} `,
  }).join('');
  const occurredAt = data?.occurredAt ? moment(data.occurredAt).format('MMMM DD, YYYY [at] hh:mm') : '';

  return data ? (
    <Box column stretch padding_025>
      <Box paddingBottom_050 marginBottom_025>
        <Text
          typography={'CaptionSmall'}
          dangerouslySetInnerHTML={{ __html: t('yard_visit_by', { name: workerNames }) }}
        />
      </Box>
      <StyledTooltipScroll column stretch>
        <YardVisitCard preset={'tooltip'} {...data} />
      </StyledTooltipScroll>
      <Box paddingTop_050 marginTop_025>
        <Text typography={'CaptionSmall'}>{occurredAt}</Text>
      </Box>
    </Box>
  ) : null;
};
