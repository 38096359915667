import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import styled, { Color } from 'styled-components';

import { useTranslation } from '@hooks/useTranslation';

const StyledSpan = styled.span<{ color: Color }>(
  ({ theme, color }) => `
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      padding: ${theme.spacing._025} ${theme.spacing._050};
      background-color: ${theme.colors.getPrimitiveOrGlobalColor(color)};
      color: ${theme.primitives.colors.grey08};
      border-radius: ${theme.spacing._025};

    `
);
export const YardStatusChip: React.FC<{ status: BeeDropStatus | BeeYardStatus; deletedAt?: string }> = ({
  status,
  deletedAt,
}) => {
  const t = useTranslation();
  const statusLowerCased = status.toLowerCase();

  const color: Color = useMemo(() => {
    switch (statusLowerCased) {
      case 'active':
        return 'green01';
      case 'emptied':
      case 'unloaded':
        return 'yellow01';
      default:
        return 'grey05';
    }
  }, [statusLowerCased]);

  return (
    <StyledSpan color={color}>{deletedAt ? capitalize(t('deleted')) : capitalize(t(statusLowerCased))}</StyledSpan>
  );
};
