import React from 'react';
// vendor:
import { Col, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import alertIcon from '@assets/Small_Alert.svg';
// icons
import deleteIcon from '@assets/Small_Delete.svg';
import layersIcon from '@assets/Small_Layers.svg';
import plusIcon from '@assets/Small_Plus.svg';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Loading } from '@components/common/Loading';
import { AlertIcon, StyledErrorTitle } from '@components/deprecated/Elements/SummaryElements';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import theme from '@style/theme';
import { Paragraph } from '@style/typeface';

// nectar
import { ZoomControlsView } from './components/ZoomControlsView';

import './map-styles.css';

const Map = styled.div`
  height: ${(props) => window.innerHeight - (props.theme.layout.tabBarHeight + props.theme.layout.siteHeaderHeight)}px;
  z-index: ${(props) => props.theme.getZIndexOf('lowLevelIndex')};
`;

const StyledRow = styled(Row)`
  height: ${(props) => props.theme.tabBarHeight}px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  z-index: ${(props) => props.theme.getZIndexOf('highLevelIndex')};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: ${(props) => props.theme.getZIndexOf('highLevelIndex')};
`;

const SelectContainer = styled.span`
  width: 2rem;
  height: 2rem;
  display: inline-block;
  padding: 4px;
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  &:hover {
    background-color: ${(props) => !props.disabled && props.theme.defaultBeekPracticeColor};
  }
`;

const StyledImg = styled.img`
  cursor: pointer;
  filter: ${(props) => (props.disabled ? props.theme.inputColorFilter : props.theme.primaryColorFilter)};
  &:hover {
    opacity: ${(props) => props.theme.defaultHoverOpacity};
  }
`;

const ErrorContainer = styled.div`
  position: absolute;
  display: flex;
  inset: 56px 0 calc(100% - 96px) 0;
  text-align: left;
  align-items: center;
  padding: 0 1.5rem;
  background-color: #f0c8c4;
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
`;

const ViewErrorButton = styled.button`
  ${Paragraph};
  text-transform: uppercase;
  margin-left: auto;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1rem;
`;

const ImgContainer = styled.div`
  height: 100%;
  border-right: ${(props) => props.theme.tertiaryBorderStyle};
  display: flex;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.getZIndexOf('highLevelIndex')};
  background-color: ${({ theme }) => theme.primitives.colors.white};
  opacity: 0.7;
`;

export const BeetrackYardMgmtMapView = ({
  t,
  type,
  zoom,
  loading,
  error,
  hasSelectedAYard,
  clicked,
  handleAdd,
  handleCancel,
  handleDelete,
  handleLayerTypeButtonToggle,
  handleSubmit,
  panToProblematicArea,
  handleZoom,
  setMapRef,
}) => (
  <>
    <StyledRow style={{ position: 'relative', flexWrap: 'nowrap' }} nogutter>
      <Col xs={4} style={{ alignSelf: 'center' }}>
        {/* <Autocomplete placeholder={t('search_location')} /> */}
      </Col>

      <StyledCol xs={4} style={{ justifyContent: 'center' }}>
        <ImgContainer style={{ borderLeft: theme.tertiaryBorderStyle }}>
          <SelectContainer
            style={{
              backgroundColor: 'create_yard' === clicked && theme.defaultBeekPracticeColor,
              marginLeft: '1.5rem',
              marginRight: '12px',
            }}
          >
            <StyledImg
              id="custom-Btn"
              data-for="create_yard"
              data-tip
              src={plusIcon}
              alt="add yard"
              onClick={handleAdd}
            />
            <Tooltip id="create_yard" disable={false} place="bottom" tooltipText={t('tooltip_create_yard')} />
          </SelectContainer>

          <SelectContainer
            disabled={!hasSelectedAYard}
            style={{
              backgroundColor: 'delete_yard' === clicked && theme.defaultBeekPracticeColor,
              marginLeft: '12px',
              marginRight: '1.5rem',
            }}
          >
            <StyledImg
              style={{ cursor: !hasSelectedAYard ? 'default' : 'pointer' }}
              data-for="delete_yard"
              data-tip
              disabled={!hasSelectedAYard}
              src={deleteIcon}
              alt="delete yard"
              onClick={hasSelectedAYard ? handleDelete : () => {}}
            />
            <Tooltip id="delete_yard" disable={false} place="bottom" tooltipText={t('tooltip_delete_yard')} />
          </SelectContainer>
        </ImgContainer>

        <ImgContainer>
          <SelectContainer style={{ margin: '0 1.5rem' }}>
            <StyledImg
              data-for="layer_type"
              data-tip
              onClick={handleLayerTypeButtonToggle}
              src={layersIcon}
              alt="map layer toggle"
            />
            <Tooltip
              id="layer_type"
              disable={false}
              place="bottom"
              tooltipText={'terrain' === type ? t('satellite_view') : t('terrain_view')}
            />
          </SelectContainer>
        </ImgContainer>
      </StyledCol>

      <Box fit paddingHorizontal_100 alignItems={'center'} justifyContent={'flex-end'}>
        <Button tertiary withMarginRight type="button" onClick={handleCancel}>
          {t('cancel')}
        </Button>

        <Button primary type="button" onClick={handleSubmit} height="2rem" disabled={!!error}>
          {t('done')}
        </Button>
      </Box>

      {error && (
        <ErrorContainer>
          <AlertIcon src={alertIcon} alt="yard alert" />
          <StyledErrorTitle>{error}</StyledErrorTitle>
          <ViewErrorButton onClick={panToProblematicArea}>{t('view')}</ViewErrorButton>
        </ErrorContainer>
      )}
    </StyledRow>

    <Map ref={(node) => setMapRef(node)}>...</Map>

    <ZoomControlsView
      key={zoom}
      zoom={zoom}
      handleZoomIn={() => handleZoom(true)}
      handleZoomOut={() => handleZoom(false)}
    />

    {loading && (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    )}
  </>
);

BeetrackYardMgmtMapView.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  hasSelectedAYard: PropTypes.bool.isRequired,

  handleAdd: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleLayerTypeButtonToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  panToProblematicArea: PropTypes.func.isRequired,
  handleZoom: PropTypes.func.isRequired,
  setMapRef: PropTypes.func.isRequired,

  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  clicked: PropTypes.oneOf(['create_yard', 'delete_yards', null]),
};
