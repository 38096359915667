import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponse } from '@helpers/Api/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Globals } from '@helpers/Globals';
import { Thunk } from '@helpers/Thunk';
import { AuthAction, AuthActionType } from '@redux/Auth/types';
import { makeRootDisposeStateAction, makeRootDisposeStorageAction } from '@redux/Root/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

export const makeAuthFetchUserStart = (): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_USER_START,
});

export const makeAuthFetchUserFinish = (user: BeeUser | null): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_USER_FINISH,
  payload: { user },
});

export const makeAuthFetchUserThunk = Thunk.createTakeFirst(() => async (dispatch) => {
  dispatch(makeAuthFetchUserStart());

  const response = await Api.get(ENDPOINTS.currentUser);

  if (response.error) {
    dispatch(makeAuthFetchUserFinish(null));
    throw new Error(String(response.error));
  } else {
    const user = parseUserFromApi(await response.json());

    if (!hasSomeMemberships(user)) {
      dispatch(makeAuthFetchUserFinish(null));
      throw new Error(String(response.error));
    }

    maybeAutoSelectOperation(user);
    dispatch(makeAuthFetchUserFinish(user));
  }
});

export const makeAuthPatchUserThunk = Thunk.createTakeFirst((user: Partial<BeeUser>) => {
  return async (dispatch, getState) => {
    const currentUser = getState().authReducer.user;
    if (!currentUser) {
      return;
    }

    dispatch(makeAuthFetchUserStart());

    const response = await Api.patch(ENDPOINTS.currentUser, parseUserToApi(user));

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const user = parseUserFromApi(await response.json());
      dispatch(makeAuthFetchUserFinish({ ...currentUser, ...user }));
      dispatch(makeShowSnackbarAction('snack_account_changed_msg'));
    }
  };
});

export const makeAuthFetchSignInStart = (): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_SIGN_IN_START,
});

export const makeAuthFetchSignInFinish = (user: BeeUser | null): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_SIGN_IN_FINISH,
  payload: { user },
});

export const makeAuthSignInThunk = Thunk.createTakeFirst((credentials: { username: string; password: string }) => {
  return async (dispatch) => {
    dispatch(makeAuthFetchSignInStart());

    // Make sure operation id won't be part of the sign-in request.
    Globals.operationId = null;
    const response = await Api.post(ENDPOINTS.login, { user: credentials });

    injectSignInError(response);

    if (response.error) {
      dispatch(makeAuthFetchSignInFinish(null));
      return response.error;
    }

    const user = parseUserFromApi(await response.json());
    if (!hasSomeMemberships(user)) {
      dispatch(makeAuthFetchSignInFinish(null));
      return {
        detail: {
          error: ['login_no_operation_error'],
        },
      };
    }

    maybeAutoSelectOperation(user);
    dispatch(makeAuthFetchSignInFinish(user));
  };
});

export const makeAuthFetchSignOutStart = (): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_SIGN_OUT_START,
});

export const makeAuthFetchSignOutFinish = (): AuthAction => ({
  type: AuthActionType.AUTH_FETCH_SIGN_OUT_FINISH,
});

/**
 * Performs the sign-out routine without calling the API.
 * */
export const makeAuthShallowSignOutThunk = Thunk.createTakeFirst(() => {
  return async (dispatch) => {
    dispatch(makeAuthFetchSignOutStart());
    Globals.operationId = null;
    dispatch(makeRootDisposeStateAction());
    dispatch(makeRootDisposeStorageAction());
    dispatch(makeAuthFetchSignOutFinish());
  };
});

/**
 * Performs the sign-out routine calling the API to revoke the token.
 * */
export const makeAuthSignOutThunk = Thunk.createTakeFirst(() => {
  return async (dispatch) => {
    dispatch(makeAuthFetchSignOutStart());
    const response = await Api.post(ENDPOINTS.logout, {});
    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw new Error();
    } else {
      Globals.operationId = null;
      dispatch(makeRootDisposeStateAction());
      dispatch(makeRootDisposeStorageAction());
      dispatch(makeAuthFetchSignOutFinish());
    }
  };
});

function parseUserToApi(user: Partial<BeeUser>) {
  return CaseAdapter.objectToSnakeCase(user);
}

function parseUserFromApi(data: any): BeeUser {
  const user = injectUserInitials(CaseAdapter.objectToCamelCase<BeeUser>(data));
  if (user.memberships) {
    user.memberships = user.memberships.filter(({ isManager }) => isManager);
  }
  return user;
}

function injectUserInitials(user: BeeUser) {
  if (user.firstName || user.lastName) {
    user.fullName = [user.firstName, user.lastName].filter(Boolean).join(' ').trim().replace(/\s+/, ' ');
  } else {
    user.fullName = user.username;
  }
  return user;
}

function maybeAutoSelectOperation(user: BeeUser) {
  // If the currently selected operation is not available,
  // reset it.
  if (Globals.operationId && !user.memberships.some(({ operation }) => operation.id === Globals.operationId)) {
    Globals.operationId = null;
  }

  // If only one operation is available, retrieve it
  // immediately, so avoiding the select operation screen.
  if (user.memberships.length === 1 && !Globals.operationId) {
    Globals.operationId = user.memberships[0].operation.id;
  }
}

function hasSomeMemberships(user: BeeUser) {
  return user.memberships.length > 0;
}

function injectSignInError(response: ApiResponse) {
  if (!response.error) {
    return;
  }

  if (response.status === 403) {
    response.error = { ...response.error, detail: { error: ['login_no_operation_error'] } };
  }
  if (response.status === 401) {
    response.error = { ...response.error, detail: { error: ['snack_unable_login'] } };
  }
}
