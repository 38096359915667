import produce from 'immer';

import { ActivityFilters, TimelineActionType, TimelineReducer, TimelineState } from '@redux/Timeline/types';

const defaultState: TimelineState = {
  isFetchingActivitySet: false,
  activitySet: null,

  isFetchingDetailedActivitySets: false,
  detailedActivitySets: [],
};

export const timelineReducer: TimelineReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case TimelineActionType.TIMELINE_DISPOSE_ACTIVITY:
        state.activitySet = null;
        break;

      case TimelineActionType.TIMELINE_FETCH_ACTIVITY_START:
        state.isFetchingActivitySet = true;
        if (state.activitySet === null) {
          state.activitySet = {
            totalCount: 0,
            activities: [],
            frozenAt: action.payload.frozenAt,
          };
        }
        break;

      case TimelineActionType.TIMELINE_FETCH_ACTIVITY_FINISH:
        const { data } = action.payload;
        if (state.activitySet && data) {
          state.activitySet.totalCount = data.totalCount;
          state.activitySet.activities = [...state.activitySet.activities, ...data.activities];
        }
        state.isFetchingActivitySet = false;
        break;

      case TimelineActionType.TIMELINE_DISPOSE_DETAILED_ACTIVITY:
        const index = getDetailedActivitySetIndex(state, action.payload.filters);
        if (index !== -1) {
          state.detailedActivitySets.splice(index, 1);
        }
        break;

      case TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_START: {
        state.isFetchingDetailedActivitySets = true;
        const { filters } = action.payload;

        if (getDetailedActivitySetIndex(state, filters) === -1) {
          state.detailedActivitySets.push({
            totalCount: 0,
            activities: [],
            filters,
          });
        }
        break;
      }

      case TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH: {
        state.isFetchingDetailedActivitySets = false;
        const { filters, data } = action.payload;

        if (data && filters) {
          const index = getDetailedActivitySetIndex(state, filters);
          const existingData = state.detailedActivitySets[index];
          if (existingData) {
            existingData.totalCount = data.totalCount;
            existingData.activities.push(...data.activities);
          }
        }
      }
    }

    return state;
  });
};

export function getDetailedActivitySet(state: TimelineState, filters: ActivityFilters) {
  const index = getDetailedActivitySetIndex(state, filters);
  return state.detailedActivitySets[index] ?? null;
}

function getDetailedActivitySetIndex(state: TimelineState, filters: ActivityFilters): number {
  return state.detailedActivitySets.findIndex(
    ({ filters: { date, yardId, alertTypes, hiveId, lostHives, practiceCategoryId } }) => {
      return (
        date === filters.date &&
        yardId === filters.yardId &&
        hiveId === filters.hiveId &&
        lostHives === filters.lostHives &&
        practiceCategoryId === filters.practiceCategoryId &&
        [...alertTypes].sort().join('-') === [...filters.alertTypes].sort().join('-')
      );
    }
  );
}
