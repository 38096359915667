import React from 'react';
import { useSelector } from 'react-redux';

import { Line } from '@components/common/Line';
import { PageContent } from '@components/common/PageContent';
import {
  StyledCard,
  StyledDashboardContainer,
  StyledResponsiveRow,
} from '@components/yard/YardDetailsDashboard/styles';
import { YardDetailsDashboardAbout } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardAbout';
import { YardDetailsDashboardGroups } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardGroups';
import { YardDetailsDashboardLocation } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardLocation';
import { YardDetailsDashboardNotes } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardNotes';
import { YardDetailsDashboardSummary } from '@components/yard/YardDetailsDashboard/YardDetailsDashboardSummary';

export const YardDetailsDashboard: React.VFC = () => {
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  if (!yard) {
    return null;
  }

  return (
    <PageContent>
      <StyledDashboardContainer stretch gap_150>
        <StyledResponsiveRow fit gap_100>
          <StyledCard $isFlex column stretch>
            <YardDetailsDashboardAbout />
            <Line />
            <YardDetailsDashboardGroups />
            <Line />
            <YardDetailsDashboardNotes />
          </StyledCard>
          <StyledCard $isFlex column stretch>
            <YardDetailsDashboardLocation />
          </StyledCard>
        </StyledResponsiveRow>
        <StyledCard column stretch>
          <YardDetailsDashboardSummary />
        </StyledCard>
      </StyledDashboardContainer>
    </PageContent>
  );
};
