import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type YardCreateOrUpdateReducer = Reducer<YardCreateOrUpdateState, YardCreateOrUpdateAction>;

export type YardCreateOrUpdateAction =
  | {
      type:
        | YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_START
        | YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_START
        | YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_OPEN_MODAL
        | YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_CLOSE_MODAL
        | YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_DISPOSE;
    }
  | {
      type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_FINISH;
      payload: { error: ApiResponseError | null };
    }
  | {
      type: YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_FINISH;
      payload: { detail: BeeYardDetails | null };
    };

export interface YardCreateOrUpdateState {
  detail: BeeYardDetails | null;
  isFetchingDetails: boolean;
  isSavingDetails: boolean;
  isModalOpen: boolean;
  didLastSaveSucceed: boolean;
}

export enum YardCreateOrUpdateActionType {
  YARD_CREATE_OR_UPDATE_DISPOSE = 'YARD_CREATE_OR_UPDATE_DISPOSE',

  YARD_CREATE_OR_UPDATE_OPEN_MODAL = 'YARD_CREATE_OR_UPDATE_OPEN_MODAL',
  YARD_CREATE_OR_UPDATE_CLOSE_MODAL = 'YARD_CREATE_OR_UPDATE_CLOSE_MODAL',

  YARD_CREATE_OR_UPDATE_FETCH_DETAIL_START = 'YARD_CREATE_OR_UPDATE_FETCH_DETAIL_START',
  YARD_CREATE_OR_UPDATE_FETCH_DETAIL_FINISH = 'YARD_CREATE_OR_UPDATE_FETCH_DETAIL_FINISH',

  YARD_CREATE_OR_UPDATE_SAVE_DETAIL_START = 'YARD_CREATE_OR_UPDATE_SAVE_DETAIL_START',
  YARD_CREATE_OR_UPDATE_SAVE_DETAIL_FINISH = 'YARD_CREATE_OR_UPDATE_SAVE_DETAIL_FINISH',
}
