import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { TextProps } from '@components/common/Text/types';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { from } from '@style/mediaQueries';

import { BoxProps } from '../Box/types';
import { Gutter } from '../Gutter';
import { Text } from '../Text';

const StyledBoxSlim = styled(Box)<{ $maxWidth: number }>(
  ({ $maxWidth }) => css`
    ${from('tablet')} {
      max-width: min(max(${$maxWidth}px, 50%), 100%);
    }
  `
);

export const PageContent: React.FC<BoxProps<'div'>> = (props) => {
  return <Gutter {...props} />;
};

export const PageContentSlim: React.FC = ({ children }) => {
  const { isMobile } = useGetScreenWidth();
  return (
    <StyledBoxSlim column stretch padding_100={isMobile} padding_150={!isMobile} block $maxWidth={585}>
      {children}
    </StyledBoxSlim>
  );
};

export const PageContentHeader: React.FC = ({ children }) => {
  return (
    <Box block paddingBottom_150 marginBottom_050>
      {children}
    </Box>
  );
};

export const PageContentTitle: React.FC = ({ children }) => {
  return (
    <Text typography="Heading2" weight="600">
      {children}
    </Text>
  );
};
export const PageContentInfo: React.FC<Omit<TextProps, 'typography' | 'lineHeight'>> = ({ children, ...textProps }) => {
  return (
    <Box block marginTop_100>
      <Text typography="SmallParagraph" lineHeight="24px" {...textProps}>
        {children}
      </Text>
    </Box>
  );
};

export const PageContentInfoSlim: React.FC = ({ children }) => {
  return (
    <StyledBoxSlim block marginTop_100 $maxWidth={450}>
      <Text typography="SmallParagraph" lineHeight="24px">
        {children}
      </Text>
    </StyledBoxSlim>
  );
};
