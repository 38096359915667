import { useSelector } from 'react-redux';

import { useHiveModalInnerContext } from '@components/hive/HiveModal/hooks';
import { HiveDetailsState } from '@redux/HiveDetails/types';

export function useHiveDetailsData<HT extends number>(): HiveDetailsState['hives'][HT] {
  // TODO: Remove this hive modal dependency
  const { id } = useHiveModalInnerContext();
  const state = useSelector((state) => state.hiveDetailsReducer.hives[id] ?? null);
  return (
    state || {
      detail: null,
      isFetchingDetail: false,
      activitySet: null,
      isFetchingActivitySet: false,
    }
  );
}
