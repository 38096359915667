import React from 'react';

import { Box } from '@components/common/Box';
import { DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { HiveStatusChip } from '@components/common/HiveStatusChip';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { getDate } from '@helpers/deprecated/time';
import { useTranslation } from '@hooks/useTranslation';

import { StatusCell } from '../types';

export const Status: React.VFC<StatusCell> = ({ actionId, occurredAt, bornDate }) => {
  const t = useTranslation();

  return (
    <DefaultCellWrapper>
      <Box alignItems="center">
        {actionId && <HiveStatusChip statusActionId={actionId} />}
        {Boolean(occurredAt || bornDate) && (
          <Tooltip placement="right">
            <Text typography="CaptionSmall">{t('Since_date', { date: getDate(occurredAt ?? bornDate) })}</Text>
          </Tooltip>
        )}
      </Box>
    </DefaultCellWrapper>
  );
};
