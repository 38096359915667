import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Text } from '@components/common/Text';

export const StyledTimeline = styled(Box)<{ $suppressScrolling?: boolean }>(
  ({ theme, $suppressScrolling }) => css`
    display: block;
    width: 100%;
    height: 100%;
    flex: 1 1 0;

    ${!$suppressScrolling &&
    css`
      overflow: hidden auto;
    `}
  `
);

export const StyledTimelineInner = styled(Box)<{ $isVisible: boolean; $suppressDayMarks?: boolean }>(
  ({ theme, $isVisible, $suppressDayMarks }) => css`
    opacity: 0;
    ${$suppressDayMarks &&
    css`
      padding: ${theme.spacing._050} 0 ${theme.spacing._150} 0;
    `}

    ${$isVisible &&
    css`
      opacity: 1;
    `}
  `
);

export const StyledTimelineItemGroup = styled(Box)<{ $suppressDayMarks?: boolean }>(
  ({ theme, $suppressDayMarks }) => css`
    border-left: ${theme.primitives.colors.grey05} 1px solid;

    // No point on using the theme spacing,
    // since these values are too odd.
    ${$suppressDayMarks
      ? css`
          margin: 8px 0 24px 12px;
        `
      : css`
          margin: 32px 0 24px 12px;
        `}
  `
);

export const StyledTimelineItem = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding-left: ${theme.spacing._150};
    color: ${theme.primitives.colors.grey06};
  `
);

export const StyledTimelineItemIconWrapper = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: -12px;
    width: 24px;
    height: 24px;
    background-color: ${theme.primitives.colors.grey08};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  `
);

export const StyledTimelineFooter = styled(Box)(
  ({ theme }) => css`
    position: relative;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${theme.primitives.colors.grey05};
    }
  `
);

export const StyledTimelineFooterCard = styled(Box)(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.primitives.colors.white};
    border: ${theme.primitives.colors.grey05} 1px solid;
    border-radius: ${theme.shape.paperRadius01}px;
    padding: 0 ${theme.spacing._100};
    align-items: center;
    height: 32px;

    a {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      line-height: inherit;
    }
  `
);

export const StyledHeading = styled(Text)(
  ({ theme }) => css`
    overflow-wrap: anywhere; // Handle ridiculously large words.
    color: ${theme.primitives.colors.grey06};
    strong {
      color: ${theme.primitives.colors.grey08};
      font-weight: 600;
    }
  `
);

export const StyledUnderlinedButton = styled(Button).attrs({
  tertiary: true,
})(
  ({ theme }) => css`
    color: ${theme.primitives.colors.grey08};
    font-weight: 600;
    display: inline;
    padding: 0;
  `
);

export const StyledYardNameLink = styled(NavRouterLink).attrs({
  tertiary: true,
})(
  () => css`
    display: inline-block;
    padding: 0;
  `
);

export const StyledYardBoxInfo = styled(Text)(
  ({ theme }) => css`
    border-radius: ${theme.shape.paperRadius01}px;
    background-color: ${theme.primitives.colors.grey05};
    color: ${theme.primitives.colors.grey08};
    padding: 0 ${theme.spacing._025};
    font-weight: 600;
    cursor: default;
  `
);

export const StyledGoToTopArrowButton = styled(Button).attrs({
  primary: true,
  suppressPadding: true,
})<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    position: absolute;

    align-items: center;
    justify-content: center;

    bottom: ${theme.spacing._150};
    right: ${theme.spacing._150};
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: ${theme.shadows.boxShadow03};
    z-index: ${theme.getZIndexOf('highLevelIndex')};
    transform: scale(0);
    pointer-events: none;
    transition: ${theme.animations.transitionMedium('transform')};

    svg {
      transform: rotate(-90deg);
    }

    ${$isVisible &&
    css`
      transform: scale(1);
      pointer-events: auto;
    `};
  `
);

export const StyledAlertTextWrapper = styled.span`
  line-height: 0;
  & * {
    line-height: 0.875rem;
    border: none;
  }
`;

export const StyledAlertText = styled(Text)`
  b,
  strong {
    font-weight: 600;
  }
`;

export const StyledAlertLink = styled(NavRouterLink)`
  display: inline;
`;
