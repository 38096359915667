import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Marker } from '@components/common/Map';
import { Coordinate } from '@components/common/Map/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { AnyInstance, TransientDrop } from '@components/pollination/PolliMap/types';
import { YardMarkerPin } from '@components/yard/YardMarkerPin';

export interface PolliMapDropsProps {
  zIndex?: number;
  onDropClick?: (drop: AnyInstance<TransientDrop>) => void;
  onDropPositionChange?: (drop: AnyInstance<TransientDrop>, position: Coordinate) => void;
  onDropDraggingChange?: (drop: AnyInstance<TransientDrop>, dragging: boolean) => void;
}

export const PolliMapDrops: React.VFC<PolliMapDropsProps> = ({
  zIndex,
  onDropClick,
  onDropPositionChange,
  onDropDraggingChange,
}) => {
  const {
    isStatic,
    drops,
    selectedDrop,
    getKey,
    isManagingDrops,
    isManagingADrop,
    isManagingBlocks,
    isManagingABlock,
    isManagingPois,
    isManagingAPoi,
  } = usePolliMap();

  const dropsList = useMemo(() => {
    const visibleDropsDict = { ...drops.added, ...drops.edited } as Record<string, AnyInstance<TransientDrop>>;
    selectedDrop && (visibleDropsDict[getKey(selectedDrop)] = selectedDrop);

    const visibleDrops = Object.values(visibleDropsDict);
    return visibleDrops.sort((a, b) => b.position.lat - a.position.lat);
  }, [drops.added, drops.edited, getKey, selectedDrop]);

  // TODO: Review and simplify bellow boolean methods.

  const isDropSelected = useCallback(
    (drop: AnyInstance<TransientDrop>) => {
      return Boolean(selectedDrop && getKey(selectedDrop) === getKey(drop));
    },
    [getKey, selectedDrop]
  );

  const isDropDraggable = useCallback(
    (drop: AnyInstance<TransientDrop>) => {
      const { isActive } = drop;
      const isManagingAnyDrop = isManagingDrops || isManagingADrop;
      const thereIsNoOtherSelection =
        !isManagingADrop || Boolean(selectedDrop && getKey(selectedDrop) === getKey(drop));
      return !isActive && isManagingAnyDrop && thereIsNoOtherSelection;
    },
    [getKey, isManagingADrop, isManagingDrops, selectedDrop]
  );

  const isDropDisabled = useCallback(
    (drop: AnyInstance<TransientDrop>) => {
      const isManagingAnotherDrop = isManagingADrop && selectedDrop && getKey(selectedDrop) !== getKey(drop);
      return Boolean(isManagingPois || isManagingBlocks || isManagingAPoi || isManagingABlock || isManagingAnotherDrop);
    },
    [isManagingADrop, selectedDrop, getKey, isManagingPois, isManagingBlocks, isManagingAPoi, isManagingABlock]
  );

  return (
    <>
      {dropsList.map((drop) => (
        <Marker
          key={getKey(drop)}
          draggable={isDropDraggable(drop)}
          onClick={() => onDropClick && onDropClick(drop)}
          onPositionChange={(position) => onDropPositionChange && onDropPositionChange(drop, position)}
          onDraggingChange={(dragging) => onDropDraggingChange && onDropDraggingChange(drop, dragging)}
          position={drop.position}
          zIndex={zIndex}
        >
          <YardMarkerPin
            yard={drop}
            animateToAppear
            active={isDropSelected(drop)}
            disabled={isDropDisabled(drop)}
            disableTruncation={isStatic}
            clickable
          >
            <DropTooltip drop={drop} />
          </YardMarkerPin>
        </Marker>
      ))}
    </>
  );
};

const DropTooltip: React.VFC<{ drop: AnyInstance<TransientDrop> }> = ({ drop }) => {
  const { t } = useTranslation();
  const { isActive } = drop;
  const { selectedDrop, getKey } = usePolliMap();
  const isBeingManaged = selectedDrop && getKey(drop) === getKey(selectedDrop);

  if (isActive && isBeingManaged) {
    return (
      <Tooltip placement={'top'}>
        <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t('tooltip_cannot_drag_active_drop') }} />
      </Tooltip>
    );
  }

  return null;
};
