import produce from 'immer';

import {
  YardCreateOrUpdateActionType,
  YardCreateOrUpdateReducer,
  YardCreateOrUpdateState,
} from '@redux/YardCreateOrUpdate/types';

const defaultState: YardCreateOrUpdateState = {
  detail: null,
  isFetchingDetails: false,
  isSavingDetails: false,
  isModalOpen: false,
  didLastSaveSucceed: false,
};

export const yardCreateOrUpdateReducer: YardCreateOrUpdateReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_DISPOSE:
        state.detail = null;
        state.isFetchingDetails = false;
        state.isModalOpen = false;
        state.didLastSaveSucceed = false;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_OPEN_MODAL:
        state.isModalOpen = true;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_CLOSE_MODAL:
        state.isModalOpen = false;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_START:
        state.isFetchingDetails = true;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_FETCH_DETAIL_FINISH:
        state.detail = action.payload.detail;
        state.isFetchingDetails = false;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_START:
        state.isSavingDetails = true;
        break;
      case YardCreateOrUpdateActionType.YARD_CREATE_OR_UPDATE_SAVE_DETAIL_FINISH:
        state.isSavingDetails = false;
        state.didLastSaveSucceed = !action.payload.error;
        break;
    }

    return state;
  });
};
