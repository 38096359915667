import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import ChangePasswordModal from '@components/deprecated/DynamicModals/ChangePasswordModal';
import { Profile } from '@scenes/admin/Profile/Profile';

export const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const hasUser = useSelector((state) => !!state.authReducer.user);

  return (
    <Box column stretch flexGrow={1}>
      <PageHeader>
        <PageHeaderTitle>{t('profile')}</PageHeaderTitle>
      </PageHeader>
      {hasUser && <Profile />}
      <ChangePasswordModal />
      <Loading visible={!hasUser} />
    </Box>
  );
};
