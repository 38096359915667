/**
 * Helper to identify orphan hives and intersecting yards.
 * */

import { geom } from 'jsts';

const GEOMETRY_FACTORY = new geom.GeometryFactory();

export class GeometryAnalyzer {
  /**
   * Size used to create tiles on the map.
   * Only polygons within close tiles are compared.
   * Approximately 555 meters.
   * */
  TILE_SIZE = 0.005;

  /**
   * Polygons larger than this are considered as part
   * of all tiles by default, which enhances the performance
   * for bigger shapes.
   * */
  MAX_OPTIMIZED_POLYGON_SIZE = 0.05;

  /**
   * Debug helpers.
   * */
  debug = false;
  debugPolygons: Array<google.maps.Polygon> = [];

  /**
   * Used to classify points and polygons in tiles.
   * */
  polygonMatrix = new TileMatrix<Polygon>();
  pointMatrix = new TileMatrix<Point>();

  /**
   * Used to quickly identify in which tiles each
   * point/polygon is classified,
   */
  polygonTilesShortcut: Record<any, Array<Tile<Polygon>>> = {};
  pointTilesShortcut: Record<any, Array<Tile<Point>>> = {};

  /**
   * Simple list with all polygons considered too
   * large to be classified in tiles.
   *
   * If a polygon is too large, it'd generate a high
   * amount of tiles, making it quite slow. Having them
   * as a list will make it easy include them in any
   * intersection check.
   *
   * If an operation has many large yard boundaries,
   * it will remain quite slow.
   * */
  tooLargePolygons: Array<Polygon> = [];

  constructor(polygons?: Array<Polygon>, points?: Array<Point>, debug = false) {
    this.debug = debug;

    this.debugTime('constructor');
    polygons?.forEach((polygon) => this.updatePolygon(polygon));
    points?.forEach((point) => this.updatePoint(point));
    this.debugTimeEnd('constructor');
  }

  public getNextOrphanPoint(): Point | null {
    this.debugTime('getNextOrphanPoint');

    const pointIds = Object.keys(this.pointTilesShortcut);

    for (const pointId of pointIds) {
      const pointTile = this.pointTilesShortcut[pointId][0];
      const point = pointTile.get(pointId);

      let isOrphan = true;

      const tile = this.polygonMatrix.get(pointTile.i, pointTile.j);
      const nearbyPolygons = Object.values(tile.items);
      for (const polygon of [...this.tooLargePolygons, ...nearbyPolygons]) {
        if (polygon.contains(point)) {
          isOrphan = false;
          break;
        }
      }

      if (isOrphan) {
        this.debugTimeEnd('getNextOrphanPoint');
        return point;
      }
    }

    this.debugTimeEnd('getNextOrphanPoint');

    return null;
  }

  public getNextIntersectingPolygons(): [Polygon, Polygon] | null {
    this.debugTime('getNextIntersectingPolygons');

    const polygonIds = Object.keys(this.polygonTilesShortcut);

    for (const polygonId of polygonIds) {
      const polygonTiles = this.polygonTilesShortcut[polygonId];
      const polygon = polygonTiles[0].get(polygonId);

      for (const polygonTile of polygonTiles) {
        for (const anotherPolygon of Object.values(polygonTile.items)) {
          if (polygon.intersects(anotherPolygon)) {
            return [polygon, anotherPolygon];
          }
        }
      }
    }

    for (const largePolygon of this.tooLargePolygons) {
      const tiledPolygons = Object.keys(this.polygonTilesShortcut).map((key) => {
        const polygonTiles = this.polygonTilesShortcut[key];
        return polygonTiles[0].get(key);
      });
      for (const polygon of [...tiledPolygons, ...this.tooLargePolygons]) {
        if (polygon.id === largePolygon.id) {
          continue;
        }
        if (polygon.intersects(largePolygon)) {
          return [polygon, largePolygon];
        }
      }
    }

    this.debugTimeEnd('getNextIntersectingPolygons');

    return null;
  }

  public updatePolygon(polygon: Polygon): void {
    this.removePolygon(polygon);
    const isTooLarge = this.isPolygonTooLarge(polygon);

    if (isTooLarge) {
      this.tooLargePolygons.push(polygon);
    } else {
      const tiles = this.getTilesFromPolygon(polygon);
      const tilesShortcut = this.getTilesFromPolygonShortcut(polygon);
      tiles.forEach((tile) => {
        tile.add(polygon);
        tilesShortcut.push(tile);
      });
    }
  }

  public updatePoint(point: Point): void {
    this.removePoint(point);
    const tile = this.getTileFromPoint(point);
    const tilesShortcut = this.getTilesFromPointShortcut(point);
    tile.add(point);
    tilesShortcut.push(tile);
  }

  public removePolygon(polygon: Polygon): void {
    const tilesShortcut = this.getTilesFromPolygonShortcut(polygon);
    tilesShortcut.forEach((tile) => {
      tile.remove(polygon);
    });
    delete this.polygonTilesShortcut[polygon.id];
    this.tooLargePolygons = this.tooLargePolygons.filter((p) => String(p.id) !== String(polygon.id));
  }

  public removePoint(point: Point): void {
    const tilesShortcut = this.getTilesFromPointShortcut(point);
    tilesShortcut.forEach((tile) => {
      tile.remove(point);
    });
    delete this.pointTilesShortcut[point.id];
  }

  private getTilesFromPolygonShortcut(polygon: Polygon): Array<Tile<Polygon>> {
    if (!this.polygonTilesShortcut[polygon.id]) {
      this.polygonTilesShortcut[polygon.id] = [];
    }
    return this.polygonTilesShortcut[polygon.id];
  }

  private getTilesFromPointShortcut(point: Point): Array<Tile<Point>> {
    if (!this.pointTilesShortcut[point.id]) {
      this.pointTilesShortcut[point.id] = [];
    }
    return this.pointTilesShortcut[point.id];
  }

  private getTilesFromPolygon(polygon: Polygon): Array<Tile<Polygon>> {
    const bounds = polygon.getBounds();
    const boundsIJ = bounds.map((point) => this.getIJFromPoint(point));
    const topMostI = Math.max(...boundsIJ.map(({ i }) => i));
    const bottomMostI = Math.min(...boundsIJ.map(({ i }) => i));
    const rightMostJ = Math.max(...boundsIJ.map(({ j }) => j));
    const leftMostJ = Math.min(...boundsIJ.map(({ j }) => j));
    const tiles = [] as Array<Tile<Polygon>>;

    for (let i = bottomMostI; i <= topMostI; i++) {
      for (let j = leftMostJ; j <= rightMostJ; j++) {
        tiles.push(this.polygonMatrix.get(i, j));
      }
    }

    return tiles;
  }

  private isPolygonTooLarge(polygon: Polygon) {
    const bounds = polygon.getBounds();
    const boundsLargerSize = Math.max(
      Math.max(...bounds.map((c) => c.x)) - Math.min(...bounds.map((c) => c.x)),
      Math.max(...bounds.map((c) => c.y)) - Math.min(...bounds.map((c) => c.y))
    );
    return boundsLargerSize > this.MAX_OPTIMIZED_POLYGON_SIZE;
  }

  private getTileFromPoint(point: Point): Tile<Point> {
    const { i, j } = this.getIJFromPoint(point);
    return this.pointMatrix.get(i, j);
  }

  private getIJFromPoint(point: Point): { i: number; j: number } {
    return {
      i: Math.floor(point.y / this.TILE_SIZE),
      j: Math.floor(point.x / this.TILE_SIZE),
    };
  }

  private getCoordinatesFromIJ({ i, j }: { i: number; j: number }): { lat: number; lng: number } {
    return {
      lat: i * this.TILE_SIZE,
      lng: j * this.TILE_SIZE,
    };
  }

  private debugTime(tag: string): void {
    this.debug && console.time(`GeometryAnalyzer: ${tag}`);
  }

  private debugTimeEnd(tag: string): void {
    this.debug && console.timeEnd(`GeometryAnalyzer: ${tag}`);
  }

  public debugTiles(map: google.maps.Map): void {
    this.clearDebugTiles();

    for (const tiles of Object.values(this.polygonTilesShortcut)) {
      tiles.forEach(({ i, j }) => {
        const polygon = new google.maps.Polygon({
          map,
        });
        polygon.setPath([
          this.getCoordinatesFromIJ({ i, j }),
          this.getCoordinatesFromIJ({ i: i + 1, j }),
          this.getCoordinatesFromIJ({ i: i + 1, j: j + 1 }),
          this.getCoordinatesFromIJ({ i, j: j + 1 }),
        ]);
        this.debugPolygons.push(polygon);
      });
    }

    this.tooLargePolygons.forEach(({ path }) => {
      const polygon = new google.maps.Polygon({
        map,
        strokeColor: 'rgb(155, 0, 0)',
        strokeWeight: 2,
        fillColor: 'rgb(155, 0, 0)',
        fillOpacity: 0.4,
      });
      polygon.setPath(path.map(({ x, y }) => ({ lat: y, lng: x })));
      this.debugPolygons.push(polygon);
    });
  }

  private clearDebugTiles() {
    this.debugPolygons.forEach((polygon) => polygon.setMap(null));
    this.debugPolygons = [];
  }
}

export class TileMatrix<I extends Point | Polygon> {
  private tiles: Record<any, Tile<I>> = {};

  get(i: number, j: number): Tile<I> {
    const key = this.getKeyFromIJ(i, j);
    if (!this.tiles[key]) {
      this.tiles[key] = new Tile(i, j);
    }
    return this.tiles[key];
  }

  getAll() {
    return Object.values(this.tiles);
  }

  getKeyFromIJ(i: number, j: number): string {
    return `${i}:${j}`;
  }

  get length() {
    return Object.keys(this.tiles).length;
  }
}

export class Tile<I extends Point | Polygon> {
  items: Record<any, I> = {};

  constructor(public i: number, public j: number) {}

  get(id: any) {
    return this.items[id];
  }

  add(item: I) {
    this.items[item.id] = item;
  }

  remove(item: I) {
    delete this.items[item.id];
  }
}

export class Polygon<P = never> {
  private geometry: geom.Polygon;

  constructor(public id: any, public path: Array<Point>, public properties?: P) {
    if (this.path.length < 3) {
      throw new Error('Polygon path must have at least three points.');
    }

    this.ensureClosedPath();
    this.geometry = this.createGeometry();
  }

  private createGeometry() {
    const path = this.path.map(({ x, y }) => new geom.Coordinate(x, y));
    return GEOMETRY_FACTORY.createPolygon(path);
  }

  ensureClosedPath() {
    const equalsTolerance = 0.000001; // Approximately 10cm.
    if (!this.path[0].geometry.equalsExact(this.path[this.path.length - 1].geometry, equalsTolerance)) {
      this.path.push(this.path[0]);
    }
  }

  getBounds(): [Point, Point, Point, Point] {
    const leftMost = Math.min(...this.path.map(({ x }) => x));
    const rightMost = Math.max(...this.path.map(({ x }) => x));
    const bottomMost = Math.min(...this.path.map(({ y }) => y));
    const topMost = Math.max(...this.path.map(({ y }) => y));
    return [
      new Point(`${this.id}:lb`, leftMost, bottomMost),
      new Point(`${this.id}:lt`, leftMost, topMost),
      new Point(`${this.id}:rt`, rightMost, topMost),
      new Point(`${this.id}:rb`, rightMost, bottomMost),
    ];
  }

  intersects(other: Polygon): boolean {
    if (this.id === other.id) {
      return false;
    }
    return this.geometry.intersects(other.geometry);
  }

  contains(point: Point): boolean {
    return this.geometry.contains(point.geometry);
  }
}

export class Point<P = never> {
  geometry: geom.Point;
  constructor(public id: any, public x: number, public y: number, public properties?: P) {
    this.geometry = GEOMETRY_FACTORY.createPoint(new geom.Coordinate(x, y));
  }
}
