import { Color } from 'styled-components';

import { YardAttributeConfig } from '@config/types';

/**
 * Defines which spec to be applied to a yard based on its status
 * */
export interface YardStatusValueConfig extends YardAttributeConfig {
  /** Unique key for a spec, used in the filter endpoint. */
  key: YardStatusKey;

  /** Different color to use when main color doesn't contrast enough with other elements */
  alternativeColor: Color;
}

export enum YardStatusKey {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
