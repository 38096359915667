import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';

export const DropdownButton = styled(Button).attrs({ secondary: true, withTrailingIcon: true })(
  ({ theme }) => css`
    border-color: ${theme.primitives.colors.grey04};

    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent;
      border-color: ${theme.primitives.colors.grey06};
      color: ${theme.primitives.colors.grey08};
    }
  `
);

export const DropdownItemsWrapper = styled(Box).attrs({ column: true, stretch: true })(
  () => css`
    width: 300px;
    max-height: 300px;
    overflow: hidden auto;
  `
);

export const DropdownSearchInputWrapper = styled(Box)(
  ({ theme }) => css`
    input {
      padding-left: ${theme.spacing._150};
    }
  `
);

export const DropdownSearchIconWrapper = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: ${theme.spacing._100};
    transform: translateY(-50%);
  `
);

export const DropdownArrowSpan = styled.span(
  ({ theme }) => `
        z-index: ${theme.getZIndexOf('highLevelIndex')};
        pointer-events: none;
        position: absolute;
        right: ${theme.spacing._100};
        height: 100%;
        display: flex;
        align-items: center;
  `
);
