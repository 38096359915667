import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import SeasonIcon from '@assets/hht/Small_Pollination.svg';
import { Box } from '@components/common/Box';
import { IconIMG } from '@components/common/Icon';
import { Line } from '@components/common/Line';
import { Text } from '@components/common/Text';
import { InputCalendar } from '@components/form/inputs/InputCalendar';
import { makePatchOperationThunk } from '@redux/Operation/actions';

const DEF_FORMAT = 'YYYY-MM-DD';

export const SeasonCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const operation = useSelector((state) => state.operationReducer.operation);
  const seasonStart = operation?.seasonStart ?? null;

  const handleSeasonStartChange = useCallback(
    (nextSeasonStart) => {
      const seasonStartStr = moment(seasonStart).format(DEF_FORMAT);
      const nextSeasonStartStr = moment(nextSeasonStart).format(DEF_FORMAT);
      if (seasonStartStr !== nextSeasonStartStr) {
        dispatch(makePatchOperationThunk({ seasonStart: nextSeasonStart }));
      }
    },
    [dispatch, seasonStart]
  );

  return (
    <Box paper02 column stretch>
      <Box paddingHorizontal_100 paddingVertical_050 alignItems={'center'}>
        <IconIMG src={SeasonIcon} size={18} marginLeft_050 />
        <Box margin_050>
          <Text typography={'Heading3'} weight={'600'}>
            {t('season')}
          </Text>
        </Box>
      </Box>

      <Line />

      <Box column stretch padding_100>
        <Box margin_050 paddingBottom_025>
          <Text typography={'SmallParagraph'} color={'grey07'}>
            {t('season_help_text')}
          </Text>
        </Box>
        <Box margin_050>
          <InputCalendar
            id={'input-season-start'}
            name={'input-season-start'}
            label={t('start_date')}
            value={seasonStart}
            onChange={handleSeasonStartChange}
            disableYearSelector
          />
        </Box>
      </Box>
    </Box>
  );
};
