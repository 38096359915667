import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { from } from '@style/mediaQueries';

export const StyledModalWrapper = styled(Box)<{ $visible: boolean; $stackIndex: number; $stackReverseIndex: number }>(
  ({ theme, $visible, $stackIndex, $stackReverseIndex }) => css`
    position: fixed;
    inset: 0;
    align-items: center;
    flex-direction: column;
    z-index: ${theme.getZIndexOf('modal') + $stackIndex};
    pointer-events: none;

    ${$visible
      ? css`
          transition: none;
        `
      : css`
          transition: z-index 0ms linear ${theme.animations.durationLong}ms;
        `};

    ${StyledDialog}, ${StyledModalBackground}, ${StyledModalContentWrapper} {
      pointer-events: none;
    }

    ${$visible &&
    css`
      visibility: ${$stackReverseIndex > 2 ? 'hidden' : 'visible'};

      ${StyledDialog}, ${StyledModalBackground} {
        pointer-events: auto;
      }

      ${StyledModalBackground} {
        ${`background-color: rgba(0, 0, 0, ${0.5 / (2 * $stackReverseIndex + 1)});`}
      }

      ${$stackReverseIndex > 2 &&
      css`
        transition: visibility 0ms linear ${theme.animations.durationLong}ms;
        visibility: hidden;
      `}
    `}
  `
);

export const StyledModalBackground = styled.div(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: ${theme.animations.transitionLong('background')};
  `
);

export const StyledModalContentWrapper = styled(Box)<{ $stackReverseIndex: number }>(
  ({ theme, $stackReverseIndex }) => css`
    position: absolute;
    inset: 0;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    transition: ${theme.animations.transitionLong('transform')};
    transform: translateY(${sigmoid($stackReverseIndex) * -theme.primitives.px._200}px)
      scaleX(${1 - Math.min(1.0, $stackReverseIndex * 0.1)});
  `
);

export const StyledModalHeader = styled(Box)<{ $hasBorder: boolean; $hasContent: boolean }>(
  ({ theme, $hasBorder, $hasContent }) => css`
    background-color: ${theme.colors.surfaceSecondary};
    z-index: ${theme.getZIndexOf('modalHeader')};

    position: sticky;
    top: 50px; // Mobile header size.

    ${$hasBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.borderSecondary};
    `};

    ${$hasContent &&
    css`
      padding: ${theme.spacing._100} ${theme.spacing._100} ${theme.spacing._100} ${theme.spacing._100};
    `}

    ${from('tablet')} {
      position: sticky;
      top: 0;

      ${$hasContent &&
      css`
        padding: ${theme.spacing._200} ${theme.spacing._150} ${theme.spacing._150} ${theme.spacing._150};
      `}
    }
  `
);

export const StyledModalMobileHeader = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.surfaceSecondary};
    border-bottom: 1px solid ${theme.colors.borderSecondary};

    z-index: ${theme.getZIndexOf('modalMobileHeader')};
  `
);

export const StyledModalContent = styled(Box)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing._100} ${theme.spacing._100} ${theme.spacing._100};
    max-width: 100vw;

    ${from('tablet')} {
      padding: 0 ${theme.spacing._150} ${theme.spacing._150} ${theme.spacing._150};
    }

    z-index: ${theme.getZIndexOf('modalContent')};
  `
);

export const StyledModalFooter = styled.footer(
  ({ theme }) => css`
    position: sticky;
    bottom: 0;

    display: flex;
    align-items: stretch;
    border-top: 1px solid ${theme.primitives.colors.grey03};

    background-color: ${theme.colors.surfaceSecondary};

    padding: ${theme.spacing._100} ${theme.spacing._100};
    ${from('tablet')} {
      padding: ${theme.spacing._100} ${theme.spacing._150};
    }

    z-index: ${theme.getZIndexOf('modalFooter')};
  `
);

/** Base style for other modals. */
export const StyledDialog = styled.div.attrs({
  role: 'dialog',
  'aria-modal': true,
})(
  ({ theme }) => css`
    position: relative;
    margin: auto;
    border: none;
    overflow: hidden auto;

    display: grid;

    box-shadow: ${theme.shadows.boxShadow04};

    &::backdrop {
      display: none;
    }

    // This assumes that modals always have
    // the structure mobile-header+header+body+footer, and
    // makes the body to expand to fill the
    // available space.
    grid-template-rows: auto auto 1fr auto;

    ${from('tablet')} {
      grid-template-rows: auto 1fr auto;
    }
  `
);

/** Function to create a smooth ascending curve. */
function sigmoid(x: number, scale = 2.0): number {
  return scale * (1 / (1 + Math.exp(-x * 2.3)) - 0.5);
}
