import React from 'react';
import { capitalize } from 'lodash';

import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { useTranslation } from '@hooks/useTranslation';

export const YardTypeCell: React.FC<{ data: string }> = ({ data }) => {
  const t = useTranslation();
  return (
    <DefaultCellWrapper>
      <DefaultCellText>{capitalize(t(data))}</DefaultCellText>
    </DefaultCellWrapper>
  );
};
