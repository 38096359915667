import { useCallback, useEffect } from 'react';

import { useDynamicTableDataCycle } from '@components/common/DynamicTable/hooks';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType, AnalyticsPerformanceMetricEventType } from '@helpers/Analytics/types';

export function useYardsListAnalytics() {
  // Analytics event to show how many whiteboard rows are visible.
  const sendViewEvent = useCallback(() => {
    const bodyViewport = document.querySelector('.ag-body-viewport');
    if (!bodyViewport) {
      return;
    }

    const bodyViewportRect = bodyViewport.getBoundingClientRect();
    const rows = Array.from<HTMLElement>(document.querySelectorAll('.ag-pinned-left-cols-container .ag-row'));
    const visibleRowsCount = rows.filter((row) => {
      const rowRect = row.getBoundingClientRect();
      const visibleHeight = Math.max(Math.min(rowRect.height, bodyViewportRect.bottom - rowRect.top), 0);
      return visibleHeight / rowRect.height >= 0.5; // At least 50% of the item must be visible.
    }).length;

    Analytics.sendEvent({
      event: AnalyticsEventType.WHITEBOARD_VIEW,
      eventData: {
        visible_rows: visibleRowsCount,
      },
    });
  }, []);

  // Analytics event to show if the user has scrolled horizontally and vertically.
  const bindScrollEvents = useCallback(() => {
    const verticalScroll = document.querySelector('.ag-body-viewport');
    const horizontalScroll = document.querySelector('.ag-center-cols-viewport');

    const handleVerticalScroll = () => {
      Analytics.sendEvent({ event: AnalyticsEventType.WHITEBOARD_SCROLL_VERTICAL });
      verticalScroll?.removeEventListener('scroll', handleVerticalScroll);
    };
    const handleHorizontalScroll = () => {
      Analytics.sendEvent({ event: AnalyticsEventType.WHITEBOARD_SCROLL_HORIZONTAL });
      horizontalScroll?.removeEventListener('scroll', handleHorizontalScroll);
    };

    verticalScroll?.addEventListener('scroll', handleVerticalScroll);
    horizontalScroll?.addEventListener('scroll', handleHorizontalScroll);
  }, []);

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      sendViewEvent();
      bindScrollEvents();

      Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_TABLE);
      Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD);
    }, [bindScrollEvents, sendViewEvent]),
  });
}
