import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { MetricCard, MetricCardSimpleValue, MetricCarrousel } from '@components/common/Metrics';
import { MetricCardSimpleValueProps } from '@components/common/Metrics/types';
import { Number } from '@helpers/Number';
import { moneyFormatter } from '@helpers/StringTransformers';
import { useTranslation } from '@hooks/useTranslation';
import { useContractsMetricsLoader } from '@redux/ContractsMetrics/hooks';
import { ContractMetric, ContractMetricName } from '@redux/ContractsMetrics/types';

export const PolliContractsMetrics: React.VFC = () => {
  const t = useTranslation();
  const { loadMetrics } = useContractsMetricsLoader();
  const metricsState = useSelector((state) => state.contractsMetricsReducer);

  useEffect(() => loadMetrics(), [loadMetrics]);

  const metrics = useMemo(() => {
    const metricsToShow: Array<ContractMetricName> = [
      ContractMetricName.CONTRACTS,
      ContractMetricName.DROPS,
      ContractMetricName.HIVES_IN_STOCK,
      ContractMetricName.INCOME,
    ];

    return metricsToShow
      .filter((metricName) => metricName in metricsState.metrics || metricName in metricsState.isFetching)
      .map((metricName) => {
        const data: ContractMetric | null = metricsState.metrics[metricName] ?? null;
        const latestYear = data?.name === ContractMetricName.INCOME ? data.current.value.latestYear : null;
        const title =
          metricName === ContractMetricName.CONTRACTS
            ? t('total_contracts')
            : metricName === ContractMetricName.DROPS
            ? t('total_drops')
            : metricName === ContractMetricName.HIVES_IN_STOCK
            ? t('hives_in_stock')
            : t('income_year', { year: latestYear });

        const tooltip =
          metricName === 'hivesInStock'
            ? t('hives_in_stock_hint')
            : metricName === 'income'
            ? t('income_year_hint')
            : undefined;

        let cardValueProps: MetricCardSimpleValueProps | null;

        if (data) {
          let currValue: number | null;
          let currValueFormatted: string;
          let prevValue: number | null;
          let prevValueFormatted: string | undefined;
          let previousValueSuffix: string;
          let trendValue: number | null;
          let trendValueFormatted: string | undefined;

          if (data.name === ContractMetricName.INCOME) {
            currValue = data.current.value.latestYearTotalIncome;
            currValueFormatted = currValue ? moneyFormatter(currValue, { notation: 'compact' }) : '-';
            previousValueSuffix = t('last_year');
            prevValue = data.previous.value.latestYearTotalIncome;
            prevValueFormatted =
              prevValue === null ? '-' : `${moneyFormatter(prevValue, { notation: 'compact' })} ${previousValueSuffix}`;
            trendValue = currValue && prevValue ? currValue - prevValue : null;
            trendValueFormatted = trendValue
              ? moneyFormatter(Math.abs(trendValue), { notation: 'compact' })
              : undefined;
          } else {
            currValue = data.current.value;
            currValueFormatted = currValue ? Number.getReadableNumber(currValue) : '-';
            previousValueSuffix = t('last_week');
            prevValue = data.previous.value;
            prevValueFormatted = `${Number.getReadableNumber(data.previous.value)} ${previousValueSuffix}`;
            trendValue = currValue && prevValue ? currValue - prevValue : null;
            trendValueFormatted = trendValue ? Number.getReadableNumber(Math.abs(trendValue)) : undefined;
          }

          cardValueProps = {
            currentValue: currValueFormatted,
            previousValue: prevValueFormatted,
            trendValue: trendValueFormatted,
            trendDown: !!trendValue && trendValue < 0.0,
            trendDanger: !!trendValue && trendValue < 0.0,
          };
        } else {
          cardValueProps = { currentValue: '' };
        }

        const isFetching = !!metricsState.isFetching[metricName];
        return {
          cardProps: {
            title,
            tooltip,
            isFetching,
          },
          cardValueProps,
        };
      });
  }, [metricsState.isFetching, metricsState.metrics, t]);

  return (
    <Box marginTop_150 marginHorizontal_150>
      <MetricCarrousel storageKey={'contracts-metrics-storage-state'}>
        {metrics.map(({ cardProps, cardValueProps }, index) => (
          <MetricCard key={index} {...cardProps}>
            <MetricCardSimpleValue {...cardValueProps} />
          </MetricCard>
        ))}
      </MetricCarrousel>
    </Box>
  );
};
