import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@components/form/inputs/CheckBox';
import { YardRow } from '@components/yard/YardsList/types';

import { useYardsListSelection } from '../hooks/useYardsListSelectionContext';

export const YardSelectCheckbox: React.VFC<{ yard?: YardRow }> = ({ yard }) => {
  const { selectYard, deselectYard, selectedYards, hasSelectedAllOnCurrentPage } = useYardsListSelection();
  const shouldBeChecked = useMemo(() => {
    if (!yard) return false;
    if (hasSelectedAllOnCurrentPage || yard.meta.id in selectedYards) return true;
    return false;
  }, [selectedYards, hasSelectedAllOnCurrentPage, yard]);
  const [isChecked, setIsChecked] = useState(shouldBeChecked);

  useEffect(() => {
    setIsChecked(shouldBeChecked);
  }, [shouldBeChecked]);

  const handleOnChange = useCallback(
    (checked: boolean) => {
      if (yard) {
        setIsChecked(checked);
        if (checked) selectYard && selectYard(yard);
        else deselectYard && deselectYard(yard);
      }
    },
    [selectYard, deselectYard, yard]
  );

  if (!yard) {
    return null;
  }

  return (
    <div>
      <Checkbox
        style={{ display: 'flex' }}
        value={isChecked}
        name={`select-${yard.meta.id}`}
        onChange={handleOnChange}
      />
    </div>
  );
};
