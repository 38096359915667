import React from 'react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import {
  DynamicTableEmpty,
  DynamicTableEmptyHeader,
  DynamicTableEmptyText,
} from '@components/common/DynamicTable/DynamicTableEmpty';
import { useDynamicTableStates } from '@components/common/DynamicTable/hooks';
import { Add } from '@components/common/Icon/presets/Add';
import { useTranslation } from '@hooks/useTranslation';
import { useContractModalController } from '@redux/Contract/hooks';

export const PolliContractsListEmpty: React.VFC<{ showArchivedContracts?: boolean }> = ({ showArchivedContracts }) => {
  const t = useTranslation();
  const { isListEmpty, hasLoaded } = useDynamicTableStates();
  const { openToCreate } = useContractModalController();
  const showEmptyList = hasLoaded && isListEmpty;

  if (showArchivedContracts) {
    return (
      <DynamicTableEmpty visible={showEmptyList} backgroundColor="surfaceDefault">
        <DynamicTableEmptyHeader>{t('no_pollination_archived_contracts_yet')}</DynamicTableEmptyHeader>
      </DynamicTableEmpty>
    );
  }

  return (
    <DynamicTableEmpty visible={showEmptyList} backgroundColor="surfaceDefault">
      <DynamicTableEmptyHeader>{t('no_pollination_contracts_yet')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText>{t('no_pollination_contracts_message')}</DynamicTableEmptyText>
      <Box justifyContent={'center'} marginTop_150>
        <Button primary withLeadingIcon onClick={openToCreate}>
          <Add />
          <span>{t('pollination_add_contract')}</span>
        </Button>
      </Box>
    </DynamicTableEmpty>
  );
};
