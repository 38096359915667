import React from 'react';

import { Box } from '@components/common/Box';
import { InputText } from '@components/form/inputs/InputText';
import { useTranslation } from '@hooks/useTranslation';

export const FormNotes: React.VFC = () => {
  const t = useTranslation();
  return (
    <Box column>
      <InputText name={'notes'} label={t('field_notes')} useTextArea rows={12} suppressResizing />
    </Box>
  );
};
