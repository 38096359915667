import React from 'react';
// vendor
import styled from 'styled-components';

//nectar
import { AuthLogo } from '@components/auth/AuthLogo';

const ImageContainer = styled.div`
  height: 100%;
  width: auto;
  z-index: ${({ theme }) => theme.getZIndexOf('content')};
  top: 0;
  overflow: hidden;
  right: 0;
  border: 1px solid #979797;
  background-color: ${(props) => props.theme.primaryColor};
`;

const StyledImageContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const BackgroundImage = () => (
  <ImageContainer>
    <StyledImageContainer>
      <AuthLogo width={window.innerWidth * 0.25} />
    </StyledImageContainer>
  </ImageContainer>
);
