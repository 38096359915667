import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';

import { useTranslation } from '@hooks/useTranslation';

export function useColumnName(columnDef: ColDef, defaultDisplayName?: string) {
  const getter = useColumnNameGetter();
  return getter(columnDef, defaultDisplayName);
}

export function useColumnNameGetter() {
  const t = useTranslation();

  return useCallback(
    (columnDef: ColDef, defaultDisplayName?: string) => {
      switch (columnDef.field) {
        case 'composedName':
          return t('name');
        case 'composedStatus':
          return t('status');
        case 'location':
          return t('location');
        case 'type':
          return t('type_yard');
        case 'hives':
          return t('Hives');
        case 'introduced':
          return t('introduced_short');
        case 'queenless':
          return t('queenless_short');
        case 'deadout':
          return t('deadout_short');
        case 'healthScore':
          return t('health_score');
        case 'averageVisits':
          return t('avg_visit');
        case 'lastVisit':
          return t('last_visit');
        case 'grading':
          return t('grading');
        case 'groups':
          return t('groups');
        default:
          return defaultDisplayName;
      }
    },
    [t]
  );
}
