export const GlobalsUtil = {
  getOperationId,
  setOperationIdToLocalStorage,
  getOperationIdFromPathname,
  getOperationIdFromNextParam,
};

function getOperationId(): number | null {
  return [getOperationIdFromPathname(), getOperationIdFromLocalStorage()].find((id) => typeof id === 'number') ?? null;
}

function getOperationIdFromNextParam() {
  try {
    let pathname = window.location.pathname;
    const nextMatch = window.location.search.match(/next=(.*?)(&|$)/);
    if (nextMatch) {
      pathname = decodeURIComponent(nextMatch[1]);
      return parseInt(pathname.split('/')[1]) || null;
    }
  } catch (_) {
    // Try catch necessary since we can't avoid
    // users wrongly editing the current url
    // query params.
  }

  return null;
}

function getOperationIdFromPathname() {
  try {
    const pathname = window.location.pathname;
    return parseInt(pathname.split('/')[1]) || null;
  } catch (_) {
    // Try catch necessary since we can't avoid
    // users wrongly editing the current url path.
  }

  return null;
}

function getOperationIdFromLocalStorage() {
  try {
    const operationId = parseInt(localStorage.getItem('operation-id') ?? '');
    return isNaN(operationId) ? null : operationId;
  } catch (_) {
    /**
     * It might fail if the user manually edit the local storage.
     * So just silent fail.
     * */
  }
  return null;
}

function setOperationIdToLocalStorage(operationId: number | null) {
  if (operationId === null) {
    localStorage.removeItem('operation-id');
  } else {
    localStorage.setItem('operation-id', String(operationId));
  }
}
