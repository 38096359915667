import React from 'react';

import { BoxProps } from '@components/common/Box/types';

import { StyledModalContent } from './styles';

export const ModalContent: React.FC<BoxProps<'div'>> = ({ children, ...boxProps }) => {
  return (
    <StyledModalContent fit column {...boxProps}>
      {children}
    </StyledModalContent>
  );
};
