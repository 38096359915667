import React from 'react';

import { ButtonProps } from '@components/common/Button';
import { Button } from '@components/common/CTA';
import { useCurrentTabInfo } from '@components/common/Tabs/hooks';
import { useYardsListSaveView } from '@components/yard/YardsList/hooks/useYardsListSaveViewContext';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useAskBeforeExitAlert } from '@hooks/useAskBeforeExitAlert';
import { useTranslation } from '@hooks/useTranslation';

export const YardsListSaveViewButton: React.VFC<ButtonProps> = ({ disabled: propDisabled, ...props }) => {
  const t = useTranslation();
  const { hasPendingChange, patchViewState, discardViewState } = useYardsListSaveView();

  const { isActive: isCurrentTabActive } = useCurrentTabInfo();

  const onClickHandler = function () {
    Analytics.sendEvent({ event: AnalyticsEventType.WHITEBOARD_VIEW_SAVE });
    return patchViewState();
  };

  useAskBeforeExitAlert({
    message: t('on_before_lose_changes_prompt'),
    disabled: !hasPendingChange || !isCurrentTabActive,
    onExit: discardViewState,
  });

  const disabled = propDisabled || !hasPendingChange;

  return (
    <Button secondary onClick={onClickHandler} disabled={disabled} {...props}>
      {t('save_view')}
    </Button>
  );
};
