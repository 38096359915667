import { Reducer } from 'redux';

export type BulkClearOutYardsReducer = Reducer<BulkClearOutYardsState, BulkClearOutYardsAction>;

export type BulkClearOutYardsAction =
  | { type: BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_OPEN_MODAL }
  | { type: BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_CLOSE_MODAL };

export interface BulkClearOutYardsState {
  isModalOpen: boolean;
}

export enum BulkClearOutYardsActionType {
  BULK_CLEAR_OUT_YARDS_OPEN_MODAL = 'BULK_CLEAR_OUT_YARDS_OPEN_MODAL',
  BULK_CLEAR_OUT_YARDS_CLOSE_MODAL = 'BULK_CLEAR_OUT_YARDS_CLOSE_MODAL',
}
