import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputText } from '@components/form/inputs/InputText';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeAuthPatchUserThunk } from '@redux/Auth/actions';

export const ProfileChangeNameModal: React.VFC<ModalProps> = ({ ...modalProps }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useFormDefaultValues({ firstName: user?.firstName ?? '', lastName: user?.lastName ?? '' }),
    resolver: useFormSchemaResolver<{ firstName: string; lastName: string }>((schema) => ({
      firstName: schema.string().required(),
      lastName: schema.string(),
    })).resolver,
  });

  const attemptToSubmit = useCallback(() => {
    if (form.formState.isValid) {
      dispatch(makeAuthPatchUserThunk(form.getValues()));
    }
  }, [dispatch, form]);

  // Clean up from last modal opening.
  useEffect(() => {
    if (modalProps.isOpen) {
      form.setValue('firstName', user?.firstName ?? '');
      form.setValue('lastName', user?.lastName ?? '');
    }
  }, [form, modalProps.isOpen, user?.firstName, user?.lastName]);

  return (
    <Modal {...modalProps}>
      <Form form={form} onValidSubmit={attemptToSubmit} column stretch>
        <ModalHeader
          title={t('profile_change_name_modal_title')}
          subtitle={t('profile_change_name_modal_message')}
          closeButtonProps={{ type: 'button' }}
        />
        <ModalContent>
          <Box gap_075 alignItems={'flex-start'}>
            <Box fit column stretch>
              <InputText
                name={'firstName'}
                label={t('profile_change_name_first_name')}
                placeholder={t('profile_change_name_first_name_placeholder')}
              />
            </Box>
            <Box fit column stretch>
              <InputText
                name={'lastName'}
                label={t('profile_change_name_last_name')}
                placeholder={t('profile_change_name_last_name_placeholder')}
              />
            </Box>
          </Box>
        </ModalContent>
        <ModalFooter
          rejectText={t('cancel')}
          acceptText={t('save_changes')}
          acceptButtonProps={{ type: 'submit', disabled: !form.formState.isValid }}
          rejectButtonProps={{ type: 'button' }}
          autoCloseOnAccept
          autoCloseOnReject
        />
      </Form>
    </Modal>
  );
};
