import produce from 'immer';

import { HiveDetailsActionType, HiveDetailsReducer, HiveDetailsState } from '@redux/HiveDetails/types';

const defaultState: HiveDetailsState = {
  hives: {},
};

export const hiveDetailsReducer: HiveDetailsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case HiveDetailsActionType.HIVE_DETAILS_DISPOSE:
        delete state.hives[action.payload.id];
        break;

      case HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_START:
        state.hives[action.payload.id] = {
          ...state.hives[action.payload.id],
          isFetchingDetail: true,
        };
        break;

      case HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_FINISH:
        state.hives[action.payload.id] = {
          ...state.hives[action.payload.id],
          detail: action.payload.data,
          isFetchingDetail: false,
        };
        break;

      case HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_START:
        state.hives[action.payload.id] = {
          ...state.hives[action.payload.id],
          isFetchingActivitySet: true,
        };
        break;

      case HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_FINISH:
        state.hives[action.payload.id] = {
          ...state.hives[action.payload.id],
          activitySet: action.payload.data
            ? {
                totalCount: action.payload.data.totalCount,
                activities: [
                  ...(state.hives[action.payload.id]?.activitySet?.activities ?? []),
                  ...action.payload.data.activities,
                ],
              }
            : null,
          isFetchingActivitySet: false,
        };
        break;
    }

    return state;
  });
};
