import produce from 'immer';

import {
  ContractsMetricsActionType,
  ContractsMetricsReducer,
  ContractsMetricsState,
} from '@redux/ContractsMetrics/types';

const defaultState: ContractsMetricsState = {
  metrics: {},
  isFetching: {},
};

export const contractsMetricsReducer: ContractsMetricsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_START:
        const { metricNames } = action.payload;
        metricNames.forEach((metricName) => {
          state.isFetching[metricName] = true;
        });
        break;
      case ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_FINISH:
        const { metrics } = action.payload;
        metrics.forEach((metric) => {
          state.isFetching[metric.name] = false;
          state.metrics[metric.name] = metric;
        });
        break;
    }
    return state;
  });
};
