import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { YardClearOrDeleteAction, YardClearOrDeleteActionType } from '@redux/YardClearOrDelete/types';

export const makeYardClearOrDeleteDeletableFetchStartAction = (): YardClearOrDeleteAction => ({
  type: YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_START,
});

export const makeYardClearOrDeleteDeletableFetchFinishAction = (deletable: boolean): YardClearOrDeleteAction => ({
  type: YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_FINISH,
  payload: { deletable },
});

export const makeYardClearOrDeleteDeletableFetchThunk = Thunk.createTakeFirst((id: number) => {
  return async (dispatch) => {
    dispatch(makeYardClearOrDeleteDeletableFetchStartAction());

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.hhtYardDetailDeletable, { id }));
    const data = response.status === 400 ? await response.json() : {};
    const hasToBeClearedOut = data?.errors?.must_be_cleared;
    const deletable = !hasToBeClearedOut && response.status === 204;

    if (deletable || hasToBeClearedOut) {
      dispatch(makeYardClearOrDeleteDeletableFetchFinishAction(deletable));
    } else {
      response.error && dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw new Error(`${response.status}`);
    }
  };
});
