import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { Box } from '@components/common/Box';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { useFormFieldError, useFormFieldValue } from '@components/form/core/Form/hooks';
import { InputMultilineBaseProps, InputSimpleBaseProps } from '@components/form/core/InputBase';
import { Children } from '@helpers/Children';

import {
  StyledErrorMessage,
  StyledHintText,
  StyledInput,
  StyledInputLeftContent,
  StyledLabel,
  StyledWrapper,
} from './styles';

export type InputTextProps<FV extends FieldValues = FieldValues> =
  | ({ useTextArea?: false } & InputTextSimpleProps<FV>)
  | ({ useTextArea: true } & InputTextMultilineProps<FV>);

export type InputTextSimpleProps<FV extends FieldValues> = InputSimpleBaseProps<FV> & InputTextExtraProps;
export type InputTextMultilineProps<FV extends FieldValues> = InputMultilineBaseProps<FV> & InputTextExtraProps;

export interface InputTextExtraProps {
  label?: string;

  /**
   * Shown below the input if no errors.
   * */
  hintText?: string;

  /**
   * The provided text is appended at left of the input text.
   * */
  innerLeftContent?: any;

  /**
   * The provided content is placed at right of the input.
   * */
  outerRightContent?: any;

  /**
   * Disables the text area manual resizing.
   * */
  suppressResizing?: boolean;
}

export const InputText = <FV extends FieldValues = FieldValues>({
  label,
  hintText,
  innerLeftContent: propInnerLeftContent,
  outerRightContent,
  suppressResizing,
  ...inputBaseProps
}: InputTextProps<FV>) => {
  const { error, hasError } = useFormFieldError(inputBaseProps.name);
  const { value } = useFormFieldValue(inputBaseProps.name);

  const bottomContent = useMemo(() => {
    return hasError ? (
      <StyledErrorMessage dangerouslySetInnerHTML={{ __html: String(error?.message) }} />
    ) : hintText ? (
      <StyledHintText dangerouslySetInnerHTML={{ __html: hintText }} />
    ) : null;
  }, [error?.message, hasError, hintText]);

  let innerLeftContent = null;
  if (value && propInnerLeftContent) {
    innerLeftContent = Children.hasOnlyText(propInnerLeftContent) ? (
      <StyledInputLeftContent>{propInnerLeftContent}</StyledInputLeftContent>
    ) : (
      propInnerLeftContent
    );
  }

  return (
    <div>
      <StyledWrapper hasError={hasError} disabled={inputBaseProps.disabled || inputBaseProps.readOnly}>
        <Box alignItems={'center'}>
          <Box fit column stretch>
            <StyledLabel isRequired={inputBaseProps.required} htmlFor={inputBaseProps.id}>
              {label}
            </StyledLabel>
            <Box paddingTop_025={!!label} alignItems={'center'}>
              {innerLeftContent}
              <StyledInput $suppressResizing={suppressResizing} autoComplete={'off'} {...inputBaseProps} />
            </Box>
          </Box>

          {outerRightContent}
        </Box>
      </StyledWrapper>

      <Box relative>
        <ContentChangeTransition content={bottomContent}>{(content) => <>{content}</>}</ContentChangeTransition>
      </Box>
    </div>
  );
};
