import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import wkx from 'wkx';

import FeatureNotAvailableImg from '@assets/responsive/feature-not-available.png';
import { Box } from '@components/common/Box';
import { HiveDot } from '@components/common/Icon/dynamic/HiveDot';
import { Map, MapControls, Marker, Polygon } from '@components/common/Map';
import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { DEFAULT_MAP_STATE } from '@components/common/Map/constants';
import { useMapState } from '@components/common/Map/hooks';
import { MapInstance, MapType, MarkerDescriptor, Path } from '@components/common/Map/types';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { YardMarkerPin } from '@components/yard/YardMarkerPin';
import { GeometryAnalyzer, Point as GAPoint, Polygon as GAPolygon } from '@helpers/MapOptimizer/GeometryAnalyzer';
import { useTranslation } from '@hooks/useTranslation';

const MIN_ZOOM_TO_SHOW_HIVES = 11;

export const FormMap: React.VFC = () => {
  const t = useTranslation();
  const theme = useTheme();
  const { detail, isModalOpen } = useSelector((state) => state.yardCreateOrUpdateReducer);
  const form = useFormContext();

  const [mapInstance, setMapInstance] = useState<MapInstance | null>(null);
  const [mapState, setMapState] = useMapState({ ...DEFAULT_MAP_STATE, mapType: MapType.TERRAIN });

  const yardEditedGeometry = form.watch('geometry') as BeePolygonGeometry | null;

  const yardPath = useMemo(() => {
    if (!detail) {
      return null;
    }
    return detail.geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }));
  }, [detail]);

  const yardEditedPath = useMemo(() => {
    if (!yardEditedGeometry) {
      return null;
    }
    return yardEditedGeometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }));
  }, [yardEditedGeometry]);

  const yardNeighbors = useMemo(() => {
    if (!detail) {
      return null;
    }
    return detail.nearbyYards.map(
      ({
        name,
        yardCenter: {
          coordinates: [lng, lat],
        },
      }) => ({ name, position: { lng, lat } })
    );
  }, [detail]);

  const yardNeighborsPaths = useMemo(() => {
    if (!detail) {
      return null;
    }
    return detail.nearbyYards.map(({ geometry }) => geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat })));
  }, [detail]);

  const hives = useMemo(() => {
    if (!detail || !detail.hivesPosition.length) {
      return null;
    }
    const rawPoints = detail.hivesPosition;
    return rawPoints
      .map(wkx.Geometry.parse)
      .map((p) => p.toGeoJSON() as any)
      .map(({ coordinates: [lng, lat] }, id) => ({ id, position: { lat, lng }, data: null }));
  }, [detail]);

  const geometryAnalyser = useMemo(() => {
    if (!detail || !yardEditedPath) {
      return null;
    }

    const yardPolygon = new GAPolygon(
      detail.id,
      yardEditedPath.map(({ lng, lat }, id) => new GAPoint(id, lng, lat))
    );
    const neighborsPolygons = (yardNeighborsPaths ?? []).map(
      (path) =>
        new GAPolygon(
          uuid(),
          path.map(({ lng, lat }, id) => new GAPoint(id, lng, lat))
        )
    );
    const hivePoints = hives ? hives.map((p, id) => new GAPoint(id, p.position.lng, p.position.lat)) : [];
    return new GeometryAnalyzer([yardPolygon, ...neighborsPolygons], hivePoints);
  }, [detail, hives, yardEditedPath, yardNeighborsPaths]);

  const renderHiveMarker = useCallback(
    (marker: MarkerDescriptor) => {
      return <Marker {...marker} icon={HiveDot.getImageURI({ theme, size: 16 })} />;
    },
    [theme]
  );

  const validateGeometry = useCallback(() => {
    if (!geometryAnalyser) {
      return;
    }

    if (geometryAnalyser.getNextOrphanPoint()) {
      form.setError('geometry', { message: t('yard_not_all_hives_included_error_msg') });
    } else if (geometryAnalyser.getNextIntersectingPolygons()) {
      form.setError('geometry', { message: t('yards_intersecting_error_msg') });
    } else {
      form.clearErrors('geometry');
    }
  }, [form, geometryAnalyser, t]);

  const recenterMap = useCallback(() => {
    const path = yardEditedPath || yardPath;
    if (detail && mapInstance && path?.length) {
      mapInstance.fitCoordinates(path, 64);
    }
    validateGeometry();
  }, [detail, mapInstance, validateGeometry, yardEditedPath, yardPath]);

  const handleGeometryChange = useCallback(
    (path: Path | null) => {
      if (path) {
        const geometry: BeePolygonGeometry = {
          type: 'Polygon',
          coordinates: [path.map(({ lng, lat }) => [lng, lat])],
        };
        form.setValue('geometry', geometry);
      }
    },
    [form]
  );

  useEffect(() => {
    recenterMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, detail]);

  useEffect(() => {
    validateGeometry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardEditedGeometry]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Box borderColor={'borderSecondary'} borderWidth={1}>
      <ResponsiveRender from={'tablet'}>
        <Map state={mapState} onStateChange={setMapState} onInstance={setMapInstance}>
          {yardPath && <Polygon path={yardPath} onPathChange={handleGeometryChange} editable />}

          {yardNeighborsPaths?.map((path, key) => (
            <Polygon key={key} path={path} strokeWeight={0} />
          ))}

          {yardNeighbors?.map(({ name, position }, key) => (
            <Marker key={key} position={position}>
              <YardMarkerPin yard={{ name }} />
            </Marker>
          ))}

          {hives && (
            <MarkerOptimizer
              markers={hives}
              markerRenderer={renderHiveMarker}
              minZoomToRender={MIN_ZOOM_TO_SHOW_HIVES}
            />
          )}

          <MapControls showMapTypeToggle recenterMap={recenterMap} />
        </Map>
      </ResponsiveRender>

      <ResponsiveRender until={'mobile'}>
        <Box gap_150 padding_150 column center>
          <img alt={t('feature_unavailable')} src={FeatureNotAvailableImg} width="120px" />
          <Text typography={'SmallParagraph'} weight={'600'} align={'center'}>
            {t('responsive_feature_not_available')}
          </Text>
        </Box>
      </ResponsiveRender>
    </Box>
  );
};
