import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const WarningToast = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 10 10" size={size}>
      <path
        fill={useColor(color)}
        d="M4.375 2.23959C4.375 1.92318 4.6315 1.66667 4.94792 1.66667C5.26433 1.66667 5.52083 1.92318 5.52083 2.23959V6.09375C5.52083 6.41017 5.26433 6.66667 4.94792 6.66667C4.6315 6.66667 4.375 6.41017 4.375 6.09375V2.23959Z"
      />
      <path
        fill={useColor(color)}
        d="M5.52083 8.07292C5.52083 8.38933 5.26433 8.64584 4.94792 8.64584C4.6315 8.64584 4.375 8.38933 4.375 8.07292C4.375 7.75651 4.6315 7.5 4.94792 7.5C5.26433 7.5 5.52083 7.75651 5.52083 8.07292Z"
      />
    </IconSVGView>
  );
};
