import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import wkx from 'wkx';

import { Box } from '@components/common/Box';
import { HiveDot } from '@components/common/Icon/dynamic/HiveDot';
import { Pin } from '@components/common/Icon/presets/Pin';
import { Line } from '@components/common/Line';
import { Map, Marker, Polygon } from '@components/common/Map';
import { MapInstance, MapState, MapType } from '@components/common/Map/types';
import { Text } from '@components/common/Text';
import { StyledMapWrapper, StyledOpenOnMapButton } from '@components/yard/YardDetailsDashboard/styles';
import { GMAP_SEARCH_URL } from '@config/google';
import { useTranslation } from '@hooks/useTranslation';

const DEF_MAP_STATE: Partial<MapState> = {
  mapType: MapType.TERRAIN,
};

export const YardDetailsDashboardLocation: React.VFC = () => {
  const t = useTranslation();
  const theme = useTheme();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const yardShape = useMemo(() => {
    return yard?.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng })) ?? [];
  }, [yard?.geometry.coordinates]);

  const hives = useMemo(() => {
    return (
      yard?.hivesPosition
        .map((posStr) => wkx.Geometry.parse(posStr).toGeoJSON() as any)
        .map(({ coordinates: [lng, lat] }) => ({ lat, lng })) ?? []
    );
  }, [yard?.hivesPosition]);

  const onMapInstance = useCallback(
    (instance: MapInstance) => {
      instance.fitCoordinates(yardShape);
    },
    [yardShape]
  );

  const openOnExternalMap = useCallback(() => {
    if (!yard) {
      return;
    }

    const { coordinates } = yard.yardCenter;
    const latLng = `${coordinates[1]},${coordinates[0]}`;
    window.open(GMAP_SEARCH_URL.replace('{{LATLNG}}', latLng).replace('{{LL}}', latLng), '_blank');
  }, [yard]);

  if (!yard) {
    return null;
  }

  return (
    <Box fit column>
      <Box column paddingVertical_100 paddingHorizontal_150 gap_025>
        <Text typography={'Heading3'} weight={'600'}>
          {t('location')}
        </Text>

        <Box alignItems={'center'} justifyContent={'space-between'}>
          <Text typography={'SmallParagraph'}>{yard.nearestLocation}</Text>
          <Text typography={'CaptionSmall'} color={'grey07'}>
            {[...yard.yardCenter.coordinates]
              .reverse()
              .map((val) => val.toFixed(6))
              .join(', ')}
          </Text>
        </Box>
      </Box>

      <Line />

      <StyledMapWrapper fit>
        <Map state={DEF_MAP_STATE} onInstance={onMapInstance}>
          {hives.map((pos, index) => (
            <Marker key={index} position={pos} icon={HiveDot.getImageURI({ theme, size: 12 })} />
          ))}
          <Polygon path={yardShape} />

          <StyledOpenOnMapButton onClick={openOnExternalMap}>
            <Pin size={24} />
          </StyledOpenOnMapButton>
        </Map>
      </StyledMapWrapper>
    </Box>
  );
};
