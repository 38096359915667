/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React from 'react';

import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { YardDotRenderer } from '@components/yard/YardsMapLayers/elements/YardDotRenderer';
import { YardMarkerRenderer } from '@components/yard/YardsMapLayers/elements/YardMarkerRenderer';
import { YardPolygonRenderer } from '@components/yard/YardsMapLayers/elements/YardPolygonRenderer';
import {
  useYardDescriptors,
  useYardElementsDescriptors,
  useYardElementsVisibilityFilter,
  useYardElementZIndexGetter,
} from '@components/yard/YardsMapLayers/hooks';
import { useLastInspectionSpecGetter } from '@config/LastInspectionConfig/hooks';

const MIN_ZOOM_TO_SHOW_YARD_ELEMENTS = 14;
const MAX_ZOOM_TO_SHOW_YARD_DOTS = 12.75;
const DEF_POLYGON_PROPS = { fillOpacity: 0.5, strokeWeight: 1, strokeOpacity: 1 };

export interface YardsMapLastInspectionLayerProps {
  yards: Array<BeeYardOnMapInfo>;
  isYardSelected?: (yard: BeeYardOnMapInfo) => boolean;
  onYardPinClick?: (yard: BeeYardOnMapInfo) => void;
}

export const YardsMapLastInspectionLayer: React.VFC<YardsMapLastInspectionLayerProps> = ({
  yards,
  isYardSelected,
  onYardPinClick,
}) => {
  const yardDescriptors = useYardDescriptors(yards);
  const yardElementsDescriptors = useYardElementsDescriptors(yards);
  const visibleMarkersFilter = useYardElementsVisibilityFilter();
  const yardElementZIndexGetter = useYardElementZIndexGetter();

  const getLIMColorSpec = useLastInspectionSpecGetter();

  return (
    <>
      <MarkerOptimizer
        enableSmartBounds
        markers={yardElementsDescriptors}
        markerRenderer={(props) => {
          // eslint-disable-next-line react/prop-types
          const yard = props.data;
          const { color, priority } = getLIMColorSpec(yard);
          const polygonProps = {
            fillColor: color,
            strokeColor: color,
            zIndex: yardElementZIndexGetter(yard, priority),
            ...DEF_POLYGON_PROPS,
          };
          return <YardPolygonRenderer polygonProps={polygonProps} {...props} />;
        }}
        visibleMarkersFilter={visibleMarkersFilter}
        minZoomToRender={MIN_ZOOM_TO_SHOW_YARD_ELEMENTS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => (
          <YardMarkerRenderer isYardSelected={isYardSelected} onYardPinClick={onYardPinClick} {...props} />
        )}
        minZoomToRender={MAX_ZOOM_TO_SHOW_YARD_DOTS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => {
          // eslint-disable-next-line react/prop-types
          const yard = props.data;
          const { color, priority } = getLIMColorSpec(yard);
          const zIndex = yardElementZIndexGetter(yard, priority);
          return <YardDotRenderer color={color} zIndex={zIndex} {...props} />;
        }}
        maxZoomToRender={MAX_ZOOM_TO_SHOW_YARD_DOTS}
      />
    </>
  );
};
