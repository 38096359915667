import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledBulkActionsList = styled(Box).attrs({
  column: true,
  stretch: true,
})(
  ({ theme }) => css`
    transition: ${theme.animations.transitionLong('height', 'opacity')};
    overflow: hidden;
  `
);

export const StyledBulkActionItem = styled(Box).attrs({
  gap_100: true,
  alignItems: 'center',
})(
  () => css`
    height: 36px;
  `
);

export const StyledBulkActionItemStatusWrapper = styled(Box).attrs({
  relative: true,
  center: true,
})(
  () => css`
    height: 36px;
    width: 36px;
    margin-left: auto;
  `
);
