import { Color, useTheme } from 'styled-components';

export function useColor(color: Color | string | undefined | null, defaultColor = 'currentColor') {
  const theme = useTheme();
  if (color) {
    const themeColor = (theme.primitives.colors as any)[color] || (theme.colors as any)[color];
    return themeColor || color;
  }
  return defaultColor;
}
