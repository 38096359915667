import { Reducer } from 'redux';

export type PreferencesReducer = Reducer<PreferencesState, PreferencesAction>;

export type PreferencesAction =
  | { type: PreferencesActionType.PREFERENCES_PATCH_USER_START }
  | {
      type: PreferencesActionType.PREFERENCES_PATCH_USER_FINISH;
      payload: { preferences: BeeUserPreferences | null };
    }
  | { type: PreferencesActionType.PREFERENCES_PATCH_MEMBERSHIP_START }
  | {
      type: PreferencesActionType.PREFERENCES_PATCH_MEMBERSHIP_FINISH;
      payload: { preferences: BeeMembershipPreferences | null };
    };

export type PreferencesState = {
  userPreferences: BeeUserPreferences | null;
  isFetchingUserPreferences: boolean;

  membershipPreferences: BeeMembershipPreferences | null;
  isFetchingMembershipPreferences: boolean;
};

export enum PreferencesActionType {
  PREFERENCES_PATCH_USER_START = 'PREFERENCES_PATCH_USER_START',
  PREFERENCES_PATCH_USER_FINISH = 'PREFERENCES_PATCH_USER_FINISH',

  PREFERENCES_PATCH_MEMBERSHIP_START = 'PREFERENCES_PATCH_MEMBERSHIP_START',
  PREFERENCES_PATCH_MEMBERSHIP_FINISH = 'PREFERENCES_PATCH_MEMBERSHIP_FINISH',
}
