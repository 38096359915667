import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { from } from '@style/mediaQueries';

export const StyledFormWrapper = styled(Box).attrs({ column: true, stretch: true })(
  ({ theme }) => css`
    width: calc(100vw - ${theme.spacing._200});

    ${from('tablet')} {
      width: 348px;
    }

    /** Removes Chrome autocomplete styling. */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${theme.colors.contentPrimary};
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px ${theme.colors.surfaceDefault};
      font-size: 1rem;
      font-weight: 600;
      padding: 0 ${theme.spacing._050};
      border-radius: ${theme.shape.paperRadius01}px;
    }
  `
);

export const StyledAuthActions = styled(Box)`
  flex-direction: column;
  align-items: stretch;

  button {
    align-items: center;
    justify-content: center;
  }

  ${from('tablet')} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledAuthTitle = styled(Text).attrs({ typography: 'Heading1', weight: '700' })``;

export const StyledAuthTitleHighlight = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.contentHighlightBright};
    weight: 700;
  `
);
