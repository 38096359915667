import { PracticesConfigSet } from '@config/Practices/types';

export const PracticesConfig: PracticesConfigSet = {
  PRACTICE_DEFINITIONS: {
    'hive status': {
      suppressMenu: true,
      suppressActionAdd: true,
    },
    'hive grading': {
      suppressActionAdd: true,
    }
  },
  ACTION_DEFINITIONS: {
    // Hive status
    deadout: { suppressMenu: true },
    queenless: { suppressMenu: true },
    queenright: { suppressMenu: true },

    // Hive grading
    hive_grading_weak: { suppressMenu: true },
    hive_grading_medium: { suppressMenu: true },
    hive_grading_strong: { suppressMenu: true },
  },
};
