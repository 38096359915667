import { GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

/**
 * This is a patch to avoid calling getColumnDefs
 * after the grid api is destroyed.
 * */

const _destroyState = { destroyed: false };

(GridApi.prototype as any)._init = (GridApi.prototype as any).init;
(GridApi.prototype as any).init = function () {
  _destroyState.destroyed = false;
  (this as any)._init();
};

(GridApi.prototype as any)._destroy = GridApi.prototype.destroy;
GridApi.prototype.destroy = function () {
  _destroyState.destroyed = true;
  return (this as any)._destroy();
};

(GridApi.prototype as any)._getColumnDefs = GridApi.prototype.getColumnDefs;
GridApi.prototype.getColumnDefs = function () {
  if (_destroyState.destroyed) {
    return [];
  }
  return (this as any)._getColumnDefs();
};

export const AgGrid: typeof AgGridReact = AgGridReact;

export * from './context';
export * from './hooks';
export * from './provider';
