import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGridApi } from '@components/common/AgGrid';

import { DynamicTableRow } from '../types';

import { DynamicTableSelectionContext, DynamicTableSelectionContextValue, RowSelectionMap } from './context';

export const DynamicTableSelectionProvider: React.FC = ({ children }) => {
  const { gridApi } = useGridApi();

  const [currentPage, setCurrentPage] = useState(gridApi?.paginationGetCurrentPage() ?? 0);
  const [hasClickedSelectAllAcrossPages, setHasClickedSelectAllAcrossPages] = useState(false);

  /**
   * Returns a nested map of the selected rows, first level is by page, second by ID
   *
   * Needs to be a map and not an array bc you could have page 4 selected and not page 5
   */
  const [selectedRowsByPage, setselectedRowsByPage] = useState<Record<number, RowSelectionMap>>({});

  /**
   * Returns a single level map of the selected rows, by id
   *
   * Used by components outside of this file
   */
  const selectedRows = useMemo(() => {
    return Object.values(selectedRowsByPage).reduce(
      (selectedRowsMap: Record<number, DynamicTableRow>, currentPage) => ({ ...selectedRowsMap, ...currentPage }),
      {}
    );
  }, [selectedRowsByPage]);

  /**
   * Returns an array of all the selected row IDs
   */
  const selectedRowIds = useMemo(() => Object.keys(selectedRows).map(Number), [selectedRows]);

  /**
   * Returns the total number of rows in the selected state map
   */
  const selectedRowsLength = useMemo(() => selectedRowIds.length, [selectedRowIds]);

  /**
   * Returns true if the user has actually checked each checkbox of each row, or the entire page mastercheckbox
   * for all (filtered) rows
   *
   * Necessary for instances when there are only 9 rows, and the user wants to apply an action to them.
   * Also, if the user actually selects 400 rows, they should be able to mass apply an action
   */
  const allRowsAcrossPagesAreSelectedManually = useMemo(() => {
    if (!selectedRowsLength) return false;
    return gridApi?.paginationGetRowCount() === selectedRowsLength;
  }, [gridApi, selectedRowsLength]);

  /**
   * Returns selectedRowsLength OR just the total number of rows, if
   * user has clicked 'selected all across pages', in which case, the rows
   * aren't actually added into selectedRowsByPage
   */
  const selectionCount = useMemo(() => {
    if (hasClickedSelectAllAcrossPages && gridApi) {
      return gridApi.paginationGetRowCount();
    }

    return selectedRowsLength;
  }, [hasClickedSelectAllAcrossPages, gridApi, selectedRowsLength]);

  /**
   * Returns a true if all rows are selected on the page the user is on
   *
   * Useful for when the user selects all on page 4, navigates to page 5, and navigates back to page 4
   * and expects to see the master page select checkbox checked
   */
  const hasSelectedAllOnCurrentPage = useMemo(() => {
    if (selectedRowsByPage[currentPage]) {
      return Object.keys(selectedRowsByPage[currentPage]).length === gridApi?.getRenderedNodes().length;
    } else return false;
  }, [selectedRowsByPage, currentPage, gridApi]);

  /**
   * Returns true if all rows are selected on any page
   * Basically it only exists to show/hide the yellow banner with
   * the 'select all' and 'deselect all' options
   */
  const hasSelectedAllOnPage = useMemo(() => {
    if (!gridApi) return false;
    if (hasClickedSelectAllAcrossPages || allRowsAcrossPagesAreSelectedManually) return true;

    const numItemsPerPage = gridApi.paginationGetPageSize();
    const atLeastOnePageFullySelected = Object.values(selectedRowsByPage).some(
      (page) => Object.keys(page).length === numItemsPerPage
    );
    if (atLeastOnePageFullySelected) return true;

    // this is because usually the length of the last page is less than the other pages,
    // so we have to get the length to know if it's been fully selected
    const totalPages = gridApi?.paginationGetTotalPages();
    const lastPage = totalPages - 1;

    if (!selectedRowsByPage[lastPage]) return false;

    const totalItems = gridApi?.paginationGetRowCount();
    const totalItemsOnLastPage = totalItems % numItemsPerPage;

    const isLastPageFullySelected = Object.keys(selectedRowsByPage[lastPage]).length === totalItemsOnLastPage;
    return isLastPageFullySelected;
  }, [allRowsAcrossPagesAreSelectedManually, gridApi, hasClickedSelectAllAcrossPages, selectedRowsByPage]);

  /**
   * Returns a list of all the rows currently on the page
   *
   * Basically just a shorthand variable with some typing
   */
  const currentlyVisibleRows = useCallback(() => {
    if (!gridApi) return [];
    const nodes = gridApi.getRenderedNodes();
    return nodes.map((node) => node.data);
  }, [gridApi]);

  /**
   * Adds a row to the selectedRowsByPage state map
   *
   * The exposed function used outside of this file
   */
  const selectRow = useCallback(
    (row: DynamicTableRow) => {
      setselectedRowsByPage((pages) => ({
        ...pages,
        [currentPage]: { ...pages[currentPage], [row.meta.id]: row },
      }));
    },
    [currentPage]
  );

  /**
   * Removes a row from the selectedRowsByPage state map
   *
   * The exposed function used outside of this file
   */

  const deselectRow = useCallback(
    (row: DynamicTableRow) => {
      setselectedRowsByPage((pages) => {
        if (Object.keys(pages[currentPage]).length === 1 && row.meta.id in pages[currentPage]) {
          const pagesCopy = { ...pages };
          delete pagesCopy[currentPage];
          return pagesCopy;
        }
        const rows = { ...pages[currentPage] };
        delete rows[row.meta.id];
        return {
          ...pages,
          [currentPage]: rows,
        };
      });

      setHasClickedSelectAllAcrossPages(false);
    },
    [currentPage]
  );

  /**
   * Adds all rows on the page to the selectedRowsByPage state map
   *
   * The exposed function used outside of this file
   */

  const selectAllOnPage: (page?: number) => void = useCallback(
    (page) => {
      const obj: RowSelectionMap = {};
      const newPage = page ?? currentPage;
      currentlyVisibleRows().forEach((row) => (obj[row?.meta?.id] = row));

      setselectedRowsByPage((pages) => {
        return {
          ...pages,
          [newPage]: obj,
        };
      });
    },
    [currentPage, currentlyVisibleRows]
  );

  /**
   * Removes the current page from selectedRowsByPage
   *
   * The exposed function used outside of this file
   */

  const deselectAllOnPage = useCallback(() => {
    if (!gridApi) return;
    setselectedRowsByPage((pages) => {
      const copiedPages = { ...pages };
      delete copiedPages[currentPage];
      return copiedPages;
    });

    setHasClickedSelectAllAcrossPages(false);
  }, [currentPage, gridApi]);

  const selectAllAcrossPages = useCallback(() => {
    if (!gridApi) return;
    setHasClickedSelectAllAcrossPages(true);
  }, [gridApi]);

  const deselectAllAcrossPages = useCallback(() => {
    if (!selectedRowsLength) return;
    setselectedRowsByPage({});
    setHasClickedSelectAllAcrossPages(false);
  }, [selectedRowsLength]);

  /**
   * This function checks to see if the current page is missing from selectedRowsByPage
   * and if missing, adds that page
   */
  const maybeAddSelectedRowsToStateMap = useCallback(
    (newPage: number) => {
      if (!gridApi) return;
      if (
        hasClickedSelectAllAcrossPages &&
        (!selectedRowsByPage[newPage] ||
          Object.keys(selectedRowsByPage[newPage]).length < gridApi.paginationGetPageSize())
      ) {
        selectAllOnPage(newPage);
      }
    },
    [gridApi, hasClickedSelectAllAcrossPages, selectAllOnPage, selectedRowsByPage]
  );
  /**
   * If the user has clicked 'selectAllAcrossPages'--which doesn't actually
   * add all rows to selectedRowsByPage--and navigates between pages,
   * they expect to see all the rows on the page selected, so
   * this function checks to see if the current page is missing from selectedRowsByPage
   * and then adds that page
   */
  useEffect(() => {
    if (!gridApi) return;

    const onPageChange = () => {
      const newPage = gridApi.paginationGetCurrentPage();
      setCurrentPage(newPage);

      maybeAddSelectedRowsToStateMap(newPage);
    };
    gridApi.addEventListener('paginationChanged', onPageChange);

    return () => gridApi.removeEventListener('paginationChanged', onPageChange);
  }, [gridApi, maybeAddSelectedRowsToStateMap]);

  useEffect(() => {
    maybeAddSelectedRowsToStateMap(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClickedSelectAllAcrossPages]);

  /**
   * Deselect rows whenever table data reloads
   */

  // useDynamicTableDataCycle({
  //   onDataServerReload: deselectAllAcrossPages,
  // });

  const context: DynamicTableSelectionContextValue = useMemo(
    () => ({
      selectRow,
      deselectRow,
      selectedRows,
      selectedRowIds,
      selectionCount,
      hasSelectedAllOnPage,
      hasSelectedAllOnCurrentPage,
      allRowsAcrossPagesAreSelectedManually,
      hasClickedSelectAllAcrossPages,
      selectAllAcrossPages,
      deselectAllAcrossPages,
      deselectAllOnPage,
      selectAllOnPage,
    }),
    [
      allRowsAcrossPagesAreSelectedManually,
      deselectAllAcrossPages,
      deselectAllOnPage,
      deselectRow,
      hasClickedSelectAllAcrossPages,
      hasSelectedAllOnCurrentPage,
      hasSelectedAllOnPage,
      selectAllAcrossPages,
      selectAllOnPage,
      selectRow,
      selectedRowIds,
      selectedRows,
      selectionCount,
    ]
  );

  return <DynamicTableSelectionContext.Provider value={context}>{children}</DynamicTableSelectionContext.Provider>;
};
