import styled, { css, DefaultTheme } from 'styled-components';

import { PrimaryButton } from '@components/common/Button';
import { StyledHivesRatioWrapper } from '@components/yard/YardMarkerPin/styles';
import { Animation } from '@helpers/Animation';

export const StyledMapToolbar = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.getZIndexOf('siteHeader')};
    transition: ${theme.animations.transitionFast('top')};
  `
);

export const StyledMapToolbarContent = styled.div<{ height: number }>(
  ({ theme, height }) => `
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: ${height}px;
  transition: ${theme.animations.transitionFast('height')};
  background-color: ${theme.primitives.colors.white};
`
);

export const StyledMapToolbarWrapper = styled.div<{ top: number }>(
  ({ theme, top }) => `
  position: absolute;
  top: ${top}px;
  bottom: 0;
  right: 0;
  left: 0;
  transition: ${theme.animations.transitionFast('top')};
  `
);

export const StyledSearchBox = styled.div<{ disabled?: boolean }>(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing._025} ${theme.spacing._050};
  color: ${theme.primitives.colors.grey08};
  
  font-family: ${theme.font.family};
  font-size: 14px;
  
  &[disabled] {
    color: ${theme.primitives.colors.grey04};
  } 
  
  input {
    flex: 1;
    border: none;
    outline: none;
    height: 100%;
    padding: 0 94px 0 42px;
    border-radius: ${theme.shape.paperRadius01}px;
    font-family: inherit;
    color: inherit;
    
    &:focus-visible {
      box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02};
    }
  }
  
  svg {
    position: absolute;
    left: ${theme.spacing._100};
  }
  
  button {
    position: absolute;
    right: 0;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${theme.primitives.colors.grey06};
    text-transform: none;
  }
`
);

export const StyledSearchBoxClear = styled(PrimaryButton)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

export const StyledPolygonChildrenWrapper = styled.div`
  pointer-events: none;
  position: relative;
  width: 0;
  height: 0;
`;

export const StyledPolygonChildren = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  height: max-content;
  transform: translate(-50%, -50%);
  transition: ${({ theme }) => theme.animations.transitionMedium('opacity')};
`;

export const StyledMarkerPinArrow = styled.svg(
  () => `
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -11px;
`
);

export const markerNormalStyle = (theme: DefaultTheme) => `
  background-color: ${theme.primitives.colors.white};
  color: ${theme.primitives.colors.grey08};
  
  ${StyledMarkerPinArrow} {
    path { fill: ${theme.primitives.colors.white}; }
  }
`;

export const markerHoverStyle = (theme: DefaultTheme) => `
  background-color: ${theme.primitives.colors.grey07};
  color: ${theme.primitives.colors.white};
 
  ${StyledHivesRatioWrapper} {
    background-color: ${theme.primitives.colors.grey06};
  }
  
  ${StyledMarkerPinArrow} {
    path { fill: ${theme.primitives.colors.grey07}; }
  }
`;

export const markerHighlightedStyle = (theme: DefaultTheme) => `
  background-color: ${theme.primitives.colors.grey08};
  color: ${theme.primitives.colors.white};
 
  ${StyledHivesRatioWrapper} {
    background-color: ${theme.primitives.colors.grey07};
  }
  
  ${StyledMarkerPinArrow} {
    path { fill: ${theme.primitives.colors.grey08}; }
  }
`;

export const StyledMarkerPin = styled.button<{
  $animateToAppear?: boolean;
  $active?: boolean;
  $clickable?: boolean;
  $disabled?: boolean;
}>(
  ({ theme, $animateToAppear, $active, $clickable, $disabled }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 4px;
  white-space: nowrap;
  outline: none;
  padding-left: 4px;
  transform: scale(1.0) translateY(calc(-50% - 10px));
  border: ${theme.primitives.colors.grey05} 1px solid;
  pointer-events: ${!$clickable || $disabled ? 'none' : 'all'};
  transition: ${theme.animations.transitionFast('background', 'color')};
  path { transition: ${theme.animations.transitionFast('fill')}; }
  
  ${$active ? markerHighlightedStyle(theme) : markerNormalStyle(theme)}
  &:hover {
    ${$clickable ? markerHoverStyle(theme) : ''}
  }
  
  ${Animation.createElasticCSSKeyframes(
    'marker-pin-appearing',
    (progress) => `transform: scale(${progress.toFixed(4)}) translateY(calc(-50% - 10px));`
  )}
  animation-name: ${$animateToAppear ? 'marker-pin-appearing' : 'none'};
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: 1600ms;
  
  &::after {
    content: '';
    position: absolute;
    height: calc(100% + 14px);
    width: calc(100% + 8px);
    left: -4px;
    bottom: -10px;
    background-color: transparent;
  }
`
);
