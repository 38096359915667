import styled, { css } from 'styled-components';

import { Check } from '@components/common/Icon';
import { from } from '@style/mediaQueries';

export const CheckboxWrapper = styled.div(
  () => css`
    position: relative;
    cursor: pointer;
    color: transparent;
    width: 24px;
    height: 24px;

    ${from('tablet')} {
      width: 18px;
      height: 18px;
    }
  `
);

export const CheckboxInput = styled.input(
  ({ theme }) => css`
    appearance: none;
    cursor: pointer;
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: ${theme.shape.paperRadius01}px;
    border: 1px solid ${theme.colors.borderSecondary};
    background-color: white;
    transition: ${theme.animations.transitionFast('border')};

    z-index: ${theme.getZIndexOf('content')};

    :checked {
      border-color: ${theme.primitives.colors.grey08};
      background-color: ${theme.primitives.colors.grey08};
    }

    :checked::after {
      border-bottom: 2px solid ${theme.primitives.colors.white};
      border-left: 2px solid ${theme.primitives.colors.white};
    }

    :disabled {
      cursor: default;
      background-color: transparent;
      border-color: ${theme.colors.borderDisabled};
    }

    :disabled:checked {
      background-color: ${theme.colors.surfaceDisabled};
    }

    :focus-visible {
      outline: none;
      border-color: ${theme.colors.borderFocus};
    }
  `
);

export const CheckboxHover = styled.div(
  ({ theme }) => `
    position: absolute;
    inset: -${theme.spacing._025};
    border-radius: ${theme.borderRadius._050};
    transition: ${theme.animations.transitionFast('transform')};
    pointer-events: none;
    z-index: ${theme.getZIndexOf('base')};
    transform: scale(0);

    ${CheckboxWrapper}:hover & {
      background-color: ${theme.primitives.colors.grey03};
      transform: scale(1.0);
    }

    ${CheckboxInput}:focus-visible {
      background-color: initial;
      transform: scale(0);
    }
`
);

export const StyledCheck = styled(Check)(
  ({ theme }) => css`
    position: absolute;
    top: 1px;
    right: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);

    z-index: ${theme.getZIndexOf('content')};
    color: transparent;
    pointer-events: none;

    ${CheckboxInput}:checked ~ & {
      color: white;
    }
    ${CheckboxInput}:checked:disabled ~ & {
      color: ${theme.colors.contentDisabled};
    }
  `
);

export const CheckboxHiddenLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
