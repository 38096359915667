import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';
import { YardVisitCardProps } from '@components/common/YardVisitCard/types';

export const StyledVisitCard = styled(Box)<{ preset: YardVisitCardProps['preset'] }>(
  ({ theme, preset }) => css`
    display: grid;
    border: 1px solid;

    grid-template-columns: min-content auto max-content;

    ${preset === 'timeline' &&
    css`
      border-radius: ${theme.shape.paperRadius02}px;
      background-color: ${theme.primitives.colors.white};
      color: ${theme.primitives.colors.grey08};
      padding: ${theme.spacing._050};

      ${StyledVisitCardRightText} {
        border-left: none;
      }

      &,
      * {
        border-color: ${theme.primitives.colors.grey04};
      }
    `}

    ${preset === 'tooltip' &&
    css`
      border-radius: ${theme.shape.paperRadius01}px;
      background-color: ${theme.primitives.colors.grey08};
      color: ${theme.primitives.colors.white};
      max-width: 300px;

      &,
      * {
        border-color: ${theme.primitives.colors.grey06};
      }
    `}

    & > *:nth-child(n + 4) {
      border-top: 1px solid;
      border-color: inherit;
    }
  `
);

export const StyledVisitCardIconWrapper = styled(Box).attrs({
  paddingBottom_050: true,
  paddingLeft_050: true,
})<Pick<YardVisitCardProps, 'preset'>>(
  ({ theme, preset }) => css`
    grid-column: 1/1;
    padding: ${theme.spacing._050} 0 ${theme.spacing._050} ${theme.spacing._050};

    ${preset === 'timeline' &&
    css`
      padding-top: 13px;
    `}
  `
);

export const StyledVisitCardText = styled(Text)<
  Pick<YardVisitCardProps, 'preset'> & Omit<TextProps, 'typography'> & { typography?: string }
>`
  b,
  strong {
    font-weight: 600;
  }
`;

export const StyledVisitCardLeftText = styled(StyledVisitCardText).attrs((props: YardVisitCardProps) => ({
  htmlTag: 'span',
  typography: props.preset === 'timeline' ? 'Heading3' : 'CaptionSmall',
}))(
  ({ theme }) => css`
    display: inline-block;
    grid-column: 2/2;
    padding: ${theme.spacing._050};
  `
);

export const StyledVisitCardRightText = styled(StyledVisitCardText).attrs((props: YardVisitCardProps) => ({
  htmlTag: 'span',
  typography: props.preset === 'timeline' ? 'SmallParagraph' : 'CaptionSmall',
}))(
  ({ theme }) => css`
    display: flex;
    grid-column: 3/3;
    min-width: 36px;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    border-left: 1px solid;
    border-color: inherit;
    padding: ${theme.spacing._050};
  `
);

export const StyledVisitCardRightTextDashed = styled.span<{ $isDashed?: boolean }>(
  ({ theme, $isDashed }) => css`
    border-bottom: ${$isDashed ? theme.primitives.colors.grey08 : 'transparent'} 1px dashed;
    cursor: default;
  `
);

export const StyledTooltipBox = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.paperRadius01}px;
    border: ${theme.primitives.colors.grey07} 1px solid;
    padding: 6px 8px;
  `
);

export const StyledNotesWrapper = styled(Box)<Pick<YardVisitCardProps, 'preset'>>(
  ({ preset }) => css`
    grid-column: 1/3;
    align-items: ${preset === 'tooltip' ? 'stretch' : 'flex-start'};
  `
);

export const StyledEmptyMessageWrapper = styled(Box)(
  () => css`
    grid-column: 1/3;
  `
);
