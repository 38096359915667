import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { StyledAuthActions, StyledFormWrapper } from '@components/auth/AuthForms/styles';
import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputText } from '@components/form/inputs/InputText';
import APP from '@config/constants';
import { useDispatch } from '@helpers/Thunk/hooks';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { makeForgottenPasswordRequestThunk } from '@redux/deprecated/actions';

export const ForgotPasswordForm: React.VFC<{ onGoBackClick: () => void }> = ({ onGoBackClick }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);

  const form = useForm({
    defaultValues: useFormDefaultValues({ email: '' }),
    resolver: useFormSchemaResolver((schema) => ({ email: schema.string().email().required() })).resolver,
  });

  const attemptToSendEmail = useCallback(async () => {
    const responseError = await dispatch(makeForgottenPasswordRequestThunk(form.getValues()) as any);
    if (responseError) {
      const errorMessage = (responseError.detail?.email ?? [])[0] ?? t('snack_non_field_errors');
      setError(errorMessage);
    } else {
      history.replace(
        URLUtil.buildURL(APP.routes.passwordForgottenSuccess, { email: form.getValues('email') as string })
      );
    }
  }, [dispatch, form, history, t]);

  const goBackButton = (
    <Button withLeadingIcon suppressPadding tertiary onClick={onGoBackClick}>
      <ArrowLeft />
      {t('go_back')}
    </Button>
  );

  return (
    <>
      <StyledFormWrapper justifyContent={'center'}>
        <ResponsiveRender until={'mobile'}>
          <Box marginBottom_200 marginTop_300>
            {goBackButton}
          </Box>
        </ResponsiveRender>

        <Form form={form} onValidSubmit={attemptToSendEmail} gap_150 column>
          <ContentChangeTransition content={error}>
            {(error) => (
              <AlertCard marginBottom_150 error>
                {error}
              </AlertCard>
            )}
          </ContentChangeTransition>

          <Box gap_050 column>
            <Text typography={'Heading1'} weight={'700'}>
              {t('forgot_password_title')}
            </Text>
            <Text typography={'SmallParagraph'} dangerouslySetInnerHTML={{ __html: t('forgot_password_message') }} />
          </Box>
          <InputText name={'email'} label={t('email')} placeholder={t('login_email_placeholder')} />

          <StyledAuthActions gap_150>
            <Button primary type={'submit'}>
              {t('send')}
            </Button>
            <Button tertiary onClick={onGoBackClick}>
              {t('cancel')}
            </Button>
          </StyledAuthActions>
        </Form>
      </StyledFormWrapper>

      <ResponsiveRender from={'tablet'}>
        <Box alignItems={'flex-end'}>{goBackButton}</Box>
      </ResponsiveRender>
    </>
  );
};
