import React from 'react';

import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { LoadingSkeletonProps } from '@components/common/LoadingSkeleton/types';

export const MetricCardLoading: React.VFC<LoadingSkeletonProps> = ({ ...props }) => {
  return (
    <LoadingSkeleton column stretch center gap_050 {...props}>
      <LoadingSkeletonRectangle height={28} width={'25%'} backgroundColor={'grey02'} />
      <LoadingSkeletonRectangle height={16} width={'60%'} backgroundColor={'grey02'} />
    </LoadingSkeleton>
  );
};
