import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { from } from '@style/mediaQueries';

export const TabBar = styled(Box)<{ $isFixed?: boolean; $isTabBarVisible: boolean }>(
  ({ theme, $isFixed, $isTabBarVisible }) => css`
    position: relative;
    background-color: ${theme.primitives.colors.white};
    z-index: ${theme.getZIndexOf('tabs')};
    height: ${theme.layout.tabBarHeight}px;
    transition: ${theme.animations.transitionFast('transform', 'height')};
    pointer-events: ${$isTabBarVisible ? 'auto' : 'none'};
    border-bottom: ${theme.primitives.colors.grey03} 1px solid;

    ${!$isTabBarVisible &&
    css`
      height: 0;
      overflow: hidden;
    `}

    ${$isFixed &&
    css`
      position: fixed;
      top: ${theme.navigation.headerHeightMobile}px;
      transform: translateY(${$isTabBarVisible ? 0 : -theme.navigation.tabBarHeightMobile}px);
      padding-left: ${theme.spacing._150};
    `};

    left: 0;
    right: 0;

    ${from('desktopSM')} {
      ${$isFixed &&
      css`
        top: ${theme.navigation.headerHeight}px;
        transform: translateY(${$isTabBarVisible ? 0 : -theme.navigation.tabBarHeight}px);
      `};
    }
  `
);

export const TabBarButton = styled.button(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding: 0 ${theme.spacing._100};

    &:first-child {
      padding: 0 ${theme.spacing._100} 0 ${theme.spacing._150};
    }

    &:hover {
      opacity: 0.6;
    }
  `
);

export const TabBarButtonInner = styled.span<{ $isActive?: boolean }>(
  ({ theme, $isActive }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: transparent 2px solid;
    border-bottom: transparent 2px solid;
    color: ${theme.primitives.colors.grey06};

    ${$isActive &&
    css`
      color: ${theme.primitives.colors.grey08};
      border-bottom: ${$isActive ? theme.primitives.colors.orange01 : theme.primitives.colors.white} 2px solid;
    `}
  `
);

export const TabView = styled(Box)<
  {
    $isActive?: boolean;
    $isFixed?: boolean;
    $suppressScrollY?: boolean;
    $isTabBarVisible: boolean;
    $height: number;
  } & BoxProps<'div'>
>(
  ({ theme, $isActive, $isFixed, $suppressScrollY, $isTabBarVisible, $height }) => css`
    position: relative;
    display: ${$isActive ? 'flex' : 'none'};
    transition: ${theme.animations.transitionFast('margin-top', 'min-height')};

    width: 100%;
    height: ${$height}px;

    ${$isFixed &&
    css`
      margin-top: ${$isTabBarVisible ? theme.navigation.tabBarHeightMobile : 0}px;

      ${from('desktopSM')} {
        margin-top: ${$isTabBarVisible ? theme.navigation.tabBarHeight : 0}px;
      }
    `}

    ${!$suppressScrollY &&
    css`
      overflow: hidden auto;
    `}
  `
);
