import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Text } from '@components/common/Text';
import { OperationIcon } from '@components/operation/OperationIcon';
import { OperationSelector } from '@components/operation/OperationSelector';
import { from } from '@style/mediaQueries';

interface ExpandedProps {
  $expanded: boolean;
}

const NavExpandedCollapsedDesktopWidths = css<ExpandedProps>(
  ({ theme, $expanded }) => css`
    ${from('desktopSM')} {
      width: ${$expanded ? theme.layout.sideNavExpandedWidth : theme.layout.sideNavCollapsedWidth};
    }
  `
);

export const StyledNavWrapper = styled.div<ExpandedProps>(
  ({ theme, $expanded }) => css`
    transition: ${theme.animations.transitionMedium('width')};
    height: 100%;
    background-color: ${theme.primitives.colors.grey08};
    color: ${theme.primitives.colors.white};

    ${NavExpandedCollapsedDesktopWidths}
  `
);

export const StyledFixedContent = styled(Box)<ExpandedProps>(
  ({ theme, $expanded }) => css`
    width: ${$expanded ? theme.layout.sideNavExpandedWidthMobile : 0};
    z-index: ${theme.getZIndexOf('sidenav')};
    transition: ${theme.animations.transitionMedium('width')};
    height: 100%;
    background-color: ${theme.primitives.colors.grey08};
    color: ${theme.primitives.colors.white};
    position: fixed;
    top: 0;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    ${from('tablet')} {
      width: ${$expanded ? theme.layout.sideNavExpandedWidth : 0};
    }

    ${NavExpandedCollapsedDesktopWidths}
  `
);

export const StyledContentOverlay = styled.div<ExpandedProps>(
  ({ theme, $expanded }) =>
    css`
      display: ${$expanded ? 'block' : 'none'};
      height: 100vh;
      width: 100%;
      position: fixed;
      z-index: ${theme.getZIndexOf('sidenavOverlay')};
      background-color: ${theme.primitives.colors.overlayBackground};
    `
);

export const StyledLogoWrap = styled(NavRouterLink).attrs({ unstyled: true })<ExpandedProps>(
  ({ theme, $expanded }) => css`
    width: ${$expanded ? '200px' : 0};
    overflow: hidden;
    opacity: ${$expanded ? 1 : 0};
    transition: ${theme.animations.transitionMedium('width')};
  `
);

export const StyledCollapseButton = styled(Button)<ExpandedProps>(
  ({ theme, $expanded }) => css`
    transform: ${$expanded ? 'initial' : 'scaleX(-1)'}; // makes it flip
    padding: ${theme.spacing._050};

    &:hover {
      background-color: ${theme.primitives.colors.black};
    }
  `
);

export const StyledNavText = styled.span(
  ({ theme }) => css`
    transition: ${theme.animations.transitionMedium('opacity')};
    width: 150px;
  `
);

export const StyledSVGWrap = styled.span(
  ({ theme }) => css`
    transition: ${theme.animations.transitionMedium('margin', 'padding')};
  `
);

export const navItemStyles = css<ExpandedProps>(
  ({ theme, $expanded }) => css`
    display: flex;
    border-left: ${$expanded ? theme.spacing._025 : 0} solid transparent;
    padding: ${$expanded ? '8px 12px' : 0};
    align-items: center;
    line-height: 24px;
    font-size: 14px;

    transition: ${theme.animations.transitionMedium('border-left', 'padding')};

    &:hover {
      background-color: ${$expanded ? theme.primitives.colors.black : 'inherit'};
    }

    ${StyledSVGWrap} {
      margin: 0 ${$expanded ? 0 : theme.spacing._100};
      padding: ${$expanded
        ? `${theme.spacing._050} ${theme.spacing._050} ${theme.spacing._050} 0`
        : theme.spacing._050};
      border-radius: ${theme.spacing._025};
      display: flex;
    }

    &:hover ${StyledSVGWrap} {
      background-color: ${$expanded ? 'inherit' : theme.primitives.colors.black};
    }

    ${StyledNavText} {
      opacity: ${$expanded ? 1 : 0};
    }

    svg {
      height: ${$expanded ? theme.sizeIcon._100 : theme.sizeIcon._150};
      width: ${$expanded ? theme.sizeIcon._100 : theme.sizeIcon._150};
      transition: ${theme.animations.transitionMedium('width', 'height')};
    }
  `
);

export const StyledNavButton = styled(Button).attrs({ unstyled: true })(
  () => css`
    width: 100%;
    ${navItemStyles}
  `
);

export const StyledNavLink = styled(NavRouterLink).attrs({ unstyled: true })<ExpandedProps>(
  ({ theme, $expanded }) => css`
    ${navItemStyles}
    // React Router NavLink element automatically adds this 'active' class to the active nav
      // 'isActive' as an attribute or prop should be usable to, but doesn't seem to work
    &.active {
      border-left-color: ${theme.primitives.colors.orange01};
      background-color: ${$expanded ? theme.primitives.colors.black : 'inherit'};
    }

    &.active ${StyledSVGWrap} {
      color: ${$expanded ? 'inherit' : theme.primitives.colors.orange01};
    }
  `
);

export const StyledNavSeparator = styled.div(
  ({ theme }) => `
      border-top: 1px solid ${theme.primitives.colors.grey07};
      padding-bottom: ${theme.spacing._100} ;
      margin: ${theme.spacing._100} ${theme.spacing._100} 0;
    `
);

export const StyledFooterOperationIcon = styled(OperationIcon)<{ $hasTooltip: boolean }>(
  ({ theme, $hasTooltip }) => css`
    outline: transparent 0 solid;

    ${$hasTooltip &&
    css`
      transition: ${theme.animations.transitionMedium('outline')};
      outline: transparent ${theme.spacing._025} solid;
      &:hover {
        outline: ${theme.colors.borderPrimaryHover} ${theme.spacing._025} solid;
      }
    `}
  `
);

export const StyledFooterTextWrap = styled.div`
  flex: 1;
  max-width: 200px;
  max-height: 42px;
  padding-left: ${({ theme }) => theme.spacing._100};
  margin-right: auto;
  text-align: left;
`;

export const StyledFooterBox = styled(Button).attrs({ suppressPadding: true })(
  ({ theme }) => css`
    border: ${theme.colors.borderPrimaryHover} 1px solid;
    border-radius: ${theme.borderRadius._050};
    padding: ${theme.spacing._100};

    transition: ${theme.animations.transitionMedium('padding', 'border')};
  `
);

export const StyledFooterText = styled(Text)(
  ({}) => css`
    text-overflow: ellipsis;
    max-width: 128px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  `
);

export const StyledFooter = styled.footer<ExpandedProps>(
  ({ theme, $expanded }) => css`
    padding-top: ${theme.spacing._100};
    margin-top: auto;
    width: 100%;

    ${StyledFooterTextWrap},
    svg {
      transition: ${theme.animations.transitionMedium('opacity')};
      white-space: nowrap;
      opacity: 1;

      ${!$expanded &&
      css`
        opacity: 0;
      `}
    }

    ${StyledFooterBox} {
      ${!$expanded &&
      css`
        border: transparent 1px solid;
        padding: 0 0 0 ${theme.spacing._050};
      `}
    }
  `
);

export const StyledSupportChip = styled(Box).attrs({
  center: true,
  paddingHorizontal_025: true,
  marginLeft_050: true,
  paper03: true,
  color: 'contentPrimary',
  backgroundColor: 'surfaceAccent',
})(
  ({ theme }) => css`
    height: 16px;
    font-family: ${theme.font.family};
    font-size: 10px; /** Special font size for this case. */
    font-weight: 600;
    line-height: 10px;
  `
);

export const StyledSideNavMenu = styled(Box).attrs({ column: true, stretch: true })(
  ({ theme }) => css`
    width: 224px;
    background-color: ${theme.colors.surfaceSecondary};
    border-radius: ${theme.borderRadius._050};
    box-shadow: ${theme.shadows.boxShadow03};

    & > div:not(:last-child) {
      border-bottom: ${theme.colors.borderSecondary} 1px solid;
    }

    button:hover,
    a:hover {
      background-color: ${theme.colors.surfaceSecondaryHover};
    }

    p {
      flex: 1;
    }
  `
);

export const StyledSideNavOperationSelector = styled(OperationSelector)<{ $hasManyOperations?: boolean }>(
  ({ theme, $hasManyOperations }) => css`
    width: 238px;

    ${$hasManyOperations
      ? css`
          height: 280px;
        `
      : css`
          max-height: 280px;
        `}

    & > div {
      background-color: ${theme.colors.surfaceSecondary};
      border-radius: ${theme.borderRadius._050};
      box-shadow: ${theme.shadows.boxShadow03};
    }
  `
);
