import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Modal } from '@components/common/Modal';
import { ModalFooter } from '@components/common/ModalBase';
import { useTimelineByActivityRequestModalOpener } from '@components/timeline/TimelineByActivityRequest/hooks';
import { StyledTimelineModalInner } from '@components/timeline/TimelineByActivityRequest/styles';
import { TimelineByActivityRequest } from '@components/timeline/TimelineByActivityRequest/TimelineByActivityRequest';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType, AnalyticsPerformanceMetricEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';
import {
  makeTimelineDisposeDetailedActivityAction,
  makeTimelineFetchVisitActivityThunk,
} from '@redux/Timeline/actions';
import { useDetailedActivitySet } from '@redux/Timeline/hooks';
import { ActivityFilters } from '@redux/Timeline/types';

export const TimelineByActivityRequestModal: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const dateUtil = useDateUtil();
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<ActivityFilters | null>(null);

  const { openedTimelineByActivityRequestModal, closeTimelineByActivityRequestModal } =
    useTimelineByActivityRequestModalOpener();

  const { isFetching: isFetchingPractices } = usePractices();

  const { isFetchingDetailedActivitySets, detailedActivitySet } = useDetailedActivitySet(filters);

  const isLoadingInitialItems = Boolean(isFetchingDetailedActivitySets && !detailedActivitySet?.activities.length);
  const isLoadingMoreItems = Boolean(isFetchingDetailedActivitySets && detailedActivitySet?.activities.length);

  const loadItems = useCallback(
    () => {
      if (filters) {
        try {
          dispatch(makeTimelineFetchVisitActivityThunk(filters));
        } catch (_) {
          closeTimelineByActivityRequestModal();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, dispatch, dateUtil]
  );

  useEffect(() => {
    if (openedTimelineByActivityRequestModal) {
      setIsOpen(true);
      setFilters(openedTimelineByActivityRequestModal);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openedTimelineByActivityRequestModal?.date,
    openedTimelineByActivityRequestModal?.yardId,
    openedTimelineByActivityRequestModal?.alertTypes,
  ]);

  useEffect(() => {
    if (isOpen) {
      Analytics.sendEvent({
        event: AnalyticsEventType.TIMELINE_DETAILS_MODAL_VIEW,
        eventData: Analytics.getPageData(),
      });
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_MODAL);
      return () => {
        Analytics.cancelPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_MODAL);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && detailedActivitySet?.totalCount) {
      Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_MODAL);
    }
  }, [detailedActivitySet?.totalCount, isOpen]);

  const closeModal = useCallback(() => {
    closeTimelineByActivityRequestModal();

    // Wait the modal to close before clearing it.
    setTimeout(() => {
      // Dispose all downloaded events in order to get fresh events next time
      // the modal is opened.
      filters && dispatch(makeTimelineDisposeDetailedActivityAction(filters));
    }, theme.animations.durationLong);
  }, [closeTimelineByActivityRequestModal, dispatch, filters, theme.animations.durationLong]);

  if (isFetchingPractices) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <StyledTimelineModalInner>
        <TimelineByActivityRequest
          items={detailedActivitySet?.activities ?? []}
          itemsTotalCount={detailedActivitySet?.totalCount ?? 0}
          onMoreItemsRequest={loadItems}
          isLoadingInitialItems={isLoadingInitialItems}
          isLoadingMoreItems={isLoadingMoreItems}
          suppressDayMarks
          footerMessage={t('timeline_bottom_message')}
          highlightedHiveId={filters?.hiveId}
        />
      </StyledTimelineModalInner>
      <ModalFooter>
        <Box fit justifyContent={'flex-end'}>
          <Button onClick={closeModal} primary>
            {t('close')}
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
