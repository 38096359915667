import styled from 'styled-components';

import { Box } from '../Box';

export const StyledLabel = styled.label<{ $isDisabled?: boolean }>(
  ({ theme, $isDisabled }) => `
      font-size: 0.75rem;
      color: ${$isDisabled ? theme.primitives.colors.grey06 : theme.primitives.colors.grey07};
      line-height: 1rem;
    `
);

export const StyledInput = styled.input(
  ({ theme }) => `
      border: none;
      outline: none;
      line-height: 22px;
      font-size: 1rem;
      color: ${theme.primitives.colors.grey08};
      flex: 1;
      min-width: 60px;
      font-weight: 600;
      font-family: inherit;
      background: initial;

      ::placeholder {
        color: ${theme.primitives.colors.grey06};
      }
    `
);

export const StyledUL = styled.ul`
  display: none;
`;

export const StyledName = styled.p`
  font-weight: 600;
  line-height: 24px;
`;

export const StyledWrapper = styled.div(
  ({ theme }) => `
      background-color: ${theme.primitives.colors.white};
      //fieldset adds 1px border all around, need to redo that, in the meantime, fix
      padding: ${10 - 1}px calc(${theme.spacing._100} - 1px);
    `
);

export const StyledDisabledOuterWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.primitives.colors.grey02};
    color: ${theme.primitives.colors.grey06};
    padding: ${10 - 1}px calc(${theme.spacing._100} - 1px);
    min-height: 64px;
    
    ${StyledLabel} {
      margin-bottom: ${theme.spacing._025};
      display: block;
    }
  
  `
);

export const StyledPillButton = styled.button(
  ({ theme }) => `
      background-color: ${theme.primitives.colors.white};
      outline: 1px solid ${theme.primitives.colors.grey05};
      padding:  ${theme.spacing._025} ${theme.spacing._050};
      border-radius: 1000px;
      white-space: nowrap;
      font-size: 12px;
      border: none;
      display: flex;
      align-items: center;

      &:hover, &:focus {
        outline-color: ${theme.primitives.colors.grey06};
      }
    `
);

export const StyledOuterWrapper = styled.div<{ hasSelectedItems: boolean }>(
  ({ theme, hasSelectedItems }) => `
      position: relative;
   
      ${StyledInput} {
        display: ${hasSelectedItems && 'none'};
      }
  
      :focus,
      :focus-within {
        ${StyledInput} {
          display: initial;
        }
  
        ${StyledUL} {
          display: contents;
        }
        ${StyledName} {
          display: none;
        }
      }
    `
);

export const StyledInputPillsWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    align-items: center;
    padding-top: ${theme.spacing._050};
    flex-wrap: wrap;
    gap: ${theme.spacing._050};
    `
);

export const StyledSpan = styled.span(
  ({ theme }) => `
     padding-right: ${theme.spacing._050};
     font-weight: 600;
    `
);

export const StyledDropdownWrapper = styled.div(
  ({ theme }) => `
  position: relative;
  z-index: ${theme.getZIndexOf('lowLevelIndex')};
  // to be uncommented when error handling is added to this component
  // border: 1px solid ${theme.primitives.colors.grey04};

  // &:focus-within {
  //   box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02}; 
  //   border-color: transparent;
  //   outline: none;

  // }
`
);

export const StyledInputWrapper = styled(Box)<{ isDisabled?: boolean }>(
  ({ theme, isDisabled }) => `
      background-color: ${isDisabled ? theme.primitives.colors.grey02 : theme.primitives.colors.white};
      padding: 10px ${theme.spacing._100};

      input {
        padding-top: ${theme.spacing._025};
      }
    `
);
