import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DEFAULT_MAP_STATE } from '@components/common/Map/constants';
import { MapContextProvider } from '@components/common/Map/context';
import { MapContextValue, MapInstance, MapState, MapType } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { useActiveLayersState } from '@components/yard/YardsMap/hooks';
import { YardsMapLayer } from '@components/yard/YardsMap/types';
import { YardsMapCard } from '@components/yard/YardsMapCard';
import { YardsMapControls } from '@components/yard/YardsMapControls';
import { YardsMapDetailsPane } from '@components/yard/YardsMapDetailsPane';
import { YardsMapLayers } from '@components/yard/YardsMapLayers';
import { YardsMapSurface } from '@components/yard/YardsMapSurface';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { ApiResponseError } from '@helpers/Api/types';
import { useQueryParamsItemSelection } from '@helpers/QueryParams/hooks';
import { URLUtil } from '@helpers/URL';
import { useGroupsFetcher } from '@hooks/useGroups';
import { setCenterGlobal, setZoomGlobal } from '@redux/deprecated/actions';
import { usePractices } from '@redux/Practices/hooks';
import { RootState } from '@redux/Root/types';
import { makeMapFetchBlocksThunk, makeMapFetchYardDetailThunk, makeMapFetchYardsThunk } from '@redux/Yards/actions';
import { makeFetchFiltersAvailabilitiesThunk } from '@redux/YardsFilters/actions';
import { useAppliedYardsFilters } from '@redux/YardsFilters/hooks';

import { YardsMapContext } from './context';
import { StyledMapWrapper } from './styles';

const SELECTED_YARD_URL_PARAM = 'yard';
const MAP_RECENTER_PADDING = 192;

export const YardsMap: React.VFC = () => {
  // Make sure groups and practices list are downloaded.
  usePractices();
  useGroupsFetcher();

  // True if the map state was never changed by the user,
  // so the map will fit to all yards.
  const shouldFitContent = useRef(false);

  // True after yards are loaded for the first time.
  const didLoadYards = useRef(false);

  const mapRef = useRef<MapContextValue | null>(null);
  const [map, setMap] = useState<MapInstance | null>(null);
  const isMapLoaded = map !== null;
  const lastReportedMapType = useRef<MapType | null>(null);

  const [activeLayers, toggleLayer] = useActiveLayersState();

  const isFetching = useSelector<RootState, boolean>(
    (store) => store.yardsReducer.map.isFetchingYards || store.yardsReducer.map.isFetchingBlocks
  );
  const isFetchingDetail = useSelector<RootState, boolean>((store) => store.yardsReducer.map.isFetchingYardDetail);
  const fetchError = useSelector<RootState, ApiResponseError | null>(
    (store) => store.yardsReducer.map.fetchYardsError || store.yardsReducer.map.fetchBlocksError
  );

  const blocks = useSelector<RootState, Array<BeeBlock>>((store) => store.yardsReducer.map.blocks);
  const yards = useSelector<RootState, Array<BeeYardOnMapInfo>>((store) =>
    isMapLoaded ? store.yardsReducer.map.yards : []
  );

  const appliedYardsFilters = useAppliedYardsFilters();

  const [selectedYard, setSelectedYard] = useQueryParamsItemSelection({
    paramName: SELECTED_YARD_URL_PARAM,
    availableItems: yards,
    autoClearSelection: !isFetching && didLoadYards.current,
    onSelectionChange: ({ item, origin }) => {
      if (item && origin === 'navigation') {
        recenterMapToYard(item);
      }
    },
  });

  const selectedYardDetailFromRedux = useSelector<RootState, BeeYardOnMapDetailedInfo | null>(
    (store) => store.yardsReducer.map.yardDetail
  );
  const selectedYardDetail = useMemo(
    () =>
      !isFetchingDetail && selectedYardDetailFromRedux?.id === selectedYard?.id ? selectedYardDetailFromRedux : null,
    [isFetchingDetail, selectedYard?.id, selectedYardDetailFromRedux]
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const retrieveYards = useCallback(async () => {
    await dispatch(makeMapFetchYardsThunk());
    didLoadYards.current = true;
  }, [dispatch]);

  const retrieveSelectedYard = useCallback(
    async (dispatchStatus = { cache: false, canceled: false }) => {
      await dispatch(makeMapFetchYardDetailThunk(selectedYard?.id ?? null, dispatchStatus));
    },
    [dispatch, selectedYard?.id]
  );

  const retrieveBlocks = useCallback(() => {
    dispatch(makeMapFetchBlocksThunk());
  }, [dispatch]);

  const recenterMapToYard = useCallback(
    (yard: BeeYardOnMapInfo) => {
      const coordinates = yard.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));
      map?.fitCoordinates(coordinates, MAP_RECENTER_PADDING);
    },
    [map]
  );

  const recenterMap = useCallback(() => {
    if (selectedYard) {
      recenterMapToYard(selectedYard);
    } else {
      const coordinates = yards.map(
        ({
          yardCenter: {
            coordinates: [lng, lat],
          },
        }) => ({ lat, lng })
      );
      map?.fitCoordinates(coordinates, MAP_RECENTER_PADDING);
    }
  }, [selectedYard, recenterMapToYard, yards, map]);

  const goToYardManagement = useCallback(() => {
    // Sets the current map state to the local storage so
    // the old yard management component starts in the right
    // map position.
    if (mapRef.current) {
      const { zoom, center } = mapRef.current.state;
      dispatch(setCenterGlobal(center.lat, center.lng));
      dispatch(setZoomGlobal(zoom));
    }

    history.push(URLUtil.buildPagePath(APP.routes.yardsMapManagement));
  }, [dispatch, history]);

  const onMapStateChange = useCallback((state: MapState) => {
    if (MapUtil.areMapStatesEqual(state, DEFAULT_MAP_STATE, true)) {
      shouldFitContent.current = true;
    }

    if (state.mapType !== lastReportedMapType.current && lastReportedMapType.current !== null) {
      Analytics.sendEvent({ event: AnalyticsEventType.YARDS_MAP_TYPE_CHANGE, eventData: { map_type: state.mapType } });
    }

    lastReportedMapType.current = state.mapType;
  }, []);

  useEffect(() => {
    isMapLoaded && retrieveYards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapLoaded, appliedYardsFilters]);

  useEffect(() => {
    if (isMapLoaded && activeLayers[YardsMapLayer.POLLINATION_BLOCKS] && !blocks.length) {
      retrieveBlocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayers[YardsMapLayer.POLLINATION_BLOCKS], isMapLoaded]);

  useEffect(() => {
    if (shouldFitContent.current && yards.length && !selectedYard) {
      shouldFitContent.current = false;
      recenterMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yards]);

  useEffect(() => {
    const dispatchStatus = { cache: true, canceled: false };
    setTimeout(() => retrieveSelectedYard(dispatchStatus));
    return () => {
      dispatchStatus.canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYard?.id]);

  useEffect(() => {
    // Make sure the filters are available, so the yards card filtering works.
    dispatch(makeFetchFiltersAvailabilitiesThunk());
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({
      isFetching,
      isFetchingDetail,
      fetchError,
      yards,
      selectedYardDetail,
      selectedYard,
      blocks,
      activeLayers,
      setSelectedYard,
      recenterMap,
      toggleLayer,
      goToYardManagement,
      retrieveYards,
      retrieveSelectedYard,
    }),
    [
      activeLayers,
      blocks,
      fetchError,
      goToYardManagement,
      isFetching,
      isFetchingDetail,
      recenterMap,
      retrieveSelectedYard,
      retrieveYards,
      selectedYard,
      selectedYardDetail,
      setSelectedYard,
      toggleLayer,
      yards,
    ]
  );

  return (
    <>
      <YardsMapContext.Provider value={contextValue}>
        <MapContextProvider ref={mapRef} onInstance={setMap} useStorageState onStateChange={onMapStateChange}>
          <StyledMapWrapper>
            <YardsMapDetailsPane>
              <YardsMapSurface>
                <YardsMapControls />
                <YardsMapLayers />
                <YardsMapCard />
              </YardsMapSurface>
            </YardsMapDetailsPane>
          </StyledMapWrapper>
        </MapContextProvider>
      </YardsMapContext.Provider>
    </>
  );
};
