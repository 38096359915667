import styled from 'styled-components';

import { Text } from '@components/common/Text';

export const StyledHiveCount = styled(Text)<{ $warned: boolean }>(
  ({ theme, $warned }) => `
  vertical-align: middle;
  padding: ${$warned ? theme.spacing._025 : 0};
  border-radius: ${theme.shape.paperRadius01}px;
  background-color: ${$warned ? theme.primitives.colors.yellow01 : 'transparent'};
`
);
