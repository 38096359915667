import React, { useMemo } from 'react';

import { MarkerPin } from '@components/common/Map/components/MarkerPin';
import { MarkerPinProps } from '@components/common/Map/types';
import { Text } from '@components/common/Text';
import { YardName } from '@components/yard/YardName';

import { StyledHivesRatioWrapper } from './styles';

export interface YardMarkerPinProps extends MarkerPinProps {
  yard: { name: string; nbHives?: number; targetHiveNumber?: number | null; contractName?: string };
  active?: boolean;
  disabled?: boolean;
  disableShadow?: boolean;
  disableTruncation?: boolean;
}

export const YardMarkerPin: React.FC<YardMarkerPinProps> = ({ yard, children, disableTruncation, ...props }) => {
  const hivesRatio = useMemo(() => {
    if (yard.targetHiveNumber) {
      return `${yard.nbHives}/${yard.targetHiveNumber}`;
    }
    if (yard.nbHives) {
      return String(yard.nbHives);
    }
    return null;
  }, [yard.nbHives, yard.targetHiveNumber]);

  return (
    <MarkerPin {...props}>
      <YardName
        name={yard.name}
        contractName={yard.contractName}
        typography={'CaptionSmall'}
        weight={'600'}
        maxWidth={128}
        suppressTruncation={disableTruncation}
      />

      {hivesRatio && (
        <StyledHivesRatioWrapper>
          <Text typography={'CaptionSmall'} weight={'600'} data-pdf-unselectable>
            {hivesRatio}
          </Text>
        </StyledHivesRatioWrapper>
      )}

      {children}
    </MarkerPin>
  );
};
