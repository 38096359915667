import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledMapTopRightControls = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing._100};
  right: ${theme.spacing._100};
`
);
