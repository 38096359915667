import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import config from '@config/config';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { DateUtil } from '@helpers/Date';
import { Environment } from '@helpers/Environment';

export const AnalyticsLoader: React.FC = ({ children }) => {
  const operation = useSelector((state) => state.operationReducer.operation);
  const user = useSelector((state) => state.authReducer.user);

  const lastLoadedUser = useRef<{ userId: number | null; timestamp: number }>({ userId: null, timestamp: 0 });

  useEffect(() => {
    Analytics.initialize();
  }, []);

  useEffect(() => {
    const didUserChange = user && user.id != lastLoadedUser.current.userId;
    const hasPassedEnoughTime =
      DateUtil.getElapsedTimeInMilliseconds(lastLoadedUser.current.timestamp) >
      DateUtil.getTimeDeltaInMilliseconds({ seconds: 10 });

    // These checks will avoid this event to be fired
    // multiple times within the same user session.
    if (didUserChange && hasPassedEnoughTime) {
      lastLoadedUser.current = { userId: user.id, timestamp: +new Date() };

      Analytics.sendEvent({
        event: AnalyticsEventType.SESSION_START,
        eventData: {
          user_id: user.id,
          user_language: user.userPreferences.languageCode,
          user_type: user.isStaff ? 'staff' : 'customer',
          app_version: config.VERSION,
          app_environment: Environment.getName(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (user && operation) {
      Analytics.sendEvent({
        event: AnalyticsEventType.SESSION_START_WITH_OPERATION,
        eventData: {
          user_id: user.id,
          user_language: user.userPreferences.languageCode,
          user_type: user.isStaff ? 'staff' : 'customer',
          operation_id: operation.id,
          operation_name: operation.name,
          app_version: config.VERSION,
          app_environment: Environment.getName(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, operation?.id]);

  return <>{children}</>;
};
