import React, { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { CellClickedEvent } from 'ag-grid-community';
import { capitalize } from 'lodash';
import moment from 'moment/moment';

import { Box } from '@components/common/Box';
import { DynamicTable } from '@components/common/DynamicTable';
import { DefaultCell, DefaultCellText } from '@components/common/DynamicTable/DefaultCell';
import {
  useDynamicTableDataCycle,
  useDynamicTableDataProvider,
  useDynamicTableEmptyState,
} from '@components/common/DynamicTable/hooks';
import { CellRenderParams, StaticCellRenderParams } from '@components/common/DynamicTable/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import {
  CONTRACT_ARCHIVED_COLUMNS,
  CONTRACT_ARCHIVED_TABLE_CONFIG,
  CONTRACT_COLUMNS,
  CONTRACT_HEADER_HEIGHT,
  CONTRACT_ROW_HEIGHT,
  CONTRACT_TABLE_CONFIG,
} from '@components/pollination/PolliContractsList/constants';
import { useContractColumnNameGetter } from '@components/pollination/PolliContractsList/hooks';
import APP from '@config/constants';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { moneyFormatter, numberFormatter } from '@helpers/StringTransformers';
import { URLUtil } from '@helpers/URL';
import { useCropTypes } from '@hooks/useCropTypes';
import { useTranslation } from '@hooks/useTranslation';

import { ActionsMenu } from './ActionsMenu';
import { StyledHiveCount } from './styles';

export const PolliContractsList: React.VFC<{ showArchivedContracts?: boolean }> = ({ showArchivedContracts }) => {
  const t = useTranslation();
  const history = useHistory();
  const [didFirstLoad, setDidFirstLoad] = useState(false);
  const getColumnDisplayName = useContractColumnNameGetter();
  const { getCropTypesVisualString } = useCropTypes();

  const { isListEmpty } = useDynamicTableEmptyState();
  const enableTableInteraction = didFirstLoad && !isListEmpty;

  const columnDefs = showArchivedContracts ? CONTRACT_ARCHIVED_COLUMNS : CONTRACT_COLUMNS;
  const config = showArchivedContracts ? CONTRACT_ARCHIVED_TABLE_CONFIG : CONTRACT_TABLE_CONFIG;

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      setDidFirstLoad(true);
    }, []),
  });

  useDynamicTableDataProvider({
    config,
    endpoint: 'contractDynamicTable',
    apiParams: {
      columns: columnDefs.map(({ field }) => CaseAdapter.toSnakeCase(field)),
      archived: Boolean(showArchivedContracts),
    },
  });

  const navigateToContract = useCallback(
    (event: CellClickedEvent) => {
      history.push(
        URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, { pathParams: { uid: event.data.meta.id } })
      );
    },
    [history]
  );

  const renderDynamicCell = useCallback(
    ({ field, data, meta }: CellRenderParams) => {
      const noContent = (
        <Text typography="SmallParagraph" color="grey06">
          {' - '}
        </Text>
      );

      switch (field) {
        case 'name':
          return <DefaultCell data={data} weight={'600'} />;
        case 'grower':
          return <DefaultCell data={data} />;
        case 'contact':
          return (
            <Box column gap_025>
              <DefaultCellText>{capitalize(data.name)}</DefaultCellText>
              <DefaultCellText color={'grey06'}>
                <NumberFormat
                  value={data.phoneNumber}
                  displayType={'text'}
                  format={formatPhoneNumber(data.phoneNumber)}
                />
              </DefaultCellText>
            </Box>
          );
        case 'hivesRequired':
        case 'hivesOnMap':
        case 'hivesOnSite':
          const val = data ?? 0;
          const hivesRequired = meta.hivesRequired;
          const warned = ['hivesOnMap', 'hivesOnSite'].includes(field) && val !== 0 && val !== hivesRequired;
          const hasUnderFlow = val < hivesRequired;
          const onMapHint = hasUnderFlow
            ? 'pollination_hives_on_map_underflow_hint'
            : 'pollination_hives_on_map_overflow_hint';
          const onSiteHint = hasUnderFlow
            ? 'pollination_hives_on_site_underflow_hint'
            : 'pollination_hives_on_site_overflow_hint';
          const hint = field === 'hivesOnMap' ? onMapHint : onSiteHint;
          return (
            <StyledHiveCount $warned={warned} typography={'SmallParagraph'}>
              {val} {t('Hives')}
              {warned && (
                <Tooltip placement={'top-start'}>
                  <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t(hint) }} />
                </Tooltip>
              )}
            </StyledHiveCount>
          );
        case 'beesIn':
        case 'beesOut':
        case 'createdOn':
        case 'archivedAt':
          if (data) {
            return <DefaultCell data={moment(data).format('MMM DD, YYYY')} />;
          }
          return noContent;
        case 'cropTypes':
          const cropTypes = getCropTypesVisualString(data);
          return <DefaultCell data={cropTypes} />;
        case 'blocks':
          return data ? <DefaultCell data={maybePluralize(data, 'block', t).toLowerCase()} /> : noContent;
        case 'drops':
          return data ? <DefaultCell data={maybePluralize(data, 'drop', t).toLowerCase()} /> : noContent;
        case 'price':
          if (!data.costPerHive && !data.nbHives && !data.totalPrice) {
            return noContent;
          }
          return (
            <Box column stretch gap_025>
              {!!data.costPerHive || !!data.nbHives ? (
                <DefaultCellText color="grey06">
                  {data.costPerHive ? moneyFormatter(data.costPerHive) : '$ - '}
                  {data.nbHives ? ` x ${numberFormatter(data.nbHives)}` : t('per_hive')}
                </DefaultCellText>
              ) : (
                noContent
              )}

              <DefaultCellText>{data.totalPrice ? moneyFormatter(data.totalPrice) : '$ -'}</DefaultCellText>
            </Box>
          );
      }

      return noContent;
    },
    [getCropTypesVisualString, t]
  );

  const renderStaticCell = useCallback(({ field, gridParamData }: StaticCellRenderParams) => {
    const contractName = gridParamData.data.name.views.default.data;
    switch (field) {
      case 'menu':
        return <ActionsMenu contractId={gridParamData.meta.id} contractName={contractName} />;
    }
    return null;
  }, []);

  return (
    <DynamicTable
      enableTableInteraction={enableTableInteraction}
      config={config}
      headerHeight={CONTRACT_HEADER_HEIGHT}
      rowHeight={CONTRACT_ROW_HEIGHT}
      columnDefs={columnDefs}
      onCellClicked={navigateToContract}
      renderDynamicCell={renderDynamicCell}
      renderStaticCell={renderStaticCell}
      headerComponentParams={{ getColumnDisplayName }}
    />
  );
};
