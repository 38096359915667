import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateUtil } from '@helpers/Date/index';

export function useDateUtil() {
  const user = useSelector((state) => state.authReducer.user);
  return useMemo(() => {
    return new DateUtil({ timezone: user?.userPreferences.timezone });
  }, [user?.userPreferences.timezone]);
}
