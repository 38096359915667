import { useMemo } from 'react';
import { debounce } from 'lodash';

/**
 * Wraps the lodash debounce in a hook to make its usage
 * more portable.
 *
 * Example:
 *    modalVisibilityDebounce = useDebounce(1000);
 *    ...
 *
 *    modalVisibilityDebounce(() => showModal());
 *    modalVisibilityDebounce(() => hideModal()); // Hide is called too fast, so show won't be executed.
 * */
export function useDebounce(waitTime: number) {
  return useMemo(() => debounce((exec: () => void) => exec(), waitTime), [waitTime]);
}
