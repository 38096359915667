import React from 'react';

import { GridApiProvider } from '@components/common/AgGrid/provider';
import { Box } from '@components/common/Box';
import { DataStatusTabDot } from '@components/common/DataStatusTabDot';
import { Tab, Tabs } from '@components/common/Tabs';
import { TimelineByActivityDatePage } from '@components/timeline/TimelineByActivityDate';
import { YardsListSaveViewProvider } from '@components/yard/YardsList/context/YardsListSaveViewProvider';
import { YardsListTooltipsProvider } from '@components/yard/YardsList/context/YardsListTooltipsProvider';
import { YardsListPage } from '@components/yard/YardsList/YardsListPage';
import { YardsMapPage } from '@components/yard/YardsMap';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { useGlobalTimelineDataStatus } from '@redux/DataStatus/hooks';
import { WhiteboardHeader } from '@scenes/admin/Whiteboard/WhiteboardHeader';

export const Whiteboard: React.FC = () => {
  const t = useTranslation();

  return (
    <GridApiProvider>
      <YardsListTooltipsProvider>
        <YardsListSaveViewProvider>
          <Box block fullWidth>
            <WhiteboardHeader />

            <Tabs useURLPath>
              <Tab id={'yards-list'} title={t('yards_list')} path={APP.routes.whiteboardYardsList}>
                <YardsListPage />
              </Tab>

              <Tab id={'yards-map'} title={t('yards_map')} path={APP.routes.whiteboardYardsMap} suppressScrollY>
                <YardsMapPage />
              </Tab>

              <Tab
                id={'activities'}
                title={
                  <>
                    {t('activities')}
                    <DataStatusTabDot dataStatus={useGlobalTimelineDataStatus()} tabKey={'activities'} />
                  </>
                }
                path={APP.routes.whiteboardActivities}
                preLoad
              >
                <TimelineByActivityDatePage />
              </Tab>
            </Tabs>
          </Box>
        </YardsListSaveViewProvider>
      </YardsListTooltipsProvider>
    </GridApiProvider>
  );
};
