import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/common/CTA';
import { Edit } from '@components/common/Icon/presets/Edit';
import { Hide } from '@components/common/Icon/presets/Hide';
import { MoreVert } from '@components/common/Icon/presets/MoreVert';
import { Show } from '@components/common/Icon/presets/Show';
import { Menu } from '@components/common/Menu';
import { MenuItem } from '@components/common/Menu/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { StyledActionPillDot, StyledActionPillView } from '@components/operation/ActionPill/styles';
import { ActionPillProps } from '@components/operation/ActionPill/types';
import { useActionConfig } from '@config/Practices/hooks';
import { usePractices } from '@redux/Practices/hooks';

export const ActionPill: React.FC<ActionPillProps> = ({
  actionId,
  onEditClick,
  onEnableClick,
  onDisableClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { getAction, getActionName, getPracticeByAction } = usePractices();
  const action = getAction(actionId);
  const practice = getPracticeByAction(actionId);
  const config = useActionConfig(action);

  const menuItems = useMemo<Array<MenuItem>>(
    () => [
      { title: t('edit'), icon: Edit, onClick: () => action && onEditClick && onEditClick(action) },
      action?.isActive
        ? {
            title: t('disable'),
            icon: Hide,
            onClick: () => action && onDisableClick && onDisableClick(action),
          }
        : {
            title: t('enable'),
            icon: Show,
            onClick: () => action && onEnableClick && onEnableClick(action),
          },
    ],
    [action, onDisableClick, onEditClick, onEnableClick, t]
  );

  if (!action) {
    return null;
  }

  const isDefault = config.suppressMenu || practice?.fieldType === 'system';

  return (
    <StyledActionPillView alignItems={'center'} gap_050 {...props}>
      {!isDefault && <StyledActionPillDot backgroundColor={action.isActive ? 'contentPositive' : 'contentDisabled'} />}

      <Text typography={'SmallParagraph'}>{getActionName(action.id)}</Text>

      {isDefault ? (
        <Tooltip>
          <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t('tooltip_default_action') }} />
        </Tooltip>
      ) : (
        <>
          <Button suppressPadding id={`action-${action.id}-menu`} aria-label={t('more_options')}>
            <MoreVert />
          </Button>
          <Menu items={menuItems} placement={'bottom-end'} target={`action-${action.id}-menu`} />
        </>
      )}
    </StyledActionPillView>
  );
};
