/**
 * Temporary component to handle YardManagement.
 * */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from '@components/common/Loading';
import CreateYardModal from '@components/deprecated/DynamicModals/CreateYardModal';
import DeleteYardModal from '@components/deprecated/DynamicModals/DeleteYardModal';
import BeeTrackYardMgmtMap from '@components/deprecated/Gmap/BeeTrackYardMgmtMap';
import { makeFetchYardMapRequestThunk } from '@redux/deprecated/actions';

import { StyledWrapper } from './styles';

const ManagementScreen = BeeTrackYardMgmtMap as any;

export const YardsManagement: React.VFC = () => {
  const { yards } = useSelector<any, any>((state) => state.beeTrackYardListReducer);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(makeFetchYardMapRequestThunk());
  }, [dispatch]);

  return (
    <>
      {yards ? (
        <StyledWrapper>
          <ManagementScreen t={t} yardsMap={[]} />
          <CreateYardModal />
          <DeleteYardModal />
        </StyledWrapper>
      ) : (
        <Loading />
      )}
    </>
  );
};
