import { Reducer } from 'redux';

export type YardClearOrDeleteReducer = Reducer<YardClearOrDeleteState, YardClearOrDeleteAction>;

export type YardClearOrDeleteAction =
  | { type: YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_START }
  | { type: YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_FINISH; payload: { deletable: boolean } };

export interface YardClearOrDeleteState {
  isDeletable: boolean;
  isFetchingDeletable: boolean;
}

export enum YardClearOrDeleteActionType {
  YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_START = 'YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_START',
  YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_FINISH = 'YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_FINISH',
}
