import React, { useEffect, useState } from 'react';

import { CheckboxProps } from '../../types';

import { CheckboxHiddenLabel, CheckboxHover, CheckboxInput, CheckboxWrapper, StyledCheck } from './styles';

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  id = name,
  value,
  onChange,
  style = {},
  hiddenLabelText,
  disabled,
  disableHoverEffect,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(value);
  useEffect(() => {
    if (value !== isChecked) {
      setIsChecked(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <CheckboxWrapper style={style}>
      <CheckboxInput
        ref={(ref) => {
          if (!ref) return;

          ref.onclick = (e) => {
            e.stopPropagation();
          };
          // @todo fix this 'any' to be the correct type!
          ref.onchange = (e: any) => {
            // calling parent state function after local state function avoids the visual delay of the parent re-rendering
            setIsChecked(e.target.checked);
            onChange(e.target.checked, name);
          };
        }}
        id={id}
        name={name}
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        // on change is already added through the ref, but adding an empty onChange here to silence the console errors
        onChange={() => {}}
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...props}
      />
      {!disabled && !disableHoverEffect && <CheckboxHover />}
      <StyledCheck />
      {hiddenLabelText && <CheckboxHiddenLabel>{hiddenLabelText}</CheckboxHiddenLabel>}
    </CheckboxWrapper>
  );
};
