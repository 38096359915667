import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';

import { RadioButtonProps } from '../../types';
import { RadioButtonV2 } from '../RadioButtonV2';

/**
 * @todo:
 * [] add option to have checkbox instead of radio
 * [] add option to have neither checkbox or radio, just text (maybe?)
 * [] add to storybook
 */

const StyledBox = styled(Box)<{ isChecked: boolean; isDisabled?: boolean }>(
  ({ theme, isChecked, isDisabled }) => css`
    padding: 0.75rem; // have to do this until we update with 12px padding stuff
    border-width: 1px;
    border-color: ${isChecked ? theme.colors.borderPrimary : theme.colors.borderSecondary};
    ${isDisabled &&
    `
        border-color: ${theme.colors.borderDisabled};
        * {
            color: ${theme.colors.contentDisabled};   
        }
    
    `}
  `
);

interface SelectionCardProps {
  radioButtonProps: RadioButtonProps;
  title: string;
  subtext?: string;
  content: string;
  bottomText?: string;
  wrapperBoxProps?: BoxProps<'div'>;
}

export const SelectionCard: React.FC<SelectionCardProps> = ({
  radioButtonProps,
  title,
  subtext,
  content,
  bottomText,
  wrapperBoxProps = {},
}) => {
  return (
    <StyledBox
      gapHorizontal_050
      paper02
      isChecked={radioButtonProps.value === radioButtonProps.selectedOptionValue}
      isDisabled={radioButtonProps.disabled}
      {...wrapperBoxProps}
    >
      <div>
        <RadioButtonV2 {...radioButtonProps} />
      </div>
      <Box column>
        <Text typography="Heading3" weight="600" lineHeight="16px">
          <label htmlFor={radioButtonProps.id}>{title}</label>
          {subtext && (
            <Text typography="CaptionSmall" color="contentTertiary" htmlTag="span">
              {subtext}
            </Text>
          )}
        </Text>
        <Box paddingTop_050 paddingBottom_100={!!bottomText}>
          <Text typography="CaptionSmall" color="contentTertiary">
            <label htmlFor={radioButtonProps.id}>{content}</label>
          </Text>
        </Box>
        {bottomText && (
          <Text typography="Legend" color="contentTertiary">
            {bottomText}
          </Text>
        )}
      </Box>
    </StyledBox>
  );
};
