import React, { ComponentType, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import QuestionMarkIcon from '@assets/xSmall_Help.svg';
import { Box } from '@components/common/Box';
import { Card, CardContent, CardHeader } from '@components/common/Card';
import { IconIMG } from '@components/common/Icon';
import { Add } from '@components/common/Icon/presets/Add';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { Pill } from '@components/common/Icon/presets/Pill';
import { Slider } from '@components/common/Icon/presets/Slider';
import { Yard } from '@components/common/Icon/presets/Yard';
import { IconSVGProps } from '@components/common/Icon/types';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { PracticeIcon } from '@components/common/PracticeIcon';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { ActionPill, ActionPillSlider } from '@components/operation/ActionPill';
import { usePracticeConfig } from '@config/Practices/hooks';
import { capitalizeFirst } from '@helpers/deprecated/capitalizeFirst';
import { Sorting } from '@helpers/Sorting';
import { makeEnableDisableActionRequestThunk, makeOpenBeekPracticesModalAction } from '@redux/deprecated/actions';
import { makePracticesFetchThunk } from '@redux/Practices/actions';
import { usePractices } from '@redux/Practices/hooks';

import {
  StyledCategoryActions,
  StyledHeaderPill,
  StyledHeaderPillWrapper,
  StyledPlusButton,
  StyledStyledHeader,
} from './styles';
import { PracticeDefinitionCardProps } from './types';

const DEF_FIELD_TYPE_ICONS: Record<BeePracticeDefinition['fieldType'], ComponentType<IconSVGProps> | null> = {
  system: null,
  checkbox: Pill,
  radio: Pill,
  slider: Slider,
};

export const PracticeDefinitionCard: React.FC<PracticeDefinitionCardProps> = ({ practiceDefinitionId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);
  const { getPractice, getPracticeByAction, getPracticeName, getActionName, isFetchingPractice } = usePractices();
  const practice = getPractice(practiceDefinitionId);
  const config = usePracticeConfig(practice);

  const actions = useMemo(() => practice?.actions.filter((action) => !action.isDeleted) || [], [practice]);
  const sortedActions = useMemo(
    () => Sorting.sort(actions, (a, b) => Sorting.alphanumericSortFunc(getActionName(a.id), getActionName(b.id))),
    [actions, getActionName]
  );
  const filteredActions = useMemo(
    () =>
      sortedActions.filter(({ isActive }) => !user?.membershipPreferences.settingsPracticesHideDisabled || isActive),
    [sortedActions, user?.membershipPreferences.settingsPracticesHideDisabled]
  );

  const isEmpty = !actions.length;

  const FieldTypeIcon = practice ? DEF_FIELD_TYPE_ICONS[practice.fieldType] : null;

  const openAddActionModal = useCallback(() => {
    practice && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'create-practice', practice }));
  }, [practice, dispatch]);

  const openEditActionModal = useCallback(
    (action: BeeAction) => {
      practice && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'update-practice', practice, action }));
    },
    [practice, dispatch]
  );

  const openDisableActionModal = useCallback(
    (action: BeeAction) => {
      practice && dispatch(makeOpenBeekPracticesModalAction({ modalType: 'remove-practice', practice, action }));
    },
    [practice, dispatch]
  );

  const handleEnableAction = useCallback(
    async (action: BeeAction) => {
      await dispatch(makeEnableDisableActionRequestThunk(action.id, { is_active: true }));
      const practice = getPracticeByAction(action.id);
      practice && dispatch(makePracticesFetchThunk({ practiceId: practice.id }));
    },
    [dispatch, getPracticeByAction]
  );

  const renderHeader = useCallback(() => {
    const name = capitalizeFirst(getPracticeName(practice?.id));
    const tooltipId = `category-help-tooltip-${practice?.id}`;

    return (
      <StyledStyledHeader fit gap_075>
        <Box fit alignItems={'center'} gap_050>
          <PracticeIcon practiceId={practice?.id} size={16} />

          <Text typography={'Heading3'} weight={'600'}>
            {name}
          </Text>
          {config.suppressMenu ? (
            <>
              <IconIMG
                id={tooltipId}
                src={QuestionMarkIcon}
                size={18}
                color={'grey06'}
                alt={'Help tooltip'}
                clickable
              />
              <Tooltip target={tooltipId}>
                <Text typography={'TooltipSmall'}>
                  {capitalizeFirst(t('category_tooltip', { category_name: name }))}
                </Text>
              </Tooltip>
            </>
          ) : null}
        </Box>

        <StyledHeaderPillWrapper center>
          {!!practice && practice.fieldType !== 'system' && (
            <StyledHeaderPill borderColor={'grey05'}>
              {!!FieldTypeIcon && <FieldTypeIcon size={16} />}
              <ResponsiveRender from={'tablet'}>
                <Text typography={'TooltipSmall'}>{t(`practice_type_${practice.fieldType}`)}</Text>
              </ResponsiveRender>
            </StyledHeaderPill>
          )}

          <StyledHeaderPill
            $color={practice?.isHiveData ? 'chartBlue' : 'chartPurple'}
            borderColor={practice?.isHiveData ? 'chartBlue' : 'chartPurple'}
          >
            {practice?.isHiveData ? <HiveDefault /> : <Yard />}
            <ResponsiveRender from={'tablet'}>
              <Text typography={'TooltipSmall'}>{t(practice?.isHiveData ? 'Hives' : 'yards_capitalized')}</Text>
            </ResponsiveRender>

            <Tooltip>
              <Text typography={'TooltipSmall'}>
                {t(practice?.isHiveData ? 'practice_hive_data_tooltip' : 'practice_yard_data_tooltip')}
              </Text>
            </Tooltip>
          </StyledHeaderPill>
        </StyledHeaderPillWrapper>
      </StyledStyledHeader>
    );
  }, [FieldTypeIcon, config.suppressMenu, getPracticeName, practice, t]);

  const renderContent = useCallback(() => {
    return (
      <StyledCategoryActions gap_050 alignItems={'center'}>
        {!practice ? null : practice?.fieldType === 'slider' ? (
          <ActionPillSlider>{`${practice.config.slider.min}-${practice.config.slider.max}`}</ActionPillSlider>
        ) : (
          filteredActions.map(({ id }) => (
            <ActionPill
              key={id}
              actionId={id}
              onEditClick={openEditActionModal}
              onDisableClick={openDisableActionModal}
              onEnableClick={handleEnableAction}
            />
          ))
        )}
        {!config.suppressActionAdd && practice?.fieldType !== 'slider' ? (
          <StyledPlusButton suppressPadding onClick={openAddActionModal}>
            <Add />
          </StyledPlusButton>
        ) : null}
      </StyledCategoryActions>
    );
  }, [
    practice,
    filteredActions,
    config.suppressActionAdd,
    openAddActionModal,
    openEditActionModal,
    openDisableActionModal,
    handleEnableAction,
  ]);

  if (!practice || (isEmpty && config.suppressActionAdd)) return null;

  return (
    <Card relative {...props}>
      <CardHeader>{renderHeader()}</CardHeader>
      <CardContent>{renderContent()}</CardContent>

      <LoadingSkeleton
        visible={isFetchingPractice[practice.id]}
        absolutelyFitParent
        backgroundColor={'overlayWhiteBackground'}
      >
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} borderRadius_150 backgroundColor={'grey02'} />
      </LoadingSkeleton>
    </Card>
  );
};
