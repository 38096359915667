import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GridApiContext } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ModalFooter, ModalHeader } from '@components/common/ModalBase';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { StepsProgress } from '@components/common/StepsProgress';
import { FormErrors } from '@components/pollination/FormContract/types';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { URLUtil } from '@helpers/URL';
import { useContract, useContractError } from '@hooks/useContract';
import { useTranslation } from '@hooks/useTranslation';
import { makeCreateContractThunk, makePatchContractThunk } from '@redux/Contract/actions';
import { useContractModalController } from '@redux/Contract/hooks';
import { useContractsMetricsLoader } from '@redux/ContractsMetrics/hooks';

import { FormSectionFinances } from './FormSectionFinances';
import { FormSectionGrowerInfo } from './FormSectionGrowerInfo';
import { FormSectionOverview } from './FormSectionOverview';
import { StyledFormPages } from './styles';

type FormContractProps = {
  onCancel: () => void;
  contract?: BeeContract | null;
  isEdit?: boolean;
};

enum FormSection {
  OVERVIEW = 0,
  GROWER = 1,
  FINANCES = 2,
}

export const FormContract: React.FC<FormContractProps> = ({ onCancel, contract }) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFetchingContract } = useContract();
  const { close } = useContractModalController();
  const isEdit = useMemo(() => !!contract, [contract]);

  const tableContext = useContext(GridApiContext);
  const { loadMetrics } = useContractsMetricsLoader();

  const overviewFormDefaultState: BeeContractOverview = useMemo(() => {
    return {
      name: contract?.name ?? '',
      cropTypesIds: contract?.cropTypesIds ?? [],
      nbRequiredHives: contract?.nbRequiredHives ?? ('' as any),
      beesIn: contract?.beesIn ?? null,
      beesOut: contract?.beesOut ?? null,
    };
  }, [contract]);

  const growerInfoDefaultState = useMemo(() => {
    return (
      contract?.grower ?? {
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactEmail: '',
      }
    );
  }, [contract]);

  const financesFormDefaultState = useMemo(() => {
    return {
      costPerHive: contract?.costPerHive ?? ('' as any),
      totalPrice: contract?.totalPrice ?? ('' as any),
      address: contract?.address ?? '',
      notes: contract?.notes ?? '',
    };
  }, [contract]);

  const contractFormDefaultState = useMemo(() => {
    return {
      ...overviewFormDefaultState,
      grower: growerInfoDefaultState,
      ...financesFormDefaultState,
    };
  }, [overviewFormDefaultState, growerInfoDefaultState, financesFormDefaultState]);

  const [contractForm, setContractForm] = useState<BeeContractForm>(contractFormDefaultState);

  const [activeSection, setActiveSection] = useState(FormSection.OVERVIEW);

  const handleSectionChange = (localFormState: Partial<BeeContractForm>) => {
    setContractForm((oldState) => ({ ...oldState, ...localFormState }));
    setActiveSection((current) => current + 1);
  };

  const handleFormSubmit = async (localFormState: Partial<BeeContractForm>) => {
    if (isFetchingContract) return;
    const completedForm = { ...contractForm, ...localFormState };
    setContractForm(completedForm);

    let response: BeeContract | undefined;
    if (isEdit && contract?.id) {
      response = await dispatch(makePatchContractThunk({ id: contract.id, ...completedForm }));
    } else {
      response = await dispatch(makeCreateContractThunk(completedForm));
    }

    if (!response) {
      return;
    }

    if (response?.id) {
      close();
    }

    if (isEdit) {
      // If a grid is present, refresh it.
      tableContext?.gridApi?.refreshInfiniteCache();

      // Also refresh metrics
      loadMetrics();
    } else if (response?.id) {
      history.push(
        URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, { pathParams: { uid: response?.id } })
      );
    }

    Analytics.sendEvent({
      event: isEdit ? AnalyticsEventType.POLLINATION_CONTRACT_EDIT : AnalyticsEventType.POLLINATION_CONTRACT_CREATE,
    });
  };

  const handleSectionBack = () => setActiveSection((current) => current - 1);

  // TODO: Handle more possible form errors.
  const contractError = useContractError();
  const formErrors = useMemo<FormErrors>(() => {
    const errors = contractError || {};
    return { growerContactPhoneNumber: errors['grower.contactPhoneNumber'] ?? null };
  }, [contractError]);

  useEffect(() => {
    if ([formErrors.growerContactPhoneNumber].some((error) => !!error)) {
      setActiveSection(FormSection.GROWER);
    }
  }, [formErrors.growerContactPhoneNumber]);

  const modalTitle = isEdit ? t('edit_contract') : t('pollination_create_contract');

  const backOrCancelButton =
    activeSection === FormSection.OVERVIEW ? (
      <Button tertiary type="button" onClick={onCancel} withMarginRight>
        {t('cancel')}
      </Button>
    ) : (
      <Button type="button" tertiary onClick={handleSectionBack} withMarginRight>
        {t('back')}
      </Button>
    );

  return (
    <>
      <ModalHeader
        title={modalTitle}
        mobileTitle={modalTitle}
        mobileLeftContent={
          <Box paddingHorizontal_050>
            <StepsProgress currentStep={activeSection} stepCount={3} markCompletedSteps />
          </Box>
        }
      />

      <ModalContent>
        <StyledFormPages currentStep={activeSection}>
          <FormSectionOverview
            defaultState={overviewFormDefaultState}
            section={FormSection.OVERVIEW}
            isActive={activeSection === FormSection.OVERVIEW}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleSectionChange}
          />
          <FormSectionGrowerInfo
            defaultState={growerInfoDefaultState}
            section={FormSection.GROWER}
            isActive={activeSection === FormSection.GROWER}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleSectionChange}
          />
          <FormSectionFinances
            formState={contractForm}
            defaultState={financesFormDefaultState}
            section={FormSection.FINANCES}
            isActive={activeSection === FormSection.FINANCES}
            activeSection={activeSection}
            formErrors={formErrors}
            handleFormSectionSubmit={handleFormSubmit}
          />
        </StyledFormPages>
      </ModalContent>

      <ModalFooter>
        <ResponsiveRender until={'mobile'}>
          <Button
            type="button"
            tertiary
            onClick={handleSectionBack}
            withMarginRight
            disabled={activeSection === FormSection.OVERVIEW}
          >
            {t('back')}
          </Button>
        </ResponsiveRender>
        <ResponsiveRender from={'tablet'}>
          <StepsProgress currentStep={activeSection} stepCount={3} markCompletedSteps />
        </ResponsiveRender>
        <Box fit justifyContent={'flex-end'}>
          <ResponsiveRender from={'tablet'}>{backOrCancelButton}</ResponsiveRender>

          <Button primary type="submit" form={`contractForm-section-${activeSection}`}>
            {activeSection < FormSection.FINANCES ? t('next') : isEdit ? t('save') : t('pollination_create')}
          </Button>
        </Box>
      </ModalFooter>
    </>
  );
};
