import React, { useMemo } from 'react';
import styled, { ColorGlobal, css } from 'styled-components';

import { DefaultCellText } from '@components/common/DynamicTable/DefaultCell';
import { DefaultEmptyCell } from '@components/common/DynamicTable/DefaultCell/DefaultEmptyCell';
import { usePractices } from '@redux/Practices/hooks';

const StyledGrading = styled.div<{ $statusColor: ColorGlobal }>(
  ({ theme, $statusColor }) => css`
    border: 1px solid;
    border-color: ${theme.colors[$statusColor]};
    padding: ${theme.spacing._050} ${theme.spacing._025};
    border-radius: ${theme.shape.paperRadius01}px;
    text-align: center;
    line-height: 1.5rem;
  `
);

export const Grading: React.VFC<{ actionId: number | null; occurredAt: string | null }> = ({ actionId }) => {
  const { getActionName, getAction } = usePractices();
  const grading = getAction(actionId)?.flag as HiveGradingFlag;
  const displayName = getActionName(actionId);

  const statusColor: ColorGlobal = useMemo(() => {
    switch (grading) {
      case 'hive_grading_strong':
        return 'borderPositive';

      case 'hive_grading_medium':
        return 'borderWarning';

      case 'hive_grading_weak':
        return 'borderDanger';
      default:
        return 'surfaceDefault';
    }
  }, [grading]);

  if (!grading || !displayName) {
    return <DefaultEmptyCell />;
  }
  return (
    <StyledGrading $statusColor={statusColor}>
      <DefaultCellText>{displayName}</DefaultCellText>
    </StyledGrading>
  );
};
