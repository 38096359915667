import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';

export const StyledOperationNameCircle = styled(Box)(
  ({ theme }) => css`
    width: ${theme.sizeIcon._150};
    height: ${theme.sizeIcon._150};
    border-radius: 50%;
  `
);

export const OperationIcon: React.VFC<{ operation?: { name: string; color: string } } & BoxProps<'div'>> = ({
  operation: propOperation,
  ...boxProps
}) => {
  const reduxOperation = useSelector((state) => state.operationReducer.operation);
  const operationName = propOperation?.name ?? reduxOperation?.name;
  const backgroundColor = propOperation?.color ?? reduxOperation?.color;

  return (
    <StyledOperationNameCircle style={{ backgroundColor }} center {...boxProps}>
      <Text typography={'CaptionSmall'} weight={'600'} color={'contentPrimaryReverse'}>
        {operationName ? operationName[0].toUpperCase() : '-'}
      </Text>
    </StyledOperationNameCircle>
  );
};
