import React from 'react';

import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { BreakPoint, BREAKPOINT_RANGES } from '@style/constants';

export interface ResponsiveRenderProps {
  from?: BreakPoint;
  until?: BreakPoint;
}

export const ResponsiveRender: React.FC<ResponsiveRenderProps> = ({ children, from, until }) => {
  /**
   * Retriggers the component when window resizes
   */
  useGetScreenWidth();
  const fromBp = from ? BREAKPOINT_RANGES[from][0] : undefined;
  const untilBp = until ? BREAKPOINT_RANGES[until][1] : undefined;

  if (fromBp && untilBp) {
    if (window.matchMedia(`(min-width: ${fromBp}) and (max-width: ${untilBp})`).matches) {
      return <>{children}</>;
    } else return null;
  }

  if (fromBp && window.matchMedia(`(min-width: ${fromBp})`).matches) return <>{children}</>;
  if (untilBp && window.matchMedia(`(max-width: ${untilBp})`).matches) return <>{children}</>;
  return null;
};
