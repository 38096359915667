import React, { Fragment } from 'react';

import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { Text } from '@components/common/Text';
import { LastVisitTooltipContent } from '@components/yard/YardsList/cells/LastVisitTooltipContent';
import { PracticeCellTooltipContent } from '@components/yard/YardsList/cells/PracticeCellTooltipContent';
import { YardsListCellProps } from '@components/yard/YardsList/cells/types';
import { ColumnType } from '@components/yard/YardsList/types';
import { YardsListTooltip } from '@components/yard/YardsList/YardsListTooltip';
import { Children } from '@helpers/Children';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getDate } from '@helpers/deprecated/time';
import { Number } from '@helpers/Number';
import { Sorting } from '@helpers/Sorting';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

interface PracticeOccurrencesData {
  occurrences: number;
}

interface PracticeCategoryOccurrencesData {
  [key: string]: {
    occurrences: number;
    practiceId: number;
  };
}

export const PracticeCell: React.FC<YardsListCellProps> = ({ id, column, view, colDef, data }) => {
  const t = useTranslation();

  const { getActionName } = usePractices();
  const tooltipParams = { id, column, view, colDef };
  const interactiveTooltipParams = { interactive: true, trigger: 'mouseenter focus', ...tooltipParams };

  if (data === null) {
    return null;
  }

  switch (view.key) {
    case 'lastOccurrenceValue':
      let label = '';
      if (data.values) {
        label = (data.values ?? []).map((v: number) => Number.getReadableNumber(v)).join(', ');
      } else {
        label = (data.actions ?? []).map((id: number) => getActionName(id, '-')).join(', ');
      }
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {label}
              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'lastOccurrenceDatetime':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {getDate(data)}

              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'lastVisitAffectedHives':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {data ?? ''}

              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'periodSinceLastOccurrence':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={typeof data === 'number' || !!data}>
              {maybePluralize(data, 'day', t, 's', true)}

              {typeof data === 'number' || !!data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'workerLastOccurrence':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>
              {data}

              {data ? (
                <YardsListTooltip {...interactiveTooltipParams}>
                  {(data) => <LastVisitTooltipContent data={data} />}
                </YardsListTooltip>
              ) : null}
            </DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'averageLastThirtyDays':
    case 'sumSinceSeasonStart':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText>{Number.getReadableNumber(data)}</DefaultCellText>
          </div>
        </DefaultCellWrapper>
      );
    case 'averageOfLastVisit':
    case 'sumOfLastVisit':
      return (
        <DefaultCellWrapper>
          <div>
            <DefaultCellText dashed={!!data}>{Number.getReadableNumber(data)}</DefaultCellText>

            {data ? (
              <YardsListTooltip {...interactiveTooltipParams}>
                {(data) => <LastVisitTooltipContent data={data} />}
              </YardsListTooltip>
            ) : null}
          </div>
        </DefaultCellWrapper>
      );
    case 'occurrencesSinceSeasonStart':
      if (column.type === ColumnType.PRACTICE) {
        const _data = data as PracticeOccurrencesData;
        return (
          <DefaultCellWrapper>
            <DefaultCellText>
              <Text typography={'SmallParagraph'} htmlTag={'span'} dashed={_data.occurrences > 0}>
                {_data.occurrences}

                {_data.occurrences > 0 && (
                  <YardsListTooltip {...tooltipParams}>
                    {({ occurrences }) => <PracticeCellTooltipContent dates={occurrences} />}
                  </YardsListTooltip>
                )}
              </Text>
            </DefaultCellText>
          </DefaultCellWrapper>
        );
      } else {
        const _data = data as PracticeCategoryOccurrencesData;
        const occurrences = Object.values(_data);
        const nonZeroOccurrences = occurrences.filter(({ occurrences }) => occurrences > 0);
        const namedOccurrences = nonZeroOccurrences.map((occurrence) => ({
          ...occurrence,
          actionName: getActionName(occurrence.practiceId, '-'),
        }));
        const sortedOccurrences = Sorting.sortAlphanumerically(namedOccurrences, 'actionName');

        return (
          <DefaultCellWrapper>
            <DefaultCellText>
              {Children.createWithSeparators(sortedOccurrences, {
                itemWrapper: ({ practiceId, actionName, occurrences }, separator) => (
                  <Fragment key={practiceId}>
                    <Text typography={'SmallParagraph'} htmlTag={'span'} dashed>
                      {actionName} ({occurrences})
                      <YardsListTooltip {...tooltipParams}>
                        {(occurrencesByPractice) => {
                          const _data = occurrencesByPractice.find((entry: any) => entry.practiceId === practiceId);
                          return <PracticeCellTooltipContent dates={_data.occurrences ?? []} />;
                        }}
                      </YardsListTooltip>
                    </Text>
                    {separator}
                  </Fragment>
                ),
              })}
            </DefaultCellText>
          </DefaultCellWrapper>
        );
      }
    default:
      return null;
  }
};
