import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { useDebounce } from '@helpers/Throttling/hooks';

const AUTOCOMPLETE_EVENTS_DEBOUNCE = 80;

export function useDropdownDesktopAutoWidth(elements: ReturnType<typeof useDropdownDesktopElements>) {
  const { targetElement, dropdownElement } = elements;

  const theme = useTheme();

  useEffect(() => {
    if (!targetElement || !dropdownElement) return;

    const inputWidth = targetElement.getBoundingClientRect().width ?? 0;
    const extraMargin = 1; // Avoid a visual conflict with the inputs borders.
    dropdownElement.style.width = `calc(${inputWidth + 2 * extraMargin}px)`;
    dropdownElement.style.marginLeft = `-${extraMargin}px`;
  }, [dropdownElement, targetElement, theme.spacing._200]);

  return { targetElement, dropdownElement };
}

/**
 * Tracks events around the dropdown anchor and content.
 * Events outside it will call onBlur, while events inside
 * it will call onFocus.
 * */
export function useDropdownDesktopEvents(
  elements: ReturnType<typeof useDropdownDesktopElements>,
  options: {
    onFocus: () => void;
    onBlur?: () => void;
  }
) {
  const { onFocus, onBlur } = options;
  const { update, targetElement, dropdownElement } = elements;
  const debounce = useDebounce(AUTOCOMPLETE_EVENTS_DEBOUNCE);

  const state = useMemo(
    () => ({
      hasCalledFocus: false,
      hasCalledFocusLastTime: false,
      eventQueue: [] as Array<{ type: string; within: boolean }>,
    }),
    []
  );

  const clearEventQueue = useCallback(() => state.eventQueue.splice(0, state.eventQueue.length), [state.eventQueue]);

  const focus = useCallback(() => {
    clearEventQueue();
    state.hasCalledFocus = true;
    state.hasCalledFocusLastTime = true;
    onFocus();
    update();
  }, [clearEventQueue, onFocus, state, update]);

  const blur = useCallback(() => {
    clearEventQueue();
    state.hasCalledFocusLastTime = false;
    onBlur && onBlur();
  }, [clearEventQueue, onBlur, state]);

  useEffect(() => {
    const handleEvents = () => {
      const hasEventWithin = state.eventQueue.some(({ within }) => within);
      const hasFocusOutside = state.eventQueue.some(({ type, within }) => type === 'focusin' && !within);
      const hasFocus = hasEventWithin && !hasFocusOutside;
      const shouldSkip = !hasFocus && !state.hasCalledFocus;
      const isRepeatingCall = hasFocus === state.hasCalledFocusLastTime;

      if (!shouldSkip && !isRepeatingCall) {
        hasFocus ? focus() : blur();
      } else {
        clearEventQueue();
      }
    };

    const handleEvent = ({ target, type }: Event) => {
      const withinTarget = !!targetElement?.contains(target as HTMLElement);
      const withinDropdown = !!dropdownElement?.contains(target as HTMLElement);
      state.eventQueue.push({ type, within: withinTarget || withinDropdown });
      debounce(handleEvents);
    };

    document.addEventListener('click', handleEvent);
    document.addEventListener('keydown', handleEvent);
    document.addEventListener('focusin', handleEvent);

    return () => {
      document.removeEventListener('click', handleEvent);
      document.removeEventListener('keydown', handleEvent);
      document.removeEventListener('focusin', handleEvent);
    };
  }, [blur, clearEventQueue, debounce, dropdownElement, focus, onBlur, onFocus, state, targetElement, update]);

  return { targetElement, dropdownElement, blur };
}

export function useDropdownDesktopElements(target: string) {
  const [targetElement, setTargetElements] = useState<HTMLElement | null>(null);
  const [dropdownElement, setDropdownElement] = useState<HTMLElement | null>(null);

  const update = useCallback(() => {
    const targetElement = document.getElementById(target);
    const dropdownElement = document.querySelector<HTMLElement>(`[data-dropdown-target=${target}]`);
    setTargetElements(targetElement);
    setDropdownElement(dropdownElement);
  }, [target]);

  useLayoutEffect(() => update(), [target, update]);

  return { update, targetElement, dropdownElement };
}

export function useDropdownMobileEvents(
  elements: ReturnType<typeof useDropdownMobileElements>,
  options: {
    onFocus: () => void;
  }
) {
  const { onFocus } = options;
  const { targetElement } = elements;
  const debounce = useDebounce(AUTOCOMPLETE_EVENTS_DEBOUNCE);

  useEffect(() => {
    const handleClick = () => onFocus && onFocus();
    targetElement?.addEventListener('click', handleClick);
    return () => {
      targetElement?.removeEventListener('click', handleClick);
    };
  }, [debounce, onFocus, targetElement]);

  return { targetElement };
}

export function useDropdownMobileElements(target: string) {
  const [targetElement, setTargetElements] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => setTargetElements(document.getElementById(target)), [target]);
  return { targetElement };
}
