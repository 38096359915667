import { padEnd } from 'lodash';

export function removeAllWhiteSpace(string: string): string {
  return string.replace(/\s+/g, '');
}

export function removeAllHTMLTags(string: string): string {
  return string.replace(/(<([^>]+)>)/gi, '');
}

export function numberFormatter(number: number): string {
  return number.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
}

export function moneyFormatter(number: number, options?: Intl.NumberFormatOptions): string {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    ...options,
  });
}

export function hiveSerialFormatter(serial: string | number, length = 7): string {
  const reversed = reverse(String(serial));
  const sliced = reversed.slice(0, length);
  const padded = padEnd(sliced, length, '0');
  return reverse(padded);
}

export function reverse(text: string): string {
  return text.split('').reverse().join('');
}
