import { Reducer } from 'redux';
import { ColorGlobal } from 'styled-components';

import { CardType } from '@components/yard/MetricCard/type';
import { MetricName } from '@config/GlobalMetricsConfig/constants';
import { ApiResponseError } from '@helpers/Api/types';

export type YardsReducer = Reducer<YardsState, YardsAction>;

export type YardsAction = YardsListAction | YardsMapAction | YardsMetricAction;

export type YardsListAction = { type: never };
export type YardsMapAction =
  | { type: YardsMapActionType.MAP_FETCH_YARDS_START }
  | {
      type: YardsMapActionType.MAP_FETCH_YARDS_FINISH;
      payload: { data: Array<BeeYardOnMapInfo>; error: ApiResponseError | null };
    }
  | { type: YardsMapActionType.MAP_FETCH_YARD_DETAIL_START }
  | {
      type: YardsMapActionType.MAP_FETCH_YARD_DETAIL_FINISH;
      payload: {
        data: BeeYardOnMapDetailedInfo | null;
        error: ApiResponseError | null;
      };
    }
  | { type: YardsMapActionType.MAP_FETCH_BLOCKS_START }
  | {
      type: YardsMapActionType.MAP_FETCH_BLOCKS_FINISH;
      payload: { data: Array<BeeBlock>; error: ApiResponseError | null };
    };
export type YardsMetricAction =
  | { type: YardsMetricActionType.METRIC_FETCH_AVAILABILITY_START }
  | {
      type: YardsMetricActionType.METRIC_FETCH_AVAILABILITY_FINISH;
      payload: { cards: MetricMetadata[]; error: ApiResponseError | null };
    }
  | {
      type: YardsMetricActionType.METRIC_FETCH_VALUE_START;
    }
  | {
      type: YardsMetricActionType.METRIC_FETCH_VALUE_FINISH;
      payload: { cards: MetricMetadata[]; error: ApiResponseError | null };
    }
  | {
      type: YardsMetricActionType.OPEN_METRIC_MODAL;
      payload: {
        metricName: string;
        metricValue: MetricValue[];
        positiveDirection: boolean;
        cardType: CardType;
        categories: string[] | null;
      };
    }
  | { type: YardsMetricActionType.METRIC_FETCH_TREND_START }
  | { type: YardsMetricActionType.METRIC_FETCH_TREND_FAILURE }
  | {
      type: YardsMetricActionType.METRIC_FETCH_TREND_FINISH;
      payload: { data: MetricTrendValues[]; error: ApiResponseError | null };
    }
  | { type: YardsMetricActionType.CLOSE_METRIC_MODAL };

export interface YardsState {
  /** List data is now managed by the AGGrid internal state. */
  /* list: never; */

  map: YardsMapState;
  metrics: YardsMetricState;
  trends: MetricTrendState;
}

export interface YardsMapState {
  /** Loaded with all operation's non-deleted yards. */
  yards: Array<BeeYardOnMapInfo>;

  /** Stores the retrieved detailed info for the selected yard. */
  yardDetail: BeeYardOnMapDetailedInfo | null;

  /** Retrieved when "blocks layer" is enabled. */
  blocks: Array<BeeBlock>;

  isFetchingYards: boolean;
  isFetchingYardDetail: boolean;
  isFetchingBlocks: boolean;
  fetchYardsError: ApiResponseError | null;
  fetchYardsDetailError: ApiResponseError | null;
  fetchBlocksError: ApiResponseError | null;
}

export interface YardsMetricState {
  cards: Array<MetricMetadata>;
  isFetching: boolean;
  fetchError: ApiResponseError | null;
}

export type MetricMetadata = {
  metricValue: MetricValue[];
  metricName: MetricName;
  sampleSize: number;
  isFetching: boolean;
};

export type MetricValue = {
  value: number;
  previousValue?: number;
  color?: ColorGlobal;
  name?: string;
};

export type TrendValues = {
  value: number;
  ts: Date;
};

export interface MetricTrendValues {
  trend: TrendValues[];
  name: string;
}

export interface MetricTrendState {
  data: MetricTrendValues[];
  isFetching: boolean;
  fetchError: ApiResponseError | null;
  metricToQuery: string;
  isMetricModalOpen: boolean;
  currentMetricValue: MetricValue[];
  positiveDirection: boolean;
  cardType: CardType;
  categories: string[] | null;
  start: number;
}

export type MetricAction =
  | {
      type: YardsMetricActionType.OPEN_METRIC_MODAL;
      payload: {
        metricName: string;
        metricValue: MetricValue[];
        positiveDirection: boolean;
        cardType: CardType;
        categories: string[] | null;
      };
    }
  | { type: YardsMetricActionType.CLOSE_METRIC_MODAL }
  | { type: YardsMetricActionType.METRIC_FETCH_TREND_START }
  | { type: YardsMetricActionType.METRIC_FETCH_TREND_FAILURE }
  | {
      type: YardsMetricActionType.METRIC_FETCH_TREND_FINISH;
      payload: { data: MetricTrendValues[]; error: ApiResponseError | null };
    };

export enum YardsFiltersActionType {}
export enum YardsListActionType {}
export enum YardsMapActionType {
  MAP_FETCH_YARDS_START = 'MAP_FETCH_YARDS_START',
  MAP_FETCH_YARDS_FINISH = 'MAP_FETCH_YARDS_FINISH',
  MAP_FETCH_YARD_DETAIL_START = 'MAP_FETCH_YARD_DETAIL_START',
  MAP_FETCH_YARD_DETAIL_FINISH = 'MAP_FETCH_YARD_DETAIL_FINISH',
  MAP_FETCH_BLOCKS_START = 'MAP_FETCH_BLOCKS_START',
  MAP_FETCH_BLOCKS_FINISH = 'MAP_FETCH_BLOCKS_FINISH',
}
export enum YardsMetricActionType {
  METRIC_FETCH_AVAILABILITY_START = 'METRIC_FETCH_AVAILABILITY_START',
  METRIC_FETCH_AVAILABILITY_FINISH = 'METRIC_FETCH_AVAILABILITY_FINISH',
  METRIC_FETCH_VALUE_START = 'METRIC_FETCH_VALUE_START',
  METRIC_FETCH_VALUE_FINISH = 'METRIC_FETCH_VALUE_FINISH',
  METRIC_FETCH_TREND_START = 'METRIC_FETCH_TREND_START',
  METRIC_FETCH_TREND_FAILURE = 'METRIC_FETCH_TREND_FAILURE',
  METRIC_FETCH_TREND_FINISH = 'METRIC_FETCH_TREND_FINISH',
  OPEN_METRIC_MODAL = 'OPEN_METRIC_MODAL',
  CLOSE_METRIC_MODAL = 'CLOSE_METRIC_MODAL',
}
