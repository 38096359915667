import React, { useCallback, useEffect } from 'react';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { HiveStatusChip } from '@components/common/HiveStatusChip';
import { ModalHeader } from '@components/common/ModalBase';
import { useHiveModalInnerContext, useHiveModalOpener } from '@components/hive/HiveModal/hooks';
import { StyledHiveModalContent } from '@components/hive/HiveModalContent/styles';
import { HiveModalMainLoading } from '@components/hive/HiveModalLoading/HiveModalMainLoading';
import { HiveModalTabs } from '@components/hive/HiveModalTabs';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeHiveDisposeAction, makeHiveFetchDetailThunk } from '@redux/HiveDetails/actions';
import { useHiveDetailsData } from '@redux/HiveDetails/hooks';

export const HiveModalContent: React.VFC = () => {
  const { id, isFocused } = useHiveModalInnerContext();
  const { openedHiveModals } = useHiveModalOpener();
  const { detail } = useHiveDetailsData();
  const dispatch = useDispatch();
  const t = useTranslation();

  const statusActionId = detail?.latestStatusAction;

  const disposeHiveData = useCallback(() => {
    // Several modals of the same hive can be stacked,
    // so we only dispose its data in case only one
    // is currently open.
    const modalsWithSameHiveId = openedHiveModals.filter((sId) => sId === id);
    if (modalsWithSameHiveId.length === 1) {
      dispatch(makeHiveDisposeAction(id));
    }
  }, [dispatch, id, openedHiveModals]);

  useEffect(() => {
    if (isFocused) {
      disposeHiveData();
      dispatch(makeHiveFetchDetailThunk(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, isFocused]);

  useEffect(() => {
    return () => {
      disposeHiveData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <>
      <ModalHeader
        title={
          <Box alignItems={'center'} gap_100>
            {detail ? `${capitalize(t('hive'))} ${detail.hiveName}` : ''}

            {statusActionId ? <HiveStatusChip statusActionId={statusActionId} /> : null}
          </Box>
        }
      />

      {detail ? <HiveModalTabs /> : null}

      <HiveModalMainLoading />
    </>
  );
};
