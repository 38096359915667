import { ColDef } from 'ag-grid-community';

/**
 * Grid related types.
 * */

export type ColDefWithMetadata = ColDef & { metadata: GridColumnMetadata };

export interface GridColumnMetadata {
  groupId: string;
  groupName: string;
  column: Column;
  activeView?: ViewKey;
}

/**
 * Data related types.
 * */

export interface YardsListResponse {
  pagination: Pagination;
  columns: Record<ColumnKey, Column>;
  rows: Array<YardRow>;
}

export interface Pagination {
  count: number;
  limit: number;
  offset: number;
}

export type Column = {
  key: ColumnKey;
  views: Record<ViewKey, View>;
} & (
  | {
      type: ColumnType.DEFAULT;
    }
  | {
      type: ColumnType.PRACTICE;
      practice: Practice;
    }
  | {
      type: ColumnType.PRACTICE_CATEGORY;
      practiceCategory: PracticeCategory;
    }
);
export type ColumnKey = string;

export enum ColumnType {
  DEFAULT = 'default',
  PRACTICE = 'practice',
  PRACTICE_CATEGORY = 'practice_category',
}

export interface View {
  key: ViewKey;
  hasTooltip: boolean;
  sortableKey: string;
}

export type ViewKey =
  | 'default'
  | 'occurrencesSinceSeasonStart'
  | 'lastOccurrenceValue'
  | 'lastOccurrenceDatetime'
  | 'lastVisitAffectedHives'
  | 'periodSinceLastOccurrence'
  | 'workerLastOccurrence'
  | 'averageLastThirtyDays'
  | 'sumSinceSeasonStart'
  | 'averageOfLastVisit'
  | 'sumOfLastVisit'
  | string;

export interface Practice extends PracticeCategory {
  categoryId: number;
  categoryName: string;
}

export interface PracticeCategory {
  id: number;
  flag: string;
  name: string;
}

export interface YardRow {
  meta: {
    id: number;
    name: string;
    groups: Array<BeeGroup>;
    isActive: boolean;
    contractId: number;
    contractName: string;
  };
  data: Record<ColumnKey, RowColumnData>;
}

export type YardTooltipRow = YardRow;

export interface RowColumnData {
  views: Record<ViewKey, RowViewValue>;
}

export interface RowViewValue {
  data: any;
}
