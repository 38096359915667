import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';

import appleBadge from '@assets/Apple_App_Store_Badge.svg';
import googleBadge from '@assets/Google_Play_Badge.png';
import logo from '@assets/nectar-logo.svg';
import { BackgroundImage } from '@components/deprecated/BackgroundImage/BackgroundImage';
import {
  Container,
  LoginContainer as _LoginContainer,
  StyledLogoImg,
} from '@components/deprecated/Elements/AuthElements';
import APP from '@config/constants';
import { withGetScreen } from '@HOC/withGetScreen';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { AppStoreLayout, StoreStyledHeader as _StoreStyledHeader } from '@scenes/public/AppStore/styles';

const LoginContainer = _LoginContainer as any;
const StoreStyledHeader = _StoreStyledHeader as any;

const AppStore: React.VFC = () => {
  const { isMobile } = useGetScreenWidth();

  if (isIOS) {
    window.location.assign(APP.APPLE_APP_STORE_LINK);
  } else if (isAndroid) {
    window.location.assign(APP.GOOGLE_PLAY_STORE_LINK);
  }

  return (
    <Container>
      <LoginContainer isMobile={isMobile}>
        <StoreStyledHeader isMobile={isMobile}>
          <StyledLogoImg src={logo} className="" alt="nectar logo" />
        </StoreStyledHeader>
        <AppStoreLayout>
          <a href={APP.APPLE_APP_STORE_LINK}>
            <img alt="Available on the App Store" src={appleBadge} height="100" />
          </a>
          <a href={APP.GOOGLE_PLAY_STORE_LINK}>
            <img alt="Get it on Google Play" src={googleBadge} height="100" width="301" />
          </a>
        </AppStoreLayout>
      </LoginContainer>
      {!isMobile && <BackgroundImage />}
    </Container>
  );
};

AppStore.propTypes = {
  isMobile: PropTypes.func.isRequired,
};

export default withGetScreen()(AppStore);
