import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Support = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66663 2C1.56206 2 0.666626 2.89543 0.666626 4V10.6667C0.666626 11.7712 1.56206 12.6667 2.66663 12.6667H6.26663L9.99996 15.3333V12.6667H13.3333C14.4379 12.6667 15.3333 11.7712 15.3333 10.6667V4C15.3333 2.89543 14.4379 2 13.3333 2H2.66663ZM2.66663 4.66667C2.66663 4.29848 2.9651 4 3.33329 4H12.6666C13.0348 4 13.3333 4.29848 13.3333 4.66667C13.3333 5.03486 13.0348 5.33333 12.6666 5.33333H3.33329C2.9651 5.33333 2.66663 5.03486 2.66663 4.66667ZM2.66663 7.33333C2.66663 6.96514 2.9651 6.66667 3.33329 6.66667H12.6666C13.0348 6.66667 13.3333 6.96514 13.3333 7.33333C13.3333 7.70152 13.0348 8 12.6666 8H3.33329C2.9651 8 2.66663 7.70152 2.66663 7.33333ZM3.33329 9.33333C2.9651 9.33333 2.66663 9.63181 2.66663 10C2.66663 10.3682 2.9651 10.6667 3.33329 10.6667H6.66663C7.03482 10.6667 7.33329 10.3682 7.33329 10C7.33329 9.63181 7.03482 9.33333 6.66663 9.33333H3.33329Z"
      />
    </IconSVGView>
  );
};
