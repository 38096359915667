import { SnackbarAction, SnackbarActionType, SnackbarOptions } from '@redux/Snackbar/types';

export function makeShowSnackbarAction(messageTranslation: string): SnackbarAction;
export function makeShowSnackbarAction(options: SnackbarOptions): SnackbarAction;
export function makeShowSnackbarAction(optionsOrMessageTranslations: SnackbarOptions | string): SnackbarAction {
  return {
    type: SnackbarActionType.SNACKBAR_SHOW,
    payload:
      typeof optionsOrMessageTranslations === 'string'
        ? {
            messageTranslation: optionsOrMessageTranslations,
          }
        : optionsOrMessageTranslations,
  };
}
