import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Page404 } from '@components/common/Page404';

export const YardDetails404: React.VFC = () => {
  const hasError = useSelector((state) => state.yardDetailsReducer.hasError);

  return hasError ? (
    <Box fit center marginVertical_200 paddingVertical_200>
      <Page404 />
    </Box>
  ) : null;
};
