import React from 'react';
import styled from 'styled-components';

import { InfoCircle } from '@components/common/Icon/presets/InfoCircle';

const StyledWrapper = styled.div(
  ({ theme }) => `
        padding: 14px 12px;
        display: grid;
        grid-template-columns: 24px auto;
        grid-gap: 12px;

        // warning
        border-radius: ${theme.shape.paperRadius01}px;
        background-color: ${theme.colors.surfaceWarningLight};
        border: 1px solid ${theme.colors.borderWarning};

        svg {
          color: ${theme.colors.surfaceAccent};
        }
    `
);

// could make this for warning, info, error...
type MessageType = 'warning';

export const MessageBox: React.FC<{ type?: MessageType }> = ({ children, type }) => {
  return (
    <StyledWrapper>
      <div>
        <InfoCircle size={24} />
      </div>
      <div>{children}</div>
    </StyledWrapper>
  );
};
