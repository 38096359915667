import React, { useContext, useEffect, useState } from 'react';

import { DataStatusTabDotProps } from '@components/common/DataStatusTabDot/types';
import { TabsContext } from '@components/common/Tabs/context';
import { DataStatusType } from '@redux/DataStatus/types';

import { StyledStatusDot } from './styles';

const AUTO_HIDE_DELAY = 8000;

export const DataStatusTabDot: React.VFC<DataStatusTabDotProps> = ({ tabKey, dataStatus }) => {
  const tabs = useContext(TabsContext);
  const [isDotVisible, setDotVisible] = useState(false);

  const isTabActive = tabs?.activeTab === tabKey;
  const isOutdated = dataStatus.status?.type === DataStatusType.DATA_OUTDATED;
  const shouldShowDot = !isTabActive && isOutdated;

  useEffect(() => {
    if (shouldShowDot) {
      setDotVisible(true);
    } else {
      const timeout = setTimeout(() => {
        setDotVisible(false);
      }, AUTO_HIDE_DELAY);
      return () => clearTimeout(timeout);
    }
  }, [shouldShowDot]);

  // Changes the tab icon to show a notification dot.
  // Note: it might not work for all browsers.
  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel='icon'][href$='.ico']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    if (isOutdated) {
      link.href = '/favicon_notification.ico';
    } else {
      link.href = '/favicon.ico';
    }
  }, [isOutdated]);

  return <StyledStatusDot $isVisible={isDotVisible}></StyledStatusDot>;
};
