import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Color } from 'styled-components';

import { Box } from '@components/common/Box';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { useColor } from '@style/theme/hooks';

export interface StatusProps {
  data: {
    new: boolean;
    status: 'active' | 'inactive' | 'unloaded' | 'emptied';
    requiringAttention: boolean;
  } | null;
}

export const Status: React.VFC<StatusProps> = ({ data }) => {
  const { t } = useTranslation();

  const [statuses, colors] = useMemo(() => {
    const statuses: Array<string> = [];
    const colors: Array<Color> = [];

    if (data?.new) {
      statuses.push(t('new'));
      colors.push('focus02');
    }
    if (data?.status) {
      statuses.push(t(data.status));
      colors.push(data.status === 'active' ? 'green02' : data.status === 'inactive' ? 'grey06' : 'yellow02');
    }

    return [statuses, colors];
  }, [data?.new, data?.status, t]);

  const [firstColorKey, secondColorKey] = colors;
  const firstColor = useColor(firstColorKey);
  const secondColor = useColor(secondColorKey);
  const hasOnlyOneColor = !secondColorKey;

  return (
    <DefaultCellWrapper>
      <Box marginRight_050>
        <svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask" style={{ maskType: 'alpha' }} width="8" height="8">
            <rect width="8" height="8" rx="2" fill="#000000" />
          </mask>
          <g mask="url(#mask)">
            {hasOnlyOneColor ? (
              <rect width="8" height="8" fill={firstColor} />
            ) : (
              <>
                <path d="M 0 0 H 7.25 L 0 7.25 Z" fill={firstColor} />
                <path d="M 0.75 8 L 8 0.75 V 8 Z" fill={secondColor} />
              </>
            )}
          </g>
        </svg>
      </Box>
      <DefaultCellText>{statuses.join(', ')}</DefaultCellText>
    </DefaultCellWrapper>
  );
};
