import styled, { css, keyframes, PrefixedStyledProps } from 'styled-components';

import { LoadingProps } from '@components/common/Loading/index';

const RotateAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const StyledLoading = styled.div<{ size: string }>(
  ({ theme, size }) => css`
    position: absolute;

    top: 50%;
    left: 50%;

    width: ${size};
    height: ${size};

    border-radius: 50%;
    border: 2px solid transparent;

    animation: ${RotateAnimation} 1s ${theme.animations.easing} infinite;
  `
);

export const StyledLoadingWrapper = styled.div<PrefixedStyledProps<LoadingProps>>(
  ({
    theme,
    $visible,
    $whiteLoader,
    $blurBackground,
    $whiteBackground,
    $defaultBackground,
    $delay,
    $roundedCorners,
  }) => `
  pointer-events: none;
  position: absolute;
  inset: 0;

  transition: ${
    // It makes it faster to appear than disappear, which fixes some
    // glitches with modals that must appear already loading.
    $visible ? theme.animations.transitionFast('opacity') : theme.animations.transitionMedium('opacity')
  } ${$delay ?? 0}ms;
  opacity: ${[undefined, true].includes($visible) ? 1 : 0};

  z-index: ${theme.getZIndexOf('loading')};
  background-color: ${
    $defaultBackground
      ? theme.primitives.colors.background
      : $blurBackground
      ? `rgba(255, 255, 255, 0.5)`
      : $whiteBackground
      ? theme.primitives.colors.white
      : 'none'
  };
  border-radius: ${$roundedCorners ? theme.shape.paperRadius03 : theme.shape.paperRadius01}px;

  ${StyledLoading} {
    ${
      $whiteLoader
        ? `
            border-top-color: ${theme.primitives.colors.white};
            border-bottom-color: ${theme.primitives.colors.white};
          `
        : `
            border-top-color: ${theme.primitives.colors.grey07};
            border-bottom-color: ${theme.primitives.colors.grey07};
          `
    }
  }
`
);
