import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';

export const StyledAlertCard = styled(Box)<{ $sticky?: boolean }>(
  ({ theme, $sticky }) => css`
    gap: 12px;
    z-index: ${theme.getZIndexOf('stickyAlert')};

    ${$sticky &&
    css`
      position: sticky;
      top: 0;
    `}
  `
);

export const StyledAlertCloseButton = styled(Button).attrs({
  unstyled: true,
})`
  line-height: 0; // In order to fix the vertical alignment.
`;

export const StyledAlertIconWrapper = styled(Box)(
  () => css`
    width: 16px;
    height: 16px;
    border-radius: 50%;
  `
);

export const StyledAlertDivider = styled(Box)(
  () => css`
    width: 1px;
    height: 16px;
  `
);
