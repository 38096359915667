import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { Api } from '@helpers/Api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

/**************** BATCH UPDATE YARDS ****************/

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeBatchUpdateYardsRequestStartAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{yard: object}}
 *
 */
export const makeBatchUpdateYardsRequestSuccessAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeBatchUpdateYardsRequestFailureAction = () => ({
  type: types.BATCH_UPDATE_YARDS_ACTION_FAILURE,
});

export const makeBatchUpdateYardsRequestThunk = (detail, user) => {
  detail.patch = detail.patch.filter((yard) => !detail.remove.includes(yard.id));

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeBatchUpdateYardsRequestStartAction());

    return new Promise((accept, reject) => {
      Api.post(ENDPOINTS.yardsBatch, detail)
        .then(parseRawResponseToJson, parseRejectedToJson)
        .then((jsonObj = {}) => {
          if ('error' in jsonObj || 'errors' in jsonObj) {
            dispatch(makeBatchUpdateYardsRequestFailureAction());
            reject();
          } else {
            const { add, patch, remove } = detail;
            const isAddingOneYard = add.length === 1 && !patch.length && !remove.length;
            const isReassigningHives = jsonObj.are_hives_moving_yard;

            if (isAddingOneYard) {
              dispatch(
                makeShowSnackbarAction({
                  messageTranslation: 'new_yard_created_successfully',
                  actionLinkToTranslation: 'view',
                  actionLinkToPath: URLUtil.buildPagePath(APP.routes.whiteboardYardsDetailDashboard, {
                    pathParams: { uid: jsonObj.add[0].id },
                  }),
                })
              );
            } else if (isReassigningHives) {
              dispatch(makeShowSnackbarAction({ messageTranslation: 'changes_saved_update_delay' }));
            } else {
              dispatch(makeShowSnackbarAction({ messageTranslation: 'changes_saved' }));
            }

            dispatch(makeBatchUpdateYardsRequestSuccessAction());
            accept();
          }
        });
    });
  };
};
