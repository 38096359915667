import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { BREAKPOINT_RANGES } from '@style/constants';

function generateScreenWidthBooleans() {
  return {
    isMobile: window.matchMedia(`(max-width: ${BREAKPOINT_RANGES.mobile[1]})`).matches,
    isTablet: window.matchMedia(
      `(min-width: ${BREAKPOINT_RANGES.tablet[0]}) and (max-width: ${BREAKPOINT_RANGES.tablet[1]})`
    ).matches,
    isTabletOrSmaller: window.matchMedia(`(max-width: ${BREAKPOINT_RANGES.tablet[1]})`).matches,
    isDesktop: window.matchMedia(`(min-width: ${BREAKPOINT_RANGES.desktopSM[0]})`).matches,
  };
}

/**
 *
 * @todo refactor to only update when screen resizes crosses a threshold
 */
export function useGetScreenWidth() {
  const [screenWidthBooleans, setScreenWidthBooleans] = useState(generateScreenWidthBooleans);

  useEffect(() => {
    const handleResize = debounce(() => setScreenWidthBooleans(generateScreenWidthBooleans()), 150);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenWidthBooleans;
}
