import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipProps } from '@components/common/Tooltip/types';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { usePractices } from '@redux/Practices/hooks';

import { ActionTooltipContainer, ActionTooltipSection } from './styles';

const ACTIONS_PREVIEW_SIZE = 2;
const YEAR_START_DATEFORMAT = 'MMM D, YYYY';

export interface TooltipYardActionProps extends Pick<TooltipProps, 'placement'> {
  icon: ReactNode;
  title: string;
  actions: BeeYardExecutedAction[];
  seasonStartBased?: boolean;
}

export const TooltipYardAction: React.VFC<TooltipYardActionProps> = ({
  title,
  icon,
  actions,
  placement,
  seasonStartBased,
}) => {
  const { t } = useTranslation();

  const ACTION_DATEFORMAT = seasonStartBased ? 'MMM D YYYY' : `MMM D, YYYY [${t('at_time')}] HH:mm`;
  const yearStartDate = moment().startOf('year').format(YEAR_START_DATEFORMAT);

  const { getActionName } = usePractices();
  const actionSafe = useMemo<BeeYardExecutedAction[]>(() => CaseAdapter.objectToCamelCase(actions || []), [actions]);

  const visibleCount = Math.min(actionSafe.length, ACTIONS_PREVIEW_SIZE);
  const hiddenCount = actionSafe.length - visibleCount;
  const actionSliced = actionSafe.slice(0, visibleCount);

  return (
    <Tooltip placement={placement}>
      <ActionTooltipContainer column stretch>
        {seasonStartBased && (
          <Text typography={'CaptionSmall'}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('over_the_season', {
                  action: title,
                  date: yearStartDate,
                }),
              }}
            />
          </Text>
        )}

        {actionSliced.map((executedAction, idx) => {
          const datesString = executedAction.dates
            .map((date: any) => moment(date).format(ACTION_DATEFORMAT))
            .join(', ');
          return (
            <Box key={idx} column stretch paddingTop_050={idx > 0}>
              <ActionTooltipSection alignItems={'center'} padding_050>
                <Box marginRight_025>{icon}</Box>
                <Text
                  typography={'CaptionSmall'}
                  dangerouslySetInnerHTML={{ __html: getActionName(executedAction.actionId, '-') }}
                />
              </ActionTooltipSection>
              <Box block paddingTop_050>
                <Text typography={'CaptionSmall'}>{datesString}</Text>
              </Box>
            </Box>
          );
        })}
        {Boolean(hiddenCount) && (
          <Text typography={'CaptionSmall'}>
            + {hiddenCount} {t('more')}
          </Text>
        )}
      </ActionTooltipContainer>
    </Tooltip>
  );
};
