import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledSwipeArea = styled(Box)`
  &.disable-pointer-events {
    cursor: grabbing;
    & * {
      pointer-events: none;
    }
  }
`;
