import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { ArrowDown } from '@components/common/Icon/presets/ArrowDown';
import { Collapse } from '@components/common/Icon/presets/Collapse';
import { GroupPage } from '@components/common/Icon/presets/GroupPage';
import { HiveLost } from '@components/common/Icon/presets/HiveLost';
import { Inspection } from '@components/common/Icon/presets/Inspection';
import { Logo } from '@components/common/Icon/presets/Logo';
import { Operation } from '@components/common/Icon/presets/Operation';
import { Pollination } from '@components/common/Icon/presets/Pollination';
import { Support } from '@components/common/Icon/presets/Support';
import { Yard } from '@components/common/Icon/presets/Yard';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipProps } from '@components/common/Tooltip/types';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { SideNavMenu } from '@components/layout/SideNav/SideNavMenu';
import config from '@config/config';
import APP from '@config/constants';
import { useIntercomOnUnreadMessages, useIsIntercomEnabled } from '@helpers/Intercom/hooks';
import { useTranslation } from '@hooks/useTranslation';

import { useLayoutContext } from '../context/hooks';

import { HelpModal } from './HelpModal';
import {
  StyledCollapseButton,
  StyledContentOverlay,
  StyledFixedContent,
  StyledFooter,
  StyledFooterBox,
  StyledFooterOperationIcon,
  StyledFooterText,
  StyledFooterTextWrap,
  StyledLogoWrap,
  StyledNavButton,
  StyledNavLink,
  StyledNavSeparator,
  StyledNavText,
  StyledNavWrapper,
  StyledSupportChip,
  StyledSVGWrap,
} from './styles';

const NavItemTooltip: React.FC<Omit<TooltipProps, 'placement'>> = ({ offset, ...tooltipProps }) => {
  return (
    <ResponsiveRender from="desktopSM">
      <Tooltip placement="right" offset={offset ?? [0, 24]} {...tooltipProps} />
    </ResponsiveRender>
  );
};

export const SideNav: React.VFC<any> = () => {
  const { toggleSideNav, isSideNavExpanded: expanded, collapseSideNav } = useLayoutContext();
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const t = useTranslation();
  const { user } = useSelector((state) => state.authReducer);
  const { operation } = useSelector((state) => state.operationReducer);

  const openHelpModal = useCallback(() => setHelpModalOpen(true), []);
  const closeHelpModal = useCallback(() => setHelpModalOpen(false), []);

  const isIntercomEnabled = useIsIntercomEnabled();
  const unreadMessages = useIntercomOnUnreadMessages();

  return (
    <>
      <div>
        <StyledNavWrapper $expanded={expanded}>
          <StyledFixedContent $expanded={expanded}>
            <header>
              <Box padding_100 marginBottom_150 justifyContent={'space-between'} alignItems="center">
                <StyledLogoWrap to={APP.routes.whiteboardYardsList} $expanded={expanded}>
                  <Logo width={95} />
                </StyledLogoWrap>

                <StyledCollapseButton
                  $expanded={expanded}
                  onClick={toggleSideNav}
                  id="nav-item-expand"
                  aria-labelledby="nav-tooltip-expand"
                >
                  <Collapse size={24} />
                </StyledCollapseButton>

                <NavItemTooltip target="nav-item-expand" light>
                  <Text typography={'CaptionSmall'} id="nav-tooltip-expand">
                    {t(expanded ? 'collapse' : 'expand')}
                  </Text>
                </NavItemTooltip>
              </Box>
            </header>
            <nav>
              <StyledNavLink to={APP.routes.whiteboard} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-whiteboard">
                  <Yard />
                </StyledSVGWrap>
                <StyledNavText>{t('whiteboard')}</StyledNavText>
              </StyledNavLink>

              <StyledNavSeparator />

              <StyledNavLink to={APP.routes.pollination} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-pollination">
                  <Pollination />
                </StyledSVGWrap>
                <StyledNavText>{t('pollination')}</StyledNavText>
              </StyledNavLink>

              <StyledNavLink to={APP.routes.groups} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-groups">
                  <GroupPage />
                </StyledSVGWrap>
                <StyledNavText>{t('groups')}</StyledNavText>
              </StyledNavLink>

              <StyledNavLink to={APP.routes.lostHives} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-lostHives">
                  <HiveLost />
                </StyledSVGWrap>
                <StyledNavText>{t('lost_hives')}</StyledNavText>
              </StyledNavLink>

              <StyledNavSeparator />

              <StyledNavLink to={APP.routes.operation} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-operation">
                  <Operation />
                </StyledSVGWrap>
                <StyledNavText>{t('operation')}</StyledNavText>
              </StyledNavLink>

              <StyledNavLink to={APP.routes.reports} $expanded={expanded}>
                <StyledSVGWrap id="nav-item-reports">
                  <Inspection />
                </StyledSVGWrap>
                <StyledNavText>{t('reports')}</StyledNavText>
              </StyledNavLink>

              <StyledNavButton
                id="intelcom-launcher"
                $expanded={expanded}
                onClick={isIntercomEnabled ? undefined : openHelpModal}
              >
                <StyledSVGWrap id="nav-item-support">
                  <Support />
                </StyledSVGWrap>
                <StyledNavText>
                  <Box relative alignItems={'center'}>
                    {t('support')}
                    <ContentChangeTransition content={unreadMessages.count}>
                      {(count) => <StyledSupportChip>{count}</StyledSupportChip>}
                    </ContentChangeTransition>
                  </Box>
                </StyledNavText>
              </StyledNavButton>

              <NavItemTooltip target="nav-item-whiteboard" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('whiteboard')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-pollination" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('pollination')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-groups" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('groups')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-lostHives" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('lost_hives')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-operation" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('operation')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-reports" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('reports')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-settings" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('settings')}</Text>
              </NavItemTooltip>
              <NavItemTooltip target="nav-item-support" disabled={expanded}>
                <Text typography={'CaptionSmall'}>{t('support')}</Text>
              </NavItemTooltip>
            </nav>

            <StyledFooter $expanded={expanded}>
              <Box column stretch padding_100 gap_100>
                <Text typography="CaptionSmall" color="grey06">
                  {config.VERSION}
                </Text>
                <StyledFooterBox>
                  <Box flex={1} alignItems="center">
                    <StyledFooterOperationIcon id="side-nav-operation-icon" $hasTooltip={!expanded} />
                    <NavItemTooltip
                      target="side-nav-operation-icon"
                      disabled={expanded}
                      offset={useMemo(() => [0, 32], [])}
                    >
                      <Text typography={'TooltipSmall'}>
                        {operation?.name ?? '-'} <br />
                        {user?.email ?? '-'}
                      </Text>
                    </NavItemTooltip>

                    <StyledFooterTextWrap>
                      <StyledFooterText typography="SmallParagraph" lineHeight="24px">
                        {operation?.name ?? '-'}
                      </StyledFooterText>
                      <StyledFooterText typography="CaptionSmall" color="grey06">
                        {user?.fullName ?? '-'}
                      </StyledFooterText>
                    </StyledFooterTextWrap>
                    <ArrowDown color={'contentTertiary'} />
                  </Box>

                  <SideNavMenu expanded={expanded} />
                </StyledFooterBox>
              </Box>
            </StyledFooter>
          </StyledFixedContent>
          <ResponsiveRender until="tablet">
            <StyledContentOverlay $expanded={expanded} onClick={collapseSideNav} />
          </ResponsiveRender>
        </StyledNavWrapper>
      </div>

      <HelpModal isOpen={helpModalOpen} onRequestClose={closeHelpModal} />
    </>
  );
};
