import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { BulkActionsProgress } from '@components/common/BulkActionsProgress';
import { useBulkActionsProgressState } from '@components/common/BulkActionsProgress/hooks';
import { ActionStatus } from '@components/common/BulkActionsProgress/types';
import { Button } from '@components/common/CTA';
import { Loading } from '@components/common/Loading';
import { ModalFooter, ModalHeader } from '@components/common/ModalBase';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useIntercomArticleOpener } from '@helpers/Intercom/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeBulkClearOutYardsThunk } from '@redux/BulkClearOutYards/actions';
import { makeFetchActiveDropsThunk } from '@redux/Contract/actions';

enum ActionIndex {
  CLEARING_OUT = 0,
  SECOND_ACTION = 1,
}

export const ClearOutDropsAndAction: React.VFC<{
  contractId: number;
  closeModal: () => void;
  onSuccess: () => void;
  activeDrops: BeeDrop[];
  secondActionProps: {
    dispatchAction: () => Promise<any>;
    acceptButtonText: string;
    progressTitle: string;
    warningText: string;
    subtitle: React.ReactNode;
  };
}> = ({ contractId, closeModal, onSuccess, activeDrops, secondActionProps }) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const activeDropsLength = activeDrops?.length;
  const modalHeaderTitle = activeDropsLength === 1 ? 'clear_out_1_drop' : 'clear_out_x_drops';
  const activeDropIds = useMemo(() => {
    if (!activeDrops) return [];
    return activeDrops.map((drop) => drop.id);
  }, [activeDrops]);

  const showLoader = useMemo(() => !activeDrops, [activeDrops]);

  const [showRequestsProgress, setShowRequestsProgress] = useState(false);
  const [clearingOutStatus, setClearingOutStatus] = useState<ActionStatus>('idle');
  const [secondActionStatus, setSecondActionStatus] = useState<ActionStatus>('idle');
  const [requestFailed, setRequestFailed] = useState(false);

  const acceptButtonText = useMemo(() => {
    if (requestFailed) {
      return t('try_again');
    } else if (secondActionStatus === 'success') {
      return t('done');
    } else {
      return secondActionProps.acceptButtonText;
    }
  }, [requestFailed, secondActionStatus, t, secondActionProps.acceptButtonText]);

  const disableButtons = useMemo(
    () => clearingOutStatus === 'loading' || secondActionStatus === 'loading',
    [secondActionStatus, clearingOutStatus]
  );

  const [clearOutBulkActions, setClearOutBulkActions] = useBulkActionsProgressState([
    {
      title: t('clearing_out'),
      status: 'idle',
    },
    {
      title: secondActionProps.progressTitle,
      status: 'idle',
    },
  ]);

  const updateClearingOutStatus = useCallback(
    (status: ActionStatus) => {
      setClearingOutStatus(status);
      setClearOutBulkActions(ActionIndex.CLEARING_OUT, { status });
    },
    [setClearOutBulkActions]
  );
  const updateSecondActionStatus = useCallback(
    (status: ActionStatus) => {
      setSecondActionStatus(status);
      setClearOutBulkActions(ActionIndex.SECOND_ACTION, { status });
    },
    [setClearOutBulkActions]
  );

  const performSecondAction = useCallback(async () => {
    updateSecondActionStatus('loading');

    try {
      await secondActionProps.dispatchAction();
      updateSecondActionStatus('success');
    } catch (_) {
      updateSecondActionStatus('fail');
      setRequestFailed(true);
    }
  }, [secondActionProps, updateSecondActionStatus]);

  const performClearOut = useCallback(async () => {
    if (!activeDrops) {
      return;
    }
    updateClearingOutStatus('loading');

    try {
      await dispatch(makeBulkClearOutYardsThunk(activeDropIds));
      Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_CONTRACT_CLEAR_OUT });
      updateClearingOutStatus('success');
      performSecondAction();
    } catch (_) {
      updateClearingOutStatus('fail');
      setRequestFailed(true);
    }
  }, [activeDropIds, activeDrops, dispatch, performSecondAction, updateClearingOutStatus]);

  const onAcceptClick = useCallback(() => {
    if (!showRequestsProgress) setShowRequestsProgress(true);

    if (clearingOutStatus === 'idle' || clearingOutStatus === 'fail') {
      performClearOut();
    } else if (clearingOutStatus === 'success' && secondActionStatus === 'fail') {
      performSecondAction();
    } else if (secondActionStatus === 'success') {
      onSuccess();
    }
  }, [showRequestsProgress, clearingOutStatus, secondActionStatus, performClearOut, performSecondAction, onSuccess]);

  useEffect(() => {
    updateClearingOutStatus('idle');
    updateSecondActionStatus('idle');
    setRequestFailed(false);
    setShowRequestsProgress(false);
    dispatch(makeFetchActiveDropsThunk(contractId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClearOutArticle = useIntercomArticleOpener(APP.intercomArticles.CLEAR_OUT_YARDS);

  return (
    <>
      {showLoader ? (
        <ModalContent>
          <ModalHeader />
          <Loading whiteBackground />
          <ModalFooter />
        </ModalContent>
      ) : (
        <>
          <ModalHeader
            suppressCloseButton
            title={t(modalHeaderTitle, { count: activeDropsLength })}
            subtitle={secondActionProps.subtitle}
            linkText={t('clear_out_contract_link')}
            onLinkClick={openClearOutArticle}
            alert={<AlertCard warning>{secondActionProps.warningText}</AlertCard>}
          />

          {showRequestsProgress && (
            <ModalContent>
              <BulkActionsProgress actions={clearOutBulkActions} visible />
            </ModalContent>
          )}

          <ModalFooter>
            <Box fit gap_100 justifyContent="flex-end">
              {secondActionStatus !== 'success' && (
                <Button tertiary onClick={closeModal} disabled={disableButtons}>
                  {t('cancel')}
                </Button>
              )}

              <Button primary onClick={onAcceptClick} disabled={disableButtons}>
                {acceptButtonText}
              </Button>
            </Box>
          </ModalFooter>
        </>
      )}
    </>
  );
};
