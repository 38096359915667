/**
 * Media query helper functions
 * 
 * Used to make media queries easier to write and read within styled components
 * 
 * @example 
    const StyledBox = styled.div`
      ${from('desktopSM')} {
        margin-bottom: 2rem;
        background-color: orange;
      }
 *  `
 */

import { BreakPoint, BREAKPOINT_RANGES } from './constants';

export function from(bp: BreakPoint) {
  const [from] = BREAKPOINT_RANGES[bp];
  return `@media (min-width: ${from})`;
}

/**
 *
 * Until is inclusive. until(tablet) includes tablet
 */
export function until(bp: BreakPoint) {
  const [, until] = BREAKPOINT_RANGES[bp];
  return `@media (max-width: ${until})`;
}

/**
 *
 * Since until is inclusive, to limit the scope to tablet for example, use
 * between('tablet', 'tablet')
 *
 * There's no need to pass the largest breakpoint to untilBp, just use from()
 */
export function between(fromBp: BreakPoint, untilBp: Exclude<BreakPoint, 'desktopLG'>) {
  const [from] = BREAKPOINT_RANGES[fromBp];
  const [, until] = BREAKPOINT_RANGES[untilBp];
  return `@media (min-width: ${from}) and (max-width: ${until})`;
}
