import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGlobalProperty } from '@helpers/Globals/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeAuthFetchUserThunk } from '@redux/Auth/actions';
import { makeFetchOperationThunk } from '@redux/Operation/actions';
import { makeRootDisposeStateAction } from '@redux/Root/actions';

export function useAuthInitialChecks() {
  const dispatch = useDispatch();
  const operationId = useGlobalProperty('operationId');

  useEffect(() => {
    (async () => {
      try {
        await dispatch(makeRootDisposeStateAction());
        await dispatch(makeAuthFetchUserThunk());
        await dispatch(makeFetchOperationThunk());
      } catch (_) {
        // Ignore and just keep the user logged out.
      }
    })();
  }, [dispatch, operationId]);
}

export function useEnsuredUser() {
  const user = useSelector((state) => state.authReducer.user);

  if (!user) {
    throw new Error('User info is not loaded yet. Make sure to load it before calling this hook.');
  }

  return user;
}
