import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColDef } from 'ag-grid-community';

import { useGridApi } from '@components/common/AgGrid';
import { useDynamicTableDataCycle } from '@components/common/DynamicTable/hooks';
import { Loading } from '@components/common/Loading';
import { DEF_VISIBLE_COLUMNS } from '@components/yard/YardsList/constants';
import { YardsListSaveViewContext } from '@components/yard/YardsList/context/context';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makePatchOperationThunk } from '@redux/Operation/actions';

export const YardsListSaveViewProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const operation = useSelector((state) => state.operationReducer.operation);
  const { gridApi } = useGridApi();

  const [savedColumnsDefs, setSavedColumnsDefs] = useState<Array<ColDef> | null>(null);
  const [currentColumnsDefs, setCurrentColumnsDefs] = useState<Array<ColDef> | null>(null);

  const hasUnsavedViewChanges = useMemo(() => {
    if (currentColumnsDefs && savedColumnsDefs) {
      return !GridApiUtil.areColumnDefsEqual(savedColumnsDefs, currentColumnsDefs);
    }
    return false;
  }, [currentColumnsDefs, savedColumnsDefs]);

  const saveCurrentViewToAPI = useCallback(() => {
    const whiteboardState = { columnsDefs: GridApiUtil.getFlattenColumnDefs(gridApi?.getColumnDefs()) };
    dispatch(makePatchOperationThunk({ preferences: { whiteboardState } }, 'changes_have_been_saved'));
    setSavedColumnsDefs(whiteboardState.columnsDefs);
  }, [dispatch, gridApi]);

  const loadInitialView = useCallback(() => {
    if (!gridApi) {
      return;
    }

    let restoredColumnsFromSaveView = operation?.preferences?.whiteboardState?.columnsDefs;

    // These props should be ignored if coming from the Backend
    // saved state, since they are not controlled by the user.
    const nonEditableProps: Array<keyof ColDef> = ['resizable', 'minWidth', 'maxWidth'];

    if (restoredColumnsFromSaveView) {
      restoredColumnsFromSaveView = restoredColumnsFromSaveView.map(({ ...remoteColDef }: ColDef) => {
        const localColDef = DEF_VISIBLE_COLUMNS.find((colDef) => colDef.field === remoteColDef.field);
        localColDef &&
          nonEditableProps.forEach((prop) => {
            (remoteColDef as any)[prop] = (localColDef as any)[prop];
          });
        return remoteColDef;
      });
    }

    const columnsToLoad = (restoredColumnsFromSaveView || DEF_VISIBLE_COLUMNS).map(({ hide, ...props }: ColDef) => ({
      hide: !!hide,
      ...props,
    }));
    GridApiUtil.updateGridColumns(gridApi, columnsToLoad);
    gridApi.refreshInfiniteCache();
  }, [gridApi, operation?.preferences?.whiteboardState?.columnsDefs]);

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      setSavedColumnsDefs(gridApi?.getColumnDefs() ?? []);
    }, [gridApi]),
  });

  /**
   * Effect responsible for restoring the saved view in
   * the first whiteboard load.
   * */
  useEffect(() => {
    loadInitialView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  /**
   * Binding grid events which trigger the save view button.
   * */
  useEffect(() => {
    const eventsToWatch = ['columnResized', 'displayedColumnsChanged'];
    const handler = () => {
      setCurrentColumnsDefs(gridApi?.getColumnDefs() ?? []);
    };
    eventsToWatch.forEach((ev) => gridApi?.addEventListener(ev, handler));
    return () => eventsToWatch.forEach((ev) => gridApi?.removeEventListener(ev, handler));
  }, [gridApi]);

  const context = useMemo(
    () => ({
      hasPendingChange: hasUnsavedViewChanges,
      patchViewState: saveCurrentViewToAPI,
      discardViewState: loadInitialView,
    }),
    [hasUnsavedViewChanges, loadInitialView, saveCurrentViewToAPI]
  );

  return (
    <YardsListSaveViewContext.Provider value={context}>
      {operation ? children : null}
      <Loading visible={!operation} />
    </YardsListSaveViewContext.Provider>
  );
};
