import produce from 'immer';

import { BulkClearOutYardsActionType, BulkClearOutYardsReducer, BulkClearOutYardsState } from './types';

const defaultState: BulkClearOutYardsState = {
  isModalOpen: false,
};

export const bulkClearOutYardsReducer: BulkClearOutYardsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_OPEN_MODAL:
        state.isModalOpen = true;
        break;

      case BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_CLOSE_MODAL:
        state.isModalOpen = false;
        break;
    }

    return state;
  });
};
