import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const InfoAlert = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 10 10" size={size}>
      <path
        fill={useColor(color)}
        d="M4.375 8.07291C4.375 8.38932 4.6315 8.64583 4.94792 8.64583C5.26433 8.64583 5.52083 8.38932 5.52083 8.07291V4.21875C5.52083 3.90233 5.26433 3.64583 4.94792 3.64583C4.6315 3.64583 4.375 3.90233 4.375 4.21875V8.07291Z"
      />
      <path
        fill={useColor(color)}
        d="M5.52083 2.23958C5.52083 1.92317 5.26433 1.66666 4.94792 1.66666C4.6315 1.66666 4.375 1.92317 4.375 2.23958C4.375 2.55599 4.6315 2.8125 4.94792 2.8125C5.26433 2.8125 5.52083 2.55599 5.52083 2.23958Z"
      />
    </IconSVGView>
  );
};
