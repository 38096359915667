import React from 'react';

import { DynamicTableRow } from '../types';

export type RowSelectionMap = Record<number, DynamicTableRow>;
export interface DynamicTableSelectionContextValue<RowMetaType = any> {
  selectedRows: RowSelectionMap;
  selectedRowIds: Array<number>;
  selectionCount: number;
  selectRow: (row: DynamicTableRow<RowMetaType>) => void;
  deselectRow: (row: DynamicTableRow<RowMetaType>) => void;
  selectAllOnPage: () => void;
  deselectAllOnPage: () => void;
  selectAllAcrossPages: () => void;
  deselectAllAcrossPages: () => void;
  /**
   * Returns true when user wants to select all rows
   * without going through them and manually selecting them.
   * this will allow you to add a param to the api post request
   * to communicate that all rows should recieve a specific action
   */
  hasClickedSelectAllAcrossPages: boolean;

  /**
   * Returns true when the number of filtered rows matches the number of rows in state
   *
   * Meaning,
   * the user has gone through and manually clicked 'select' or 'select all on page' for each row & page.
   */
  allRowsAcrossPagesAreSelectedManually: boolean;
  hasSelectedAllOnPage: boolean;
  hasSelectedAllOnCurrentPage: boolean;
}

export const DynamicTableSelectionContext = React.createContext<DynamicTableSelectionContextValue | null>(null);
