import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { AnimationDuration } from './types';

export function useTransitionDuration(duration?: AnimationDuration) {
  const theme = useTheme();
  return useMemo(() => {
    return {
      fast: theme.animations.durationFast,
      medium: theme.animations.durationMedium,
      long: theme.animations.durationLong,
    }[duration ?? 'medium'];
  }, [duration, theme.animations.durationFast, theme.animations.durationLong, theme.animations.durationMedium]);
}
