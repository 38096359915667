import React, { ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { useQueryParamState } from '@helpers/QueryParams/hooks';

import { ModalContext } from './context';
import { ModalContextValue, ModalFooterProps, ModalHeaderProps } from './types';

type OpenProps = ModalSimplifiedProps & {
  headerProps?: ModalHeaderProps;
} & {
  footerProps?: ModalFooterProps;
};

type ModalSimplifiedProps = Omit<ModalProps, keyof ModalOpeningProps>;
type ModalOpeningProps = Pick<ModalProps, 'isOpen' | 'onRequestClose'>;

export type ModalOpenerReturn = {
  open: (props?: OpenProps) => void;
  close: () => void;
  modalProps: ModalProps;
  headerProps: ModalHeaderProps;
  footerProps: ModalFooterProps;
  content: ReactNode;
};

/**
 * Helps to build modals quicker by changing props on-the-fly.
 * */
export function useDynamicModal(): ModalOpenerReturn {
  const [isOpen, setOpen] = useState(false);

  const [propsOverride, setPropsOverride] = useState<ModalSimplifiedProps>({});
  const [headerPropsOverride, setHeaderPropsOverride] = useState<ModalHeaderProps>({});
  const [footerPropsOverride, setFooterPropsOverride] = useState<ModalFooterProps>({});

  return useMemo(() => {
    return {
      open: (props) => {
        if (props) {
          const { headerProps, footerProps, ...modalProps } = props;
          modalProps && setPropsOverride(modalProps);
          headerProps && setHeaderPropsOverride(headerProps);
          footerProps && setFooterPropsOverride(footerProps);
        }
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
      modalProps: { isOpen, onRequestClose: () => setOpen(false), ...propsOverride },
      headerProps: headerPropsOverride,
      footerProps: footerPropsOverride,
      content: (
        <Modal isOpen={isOpen} onRequestClose={() => setOpen(false)} {...propsOverride}>
          <ModalHeader {...headerPropsOverride} />
          <ModalFooter {...footerPropsOverride} />
        </Modal>
      ),
    };
  }, [footerPropsOverride, headerPropsOverride, isOpen, propsOverride]);
}

export function useModalContext(): ModalContextValue {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("Can't find a ModalContext");
  }

  return context;
}

/**
 * Helper to defer the modal open state change until it's
 * completely rendered. It will improve the user experience
 * for pre-opened modals.
 * */
export function useModalDeferredOpenState(isOpen: boolean) {
  const [deferredIsOpen, setDeferredIsOpen] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setDeferredIsOpen(isOpen), 20);
  }, [isOpen]);

  return deferredIsOpen;
}

/**
 * Helper to handle modal open state using the current url
 * query params.
 * */
export function useQueryParamsModalOpener<V>(options: {
  paramName: string;
  paramValueStringifier?: (value: V) => string;
  paramValueParser?: (value: string) => V | null;
}) {
  const { paramName, paramValueStringifier = String, paramValueParser = String } = options;
  const [queryParamValues, setQueryParamValues] = useQueryParamState(paramName);

  const unparsedOpenedModals = useMemo(() => {
    return queryParamValues.length ? queryParamValues.split(',') : [];
  }, [queryParamValues]);

  const openedModals = useMemo(() => {
    return unparsedOpenedModals.map((val) => paramValueParser(val)).filter((val) => val !== null) as Array<V>;
  }, [paramValueParser, unparsedOpenedModals]);

  const openModal = useCallback(
    (value: V) => {
      const stringValue = paramValueStringifier(value);
      if (unparsedOpenedModals[openedModals.length - 1] === stringValue) {
        return;
      }
      setQueryParamValues([...openedModals, stringValue].join(','));
    },
    [openedModals, paramValueStringifier, setQueryParamValues, unparsedOpenedModals]
  );

  const closeModal = useCallback(
    (value: V) => {
      const stringValue = paramValueStringifier(value);
      const lastOccurrenceIndex = unparsedOpenedModals.lastIndexOf(stringValue);
      if (lastOccurrenceIndex !== -1) {
        const nextQueryParamValues = unparsedOpenedModals.slice(0, lastOccurrenceIndex);
        setQueryParamValues(nextQueryParamValues.join(','));
      }
    },
    [paramValueStringifier, setQueryParamValues, unparsedOpenedModals]
  );

  const handleInvalidModalValues = useCallback(() => {
    // To clean the given values, we basically parse then stringify the values.
    // If it returns null, the given value is invalid, then it's filtered out.
    const cleanValues = unparsedOpenedModals
      .map((val: any) => paramValueParser(val))
      .map((val: any) => paramValueStringifier(val))
      .filter(Boolean)
      .join(',');
    setQueryParamValues(cleanValues);
  }, [paramValueParser, paramValueStringifier, setQueryParamValues, unparsedOpenedModals]);

  // Only once at the beginning, handle invalid modal values.
  // Invalid values may come from badly formed url params.
  useEffect(() => {
    handleInvalidModalValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openedModals, openModal, closeModal };
}

/**
 * Optimizes the modals usage by only rendering them when necessary.
 * */
export function useModalConditionalRendering(shouldRender: boolean) {
  const [shouldRenderModal, setShouldRenderModal] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const delay = shouldRender ? 0 : theme.animations.durationLong;
    const tid = setTimeout(() => setShouldRenderModal(shouldRender), delay);
    return () => clearTimeout(tid);
  }, [shouldRender, theme.animations.durationLong]);

  return shouldRenderModal;
}
