import { ApiResponse } from '@helpers/Api/types';

const FILENAME_INVALID_CHAR_REGEX = /[^a-zA-Z0-9_]+/g;

export const File = {
  cleanupFileName,
  downloadFileFromApiResponse,
};

function cleanupFileName(name: string) {
  return name.replace(FILENAME_INVALID_CHAR_REGEX, '_');
}

/**
 * Downloads a file received from API
 * */
async function downloadFileFromApiResponse(name: string, response: ApiResponse) {
  // Inspired by: https://stackoverflow.com/a/63965930
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
