import React, { useCallback, useEffect, useState } from 'react';
import { IHeaderParams } from 'ag-grid-community';

import { Box } from '@components/common/Box';
import { useDynamicTableHasLoaded } from '@components/common/DynamicTable/hooks';
import { Checkbox } from '@components/form/inputs/CheckBox';

import { useYardsListSelection } from './hooks/useYardsListSelectionContext';

export const YardsSelectAllCheckbox: React.VFC<IHeaderParams> = () => {
  const [isChecked, setIsChecked] = useState(false);

  const { selectAllOnPage, deselectAllOnPage, hasSelectedAllOnCurrentPage } = useYardsListSelection();

  const yardsListHasLoaded = useDynamicTableHasLoaded();
  const handleOnChange = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      if (checked) selectAllOnPage();
      else deselectAllOnPage();
    },
    [deselectAllOnPage, selectAllOnPage]
  );

  useEffect(() => {
    if (isChecked !== hasSelectedAllOnCurrentPage) {
      setIsChecked(hasSelectedAllOnCurrentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedAllOnCurrentPage]);

  if (!yardsListHasLoaded) return null;

  return (
    <Box marginLeft_100>
      <Checkbox value={isChecked} name={`select-all-yards}`} onChange={handleOnChange} />
    </Box>
  );
};
