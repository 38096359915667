import React, { useCallback, useMemo, useState } from 'react';

import { ModalContext } from './context';
import { ModalContextValue } from './types';

export const ModalProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<Array<string>>([]);

  const addModalToStack = useCallback((modalId: string) => {
    setStack((curr) => [...curr, modalId]);
  }, []);

  const popModalFromStack = useCallback((modalId?: string) => {
    setStack((curr) => curr.filter((id) => id !== modalId));
  }, []);

  /**
   * Modal index from the first to the latest open.
   * Example: if you open the modals A and B, their indexes will
   * be A=0 and B=1.
   * */
  const getModalIndex = useCallback(
    (modalId: string) => {
      const index = stack.indexOf(modalId);
      return index === -1 ? stack.length - 1 : index;
    },
    [stack]
  );

  /**
   * Modal index from the latest to the first open, i.e. the opposite
   * as getModalIndex. Mostly used for some animations and visuals.
   * Example: if you open the modals A and B, their indexes will
   * be A=1 and B=0.
   * */
  const getModalReverseIndex = useCallback(
    (modalId: string) => {
      return stack.length - getModalIndex(modalId) - 1;
    },
    [getModalIndex, stack.length]
  );

  const context: ModalContextValue = useMemo(
    () => ({
      addModalToStack,
      popModalFromStack,
      getModalIndex,
      getModalReverseIndex,
    }),
    [addModalToStack, getModalIndex, getModalReverseIndex, popModalFromStack]
  );

  return <ModalContext.Provider value={context}>{children}</ModalContext.Provider>;
};
