export enum AnalyticsEventType {
  PAGE_VIEW = 'mp_page_view',

  /** Fired when the user logs in. */
  SESSION_START = 'mp_session_start',

  /** Fired when the selected operation become known. */
  SESSION_START_WITH_OPERATION = 'mp_session_start_with_operation',

  PASSWORD_CHANGE = 'mp_password_change',
  NAVBAR_MENU_OPEN = 'mp_navbar_menu_open',

  OPERATION_SWITCH = 'mp_operation_switch',

  WHITEBOARD_VIEW = 'mp_whiteboard_view',
  WHITEBOARD_SORT = 'mp_whiteboard_sort',
  WHITEBOARD_FILTER = 'mp_whiteboard_filter',
  WHITEBOARD_COLUMNS_CHANGE = 'mp_whiteboard_columns_change',
  WHITEBOARD_COLUMN_VIEW_CHANGE = 'mp_whiteboard_column_view_change',
  WHITEBOARD_TOOLTIP_VIEW = 'mp_whiteboard_tooltip_view',
  WHITEBOARD_PARTIAL_LOAD = 'mp_whiteboard_partial_load',
  WHITEBOARD_COMPLETE_LOAD = 'mp_whiteboard_complete_load',
  WHITEBOARD_VIEW_SAVE = 'mp_whiteboard_view_save',
  WHITEBOARD_RESET_TO_DEFAULT = 'mp_whiteboard_reset_to_default',
  WHITEBOARD_SCROLL_VERTICAL = 'mp_whiteboard_scroll_vertical',
  WHITEBOARD_SCROLL_HORIZONTAL = 'mp_whiteboard_scroll_horizontal',

  YARDS_MAP_LEFT_PANE_VIEW = 'mp_yards_map_left_pane_view',
  YARDS_MAP_TYPE_CHANGE = 'mp_yards_map_type_change',
  YARDS_MAP_LAYER_CHANGE = 'mp_yards_map_layer_change',
  YARD_CREATE = 'mp_yard_create',
  YARD_EDIT = 'mp_yard_edit',
  YARD_DELETE = 'mp_yard_delete',
  YARD_CLEAR_OUT = 'mp_yard_clear_out',
  YARD_BULK_DELETE = 'mp_yard_bulk_delete',
  YARD_BULK_CLEAR_OUT = 'mp_yard_bulk_clear_out',
  HIVE_DETAILS_VIEW = 'mp_hive_details_view',
  LOST_HIVE_DEADOUT = 'mp_lost_hive_deadout',
  LOST_HIVE_REMOVE = 'mp_lost_hive_remove',
  LOST_HIVE_BULK_DEADOUT = 'mp_lost_hive_bulk_deadout',
  LOST_HIVE_BULK_REMOVE = 'mp_lost_hive_bulk_remove',
  PRACTICE_CREATE = 'mp_practice_create',
  PRACTICE_EDIT = 'mp_practice_edit',
  PRACTICE_ENABLE = 'mp_practice_enable',
  PRACTICE_DISABLE = 'mp_practice_disable',
  WORKER_CREATE = 'mp_worker_create',
  WORKER_EDIT = 'mp_worker_edit',
  WORKER_REMOVE = 'mp_worker_remove',
  METRIC_CHART_VIEW = 'mp_metric_chart_view',
  METRIC_CHART_EXIT = 'mp_metric_chart_exit',
  SETTINGS_TIMEZONE_CHANGE = 'mp_settings_timezone_change',
  GLOBAL_SEARCH_FOCUS = 'mp_global_search_focus',
  GLOBAL_SEARCH_YARD_NAME_CLICK = 'mp_global_search_yard_name_click',
  GLOBAL_SEARCH_YARD_PIN_CLICK = 'mp_global_search_yard_pin_click',
  HELP_VIEW = 'mp_help_view',
  HELP_EMAIL_CLICK = 'mp_help_email_click',
  HELP_KNOWLEDGE_BASE_CLICK = 'mp_help_knowledge_base_click',

  TIMELINE_DAY_OFFSET_VIEW = 'mp_timeline_day_offset_view',
  TIMELINE_GO_TO_TOP = 'mp_timeline_go_to_top',
  TIMELINE_TIMEZONE_WARNING_VIEW = 'mp_timeline_timezone_warning_view',
  TIMELINE_TIMEZONE_WARNING_DISMISS = 'mp_timeline_timezone_warning_dismiss',
  TIMELINE_DETAILS_MODAL_VIEW = 'mp_timeline_details_modal_view',
  TIMELINE_HIVES_COUNT_TOOLTIP_VIEW = 'mp_timeline_hives_count_tooltip_view',

  NOTES_NAVIGATOR_PAGE_CHANGE = 'mp_notes_navigator_page_change',

  ELAP_EXPORT_CLICK = 'mp_elap_export_click',
  ELAP_EXPORT_SUCCESS = 'mp_elap_export_success',

  POLLINATION_CONTRACT_CREATE = 'mp_pollination_contract_create',
  POLLINATION_CONTRACT_EDIT = 'mp_pollination_contract_edit',
  POLLINATION_CONTRACT_CLEAR_OUT = 'mp_pollination_contract_clear_out',
  POLLINATION_CONTRACT_ARCHIVE = 'mp_pollination_contract_archive',
  POLLINATION_CONTRACT_RENEW = 'mp_pollination_contract_renew',
  POLLINATION_CONTRACT_DELETE = 'mp_pollination_contract_delete', // TODO: Add when this feature is enabled
  POLLINATION_CONTRACT_EXPORT_SUCCESS = 'mp_pollination_contract_export_success',
  POLLINATION_CONTRACT_EXPORT_FAIL = 'mp_pollination_contract_export_fail',

  POLLINATION_MAP_EDIT_START = 'mp_pollination_map_edit_start',
  POLLINATION_MAP_EDIT_SAVE = 'mp_pollination_map_edit_save',
  POLLINATION_MAP_EDIT_CANCEL = 'mp_pollination_map_edit_cancel',

  POLLINATION_MAP_DROP_ADD = 'mp_pollination_map_drop_add',
  POLLINATION_MAP_DROP_EDIT = 'mp_pollination_map_drop_edit',
  POLLINATION_MAP_DROP_DELETE = 'mp_pollination_map_drop_delete',
  POLLINATION_MAP_BLOCK_ADD = 'mp_pollination_map_block_add',
  POLLINATION_MAP_BLOCK_EDIT = 'mp_pollination_map_block_edit',
  POLLINATION_MAP_BLOCK_DELETE = 'mp_pollination_map_block_delete',
  POLLINATION_MAP_POI_ADD = 'mp_pollination_map_poi_add',
  POLLINATION_MAP_POI_EDIT = 'mp_pollination_map_poi_edit',
  POLLINATION_MAP_POI_DELETE = 'mp_pollination_map_poi_delete',
}

export enum AnalyticsPerformanceMetricEventType {
  PERFORMANCE_METRIC_WHITEBOARD = 'mp_performance_metric_whiteboard',
  PERFORMANCE_METRIC_WHITEBOARD_METRICS = 'mp_performance_metric_whiteboard_metrics',
  PERFORMANCE_METRIC_WHITEBOARD_TABLE = 'mp_performance_metric_whiteboard_table',

  PERFORMANCE_METRIC_ACTIVITIES = 'mp_performance_metric_activities',
  PERFORMANCE_METRIC_ACTIVITIES_MODAL = 'mp_performance_metric_activities_modal',
  PERFORMANCE_METRIC_ACTIVITIES_HIVE_MODAL = 'mp_performance_metric_activities_hive_modal',
}

export interface PageData {
  page_url: string;
  page_path: string;
  page_query: string;
}

export type AnalyticsEvent =
  | {
      event: AnalyticsEventType.PAGE_VIEW;
      eventData: PageData;
    }
  | {
      event: AnalyticsEventType.SESSION_START;
      eventData: {
        user_id: number;
        user_language: string;
        user_type: 'staff' | 'customer';
        app_version: string;
        app_environment: 'local' | 'development' | 'staging' | 'production';
      };
    }
  | {
      event: AnalyticsEventType.SESSION_START_WITH_OPERATION;
      eventData: {
        user_id: number;
        user_language: string;
        user_type: 'staff' | 'customer';
        operation_id: number;
        operation_name: string;
        app_version: string;
        app_environment: 'local' | 'development' | 'staging' | 'production';
      };
    }
  | {
      event: AnalyticsEventType.PASSWORD_CHANGE | AnalyticsEventType.NAVBAR_MENU_OPEN;
    }
  | {
      event: AnalyticsEventType.OPERATION_SWITCH;
      eventData: {
        origin: 'auth' | 'menu';
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_VIEW;
      eventData: {
        visible_rows: number;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_SORT;
      eventData: {
        column: string;
        direction: 'asc' | 'desc';
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_FILTER;
      eventData: {
        sections: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_COLUMNS_CHANGE;
      eventData: {
        visible_columns: string;
        origin: 'header-menu' | 'management-modal' | 'drag-and-drop';
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_COLUMN_VIEW_CHANGE;
      eventData: {
        column: string;
        column_type: 'default' | 'practice' | 'practice_category';
        column_view: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_TOOLTIP_VIEW;
      eventData: {
        column: string;
      };
    }
  | {
      event: AnalyticsEventType.WHITEBOARD_PARTIAL_LOAD | AnalyticsEventType.WHITEBOARD_COMPLETE_LOAD;
      eventData: {
        visible_columns: string;
        elapsed: number;
      };
    }
  | {
      event:
        | AnalyticsEventType.WHITEBOARD_VIEW_SAVE
        | AnalyticsEventType.WHITEBOARD_RESET_TO_DEFAULT
        | AnalyticsEventType.WHITEBOARD_SCROLL_VERTICAL
        | AnalyticsEventType.WHITEBOARD_SCROLL_HORIZONTAL;
    }
  | {
      event: AnalyticsEventType.YARDS_MAP_LEFT_PANE_VIEW;
    }
  | {
      event: AnalyticsEventType.YARDS_MAP_TYPE_CHANGE;
      eventData: {
        map_type: 'terrain' | 'terrain-simplified' | 'satellite' | 'satellite-simplified';
      };
    }
  | {
      event: AnalyticsEventType.YARDS_MAP_LAYER_CHANGE;
      eventData: {
        map_layers: string;
      };
    }
  | {
      event:
        | AnalyticsEventType.YARD_CREATE
        | AnalyticsEventType.YARD_EDIT
        | AnalyticsEventType.YARD_DELETE
        | AnalyticsEventType.YARD_CLEAR_OUT
        | AnalyticsEventType.YARD_BULK_DELETE
        | AnalyticsEventType.YARD_BULK_CLEAR_OUT;
    }
  | {
      event:
        | AnalyticsEventType.HIVE_DETAILS_VIEW
        | AnalyticsEventType.LOST_HIVE_REMOVE
        | AnalyticsEventType.LOST_HIVE_DEADOUT
        | AnalyticsEventType.LOST_HIVE_BULK_REMOVE
        | AnalyticsEventType.LOST_HIVE_BULK_DEADOUT;
    }
  | {
      event:
        | AnalyticsEventType.PRACTICE_CREATE
        | AnalyticsEventType.PRACTICE_EDIT
        | AnalyticsEventType.PRACTICE_ENABLE
        | AnalyticsEventType.PRACTICE_DISABLE
        | AnalyticsEventType.WORKER_CREATE
        | AnalyticsEventType.WORKER_EDIT
        | AnalyticsEventType.WORKER_REMOVE;
    }
  | {
      event: AnalyticsEventType.METRIC_CHART_VIEW;
      eventData: {
        metric: string;
      };
    }
  | {
      event: AnalyticsEventType.METRIC_CHART_EXIT;
      eventData: {
        metric: string;
        elapsed: number;
      };
    }
  | {
      event: AnalyticsEventType.SETTINGS_TIMEZONE_CHANGE;
      eventData: {
        timezone: string;
        previous_timezone: string;
        has_searched: boolean;
      };
    }
  | {
      event:
        | AnalyticsEventType.GLOBAL_SEARCH_FOCUS
        | AnalyticsEventType.GLOBAL_SEARCH_YARD_NAME_CLICK
        | AnalyticsEventType.GLOBAL_SEARCH_YARD_PIN_CLICK;
    }
  | {
      event:
        | AnalyticsEventType.HELP_VIEW
        | AnalyticsEventType.HELP_EMAIL_CLICK
        | AnalyticsEventType.HELP_KNOWLEDGE_BASE_CLICK;
    }
  | {
      event: AnalyticsEventType.TIMELINE_DAY_OFFSET_VIEW;
      eventData: {
        day_offset: number;
      } & PageData;
    }
  | {
      event:
        | AnalyticsEventType.TIMELINE_GO_TO_TOP
        | AnalyticsEventType.TIMELINE_DETAILS_MODAL_VIEW
        | AnalyticsEventType.TIMELINE_HIVES_COUNT_TOOLTIP_VIEW;
      eventData: PageData;
    }
  | {
      event:
        | AnalyticsEventType.TIMELINE_TIMEZONE_WARNING_VIEW
        | AnalyticsEventType.TIMELINE_TIMEZONE_WARNING_DISMISS
        | AnalyticsEventType.NOTES_NAVIGATOR_PAGE_CHANGE;
      eventData: PageData;
    }
  | {
      event: AnalyticsEventType.NOTES_NAVIGATOR_PAGE_CHANGE;
      eventData: PageData;
    }
  | {
      event: AnalyticsEventType.ELAP_EXPORT_CLICK;
      eventData: {
        from_date: string;
        to_date: string;
      };
    }
  | {
      event: AnalyticsEventType.ELAP_EXPORT_SUCCESS;
      eventData: {
        elapsed: number;
      };
    }
  | {
      event:
        | AnalyticsEventType.POLLINATION_CONTRACT_CREATE
        | AnalyticsEventType.POLLINATION_CONTRACT_EDIT
        | AnalyticsEventType.POLLINATION_CONTRACT_CLEAR_OUT
        | AnalyticsEventType.POLLINATION_CONTRACT_ARCHIVE
        | AnalyticsEventType.POLLINATION_CONTRACT_RENEW
        | AnalyticsEventType.POLLINATION_CONTRACT_DELETE;
    }
  | {
      event:
        | AnalyticsEventType.POLLINATION_CONTRACT_EXPORT_SUCCESS
        | AnalyticsEventType.POLLINATION_CONTRACT_EXPORT_FAIL;
      eventData: {
        elapsed: number;
      };
    }
  | {
      event: AnalyticsEventType.POLLINATION_MAP_EDIT_START;
    }
  | {
      event: AnalyticsEventType.POLLINATION_MAP_EDIT_SAVE | AnalyticsEventType.POLLINATION_MAP_EDIT_CANCEL;
      eventData: {
        elapsed: number;
      };
    }
  | {
      event:
        | AnalyticsEventType.POLLINATION_MAP_DROP_ADD
        | AnalyticsEventType.POLLINATION_MAP_DROP_EDIT
        | AnalyticsEventType.POLLINATION_MAP_DROP_DELETE
        | AnalyticsEventType.POLLINATION_MAP_BLOCK_ADD
        | AnalyticsEventType.POLLINATION_MAP_BLOCK_EDIT
        | AnalyticsEventType.POLLINATION_MAP_BLOCK_DELETE
        | AnalyticsEventType.POLLINATION_MAP_POI_ADD
        | AnalyticsEventType.POLLINATION_MAP_POI_EDIT
        | AnalyticsEventType.POLLINATION_MAP_POI_DELETE;
    }
  | {
      event: AnalyticsPerformanceMetricEventType;
      eventData: {
        elapsed: number;
      };
    };
