import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledTimelineModalInner = styled(Box)(
  ({ theme }) => css`
    width: 100%;

    // 128 compensates the footer height plus some extra margin.
    height: min(670px, calc(100vh - 128px));
  `
);
