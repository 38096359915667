import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { HealthScore } from '@components/common/HealthScore/HealthScore';
import { HealthScoreTooltip } from '@components/common/HealthScore/HealthScoreTooltip';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { Mating } from '@components/common/Icon/presets/Mating';
import { Queenless } from '@components/common/Icon/presets/Queenless';
import { Queenright } from '@components/common/Icon/presets/Queenright';
import { Line } from '@components/common/Line';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { PracticeIcon } from '@components/common/PracticeIcon';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { StyledTooltipPractice } from '@components/hive/HiveModalTabsSummary/styles';
import {
  StyledSummary,
  StyledSummaryItem,
  StyledSummaryValueText,
  StyledSummaryValueTextWrapper,
  StyledSummaryValueTitle,
  StyledSummaryValueTitleWrapper,
} from '@components/yard/YardDetailsDashboard/styles';
import { useCropTypeFetcher } from '@hooks/useCropTypes';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

export const YardDetailsDashboardSummary: React.VFC = () => {
  const t = useTranslation();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const { isFetching: isFetchingCropTypes } = useCropTypeFetcher();
  const { isFetching: isFetchingPractices, getActionName } = usePractices();

  const renderPracticeTooltip = useCallback((options: { name: string; date: string; icon: any }) => {
    const formattedDate = moment(options.date).format('MMM DD, YYYY [at] HH:mm');
    return (
      <Tooltip placement={'top-end'}>
        <StyledTooltipPractice marginBottom_050 paddingHorizontal_050 paddingVertical_025 gap_050 alignItems={'center'}>
          {options.icon}
          <Text typography={'SmallParagraph'}>{options.name}</Text>
        </StyledTooltipPractice>
        <Text typography={'CaptionSmall'}>{formattedDate}</Text>
      </Tooltip>
    );
  }, []);

  if (!yard) {
    return null;
  }

  return (
    <StyledSummary relative column stretch>
      <Box column paddingVertical_100 paddingHorizontal_150 gap_025>
        <Text typography={'Heading3'} weight={'600'}>
          {t('summary')}
        </Text>
      </Box>
      <Line />
      <Box column stretch paddingVertical_100 paddingHorizontal_150 gap_100>
        <StyledSummaryItem>
          <HiveDefault />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('Hives')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'}>{yard.nbHives ?? '—'}</StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <Mating />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle dashed>
              {t('health_score')}
              <Tooltip>
                <Text
                  typography={'TooltipSmall'}
                  dangerouslySetInnerHTML={{ __html: t('health_score_header_tooltip') }}
                />
              </Tooltip>
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <Box fit justifyContent={'flex-end'}>
              <HealthScore data={yard.healthScore}>
                <HealthScoreTooltip data={yard.healthScore} />
              </HealthScore>
            </Box>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <Queenright />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('queenright')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'}>{yard.nbQueenrightHives ?? '—'}</StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>
        {/* Not available yet. */}
        {/*<StyledSummaryItem>*/}
        {/*  <Introduced />*/}
        {/*  <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>*/}
        {/*    {t('introduced')}*/}
        {/*  </StyledSummaryValueTitle>*/}
        {/*  <StyledSummaryValueText typography={'Heading3'}>{yard.nbQueenrightHives ?? '—'}</StyledSummaryValueText>*/}
        {/*</StyledSummaryItem>*/}
        <StyledSummaryItem>
          <Queenless />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('queenless')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'}>{yard.nbQueenlessHives ?? '—'}</StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>
        <StyledSummaryItem>
          <Deadout />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('deadouts')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'}>{yard.nbDeadoutSeason ?? '—'}</StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <PracticeIcon usePresetFeeding />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('last_feed')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'} dashed={!!yard.lastFeedingAction}>
              {getActionName(yard.lastFeedingAction, '—')}
              {!!yard.lastFeedingAction &&
                !!yard.lastFeedingDatetime &&
                renderPracticeTooltip({
                  icon: <PracticeIcon usePresetFeeding />,
                  name: getActionName(yard.lastFeedingAction, '-'),
                  date: yard.lastFeedingDatetime,
                })}
            </StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>

        <StyledSummaryItem>
          <PracticeIcon usePresetTreatment />
          <StyledSummaryValueTitleWrapper>
            <StyledSummaryValueTitle typography={'Heading3'} weight={'600'}>
              {t('last_treatment')}
            </StyledSummaryValueTitle>
          </StyledSummaryValueTitleWrapper>

          <StyledSummaryValueTextWrapper>
            <StyledSummaryValueText typography={'Heading3'} dashed={!!yard.lastTreatmentAction}>
              {getActionName(yard.lastTreatmentAction, '—')}
              {!!yard.lastTreatmentAction &&
                !!yard.lastTreatmentDatetime &&
                renderPracticeTooltip({
                  icon: <PracticeIcon usePresetTreatment />,
                  name: getActionName(yard.lastTreatmentAction, '-'),
                  date: yard.lastTreatmentDatetime,
                })}
            </StyledSummaryValueText>
          </StyledSummaryValueTextWrapper>
        </StyledSummaryItem>
      </Box>

      <LoadingSkeleton
        visible={isFetchingCropTypes || isFetchingPractices}
        backgroundColor={'white'}
        absolutelyFitParent
        column
        padding_150
        gap_150
      >
        <LoadingSkeletonRectangle width={'100%'} height={36} borderRadius={'paperRadius02'} />
        <LoadingSkeletonRectangle width={'60%'} height={36} borderRadius={'paperRadius02'} />
        <LoadingSkeletonRectangle width={'80%'} height={36} borderRadius={'paperRadius02'} />
      </LoadingSkeleton>
    </StyledSummary>
  );
};
