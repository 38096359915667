import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { NavRouterLink } from '@components/common/CTA';
import { Contract } from '@components/common/Icon/presets/Contract';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import { StyledAboutItem } from '@components/yard/YardDetailsDashboard/styles';
import { YardTypeIcon } from '@components/yard/YardTypeIcon';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useCropTypeFetcher, useCropTypesDictionary } from '@hooks/useCropTypes';
import { useTranslation } from '@hooks/useTranslation';

const DATEFORMAT = 'MMM DD, YYYY';

export const YardDetailsDashboardAbout: React.VFC = () => {
  const t = useTranslation();
  const yard = useSelector((state) => state.yardDetailsReducer.detail);

  const { isFetching: isFetchingCropTypes } = useCropTypeFetcher();
  const cropTypes = useCropTypesDictionary();

  const getFormattedDate = useCallback((date) => {
    return date ? moment(date).format(DATEFORMAT) : '—';
  }, []);

  const cropTypesLabel = useMemo(() => {
    if (isFetchingCropTypes || !yard?.cropTypesIds.length) {
      return '—';
    }

    return yard.cropTypesIds
      .map((id) => cropTypes[id]?.name)
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }, [cropTypes, isFetchingCropTypes, yard?.cropTypesIds]);

  if (!yard) {
    return null;
  }

  return (
    <Box relative column paddingVertical_100 paddingHorizontal_150 gap_100>
      <Text typography={'Heading3'} weight={'600'}>
        {t('about')}
      </Text>

      <Box gap_150 wrap>
        <StyledAboutItem column gap_050>
          <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
            {t('active_since')}
          </Text>
          <Text typography={'Heading3'} weight={'600'}>
            {getFormattedDate(yard.activeChangeDatetime)}
          </Text>
        </StyledAboutItem>

        <StyledAboutItem column gap_050>
          <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
            {t('creation_date')}
          </Text>
          <Text typography={'Heading3'} weight={'600'}>
            {getFormattedDate(yard.createdAt)}
          </Text>
        </StyledAboutItem>

        <StyledAboutItem column gap_050>
          <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
            {t('contract')}
          </Text>

          <Box alignItems={'center'} gap_050>
            <Contract />
            {yard.contractName ? (
              <NavRouterLink
                to={URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, {
                  pathParams: { uid: String(yard.contractId) },
                })}
                suppressPadding
              >
                {yard.contractName}
              </NavRouterLink>
            ) : (
              <Text typography={'Heading3'} weight={'600'}>
                —
              </Text>
            )}
          </Box>
        </StyledAboutItem>

        <StyledAboutItem column gap_050>
          <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
            {t('yard_type')}
          </Text>

          <Box alignItems={'center'} gap_050>
            <YardTypeIcon type={yard.yardType} />
            <Text typography={'Heading3'} weight={'600'}>
              {yard.yardType ? t(yard.yardType) : '—'}
            </Text>
          </Box>
        </StyledAboutItem>
        <StyledAboutItem column gap_050>
          <Text typography={'Heading3'} weight={'600'} color={'grey06'}>
            {t('crop_type')}
          </Text>
          <Text typography={'Heading3'} weight={'600'}>
            {cropTypesLabel}
          </Text>
        </StyledAboutItem>
      </Box>

      <LoadingSkeleton visible={isFetchingCropTypes} backgroundColor={'white'} absolutelyFitParent>
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} />
      </LoadingSkeleton>
    </Box>
  );
};
