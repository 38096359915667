import React, { useMemo } from 'react';

import { Text } from '@components/common/Text';
import {
  StyledTimelineDayLine,
  StyledTimelineDayMark,
  StyledTimelineDayMarkSelector,
} from '@components/timeline/TimelineDayMark/styles';
import { TimelineDayMarkProps } from '@components/timeline/TimelineDayMark/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineDayMark: React.VFC<TimelineDayMarkProps> = ({ date }) => {
  const t = useTranslation();
  const dateUtils = useDateUtil();

  const formattedDate = useMemo(() => {
    if (dateUtils.isDateToday(date)) {
      return t('today');
    } else if (dateUtils.isDateYesterday(date)) {
      return t('yesterday');
    }
    return dateUtils.getFormattedDate(date, 'MMMM DD, YYYY');
  }, [date, dateUtils, t]);

  return (
    <>
      <StyledTimelineDayLine />
      <StyledTimelineDayMark data-timestamp={date}>
        <StyledTimelineDayMarkSelector>
          <Text typography={'Heading3'} weight={'700'}>
            {formattedDate}
          </Text>
        </StyledTimelineDayMarkSelector>
      </StyledTimelineDayMark>
    </>
  );
};
