import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';
import { FetchState } from '@redux/types';

export type ReportsReducer = Reducer<ReportsState, ReportsAction>;

export type ReportsAction =
  | {
      type: ReportsActionType.FETCH_START;
    }
  | {
      type: ReportsActionType.FETCH_FINISH;
      payload: {
        error: ApiResponseError | null;
      };
    };

export type ReportsState = FetchState;

export enum ReportsActionType {
  FETCH_START = 'ELAP_REPORTS_FETCH_START',
  FETCH_FINISH = 'ELAP_REPORTS_FETCH_FINISH',
}
