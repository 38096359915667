import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { TextProps } from '@components/common/Text/types';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Number } from '@helpers/Number';

import { StyledGaugeSVG, StyledGaugeText } from './styles';

export interface GaugeProps extends BoxProps<'div'> {
  value: number;
  size?: number;
  min?: number;
  max?: number;
  textProps?: TextProps;
}

export const Gauge: React.VFC<GaugeProps> = ({
  value: propValue,
  size = 35,
  min = 0,
  max = 10,
  textProps,
  ...boxProps
}) => {
  const [runningInitialTransition, setRunningInitialTransition] = useState(true);

  const theme = useTheme();
  const value = Math.max(0, Math.min(max, propValue));

  const svgStyle = useMemo<CSSProperties>(() => {
    return { width: size };
  }, [size]);

  const highlightCircleStyle = useMemo<CSSProperties>(() => {
    const mapped_0_10 = Number.interpolate(value, [min, max], [0, 10]);
    const mapped_0_80 = Math.round(Number.interpolate(value, [min, max], [0, 80]));

    const stroke =
      mapped_0_10 <= 4
        ? theme.colors.borderDanger
        : mapped_0_10 <= 7
        ? theme.colors.borderWarning
        : theme.colors.borderPositive;

    return {
      stroke,
      strokeDasharray: `${runningInitialTransition ? 0 : mapped_0_80}, 100`,
    };
  }, [
    max,
    min,
    runningInitialTransition,
    theme.colors.borderPositive,
    theme.colors.borderDanger,
    theme.colors.borderWarning,
    value,
  ]);

  /**
   * Makes the gauge animate from 0 to the given value
   * during the mounting time.
   * */
  useEffect(() => {
    setTimeout(() => setRunningInitialTransition(false), theme.animations.durationFast);
  }, [theme.animations.durationFast]);

  return (
    <Box relative center {...boxProps}>
      <StyledGaugeSVG viewBox="0 0 40 40" style={svgStyle} $zeroed={value === 0}>
        <circle className="circle-back" cx="50%" cy="50%" r="16" fill="transparent" strokeWidth="3" stroke="#EEE" />
        <circle
          className="circle-front"
          cx="50%"
          cy="50%"
          r="16"
          fill="transparent"
          strokeWidth="3"
          style={highlightCircleStyle}
        />
      </StyledGaugeSVG>

      <StyledGaugeText {...textProps} typography={'SmallParagraph'}>
        <ContentChangeTransition content={value >= 0 ? String(value) : null}>
          {(value) => value}
        </ContentChangeTransition>
      </StyledGaugeText>
    </Box>
  );
};
