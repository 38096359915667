import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type TimelineReducer = Reducer<TimelineState, TimelineAction>;

export type TimelineAction =
  | { type: TimelineActionType.TIMELINE_DISPOSE_ACTIVITY }
  | {
      type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_START;
      payload: {
        frozenAt: string;
      };
    }
  | {
      type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_FINISH;
      payload: {
        data: ActivityByDateSet | null;
        error: ApiResponseError | null;
      };
    }
  | { type: TimelineActionType.TIMELINE_DISPOSE_DETAILED_ACTIVITY; payload: { filters: ActivityFilters } }
  | {
      type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_START;
      payload: {
        filters: ActivityFilters;
      };
    }
  | {
      type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH;
      payload: {
        filters: ActivityFilters;
        data: ActivityByRequestSet | null;
        error: ApiResponseError | null;
      };
    };

export interface TimelineState {
  isFetchingActivitySet: boolean;
  activitySet: ActivityByDateSet | null;

  isFetchingDetailedActivitySets: boolean;
  detailedActivitySets: Array<
    ActivityByRequestSet & {
      filters: ActivityFilters;
    }
  >;
}

export interface ActivityByDateSet {
  activities: Array<BeeActivityByDate>;
  totalCount: number;
  frozenAt: string;
}

export interface ActivityByRequestSet {
  activities: Array<BeeActivityByRequest>;
  totalCount: number;
}

export interface ActivityFilters {
  date: string;
  alertTypes: Array<BeeActivityByRequestAlertType>;
  yardId?: number;
  hiveId?: number;
  lostHives?: boolean;
  practiceCategoryId?: number;
}

export enum TimelineActionType {
  TIMELINE_DISPOSE_ACTIVITY = 'TIMELINE_DISPOSE_ACTIVITY',

  TIMELINE_FETCH_ACTIVITY_START = 'TIMELINE_FETCH_ACTIVITY_START',
  TIMELINE_FETCH_ACTIVITY_FINISH = 'TIMELINE_FETCH_ACTIVITY_FINISH',

  TIMELINE_DISPOSE_DETAILED_ACTIVITY = 'TIMELINE_DISPOSE_DETAILED_ACTIVITY',

  TIMELINE_FETCH_DETAILED_ACTIVITY_START = 'TIMELINE_FETCH_DETAILED_ACTIVITY_START',
  TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH = 'TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH',
}
