import React, { useCallback, useEffect } from 'react';

import { AlertCard } from '@components/common/AlertCard';
import { StyledAlertLink, StyledAlertText, StyledAlertTextWrapper } from '@components/timeline/TimelineBase/styles';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineTimezoneAlert: React.VFC = () => {
  const t = useTranslation();
  const dateUtil = useDateUtil();
  const showAlert = dateUtil.isMismatchingBrowserTimezone();

  const sendDismissEvent = useCallback(() => {
    Analytics.sendEvent({
      event: AnalyticsEventType.TIMELINE_TIMEZONE_WARNING_DISMISS,
      eventData: Analytics.getPageData(),
    });
  }, []);

  useEffect(() => {
    if (showAlert) {
      Analytics.sendEvent({
        event: AnalyticsEventType.TIMELINE_TIMEZONE_WARNING_VIEW,
        eventData: Analytics.getPageData(),
      });
    }
  }, [showAlert]);

  if (!showAlert) {
    return null;
  }

  return (
    <AlertCard
      dismissible
      storageKey={'timeline-timezone-warning-storage-state'}
      warning
      marginBottom_100
      onDismiss={sendDismissEvent}
    >
      <StyledAlertTextWrapper>
        <StyledAlertText
          typography={'CaptionSmall'}
          dangerouslySetInnerHTML={{
            __html: t('timeline_mismatch_timezone_alert', {
              userTimezone: dateUtil.timezoneReadable,
              browserTimezone: dateUtil.browserTimezoneReadable,
            }),
          }}
        />{' '}
        <StyledAlertLink suppressPadding to={APP.routes.profile}>
          <StyledAlertText typography={'CaptionSmall'}>{t('settings').toLowerCase()}</StyledAlertText>
        </StyledAlertLink>
        {'.'}
      </StyledAlertTextWrapper>
    </AlertCard>
  );
};
