import styled from 'styled-components';

import { Button } from '@components/common/CTA';

export const StyledMapToolbarButtonToggle = styled(Button)<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
  position: absolute;
  top: ${theme.spacing._100};
  right: ${theme.spacing._100};

  transition: ${theme.animations.transitionFast('all')};
  
  opacity: ${isVisible ? 1 : 0};
  visibility: ${isVisible ? 'visible' : 'hidden'};
  
`
);
