import React from 'react';
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';

export interface FormProps<FV extends FieldValues = FieldValues>
  extends Pick<React.HTMLAttributes<HTMLFormElement>, FormCommonProps>,
    Omit<BoxProps<'form'>, 'onSubmit'> {
  form: UseFormReturn<FV>;
  onValidSubmit?: SubmitHandler<FV>;
  onInvalidSubmit?: SubmitErrorHandler<FV>;
}

type FormCommonProps = 'className' | 'onTransitionEnd' | 'children';

export const Form = <FV extends FieldValues = FieldValues>({
  /** Native Props. */
  className,
  children,

  /** Form API Props */
  form,
  onValidSubmit,
  onInvalidSubmit,

  /** Box props */
  ...boxProps
}: FormProps<FV>) => {
  const onSubmit = form.handleSubmit(
    (data, event) => {
      event?.preventDefault();
      onValidSubmit && onValidSubmit(data, event);
    },
    (data, event) => {
      event?.preventDefault();
      onInvalidSubmit && onInvalidSubmit(data, event);
    }
  );

  return (
    <FormProvider {...form}>
      <Box tag={'form'} {...{ onSubmit, className }} {...boxProps}>
        {children}
      </Box>
    </FormProvider>
  );
};
