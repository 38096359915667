import styled, { ColorGlobal, css } from 'styled-components';

export const StyledStepDot = styled.span<{
  $isActive: boolean;
  $isCompleted: boolean;
  $isClickable?: boolean;
  $defaultStepDotColor?: ColorGlobal;
}>(
  ({ theme, $isActive, $isCompleted, $isClickable, $defaultStepDotColor }) => css`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;

    background-color: ${$isCompleted
      ? theme.colors.surfaceAccent
      : $defaultStepDotColor
      ? theme.colors[$defaultStepDotColor]
      : theme.colors.contentLight};
    transition: ${theme.animations.transitionMedium('width', 'background-color')};

    ${$isActive &&
    css`
      width: 16px;
    `}

    ${$isClickable &&
    css`
      cursor: pointer;
    `}
  `
);
