import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { Checkbox } from '@components/form/inputs/CheckBox';
import { InputText } from '@components/form/inputs/InputText';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeAddWorkerRequestThunk, makeUpdateWorkerRequestThunk } from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

// BE already validates it in deep.
const SIMPLIFIED_PHONE_REGEX = /^[ \d+-]{8,}$/;

export const InviteWorkerModal: React.VFC<ModalProps> = ({ ...modalProps }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [keepAdding, setKeepAdding] = useState(false);
  const [askUserName, setAskUserName] = useState(false);
  const [addedUserId, setAddedUserId] = useState<number | null>(null);

  const isFetching = useSelector((state) => state.workerReducer.isFetching);

  const form = useForm({
    defaultValues: useFormDefaultValues({ phoneNumber: '', name: '' }),
    resolver: useFormSchemaResolver((schema) => ({
      phoneNumber: schema.string().matches(SIMPLIFIED_PHONE_REGEX, t('worker_phone_number_error')).required(),
      name: schema.string().required(),
    })).resolver,
  });

  const resetForm = useCallback(() => {
    form.reset({ phoneNumber: '', name: '' });
  }, [form]);

  const finishOrKeepAdding = useCallback(() => {
    resetForm();
    setAskUserName(false);

    if (!keepAdding) {
      modalProps.onRequestClose && modalProps.onRequestClose();
    }
  }, [keepAdding, modalProps, resetForm]);

  const attemptToSubmitPhoneNumber = useCallback(async () => {
    if (form.getFieldState('phoneNumber').invalid) {
      return;
    }

    const response = await dispatch(makeAddWorkerRequestThunk(form.getValues('phoneNumber')));
    const userIsExisting = response.status === 200;
    const userIsNew = response.status === 201;

    if (response.error) {
      const errors = response.error.detail ?? {};
      const isPhoneNumberInvalid = 'phoneNumber' in errors;
      const isDuplicatedWorker = errors.error?.includes('WORKER_ALREADY_ACTIVE');
      const isDuplicatedUser = (errors.error ?? [])[0] === 'INVALID_USER';
      const isInviteLimitReached = response.status === 429;

      if (isPhoneNumberInvalid) {
        form.setError('phoneNumber', { message: t('worker_phone_number_error') });
      } else if (isDuplicatedWorker) {
        form.setError('phoneNumber', { message: t('worker_already_active') });
      } else if (isDuplicatedUser) {
        form.setError('phoneNumber', { message: t('phone_number_already_taken') });
      } else if (isInviteLimitReached) {
        dispatch(
          makeShowSnackbarAction({
            messageTranslation: 'worker_invite_429_error',
            actionTranslation: 'worker_invite_429_error_action',
            actionProps: { id: 'intelcom-launcher' },
            persistent: true,
            type: 'error',
          })
        );
        finishOrKeepAdding();
      } else {
        dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
        finishOrKeepAdding();
      }
    } else if (userIsNew) {
      setAskUserName(true);
      setAddedUserId((await response.json()).id);
    } else if (userIsExisting) {
      dispatch(makeShowSnackbarAction('worker_added'));
      finishOrKeepAdding();
    }
  }, [dispatch, finishOrKeepAdding, form, t]);

  const attemptToSubmitUserName = useCallback(async () => {
    if (form.getFieldState('name').invalid) {
      return;
    }

    const [firstName, ...lastNames] = String(form.getValues('name')).split(' ');
    const response = await dispatch(makeUpdateWorkerRequestThunk(addedUserId, firstName, lastNames.join(' ')));

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      dispatch(makeShowSnackbarAction('worker_added'));
      finishOrKeepAdding();
    }
  }, [addedUserId, dispatch, finishOrKeepAdding, form]);

  const attemptToSubmit = useCallback(() => {
    askUserName ? attemptToSubmitUserName() : attemptToSubmitPhoneNumber();
  }, [askUserName, attemptToSubmitPhoneNumber, attemptToSubmitUserName]);

  useEffect(() => {
    if (!modalProps.isOpen) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.isOpen]);

  const disableDoneButton = askUserName
    ? form.getFieldState('name').invalid
    : form.getFieldState('phoneNumber').invalid;

  return (
    <Modal {...modalProps} nonDismissible={askUserName}>
      <Form column stretch form={form} onValidSubmit={attemptToSubmit} onInvalidSubmit={attemptToSubmit}>
        <ModalHeader
          title={askUserName ? t('worker_invite_modal_title_2') : t('worker_invite_modal_title_1')}
          subtitle={askUserName ? t('worker_invite_modal_message_2') : t('worker_invite_modal_message_1')}
          closeButtonProps={{ type: 'button' }}
          suppressCloseButton={askUserName}
        />
        <ModalContent>
          <ContentChangeTransition column stretch content={useMemo(() => ({ askUserName }), [askUserName])}>
            {({ askUserName }) =>
              askUserName ? (
                <InputText
                  name={'name'}
                  label={t('name')}
                  placeholder={t('worker_invite_name_name_placeholder')}
                  required
                />
              ) : (
                <InputText
                  name={'phoneNumber'}
                  label={t('phone_int')}
                  placeholder={t('phone_int_placeholder')}
                  required
                />
              )
            }
          </ContentChangeTransition>
        </ModalContent>
        <ModalFooter
          rejectText={askUserName ? undefined : t('cancel')}
          rejectButtonProps={{ type: 'button' }}
          acceptText={askUserName ? t('worker_invite_confirm') : t('add')}
          acceptButtonProps={{ type: 'submit', disabled: disableDoneButton }}
          autoCloseOnReject
        >
          <Box fit gap_050 tag={'label'} alignItems={'center'}>
            <Checkbox value={keepAdding} name={'keep-adding'} onChange={setKeepAdding} />
            <Text typography={'SmallParagraph'} weight={'600'}>
              {t('keep_adding')}
            </Text>
          </Box>
        </ModalFooter>
      </Form>

      <Loading whiteBackground visible={isFetching} roundedCorners />
    </Modal>
  );
};
