import { Reducer } from 'redux';

export type ArchiveContractReducer = Reducer<ArchiveContractState, ArchiveContractAction>;

export type ArchiveContractAction = {
  type:
    | ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_START
    | ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_SUCCESS
    | ArchiveContractActionType.ARCHIVE_CONTRACT_FETCH_FAILURE;
};

export interface ArchiveContractState {
  isFetchingArchiveContract: boolean;
}

export enum ArchiveContractActionType {
  ARCHIVE_CONTRACT_FETCH_START = 'ARCHIVE_CONTRACT_FETCH_START',
  ARCHIVE_CONTRACT_FETCH_SUCCESS = 'ARCHIVE_CONTRACT_FETCH_SUCCESS',
  ARCHIVE_CONTRACT_FETCH_FAILURE = 'ARCHIVE_CONTRACT_FETCH_FAILURE',
}
