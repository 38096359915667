import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction } from './index';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeWorkerRequestStartAction = () => ({
  type: types.WORKER_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{action: object}}
 *
 */
export const makeFetchWorkerRequestSuccessAction = (workerList) => ({
  type: types.FETCH_WORKER_SUCCESS,
  workerList,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{worker: object}}
 *
 */
export const makeAddWorkerRequestSuccessAction = (worker) => ({
  type: types.ADD_WORKER_SUCCESS,
  worker,
});

export const makeAddWorkerRequestFailureAction = () => ({
  type: types.ADD_WORKER_FAILURE,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{worker: object}}
 *
 */
export const makeUpdateWorkerRequestSuccessAction = (worker) => ({
  type: types.UPDATE_WORKER_SUCCESS,
  worker,
});

export const makeUpdateWorkerRequestFailureAction = () => ({
  type: types.UPDATE_WORKER_FAILURE,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{id: string}}
 *
 */
export const makeDeactivateWorkerRequestSuccessAction = (id) => ({
  type: types.DEACTIVATE_WORKER_SUCCESS,
  id,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeWorkerRequestFailureAction = () => ({
  type: types.WORKER_FAILURE,
});

/**
 *
 * @param error
 * @returns {{type: string, order_by: string, order_direction: string}}
 */
export const handleSortWorkerList = (order_by, order_direction) => ({
  type: types.SORT_WORKER_LIST,
  order_by,
  order_direction,
});

/**
 *
 * "thunk" action creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @param workerName
 * @param workerPhone
 * @returns {Function}
 */

export const makeFetchWorkerRequestThunk = () => {
  return (dispatch) => {
    dispatch(makeWorkerRequestStartAction());

    Api.get(ENDPOINTS.workerList)
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeWorkerRequestFailureAction());
            dispatch(makeShowSnackbarAction('snack_default_msg'));
            return;
          }
        } else if ('results' in jsonObj) {
          // all is right
          dispatch(makeFetchWorkerRequestSuccessAction(jsonObj.results));
        }
      });
  };
};

export const makeAddWorkerRequestThunk = (phone_number) => {
  return async function (dispatch) {
    dispatch(makeWorkerRequestStartAction());

    const url = ENDPOINTS.workerList;
    const data = {
      phone_number,
    };

    const response = await Api.post(url, data);

    if (!response.error) {
      dispatch(makeAddWorkerRequestSuccessAction(await response.json()));
    } else {
      dispatch(makeAddWorkerRequestFailureAction());
    }

    return response;
  };
};

export const makeUpdateWorkerRequestThunk = (id, first_name, last_name) => {
  return async function (dispatch) {
    dispatch(makeWorkerRequestStartAction());

    const url = URLUtil.buildURL(ENDPOINTS.workerDetail, { id });
    const data = {
      first_name,
      last_name,
    };

    const response = await Api.patch(url, data);

    if (!response.error) {
      dispatch(makeUpdateWorkerRequestSuccessAction(await response.json()));
    } else {
      dispatch(makeUpdateWorkerRequestFailureAction());
    }

    return response;
  };
};

export const makeDeactivateWorkerRequestThunk = (id) => {
  return async function (dispatch) {
    // show the spinning screen:
    dispatch(makeWorkerRequestStartAction());

    const response = await Api.post(`${ENDPOINTS.workerList}${id}/remove/`, {});
    if (response.error) {
      dispatch(makeWorkerRequestFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      dispatch(makeDeactivateWorkerRequestSuccessAction(id));
      dispatch(makeShowSnackbarAction('worker_deactivated'));
    }
  };
};

export const makeResendInviteWorkerRequestThunk = (id) => {
  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeWorkerRequestStartAction());

    Api.post(`${ENDPOINTS.workerList}${id}/reinvite/`, {})
      .then((res) => res.json())
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          dispatch(makeWorkerRequestFailureAction());
          dispatch(makeShowSnackbarAction('snack_default_msg'));
          return;
        } else {
          dispatch(makeShowSnackbarAction('worker_sms_resent', { icon: true }));
        }
      });
  };
};
