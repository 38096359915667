import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { IHeaderParams } from 'ag-grid-community';

import { Button } from '@components/common/CTA';
import { DynamicTableHeaderMenu } from '@components/common/DynamicTable/DynamicTableHeaderMenu';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { useTranslation } from '@hooks/useTranslation';

import { Box } from '../Box';
import { ArrowDown } from '../Icon/presets/ArrowDown';
import { ArrowsVertical } from '../Icon/presets/ArrowsVertical';
import { ArrowUp } from '../Icon/presets/ArrowUp';

import {
  StyledHeader,
  StyledHeaderSortButton,
  StyledHeaderSortButtonTextBox,
  StyledHeaderText,
  StyledShowOnHover,
} from './styles';
import { HeaderComponentParams, HeaderTitleComposed } from './types';

export const DynamicTableHeader: React.VFC<IHeaderParams & HeaderComponentParams> = (props) => {
  const t = useTranslation();

  const { column, progressSort, enableSorting, displayName, onSortRequested, getColumnDisplayName } = props;
  const columnDef = GridApiUtil.getColumnDefWithMetadata(column.getColDef());

  const headerId = `_${columnDef.colId}`;
  const headerMenuId = `_${columnDef.colId}-menu`;
  const mayShowMenu = !columnDef.pinned && !!columnDef.headerName;

  const _onSortRequested = useCallback<MouseEventHandler>(
    (event) => {
      progressSort(event.shiftKey);
      const sort = column.getSort();
      onSortRequested && onSortRequested(sort);
    },
    [column, onSortRequested, progressSort]
  );

  const _displayName = useMemo<HeaderTitleComposed>(() => {
    const val = getColumnDisplayName ? getColumnDisplayName(columnDef, displayName) : displayName;
    if (typeof val !== 'string') {
      return val as HeaderTitleComposed;
    }
    return {
      title: val as string,
      tooltip: '',
    };
  }, [columnDef, displayName, getColumnDisplayName]);

  const sortingIcon = column.isSortAscending() ? (
    <Box marginLeft_100>
      <ArrowUp />
    </Box>
  ) : column.isSortDescending() ? (
    <Box marginLeft_100>
      <ArrowDown />
    </Box>
  ) : (
    <StyledShowOnHover>
      <ArrowsVertical color="grey06" height={12} />
    </StyledShowOnHover>
  );

  const title = (
    <StyledHeaderText dashed={!!_displayName.tooltip}>
      {_displayName.title}
      {!!_displayName.tooltip && (
        <Tooltip>
          <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t(_displayName.tooltip) }} />
        </Tooltip>
      )}
    </StyledHeaderText>
  );

  const menuButton = mayShowMenu ? (
    <Button unstyled id={headerMenuId}>
      <StyledShowOnHover>
        <DynamicTableHeaderMenu target={headerMenuId} {...props} />
      </StyledShowOnHover>
    </Button>
  ) : null;

  if (enableSorting) {
    return (
      <StyledHeader id={headerId}>
        <StyledHeaderSortButton onClick={_onSortRequested}>
          <StyledHeaderSortButtonTextBox>{title}</StyledHeaderSortButtonTextBox>
          {sortingIcon}
        </StyledHeaderSortButton>
        {menuButton}
      </StyledHeader>
    );
  }

  return (
    <StyledHeader id={headerId}>
      <Box fit column alignItems={'flex-start'} justifyContent={'center'}>
        {title}
      </Box>
      {menuButton}
    </StyledHeader>
  );
};
