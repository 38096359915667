import * as d3 from 'd3';
import wkx from 'wkx';

import { CardType } from '@components/yard/MetricCard/type';
import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeFetchYardRequestSuccessAction } from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import {
  MetricAction,
  MetricMetadata,
  MetricTrendValues,
  MetricValue,
  YardsAction,
  YardsMapActionType,
  YardsMetricActionType,
} from '@redux/Yards/types';

const YARD_DETAIL_MAX_CACHE_AGE = 60000; // 1 minute.

export const makeMapFetchYardsStartAction = (): YardsAction => ({ type: YardsMapActionType.MAP_FETCH_YARDS_START });
export const makeMapFetchYardsFinishAction = (
  data: Array<BeeYardOnMapInfo>,
  error: ApiResponseError | null
): YardsAction => ({
  type: YardsMapActionType.MAP_FETCH_YARDS_FINISH,
  payload: { data, error },
});

export const makeMapFetchYardsThunk = Thunk.createTakeFirst(() => {
  return async (dispatch, getState) => {
    const { isFetchingYards } = getState().yardsReducer.map;
    if (isFetchingYards) {
      return;
    }

    dispatch(makeMapFetchYardsStartAction());

    const { appliedFilters } = getState().yardsFiltersReducer;

    let url: string = URLUtil.buildURL(ENDPOINTS.yardsMap, { ...appliedFilters });
    let error: ApiResponseError | null = null;
    let data: Array<BeeYardOnMapInfo> = [];

    while (url) {
      const response = await Api.get(url);
      if (response.error) {
        data = [];
        error = response.error;
        break;
      }
      const responseData = await response.json();
      data = [...data, ...responseData.results.map(parseYardFromApi)];
      url = responseData.next;
    }

    dispatch(makeMapFetchYardsFinishAction(data, error));
  };
});

export const makeMapFetchYardDetailStartAction = (): YardsAction => ({
  type: YardsMapActionType.MAP_FETCH_YARD_DETAIL_START,
});
export const makeMapFetchYardDetailFinishAction = (
  data: BeeYardOnMapDetailedInfo | null,
  error: ApiResponseError | null
): YardsAction => ({
  type: YardsMapActionType.MAP_FETCH_YARD_DETAIL_FINISH,
  payload: { data, error },
});

export const makeMapFetchYardDetailThunk = Thunk.createTakeLast(
  (yardId: number | null, dispatchStatus?: { cache: boolean; canceled: boolean }) => {
    return async (dispatch: any) => {
      dispatch(makeMapFetchYardDetailStartAction());

      let data: BeeYardOnMapDetailedInfo | null = null;
      let error: ApiResponseError | null = null;

      if (yardId) {
        const maxCacheAge = dispatchStatus?.cache ? YARD_DETAIL_MAX_CACHE_AGE : 0;
        const detailUrl: string = URLUtil.buildURL(ENDPOINTS.hhtYardDetail, { id: yardId });
        const detailResponse = await CachedRequest.performRequest(detailUrl, Api.get, maxCacheAge);

        const inspectionsUrl: string = URLUtil.buildURL(ENDPOINTS.hhtActivitiesByDate, {
          yard: yardId,
          limit: 1,
          alert_type: ['new_yard_visit'],
        });
        const inspectionsResponse = await CachedRequest.performRequest(
          inspectionsUrl,
          Api.get,
          YARD_DETAIL_MAX_CACHE_AGE
        );

        if (detailResponse.error || inspectionsResponse.error) {
          error = detailResponse.error || inspectionsResponse.error;
        } else {
          const last_activity = (await inspectionsResponse.json())?.results[0] ?? null;
          data = parseYardDetailFromApi({
            ...(await detailResponse.json()),
            last_activity,
          });
        }
      }

      if (!dispatchStatus?.canceled) {
        dispatch(makeMapFetchYardDetailFinishAction(data, error));
      }
    };
  }
);

// Empty Yard
export const emptyYardThunk = Thunk.createTakeFirst((yard_id: number) => {
  return async (dispatch: any) => {
    const url = `${ENDPOINTS.hhtYards}${yard_id}/empty-yard/`;
    const response = await Api.post(url, {});
    if (response.status == 200) {
      dispatch(makeFetchYardRequestSuccessAction(await response.json()));
      dispatch(makeShowSnackbarAction({ messageTranslation: 'yard_cleared_out' }));
    } else {
      dispatch(
        makeShowSnackbarAction(
          response.error?.snackbarOptions ?? { messageTranslation: 'snack_non_field_errors', type: 'error' }
        )
      );
      throw new Error("Can't empty yard");
    }
  };
});

// Delete Yard
export const deleteYardThunk = Thunk.createTakeFirst((yard_id: number) => {
  return async (dispatch: any) => {
    const url = `${ENDPOINTS.hhtYards}${yard_id}/`;
    const data = { yard_id: yard_id };
    const response = await Api.delete_(url, data);
    if (response.status == 204) {
      dispatch(makeShowSnackbarAction({ messageTranslation: 'yard_deleted_only' }));
    } else {
      dispatch(
        makeShowSnackbarAction(
          response.error?.snackbarOptions ?? { messageTranslation: 'snack_non_field_errors', type: 'error' }
        )
      );
      throw new Error("Can't delete yard");
    }
  };
});

export const makeMapFetchBlocksStartAction = (): YardsAction => ({ type: YardsMapActionType.MAP_FETCH_BLOCKS_START });
export const makeMapFetchBlocksFinishAction = (data: Array<BeeBlock>, error: ApiResponseError | null): YardsAction => ({
  type: YardsMapActionType.MAP_FETCH_BLOCKS_FINISH,
  payload: { data, error },
});

export const makeMapFetchBlocksThunk = Thunk.create(() => {
  return async (dispatch) => {
    dispatch(makeMapFetchBlocksStartAction());

    let url: string = URLUtil.buildURL(ENDPOINTS.blocks, { limit: 2048 });
    let error: ApiResponseError | null = null;
    let data: Array<BeeBlock> = [];

    while (url) {
      const response = await Api.get(url);
      if (response.error) {
        data = [];
        error = response.error;
        break;
      }
      const responseData = await response.json();
      data = [...data, ...responseData.results.map(parseBlockFromApi)];
      url = responseData.next;
    }

    dispatch(makeMapFetchBlocksFinishAction(data, error));
  };
});

export const makeFetchMetricAvailabilityStartAction = (): YardsAction => ({
  type: YardsMetricActionType.METRIC_FETCH_AVAILABILITY_START,
});
export const makeFetchMetricValueStartAction = (): YardsAction => ({
  type: YardsMetricActionType.METRIC_FETCH_VALUE_START,
});
export const makeFetchMetricAvailabilityFinishAction = (
  cards: Array<MetricMetadata>,
  error: ApiResponseError | null
): YardsAction => ({
  type: YardsMetricActionType.METRIC_FETCH_AVAILABILITY_FINISH,
  payload: { cards, error },
});
export const makeFetchMetricValueFinishAction = (
  cards: Array<MetricMetadata>,
  error: ApiResponseError | null
): YardsAction => ({
  type: YardsMetricActionType.METRIC_FETCH_VALUE_FINISH,
  payload: { cards, error },
});

export const makeFetchYardMetricsAvailabilityThunk = Thunk.createTakeFirst(() => {
  return async (dispatch) => {
    dispatch(makeFetchMetricAvailabilityStartAction());
    let error: ApiResponseError | null = null;
    let cards: Array<MetricMetadata> = [];
    const response = await Api.get(ENDPOINTS.yardsMetricAvailability);

    if (response.error) {
      cards = [];
      error = response.error;
    }
    const responseData = await response.json();
    if (responseData) {
      cards = responseData.results.map((r: any) => ({
        ...parseMetricMetadataFromApi(r),
        isFetching: true,
      }));
    }

    dispatch(makeFetchMetricAvailabilityFinishAction(cards, error));
  };
});

export const makeFetchYardMetricsThunk = Thunk.createTakeFirst(() => {
  return async (dispatch, getState) => {
    dispatch(makeFetchMetricValueStartAction());
    const { appliedFilters } = getState().yardsFiltersReducer;
    const { metrics } = getState().yardsReducer;

    const requests = metrics.cards.map((card) => {
      const url = URLUtil.buildURL(ENDPOINTS.yardsMetric, { ...appliedFilters, metric_name: [card.metricName] });
      let responseCards: Array<MetricMetadata> = [];

      return Api.get(url)
        .then(async (response) => {
          const responseData = await response.json();
          if (responseData) {
            responseCards = responseData.results.map(parseMetricMetadataFromApi);
            responseCards = responseCards.filter((responseCard) => responseCard.metricName == card.metricName);
          }
          dispatch(makeFetchMetricValueFinishAction(responseCards, null));
        })
        .catch((error) => {
          responseCards = [];
          dispatch(makeFetchMetricValueFinishAction(responseCards, error));
        });
    });

    return await Promise.all(requests);
  };
});

function parseYardFromApi(data: any): BeeYardOnMapInfo {
  return CaseAdapter.objectToCamelCase<BeeYardOnMapInfo>({
    ...data,
    hives_position: data.hives_position.map((pos: string) => wkx.Geometry.parse(pos).toGeoJSON()),
  });
}

function parseYardDetailFromApi(data: any): BeeYardOnMapDetailedInfo {
  return CaseAdapter.objectToCamelCase<BeeYardOnMapDetailedInfo>({
    ...data,
    hives_position: data.hives_position.map((pos: string) => wkx.Geometry.parse(pos).toGeoJSON()),
  });
}

function parseBlockFromApi(data: any): BeeBlock {
  return CaseAdapter.objectToCamelCase<BeeBlock>({
    ...data,
  });
}

function parseMetricMetadataFromApi(data: any): MetricMetadata {
  return CaseAdapter.objectToCamelCase(data);
}

export const makeFetchMetricTrendStartAction = (): MetricAction => ({
  type: YardsMetricActionType.METRIC_FETCH_TREND_START,
});

export const makeFetchMetricTrendFailureAction = (): MetricAction => ({
  type: YardsMetricActionType.METRIC_FETCH_TREND_FAILURE,
});

export const makeFetchMetricTrendFinishAction = (
  data: MetricTrendValues[],
  error: ApiResponseError | null
): MetricAction => ({
  type: YardsMetricActionType.METRIC_FETCH_TREND_FINISH,
  payload: { data, error },
});

export const makeFetchMetricTrendThunk = Thunk.createTakeFirst(
  (metricName: string, displayedMetricValue: MetricValue[] = [{ value: 0 }]) => {
    return async (dispatch, getState) => {
      dispatch(makeFetchMetricTrendStartAction());
      const { appliedFilters } = getState().yardsFiltersReducer;
      const parseDate = d3.timeParse('%m');

      let error: ApiResponseError | null = null;
      let data: MetricTrendValues[] = [
        { name: 'current_season_trend', trend: [{ ts: parseDate('1') as Date, value: 0 }] },
        { name: 'last_season_trend', trend: [{ ts: parseDate('1') as Date, value: 0 }] },
      ];
      if (metricName != 'winter_mortality') {
        if (metricName) {
          const url = URLUtil.buildURL(ENDPOINTS.metricTrend, { name: metricName }, { ...appliedFilters });
          const response = await CachedRequest.performRequest(
            url,
            (url: string) => Api.get(url),
            YARD_DETAIL_MAX_CACHE_AGE
          );

          if (response.error) {
            error = response.error;
            dispatch(makeFetchMetricTrendFailureAction());
            dispatch(makeShowSnackbarAction(error.snackbarOptions));
          } else {
            const responseData = await response.json();
            if (responseData) {
              data = responseData.results.map(function (d: any) {
                if (d) {
                  return {
                    ...d,
                    trend: d.trend.map((d: any) => {
                      return {
                        ...d,
                        value: +d.value,
                        ts: parseDate(d.ts) as Date,
                      };
                    }),
                  };
                }
              });
            }
          }
        }
      } else {
        if (displayedMetricValue) {
          data = [
            {
              name: 'current_season_trend',
              trend: [...Array(12)].map((e, i) => ({
                ts: parseDate(`${i}`) as Date,
                value: displayedMetricValue[0].value,
              })),
            },
            {
              name: 'last_season_trend',
              trend: [...Array(12)].map((e, i) => ({
                ts: parseDate(`${i}`) as Date,
                value: displayedMetricValue[0].previousValue as number,
              })),
            },
          ];
        }
      }
      dispatch(makeFetchMetricTrendFinishAction(data, error));
    };
  }
);

export const makeOpenMetricModal = (
  metricName: string,
  metricValue: MetricValue[],
  positiveDirection: boolean,
  cardType: CardType,
  categories: string[] | null
): MetricAction => ({
  type: YardsMetricActionType.OPEN_METRIC_MODAL,
  payload: {
    metricName: metricName,
    metricValue: metricValue,
    positiveDirection: positiveDirection,
    cardType: cardType,
    categories: categories,
  },
});

export const makeCloseMetricModal = (): MetricAction => ({
  type: YardsMetricActionType.CLOSE_METRIC_MODAL,
});
