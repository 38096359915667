import { isArrayLike } from 'lodash';

export type ComparisonReturn = number;
export type SortFunc<T> = (a: T, b: T) => number;

export const Sorting = {
  sort,
  sortAlphanumerically,
  alphanumericSortFunc,
  defaultSortFunc
};

export function sortAlphanumerically<T = unknown>(items: Array<T>, sortKey: keyof T) {
  return sort<T>(items, (a, b) => alphanumericSortFunc(a[sortKey], b[sortKey]));
}

export function sort<T = unknown>(items: Array<T>, sortKey: keyof T): Array<T>;
export function sort<T = unknown>(items: Array<T>, sortKeys: Array<keyof T>): Array<T>;
export function sort<T = unknown>(items: Array<T>, sortFunc: SortFunc<T>): Array<T>;
export function sort<T = unknown>(items: Array<T>, sortKeyOrFunc: SortFunc<T> | keyof T | Array<keyof T>): Array<T>;
export function sort<T = unknown>(items: Array<T>, sortKeyOrFunc: SortFunc<T> | keyof T | Array<keyof T>): Array<T> {
  if (typeof sortKeyOrFunc === 'function') {
    return [...items].sort(sortKeyOrFunc);
  }
  if (isArrayLike(sortKeyOrFunc)) {
    return [...items].sort((a, b) => {
      for (const key of sortKeyOrFunc) {
        const cmp = defaultSortFunc(a[key], b[key]);
        if (cmp !== 0) {
          return cmp;
        }
      }
      return 0;
    });
  }
  return [...items].sort((a, b) => defaultSortFunc(a[sortKeyOrFunc], b[sortKeyOrFunc]));
}

function alphanumericSortFunc(a: unknown, b: unknown): ComparisonReturn {
  const aParts = splitAlphanumericString(String(a));
  const bParts = splitAlphanumericString(String(b));

  if (aParts.length === 0 && bParts.length === 0) {
    return 0;
  }

  if (aParts.length === 0 || bParts.length === 0) {
    return aParts.length - bParts.length;
  }

  const comp = alphanumericCompare(aParts[0], bParts[0]);
  if (comp === 0) {
    const aNext = aParts.slice(1).join(' ');
    const bNext = bParts.slice(1).join(' ');
    return alphanumericSortFunc(aNext, bNext);
  }

  return comp;
}

function defaultSortFunc(a: unknown, b: unknown): ComparisonReturn {
  return alphanumericCompare(a, b);
}

function splitAlphanumericString(str: string): Array<string> {
  const alphaNumRegex = [/([0-9])([^0-9\s])/g, /([^0-9\s])([0-9])/g];
  const replaced = alphaNumRegex.reduce((str, regex) => str.replace(regex, '$1 $2'), str).trim();
  return replaced ? replaced.split(' ') : [];
}

function alphanumericCompare(a: unknown, b: unknown): ComparisonReturn {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    if (isNumericString(a) && isNumericString(b)) {
      return parseFloat(a) - parseFloat(b);
    }

    return a.localeCompare(b);
  }

  return 0;
}

function isNumericString(str: string): boolean {
  return Boolean(str.match(/^\d+(\.\d+)?$/));
}
