/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
// vendor:
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Modal } from '@components/common/Modal';
import { createDropName } from '@helpers/Drop';
import { QueryParams } from '@helpers/QueryParams';
import { withGetScreen } from '@HOC/withGetScreen';
import {
  makeCloseUpdateYardModalAction,
  makeFetchYardRequestThunk,
  makeResetYardZoomModaAction,
  makeUpdateYardRequestThunk,
  setEditModeAction,
} from '@redux/deprecated/actions';
import { resetYardModalData } from '@redux/deprecated/actions/beeTrackYardListRequestActionCreator';

// nectar:
import { UpdateYardModalView } from './UpdateYardModalView';

const modalInitialState = {
  step: 1,
  title: 'edit_yard',
  nextButton: 'next',
  backButton: 'back',
};

const yardInitialState = {
  name: '',
  id: '',
  yard_type: '',
  crop_types_ids: [],
  contract_id: null,
  contract_name: '',
  field_notes: '',
  geometry: [],
  coordinates: null,
};

const validationInitialState = {
  isDisabledBtn: true,
  isDrop: false,
  isDisabledNext: {
    shape: false,
    hives: false,
  },
  updated: {},
};

class UpdateYardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...modalInitialState,
      ...yardInitialState,
      ...validationInitialState,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.handleChangeCropType = this.handleChangeCropType.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIfButtonIsDisabled = this.setIfButtonIsDisabled.bind(this);
    this.setModalContent = this.setModalContent.bind(this);
    this.handleSetYardType = this.handleSetYardType.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.setNewYardGeometry = this.setNewYardGeometry.bind(this);
    this.validateGeometry = this.validateGeometry.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { yard, contentObj, fetchBeeTrackYard, isFetchingYard } = this.props;
    const { modalType } = contentObj;

    if ('update-yard' === modalType && contentObj.yard !== prevProps.contentObj.yard)
      fetchBeeTrackYard(contentObj.yard.id);

    if (yard && yard !== prevProps.yard && !isFetchingYard) {
      const { id, yard_type, name, crop_types_ids, field_notes, geometry, contract_id, contract_name } = yard;

      this.setState((current) => ({
        title: contract_id ? 'edit_drop' : 'edit_yard',
        id,
        yard_type,
        contract_id,
        contract_name,
        name,
        crop_types_ids,
        field_notes,
        geometry: geometry?.coordinates,
        isDisabledBtn: !name,
        isDrop: !!contract_id,
        updated: {
          ...current.updated,
          name: name || current.name, // Name is required
        },
      }));
    } else {
      const isDisabledBtn = !this.state.name;
      if (this.state.isDisabledBtn !== isDisabledBtn) {
        this.setState((current) => ({ ...current, isDisabledBtn }));
      }
    }
  }

  handleClose() {
    this.props.closeDispatch();
    this.props.resetZoom();
    this.setState({
      ...modalInitialState,
      ...validationInitialState,
    });
  }

  setIfButtonIsDisabled() {
    const { name, contract_id, isDrop } = this.state;
    this.setState({ isDisabledBtn: !name || (isDrop && !contract_id) });
  }

  setModalContent(step) {
    const { t } = this.props;
    switch (true) {
      case 1 === step:
        this.setState({ ...modalInitialState });
        break;
      case 2 === step:
        this.setState({
          title: 'edit_boundaries',
          instructions: null,
          nextButton: 'next',
          backButton: 'back',
        });
        break;
      case 3 === step:
        this.setState({
          title: 'add_field_notes',
          instructions: t('field_notes_instructions'),
          nextButton: 'submit',
        });
        break;
      default:
        break;
    }
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
        updated: {
          ...this.state.updated,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleTextChange(value, name) {
    this.setState(
      {
        [name]: value,
        updated: {
          ...this.state.updated,
          [name]: value,
        },
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleContractChange(contractSummary) {
    if (!contractSummary) {
      const contractCleanedState = {
        contract_id: null,
        crop_types_ids: this.props.yard.crop_types_ids,
        name: this.props.yard.name,
        yard_type: this.props.yard.yard_type,
      };
      const { contract_id, crop_types_ids, name, yard_type, ...cleanedUpdated } = this.state.updated;
      this.setState(
        {
          ...contractCleanedState,
          updated: cleanedUpdated,
        },
        () => {
          this.setIfButtonIsDisabled();
        }
      );
    } else {
      const contractDirtedState = {
        contract_id: contractSummary.id,
        crop_types_ids: contractSummary.crop_types_ids,
        name: createDropName(contractSummary.latest_drop_name),
        yard_type: 'pollination',
      };
      this.setState(
        {
          ...contractDirtedState,
          updated: contractDirtedState,
        },
        () => {
          this.setIfButtonIsDisabled();
        }
      );
    }
  }

  handleChangeCropType(cropTypesIds) {
    this.setState(
      {
        crop_types_ids: cropTypesIds,
        updated: {
          ...this.state.updated,
          crop_types_ids: cropTypesIds,
        },
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  handleSetYardType(yard_type) {
    this.setState(
      {
        yard_type,
        updated: {
          ...this.state.updated,
          yard_type,
        },
      },
      () => {
        this.setIfButtonIsDisabled();
      }
    );
  }

  setNewYardGeometry(polygon) {
    let coordinates = [];
    for (var i = 0; i < polygon.length; i++) {
      let lat = polygon.getAt(i).lat();
      let lng = polygon.getAt(i).lng();
      coordinates.push([lng, lat]);
    }
    // quick hack to check that the first item in the coordintates is equal to the last
    const first = 0;
    const last = coordinates.length - 1;
    if (coordinates[first][0] !== coordinates[last][0] || coordinates[first][1] !== coordinates[last][1])
      coordinates.push(coordinates[first]);
    this.setState({
      coordinates,
      updated: {
        ...this.state.updated,
        geometry: { type: 'Polygon', coordinates: [coordinates] },
      },
    });
  }

  handleKeyDown(e) {
    const { contentObj } = this.props;
    const { isDisabledBtn } = this.state;

    const { modalType } = contentObj;

    if ('update-yard' === modalType) {
      if ('Enter' === e.key && !isDisabledBtn) this.handleSubmit(e);
    }
  }

  handleNext() {
    const { step, updated } = this.state;

    const { updateYard, yard, closeDispatch, resetZoom, history, contentObj } = this.props;

    const { id } = yard;

    if (3 === step) {
      if (Object.keys(updated).length) {
        updateYard(id, updated, updated.name).then(() => {
          closeDispatch();
          resetZoom();
          this.setState({ ...modalInitialState });
          history.push({ pathname: history.location.pathname, search: QueryParams.getCurrentQueryParamsStr() });
          contentObj.onUpdated && contentObj.onUpdated();
        });
      } else {
        closeDispatch();
        this.setState({ ...modalInitialState });
      }
    } else {
      const { step } = this.state;
      this.setState(
        {
          step: step + 1,
        },
        () => {
          const { step } = this.state;
          this.setModalContent(step);
        }
      );
    }
  }

  handleBack() {
    const { step } = this.state;

    this.setState(
      {
        step: step - 1,
        ...validationInitialState.isDisabledNext,
      },
      () => {
        const { step } = this.state;
        this.setModalContent(step);
      }
    );
  }

  validateGeometry(type, isDisabledNext) {
    this.setState((prevState) => ({
      isDisabledNext: {
        ...prevState.isDisabledNext,
        [type]: isDisabledNext,
      },
    }));
  }

  render() {
    const { t, isUpdateYardOpen, isMobile, contentObj, yard, isFetchingUpdateYard } = this.props;
    const {
      isDisabledBtn,
      isDisabledNext,
      id,
      yard_type,
      contract_id,
      contract_name,
      name,
      crop_types_ids,
      field_notes,
      step,
      title,
      instructions,
      nextButton,
      backButton,
      geometry,
    } = this.state;

    const { modalType } = contentObj;

    const modalObj = {
      step,
      title,
      instructions,
      nextButton,
      backButton,
    };

    const yardObj = {
      yard_id: id,
      yard_type,
      contract_id,
      contract_name,
      name,
      crop_types_ids,
      field_notes,
      geometry,
    };

    const next_button = () => {
      if (1 === step) return false;
      else return isDisabledNext.shape || isDisabledNext.hives;
    };

    return (
      <Modal
        isMobile={isMobile()}
        isOpen={isUpdateYardOpen}
        onRequestClose={this.handleClose}
        modalType={modalType}
        nonDismissible
      >
        {isUpdateYardOpen ? (
          <UpdateYardModalView
            t={t}
            key={step}
            modalObj={modalObj}
            isMobile={isMobile()}
            contentObj={{
              yardObj,
              modalType: contentObj.modalType,
            }}
            step={step}
            isDisabledBtn={isDisabledBtn}
            handleClose={this.handleClose}
            handleNext={this.handleNext}
            handleChange={this.handleChange}
            handleTextChange={this.handleTextChange}
            handleContractChange={this.handleContractChange}
            handleChangeCropTypes={this.handleChangeCropType}
            handleBack={this.handleBack}
            passDropdownDataToParent={this.handleSetYardType}
            setNewYardGeometry={this.setNewYardGeometry}
            isDisabledNext={next_button()}
            validateGeometry={this.validateGeometry}
            isDrop={this.state.isDrop}
            loading={!yard || isFetchingUpdateYard}
          />
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

/**
 *
 * @param dispatch
 * @returns {{closeDispatch: makeCloseUpdateYardModalAction}}
 * @returns {{updateYard: makeUpdateYardRequestThunk}}
 * @returns {{resetZoom: makeResetYardZoomModaAction}}
 * @returns {{setEditModeAction: setEditModeAction}}
 * @returns {{fetchBeeTrackYard: makeFetchYardRequestThunk}}
 *
 *
 */
const mapDispatchToProps = (dispatch) => ({
  closeDispatch: () => {
    dispatch(makeCloseUpdateYardModalAction());
  },
  updateYard: (id, yardData, yard_name) => {
    return dispatch(makeUpdateYardRequestThunk(id, yardData, yard_name));
  },
  resetZoom: () => {
    dispatch(makeResetYardZoomModaAction());
  },
  resetYardData: () => {
    dispatch(resetYardModalData());
  },
  setEditModeAction: (editMode) => {
    dispatch(setEditModeAction(editMode));
  },
  fetchBeeTrackYard: (id) => {
    dispatch(makeFetchYardRequestThunk(id));
  },
});

/**
 *
 * @param state
 * @returns {{isUpdateYardOpen: (*|boolean)}}
 * @returns {{contentObj: (*|object)}}
 * @returns {{yard: (*|object)}}
 * @returns {{isFetchingYard: (*|boolean)}}
 *
 *
 */
const mapStateToProps = (state) => ({
  isUpdateYardOpen: state.modalReducer.isUpdateYardOpen,
  contentObj: state.modalReducer.contentObj,
  yard: state.beeTrackYardListReducer.yard,
  isFetchingYard: state.beeTrackYardListReducer.isFetchingYard,
  isFetchingUpdateYard: state.beeTrackYardListReducer.isFetchingUpdateYard,
});

UpdateYardModal.propTypes = {
  isUpdateYardOpen: PropTypes.bool.isRequired,
  contentObj: PropTypes.object.isRequired,
  closeDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withGetScreen()(UpdateYardModal)))
);
