import React, { useMemo, useState } from 'react';
import { GridApi } from 'ag-grid-community';

import { GridApiContext, GridApiContextValue } from '@components/common/AgGrid/context';

export const GridApiProvider: React.FC = ({ children }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const context: GridApiContextValue = useMemo(
    () => ({
      gridApi,
      setGridApi,
    }),
    [gridApi]
  );

  return <GridApiContext.Provider value={context}>{children}</GridApiContext.Provider>;
};
