import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGridApi } from '@components/common/AgGrid';
import { AlertCard } from '@components/common/AlertCard';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { useYardsListSelection } from '@components/yard/YardsList/hooks/useYardsListSelectionContext';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useIntercomArticleOpener } from '@helpers/Intercom/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeBulkClearOutYardsModalCloseAction, makeBulkClearOutYardsThunk } from '@redux/BulkClearOutYards/actions';

export const BulkClearOutYardsModal: React.VFC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { isModalOpen } = useSelector((state) => state.bulkClearOutYardsReducer);
  const { selectedYards } = useYardsListSelection();
  const { gridApi } = useGridApi();

  const selectedYardsList = Object.values(selectedYards);
  const activeYardsCount = selectedYardsList.filter((yard) => yard.meta.isActive).length;
  const inactiveYardsCount = selectedYardsList.filter((yard) => !yard.meta.isActive).length;
  const showAlert = inactiveYardsCount > 0;

  const t = useTranslation();
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(makeBulkClearOutYardsModalCloseAction());
  }, [dispatch]);

  const attemptToClearOutYards = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(makeBulkClearOutYardsThunk(selectedYardsList.map(({ meta }) => meta.id)));
      closeModal();
      gridApi?.refreshInfiniteCache();

      Analytics.sendEvent({ event: AnalyticsEventType.YARD_BULK_CLEAR_OUT });
    } finally {
      setIsLoading(false);
    }
  }, [closeModal, dispatch, gridApi, selectedYardsList]);

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      <ModalHeader
        title={t(activeYardsCount === 1 ? 'clear_out_x_yards_title_single' : 'clear_out_x_yards_title', {
          count: activeYardsCount,
        })}
        subtitle={t(activeYardsCount === 1 ? 'clear_out_x_yards_text_single' : 'clear_out_x_yards_text')}
        linkText={t('clear_out_yard_help_link')}
        onLinkClick={useIntercomArticleOpener(APP.intercomArticles.CLEAR_OUT_YARDS)}
        alert={
          showAlert ? (
            <AlertCard fit warning>
              {t(inactiveYardsCount === 1 ? 'clear_out_x_yards_alert_single' : 'clear_out_x_yards_alert', {
                count: inactiveYardsCount,
              })}
            </AlertCard>
          ) : undefined
        }
        suppressCloseButton={showAlert}
      />
      <ModalFooter
        autoCloseOnReject
        rejectText={t('cancel')}
        rejectButtonProps={{ disabled: isLoading }}
        acceptText={t('clear_out_yard_accept')}
        onAcceptClick={attemptToClearOutYards}
        acceptButtonProps={{ disabled: isLoading }}
      />

      <Loading visible={isLoading} whiteBackground roundedCorners />
    </Modal>
  );
};
