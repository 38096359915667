import React, { useCallback, useContext, useLayoutEffect, useRef } from 'react';

import { Check } from '@components/common/Icon';
import { Checkbox } from '@components/form/inputs/CheckBox';
import { InputAutocompleteContext } from '@components/form/inputs/InputAutocomplete/context';

import { StyledOption, StyledOptionContent } from './styles';
import { OptionProps } from './types';

export const Option: React.FC<OptionProps> = ({ children, value, ...buttonProps }) => {
  const optionRef = useRef<HTMLButtonElement>(null);

  const autocompleteContext = useContext(InputAutocompleteContext);
  const option = autocompleteContext?.options[value];
  const selected = !!autocompleteContext?.selectedOptions[value];

  const handleClick = useCallback(() => {
    autocompleteContext?.toggleOption(value);
  }, [autocompleteContext, value]);

  useLayoutEffect(() => {
    const label = optionRef.current?.textContent;
    if (label) {
      return autocompleteContext?.registerOption(value, label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useLayoutEffect(() => {
    const element = optionRef.current;
    if (!element) return;

    const keyDownHandler = (e: KeyboardEvent) => {
      e.key === 'Enter' && e.preventDefault();
    };
    const keyUpHandler = (e: KeyboardEvent) => {
      e.key === 'Enter' && handleClick();
    };

    element.addEventListener('keydown', keyDownHandler);
    element.addEventListener('keyup', keyUpHandler);

    return () => {
      element.removeEventListener('keydown', keyDownHandler);
      element.removeEventListener('keyup', keyUpHandler);
    };
  }, [handleClick]);

  return (
    <StyledOption
      ref={optionRef}
      suppressPadding
      disabled={!option?.visible}
      onClick={handleClick}
      $visible={option?.visible}
      {...buttonProps}
    >
      <StyledOptionContent fit gap_075 alignItems={'center'}>
        {autocompleteContext?.isMultiple && (
          <Checkbox value={selected} name={`${value}-option`} onChange={handleClick} data-omit-keyboard-navigation />
        )}
        {children}
        {selected && !autocompleteContext?.isMultiple && <Check />}
      </StyledOptionContent>
    </StyledOption>
  );
};
