import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IHeaderParams } from 'ag-grid-community';

import { useDynamicTableUtil } from '@components/common/DynamicTable/hooks/useDynamicTableUtil';
import { History } from '@components/common/Icon/presets/History';
import { Menu as MenuIcon } from '@components/common/Icon/presets/Menu';
import { Menu } from '@components/common/Menu';
import { MenuItem, MenuProps } from '@components/common/Menu/types';
import { useAnimationLoop } from '@hooks/useAnimationLoop';

const DEF_BORDER_PROXIMITY_TOLERANCE = 360;
const DEF_MENU_OFFSET: [number, number] = [24, 6];

export interface DynamicTableHeaderMenuProps extends IHeaderParams {
  target: string;
}

export const DynamicTableHeaderMenu: React.VFC<DynamicTableHeaderMenuProps> = ({
  api: gridApi,
  columnApi,
  column,
  target,
}) => {
  const tableUtil = useDynamicTableUtil();
  const [menuPositionProps, setMenuPositionProps] = useState<Partial<MenuProps>>({});
  const menuAnchorRef = useRef<HTMLSpanElement>(null);

  const { t } = useTranslation();
  const columnDef = tableUtil.getColumnDefWithMetadata(column.getColDef());

  const handleAutoResize = useCallback(
    (columnId?: string) => {
      if (columnId) {
        columnApi.autoSizeColumn(columnId);
      } else {
        const resizableColumnKeys = tableUtil
          .getFlattenColumnDefs(gridApi.getColumnDefs())
          .filter((def) => !def.suppressAutoSize)
          .map((def) => def.colId as string);
        columnApi.autoSizeColumns(resizableColumnKeys);
      }
    },
    [columnApi, gridApi, tableUtil]
  );
  const menuItems = useMemo<Array<MenuItem>>(() => {
    const items: Array<MenuItem> = [
      {
        title: t('autosize_this_column'),
        group: 'autosize',
        icon: History,
        onClick: () => handleAutoResize(columnDef.colId),
      },
      {
        title: t('autosize_all_columns'),
        group: 'autosize',
        icon: History,
        onClick: () => handleAutoResize(),
      },
    ];

    return items;
  }, [columnDef.colId, handleAutoResize, t]);

  const updateMenuPositionProps = useCallback(() => {
    const anchorElement = menuAnchorRef.current;

    if (anchorElement) {
      const menuAnchorRect = anchorElement.getBoundingClientRect();
      const distanceFromRightBorder = window.innerWidth - menuAnchorRect.right;
      const isMenuAtVeryRight = distanceFromRightBorder < DEF_BORDER_PROXIMITY_TOLERANCE;

      const nextProps: Partial<MenuProps> = {
        subMenuProps: {
          placement: isMenuAtVeryRight ? 'left-start' : 'right-start',
        },
      };

      setMenuPositionProps((current) => {
        if (current.subMenuProps?.placement !== nextProps.subMenuProps?.placement) {
          return { ...current, ...nextProps };
        }
        return current;
      });
    }
  }, []);

  useAnimationLoop(updateMenuPositionProps);

  return (
    <span ref={menuAnchorRef}>
      <MenuIcon size={16} />
      <Menu
        items={menuItems}
        target={target}
        placement={'bottom-end'}
        offset={DEF_MENU_OFFSET}
        {...menuPositionProps}
      />
    </span>
  );
};
