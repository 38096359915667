import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Refresh = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.65 6.35C16.205 4.9 14.21 4 12 4C7.58001 4 4.01001 7.58 4.01001 12C4.01001 16.42 7.58001 20 12 20C15.725 20 18.845 17.45 19.73 14H17.65C16.825 16.33 14.615 18 12 18C8.68501 18 6.00001 15.315 6.00001 12C6.00001 8.685 8.68501 6 12 6C13.655 6 15.14 6.69 16.225 7.775L13 11H20V4L17.65 6.35Z"
      />
    </IconSVGView>
  );
};
