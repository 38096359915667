import { useCallback, useLayoutEffect } from 'react';

import { useAnimationLoop } from '@hooks/useAnimationLoop';

const ACTIVATE_SHADOW_CLASS = 'ag-shadow-active';
const VERTICAL_SCROLL_ELEMENTS = ['.ag-body-viewport'];
const VERTICAL_SHADOWED_ELEMENTS = ['.ag-header'];
const HORIZONTAL_SCROLL_ELEMENTS = ['.ag-center-cols-viewport', '.ag-body-horizontal-scroll-viewport'];
const HORIZONTAL_SHADOWED_ELEMENTS = ['.ag-pinned-left-cols-container', '.ag-pinned-left-header'];
const SCROLL_ANIMATION_INITIAL_DELAY = 8000;
const SCROLL_ANIMATION_STATE = { enabled: false /** Disabled for now. It may be re-enabled if design decides it. */ };
const SCROLL_ANIMATION = { duration: 1600, left: 16 };

export interface ScrollBounceAnimationOptions {
  duration?: number;
  left?: number;
  top?: number;
}

export function useDynamicTableScrollingEffects() {
  useLayoutEffect(() => {
    setTimeout(async () => {
      const horizontalScrollElements = getElements(HORIZONTAL_SCROLL_ELEMENTS);
      setInteractionListener(horizontalScrollElements, () => {
        SCROLL_ANIMATION_STATE.enabled = false;
      });

      await animateScrollBounce(horizontalScrollElements, SCROLL_ANIMATION);
      await animateScrollBounce(horizontalScrollElements, SCROLL_ANIMATION);
    }, SCROLL_ANIMATION_INITIAL_DELAY);
  }, []);

  const updateScrollEffects = useCallback(() => {
    const verticalScrollElements = getElements(VERTICAL_SCROLL_ELEMENTS);
    const verticalShadowedElements = getElements(VERTICAL_SHADOWED_ELEMENTS);
    const showVerticalShadow = getScrollOffset(verticalScrollElements) > 0;
    showVerticalShadow
      ? addClass(verticalShadowedElements, ACTIVATE_SHADOW_CLASS)
      : removeClass(verticalShadowedElements, ACTIVATE_SHADOW_CLASS);

    const horizontalScrollElements = getElements(HORIZONTAL_SCROLL_ELEMENTS);
    const horizontalShadowedElements = getElements(HORIZONTAL_SHADOWED_ELEMENTS);
    const showHorizontalShadow = getScrollOffset(horizontalScrollElements) > 0;
    showHorizontalShadow
      ? addClass(horizontalShadowedElements, ACTIVATE_SHADOW_CLASS)
      : removeClass(horizontalShadowedElements, ACTIVATE_SHADOW_CLASS);
  }, []);

  useAnimationLoop(updateScrollEffects);
}

function addClass(elements: Array<HTMLElement>, className: string) {
  elements.forEach((el) => {
    !el.className.includes(className) && (el.className += ` ${className}`);
  });
}

function removeClass(elements: Array<HTMLElement>, className: string) {
  elements.forEach((el) => {
    el.className = el.className.replace(className, '').trim();
  });
}

async function animateScrollBounce(elements: Array<HTMLElement>, options: ScrollBounceAnimationOptions) {
  const f = (x: number, offset: number, duration: number) =>
    -Math.pow(Math.sqrt(offset) - (2 * x * Math.sqrt(offset)) / duration, 2.0) + offset;

  const top = options.top ?? 0;
  const left = options.left ?? 0;
  const duration = options.duration ?? 0;
  const animationStep = 1000.0 / 60.0;

  for (let x = 0; x <= duration; x += animationStep) {
    await new Promise((resolve) => setTimeout(resolve, animationStep));
    setScrollOffset(elements, {
      top: f(x, top ?? 0, duration),
      left: f(x, left ?? 0, duration),
    });
  }

  setScrollOffset(elements, {
    top: 0,
    left: 0,
  });
}

function setScrollOffset(elements: Array<HTMLElement>, options: ScrollToOptions) {
  elements.forEach((el) => {
    if (SCROLL_ANIMATION_STATE.enabled) {
      el.scrollTo(options.left ?? 0, options.top ?? 0);
    }
  });
}

function setInteractionListener(elements: Array<HTMLElement>, handler: () => void) {
  elements.forEach((el) => {
    el.addEventListener('wheel', handler);
    el.addEventListener('mousedown', handler);
  });
}

function getScrollOffset(elements: Array<HTMLElement>): number {
  return elements.reduce((major, el) => Math.max(major, el.scrollLeft, el.scrollTop), 0);
}

function getElements(classes: Array<string>): Array<HTMLElement> {
  return Array.from<HTMLElement>(document.querySelectorAll(classes.join(',')));
}
