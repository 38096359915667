import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from '@components/common/Icon/presets/Filter';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { StyledFilterButton, StyledFilterCount } from '@components/yard/YardsFilters/styles';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeFetchFiltersAvailabilitiesThunk } from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';

import { YardsFiltersModal } from './YardsFiltersModal';

export const YardsFiltersTrigger: React.VFC = () => {
  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appliedFiltersCount } = useYardsFilters();
  const isFilterCountVisible = appliedFiltersCount > 0;

  const retrieveFilterAvailabilities = useCallback(() => {
    dispatch(makeFetchFiltersAvailabilitiesThunk());
  }, [dispatch]);

  const closeFiltersModal = useCallback(() => setFiltersModalOpen(false), []);

  const openFiltersModal = useCallback(() => {
    retrieveFilterAvailabilities();
    setFiltersModalOpen(true);
  }, [retrieveFilterAvailabilities]);

  return (
    <>
      <ResponsiveRender until="mobile">
        <StyledFilterButton iconOnly onClick={openFiltersModal} aria-label={t('filter_yards')}>
          <Filter size={24} />
          <StyledFilterCount $isVisible={isFilterCountVisible}>{appliedFiltersCount}</StyledFilterCount>
        </StyledFilterButton>
      </ResponsiveRender>
      <ResponsiveRender from="tablet">
        <StyledFilterButton primary withLeadingIcon onClick={openFiltersModal}>
          <Filter />
          {t('filter_yards')}
          <StyledFilterCount $isVisible={isFilterCountVisible}>{appliedFiltersCount}</StyledFilterCount>
        </StyledFilterButton>
      </ResponsiveRender>

      <YardsFiltersModal isOpen={isFiltersModalOpen} onRequestClose={closeFiltersModal} />
    </>
  );
};
