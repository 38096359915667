import React from 'react';

import { DefaultCellText } from './DefaultCellText';
import { DefaultCellWrapper } from './DefaultCellWrapper';

export const DefaultEmptyCell: React.VFC = () => {
  return (
    <DefaultCellWrapper block>
      <DefaultCellText>—</DefaultCellText>
    </DefaultCellWrapper>
  );
};
