import React, { FormEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { PoiIcon } from '@components/common/PoiIcon';
import { Text } from '@components/common/Text';
import { InputTextBackwardCompatible } from '@components/form/backwardCompatible/InputTextBackwardCompatible';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapUtil } from '@components/pollination/PolliMap/util';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import {
  StyledForm,
  StyledFormContent,
  StyledFormFooter,
  StyledFormHead,
  StyledPoiTypeButton,
  StyledPoiTypeButtons,
} from './styles';

const POI_CATEGORIES = ['access', 'road', 'security', 'storage', 'hazard'] as BeePointOfInterestCategory[];
const POI_DEFAULT_NAME_REGEX = new RegExp(`^(${POI_CATEGORIES.join('|')})\\s*\\d+$`, 'i');

export const PolliMapPanePoiForm: React.VFC = () => {
  const isInputNameDirty = useRef(false);

  const { t } = useTranslation();
  const {
    getKey,
    selectedPoi,
    selectPoi,
    isAdding,
    pois,
    poisList,
    isDraggingElements,
    isManagingAPoi,
    submitPoiChange,
    cancelPoiChange,
  } = usePolliMap();

  const handleValueChange = useCallback(
    (value: any, key: string) => {
      if (selectedPoi) {
        const nextPoi = { ...selectedPoi, [key]: String(value) || null };

        if (key === 'name') {
          isInputNameDirty.current = true;
        } else if (key === 'category') {
          // Whenever the poi name is set to a default name (e.g.: Access 1), we may
          // override it by the category name when it changes.
          const isPoiDefaultName = POI_DEFAULT_NAME_REGEX.test(selectedPoi.name ?? '');
          if (isPoiDefaultName) {
            const otherPois = poisList.filter((poi) => getKey(poi) !== getKey(selectedPoi));
            const number = PolliMapUtil.generateNextPoiNumber(value, otherPois);
            const category = t(`pollination_poi_category_${value}`);
            nextPoi.name = PolliMapUtil.replacePoiName(nextPoi, { number, category });
          }
        }

        selectPoi({ ...nextPoi });
      }
    },
    [getKey, poisList, selectPoi, selectedPoi, t]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      selectedPoi &&
        Analytics.sendEvent({
          event: isAdding(selectedPoi, pois)
            ? AnalyticsEventType.POLLINATION_MAP_POI_ADD
            : AnalyticsEventType.POLLINATION_MAP_POI_EDIT,
        });

      event.preventDefault();
      event.stopPropagation();
      submitPoiChange();
    },
    [isAdding, pois, selectedPoi, submitPoiChange]
  );

  if (!(isDraggingElements || isManagingAPoi)) {
    return null;
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormHead>
        <Text typography={'Heading3'} weight={'600'}>
          {t('pollination_new_poi_name')}
        </Text>
      </StyledFormHead>
      <StyledFormContent>
        <Box marginBottom_100 column stretch>
          <Text typography={'SmallParagraph'}>{t('pollination_new_poi_hint')}</Text>
        </Box>

        <StyledPoiTypeButtons>
          {POI_CATEGORIES.map((category) => (
            <StyledPoiTypeButton
              key={category}
              flat={category !== selectedPoi?.category}
              onClick={() => handleValueChange(category, 'category')}
              type="button"
            >
              <PoiIcon category={category} size={24} />
              <Text typography={'CaptionSmall'}>{t(`pollination_poi_category_${category}`)}</Text>
            </StyledPoiTypeButton>
          ))}
        </StyledPoiTypeButtons>

        <Box marginTop_150 column stretch>
          <InputTextBackwardCompatible
            id="name"
            name="name"
            type="text"
            label={t('pollination_poi_name_label')}
            value={selectedPoi?.name ?? ''}
            onTextChange={handleValueChange}
            required
            autoFocus
          />
        </Box>

        <Box marginTop_100 column stretch>
          <InputTextBackwardCompatible
            id="descriptionEnglish"
            name="descriptionEnglish"
            type="textarea"
            label={t('pollination_poi_en_desc_label')}
            value={selectedPoi?.descriptionEnglish ?? ''}
            multiple
            onTextChange={handleValueChange}
          />
        </Box>

        <Box marginTop_100 column stretch>
          <InputTextBackwardCompatible
            id="descriptionSpanish"
            name="descriptionSpanish"
            type="textarea"
            label={t('pollination_poi_es_desc_label')}
            value={selectedPoi?.descriptionSpanish ?? ''}
            onTextChange={handleValueChange}
          />
        </Box>
      </StyledFormContent>
      <StyledFormFooter>
        <Button secondary grow withMarginRight type={'button'} onClick={cancelPoiChange}>
          {t('cancel')}
        </Button>
        <Button primary grow>
          {t(isAdding(selectedPoi, pois) ? 'create' : 'done')}
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
