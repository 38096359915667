import React from 'react';

export interface LayoutContextValue {
  isSearchExpanded: boolean; // maybe do 'searchState' 'expanded' 'collapsed' 'desktop' ?
  isSideNavExpanded: boolean;
  collapseSearch: () => void;
  collapseSideNav: () => void;
  expandSearch: () => void;
  expandSideNav: () => void;
  toggleSideNav: () => void;
}

export const LayoutContext = React.createContext<LayoutContextValue | null>(null);
