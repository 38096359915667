import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Link = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C6.36819 4 6.66667 4.29848 6.66667 4.66667C6.66667 5.03486 6.36819 5.33333 6 5.33333H3.33333V12.6667H10.6667V10C10.6667 9.63181 10.9651 9.33333 11.3333 9.33333C11.7015 9.33333 12 9.63181 12 10V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6ZM13.5596 2C13.8028 2 14 2.19716 14 2.44037V6.66667C14 7.03486 13.7015 7.33333 13.3333 7.33333C12.9651 7.33333 12.6667 7.03486 12.6667 6.66667V4.27533L7.94267 8.99994C7.68236 9.26028 7.26029 9.26029 6.99997 8.99997C6.73967 8.73967 6.73966 8.31765 6.99994 8.05733L11.7233 3.33333H9.33333C8.96514 3.33333 8.66667 3.03486 8.66667 2.66667C8.66667 2.29848 8.96514 2 9.33333 2H13.5596Z"
      />
    </IconSVGView>
  );
};
