import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useGridApi } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Assign } from '@components/common/Icon/presets/Assign';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { EmptyYard } from '@components/common/Icon/presets/EmptyYard';
import { Menu } from '@components/common/Menu';
import { MenuItem } from '@components/common/Menu/types';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import {
  useOnYardSaveListener,
  useYardCreateOrUpdateModalOpener,
} from '@components/yard/YardCreateOrUpdateModal/hooks';
import { YardDetailsClearOutAndDeleteModal } from '@components/yard/YardDetails/YardDetailsClearOutAndDeleteModal';
import { YardRow } from '@components/yard/YardsList/types';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenAssignYardsGroupsModalAction } from '@redux/deprecated/actions';

const featureFlagGroupingEnabled = APP.featureFlag.grouping;

export const MenuCell: React.FC<{ data: YardRow | null }> = ({ data }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isActive = data?.meta.isActive;
  const { gridApi } = useGridApi();

  const confirmClearOutYardModal = useDynamicModal();
  const confirmDeleteYardModal = useDynamicModal();
  const updateYardModal = useYardCreateOrUpdateModalOpener();

  const refreshTable = useCallback(() => {
    gridApi?.refreshInfiniteCache();
  }, [gridApi]);

  useOnYardSaveListener(useCallback(() => refreshTable(), [refreshTable]));

  const handleUpdateYardModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        updateYardModal.openForUpdate(data.meta.id);
      }
    },
    [data, updateYardModal]
  );

  const handleAssignGroupsModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        // Making it compatible with the old modal.
        const yard = { ...data.meta, group_ids: data.meta.groups.map((group) => group.id) };
        dispatch(
          makeOpenAssignYardsGroupsModalAction({
            modalType: 'assign-yard-groups',
            yard,
            onAssign: refreshTable,
          })
        );
      }
    },
    [data, dispatch, refreshTable]
  );

  const handleClearOutYardModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        confirmClearOutYardModal.open();
      }
    },
    [confirmClearOutYardModal, data]
  );

  const handleDeleteYardModal = useCallback(
    (e) => {
      e.stopPropagation();
      if (data) {
        confirmDeleteYardModal.open();
      }
    },
    [confirmDeleteYardModal, data]
  );

  const menuItems = useMemo<Array<MenuItem>>(() => {
    const items: Array<MenuItem> = [];

    items.push({ title: t('edit_yard'), icon: Edit, onClick: handleUpdateYardModal });
    items.push({ title: t('clear_out_yard'), icon: EmptyYard, disabled: !isActive, onClick: handleClearOutYardModal });

    featureFlagGroupingEnabled &&
      items.push({ title: t('assign_groups'), icon: Assign, onClick: handleAssignGroupsModal });

    items.push({ title: t('delete_yard'), icon: Delete, danger: true, onClick: handleDeleteYardModal });

    return items;
  }, [handleAssignGroupsModal, handleClearOutYardModal, handleDeleteYardModal, handleUpdateYardModal, isActive, t]);

  const modalCommonProps = useMemo(
    () => ({
      onDeleteSuccess: refreshTable /** When "just delete" action is success */,
      onClearOutSuccess: refreshTable /** When "just clear-out" action is success */,
      onCloseAfterSuccess: refreshTable /** When both actions are success, and the modal is closed */,
    }),
    [refreshTable]
  );

  return data ? (
    <>
      <Box fit justifyContent={'flex-end'} className={'yard-list--menu-cell'} style={{ position: 'relative' }}>
        <ThreeDotsMenuTrigger id={`menu-${data.meta.id}`} />
        <Menu items={menuItems} placement={'bottom-end'} target={`menu-${data.meta.id}`} />
      </Box>

      <YardDetailsClearOutAndDeleteModal
        {...confirmClearOutYardModal.modalProps}
        {...modalCommonProps}
        yardId={data.meta.id}
        primaryAction={'clear-out'}
      />
      <YardDetailsClearOutAndDeleteModal
        {...confirmDeleteYardModal.modalProps}
        {...modalCommonProps}
        yardId={data.meta.id}
        primaryAction={'delete'}
      />
    </>
  ) : null;
};
