import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as Logo } from '@assets/auth/auth-logo.svg';
import { AuthCarrousel } from '@components/auth/AuthCarrousel';
import { CreatePasswordForm } from '@components/auth/AuthForms/CreatePasswordForm';
import { ForgotPasswordForm } from '@components/auth/AuthForms/ForgotPasswordForm';
import { ForgotPasswordFormSuccess } from '@components/auth/AuthForms/ForgotPasswordFormSuccess';
import { LoginForm } from '@components/auth/AuthForms/LoginForm';
import { SelectOperationForm } from '@components/auth/AuthForms/SelectOperationForm';
import { Box } from '@components/common/Box';
import APP from '@config/constants';
import { QueryParams } from '@helpers/QueryParams';
import { useDispatch } from '@helpers/Thunk/hooks';
import { URLUtil } from '@helpers/URL';
import { makeAuthSignOutThunk } from '@redux/Auth/actions';
import {
  StyledAuthContent,
  StyledAuthContentTransition,
  StyledAuthContentTransitionContent,
} from '@scenes/auth/AuthPage/styles';

export const AuthPage: React.VFC = () => {
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToAuthScreen = useCallback(
    (path: string) => {
      return () => {
        if (history.location.pathname !== path) {
          history.push(path);
        }
      };
    },
    [history]
  );

  const attemptToSignOut = useCallback(() => {
    dispatch(makeAuthSignOutThunk());
  }, [dispatch]);

  const exitSelectOperation = useCallback(() => {
    const isSwitchingOperation = QueryParams.getCurrentQueryParams().switching === 'true';
    if (isSwitchingOperation) {
      const queryParams = QueryParams.getCurrentQueryParams();
      delete queryParams.switching;
      history.replace(URLUtil.buildPagePath(history.location.pathname, { queryParams }));
    } else {
      attemptToSignOut();
    }
  }, [attemptToSignOut, history]);

  useLayoutEffect(() => {
    setTimeout(() => setIsLayoutReady(true), theme.animations.durationMedium);
  }, [theme.animations.durationMedium]);

  const verticallyCenteredPages = [APP.routes.login, APP.routes.passwordCreate];
  const isVerticallyCentered = verticallyCenteredPages.includes(history.location.pathname);

  return (
    <Box fit stretch backgroundColor={'surfaceSecondary'}>
      <StyledAuthContent>
        <StyledAuthContentTransition content={isLayoutReady ? history.location : null}>
          {(location: any) => (
            <StyledAuthContentTransitionContent $isVerticallyCentered={isVerticallyCentered}>
              <Box>
                <Logo width={106} />
              </Box>
              <Switch location={location}>
                <Route path={APP.routes.login}>
                  <LoginForm onForgotPasswordClick={goToAuthScreen(APP.routes.passwordForgotten)} />
                </Route>
                <Route path={APP.routes.passwordForgotten}>
                  <ForgotPasswordForm onGoBackClick={goToAuthScreen(APP.routes.login)} />
                </Route>
                <Route path={APP.routes.passwordForgottenSuccess}>
                  <ForgotPasswordFormSuccess onGoBackClick={goToAuthScreen(APP.routes.login)} />
                </Route>
                <Route path={APP.routes.passwordCreate}>
                  <CreatePasswordForm />
                </Route>
                <Route path={APP.routes.selectOperation}>
                  <SelectOperationForm onGoBackClick={exitSelectOperation} />
                </Route>
              </Switch>
            </StyledAuthContentTransitionContent>
          )}
        </StyledAuthContentTransition>
      </StyledAuthContent>
      <AuthCarrousel />
    </Box>
  );
};
