import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { ChevronDown } from '@components/common/Icon/presets/ChevronDown';
import { ChevronUp } from '@components/common/Icon/presets/ChevronUp';
import { InputText, InputTextProps } from '@components/form/inputs/InputText';

import { StyledInputWrapper, StyledStepButton, StyledStepButtons } from './styles';

const INCREMENT_INITIAL_DELAY = 250;
const INCREMENT_SEQUENCE_DELAY = 60;
const INCREMENT_DIRECTION_NONE = 0;
const INCREMENT_DIRECTION_POSITIVE = 1;
const INCREMENT_DIRECTION_NEGATIVE = -1;
const INCREMENT_DEFAULT_STEP = 1;

export type InputNumberProps<FV extends FieldValues = FieldValues> = Omit<
  InputTextProps<FV>,
  'onKeyUp' | 'onKeyDown'
> & {
  step?: number;
};

export const InputNumber = <FV extends FieldValues = FieldValues>(props: InputNumberProps<FV>) => {
  const form = useFormContext<FV>();
  const [incrementDirection, setIncrementDirection] = useState<number>(INCREMENT_DIRECTION_NONE);

  const startIncrement = useCallback((direction: number) => setIncrementDirection(direction), []);
  const stopIncrement = useCallback(() => setIncrementDirection(INCREMENT_DIRECTION_NONE), []);

  const onKeyDown = useCallback<KeyboardEventHandler<any>>(
    (event) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        const direction =
          event.key === 'ArrowUp'
            ? INCREMENT_DIRECTION_POSITIVE
            : event.key === 'ArrowDown'
            ? INCREMENT_DIRECTION_NEGATIVE
            : INCREMENT_DIRECTION_NONE;
        startIncrement(direction);
      }
    },
    [startIncrement]
  );

  const onKeyUp = useCallback<KeyboardEventHandler<any>>(
    (event) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        stopIncrement();
      }
    },
    [stopIncrement]
  );

  useEffect(() => {
    const value: number = parseFloat(form.getValues(props.name));
    const step = parseFloat(String(props.step ?? INCREMENT_DEFAULT_STEP));

    if (incrementDirection !== 0) {
      let interval: ReturnType<typeof setInterval>;
      let safeNumericValue = !isNaN(value) ? value : 0;

      const increment = () => {
        safeNumericValue = safeNumericValue + step * incrementDirection;
        form.setValue(props.name, safeNumericValue as any, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      };

      increment();

      const timeout = setTimeout(() => {
        interval = setInterval(increment, INCREMENT_SEQUENCE_DELAY);
      }, INCREMENT_INITIAL_DELAY);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementDirection]);

  return (
    <StyledInputWrapper>
      <InputText type={'number'} onKeyDown={onKeyDown} onKeyUp={onKeyUp} {...props} />

      <StyledStepButtons>
        <StyledStepButton
          tabIndex={-1}
          onMouseDown={() => startIncrement(INCREMENT_DIRECTION_POSITIVE)}
          onMouseUp={stopIncrement}
          onMouseLeave={stopIncrement}
          disabled={props.disabled || props.readOnly}
        >
          <ChevronUp size={18} />
        </StyledStepButton>
        <StyledStepButton
          tabIndex={-1}
          onMouseDown={() => startIncrement(INCREMENT_DIRECTION_NEGATIVE)}
          onMouseUp={stopIncrement}
          onMouseLeave={stopIncrement}
          disabled={props.disabled || props.readOnly}
        >
          <ChevronDown size={18} />
        </StyledStepButton>
      </StyledStepButtons>
    </StyledInputWrapper>
  );
};
