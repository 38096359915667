import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { getDetailedActivitySet } from '@redux/Timeline/reducer';
import {
  ActivityByDateSet,
  ActivityByRequestSet,
  ActivityFilters,
  TimelineAction,
  TimelineActionType,
} from '@redux/Timeline/types';

const DEF_TIMELINE_ACTIVITIES_PAGE_SIZE = 20;
const DEF_TIMELINE_VISIT_ACTIVITIES_PAGE_SIZE = 20;

export const makeTimelineDisposeActivityAction = (): TimelineAction => ({
  type: TimelineActionType.TIMELINE_DISPOSE_ACTIVITY,
});

export const makeTimelineFetchActivityStartAction = (frozenAt: string): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_START,
  payload: {
    frozenAt,
  },
});

export const makeTimelineFetchActivityFinishAction = (
  data: ActivityByDateSet | null,
  error: ApiResponseError | null
): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_ACTIVITY_FINISH,
  payload: {
    data,
    error,
  },
});

export const makeTimelineFetchActivityThunk = Thunk.createTakeFirst((frozenAt: string, yardId?: number) => {
  return async (dispatch, getState) => {
    dispatch(makeTimelineFetchActivityStartAction(frozenAt));

    const offset = getState().timelineReducer.activitySet?.activities.length ?? 0;
    const limit = DEF_TIMELINE_ACTIVITIES_PAGE_SIZE;

    const response = await CachedRequest.performRequest(
      URLUtil.buildURL(ENDPOINTS.hhtActivitiesByDate, {
        limit,
        offset,
        frozen_at: frozenAt ?? null,
        yard: yardId ?? null,
      }),
      (url) => Api.get(url)
    );

    let data: ActivityByDateSet | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction(error.snackbarOptions));
    } else {
      data = parseTimelineActivityFromApi(frozenAt, await response.json());
    }

    dispatch(makeTimelineFetchActivityFinishAction(data, error));
  };
});

export const makeTimelineDisposeDetailedActivityAction = (filters: ActivityFilters): TimelineAction => ({
  type: TimelineActionType.TIMELINE_DISPOSE_DETAILED_ACTIVITY,
  payload: {
    filters,
  },
});

export const makeTimelineFetchVisitActivityStartAction = (filters: ActivityFilters): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_START,
  payload: { filters },
});

export const makeTimelineFetchVisitActivityFinishAction = (
  filters: ActivityFilters,
  data: ActivityByRequestSet | null,
  error: ApiResponseError | null
): TimelineAction => ({
  type: TimelineActionType.TIMELINE_FETCH_DETAILED_ACTIVITY_FINISH,
  payload: {
    filters,
    data,
    error,
  },
});

export const makeTimelineFetchVisitActivityThunk = Thunk.createTakeFirst((filters: ActivityFilters) => {
  return async (dispatch, getState) => {
    // Inherit the frozen at from the current parent timeline.
    const frozenAt = getState().timelineReducer.activitySet?.frozenAt ?? new Date().toISOString();

    dispatch(makeTimelineFetchVisitActivityStartAction(filters));

    const visitDetails = getDetailedActivitySet(getState().timelineReducer, filters);
    const offset = visitDetails?.activities.length ?? 0;
    const limit = DEF_TIMELINE_VISIT_ACTIVITIES_PAGE_SIZE;

    const response = await CachedRequest.performRequest(
      URLUtil.buildURL(ENDPOINTS.hhtActivitiesByRequest, {
        limit,
        offset,
        day: filters.date,
        yard: filters.yardId ?? null,
        hive_identity: filters.hiveId ?? null,
        lost_hives: filters.lostHives ? String(filters.lostHives) : null,
        alert_type: filters.alertTypes,
        practice_category: filters.practiceCategoryId ?? null,
        frozen_at: frozenAt,
      }),
      (url) => Api.get(url)
    );

    let data: ActivityByRequestSet | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction(error.snackbarOptions));
    } else {
      data = parseTimelineVisitActivityFromApi(await response.json());
    }

    dispatch(makeTimelineFetchVisitActivityFinishAction(filters, data, error));

    if (error) {
      throw error;
    }
  };
});

function parseTimelineActivityFromApi(frozenAt: string, { count, results }: any): ActivityByDateSet {
  return {
    totalCount: count,
    activities: removeDuplicatedActivities(results.map((entry: any) => CaseAdapter.objectToCamelCase(entry))),
    frozenAt,
  };
}

export function parseTimelineVisitActivityFromApi({ count, results }: any): ActivityByRequestSet {
  return {
    totalCount: count,
    activities: results.map((entry: any) => CaseAdapter.objectToCamelCase(entry)),
  };
}

/**
 * Some API edge cases may cause duplicated activities. This
 * is a temporary fix from the frontend side.
 * */
function removeDuplicatedActivities(activities: Array<BeeActivityByDate>) {
  const seen: Record<string, boolean> = {};
  const toKey = (activity: BeeActivityByDate) => `${activity.occurredAt}:${activity.yardId}:${activity.alertType}`;
  const cleanActivities: Array<BeeActivityByDate> = [];

  activities.forEach((activity) => {
    const key = toKey(activity);
    if (!(key in seen)) {
      seen[key] = true;
      cleanActivities.push(activity);
    }
  });

  return cleanActivities;
}
