/**
 * TODO: Move everything to ./components/styles.
 * */

import styled from 'styled-components';

import { Button } from '../CTA';

export const StyledMapControlWrapper = styled.div(
  ({ theme }) => `
  position: absolute;
  bottom: 2rem;
  right: ${theme.spacing._100};
`
);

export const StyledMapControlButton = styled(Button).attrs({ type: 'button', suppressPadding: true })(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${theme.primitives.colors.grey08};
  border-radius: ${theme.shape.paperRadius01}px;
  &:hover {
    path {
      fill: ${theme.primitives.colors.grey05};
    }
  }
`
);

export const StyledMapControlButtonPlus = styled(StyledMapControlButton)(
  ({ theme }) => `
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid ${theme.primitives.colors.grey07};
`
);

export const StyledMapControlButtonMinus = styled(StyledMapControlButton)(
  () => `
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`
);

export const StyledMapControlButtonLayers = styled(StyledMapControlButton)<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
  margin-bottom: ${theme.spacing._050};
  transform: scale(${isVisible ? 1 : 0});
  transition: ${theme.animations.transitionMedium('transform')};
`
);

export const StyledMapPane = styled.div(
  () => `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
);

export const StyledMapPaneDrawer = styled.div<{ left: number; width: number; scrollable?: boolean }>(
  ({ theme, left, width, scrollable }) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden ${scrollable ? 'auto' : 'hidden'};
  top: 0;
  bottom: 0;
  left: ${left}px;
  width: ${width}px;
  transition: ${theme.animations.transitionFast('left')};
  background-color: ${theme.primitives.colors.white};
  border-top: 1px solid ${theme.primitives.colors.grey04};
  z-index: ${theme.getZIndexOf('content')};
`
);

export const StyledMapPaneWrapper = styled.div<{ left: number }>(
  ({ theme, left }) => `
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: ${left}px;
  transition: ${theme.animations.transitionFast('left')};
  z-index: ${theme.getZIndexOf('base')};
`
);

export const StyledMapPaneToggleButton = styled(StyledMapControlButton)<{ isVisible: boolean; left: number }>(
  ({ theme, isVisible, left }) => `
  position: absolute;
  bottom: 2rem;
  left: ${theme.spacing._100};
  transition: ${theme.animations.transitionFast('transform')};
  opacity: ${isVisible ? 1 : 0};
  transform: translateX(${left}px);
`
);

export const StyledMapPaneToggleIconWrapper = styled.div<{ open: boolean }>(
  ({ theme, open }) => `
  transition: ${theme.animations.transitionFast('transform')};
  transform: rotate(${open ? 180 : 0}deg);
`
);
