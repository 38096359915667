import { useCallback, useState } from 'react';

import { Action } from '@components/common/BulkActionsProgress/types';

/**
 * Shortcut to use stateful actions for the BulkActionsProgress component.
 * */
export function useBulkActionsProgressState(
  initialState: Array<Action>
): [actions: Array<Action>, setAction: (index: number, nextAction: Partial<Action>) => void, resetActions: () => void] {
  const [actions, setActions] = useState(initialState);

  const setAction = useCallback((index: number, nextAction: Partial<Action>) => {
    setActions(([...curr]) => {
      if (index < curr.length) {
        curr[index] = { ...curr[index], ...nextAction };
      }
      return curr;
    });
  }, []);

  const resetActions = useCallback(() => {
    actions.forEach((_, index) => setAction(index, { status: 'idle' }));
  }, [actions, setAction]);

  return [actions, setAction, resetActions];
}
