import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';
import { Animation } from '@helpers/Animation';

import { AnimationType } from './types';

const PRESET_CHANGING_ANIMATIONS = {
  fade: {
    in: (duration: number) =>
      `
        opacity: 1;
        ${Animation.createCSSKeyframes('anim-fade-in', (progress) => `opacity: ${progress}`)}
        animation: anim-fade-in ${duration}ms linear 0ms 1;
      `,
    out: (duration: number) =>
      `
        opacity: 0;
        ${Animation.createCSSKeyframes('anim-fade-out', (progress) => `opacity: ${1 - progress}`)}
        animation: anim-fade-out ${duration}ms linear 0ms 1;
      `,
  },
  focus: {
    in: (duration: number) =>
      `
        opacity: 1;
        ${Animation.createCSSKeyframes(
          'anim-focus-in',
          (progress) => `filter: blur(${(1 - progress) * 2.5}px); opacity: ${progress}`
        )}
        animation: anim-focus-in ${duration}ms linear 0ms 1;
      `,
    out: (duration: number) =>
      `
        opacity: 0;
        ${Animation.createCSSKeyframes(
          'anim-focus-out',
          (progress) => `filter: blur(${progress * 2.5}px); opacity: ${1 - progress}`
        )}
        animation: anim-focus-out ${duration}ms linear 0ms 1;
      `,
  },
} as Record<AnimationType, { in: (duration: number) => string; out: (duration: number) => string }>;

export const StyledAnimatedChangingContent = styled(Box)<{
  $type: AnimationType;
  $duration: number;
  $stage: 'in' | 'out';
}>`
  position: relative;
  ${({ $type, $duration, $stage }) => PRESET_CHANGING_ANIMATIONS[$type][$stage]($duration)}
`;

const AppearingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(5vh);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledAnimatedAppearingContent = styled(Box)<{ $duration: number }>(
  ({ theme, $duration }) => css`
    & > * {
      animation-name: ${AppearingAnimation};
      animation-timing-function: ${theme.animations.easing};
      animation-iteration-count: 1;
      animation-duration: ${$duration * 3}ms;
    }

    ${() =>
      new Array(10)
        .fill(null)
        .map(
          (_, index, arr) => `
            & > *:nth-child(${index + 1}) {
              animation-duration: ${$duration + 2 * $duration * (index / arr.length)}ms;
            }
          `
        )
        .join('\n')}
  `
);
