export const Number = {
  getReadableNumber,
  interpolate,
};

/**
 * Fixes float point imprecision. It happens sometimes
 * when deserializing JSON objects. For instance, "1" can
 * become "0.9999999998".
 * */
function getReadableNumber(n: number | null | undefined, decimalPlaces = 2, defaultValue = '-'): string {
  if (n === null || n === undefined) {
    return defaultValue;
  }
  const precision = Math.pow(10, decimalPlaces);
  return String(Math.round(n * precision) / precision);
}

/**
 * Transposes the given value from the given input range
 * to the given output range.
 * Example:
 *    // 5 from the input range is equivalent
 *    // to 50 in the output range.
 *    Number.interpolate(5, [0, 10], [0, 100])
 *    >>> 50
 * */
function interpolate(n: number, inputRange: [number, number], outputRange: [number, number]) {
  const [iStart, iEnd] = inputRange;
  const [oStart, oEnd] = outputRange;
  const iPos = (n - iStart) / (iEnd - iStart);
  return oStart + iPos * (oEnd - oStart);
}
