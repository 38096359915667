import React from 'react';
import { useSelector } from 'react-redux';

import { LoadingDownload } from '@components/common/LoadingDownload';

import { ReportsHeader } from './ReportsHeader';
import { ReportsPage } from './ReportsPage';

export const Reports: React.FC = () => {
  const isFetching = useSelector((state) => state.reportsReducer.isFetching);

  if (isFetching) {
    return <LoadingDownload />;
  }

  return (
    <>
      <ReportsHeader />
      <ReportsPage />
    </>
  );
};
