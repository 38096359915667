import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize, toLower } from 'lodash';

import { Box } from '@components/common/Box';
import { DataStatusContentDot } from '@components/common/DataStatusContentDot';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { YardVisitCard } from '@components/common/YardVisitCard';
import {
  StyledHeading,
  StyledUnderlinedButton,
  StyledYardBoxInfo,
  StyledYardNameLink,
} from '@components/timeline/TimelineBase/styles';
import { StyledYardVisitWrapper } from '@components/timeline/TimelineByActivityDate/styles';
import { useTimelineByActivityRequestModalOpener } from '@components/timeline/TimelineByActivityRequest/hooks';
import { YardName } from '@components/yard/YardName';
import APP from '@config/constants';
import { Children } from '@helpers/Children';
import { useDateUtil } from '@helpers/Date/hooks';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { useGlobalTimelineDataStatus, useYardTimelineDataStatus } from '@redux/DataStatus/hooks';
import { ActivityFilters } from '@redux/Timeline/types';

export function useItemHeadingRender(options: { suppressYardName?: boolean; highlightedYardId?: number }) {
  const t = useTranslation();
  const history = useHistory();
  const dateUtil = useDateUtil();

  const globalDataStatus = useGlobalTimelineDataStatus();
  const yardDataStatus = useYardTimelineDataStatus(options.highlightedYardId ?? -1);
  const dataStatus = options.highlightedYardId ? yardDataStatus : globalDataStatus;

  const { openTimelineByActivityRequestModal } = useTimelineByActivityRequestModalOpener();

  const openYardVisitModal = useCallback(
    (filters: ActivityFilters) => {
      openTimelineByActivityRequestModal({
        ...filters,
        date: dateUtil.getFormattedDate(filters.date, 'YYYY-MM-DD'),
      });
    },
    [dateUtil, openTimelineByActivityRequestModal]
  );

  return useCallback(
    (item: BeeActivityByDate) => {
      const yardPagePath = item.yardId
        ? URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: item.yardId } })
        : null;
      const isAlreadyOnYardPage = yardPagePath && history.location.pathname.startsWith(yardPagePath);
      const canLinkToYardPage = yardPagePath && !isAlreadyOnYardPage;

      const isNewDot = <DataStatusContentDot timestamp={+new Date(item.occurredAt)} dataStatus={dataStatus} />;

      const nbHivesTooltip = !item.yardIsDeleted && (
        <Tooltip>
          <Text typography={'CaptionSmall'}>
            {t('timeline_yard_nb_hives_today_tooltip', { count: item.yardNbHives })}
          </Text>
        </Tooltip>
      );

      const yardName = <YardName name={item.yardName} contractName={item.yardContractName} suppressStyling />;
      const yardLabel = (
        <>
          {item.yardName ? (
            <>
              {canLinkToYardPage ? (
                <StyledYardNameLink to={yardPagePath}>{yardName}</StyledYardNameLink>
              ) : (
                <strong>{yardName}</strong>
              )}{' '}
              <StyledYardBoxInfo typography={'CaptionSmall'}>
                {item.yardIsDeleted ? t('deleted') : item.yardNbHives}
                {nbHivesTooltip}
              </StyledYardBoxInfo>
            </>
          ) : (
            <strong>{t('unknown_yard')}</strong>
          )}{' '}
        </>
      );

      const byWorkerLabel = (
        <>
          {t('by')}{' '}
          {Children.createWithSeparators(item.workerNames, {
            lastSeparator: `, ${t('and')} `,
            singleSeparator: ` ${t('and')} `,
          })}
        </>
      );

      const bounds_updated_label = item.hasYardBeenResized ? t('yard_boundaries_updated') : null;

      switch (item.alertType) {
        case 'new_yard_visit':
          return (
            <StyledHeading typography={'Heading3'}>
              <StyledUnderlinedButton
                onClick={() =>
                  openYardVisitModal({
                    date: item.occurredAt,
                    yardId: item.yardId,
                    alertTypes: ['new_inspection'],
                  })
                }
              >
                {t('yard_visit')}
              </StyledUnderlinedButton>{' '}
              {!options.suppressYardName && (
                <>
                  {t('at')} {yardLabel}
                </>
              )}{' '}
              {byWorkerLabel}
              {'.'}
              {isNewDot}
            </StyledHeading>
          );
        case 'hive_managed_to_yard':
        case 'hive_added_to_yard':
          return (
            <Box column>
              <StyledHeading typography={'Heading3'}>
                <StyledUnderlinedButton
                  onClick={() =>
                    openYardVisitModal({
                      date: item.occurredAt,
                      yardId: item.yardId,
                      alertTypes: ['hive_added_to_yard', 'hive_managed_to_yard'],
                    })
                  }
                >
                  <strong> {toLower(maybePluralize(item.nbHives, 'hive', t))}</strong>
                </StyledUnderlinedButton>{' '}
                <strong>{t('added')}</strong>{' '}
                {!options.suppressYardName && (
                  <>
                    {t('to')} {yardLabel}
                  </>
                )}{' '}
                {byWorkerLabel}
                {'.'}
                {isNewDot}
              </StyledHeading>
              <StyledHeading typography={'Heading3'}>{bounds_updated_label}</StyledHeading>
            </Box>
          );
        case 'lost_hive_marked_as_removed':
        case 'lost_hive_marked_as_deadout':
          return (
            <Box column>
              <StyledHeading typography={'Heading3'}>
                <StyledUnderlinedButton
                  onClick={() =>
                    openYardVisitModal({
                      date: item.occurredAt,
                      alertTypes: [item.alertType as BeeActivityByRequestAlertType],
                      lostHives: true,
                    })
                  }
                >
                  <strong>
                    {item.nbHives} {t('lost')} {toLower(maybePluralize(item.nbHives, 'hive', t, 's', false))}
                  </strong>
                </StyledUnderlinedButton>{' '}
                {item.nbHives === 1 ? t('was') : t('were')}{' '}
                {item.alertType === 'lost_hive_marked_as_removed' ? (
                  <strong>{t('removed')}</strong>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: toLower(
                        item.nbHives === 1 ? t('flagged_as_deadout_bold_single') : t('flagged_as_deadout_bold')
                      ),
                    }}
                  />
                )}{' '}
                {byWorkerLabel}
                {'.'}
                {isNewDot}
              </StyledHeading>
            </Box>
          );
        case 'new_yard_created':
          return (
            <StyledHeading typography={'Heading3'}>
              {options.suppressYardName ? (
                <strong>{capitalize(t('created'))}</strong>
              ) : (
                <>
                  {yardLabel} {t('was')} <strong>{t('created')}</strong>
                </>
              )}{' '}
              {byWorkerLabel}
              {'.'}
              {isNewDot}
            </StyledHeading>
          );
        case 'yard_edited_by_manager':
          return (
            <StyledHeading typography={'Heading3'}>
              {options.suppressYardName ? (
                <>
                  <strong>{capitalize(t('info'))}</strong> {t('was')} <strong>{t('updated')}</strong>
                </>
              ) : (
                <>
                  {yardLabel} <strong>{t('info')}</strong> {t('was')} <strong>{t('updated')}</strong>
                </>
              )}{' '}
              {byWorkerLabel}
              {'.'}
              {isNewDot}
            </StyledHeading>
          );
        case 'yard_cleared_out':
          return (
            <StyledHeading typography={'Heading3'}>
              {options.suppressYardName ? <strong>{capitalize(t('yard'))}</strong> : <>{yardLabel}</>} {t('was')}{' '}
              <strong>{t('cleared_out')}</strong> {t('of')}{' '}
              <StyledUnderlinedButton
                onClick={() =>
                  openYardVisitModal({
                    date: item.occurredAt,
                    yardId: item.yardId,
                    alertTypes: ['yard_cleared_out'],
                  })
                }
              >
                <strong> {toLower(maybePluralize(item.nbHives, 'hive', t))}</strong>
              </StyledUnderlinedButton>{' '}
              {byWorkerLabel}
              {'.'}
              {isNewDot}
            </StyledHeading>
          );
        case 'yard_deleted':
          return (
            <StyledHeading typography={'Heading3'}>
              {options.suppressYardName ? <strong>{capitalize(t('yard'))}</strong> : <>{yardLabel}</>} {t('was')}{' '}
              <strong>{t('deleted')}</strong> {byWorkerLabel}
              {'.'}
              {isNewDot}
            </StyledHeading>
          );
        default:
          return null;
      }
    },
    [dataStatus, history.location.pathname, openYardVisitModal, options.suppressYardName, t]
  );
}

export function useItemContentRenderer() {
  return useCallback((item: BeeActivityByDate) => {
    if (item.alertType !== 'new_yard_visit') {
      return null;
    }
    return (
      <StyledYardVisitWrapper column stretch>
        <YardVisitCard
          occurredAt={item.occurredAt}
          workerNames={item.workerNames}
          yardNbHives={item.yardNbHives}
          yardNbHivesOnVisitDate={item.yardNbHivesOnVisitDate}
          practices={[...item.practices]}
          notes={item.notes.filter(Boolean)}
        />
      </StyledYardVisitWrapper>
    );
  }, []);
}
