import { useCallback } from 'react';

import { useDynamicTableUtil } from '@components/common/DynamicTable/hooks/useDynamicTableUtil';
import { DynamicTableConfig } from '@components/common/DynamicTable/types';

import { useDynamicTableDataCycle } from './useDynamicTableDataCycle';

export function useDynamicTablePaginationHelper(options?: { pageParamName?: string; config?: DynamicTableConfig }) {
  const tableUtil = useDynamicTableUtil(options?.config);

  const applyPageFromURL = useCallback(
    (gridApi) => {
      const initialPage = tableUtil.getPageFromURLParams(options);
      gridApi.paginationGoToPage(initialPage);
    },
    [options, tableUtil]
  );

  const updatePageToURL = useCallback(
    (gridApi) => {
      const currentPage = gridApi.paginationGetCurrentPage() ?? 0;
      tableUtil.setPageToURLParams({ page: currentPage, ...options });
    },
    [options, tableUtil]
  );

  useDynamicTableDataCycle({
    onDataServerLoad: applyPageFromURL,
    onDataServerReload: updatePageToURL,
  });
}
