import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  BulkActionButton,
  BulkActionsNav,
  BulkActionTooltip,
  BulkActionWrapper,
} from '@components/common/BulkActionsNav';
import { Close } from '@components/common/Icon';
import { Contract } from '@components/common/Icon/presets/Contract';
import { Delete } from '@components/common/Icon/presets/Delete';
import { EmptyYard } from '@components/common/Icon/presets/EmptyYard';
import { Group } from '@components/common/Icon/presets/Group';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useContractSummaries } from '@hooks/useContract';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenAssignContractModal } from '@redux/AssignContract/actions';
import { AssignContractYard } from '@redux/AssignContract/types';
import { makeBulkClearOutYardsModalOpenAction } from '@redux/BulkClearOutYards/actions';
import { makeBulkDeleteYardsModalOpenAction } from '@redux/BulkDeleteYards/actions';
import { makeOpenManyToManyGroupsModalAction } from '@redux/deprecated/actions';

import { useYardsListSelection } from '../YardsList/hooks/useYardsListSelectionContext';

export const YardsListBulkActionsNav: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { selectedYards, selectionCount, hasClickedSelectAllAcrossPages, deselectAllAcrossPages } =
    useYardsListSelection();
  const contractSummaries = useContractSummaries();
  const disableContractButton = hasClickedSelectAllAcrossPages;
  const noContracts = !contractSummaries || !contractSummaries.length;
  const shouldDisableContractButton = disableContractButton || noContracts;

  const hasSelectedActiveYards = Object.values(selectedYards).some((yard) => yard.meta.isActive);
  const hasSelectedInactiveYards = Object.values(selectedYards).some((yard) => !yard.meta.isActive);

  const shouldDisableClearOutButton = !hasSelectedActiveYards || hasClickedSelectAllAcrossPages;
  const shouldDisableDeleteButton = !hasSelectedInactiveYards || hasClickedSelectAllAcrossPages;

  const contractButtonTooltipText = disableContractButton
    ? 'assign_contract_disabled_all_yards'
    : noContracts
    ? 'assign_contract_disabled_no_contracts'
    : 'assign_contract';

  const handleManyToManyGroupsModal = useCallback(() => {
    const modalObj = {
      modalType: 'assign-many-to-many',
      yard_ids: Object.keys(selectedYards),
      hasSelectedAllYardsAcrossPages: hasClickedSelectAllAcrossPages,
    };
    dispatch(makeOpenManyToManyGroupsModalAction(modalObj));
  }, [dispatch, hasClickedSelectAllAcrossPages, selectedYards]);

  const handleBulkAssignContractModal = useCallback(() => {
    const assignContractYards: AssignContractYard[] = Object.values(selectedYards).map((yard) => {
      return {
        id: yard.meta.id,
        contractName: yard.meta.contractName,
        name: yard.meta.name,
      };
    });
    dispatch(makeOpenAssignContractModal(assignContractYards));
  }, [dispatch, selectedYards]);

  const handleBulkClearOutYardsModal = useCallback(() => {
    dispatch(makeBulkClearOutYardsModalOpenAction());
  }, [dispatch]);

  const handleBulkDeleteYardsModal = useCallback(() => {
    dispatch(makeBulkDeleteYardsModalOpenAction());
  }, [dispatch]);

  return (
    <BulkActionsNav
      isVisible={!!selectionCount}
      leftHandText={t('yards_selected', {
        number: maybePluralize(selectionCount, 'yard', t),
      })}
    >
      <BulkActionButton onClick={handleManyToManyGroupsModal}>
        <BulkActionWrapper id="assign-to-group-icon">
          <Group size={24} />
        </BulkActionWrapper>

        <Tooltip target="assign-to-group-icon">
          <Text typography="CaptionSmall">{t('assign_groups')}</Text>
        </Tooltip>
      </BulkActionButton>

      <BulkActionButton onClick={handleBulkAssignContractModal} disabled={shouldDisableContractButton}>
        <BulkActionWrapper id="assign-to-contract-icon">
          <Contract size={24} />
        </BulkActionWrapper>

        <BulkActionTooltip target="assign-to-contract-icon">
          <Text typography="CaptionSmall">{t(contractButtonTooltipText)}</Text>
        </BulkActionTooltip>
      </BulkActionButton>

      <BulkActionButton onClick={handleBulkClearOutYardsModal} disabled={shouldDisableClearOutButton}>
        <BulkActionWrapper id="clear-out-icon">
          <EmptyYard size={24} />
        </BulkActionWrapper>

        <BulkActionTooltip target="clear-out-icon">
          <Text typography="CaptionSmall">{t('clear_out_yards')}</Text>
        </BulkActionTooltip>
      </BulkActionButton>

      <BulkActionButton onClick={handleBulkDeleteYardsModal} disabled={shouldDisableDeleteButton}>
        <BulkActionWrapper id="delete-icon">
          <Delete size={24} />
        </BulkActionWrapper>

        <BulkActionTooltip target="delete-icon">
          <Text typography="CaptionSmall">{t('delete_yards')}</Text>
        </BulkActionTooltip>
      </BulkActionButton>

      <BulkActionButton onClick={() => deselectAllAcrossPages && deselectAllAcrossPages()}>
        <BulkActionWrapper id="clear-selection-icon">
          <Close size={24} />
        </BulkActionWrapper>

        <Tooltip target="clear-selection-icon">
          <Text typography="CaptionSmall">{t('clear_selection')}</Text>
        </Tooltip>
      </BulkActionButton>
    </BulkActionsNav>
  );
};
