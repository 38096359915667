import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { YardName } from '@components/yard/YardName';
import { between, from } from '@style/mediaQueries';

const SEARCH_INPUT_HEIGHT_MOBILE = '56px';
const SEARCH_TOP_OFFSET_MOBILE = '42px'; // the 'yards' header at the top, a bit eyeballed

export const StyledSearch = styled.div<{ isSearchOpen: boolean }>(
  ({ isSearchOpen, theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 34px;
    transform: ${isSearchOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: ${theme.animations.transitionMedium('transform')};
    ${from('desktopSM')} {
      transform: initial;
      min-width: 400px;
    }
  `
);

export const StyledSearchWrapper = styled.div(
  ({ theme }) => css`
    // mobile
    color: ${theme.colors.contentPrimary};
    background-color: ${theme.colors.surfaceSecondary};

    ${from('desktopSM')} {
      border-radius: ${theme.borderRadius._025};
      background-color: ${theme.primitives.colors.grey07};
      color: ${theme.primitives.colors.grey05};
      width: 400px;
      margin-left: auto;

      &:focus-within ${StyledResultsDrawer} {
        display: block;
      }
    }

    input {
      flex-grow: 1;
    }
  `
);

export const StyledSearchInputWrapper = styled(Box)(
  ({ theme }) => css`
    min-height: ${SEARCH_INPUT_HEIGHT_MOBILE};
    padding: ${theme.spacing._050} ${theme.spacing._075};
    ${from('desktopSM')} {
      min-height: auto;
      padding: ${theme.spacing._050} ${theme.spacing._100};
    }
  `
);

export const SearchInput = styled.input(
  ({ theme }) => css`
    background-color: inherit;
    font-size: 14px;
    border: none;
    line-height: ${theme.lineHeight._150};
    padding-left: ${theme.spacing._050};
    color: currentColor;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: ${theme.colors.contentTertiary};
      ${from('desktopSM')} {
        color: ${theme.primitives.colors.grey05};
      }
    }
  `
);

export const StyledClearButton = styled(Button).attrs({ unstyled: true })<{ $visible?: boolean }>(
  ({ $visible }) => css`
    visibility: ${$visible ? 'visible' : 'hidden'};
    opacity: ${$visible ? '1' : '0'};
  `
);

export const StyledDrawerWrapper = styled(Box)(
  ({}) => css`
    position: relative;
    height: calc(100vh - ${SEARCH_INPUT_HEIGHT_MOBILE});
    ${from('desktopSM')} {
      height: auto;
    }
  `
);

export const StyledResultsDrawer = styled.div(
  ({ theme }) => css`
    width: 100%;
    position: relative;
    border-top: 1px solid ${theme.primitives.colors.grey06};
    padding: ${theme.spacing._050} 0 0;

    ${from('desktopSM')} {
      display: none;
    }
  `
);
export const StyledResults = styled.div(
  ({ theme }) => css`
    overflow: auto;
    height: calc(100vh - (${SEARCH_INPUT_HEIGHT_MOBILE} + ${SEARCH_TOP_OFFSET_MOBILE}));
    padding-bottom: ${theme.spacing._050};

    ${from('desktopSM')} {
      height: auto;
      max-height: 384px;
      padding-bottom: initial;
    }
  `
);

export const StyledYardButton = styled(Button).attrs({ unstyled: true })(
  ({ theme }) => `
      display: flex;
      align-items: center;
      padding: ${theme.spacing._100};
      padding-right: 0;
      border-left: 2px solid transparent;
    `
);

export const StyledViewOnMapButton = styled(Button).attrs({ unstyled: true })(
  ({ theme }) => css`
    display: flex;
    white-space: nowrap;
    padding: ${theme.spacing._100};
    padding-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  `
);

export const StyledResult = styled(Box).attrs({ alignItems: 'center', fullWidth: true })(
  ({ theme }) => css`
    ${from('desktopSM')} {
      &:focus-within,
      &:hover {
        background-color: ${theme.primitives.colors.grey08};

        ${StyledYardButton} {
          border-left-color: ${theme.primitives.colors.orange01};
        }
      }
    }
  `
);

export const StyledYardName = styled(YardName)`
  max-width: calc(100vw - 170px);
  text-align: left;
  margin-right: 8px;
  white-space: nowrap;

  ${between('tablet', 'tablet')} {
    max-width: calc(100vw - 242px);
  }

  ${from('desktopSM')} {
    max-width: auto;
    width: 160px;
  }
`;

export const ActiveStatus = styled.span<{ $active: boolean }>(
  ({ $active, theme }) => css`
    margin-right: ${theme.spacing._050};
    margin-left: ${theme.spacing._100};
    min-height: 6px;
    min-width: 6px;
    background-color: ${$active ? theme.primitives.colors.green02 : theme.primitives.colors.yellow02};
    border-radius: 50%;
    display: inline-block;
  `
);

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const StyledNbHives = styled.span`
  padding: 1px 8px 0 0;
  font-size: 12px;
  white-space: nowrap;
`;

export const StyledResultsFooter = styled.div(
  ({ theme }) => css`
    border-top: 1px solid ${theme.primitives.colors.grey06};
    padding: ${theme.spacing._100};

    strong {
      font-weight: 700;
    }
  `
);
