import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const PaneContent = styled(Box)<{ visible: boolean; scrollable?: boolean }>(
  ({ visible, scrollable }) => `
  flex: 1;
  flex-direction: column;
  display: ${visible ? 'flex' : 'none'};
  overflow: ${scrollable ? 'hidden auto' : 'hidden'};
`
);

export const ListItem = styled.button(
  ({ theme }) => `
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing._100};
  color: ${theme.primitives.colors.grey08};
  border-radius: ${theme.shape.paperRadius01};
  
  &:first-child {
    margin-top: 0;
  }
  
  &:hover {
    color: ${theme.primitives.colors.grey06};
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02};
  }
`
);

export const SummaryHeader = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.primitives.colors.grey04};
  padding-bottom: ${theme.spacing._100};
  margin: ${theme.spacing._100} ${theme.spacing._100} 0 ${theme.spacing._100};
`
);

export const SummaryHeaderTitle = styled(Text)(
  () => `
  display: flex;
  flex: 1;
`
);

export const SummaryContent = styled(Box)(
  ({ theme }) => `
  flex: 1 1 0;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden auto;
  padding-bottom: ${theme.spacing._100};
`
);

export const SummaryRow = styled(Box)(
  ({ theme }) => `
  position: relative;
  align-items: center;
  padding-bottom: ${theme.spacing._100};
  margin: ${theme.spacing._100} ${theme.spacing._100} 0 ${theme.spacing._100};
`
);

export const SummaryPaddedRow = styled(Box)(
  ({ theme }) => `
  position: relative;
  align-items: center;
  height: 32px;
  padding-left: ${theme.spacing._050};
  margin-left: ${theme.spacing._150};
  margin-right: ${theme.spacing._100};
  border-left: 1px solid ${theme.primitives.colors.grey08};
`
);

export const SummaryPaddedColumn = styled(Box)(
  ({ theme }) => `
  flex-direction: column;
  padding-left: ${theme.spacing._050};
  margin-left: ${theme.spacing._150};
  margin-right: ${theme.spacing._100};
  border-left: 1px solid ${theme.primitives.colors.grey08};
`
);

export const SummaryTitle = styled(Text)(
  ({ theme }) => `
  display: flex;
  flex: 1;
  margin-left: ${theme.spacing._050};
`
);

export const SummaryChip = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 28px;
  border-radius: 14px;
  background-color: ${theme.primitives.colors.grey08};
  color: ${theme.primitives.colors.white};
  margin-left: ${theme.spacing._100};
  padding: 0 12px;
`
);

export const SummaryClose = styled.button(
  ({ theme }) => `
  border-radius: 50%;
  border: none;
  outline: none;
  color: ${theme.primitives.colors.grey08};
  
  &:hover {
    color: ${theme.primitives.colors.grey06};
  }
  
  &:focus-visible {
    box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02};
  }
`
);

export const SummaryFooter = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing._100};
`
);

export const PoiFloatingIconWrapper = styled.div(
  ({ theme }) => `
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${theme.primitives.colors.grey08}; 
`
);

export const ProgressValue = styled(Text)(
  () => `
  cursor: pointer; 
`
);
