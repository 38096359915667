import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { BulkActionsProgress } from '@components/common/BulkActionsProgress';
import { useBulkActionsProgressState } from '@components/common/BulkActionsProgress/hooks';
import { Button } from '@components/common/CTA';
import { Loading } from '@components/common/Loading';
import { Modal } from '@components/common/Modal';
import { ModalProps } from '@components/common/Modal/types';
import { ModalFooter, ModalHeader } from '@components/common/ModalBase';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useIntercomArticleOpener } from '@helpers/Intercom/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';
import { makeYardClearOrDeleteDeletableFetchThunk } from '@redux/YardClearOrDelete/actions';
import { deleteYardThunk, emptyYardThunk } from '@redux/Yards/actions';

export const YardDetailsClearOutAndDeleteModal: React.VFC<
  ModalProps & {
    yardId: number | null | undefined;

    /**
     * Sets if the action comes from the "delete"
     * or from the "clear out" button.
     * */
    primaryAction: 'delete' | 'clear-out';

    /**
     * Called as soon as all modal actions are successfully finished,
     * regardless if it's a "delete", "clea-out" or "clear-out and delete"
     * actions.
     * */
    onSuccess?: () => void;

    /** Called as soon as the "delete" action is successfully finished */
    onDeleteSuccess?: () => void;

    /** Called as soon as the "clear-out" action is successfully finished */
    onClearOutSuccess?: () => void;

    /**
     * Called as soon as the "clear-out and delete" action is successfully
     * finished
     * */
    onClearOutAndDeleteSuccess?: () => void;

    /**
     * Called as soon as the actions are performed successfully and the
     * "close" button is clicked
     * */
    onCloseAfterSuccess?: () => void;
  }
> = ({
  yardId,
  primaryAction,
  onSuccess,
  onDeleteSuccess,
  onClearOutSuccess,
  onClearOutAndDeleteSuccess,
  onCloseAfterSuccess,
  ...props
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const { isMobile } = useGetScreenWidth();
  const { isFetchingDeletable, isDeletable } = useSelector((state) => state.yardClearOrDeleteReducer);

  const [clearOutAndDeleteActions, setClearOutAndDeleteAction, resetClearOutAndDeleteActions] =
    useBulkActionsProgressState([
      {
        title: t('clear_out_yard_loading'),
        status: 'idle',
      },
      {
        title: t('delete_yard_loading'),
        status: 'idle',
      },
    ]);

  const [showRequestsProgress, setShowRequestsProgress] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isClearingOut, setIsClearingOut] = useState(false);
  const [didSucceed, setDidSucceed] = useState(false);
  const [didFailDelete, setDidFailDelete] = useState(false);
  const [didFailClearOut, setDidFailClearOut] = useState(false);

  const didFail = didFailDelete || didFailClearOut;
  const didFailOrSucceed = didFail || didSucceed;

  const isClearingOutOrDeleting = isClearingOut || isDeleting;
  const isClearingOutXorDeleting = isClearingOut !== isDeleting;
  const showMainLoading = (isFetchingDeletable || isClearingOutXorDeleting) && !didFail;

  // When the user clicks to delete a yard that is inactive, thus deletable.
  const isDeleteOnlyAction = primaryAction === 'delete' && isDeletable;

  // When the user clicks to delete a yard that is still active, thus it needs to be cleared before deleting.
  const isDeleteWithClearOutAction = primaryAction === 'delete' && !isDeletable;

  // When the user clicks to clear out a yard.
  const isClearOutAction = primaryAction === 'clear-out';

  const suppressHeaderCloseButton = useMemo(
    () => !isMobile && isDeleteWithClearOutAction,
    [isDeleteWithClearOutAction, isMobile]
  );

  const updateDeletableStatus = useCallback(async () => {
    if (!yardId) {
      return;
    }
    await dispatch(makeYardClearOrDeleteDeletableFetchThunk(yardId));
  }, [dispatch, yardId]);

  const closeModal = useCallback(() => {
    props.onRequestClose && props.onRequestClose();
  }, [props]);

  const performClearOut = useCallback(async () => {
    if (!yardId) {
      return;
    }

    setDidFailClearOut(false);
    setIsClearingOut(true);

    try {
      await dispatch(emptyYardThunk(yardId));
      closeModal();
      onSuccess && onSuccess();
      onClearOutSuccess && onClearOutSuccess();

      Analytics.sendEvent({ event: AnalyticsEventType.YARD_CLEAR_OUT });
    } catch (_) {
      setDidFailClearOut(true);
    }

    setIsClearingOut(false);
  }, [closeModal, dispatch, onClearOutSuccess, onSuccess, yardId]);

  const performDelete = useCallback(async () => {
    if (!yardId) {
      return;
    }

    setDidFailDelete(false);
    setIsDeleting(true);

    try {
      await dispatch(deleteYardThunk(yardId));
      closeModal();
      onSuccess && onSuccess();
      onDeleteSuccess && onDeleteSuccess();

      Analytics.sendEvent({ event: AnalyticsEventType.YARD_DELETE });
    } catch (_) {
      setDidFailDelete(true);
    }

    setIsDeleting(false);
  }, [closeModal, dispatch, onDeleteSuccess, onSuccess, yardId]);

  const performClearOutAndDelete = useCallback(async () => {
    if (!yardId) {
      return;
    }

    setDidSucceed(false);
    setIsClearingOut(true);
    setIsDeleting(true);
    setShowRequestsProgress(true);
    resetClearOutAndDeleteActions();

    const requests: Array<() => Promise<void>> = [
      async () => {
        !isDeletable && (await dispatch(emptyYardThunk(yardId)));
        Analytics.sendEvent({ event: AnalyticsEventType.YARD_CLEAR_OUT });
      },
      async () => {
        await dispatch(deleteYardThunk(yardId));
        Analytics.sendEvent({ event: AnalyticsEventType.YARD_DELETE });
      },
    ];

    // Execute each request.
    let index = 0;
    let hasFailed = false;
    for (const request of requests) {
      if (hasFailed) {
        setClearOutAndDeleteAction(index, { status: 'fail' });
      } else {
        setClearOutAndDeleteAction(index, { status: 'loading' });
        try {
          await request();
          setClearOutAndDeleteAction(index, { status: 'success' });
        } catch (_) {
          setClearOutAndDeleteAction(index, { status: 'fail' });
          hasFailed = true;
        }
      }

      index++;
    }

    if (hasFailed) {
      updateDeletableStatus().then(null);
      setDidSucceed(false);
      setDidFailClearOut(true);
      setDidFailDelete(true);
    } else {
      setDidSucceed(true);
      setDidFailClearOut(false);
      setDidFailDelete(false);
      onSuccess && onSuccess();
      onClearOutAndDeleteSuccess && onClearOutAndDeleteSuccess();
    }

    setIsClearingOut(false);
    setIsDeleting(false);
  }, [
    dispatch,
    isDeletable,
    onClearOutAndDeleteSuccess,
    onSuccess,
    resetClearOutAndDeleteActions,
    setClearOutAndDeleteAction,
    updateDeletableStatus,
    yardId,
  ]);

  const handleCloseAfterSuccess = useCallback(() => {
    closeModal();
    onCloseAfterSuccess && onCloseAfterSuccess();
  }, [closeModal, onCloseAfterSuccess]);

  useEffect(() => {
    if (props.isOpen) {
      setDidFailDelete(false);
      setDidFailClearOut(false);
      setDidSucceed(false);
      setIsClearingOut(false);
      setIsDeleting(false);
      resetClearOutAndDeleteActions();
      setShowRequestsProgress(false);

      updateDeletableStatus().catch(() => {
        props.onRequestClose && props.onRequestClose();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <Modal {...props} onRequestClose={handleCloseAfterSuccess} nonDismissible={!didSucceed && !isMobile}>
      <ModalHeader
        title={isDeleteOnlyAction ? t('delete_yard_title') : t('clear_out_and_delete_yard_title')}
        subtitle={
          <span
            dangerouslySetInnerHTML={{
              __html: isDeleteOnlyAction
                ? t('delete_yard_text')
                : isDeleteWithClearOutAction
                ? t('delete_and_clear_out_yard_message')
                : t('clear_out_and_delete_yard_message'),
            }}
          />
        }
        linkText={isDeleteWithClearOutAction || isClearOutAction ? t('clear_out_yard_help_link') : undefined}
        onLinkClick={useIntercomArticleOpener(APP.intercomArticles.CLEAR_OUT_YARDS)}
        alert={
          isDeleteWithClearOutAction ? <AlertCard warning>{t('delete_active_yard_warning')}</AlertCard> : undefined
        }
        suppressCloseButton={suppressHeaderCloseButton}
        closeButtonProps={{ disabled: isClearingOutOrDeleting }}
      />
      {showRequestsProgress && (
        <ModalContent>
          <BulkActionsProgress actions={clearOutAndDeleteActions} visible />
        </ModalContent>
      )}
      <ModalFooter>
        <Box fit gap_100 justifyContent={'flex-end'} wrap>
          <ResponsiveRender from="tablet">
            {!didSucceed && (
              <Button tertiary onClick={closeModal} disabled={isClearingOutOrDeleting}>
                {t('cancel')}
              </Button>
            )}
          </ResponsiveRender>

          {!didFailOrSucceed && isDeleteOnlyAction && (
            <Button primary danger onClick={performDelete} disabled={isClearingOutOrDeleting}>
              {t('delete')}
            </Button>
          )}

          {!didFailOrSucceed && isDeleteWithClearOutAction && (
            <Button primary danger onClick={performClearOutAndDelete} disabled={isClearingOutOrDeleting}>
              {t('clear_out_and_delete_yard_button')}
            </Button>
          )}

          {!didFailOrSucceed && isClearOutAction && (
            <>
              <ResponsiveRender from="tablet">
                <Box fit />
              </ResponsiveRender>
              <Button
                secondary
                danger
                grow={isMobile}
                onClick={performClearOutAndDelete}
                disabled={isClearingOutOrDeleting}
              >
                {t('clear_out_and_delete_yard_button')}
              </Button>
              <Button primary grow={isMobile} onClick={performClearOut} disabled={isClearingOutOrDeleting}>
                {t('clear_out_yard_only_button')}
              </Button>
            </>
          )}

          {didSucceed && (
            <Button primary onClick={handleCloseAfterSuccess} disabled={isClearingOutOrDeleting}>
              {t('close')}
            </Button>
          )}

          {didFail && (
            <Button
              primary
              onClick={
                didFailDelete || didFailClearOut
                  ? performClearOutAndDelete
                  : didFailDelete
                  ? performDelete
                  : performClearOut
              }
              disabled={isClearingOutOrDeleting}
            >
              {t('try_again')}
            </Button>
          )}
        </Box>
      </ModalFooter>

      <Loading whiteBackground roundedCorners visible={showMainLoading} />
    </Modal>
  );
};
