import React from 'react';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import KeepAddingComponent from '@components/deprecated/KeepAddingComponent';
import theme from '@style/theme';

import { ModalContainer } from '../Elements/ModalElements';
// nectar:
import { TextInput } from '../TextInput/TextInput';

const ErrorMsg = styled.div`
  color: ${(props) => props.theme.criticalColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  line-height: 19px;
`;

const textInputStyle = {
  borderRadius: 0,
  border: theme.primaryBorderStyle,
};

export const WorkerModalView = ({
  t,
  isMobile,
  contentObj,
  keepAdding,
  handleChange,
  handleKeepAdding,
  errorMsg,
  handleClose,
  handleSubmit,
  isDisabledBtn,
}) => {
  const { title, instructions, name, phone_number, modalType, buttonText } = contentObj;

  const keepAddingComponent = 'add-worker' === modalType && (
    <KeepAddingComponent t={t} isMobile={isMobile} handleKeepAdding={handleKeepAdding} keepAdding={keepAdding} />
  );

  return (
    <ModalContainer isMobile={isMobile} smallModal={'remove-worker' === modalType}>
      <section>
        <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
          {title}
        </StyledHeading2>
        <StyledParagraph style={{ marginBottom: 'remove-worker' !== modalType && '1.5rem' }}>
          {instructions}
        </StyledParagraph>

        {'remove-worker' !== modalType && (
          <fieldset style={{ width: '100%' }}>
            <TextInput
              id="name"
              name="name"
              required
              value={name}
              label={t('name')}
              handleChange={handleChange}
              type="text"
              styles={{ ...textInputStyle, marginBottom: '1rem' }}
            />

            <TextInput
              id="phone_number"
              name="phone_number"
              required
              value={phone_number}
              defaultValue="+17012411310"
              label={t('phone_int')}
              handleChange={handleChange}
              type="phone"
              styles={textInputStyle}
              error={errorMsg}
            />
          </fieldset>
        )}

        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

        {isMobile && keepAddingComponent}
      </section>
      <footer>
        <Box paddingHorizontal_150 justifyContent="space-between" alignItems="center" fullWidth>
          {!isMobile && keepAddingComponent}
          <Box alignItems="center">
            <Button tertiary withMarginRight type="button" onClick={handleClose}>
              {t('cancel')}
            </Button>

            <Button
              primary
              type="button"
              onClick={handleSubmit}
              disabled={isDisabledBtn && 'remove-worker' !== modalType}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </footer>
    </ModalContainer>
  );
};

WorkerModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleKeepAdding: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    phone_number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    modalType: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,
  keepAdding: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
