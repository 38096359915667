import React from 'react';

import { Box } from '@components/common/Box';

import { CheckboxProps } from '../../types';

import { ToggleHiddenLabel, ToggleInput } from './styles';

export const Toggle = ({ name, id = name, value, onChange, hiddenLabelText }: CheckboxProps) => {
  return (
    <Box>
      <ToggleInput name={name} id={id} type="checkbox" checked={value} onChange={() => onChange(!value, id)} />
      {hiddenLabelText && <ToggleHiddenLabel htmlFor={id}>{hiddenLabelText}</ToggleHiddenLabel>}
    </Box>
  );
};
