import { useCallback } from 'react';

import { useQueryParamsModalOpener } from '@components/common/ModalBase/hooks';
import { ActivityFilters } from '@redux/Timeline/types';

const NONE_PARAM_VALUE = 'n';
const ALERT_TYPE_PARAM_MAP: Record<BeeActivityByRequestAlertType, string> = {
  new_inspection: 'ni',
  hive_added_to_yard: 'ha',
  hive_managed_to_yard: 'hm',
  lost_hive_marked_as_removed: 'lr',
  lost_hive_marked_as_deadout: 'ld',
  yard_cleared_out: 'yc',
};
const ALERT_TYPE_PARAM_MAP_REVERSED = Object.entries(ALERT_TYPE_PARAM_MAP).reduce(
  (acc, [alertType, slug]) => ({ ...acc, [slug]: alertType }),
  {}
) as Record<string, BeeActivityByRequestAlertType>;

export function useTimelineByActivityRequestModalOpener() {
  const { openedModals, openModal, closeModal } = useQueryParamsModalOpener({
    paramName: 'visit',
    paramValueStringifier: queryValuesEncoder,
    paramValueParser: queryValuesDecoder,
  });

  const close = useCallback(() => {
    openedModals.forEach((entry) => closeModal(entry));
  }, [closeModal, openedModals]);

  return {
    openedTimelineByActivityRequestModal: openedModals[0] ?? null,
    openTimelineByActivityRequestModal: openModal,
    closeTimelineByActivityRequestModal: close,
  };
}

/**
 * Encodes the given dictionary of values into a single string, which can be
 * used as a URL query param.
 * */
function queryValuesEncoder(value: ActivityFilters): string {
  const cleanDate = value.date.replace(/(^\d+-\d+-\d+).*?$/, '$1');
  const cleanAlertType = value.alertTypes.map((alertType) => ALERT_TYPE_PARAM_MAP[alertType]);
  return [cleanDate, ...cleanAlertType, value.yardId, value.hiveId, !!value.lostHives, value.practiceCategoryId]
    .map((v: any) => ([null, undefined].includes(v) ? NONE_PARAM_VALUE : v))
    .join('-');
}

/**
 * Decodes the given URL encoded value into a dictionary of values.
 * */
function queryValuesDecoder(value: string): ActivityFilters | null {
  const valueParts = value
    .split('-')
    .map((v) => (v === NONE_PARAM_VALUE ? undefined : v))
    .reverse();

  const dateYear = valueParts.pop() ?? '0';
  const dateMonth = valueParts.pop() ?? '0';
  const dateDay = valueParts.pop() ?? '0';

  const alertTypes: Array<BeeActivityByRequestAlertType> = [];
  while ((valueParts.slice(-1)[0] as string) in ALERT_TYPE_PARAM_MAP_REVERSED) {
    alertTypes.push(ALERT_TYPE_PARAM_MAP_REVERSED[valueParts.pop() as string]);
  }

  const yardId = valueParts.pop();
  const hiveId = valueParts.pop();
  const lostHives = valueParts.pop() === 'true';
  const practiceCategoryId = valueParts.pop();

  if (alertTypes.every((at) => at in ALERT_TYPE_PARAM_MAP)) {
    return {
      date: [dateYear, dateMonth, dateDay].join('-'),
      alertTypes,
      yardId: yardId ? parseInt(yardId) : undefined,
      hiveId: hiveId ? parseInt(hiveId) : undefined,
      lostHives,
      practiceCategoryId: practiceCategoryId ? parseInt(practiceCategoryId) : undefined,
    };
  }

  return null;
}
