import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useTranslation } from '@hooks/useTranslation';

import { Box } from '../Box';
import { Button, MenuTrigger } from '../CTA';
import { IconIMG } from '../Icon';
import { MoreVert } from '../Icon/presets/MoreVert';
import { Menu } from '../Menu';
import { MenuItem } from '../Menu/types';
import { ResponsiveRender } from '../ResponsiveRender';

/**
 * Business Logic
 * on desktop:
 * display first button
 * display second button if there aren't other buttons
 * display menu if there are more ahn 2 buttons
 *
 * on mobile:
 * display first button if only 1 button
 * display all buttons in menu otherwise
 */

export interface HeaderAction extends MenuItem {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
}
interface HeaderActions {
  actions: HeaderAction[];
}
export const HeaderActions: React.FC<HeaderActions> = ({ actions }) => {
  const t = useTranslation();
  const mobileActions = actions.filter((action) => !action.desktopOnly);
  const displayMobileMenu = useMemo(() => mobileActions.length > 1, [mobileActions]);
  const mobileFirstAction = useMemo(() => mobileActions[0], [mobileActions]);

  const desktopActions = actions.filter((action) => !action.mobileOnly);
  const [firstAction, ...restOfActions] = desktopActions;
  const secondAction = useMemo(() => (restOfActions.length === 1 ? restOfActions[0] : null), [restOfActions]);
  const displayDesktopMenu = useMemo(() => desktopActions.length > 2, [desktopActions]);

  const uniqueId = useMemo(() => `header-actions-menu-${uuid()}`, []);

  const renderIcon = useCallback((item: HeaderAction) => {
    if (item.icon) {
      if (typeof item.icon === 'string') {
        return <IconIMG size={18} src={item.icon} />;
      }

      return <item.icon size={18} />;
    }
    return null;
  }, []);

  const renderFirstActionMobile = useCallback(() => {
    if (!mobileFirstAction) return null;
    if (mobileFirstAction.render) return mobileFirstAction.render();

    return (
      <Button
        primary
        iconOnly
        aria-label={mobileFirstAction.title}
        danger={mobileFirstAction?.danger}
        disabled={mobileFirstAction?.disabled}
        onClick={mobileFirstAction.onClick}
      >
        {renderIcon(mobileFirstAction)}
      </Button>
    );
  }, [mobileFirstAction, renderIcon]);

  const renderFirstActionDesktop = useCallback(() => {
    if (!firstAction) return null;
    if (firstAction.render) return firstAction.render();

    return (
      <Button
        primary
        withLeadingIcon
        danger={firstAction?.danger}
        disabled={firstAction?.disabled}
        onClick={firstAction.onClick}
      >
        {renderIcon(firstAction)}
        {firstAction.title}
      </Button>
    );
  }, [firstAction, renderIcon]);

  const renderSecondActionDesktop = useCallback(() => {
    if (!secondAction) return null;

    if (secondAction.render) return secondAction.render();

    return (
      <Button
        secondary
        withLeadingIcon
        danger={secondAction?.danger}
        disabled={secondAction?.disabled}
        onClick={secondAction.onClick}
      >
        {renderIcon(secondAction)}
        {secondAction.title}
      </Button>
    );
  }, [secondAction, renderIcon]);

  return (
    <Box gap_100>
      <ResponsiveRender until="mobile">
        {displayMobileMenu ? (
          <Button iconOnly id={uniqueId} aria-label={t('more_options')}>
            <MoreVert size={24} color={'contentTertiary'} />
            <Menu items={mobileActions} target={uniqueId} />
          </Button>
        ) : (
          renderFirstActionMobile()
        )}
      </ResponsiveRender>

      <ResponsiveRender from="tablet">
        {displayDesktopMenu && (
          <MenuTrigger id={uniqueId}>
            {t('more_options')}
            <Menu items={restOfActions} target={uniqueId} placement={'bottom-start'} />
          </MenuTrigger>
        )}
        {renderSecondActionDesktop()}
        {renderFirstActionDesktop()}
      </ResponsiveRender>
    </Box>
  );
};
