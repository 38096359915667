import { generatePath } from 'react-router-dom';
import { isArray, isArrayLike } from 'lodash';

import { Globals } from '@helpers/Globals';

const URL_PATH_DIVIDER = '/';
const URL_PARAMS_DIVIDER = '&';
const URL_PATH_PARAMS_DIVIDER = '?';

type Arg = Array<string> | string | number | null;
type Args = Array<Arg | Record<string, Arg>>;

export const URLUtil = {
  /**
   * Replaces the operation id in the current page path.
   * Used when the user switches the current operation.
   * */
  replaceOperationIdInPath,

  /**
   * Builds a screen path from the given args.
   * Use it for 'history.push' calls.
   * */
  buildPagePath,

  /**
   * Builds an url from the given args.
   * Use it for 'API.get/post/put/patch/delete' calls.
   * Examples:
   *
   *  buildURL("https://api.nectar.buzz", "v3/hives", { status: "deadout" })
   *  result: "https://api.nectar.buzz/v3/hives?status=deadout"
   *
   *  buildURL("v3/hives", { status: "deadout" })
   *  result: "v3/hives?status=deadout"
   *
   *  buildURL({ status: null, id: 10 })
   *  result: "id=10"
   *
   *  buildURL("/contract/<id>/patch", { id: 10 })
   *  result: "/contract/10/patch"
   * */
  buildURL,
  buildURLQueryParams,
};

function replaceOperationIdInPath(pathname: string) {
  return buildPagePath(pathname.replace(/^\/([^/]*)(\/|$)/, '/:operation/'));
}

function buildPagePath(
  pathname: string,
  options?: { pathParams?: Record<string, any>; queryParams?: Record<string, any> }
): string {
  const pathParams = { ...options?.pathParams, operation: Globals.operationId ?? '_' };
  const queryParams = options?.queryParams ?? {};
  return buildURL(generatePath(pathname, pathParams), queryParams);
}

function buildURL(...args: Args): string {
  let path = buildURLPath(...args);
  path = buildURLPathParams(path, ...args);
  path = buildURLQueryParams(path, ...args);
  return path;
}

function buildURLPath(...args: Args): string {
  let path = '';

  for (const arg of args) {
    if (['string', 'number'].includes(typeof arg)) {
      if (!path) {
        path = String(arg);
      } else if (path.endsWith(URL_PATH_DIVIDER)) {
        path += String(arg);
      } else {
        path = `${path}${URL_PATH_DIVIDER}${arg}`;
      }
    }
  }

  return path;
}

function buildURLPathParams(path: string, ...args: Args): string {
  for (const arg of args) {
    if (!isArrayLike(arg) && typeof arg === 'object' && arg !== null)
      for (const [key, val] of Object.entries(arg)) {
        const interpolation = `{{${key}}}`;
        if (path.includes(interpolation)) {
          path = path.replace(interpolation, String(val));
          delete arg[key];
        }
      }
  }
  return path;
}

function buildURLQueryParams(path: string, ...args: Args): string {
  let params = {};

  for (const arg of args) {
    if (typeof arg === 'object') {
      params = { ...params, ...arg };
    }
  }

  const paramsStrList = [];

  for (const [prop, value] of Object.entries(params)) {
    if (typeof value !== 'undefined' && value !== null) {
      const values = isArray(value) ? value : [value];
      for (const subValue of values) {
        paramsStrList.push(`${prop}=${encodeURIComponent(subValue)}`);
      }
    }
  }

  if (paramsStrList.length) {
    const paramsStr = paramsStrList.join(URL_PARAMS_DIVIDER);
    return [path, paramsStr].join(URL_PATH_PARAMS_DIVIDER);
  }

  return path;
}
