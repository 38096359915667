import Calendar from 'react-calendar';
import styled from 'styled-components/macro';

import { Text } from '@components/common/Text';
import { InputCalendarProps } from '@components/form/inputs/InputCalendar/types';

export const InputCalendarView = styled.div<Partial<InputCalendarProps> & { hasError: boolean }>(
  ({ theme, disableYearSelector, hasError }) => `
  display: flex;
  flex: ${disableYearSelector ? 130 : 170}px 0 0;
  align-items: stretch;
  flex-direction: column;
  cursor: pointer;
  background-color: ${theme.colors.surfaceSecondary};
  padding: ${theme.spacing._050};
  border: ${hasError ? theme.colors.borderDanger : theme.primitives.colors.grey03} 1px solid;
`
);

export const InputCalendarRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const InputCalendarText = styled(Text)(
  ({ theme }) => `
  flex: 1;
  margin: 0 ${theme.spacing._050};
`
);

export const InputCalendarTooltipView = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 280px;
  background: ${theme.primitives.colors.white};
  border-radius: ${theme.shape.paperRadius01}px;
  box-shadow: ${theme.shadows.boxShadow02};
  padding: ${theme.spacing._050};
`
);

export const InputCalendarTooltipCalendar = styled(Calendar)(
  ({ theme }) => `
  font-size: 1rem;
  font-family: ${theme.font.family};
  color: ${theme.primitives.colors.grey08};

  .react-calendar__navigation {
    display: flex;
    align-items: center;
  }

  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow,
  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing._050};
    border-radius: ${theme.shape.paperRadius01}px;
    font-size: 0.75rem;
    font-weight: 600;

    &.react-calendar__navigation__label {
      flex: 1;
    }

    &.react-calendar__navigation__arrow {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    &:hover {
      background-color: ${theme.primitives.colors.grey02};
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &[class*=now] {
       border: ${theme.primitives.colors.grey08} 1px solid;
    }

    &[class*=Active] {
       border: none;
       color: ${theme.primitives.colors.white};
       background-color: ${theme.primitives.colors.grey08};
       &:hover {
         background-color: ${theme.primitives.colors.grey06};
       }
    }
  }

  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
     padding: ${theme.spacing._150} ${theme.spacing._150};
  }

  .react-calendar__month-view__weekdays, .react-calendar__month-view__days {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .react-calendar__month-view__weekdays__weekday, .react-calendar__month-view__days__day {
    display: flex;
    align-items: center;
    justify-content: center;

    &[disabled] {
      pointer-events: none;
      abbr {
        opacity: 0.5;
      }
    }

    abbr {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      margin: ${theme.spacing._025};
      text-decoration: none;
    }

    &.react-calendar__month-view__weekdays__weekday {
      color: ${theme.primitives.colors.grey06};
      font-weight: 600;
    }

    &.react-calendar__month-view__days__day--neighboringMonth {
      color: ${theme.primitives.colors.grey06};
    }

    &.react-calendar__month-view__days__day:hover {
      abbr {
        background-color: ${theme.primitives.colors.grey02};
      }
    }

    &.react-calendar__tile--now {
      abbr {
        border: ${theme.primitives.colors.grey08} 1px solid;
      }
    }

    &.react-calendar__tile--active {
      abbr {
        border: none;
        color: ${theme.primitives.colors.white};
        background-color: ${theme.primitives.colors.grey08};
      }

      &:hover {
        abbr {
          background-color: ${theme.primitives.colors.grey06};
        }
      }
    }
  }

  &.hide-weekdays {
    .react-calendar__month-view__weekdays {
      display: none !important;
    }
  }

  &.hide-double-arrows {
    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
      display: none !important;
    }
  }
`
);
