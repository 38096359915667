import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';

const StyledWrapper = styled.div(
  ({ theme }) => css`
    margin: auto;
    text-align: center;

    p {
      max-width: min(500px, calc(100vw - ${theme.spacing._200}));
      margin: ${theme.spacing._150} auto;
    }
  `
);

export const EmptyView: React.FC<{ heading: string; text: string }> = ({ heading, text, children }) => (
  <StyledWrapper>
    <Text typography="Heading1">{heading}</Text>
    <Text typography="Paragraph">{text}</Text>
    {children}
  </StyledWrapper>
);
