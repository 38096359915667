import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useGridApi } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { UnstyledButton } from '@components/common/UnstyledButton';
import { useTranslation } from '@hooks/useTranslation';

import { useDynamicTableSelection } from './hooks/useDynamicTableSelection';

const TableHeaderBottom = styled(Box)<{ visible?: boolean }>(
  ({ theme, visible }) => `
      background: ${theme.primitives.colors.yellow01};
      transition: 
          ${theme.animations.transitionMedium('max-height')} 120ms,
          ${theme.animations.transitionMedium('padding')} 120ms;
      padding: ${visible ? theme.spacing._050 : 0} ${theme.spacing._050};
      max-height: ${visible ? 66 : 0}px;
      opacity: ${visible ? 1 : 0}px;
      overflow: hidden;
      
      & > * {
        margin-right: ${theme.spacing._050};
      }

      button {
        color: ${theme.primitives.colors.orange02};
      }

      button:hover {
        text-decoration: underline;
      }
    `
);

export const DynamicTableSelectAllAcrossPages: React.VFC<{
  allPagesSelectedMessage: string;
  somePagesSelectedMessage: string;
  selectButtonTitle: string;
  deselectButtonTitle: string;
}> = ({ allPagesSelectedMessage, somePagesSelectedMessage, selectButtonTitle, deselectButtonTitle }) => {
  const { gridApi } = useGridApi();

  const {
    hasSelectedAllOnPage,
    allRowsAcrossPagesAreSelectedManually,
    selectAllAcrossPages,
    deselectAllAcrossPages,
    hasClickedSelectAllAcrossPages,
    selectionCount,
  } = useDynamicTableSelection();
  const t = useTranslation();
  const allRowsAcrossPagesSelected = useMemo(
    () => hasClickedSelectAllAcrossPages || allRowsAcrossPagesAreSelectedManually,
    [hasClickedSelectAllAcrossPages, allRowsAcrossPagesAreSelectedManually]
  );
  const hasMultiplePages = (gridApi?.paginationGetTotalPages() ?? 0) > 1;

  const isVisible = hasSelectedAllOnPage && hasMultiplePages;

  const toggleSelection = useCallback(() => {
    if (allRowsAcrossPagesSelected) {
      deselectAllAcrossPages();
    } else {
      selectAllAcrossPages();
    }
  }, [deselectAllAcrossPages, allRowsAcrossPagesSelected, selectAllAcrossPages]);

  return (
    <TableHeaderBottom fit center visible={isVisible}>
      <Text typography={'CaptionSmall'}>
        {t(allRowsAcrossPagesSelected ? allPagesSelectedMessage : somePagesSelectedMessage, {
          number: selectionCount,
        })}
      </Text>
      <UnstyledButton onClick={toggleSelection}>
        <Text typography={'CaptionSmall'} color={'orange02'} weight={'700'}>
          {t(allRowsAcrossPagesSelected || hasClickedSelectAllAcrossPages ? deselectButtonTitle : selectButtonTitle, {
            number: gridApi?.paginationGetRowCount() ?? 0,
          })}
        </Text>
      </UnstyledButton>
    </TableHeaderBottom>
  );
};
