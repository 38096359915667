import React from 'react';
import { useTheme } from 'styled-components';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HazardPin = ({ size }: IconSVGProps) => {
  const { colors } = useTheme();
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <rect fill={colors.surfacePrimary} width="16" height="16" rx="8" />
      <path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8029 10.9034C11.2999 10.9034 11.6102 10.3651 11.3612 9.93502L8.55833 5.09364C8.30984 4.66442 7.69015 4.66442 7.44165 5.09364L4.63875 9.93502C4.38974 10.3651 4.7001 10.9034 5.19709 10.9034H10.8029ZM8.01613 6.70988C7.79343 6.70988 7.6129 6.89041 7.6129 7.11311V8.40343C7.6129 8.62613 7.79343 8.80666 8.01613 8.80666C8.23882 8.80666 8.41935 8.62613 8.41935 8.40343V7.11311C8.41935 6.89041 8.23882 6.70988 8.01613 6.70988ZM8.01613 10.097C8.23882 10.097 8.41935 9.91645 8.41935 9.69375C8.41935 9.47106 8.23882 9.29053 8.01613 9.29053C7.79343 9.29053 7.6129 9.47106 7.6129 9.69375C7.6129 9.91645 7.79343 10.097 8.01613 10.097Z"
      />
    </IconSVGView>
  );
};
