import React from 'react';

import { Box } from '@components/common/Box';
import { Gauge } from '@components/common/Gauge';
import { useHealthScoreStats } from '@components/common/HealthScore/hooks';
import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';
import { useTranslation } from '@hooks/useTranslation';

export const HealthScore: React.FC<{
  data: BeeHealthScore | null;
  naTextProps?: Partial<TextProps>;
}> = ({ data, naTextProps, children }) => {
  const t = useTranslation();

  const { noData, noHives, noScore } = useHealthScoreStats(data);

  if (noData) {
    return null;
  }

  if (noHives) {
    return (
      <Box center>
        <Text typography={'Heading3'} {...naTextProps}>
          {t('not_available')}
          {children}
        </Text>
      </Box>
    );
  }

  if (noScore) {
    return (
      <Box center>
        <Text typography={'Heading3'} {...naTextProps}>
          {t('not_available')}
          {children}
        </Text>
      </Box>
    );
  }

  return (
    <Box center>
      <Gauge value={Math.round((data?.healthScore ?? 0) * 10)} />
      {children}
    </Box>
  );
};
