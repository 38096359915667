import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Slider = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75025 12.9686C4.40913 13.5837 3.75319 14 3 14C1.89543 14 1 13.1046 1 12C1 10.8954 1.89543 10 3 10C3.75319 10 4.40913 10.4163 4.75025 11.0314C4.83008 11.0109 4.91376 11 5 11H10.2676C10.6134 10.4022 11.2597 10 12 10C12.7403 10 13.3866 10.4022 13.7324 11H19C19.0862 11 19.1699 11.0109 19.2498 11.0314C19.5909 10.4163 20.2468 10 21 10C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14C20.2468 14 19.5909 13.5837 19.2498 12.9686C19.1699 12.9891 19.0862 13 19 13H13.7324C13.3866 13.5978 12.7403 14 12 14C11.2597 14 10.6134 13.5978 10.2676 13H5C4.91376 13 4.83008 12.9891 4.75025 12.9686Z"
      />
    </IconSVGView>
  );
};
