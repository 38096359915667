import React from 'react';

import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { YardsMapLayer } from '@components/yard/YardsMap/types';

import { AllYardsStats } from './AllYardsStats';
import { LastInspectionsStats } from './LastInspectionStats';

export const YardsMapCard: React.VFC = () => {
  const { activeLayers } = useYardsMap();

  const isLastInspectionsEnabled = !!activeLayers[YardsMapLayer.LAST_INSPECTIONS];

  return isLastInspectionsEnabled ? <LastInspectionsStats /> : <AllYardsStats />;
};
