import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { ModalProvider } from '@components/common/ModalBase';
import { store } from '@redux/store';
import { GlobalStyle } from '@style/global';
import theme from '@style/theme';

import App from './App';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalProvider>
        <App />
      </ModalProvider>
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
