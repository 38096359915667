import React from 'react';

import { Box } from '@components/common/Box';
import { BoxProps } from '@components/common/Box/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';

import { StyledPageTitle } from './styles';

export const PageHeaderTitle: React.FC<BoxProps<'div'> & { enableTruncate?: boolean }> = ({
  children,
  enableTruncate,
  ...props
}) => {
  const a11yTooltip = enableTruncate ? (children as string) : undefined;

  return (
    <Box fit overflow={'inherit'} {...props}>
      <StyledPageTitle $enableTruncate={enableTruncate}>
        {children}
        {!!a11yTooltip && (
          <Tooltip>
            <Text typography={'CaptionSmall'}>{a11yTooltip}</Text>
          </Tooltip>
        )}
      </StyledPageTitle>
    </Box>
  );
};
