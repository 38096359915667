import React from 'react';

import { URLUtil } from '@helpers/URL';

import { StyledNavLink } from './styles';
import { NavRouterLinkProps } from './types';

// not sure if ref will work, might need to add fwdRef later

export const NavRouterLink: React.FC<NavRouterLinkProps> = ({
  to: propTo,
  children,
  unstyled,
  primary,
  secondary,
  tertiary,
  elevated,
  withLeadingIcon,
  withTrailingIcon,
  suppressPaddingHorizontal,
  suppressPaddingVertical,
  suppressPadding,
  withMargin,
  withMarginLeft,
  withMarginRight,
  grow,
  ...props
}) => {
  const to = URLUtil.buildPagePath(propTo as string);

  return (
    <StyledNavLink
      to={to}
      $unstyled={unstyled}
      $primary={primary}
      $secondary={secondary}
      $tertiary={tertiary}
      $elevated={elevated}
      $withLeadingIcon={withLeadingIcon}
      $withTrailingIcon={withTrailingIcon}
      $suppressPaddingHorizontal={suppressPadding || suppressPaddingHorizontal}
      $suppressPaddingVertical={suppressPadding || suppressPaddingVertical}
      $withMarginLeft={withMargin || withMarginLeft}
      $withMarginRight={withMargin || withMarginRight}
      $grow={grow}
      {...props}
    >
      {children}
    </StyledNavLink>
  );
};
