/**
 * TODO: Move these components to ./components folder, and break into files.
 * */

import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ChevronRight } from '@components/common/Icon/presets/ChevronRight';
import { Hide } from '@components/common/Icon/presets/Hide';
import { Layers } from '@components/common/Icon/presets/Layers';
import { Location } from '@components/common/Icon/presets/Location';
import { Show } from '@components/common/Icon/presets/Show';
import { ZoomIn } from '@components/common/Icon/presets/ZoomIn';
import { ZoomOut } from '@components/common/Icon/presets/ZoomOut';
import { MapApiProviders } from '@components/common/Map/apis';
import { DEF_MAX_ZOOM, DEF_MIN_ZOOM, DEF_PANE_WIDTH, DEFAULT_MAP_API } from '@components/common/Map/constants';
import { useMap, useMapChildren } from '@components/common/Map/hooks';
import {
  StyledMapControlButtonLayers,
  StyledMapControlButtonMinus,
  StyledMapControlButtonPlus,
  StyledMapControlWrapper,
  StyledMapPane,
  StyledMapPaneDrawer,
  StyledMapPaneToggleButton,
  StyledMapPaneToggleIconWrapper,
  StyledMapPaneWrapper,
} from '@components/common/Map/styles';
import {
  MapApi,
  MapContextValue,
  MapControlsProps,
  MapInstance,
  MapPaneProps,
  MapProps,
  MapType,
  MarkerProps,
} from '@components/common/Map/types';
import { Menu } from '@components/common/Menu';
import { MenuItem } from '@components/common/Menu/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { useQueryParamsState } from '@helpers/QueryParams/hooks';

import { MapContext, MapContextProvider } from './context';

export * from './components';

/**
 * Main map component.
 * */
export const Map: React.FC<MapProps> = ({ children, onInstance, ...props }) => {
  const MapRenderer = MapApiProviders[props.api ?? DEFAULT_MAP_API].mapRenderer;

  const forwardOnInstance = useCallback(
    (context: MapContextValue) => {
      return (instance: MapInstance) => {
        context.__setInstance(instance);
        onInstance && onInstance(instance);
      };
    },
    [onInstance]
  );

  return (
    <MapContextProvider {...props}>
      <MapContext.Consumer>
        {(mapContext) =>
          mapContext ? (
            <MapRenderer
              state={mapContext.state}
              onStateChange={mapContext.setState}
              onInstance={forwardOnInstance(mapContext)}
              {...props}
            >
              {children}
            </MapRenderer>
          ) : null
        }
      </MapContext.Consumer>
    </MapContextProvider>
  );
};

/**
 * Injects the current map API without
 * unecessary re-renders.
 * */
export function withMapApi<P>(Component: ComponentType<P & { api: MapApi }>): ComponentType<P> {
  return (props) => {
    const { api } = useMap();
    return useMemo(() => <Component api={api} {...props} />, [api, props]);
  };
}

/**
 * Renders any react content in a map coordinate.
 * */
export const Marker = withMapApi<MarkerProps>(({ api, children, ...props }) => {
  const MarkerRenderer = useMemo(() => MapApiProviders[api].markerRenderer, [api]);
  return <MarkerRenderer {...props}>{children}</MarkerRenderer>;
});

/**
 * Renders a maker info window.
 * */
export const InfoWindow: React.FC = ({ children }) => {
  return (
    <Tooltip appendToTarget placement="top" interactive arrow light>
      {children}
    </Tooltip>
  );
};

/**
 * Renders the zoom and map type controls.
 * Also accept extra menu options.
 * */
export const MapControls: React.VFC<MapControlsProps> = ({ showMapTypeToggle, extraMenuItems, recenterMap }) => {
  const { state, setState } = useMap();
  const { t } = useTranslation();
  const { animations } = useTheme();

  const zoom = useCallback(
    (by: number) =>
      setState((curr) => ({ ...curr, zoom: Math.max(DEF_MIN_ZOOM, Math.min(DEF_MAX_ZOOM, curr.zoom + by)) })),
    [setState]
  );

  const zoomIn = useCallback(() => zoom(1), [zoom]);
  const zoomOut = useCallback(() => zoom(-1), [zoom]);

  const toggleMapType = useCallback(() => {
    const mapToggles = {
      [MapType.SATELLITE]: MapType.TERRAIN,
      [MapType.SATELLITE_SIMPLIFIED]: MapType.TERRAIN_SIMPLIFIED,
      [MapType.TERRAIN]: MapType.SATELLITE,
      [MapType.TERRAIN_SIMPLIFIED]: MapType.SATELLITE_SIMPLIFIED,
    };
    // Waiting the menu close animation to finish before
    // updating the state.
    setTimeout(() => {
      setState((curr) => ({
        ...curr,
        mapType: mapToggles[curr.mapType],
      }));
    }, animations.durationFast);
  }, [animations.durationFast, setState]);

  const toggleLabelsVisibility = useCallback(() => {
    const mapToggles = {
      [MapType.SATELLITE]: MapType.SATELLITE_SIMPLIFIED,
      [MapType.SATELLITE_SIMPLIFIED]: MapType.SATELLITE,
      [MapType.TERRAIN]: MapType.TERRAIN_SIMPLIFIED,
      [MapType.TERRAIN_SIMPLIFIED]: MapType.TERRAIN,
    };
    // Waiting the menu close animation to finish before
    // updating the state.
    setTimeout(() => {
      setState((curr) => ({
        ...curr,
        mapType: mapToggles[curr.mapType],
      }));
    }, animations.durationFast);
  }, [animations.durationFast, setState]);

  const mapTypeToggleMenuItems = useMemo(() => {
    const isInSatelliteView = [MapType.SATELLITE, MapType.SATELLITE_SIMPLIFIED].includes(state.mapType);
    const isShowingLabels = [MapType.SATELLITE, MapType.TERRAIN].includes(state.mapType);
    return [
      {
        icon: Layers,
        title: t(isInSatelliteView ? 'terrain_view' : 'satellite_view'),
        onClick: toggleMapType,
      },
      {
        icon: isShowingLabels ? Hide : Show,
        title: t(isShowingLabels ? 'hide_street_names' : 'show_street_names'),
        onClick: toggleLabelsVisibility,
      },
    ];
  }, [state.mapType, t, toggleLabelsVisibility, toggleMapType]);

  const menuItems = useMemo<Array<MenuItem>>(
    () => [...(showMapTypeToggle ? [...mapTypeToggleMenuItems] : []), ...(extraMenuItems || [])],
    [extraMenuItems, mapTypeToggleMenuItems, showMapTypeToggle]
  );

  return (
    <StyledMapControlWrapper>
      <StyledMapControlButtonLayers isVisible={!!recenterMap} onClick={recenterMap}>
        <Location size={24} color={'white'} />
        <Tooltip placement="left">
          <Text typography="CaptionSmall">{t('map_recenter')}</Text>
        </Tooltip>
      </StyledMapControlButtonLayers>

      <StyledMapControlButtonLayers isVisible={!!menuItems.length} id={'map-control-button-layers-menu-target'}>
        <Layers size={24} color={'white'} />
        <Menu items={menuItems} placement={'top-end'} target="map-control-button-layers-menu-target" />
        <Tooltip placement="left">
          <Text typography="CaptionSmall">{t('map_change_view')}</Text>
        </Tooltip>
      </StyledMapControlButtonLayers>

      <StyledMapControlButtonPlus onClick={zoomIn}>
        <ZoomIn size={24} color={'white'} />
      </StyledMapControlButtonPlus>

      <StyledMapControlButtonMinus onClick={zoomOut}>
        <ZoomOut size={24} color={'white'} />
      </StyledMapControlButtonMinus>
    </StyledMapControlWrapper>
  );
};

export const MapPane: React.VFC<MapPaneProps> = ({
  initiallyExpanded,
  expanded: propExpanded,
  onToggled: propOnExpanded,
  width: propWidth,
  contractMapToExpand,
  hideToggle,
  scrollable,
  useURLState,
  children,
}) => {
  const [expandedMemState, setExpandedMemState] = useState({ pane: initiallyExpanded ? 'expanded' : 'collapsed' });
  const [expandedURLState, setExpandedURLState] = useQueryParamsState({
    pane: initiallyExpanded ? 'expanded' : 'collapsed',
  });

  const [expandedState, setExpandedState] = useMemo(
    () => (useURLState ? [expandedURLState, setExpandedURLState] : [expandedMemState, setExpandedMemState]),
    [expandedMemState, expandedURLState, setExpandedURLState, useURLState]
  );

  const isExpanded = useMemo(() => expandedState.pane === 'expanded', [expandedState.pane]);
  const width = propWidth ?? DEF_PANE_WIDTH;
  const panelLeft = useMemo(() => (isExpanded ? 0 : -width), [width, isExpanded]);
  const contentLeft = useMemo(
    () => (isExpanded && contractMapToExpand ? width : 0),
    [contractMapToExpand, isExpanded, width]
  );
  const toggleLeft = useMemo(
    () => (isExpanded && !contractMapToExpand ? width : 0),
    [contractMapToExpand, isExpanded, width]
  );

  const [map, ...elements] = useMapChildren(children);

  const toggle = useCallback(
    () => setExpandedState((curr) => ({ pane: curr.pane === 'expanded' ? 'collapsed' : 'expanded' })),
    [setExpandedState]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => propOnExpanded && propOnExpanded(isExpanded), [isExpanded]);
  useEffect(
    () =>
      typeof propExpanded !== 'undefined'
        ? setExpandedState(() => ({ pane: propExpanded ? 'expanded' : 'collapsed' }))
        : void 0,
    [propExpanded, setExpandedState]
  );

  return (
    <MapContextProvider>
      <StyledMapPane>
        <StyledMapPaneDrawer left={panelLeft} width={width} scrollable={scrollable}>
          {elements}
        </StyledMapPaneDrawer>
        <StyledMapPaneWrapper left={contentLeft}>
          {map}
          <StyledMapPaneToggleButton onClick={toggle} disabled={hideToggle} isVisible={!hideToggle} left={toggleLeft}>
            <StyledMapPaneToggleIconWrapper open={isExpanded}>
              <ChevronRight size={24} color={'white'} />
            </StyledMapPaneToggleIconWrapper>
          </StyledMapPaneToggleButton>
        </StyledMapPaneWrapper>
      </StyledMapPane>
    </MapContextProvider>
  );
};
