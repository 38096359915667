import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledSnackbarStack = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: ${theme.spacing._200};
    bottom: ${theme.spacing._200};
    z-index: ${theme.getZIndexOf('snackbar')};
    width: 100%;
    pointer-events: none;
  `
);

export const StyledSnackbar = styled.div<{
  $appearing?: boolean;
  $dismissing?: boolean;
  $index: number;
  $subIndex: number;
  $subIndexReversed: number;
}>(
  ({ theme, $appearing, $dismissing, $subIndex, $subIndexReversed }) => css`
    position: absolute;
    opacity: ${$appearing && !$dismissing ? 1 : 0};
    transform: translate(0, calc(100% + ${theme.primitives.px._100}px));
    min-height: 52px;
    left: 0;
    bottom: 0;
    transition: ${theme.animations.transitionMedium('transform', 'opacity')}
      ${$subIndex * theme.animations.durationFast * 0.25}ms;
    z-index: ${$subIndexReversed};
    pointer-events: all;

    ${StyledSnackbarCard} {
      transform: translate(${-theme.primitives.px._050 * $subIndex}px, ${theme.primitives.px._050 * $subIndex}px);

      ${$subIndex > 0 &&
      css`
        border-bottom-color: transparent;
        &:before {
          bottom: 0;
        }
      `}
    }
  `
);

export const StyledSnackbarCard = styled(Box).attrs({
  relative: true,
  center: true,
  backgroundColor: 'white',
  shadow: 'boxShadow03',
  gap_200: true,
})<{ $color: string; $lightColor: string; $showProgress?: boolean }>(
  ({ theme, $lightColor, $showProgress }) => css`
    max-width: calc(100vw - ${theme.spacing._350});
    min-height: 52px;
    padding: ${theme.spacing._050} ${theme.spacing._100};

    border-radius: ${theme.spacing._025};
    transition: ${theme.animations.transitionMedium('transform', 'bottom', 'border-bottom-color')};

    ${$showProgress &&
    css`
      border-bottom: ${$lightColor} 4px solid;
    `}
  `
);

export const StyledSnackbarCardLoader = styled.div<{
  $color: string;
  $isStandBy: boolean;
  $duration?: number;
  $showProgress?: boolean;
}>(
  ({ theme, $color, $duration, $isStandBy, $showProgress }) => css`
    position: absolute;
    inset: 100% 0 -4px 0;
    background-color: ${$color};
    border-radius: 0 ${theme.spacing._025} ${theme.spacing._025} ${theme.spacing._050};
    animation-name: ${SnackbarCardProgressAnimation};
    animation-duration: ${$duration}ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-play-state: ${$isStandBy ? 'paused' : 'running'};
    opacity: ${$showProgress ? 1 : 0};
    transition: ${theme.animations.transitionMedium('opacity')};
  `
);

const SnackbarCardProgressAnimation = keyframes`
  0% { right: 100% }
  100% { right: 0 }
`;

export const StyledSnackbarIconWrapper = styled(Box).attrs({ center: true })(
  ({ theme }) => css`
    min-width: ${theme.spacing._100};
    height: ${theme.spacing._100};
    border-radius: 50%;
    color: ${theme.primitives.colors.white};
  `
);
