import styled from 'styled-components';

import { Text } from '@components/common/Text';

export const BlockInfoText = styled(Text)<{ $useDarkerText: boolean }>(
  ({ theme, $useDarkerText }) => `
  max-width: 128px;
  display: flex;
  text-shadow: 0 0 4px rgba(${$useDarkerText ? '255, 255, 255' : '0, 0, 0'}, 1.0);
  flex-direction: column;
  text-align: center;
  
  span {
    color: ${$useDarkerText ? theme.primitives.colors.grey08 : theme.primitives.colors.white};
  }
`
);
