import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';

export const YardDetailsLoading: React.VFC = () => {
  const isFetching = useSelector((state) => state.yardDetailsReducer.isFetchingDetail);

  return (
    <LoadingSkeleton visible={isFetching} absolutelyFitParent column stretch padding_150 backgroundColor={'background'}>
      <LoadingSkeletonRectangle
        width={'192px'}
        height={'28px'}
        backgroundColor={'grey06'}
        borderRadius={'paperRadius02'}
      />
      <Box marginTop_200 gap_100>
        <LoadingSkeletonRectangle width={'128px'} height={'24px'} />
        <LoadingSkeletonRectangle width={'148px'} height={'24px'} />
        <LoadingSkeletonRectangle width={'128px'} height={'24px'} />
      </Box>
      <Box marginTop_200 stretch gap_150>
        <LoadingSkeletonRectangle fit height={'70vh'} />
        <LoadingSkeletonRectangle fit height={'70vh'} />
        <LoadingSkeletonRectangle fit height={'70vh'} />
      </Box>
    </LoadingSkeleton>
  );
};
