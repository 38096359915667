import styled, { css, ModalWidth } from 'styled-components';

import { StyledDialog } from '@components/common/ModalBase/styles';
import { from } from '@style/mediaQueries';

export const StyledModal = styled(StyledDialog)<{
  $width?: ModalWidth;
  $backgroundColor: string;
  $useFullHeight?: boolean;
  $suppressAutoScroll?: boolean;
  open: boolean;
}>(
  ({ theme, $width, $backgroundColor, $useFullHeight, $suppressAutoScroll }) => css`
    border-radius: ${theme.shape.paperRadius03}px;
    background-color: ${$backgroundColor};
    width: calc(100% - ${theme.spacing._100});

    ${$useFullHeight
      ? css`
          height: calc(100% - ${theme.spacing._100});
        `
      : css`
          max-height: calc(100% - ${theme.spacing._100});
        `}

    ${from('tablet')} {
      width: ${theme.modals.width[$width ?? 'base']}px;

      ${$useFullHeight
        ? css`
            height: 90vh;
          `
        : css`
            height: fit-content;
            max-height: 90vh;
          `}
    }

    ${$suppressAutoScroll &&
    css`
      overflow: hidden;
    `}

    opacity: 0;
    transform: translateY(100vh);
    transition: ${theme.animations.transitionLong('opacity', 'transform')};

    &[open] {
      opacity: 1;
      transform: translateY(0);
    }
  `
);
