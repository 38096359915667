import React from 'react';
import { useSelector } from 'react-redux';

import { GridApiProvider } from '@components/common/AgGrid';
import {
  DynamicTableEmpty,
  DynamicTableEmptyHeader,
  DynamicTableEmptyText,
} from '@components/common/DynamicTable/DynamicTableEmpty';
import { useDynamicTableStates } from '@components/common/DynamicTable/hooks';
import { PageContent, PageContentHeader, PageContentTitle } from '@components/common/PageContent';
import { useTranslation } from '@hooks/useTranslation';

import { DEADOUTS_COL_DEFS, DEADOUTS_COLS_KEYS, DEADOUTS_PERMANENT_RIGHT_COL_DEFS } from './constants';
import { HivesList } from './HivesList';

export const DeadoutsListEmpty: React.VFC = () => {
  const { hasLoaded, isListEmpty } = useDynamicTableStates();
  const { detail } = useSelector((state) => state.yardDetailsReducer);

  const t = useTranslation();
  const showEmptyList = hasLoaded && isListEmpty;
  return (
    <DynamicTableEmpty visible={showEmptyList} backgroundColor="surfaceDefault">
      <DynamicTableEmptyHeader>{t('no_hives_deadout')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText>{t('no_hives_msg_deadout', { yard: detail?.name })}</DynamicTableEmptyText>
    </DynamicTableEmpty>
  );
};

export const DeadoutsListPage: React.VFC = () => {
  const t = useTranslation();
  const { detail } = useSelector((state) => state.yardDetailsReducer);
  return (
    <PageContent>
      <PageContentHeader>
        <PageContentTitle>{t('deadouts')}</PageContentTitle>
      </PageContentHeader>

      <GridApiProvider>
        <HivesList
          columnDefinitions={DEADOUTS_COL_DEFS}
          apiParams={{ yard_id: detail?.id, hive_status: 'deadout', columns: DEADOUTS_COLS_KEYS }}
          config={{
            permanentRightColumns: DEADOUTS_PERMANENT_RIGHT_COL_DEFS,
            defaultSorting: [{ field: 'died', sort: 'desc' }],
          }}
        />
        <DeadoutsListEmpty />
      </GridApiProvider>
    </PageContent>
  );
};
