import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Text } from '@components/common/Text';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { AnyInstance, TransientDrop } from '@components/pollination/PolliMap/types';
import { ListItem } from '@components/pollination/PolliMapPaneSummary/styles';
import { useAlphanumericallySorted } from '@hooks/useSorted';

export const PolliMapPaneDrops: React.VFC = () => {
  const { t } = useTranslation();
  const { dropsList, getKey, selectDrop } = usePolliMap();

  const dropsListSorted = useAlphanumericallySorted(dropsList, 'name');

  const handleItemClick = useCallback(
    (drop: AnyInstance<TransientDrop>) => {
      selectDrop(drop);
    },
    [selectDrop]
  );

  return (
    <Box column stretch>
      {dropsListSorted.map((drop) => (
        <ListItem key={getKey(drop)} onClick={() => handleItemClick(drop)}>
          <Yard size={16} />
          <Box fit paddingHorizontal_050>
            <Text typography={'Heading3'} weight={'600'} align={'left'}>
              {drop.name}
            </Text>
          </Box>
          <Text typography={'Heading3'}>
            {t('pollination_drops_hives_count', { count: drop.targetHiveNumber || 0 })}
          </Text>
        </ListItem>
      ))}
    </Box>
  );
};
