import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ApiResponseErrorDetail } from '@helpers/Api/types';
import {
  makeFetchBlocksMapThunk,
  makeFetchContractsThunk,
  makeFetchContractSummaryThunk,
  makeFetchContractThunk,
  makeFetchDropsListThunk,
  makeFetchDropsMapThunk,
  makeFetchPoisMapThunk,
} from '@redux/Contract/actions';
import { ContractState } from '@redux/Contract/types';
import { RootState } from '@redux/Root/types';

const throttledFetchContractThunk = makeFetchContractsThunk();
const throttledFetchContractSummaryThunk = makeFetchContractSummaryThunk();

export function useContractSummaries() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(throttledFetchContractSummaryThunk);
  }, [dispatch]);

  return useSelector<RootState, BeeContractSummary[] | null>((state) => state.contractReducer.contractSummaries);
}

export function useContractSummary(contractId: number) {
  return useSelector((state) => state.contractReducer.contractSummaries?.find(({ id }) => id === contractId) ?? null);
}

export function useContractState(): ContractState {
  return useSelector<RootState, ContractState>((state) => state.contractReducer);
}

export function useContractId(): number | null {
  const params = useParams<{ uid?: string }>();
  return params.uid ? parseInt(params.uid) : null;
}

export function useContract(id?: number | null) {
  const contractState = useContractState();
  const isReady = useMemo(() => contractState?.contract?.id === id, [contractState.contract, id]);
  const contract = contractState.contract?.id === id ? contractState.contract : null;
  return { isReady, ...contractState, contract };
}

export function useContractFetcher() {
  const dispatch = useDispatch();
  const id = useContractId();

  useEffect(() => {
    if (id) {
      dispatch(makeFetchContractThunk(id));
    }
  }, [dispatch, id]);
}

export function useContractMapData(id: number) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeFetchDropsMapThunk(id));
    dispatch(makeFetchBlocksMapThunk(id));
    dispatch(makeFetchPoisMapThunk(id));
  }, [dispatch, id]);

  const {
    contractDropsMap,
    contractBlocksMap,
    contractPoisMap,
    isFetchingContractDrops,
    isFetchingContractBlocks,
    isFetchingContractPois,
  } = useSelector<RootState, ContractState>((state) => state.contractReducer);

  const loaded =
    contractDropsMap &&
    contractBlocksMap &&
    contractPoisMap &&
    !isFetchingContractDrops &&
    !isFetchingContractBlocks &&
    !isFetchingContractPois;

  return {
    loaded,
    contractDropsMap,
    contractBlocksMap,
    contractPoisMap,
  };
}

export function useContractError() {
  return useSelector<RootState, ApiResponseErrorDetail | null>((state) => state.contractReducer.contractError);
}

export function useContractDrops(options?: { autoFetch?: boolean }) {
  const dispatch = useDispatch();
  const contractId = useContractId();

  const {
    contractDropsMap,
    contractDropsList,
    contractDropsListOptions,
    isFetchingContractDrops,
    hasFetchContractDropsError,
  } = useContract();

  useEffect(() => {
    if (contractId && options?.autoFetch) {
      dispatch(makeFetchDropsListThunk(contractId));
    }
  }, [dispatch, contractId, options?.autoFetch]);

  return {
    contractDropsMap,
    contractDropsList,
    contractDropsListOptions,
    isFetchingContractDrops,
    hasFetchContractDropsError,
  };
}

export function useContracts() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(throttledFetchContractThunk);
  }, [dispatch]);

  return useSelector<RootState, ContractState>((state) => state.contractReducer);
}
