import React from 'react';
import { useTranslation } from 'react-i18next';

import { PracticeIcon } from '@components/common/PracticeIcon';
import { TooltipYardAction } from '@components/common/TooltipYardAction';
import {
  StyledSectionHeader,
  StyledSectionRow,
  StyledSectionSubHeader,
  StyledSectionValue,
} from '@components/yard/YardsMapDetailsPane/styles';
import { usePractices } from '@redux/Practices/hooks';

export const YardsMapDetailsPaneActions: React.VFC<BeeYardOnMapDetailedInfo> = ({
  lastFeedingAction,
  lastFeedingDatetime,
  lastTreatmentAction,
  lastTreatmentDatetime,
}) => {
  const { t } = useTranslation();
  const { getActionName } = usePractices();

  return (
    <>
      <StyledSectionHeader typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
        {t('feeding_and_treatment')}
      </StyledSectionHeader>
      <StyledSectionRow>
        <StyledSectionSubHeader>
          <PracticeIcon usePresetFeeding />
          {t('last_feed')}
        </StyledSectionSubHeader>

        {lastFeedingAction ? (
          <StyledSectionValue dashed>
            {getActionName(lastFeedingAction, '-')}
            <TooltipYardAction
              icon={<PracticeIcon usePresetFeeding />}
              title={t('feeding')}
              actions={[{ actionId: lastFeedingAction, dates: [lastFeedingDatetime] }]}
            />
          </StyledSectionValue>
        ) : (
          <StyledSectionValue>-</StyledSectionValue>
        )}
      </StyledSectionRow>
      <StyledSectionRow>
        <StyledSectionSubHeader>
          <PracticeIcon usePresetTreatment />
          {t('last_treatment')}
        </StyledSectionSubHeader>

        {lastTreatmentAction ? (
          <StyledSectionValue dashed>
            {getActionName(lastTreatmentAction, '-')}
            <TooltipYardAction
              icon={<PracticeIcon usePresetTreatment />}
              title={t('Treatment')}
              actions={[{ actionId: lastTreatmentAction, dates: [lastTreatmentDatetime] }]}
            />
          </StyledSectionValue>
        ) : (
          <StyledSectionValue>-</StyledSectionValue>
        )}
      </StyledSectionRow>
    </>
  );
};
