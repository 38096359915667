import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ModalBaseProps } from '@components/common/ModalBase/types';
import { SelectionCard } from '@components/form/inputs/SelectionCard';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useIntercomArticleOpener } from '@helpers/Intercom/hooks';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { makeActiveDropsDisposeAction, makeFetchActiveDropsThunk } from '@redux/Contract/actions';
import { makeRenewContractThunk } from '@redux/RenewContract/actions';

import { ClearOutDropsAndAction } from './ClearOutDropsAndAction';

export const RenewModal: React.VFC<{ contractId: number; contractName?: string; modalProps: ModalBaseProps }> = ({
  contractId,
  contractName,
  modalProps,
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeDrops = useSelector((state) => state.contractReducer.contractActiveDrops);
  const { isFetchingRenewContract } = useSelector((state) => state.renewContractReducer);

  const hasActiveDrops = activeDrops !== null && activeDrops.length;
  const [includeRelatedEntities, setIncludeRelatedEntities] = useState(true);
  const onOptionChange = useCallback((value) => {
    setIncludeRelatedEntities(value);
  }, []);
  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);

  const renewModalTitle = useMemo(
    () => (contractName ? t('renew_contract_name', { contract: contractName }) : t('renew_contract')),
    [contractName, t]
  );

  const renewContract = useCallback(
    async () => dispatch(makeRenewContractThunk(contractId, includeRelatedEntities)),
    [contractId, includeRelatedEntities, dispatch]
  );

  const closeModal = useCallback(() => {
    modalProps.onRequestClose && modalProps.onRequestClose();
  }, [modalProps]);

  const onSuccess = useCallback(() => {
    closeModal();
    if (history.location.pathname === URLUtil.buildPagePath(APP.routes.pollinationContractsOngoingList)) {
      history.push(URLUtil.buildPagePath(APP.routes.pollinationContractsOngoingList));
    } else {
      history.push(URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, { pathParams: { uid: contractId } }));
    }
  }, [closeModal, contractId, history]);

  const performRenewAction = useCallback(async () => {
    try {
      await renewContract();
      onSuccess();

      Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_CONTRACT_RENEW });
    } catch (_) {
      closeModal();
    }
  }, [closeModal, onSuccess, renewContract]);

  const onAcceptClick = useCallback(() => {
    if (hasActiveDrops) {
      setIsBulkActionsOpen(true);
      return;
    }

    performRenewAction();
  }, [hasActiveDrops, performRenewAction]);

  useEffect(() => {
    if (modalProps.isOpen) {
      dispatch(makeFetchActiveDropsThunk(contractId));
    } else {
      dispatch(makeActiveDropsDisposeAction());
    }
    setIsBulkActionsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.isOpen]);

  const openRenewContractArticle = useIntercomArticleOpener(APP.intercomArticles.RENEW_CONTRACTS);

  return (
    <Modal nonDismissible={isFetchingRenewContract} {...modalProps}>
      {isBulkActionsOpen && activeDrops ? (
        <ClearOutDropsAndAction
          activeDrops={activeDrops}
          closeModal={closeModal}
          onSuccess={onSuccess}
          contractId={contractId}
          secondActionProps={{
            dispatchAction: renewContract,
            acceptButtonText: t('clear_out_and_renew'),
            progressTitle: t('renewing'),
            warningText: t('clear_out_contract_warn_renew'),
            subtitle: t('clear_out_renew_contract_desc'),
          }}
        />
      ) : (
        <>
          <ModalHeader
            title={renewModalTitle}
            subtitle={t('renew_contract_desc')}
            linkText={t('renew_contract_link')}
            onLinkClick={openRenewContractArticle}
          />
          <ModalContent>
            <SelectionCard
              radioButtonProps={{
                name: `renew-contract-content-${contractId}`,
                id: `contract-details-and-map-${contractId}`,
                selectedOptionValue: includeRelatedEntities,
                value: true,
                onChange: onOptionChange,
              }}
              title={t('renew_contract_details_map')}
              content={t('renew_contract_details_map_desc')}
              bottomText={t('recommended')}
              wrapperBoxProps={{ marginBottom_100: true }}
            />

            <SelectionCard
              radioButtonProps={{
                name: `renew-contract-content-${contractId}`,
                id: `contract-details-only-${contractId}`,
                selectedOptionValue: includeRelatedEntities,
                value: false,
                onChange: onOptionChange,
              }}
              title={t('renew_contract_details_only')}
              content={t('renew_contract_details_only_desc')}
            />
          </ModalContent>
          <ModalFooter
            autoCloseOnReject
            rejectText={t('cancel')}
            acceptText={t('renew')}
            onAcceptClick={onAcceptClick}
            acceptButtonProps={{ disabled: isFetchingRenewContract }}
            rejectButtonProps={{ disabled: isFetchingRenewContract }}
          />
        </>
      )}

      {!isBulkActionsOpen && <Loading visible={isFetchingRenewContract} roundedCorners whiteBackground />}
    </Modal>
  );
};
