import produce from 'immer';

import { ReportsActionType, ReportsReducer, ReportsState } from '@redux/Reports/types';

const defaultState: ReportsState = {
  isFetching: false,
  hasFetchError: false,
};

export const reportsReducer: ReportsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case ReportsActionType.FETCH_START:
        state.isFetching = true;
        break;

      case ReportsActionType.FETCH_FINISH:
        state.isFetching = false;
        state.hasFetchError = !!action.payload.error;
        break;

      default:
        break;
    }

    return state;
  });
};
