import React, { useMemo } from 'react';
// nectar
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
// vendor
import styled from 'styled-components';

// icons:
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { EmptyView } from '@components/common/EmptyView';
import { HeaderActions } from '@components/common/HeaderActions';
import { Add } from '@components/common/Icon/presets/Add';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { Menu } from '@components/common/Menu';
import { PageContent } from '@components/common/PageContent';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { StyledTableHeightSetter } from '@components/common/Table/styles';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { Table } from '@components/deprecated/Table_deprecated/Table';
import TableHeader from '@components/deprecated/Table_deprecated/TableHeader';
import { groupColorIndexToRealColor } from '@helpers/deprecated/color';

const StyledTable = styled(Table)`
  width: 100%;
`;

const StyledTr = styled.tr`
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => !props.isMobile && props.theme.hoverColor};
  }
`;

const GroupColor = styled.div`
  height: 1px;
  width: 16px;
  display: inline-flex;
  margin-right: 16px;
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin-top: -12px;
  }
`;

export const GroupsView = ({
  t,
  isMobile,
  groups,
  sort,
  handleSort,
  handleEditGroup,
  handleRemoveGroup,
  handleGroupClick,
  handleAddGroup,
}) => {
  const operation = useSelector((state) => state.operationReducer.operation);

  const seasonStartDate = useMemo(() => {
    if (operation) {
      return moment(operation.seasonStart, 'YYYY/MM/DD').format('MMMM DD, YYYY');
    }
    return null;
  }, [operation]);

  const actions = useMemo(
    () => [
      {
        title: t('create_groups'),
        icon: Add,
        onClick: handleAddGroup,
      },
    ],
    [handleAddGroup, t]
  );

  if (!groups.length) {
    return (
      <Box column fullHeight>
        <PageHeader>
          <PageHeaderTitle>{t('groups')}</PageHeaderTitle>
        </PageHeader>

        <EmptyView heading={t('no_groups')} text={t('no_groups_msg')}>
          <Box justifyContent="center">
            <Button primary withLeadingIcon onClick={handleAddGroup}>
              <Add />
              {t('create_groups')}
            </Button>
          </Box>
        </EmptyView>
      </Box>
    );
  }

  return (
    <Box column stretch flexGrow={1}>
      <PageHeader>
        <PageHeaderTitle>{t('groups')}</PageHeaderTitle>
        <HeaderActions actions={actions} />
      </PageHeader>
      <PageContent>
        <StyledTableHeightSetter $maxViewHeightOffset={170}>
          <StyledTable isMobile={isMobile}>
            <thead>
              <tr>
                <TableHeader handleSort={handleSort} id="name" data={t('group')} sort={sort} />
                <TableHeader handleSort={handleSort} id="nb_yards" data={t('yards')} sort={sort} />
                <TableHeader handleSort={handleSort} id="nb_hives" data={t('hives')} sort={sort} />
                <TableHeader
                  handleSort={handleSort}
                  id="nb_deadout_season"
                  data={t('deadout_short')}
                  sort={sort}
                  tooltip={t('deadout_since', { date: seasonStartDate })}
                  tooltipPlace="right"
                  tooltipOffset={{ top: 32 }}
                />

                {/* <TableHeader
              handleSort={handleSort}
              id="outliers"
              data={t("outliers")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="mortality"
              data={t("mortality")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="latest_used"
              data={t("latest_used")}
              sort={sort}
            />
            <TableHeader
              handleSort={handleSort}
              id="reporting_users"
              data={t("reporting_users")}
              sort={sort}
            /> */}
                <th />
              </tr>
            </thead>

            <tbody>
              {groups.map((group) => {
                const { id, name, nb_yards: nbYards, nb_hives: nbHives, nb_deadout_season: nbDeadoutSeason } = group;
                const htmlId = `group-${group.id}-menu`;

                const menuItems = [
                  { title: t('edit'), icon: Edit, onClick: () => handleEditGroup(group) },
                  { title: t('remove'), icon: Delete, danger: true, onClick: () => handleRemoveGroup(group) },
                ];

                return (
                  <StyledTr
                    key={id}
                    isMobile={isMobile}
                    onClick={() => {
                      handleGroupClick(id);
                    }}
                  >
                    <td
                      style={{
                        textTransform: 'capitalize',
                        width: '153px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        verticalAlign: 'middle',
                      }}
                    >
                      <GroupColor color={groupColorIndexToRealColor(group)} />
                      {name}
                    </td>
                    <td style={{ width: '97px' }}>{nbYards}</td>
                    <td style={{ width: '138px' }}>{nbHives}</td>
                    <td>{nbDeadoutSeason}</td>

                    <td>
                      <Box justifyContent="flex-end">
                        <ThreeDotsMenuTrigger id={htmlId} />
                        <Menu items={menuItems} target={htmlId} />
                      </Box>
                    </td>
                  </StyledTr>
                );
              })}
            </tbody>
          </StyledTable>
        </StyledTableHeightSetter>
      </PageContent>
    </Box>
  );
};

GroupsView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  display: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  setRef: PropTypes.object.isRequired,
  sort: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  user: PropTypes.shape({ season_start: PropTypes.string }).isRequired,
  handleSort: PropTypes.func.isRequired,
  handleDisplayMenu: PropTypes.func.isRequired,
  handleGroupClick: PropTypes.func.isRequired,
  handleEditGroup: PropTypes.func.isRequired,
  handleRemoveGroup: PropTypes.func.isRequired,
  handleAddGroup: PropTypes.func.isRequired,
};
