import moment from 'moment/moment';

import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Globals } from '@helpers/Globals';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeAuthFetchUserThunk } from '@redux/Auth/actions';
import { OperationAction, OperationActionType } from '@redux/Operation/types';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

const SEASON_START_DATE_FORMAT = 'YYYY-MM-DD';

export const makeFetchOperationStartAction = (): OperationAction => ({
  type: OperationActionType.FETCH_START,
});

export const makeFetchOperationSuccessAction = (operation: BeeOperation): OperationAction => ({
  type: OperationActionType.FETCH_SUCCESS,
  payload: { operation },
});

export const makeFetchOperationFailureAction = (): OperationAction => ({
  type: OperationActionType.FETCH_FAILURE,
});

export const makePatchOperationStartAction = (): OperationAction => ({
  type: OperationActionType.PATCH_START,
});

export const makePatchOperationSuccessAction = (operation: BeeOperation): OperationAction => ({
  type: OperationActionType.PATCH_SUCCESS,
  payload: { operation },
});

export const makePatchOperationFailureAction = (): OperationAction => ({
  type: OperationActionType.PATCH_FAILURE,
});

export const makeFetchOperationThunk = Thunk.createTakeFirst(() => {
  return async (dispatch) => {
    if (!Globals.operationId) {
      return;
    }

    dispatch(makeFetchOperationStartAction());

    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.operation));

    if (response.error) {
      dispatch(makeFetchOperationFailureAction());

      // If fetch operation fail with this code, it means the
      // operation is invalid. Calling user refresh will update
      // the available operations.
      if (response.status === 403) {
        dispatch(makeAuthFetchUserThunk());
      }
    } else {
      const data = parseOperationFromApi(await response.json());
      dispatch(makeFetchOperationSuccessAction(data));
    }
  };
});

export const makePatchOperationThunk = Thunk.createTakeLast(
  (payload: Partial<BeeOperation>, saveMessage = 'snack_account_changed_msg') => {
    return async (dispatch) => {
      dispatch(makePatchOperationStartAction());

      const body = parseOperationToApi(payload);
      const response = await Api.patch(URLUtil.buildURL(ENDPOINTS.operation), body);

      if (response.error) {
        dispatch(makePatchOperationFailureAction());
        dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      } else {
        const data = parseOperationFromApi(await response.json());
        dispatch(makePatchOperationSuccessAction(data));
        dispatch(makeShowSnackbarAction({ messageTranslation: saveMessage as string }));
      }
    };
  }
);

function parseOperationFromApi(data: any): BeeOperation {
  const dataCamelCase = CaseAdapter.objectToCamelCase(data);
  dataCamelCase.seasonStart = moment(dataCamelCase.seasonStart, SEASON_START_DATE_FORMAT).toDate();
  return dataCamelCase;
}

function parseOperationToApi(data: Partial<BeeOperation>): any {
  const dataSnakeCase = CaseAdapter.objectToSnakeCase(data);

  if ('seasonStart' in data) {
    dataSnakeCase.season_start = moment(data.seasonStart).format(SEASON_START_DATE_FORMAT);
  }

  return dataSnakeCase;
}
