import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useAccordion } from '@components/common/Accordion/hooks';
import { ChevronDown } from '@components/common/Icon/presets/ChevronDown';
import { Text } from '@components/common/Text';
import { Children } from '@helpers/Children';

import { AccordionContext } from './context';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionContentInner,
  StyledAccordionHeader,
  StyledAccordionHeaderIconWrapper,
} from './styles';
import { AccordionContentProps, AccordionContextValue, AccordionHeaderProps, AccordionProps } from './types';

const DEF_COLLAPSE_OVERFLOW_DELAY = 250;

export const Accordion: React.FC<AccordionProps> = ({
  expanded: propExpanded,
  onExpandedChange: propOnExpandedChange,
  initiallyExpanded,
  className,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(!!initiallyExpanded);
  const [contentHeight, setContentHeight] = useState(0);

  const toggle = useCallback(() => {
    setIsExpanded((curr) => !curr);
  }, []);

  const accordionContext = useMemo<AccordionContextValue>(
    () => ({ isExpanded: isExpanded, toggle, contentHeight, setContentHeight }),
    [contentHeight, isExpanded, toggle]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => propOnExpandedChange && propOnExpandedChange(isExpanded), [isExpanded]);
  useEffect(() => (typeof propExpanded !== 'undefined' ? setIsExpanded(propExpanded) : void 0), [propExpanded]);

  return (
    <AccordionContext.Provider value={accordionContext}>
      <StyledAccordion className={className}>{children}</StyledAccordion>
    </AccordionContext.Provider>
  );
};

export const AccordionHeader: React.FC<AccordionHeaderProps> = ({ className, children }) => {
  const { toggle, isExpanded } = useAccordion();

  const content = useMemo(() => {
    if (Children.hasOnlyText(children)) {
      return (
        <Text typography={'Heading3'} weight={'600'}>
          {children}
        </Text>
      );
    }
    return children;
  }, [children]);

  return (
    <StyledAccordionHeader type={'button'} className={className} onClick={toggle}>
      {content}
      <StyledAccordionHeaderIconWrapper expanded={isExpanded}>
        <ChevronDown size={24} color={'grey08'} />
      </StyledAccordionHeaderIconWrapper>
    </StyledAccordionHeader>
  );
};

export const AccordionContent: React.FC<AccordionContentProps> = ({ className, children }) => {
  const [showOverflow, setShowOverflow] = useState(false);
  const { isExpanded, contentHeight, setContentHeight } = useAccordion();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const sizeObserver = useMemo(
    () =>
      new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
        requestAnimationFrame(() => {
          entries.forEach(({ contentRect }) => contentRect.height > 0 && setContentHeight(contentRect.height));
        });
      }),
    [setContentHeight]
  );

  const content = useMemo(() => {
    if (typeof children === 'string') {
      return <Text typography={'SmallParagraph'}>{children}</Text>;
    }
    return children;
  }, [children]);

  useLayoutEffect(() => {
    const { current } = contentRef;
    if (current) {
      sizeObserver.observe(current);
      return () => sizeObserver.unobserve(current);
    }
  }, [sizeObserver]);

  useEffect(() => {
    if (isExpanded) {
      const timeout = setTimeout(() => setShowOverflow(true), DEF_COLLAPSE_OVERFLOW_DELAY);
      return () => clearTimeout(timeout);
    } else {
      setShowOverflow(false);
    }
  }, [isExpanded]);

  return (
    <StyledAccordionContent
      className={className}
      height={contentHeight}
      expanded={isExpanded}
      showOverflow={showOverflow}
    >
      <StyledAccordionContentInner ref={contentRef}>{content}</StyledAccordionContentInner>
    </StyledAccordionContent>
  );
};
