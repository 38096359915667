import React from 'react';

import { useTransitionDuration } from '@components/common/Transition/hooks';
import { StyledAnimatedAppearingContent } from '@components/common/Transition/styles';

import { ContentAppearingTransitionProps } from './types';

export const ContentAppearingTransition: React.VFC<ContentAppearingTransitionProps> = ({
  animationDuration,
  children,
  ...boxProps
}) => {
  const duration = useTransitionDuration(animationDuration);

  return (
    <StyledAnimatedAppearingContent $duration={duration} {...boxProps}>
      {children}
    </StyledAnimatedAppearingContent>
  );
};
