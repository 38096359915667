import React, { useCallback, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { NotesNavigator } from '@components/common/NotesNavigator';
import { PracticeIcon } from '@components/common/PracticeIcon';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import {
  StyledEmptyMessageWrapper,
  StyledNotesWrapper,
  StyledTooltipBox,
  StyledVisitCard,
  StyledVisitCardIconWrapper,
  StyledVisitCardLeftText,
  StyledVisitCardRightText,
  StyledVisitCardRightTextDashed,
} from '@components/common/YardVisitCard/styles';
import { YardVisitCardProps } from '@components/common/YardVisitCard/types';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { Number } from '@helpers/Number';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

export const YardVisitCard: React.VFC<YardVisitCardProps> = ({
  preset = 'timeline',
  occurredAt,
  yardNbHivesOnVisitDate,
  practices,
  notes,
}) => {
  const t = useTranslation();
  const dateUtil = useDateUtil();
  const { getPractice, getActionName, getPracticeName } = usePractices();

  const hasNotes = notes.length > 0;
  const [selectedNote, setSelectedNote] = useState<{ text: string; nbHives?: number } | null>(null);

  const mergedPractices = useMemo(() => {
    return [
      ...practices.map(({ actionId, categoryId, value, nbHives, occurredAt }) => {
        const practiceDefinition = getPractice(categoryId);
        return {
          title: capitalize(getPracticeName(categoryId, '-')),
          subtitle:
            practiceDefinition?.fieldType === 'slider'
              ? Number.getReadableNumber(value)
              : capitalize(getActionName(actionId, '-')),
          icon: <PracticeIcon practiceId={categoryId} />,
          value: practiceDefinition?.isHiveData ? `${nbHives}/${yardNbHivesOnVisitDate ?? '-'}` : capitalize(t('yard')),
          tooltip: practiceDefinition?.isHiveData
            ? t(nbHives === 1 ? 'timeline_applied_action_tooltip_singular' : 'timeline_applied_action_tooltip', {
                count: nbHives,
              })
            : null,
          occurredAt,
          isHiveData: !!practiceDefinition?.isHiveData,
        };
      }),
    ];
  }, [getActionName, getPractice, getPracticeName, practices, t, yardNbHivesOnVisitDate]);

  const sortedPractice = useMemo(() => {
    return mergedPractices.sort((a, b) => {
      if (a.isHiveData !== b.isHiveData) {
        return a.isHiveData ? -1 : 1;
      }
      const comp = a.title.localeCompare(b.title);
      if (comp !== 0) {
        return comp;
      }
      return (a.occurredAt || '').localeCompare(b.occurredAt || '') * -1; // Descendant;
    });
  }, [mergedPractices]);

  const showTooltipNbHivesNote = !dateUtil.isDateToday(occurredAt);
  const showTooltipHivesRemovedNote = practices.some(({ nbHives }) => nbHives > yardNbHivesOnVisitDate);

  const sendHiveCountTooltipViewEvent = useCallback(() => {
    Analytics.sendEvent({
      event: AnalyticsEventType.TIMELINE_HIVES_COUNT_TOOLTIP_VIEW,
      eventData: Analytics.getPageData(),
    });
  }, []);

  const tooltipNotes = useMemo(() => {
    const notes: Array<string> = [];
    showTooltipNbHivesNote &&
      notes.push(
        t(
          yardNbHivesOnVisitDate === 1
            ? 'timeline_yard_nb_hives_during_visit_tooltip_singular'
            : 'timeline_yard_nb_hives_during_visit_tooltip',
          {
            date: dateUtil.getFormattedDate(occurredAt, 'MMM DD, YYYY'),
            count: yardNbHivesOnVisitDate,
          }
        )
      );
    showTooltipHivesRemovedNote &&
      notes.push(
        t(
          yardNbHivesOnVisitDate === 1
            ? 'timeline_hives_possibly_removed_tooltip_single'
            : 'timeline_hives_possibly_removed_tooltip'
        )
      );
    return notes;
  }, [dateUtil, occurredAt, showTooltipNbHivesNote, showTooltipHivesRemovedNote, t, yardNbHivesOnVisitDate]);

  const tooltipNotesElement = tooltipNotes.length > 0 && (
    <Box column gap_050 marginBottom_050>
      {tooltipNotes.map((note, key) => (
        <Text key={key} typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: note }} />
      ))}
    </Box>
  );

  const notesGridRowStyles = { gridRow: `${sortedPractice.length + 2}/${sortedPractice.length + 2}` };

  /**
   * This is most likely a bug from the app, since inspections must have at
   * least one practice or non-empty notes, but we're still covering it
   * just in case.
   * */
  const arePracticesAndNotesEmpty = sortedPractice.length === 0 && !hasNotes;

  return (
    <StyledVisitCard preset={preset}>
      {arePracticesAndNotesEmpty && (
        <StyledEmptyMessageWrapper padding_050 fullWidth>
          <Text typography={'CaptionSmall'}>{t('empty_activity_message')}</Text>
        </StyledEmptyMessageWrapper>
      )}

      {sortedPractice.map(({ title, subtitle, icon, value, tooltip }, index) => {
        const gridRowStyles = { gridRow: `${index + 1}/${index + 1}` };
        return (
          <React.Fragment key={index}>
            <StyledVisitCardIconWrapper style={gridRowStyles} preset={preset}>
              {icon}
            </StyledVisitCardIconWrapper>
            <StyledVisitCardLeftText style={gridRowStyles} preset={preset}>
              <b>{title}</b>: {subtitle}
            </StyledVisitCardLeftText>
            <StyledVisitCardRightText style={gridRowStyles} preset={preset}>
              <StyledVisitCardRightTextDashed $isDashed={!!tooltip}>
                {value}
                {!!tooltip && (
                  <Tooltip offset={[0, 0]} onShown={sendHiveCountTooltipViewEvent}>
                    {tooltipNotesElement}
                    <StyledTooltipBox>
                      <Text typography={'CaptionSmall'}> {tooltip} </Text>
                    </StyledTooltipBox>
                  </Tooltip>
                )}
              </StyledVisitCardRightTextDashed>
            </StyledVisitCardRightText>
          </React.Fragment>
        );
      })}

      {hasNotes && (
        <>
          <StyledNotesWrapper fit style={notesGridRowStyles} preset={preset}>
            <NotesNavigator
              notes={notes}
              titleTextProps={preset === 'tooltip' ? { typography: 'CaptionSmall', weight: '600' } : undefined}
              bodyTextProps={preset === 'tooltip' ? { typography: 'CaptionSmall' } : undefined}
              onNoteSelected={setSelectedNote}
            />
          </StyledNotesWrapper>

          {!!selectedNote && (
            <StyledVisitCardRightText style={notesGridRowStyles} preset={preset}>
              <StyledVisitCardRightTextDashed>
                {selectedNote.nbHives ?? '-'}/{yardNbHivesOnVisitDate}
                {!!selectedNote.nbHives && (
                  <Tooltip offset={[0, 0]} onShown={sendHiveCountTooltipViewEvent}>
                    {tooltipNotesElement}
                    <StyledTooltipBox>
                      <Text typography={'CaptionSmall'}>
                        {t(
                          selectedNote.nbHives === 1
                            ? 'timeline_applied_action_tooltip_singular'
                            : 'timeline_applied_action_tooltip',
                          {
                            count: selectedNote.nbHives,
                          }
                        )}
                      </Text>
                    </StyledTooltipBox>
                  </Tooltip>
                )}
              </StyledVisitCardRightTextDashed>
            </StyledVisitCardRightText>
          )}
        </>
      )}
    </StyledVisitCard>
  );
};
