import React from 'react';

import { Column, View, YardRow } from '@components/yard/YardsList/types';

export interface YardsListTooltipsContextValue {
  /**
   * Returns true if the respective yard's tooltip is being fetched.
   * */
  isFetching: (yardId: number) => boolean;

  /**
   * Fetches the respective yard's tooltip data.
   * */
  fetchTooltipData: (yardId: number) => void;

  /**
   * Returns the tooltip data for the given yard, column and view.
   * It may return undefined if the data is not available yet.
   * */
  getTooltipData: (yardId: number, column: Column, view: View) => any | undefined;
}

export const YardsListTooltipsContext = React.createContext<YardsListTooltipsContextValue | null>(null);

export interface YardsListSaveViewContextValue {
  hasPendingChange: boolean;

  /**
   * Patches the current columns to API.
   * */
  patchViewState: () => void;

  /**
   * Discards any unsaved change.
   * */
  discardViewState: () => void;
}

export const YardsListSaveViewContext = React.createContext<YardsListSaveViewContextValue | null>(null);

export interface YardsListSelectionContextValue {
  selectedYards: Record<number, YardRow>;
  selectionCount: number;
  selectYard: (yardToSelect: YardRow) => void;
  deselectYard: (yardToDeselect: YardRow) => void;
  selectAllOnPage: () => void;
  deselectAllOnPage: () => void;
  selectAllAcrossPages: () => void;
  deselectAllAcrossPages: () => void;
  /**
   * Returns true when user has clicked "Select All Yards", which doesn't actually add the yards to the state map
   *
   * The reason is that the bulk Assign To Groups api allows the user to apply a bulk group change to all
   * yards, by sending an empty array, the group change will apply to all filtered yards
   * BUT the bulk Assin to Contract api does not allow this, to prevent the user from
   * accidentally assigning all their yards to a contract by mistake
   */
  hasClickedSelectAllAcrossPages: boolean;

  /**
   * Returns true when the number of filtered yards matches the number of yards in state
   *
   * Meaning,
   * the user has gone through and manually clicked 'select' or 'select all on page' for each yard / page.
   * In this case, the user could assing all their yards to a contract
   */
  allYardsAcrossPagesAreSelectedManually: boolean;
  hasSelectedAllOnPage: boolean;
  hasSelectedAllOnCurrentPage: boolean;
}

export const YardsListSelectionContext = React.createContext<YardsListSelectionContextValue | null>(null);
