import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColDef, IDatasource, IGetRowsParams, SortModelItem } from 'ag-grid-community';
import { capitalize, chunk } from 'lodash';
import * as uuid from 'uuid';

import { useGridApi } from '@components/common/AgGrid';
import {
  DEF_COLUMNS_LOADING_BATCH_SIZE,
  DEF_COLUMNS_LOADING_THREAD_COUNT,
} from '@components/common/DynamicTable/constants';
import { DEF_ABSTRACT_COLUMNS_KEYS } from '@components/yard/YardsList/constants';
import { useColumnNameGetterForAnalytics } from '@components/yard/YardsList/hooks/useColumnNameGetterForAnalytics';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { ENDPOINTS } from '@config/api';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { ApiClient } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { AsyncTaskPool } from '@helpers/AsyncTaskPool';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { useDispatch } from '@helpers/Thunk/hooks';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { useAppliedYardsFilters } from '@redux/YardsFilters/hooks';

import { ColDefWithMetadata, Column, ColumnKey, ColumnType, YardsListResponse } from '../types';

/**
 * This hook should only be used in one place
 * otherwise data will be re-called.
 */
export function useYardsListDataProvider(): { isFetchingFirstBatch: boolean } {
  const { gridApi } = useGridApi();
  const [isFetchingFirstBatch, setIsFetchingFirstBatch] = useState(true);
  const [api] = useState(() => new ApiClient());

  const dispatch = useDispatch();
  const appliedFilters = useAppliedYardsFilters();
  const getColumnName = useColumnNameGetterForAnalytics();

  const columnsSortingFunction = useCallback((a: Column, b: Column) => {
    if (a.type !== b.type) {
      return a.type === ColumnType.DEFAULT ||
        (a.type === ColumnType.PRACTICE_CATEGORY && b.type === ColumnType.PRACTICE)
        ? -1
        : 1;
    }
    return 0;
  }, []);

  const getColumns = useCallback(() => {
    return GridApiUtil.getFlattenColumnDefs(gridApi?.getColumnDefs());
  }, [gridApi]);

  const getVisibleColumns = useCallback(() => {
    return getColumns().filter((def) => !def.hide);
  }, [getColumns]);

  const getVisibleColumnIds = useCallback(() => {
    return GridApiUtil.getFlattenColumnIds(getVisibleColumns());
  }, [getVisibleColumns]);

  const getOrderBy = useCallback(
    (sortingItems: Array<SortModelItem>) => {
      const orderBy: Array<string> = [];
      for (const sortingItem of sortingItems) {
        const columns = getColumns();
        const sortingColumnDef = columns.find((column) => sortingItem.colId === column.colId);

        if (sortingColumnDef) {
          const sortingColumn = GridApiUtil.getColumnDefWithMetadata(sortingColumnDef).metadata.column;
          const view = sortingColumn ? GridApiUtil.getActiveView(sortingColumnDef) : null;

          if (view) {
            const sortingPrefix = sortingItem.sort === 'asc' ? '' : '-';
            const sortingKey = view.sortableKey;
            orderBy.push(`${sortingPrefix}${sortingKey}`);
          }
        }
      }
      return {
        order_by: orderBy,
      };
    },
    [getColumns]
  );

  const generateColumDefs = useCallback(
    (column: Column) => {
      let columnDefs = GridApiUtil.getFlattenColumnDefs(gridApi?.getColumnDefs()).filter(
        (columnDef) => columnDef.field === column.key
      );

      if (columnDefs.length === 0) {
        columnDefs.push({ field: column.key, metadata: { column } } as ColDefWithMetadata);
      } else {
        columnDefs = GridApiUtil.getPatchedColumnDefsUsingColumnKey(columnDefs, {
          field: column.key,
          metadata: {
            column,
          },
        } as ColDefWithMetadata);
      }

      return columnDefs.map((columnDef) => {
        const activeView = GridApiUtil.getActiveView(columnDef);
        const sortable = Boolean(activeView?.sortableKey);

        let groupId: string;
        let groupName: string;
        let headerName: string;

        switch (column.type) {
          case ColumnType.PRACTICE:
            groupId = String(column.practice.categoryId);
            groupName = capitalize(column.practice.categoryName);
            headerName = capitalize(column.practice.name);
            break;
          case ColumnType.PRACTICE_CATEGORY:
            groupId = String(column.practiceCategory.id);
            groupName = capitalize(column.practiceCategory.name);
            headerName = groupName;
            break;
          default:
            groupId = column.key;
            groupName = column.key;
            headerName = column.key;
            break;
        }
        const columnDefinition: ColDefWithMetadata = {
          colId: uuid.v4(),
          field: column.key,
          hide: false,
          sortable,
          headerName,
          metadata: {
            groupId: groupId,
            groupName: groupName,
            column,
          },
        };
        return columnDefinition;
      });
    },
    [gridApi]
  );

  const updateAvailableColumns = useCallback(
    (columnsFromApi: Record<ColumnKey, Column>) => {
      if (gridApi) {
        const visibleColumnIds = getVisibleColumnIds();
        const availableColumnsKeys = Object.keys(columnsFromApi);
        const orderedColumns = Object.values(columnsFromApi).sort(columnsSortingFunction);
        let patchedColumns: Array<ColDef> = getColumns();

        for (const column of orderedColumns) {
          const columnDefinitions = generateColumDefs(column);
          for (const columnDef of columnDefinitions) {
            patchedColumns = GridApiUtil.getPatchedColumnDefsUsingColumnKey(patchedColumns, columnDef);
          }
        }

        // Remove stale columns and update hide property.
        patchedColumns = patchedColumns.filter((columnDef) => availableColumnsKeys.includes(columnDef.field as string));
        patchedColumns = patchedColumns.map((columnDef) => ({
          ...columnDef,
          hide: !visibleColumnIds.includes(columnDef.colId as string),
        }));

        GridApiUtil.updateGridColumns(gridApi, patchedColumns);
      }
    },
    [columnsSortingFunction, generateColumDefs, getColumns, getVisibleColumnIds, gridApi]
  );

  const getColumnBatches = useCallback(() => {
    const visibleColumns = GridApiUtil.getColumnDefsWithMetadata(getVisibleColumns())
      .filter((colDef) => !DEF_ABSTRACT_COLUMNS_KEYS.includes(colDef.field))
      .filter((col) => !col.sort); // Filtering out sorting columns, since they are always returned anyway.
    const visibleColumnsKeys = GridApiUtil.getFlattenColumnKeys(visibleColumns).map(CaseAdapter.toSnakeCase);
    return chunk(visibleColumnsKeys, DEF_COLUMNS_LOADING_BATCH_SIZE);
  }, [getVisibleColumns]);

  const getVisibleColumnNamesForAnalytics = useCallback(() => {
    return GridApiUtil.getNonAbstractVisibleColumnDefs(gridApi?.getColumnDefs()).map(getColumnName).join(', ');
  }, [getColumnName, gridApi]);

  const fetchFromApi = useCallback(
    async (queryParams) => {
      const url = URLUtil.buildURL(ENDPOINTS.yardsListWhiteboard, { ...queryParams });
      const response = await api.get(url);

      let error: ApiResponseError | null = null;
      let data: YardsListResponse | null = null;

      if (response.error) {
        error = response.error;
      } else {
        data = CaseAdapter.objectToCamelCase<YardsListResponse>(await response.json(), ['key']);
      }
      return { data, error };
    },
    [api]
  );

  const dataSource = useMemo<IDatasource>(
    () => ({
      async getRows({ startRow, endRow, sortModel, successCallback }: IGetRowsParams) {
        api.cancelPendingRequests();

        // Clears all table rows and fires the skeleton loading animation.
        gridApi?.getRenderedNodes().forEach((node) => node.setData(undefined));

        setIsFetchingFirstBatch(true);

        const [firstBatchColumns, ...remainingColumnBatches] = getColumnBatches();
        const orderBy = getOrderBy(sortModel);
        const timestamp = +new Date();

        const firstBatchQueryParams = {
          limit: endRow - startRow,
          offset: startRow,
          columns: firstBatchColumns,
          ...orderBy,
          ...appliedFilters,
        };
        const { data: firstBatchData, error } = await fetchFromApi(firstBatchQueryParams);

        if (error && !error?.isAborted) {
          dispatch(makeShowSnackbarAction(error.snackbarOptions));
        }

        if (!firstBatchData) {
          successCallback([], gridApi?.getInfiniteRowCount());
          setIsFetchingFirstBatch(false);
          return;
        }

        Analytics.sendEvent({
          event: AnalyticsEventType.WHITEBOARD_PARTIAL_LOAD,
          eventData: {
            visible_columns: getVisibleColumnNamesForAnalytics(),
            elapsed: (+new Date() - timestamp) / 1000,
          },
        });

        updateAvailableColumns(firstBatchData.columns);
        successCallback(firstBatchData.rows, firstBatchData.pagination.count);
        setIsFetchingFirstBatch(false);

        const yardIds = firstBatchData.rows.map((row) => row.meta.id);

        const remainingBatchesTasks = remainingColumnBatches.map((columns) => {
          return async () => {
            const params = {
              columns,
              yard_ids: yardIds,
              suppress_meta: true,
            };
            const { data, error } = await fetchFromApi(params);

            if (!data) {
              throw error;
            }

            for (const rowFromAPI of data.rows) {
              const rowId = String(rowFromAPI.meta.id);
              const rowNode = gridApi?.getRowNode(rowId);
              const rowFromGrid = rowNode?.data;

              if (!rowNode || !rowFromGrid) {
                console.warn(`Can't find existing row data for yard ${rowId}.`);
                continue;
              }

              rowNode?.setData({
                meta: rowFromGrid.meta,
                data: {
                  ...rowFromGrid.data,
                  ...rowFromAPI.data,
                },
              });
              gridApi?.dispatchEvent({ type: 'rowDataUpdated' });
            }
          };
        });

        await new AsyncTaskPool({
          tasks: remainingBatchesTasks,
          threads: DEF_COLUMNS_LOADING_THREAD_COUNT,
        }).execute();

        Analytics.sendEvent({
          event: AnalyticsEventType.WHITEBOARD_COMPLETE_LOAD,
          eventData: {
            visible_columns: getVisibleColumnNamesForAnalytics(),
            elapsed: (+new Date() - timestamp) / 1000,
          },
        });
      },
      destroy() {
        api.cancelPendingRequests();
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridApi, appliedFilters]
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, dataSource]);

  return { isFetchingFirstBatch };
}
