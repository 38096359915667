import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

export const StyledSummaryRow = styled(Box)(
  ({ theme }) => css`
    height: 56px;

    &:not(:last-child) {
      border-bottom: ${theme.primitives.colors.grey03} 1px solid;
    }

    a {
      // Despite it's a link, Design asked to make
      // it normal weight in that case.
      font-weight: 400;
    }
  `
);

export const StyledSummaryRowText = styled(Text)(
  () => css`
    max-width: 60%;

    &,
    & a {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0;
    }
  `
);

export const StyledTooltipPractice = styled(Box)(
  ({ theme }) => css`
    min-width: 150px;
    border: ${theme.primitives.colors.grey06} 1px solid;
    border-radius: ${theme.shape.paperRadius01}px;
    color: ${theme.primitives.colors.white};
  `
);
