import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Button, MenuTrigger } from '@components/common/CTA';
import { Close } from '@components/common/Icon';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { EmptyYard } from '@components/common/Icon/presets/EmptyYard';
import { Loading } from '@components/common/Loading';
import { Menu } from '@components/common/Menu';
import { MenuItem } from '@components/common/Menu/types';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { Text } from '@components/common/Text';
import {
  useOnYardSaveListener,
  useYardCreateOrUpdateModalOpener,
} from '@components/yard/YardCreateOrUpdateModal/hooks';
import { YardDetailsClearOutAndDeleteModal } from '@components/yard/YardDetails/YardDetailsClearOutAndDeleteModal';
import { YardName } from '@components/yard/YardName';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import {
  StyledContentTransition,
  StyledFooter,
  StyledHeader,
  StyledHeaderCloseButton,
  StyledLoaderWrapper,
  StyledPane,
  StyledPaneScroll,
  StyledSection,
  StyledYardTitle,
} from '@components/yard/YardsMapDetailsPane/styles';
import { YardsMapDetailsPaneActions } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneActions';
import { YardsMapDetailsPaneSummary } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneSummary';
import { YardsMapDetailsPaneVisit } from '@components/yard/YardsMapDetailsPane/YardsMapDetailsPaneVisit';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { URLUtil } from '@helpers/URL';

export const YardsMapDetailsPane: React.FC = ({ children }) => {
  const { selectedYard, selectedYardDetail, setSelectedYard, retrieveSelectedYard, retrieveYards, isFetchingDetail } =
    useYardsMap();
  const { t } = useTranslation();
  const confirmDeleteModal = useDynamicModal();
  const confirmClearOutModal = useDynamicModal();
  const updateYardModal = useYardCreateOrUpdateModalOpener();

  const hasSelectedYard = !!selectedYard;
  const clearYard = useCallback(() => setSelectedYard(null), [setSelectedYard]);

  useOnYardSaveListener(useCallback(() => retrieveSelectedYard(), [retrieveSelectedYard]));

  const goToYardDetails = useCallback(() => {
    if (selectedYard) {
      window.open(URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: selectedYard.id } }));
    }
  }, [selectedYard]);

  const openUpdateYardModal = useCallback(() => {
    if (selectedYard) {
      updateYardModal.openForUpdate(selectedYard.id);
    }
  }, [selectedYard, updateYardModal]);

  const reloadYards = useCallback(() => {
    retrieveYards();
    retrieveSelectedYard();
  }, [retrieveSelectedYard, retrieveYards]);

  const menuItems = useMemo<Array<MenuItem>>(
    () => [
      { title: t('edit_yard'), icon: Edit, onClick: openUpdateYardModal },
      {
        title: t('clear_out_yard'),
        icon: EmptyYard,
        disabled: !!selectedYardDetail && selectedYardDetail.nbHives === 0,
        onClick: () => confirmClearOutModal.open(),
      },
      { title: t('delete_yard'), icon: Delete, danger: true, onClick: () => confirmDeleteModal.open() },
    ],
    [confirmClearOutModal, confirmDeleteModal, openUpdateYardModal, selectedYardDetail, t]
  );

  useEffect(() => {
    if (hasSelectedYard) {
      Analytics.sendEvent({ event: AnalyticsEventType.YARDS_MAP_LEFT_PANE_VIEW });
    }
  }, [hasSelectedYard]);

  return (
    <>
      <StyledPane contractMapToExpand hideToggle expanded={hasSelectedYard}>
        {children}

        <StyledHeader>
          <Text typography={'Heading3'} weight={'600'}>
            {t('yard_selected')}
          </Text>
          <StyledHeaderCloseButton onClick={clearYard}>
            <Close size={24} />
          </StyledHeaderCloseButton>
        </StyledHeader>

        <StyledPaneScroll>
          <StyledContentTransition content={selectedYardDetail}>
            {(yard) => (
              <StyledYardTitle>
                <Text typography={'Heading2'} weight={'700'} color={'white'}>
                  <YardName name={yard.name} contractName={yard.contractName} suppressTruncation suppressStyling />
                </Text>
              </StyledYardTitle>
            )}
          </StyledContentTransition>

          <StyledContentTransition content={selectedYardDetail}>
            {(yard) => (
              <>
                <StyledSection>
                  <YardsMapDetailsPaneSummary {...yard} />
                </StyledSection>
                <StyledSection>
                  <YardsMapDetailsPaneActions {...yard} />
                </StyledSection>
              </>
            )}
          </StyledContentTransition>

          <StyledContentTransition content={selectedYardDetail}>
            {({ lastActivity }) => (
              <StyledSection $last>
                <YardsMapDetailsPaneVisit activities={lastActivity ? [lastActivity] : []} />
              </StyledSection>
            )}
          </StyledContentTransition>

          {isFetchingDetail && (
            <StyledLoaderWrapper>
              <Loading />
            </StyledLoaderWrapper>
          )}
        </StyledPaneScroll>

        <StyledFooter gap_050>
          <Box fit>
            <MenuTrigger id="yard-more-options-button" grow>
              {t('more_options')}
            </MenuTrigger>
            <Menu items={menuItems} target={'yard-more-options-button'} />
          </Box>

          <Box fit>
            <Button primary grow onClick={goToYardDetails}>
              {t('view_details')}
            </Button>
          </Box>
        </StyledFooter>
      </StyledPane>

      <YardDetailsClearOutAndDeleteModal
        yardId={selectedYard?.id}
        {...confirmDeleteModal.modalProps}
        primaryAction={'delete'}
        onSuccess={reloadYards}
      />
      <YardDetailsClearOutAndDeleteModal
        yardId={selectedYard?.id}
        {...confirmClearOutModal.modalProps}
        primaryAction={'clear-out'}
        onSuccess={reloadYards}
      />
    </>
  );
};
