import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { from } from '@style/mediaQueries';

export const StyledAuthContent = styled(Box).attrs({
  fit: true,
  column: true,
  stretch: true,
  relative: true,
})(
  ({ theme }) => css`
    padding: ${theme.spacing._200} ${theme.spacing._100};

    ${from('tablet')} {
      padding: 40px;
    }
  `
);

export const StyledAuthContentTransition = styled(ContentChangeTransition).attrs({
  fit: true,
  animationType: 'fade',
})(() => css``);

export const StyledAuthContentTransitionContent = styled(Box).attrs({
  fit: true,
  grid: true,
})<{ $isVerticallyCentered: boolean }>(
  ({ $isVerticallyCentered }) => css`
    ${$isVerticallyCentered
      ? css`
          grid-template-rows: 1fr auto 1fr;
        `
      : css`
          grid-template-rows: auto auto 1fr;
        `}

    ${from('tablet')} {
      grid-template-rows: 1fr auto 1fr;
    }

    br {
      display: none;
    }

    & > *:last-child {
      justify-content: center;
    }

    ${from('tablet')} {
      br {
        display: inline-block;
      }

      & > *:last-child {
        justify-content: flex-start;
      }
    }
  `
);
