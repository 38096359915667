import styled, { css } from 'styled-components';

import { Accordion, AccordionContent, AccordionHeader } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { Button } from '@components/common/CTA';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { Text } from '@components/common/Text';
import { from } from '@style/mediaQueries';

export const StyledFilterButton = styled(Button)(
  () => `
  position: relative;
`
);

export const StyledFilterCount = styled(Text).attrs({ typography: 'TooltipSmall', weight: '600' })<{
  $isVisible: boolean;
}>(
  ({ theme, $isVisible }) => `
  position: absolute;
  top: -4px;
  right: -4px;
  
  min-width: 16px;
  border-radius: 8px;
  background-color: ${theme.primitives.colors.yellow02};
  color: ${theme.primitives.colors.grey08};
  
  transition: ${theme.animations.transitionMedium('transform')};
  transform: scale(${$isVisible ? 1 : 0});
`
);

export const StyledFiltersForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  border-radius: ${theme.shape.paperRadius01}px;
`
);

export const StyledFiltersFormHeader = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: ${theme.getZIndexOf('content')};
    background-color: ${theme.colors.surfaceSecondary};

    overflow: hidden auto;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing._200} 0 ${theme.spacing._150} 0;
    border-bottom: ${theme.primitives.colors.grey04} 1px solid;
  `
);

export const StyledFiltersFormContent = styled(Box).attrs({
  column: true,
  stretch: true,
})(
  ({ theme }) => css`
    position: relative;
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const StyledClearFiltersButton = styled(PrimaryButton).attrs({
  flat: true,
})(
  () => `
  padding: 0;
`
);

export const StyledClearFiltersButtonText = styled(Text).attrs({
  typography: 'SmallParagraph',
  weight: '600',
})(
  () => `
  text-transform: capitalize;
  text-decoration: underline;
`
);

export const StyledFilterSection = styled(Accordion).attrs({
  column: true,
  stretch: true,
})(
  ({ theme }) => `
  padding: ${theme.spacing._025} 0;
  
  &:not(:last-child) {
    border-bottom: ${theme.primitives.colors.grey04} 1px solid;
  }
`
);

export const StyledFilterSectionHeader = styled(AccordionHeader)(
  ({ theme }) => css`
    z-index: ${theme.getZIndexOf('content')};
    background-color: ${theme.colors.surfaceSecondary};
    margin: 0;
    padding: ${theme.spacing._100} 0;

    &::before {
      border-bottom: none;
    }
  `
);

export const StyledFilterSectionContent = styled(AccordionContent)(
  ({ theme }) => css`
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: stretch;
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const StyledFilterSectionContentInner = styled(Box)(
  ({ theme }) => css`
    flex-direction: column;
    padding: 0 0 ${theme.spacing._100} 0;
    gap: ${theme.spacing._100};

    ${from('tablet')} {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
    }
  `
);

export const StyledFilterItem = styled(Box).attrs({
  tag: 'label',
})<{ isDisabled: boolean }>(
  ({ theme, isDisabled }) => css`
    align-items: center;
    cursor: ${isDisabled ? 'default' : 'pointer'};

    width: 100%;
    ${from('tablet')} {
      width: calc(50% - ${theme.spacing._050});
    }

    [role='text'] {
      max-width: calc(100% - ${theme.spacing._200});
    }
  `
);
