import { produce } from 'immer';
import * as uuid from 'uuid';

import { SnackbarActionType, SnackbarReducer, SnackbarState } from '@redux/Snackbar/types';

const defaultState: SnackbarState = {
  queue: [],
};

export const snackbarReducer: SnackbarReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case SnackbarActionType.SNACKBAR_SHOW:
        state.queue.unshift({
          id: uuid.v4(),
          ...action.payload,
        });
        break;
    }
  });
};
