import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';

import {
  StyledDollarSign,
  StyledErrorMessage,
  StyledInput,
  StyledLabel,
  StyledTextArea,
  StyledWrapper,
} from './styles';

type ERROR = keyof ValidityState;

// move this to type file
interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  rows?: number;
  type?: React.HTMLInputTypeAttribute | 'integer' | 'money' | 'textarea' | undefined;
  error?: string[] | null;
  errorLabels?: { [K in ERROR]?: string };
  onTextChange: (value: string | number, name: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  suppressResizing?: boolean;
}

export const InputTextBackwardCompatible: React.FC<InputTextProps> = ({
  name,
  id = name,
  label,
  value,
  onTextChange,
  required = false,
  type,
  error: propError,
  errorLabels,
  placeholder,
  disabled,
  rows = 2,
  inputRef,
  suppressResizing,
  ...inputProps
}) => {
  const [error, setError] = useState<ERROR | null>(null);
  const { t } = useTranslation();

  const handleOnInvalid: React.FormEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { validity } = e.currentTarget;
      for (const errorType in validity) {
        if (validity[errorType as ERROR]) {
          setError(errorType as ERROR);
          break;
        }
      }
    },
    [setError]
  );

  const getCleanIntegerValue = useCallback((value: string | null) => {
    // Remove decimal places.
    return (value || '').replace(/\./, '');
  }, []);

  const getCleanMoneyValue = useCallback((value: string | null) => {
    // Remove surplus decimal places.
    return (value || '').replace(/(\.\d{2}).*$/, '$1');
  }, []);

  const handleOnChange: React.FormEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { value, valueAsNumber, validity } = e.currentTarget;
      let newValue = value as string | number;

      if (type === 'number') {
        newValue = valueAsNumber;
      } else if (type === 'integer') {
        newValue = getCleanIntegerValue(newValue as string);
      } else if (type === 'money') {
        newValue = getCleanMoneyValue(newValue as string);
      }

      onTextChange(newValue, name);
      if (error && validity.valid) setError(null);
    },
    [type, onTextChange, name, error, getCleanIntegerValue, getCleanMoneyValue]
  );

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { currentTarget } = e;

      if (currentTarget.value.trim() === '') {
        // edge case where the user enters in a bunch of spaces
        onTextChange('', name);
      } else if (error && currentTarget.validity.valid) {
        setError(null);
      }

      inputProps.onBlur && inputProps.onBlur(e);
    },
    [error, inputProps, name, onTextChange]
  );

  const errors = useMemo(() => {
    const errors: string[] = [];
    const labels: Record<string, string> = {
      valueMissing: t('error_value_missing', { label }),
      typeMismatch: t('error_type_mismatch', { label: label.toLowerCase() }),
      ...(type === 'tel' ? { patternMismatch: t('error_type_phone_number') } : {}),
      ...(errorLabels || {}),
    };

    const errorLabel = error ? labels[error] : null;
    errorLabel && errors.push(errorLabel);

    // Only include error from props in care there are
    // no local errors, to avoid duplication.
    if (!errors.length && propError) {
      errors.push(...propError);
    }

    return errors;
  }, [error, errorLabels, label, propError, t, type]);

  const hasError = errors.length > 0;

  return (
    <div>
      <StyledWrapper column hasError={hasError} disabled={disabled}>
        <StyledLabel isRequired={required} htmlFor={id}>
          {label}
        </StyledLabel>
        <Box paddingTop_025 alignItems={'center'}>
          {type === 'textarea' ? (
            <StyledTextArea //might need to be its own component
              rows={rows}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              name={name}
              value={value}
              id={id}
              onChange={(e) => onTextChange(e.target.value, name)}
              $suppressResizing={suppressResizing}
            />
          ) : (
            <>
              {type === 'money' && !!value && <StyledDollarSign>$</StyledDollarSign>}
              <StyledInput
                {...inputProps}
                ref={inputRef}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                autoComplete={'off'}
                name={name}
                type={['integer', 'money'].includes(type as string) ? 'number' : type}
                step={type === 'money' ? 'any' : inputProps.step}
                value={value}
                id={id}
                aria-describedby={error && error === 'valueMissing' ? `${name}-error-message` : undefined}
                onInvalid={handleOnInvalid}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            </>
          )}
        </Box>
      </StyledWrapper>
      {errors.map((error, index) => (
        <StyledErrorMessage key={index} id={`${name}-error-message`} dangerouslySetInnerHTML={{ __html: error }} />
      ))}
    </div>
  );
};
