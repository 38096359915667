import React, { useCallback } from 'react';
import { CellClickedEvent } from 'ag-grid-community';

import { Box } from '@components/common/Box';
import { DynamicTable } from '@components/common/DynamicTable';
import { DefaultCell } from '@components/common/DynamicTable/DefaultCell';
import { useDynamicTableDataProvider } from '@components/common/DynamicTable/hooks';
import {
  ColDefWithMetadata,
  DynamicTableConfig,
  DynamicTableExternalQueryParams,
  StaticCellRenderParams,
} from '@components/common/DynamicTable/types';
import { HealthScore } from '@components/common/HealthScore/HealthScore';
import { HealthScoreTooltip } from '@components/common/HealthScore/HealthScoreTooltip';
import { useHiveModalOpener } from '@components/hive/HiveModal/hooks';

import { Grading } from './cells/Grading';
import { LastAction } from './cells/LastAction';
import { MenuCell } from './cells/Menu';
import { PreviousYard } from './cells/PreviousYard';
import { SelectCheckbox } from './cells/SelectCheckbox';
import { Status } from './cells/Status';
import { useHiveColumnNameGetter } from './hooks/useHiveColumnNameGetter';
import { HiveRow, HivesListCellRenderParams } from './types';

interface HiveListApiParams extends DynamicTableExternalQueryParams {
  yard_id?: number;
  hive_status?: HiveStatus | 'alive';
  is_lost?: boolean;
}

export const HivesList: React.VFC<{
  apiParams?: HiveListApiParams;
  columnDefinitions: ColDefWithMetadata<any>[];
  config?: DynamicTableConfig;
}> = ({ apiParams, columnDefinitions, config = {} }) => {
  const { openHiveModal } = useHiveModalOpener();
  const columnNameGetter = useHiveColumnNameGetter();

  useDynamicTableDataProvider({
    apiParams,
    config,
    endpoint: 'hiveListDynamicTable',
  });

  /**
   * Function to determine how to render data
   * that isn't just plain text basically
   */
  const renderDynamicCell = useCallback(({ field, data, meta }: HivesListCellRenderParams) => {
    switch (field) {
      case 'hive':
        return <DefaultCell data={data} weight={'600'} />;
      case 'status':
        return <Status {...data} />;
      case 'healthScore':
        const healthScore = { healthScore: data, totalNbHives: 1, usedNbHives: 1 };
        return (
          <HealthScore data={healthScore} naTextProps={{ typography: 'SmallParagraph', dashed: true }}>
            <HealthScoreTooltip data={healthScore} />
          </HealthScore>
        );
      case 'lastFeeding':
      case 'lastTreatment':
        return <LastAction {...data} {...meta} />;
      case 'grading':
        return <Grading {...data} />;
      case 'previousYard':
        return <PreviousYard {...data} />;

      default:
        return null;
    }
  }, []);

  const renderStaticCell = useCallback((params: StaticCellRenderParams) => {
    switch (params.field) {
      case 'select':
        return <SelectCheckbox {...params.gridParamData} />;
      case 'fillTableRight':
        return <Box fit justifyContent={'flex-end'} style={{ position: 'relative' }} />;
      case 'menu':
        return <MenuCell {...params.gridParamData} />;

      default:
        return null;
    }
  }, []);

  const onCellClicked = useCallback(
    (e: CellClickedEvent<HiveRow>) => {
      if (e.column.getId() === 'select') return;
      if (!e.data) return;
      openHiveModal(e.data.meta.id);
    },
    [openHiveModal]
  );

  return (
    <DynamicTable
      columnDefs={columnDefinitions}
      onCellClicked={onCellClicked}
      renderDynamicCell={renderDynamicCell}
      renderStaticCell={renderStaticCell}
      headerComponentParams={{ getColumnDisplayName: columnNameGetter }}
      config={config}
    />
  );
};
