import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Pill = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5H7C5.067 8.5 3.5 10.067 3.5 12C3.5 13.933 5.067 15.5 7 15.5H17C18.933 15.5 20.5 13.933 20.5 12C20.5 10.067 18.933 8.5 17 8.5ZM7 7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17H17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7H7Z"
      />
    </IconSVGView>
  );
};
