import { useEffect, useState } from 'react';

import { Globals, GlobalsProperty } from './';

export function useGlobalProperty(property: GlobalsProperty) {
  const [value, setValue] = useState(Globals[property]);
  useEffect(
    () =>
      Globals.addListener({
        property,
        onChange: (value) => setTimeout(() => setValue(value)),
      }),
    [property]
  );
  return value;
}
