import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { CardContent, CardContentRow } from '@components/common/Card';
import { Text } from '@components/common/Text';
import { moneyFormatter, numberFormatter } from '@helpers/StringTransformers';

import { ContractDetailInstructions } from './ContractDetailInstructions';

export const Pricing: React.FC<{ contract: BeeContract }> = ({ contract }) => {
  const { t } = useTranslation();
  const { nbRequiredHives, totalPrice, costPerHive } = contract;

  if (!nbRequiredHives && !totalPrice && !costPerHive) {
    return (
      <CardContent>
        <Box marginVertical_050>
          <ContractDetailInstructions />
        </Box>
      </CardContent>
    );
  }

  const formattedNbHives = nbRequiredHives ? numberFormatter(nbRequiredHives) : '-';
  const formattedCostPerHive = costPerHive ? moneyFormatter(costPerHive) : '$ -';
  const formattedTotalPrice = totalPrice ? moneyFormatter(totalPrice) : '$ -';

  return (
    <CardContent>
      <CardContentRow>
        <Box justifyContent={'space-between'}>
          <Text typography="SmallParagraph" weight="600">
            {t('Hives')}
          </Text>
          <Text typography="SmallParagraph">{formattedNbHives}</Text>
        </Box>
        <Box justifyContent={'space-between'} marginTop_050>
          <Text typography="SmallParagraph" weight="600">
            {t('cost')}
          </Text>
          <Text typography="SmallParagraph">{t('per_hive_amount', { amount: formattedCostPerHive })}</Text>
        </Box>
      </CardContentRow>
      <CardContentRow>
        <Box justifyContent={'space-between'}>
          <Text typography="Paragraph" weight="700">
            {t('total')}
          </Text>
          <Text typography="Paragraph" weight="700">
            {formattedTotalPrice}
          </Text>
        </Box>
      </CardContentRow>
    </CardContent>
  );
};
