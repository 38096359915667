import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Warning = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.375C10.5 4.61561 11.1156 4 11.875 4C12.6344 4 13.25 4.61561 13.25 5.375V14.625C13.25 15.3844 12.6344 16 11.875 16C11.1156 16 10.5 15.3844 10.5 14.625V5.375Z"
      />
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 19.375C13.25 20.1344 12.6344 20.75 11.875 20.75C11.1156 20.75 10.5 20.1344 10.5 19.375C10.5 18.6156 11.1156 18 11.875 18C12.6344 18 13.25 18.6156 13.25 19.375Z"
      />
    </IconSVGView>
  );
};
