import styled, { Color, ColorGlobal } from 'styled-components';

import { Text } from '@components/common/Text';
import { UnclickableButton } from '@components/common/UnclickableButton';

export const StyledAttentionDot = styled(UnclickableButton)<{ color: ColorGlobal }>(
  ({ theme, color }) => `
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${theme.colors[color]};
  `
);

export const StyledText = styled(Text)`
  width: 169px;
`;
