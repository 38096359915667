import { combineReducers, Reducer } from 'redux';

import { assignContractReducer } from '@redux/AssignContract/reducer';
import { authReducer } from '@redux/Auth/reducer';
import { bulkClearOutYardsReducer } from '@redux/BulkClearOutYards/reducer';
import { bulkDeleteYardsReducer } from '@redux/BulkDeleteYards/reducer';
import { contractReducer } from '@redux/Contract/reducer';
import { contractsMetricsReducer } from '@redux/ContractsMetrics/reducer';
import { cropTypeReducer } from '@redux/CropType/reducer';
import { dataStatusReducer } from '@redux/DataStatus/reducer';
import { accountReducer } from '@redux/deprecated/reducers/accountReducer';
import { actionReducer } from '@redux/deprecated/reducers/actionReducer';
import { batchYardsReducer } from '@redux/deprecated/reducers/batchYardsReducer';
import { beeTrackHiveListReducer } from '@redux/deprecated/reducers/beeTrackHiveListReducer';
import { beeTrackYardListReducer } from '@redux/deprecated/reducers/beeTrackYardListReducer';
import { changePasswordReducer } from '@redux/deprecated/reducers/changePasswordReducer';
import { eventListReducer } from '@redux/deprecated/reducers/eventListReducer';
import { exportReducer } from '@redux/deprecated/reducers/exportReducer';
import { fetchReducer } from '@redux/deprecated/reducers/fetchReducer';
import { forgottenPasswordReducer } from '@redux/deprecated/reducers/forgottenPasswordReducer';
import { groupsReducer } from '@redux/deprecated/reducers/groupsReducer';
import { loginReducer } from '@redux/deprecated/reducers/loginReducer';
import { mapReducer } from '@redux/deprecated/reducers/mapReducer';
import { modalReducer } from '@redux/deprecated/reducers/modalReducer';
import { navBarTitleReducer } from '@redux/deprecated/reducers/navBarTitleReducer';
import { notificationCountReducer } from '@redux/deprecated/reducers/notificationCountReducer';
import { recreatePasswordReducer } from '@redux/deprecated/reducers/recreatePasswordReducer';
import { translationsReducer } from '@redux/deprecated/reducers/translationsReducer';
import { workerReducer } from '@redux/deprecated/reducers/workerReducer';
import { hiveDetailsReducer } from '@redux/HiveDetails/reducer';
import { operationReducer } from '@redux/Operation/reducer';
import { practicesReducer } from '@redux/Practices/reducer';
import { renewContractReducer } from '@redux/RenewContract/reducer';
import { reportsReducer } from '@redux/Reports/reducer';
import { RootActionType } from '@redux/Root/types';
import { snackbarReducer } from '@redux/Snackbar/reducer';
import { timelineReducer } from '@redux/Timeline/reducer';
import { yardClearOrDeleteReducer } from '@redux/YardClearOrDelete/reducer';
import { yardCreateOrUpdateReducer } from '@redux/YardCreateOrUpdate/reducer';
import { yardDetailsReducer } from '@redux/YardDetails/reducer';
import { yardsReducer } from '@redux/Yards/reducer';
import { yardsFiltersReducer } from '@redux/YardsFilters/reducer';
import { yardsGlobalSearchReducer } from '@redux/YardsGlobalSearch/reducer';

export const reducers = combineReducers({
  authReducer,

  yardsReducer,
  operationReducer,
  cropTypeReducer,
  contractReducer,
  contractsMetricsReducer,
  assignContractReducer,
  renewContractReducer,
  yardsFiltersReducer,
  yardsGlobalSearchReducer,
  yardDetailsReducer,
  yardClearOrDeleteReducer,
  yardCreateOrUpdateReducer,
  hiveDetailsReducer,
  timelineReducer,
  reportsReducer,
  snackbarReducer,
  practicesReducer,
  dataStatusReducer,
  bulkClearOutYardsReducer,
  bulkDeleteYardsReducer,

  // Deprecated reducers
  loginReducer,
  navBarTitleReducer,
  modalReducer,
  fetchReducer,
  beeTrackYardListReducer,
  accountReducer,
  changePasswordReducer,
  forgottenPasswordReducer,
  recreatePasswordReducer,
  exportReducer,
  workerReducer,
  actionReducer,
  notificationCountReducer,
  eventListReducer,
  beeTrackHiveListReducer,
  translationsReducer,
  mapReducer,
  batchYardsReducer,
  groupsReducer,
});

export const rootReducer: Reducer = (state, action) => {
  switch (action.type) {
    case RootActionType.ROOT_DISPOSE_STATE:
      state = undefined;
      break;

    case RootActionType.ROOT_DISPOSE_STORAGE:
      localStorage.clear();
      sessionStorage.clear();
      break;
  }
  return reducers(state, action);
};
