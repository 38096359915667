import produce from 'immer';

import { YardDetailsActionType, YardDetailsReducer, YardDetailsState } from '@redux/YardDetails/types';

const defaultState: YardDetailsState = {
  detail: null,
  isFetchingDetail: false,
  hasError: false,
};

export const yardDetailsReducer: YardDetailsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case YardDetailsActionType.YARD_DETAILS_DISPOSE:
        state.detail = null;
        state.isFetchingDetail = false;
        state.hasError = false;
        break;

      case YardDetailsActionType.YARD_DETAILS_FETCH_START:
        state.isFetchingDetail = true;
        break;

      case YardDetailsActionType.YARD_DETAILS_FETCH_FINISH:
        state.isFetchingDetail = false;
        state.hasError = !!action.payload.error;
        state.detail = action.payload.data;
        break;
    }

    return state;
  });
};
