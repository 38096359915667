import { ColDef } from 'ag-grid-community';

import { generateDefaultStaticColDef } from '@components/common/DynamicTable/constants';
import { ColDefWithMetadata } from '@components/common/DynamicTable/types';

export const CONTRACT_HEADER_HEIGHT = 56;
export const CONTRACT_ROW_HEIGHT = 64;

const DEF_CONTRACT_NAME_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'name',
  pinned: 'left',
  minWidth: 192,
  maxWidth: window.innerWidth * 0.7,
  lockPosition: true,
  metadata: {},
};
const DEF_CONTRACT_GROWER_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'grower',
  metadata: {},
};
const DEF_CONTRACT_CONTACT_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'contact',
  metadata: {},
};
const DEF_CONTRACT_REQUIRED_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'hivesRequired',
  metadata: {},
};
const DEF_CONTRACT_ON_MAP_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'hivesOnMap',
  metadata: {},
};
const DEF_CONTRACT_ON_SITE_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'hivesOnSite',
  metadata: {},
};
const DEF_CONTRACT_DROPS_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'drops',
  metadata: {},
};
const DEF_CONTRACT_BLOCKS_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'blocks',
  metadata: {},
};
const DEF_CONTRACT_CROP_TYPES_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'cropTypes',
  metadata: {},
};
const DEF_CONTRACT_CREATED_ON_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'createdOn',
  metadata: {
    activeView: 'default',
    column: {
      views: {
        default: {
          key: 'default',
          sortableKey: 'created_on',
        },
      },
    },
  },
};
const DEF_CONTRACT_ARCHIVED_ON_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'archivedAt',
  metadata: {},
};
const DEF_CONTRACT_BEES_IN_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'beesIn',
  metadata: {},
};
const DEF_CONTRACT_BEES_OUT_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'beesOut',
  metadata: {},
};
const DEF_CONTRACT_PRICE_COLUMN_DEF: ColDefWithMetadata<any> = {
  field: 'price',
  metadata: {},
};
const DEF_CONTRACT_MENU_COLUMN_DEF = generateDefaultStaticColDef({
  field: 'menu',
  headerName: '',
  lockPosition: 'right',
  flex: 1,
  suppressAutoSize: true,
});

export const CONTRACT_COLUMNS = [
  DEF_CONTRACT_NAME_COLUMN_DEF,
  DEF_CONTRACT_GROWER_COLUMN_DEF,
  DEF_CONTRACT_CONTACT_COLUMN_DEF,
  DEF_CONTRACT_REQUIRED_COLUMN_DEF,
  DEF_CONTRACT_ON_MAP_COLUMN_DEF,
  DEF_CONTRACT_ON_SITE_COLUMN_DEF,
  DEF_CONTRACT_DROPS_COLUMN_DEF,
  DEF_CONTRACT_BLOCKS_COLUMN_DEF,
  DEF_CONTRACT_CROP_TYPES_COLUMN_DEF,
  DEF_CONTRACT_CREATED_ON_COLUMN_DEF,
  DEF_CONTRACT_BEES_IN_COLUMN_DEF,
  DEF_CONTRACT_BEES_OUT_COLUMN_DEF,
  DEF_CONTRACT_PRICE_COLUMN_DEF,
  DEF_CONTRACT_MENU_COLUMN_DEF,
];

export const CONTRACT_SORTING: Array<ColDef> = [
  { field: DEF_CONTRACT_CREATED_ON_COLUMN_DEF.field, sort: 'desc' },
  { field: DEF_CONTRACT_NAME_COLUMN_DEF.field, sort: 'asc' },
];

export const CONTRACT_ABSTRACT_COLUMN_KEYS = [DEF_CONTRACT_MENU_COLUMN_DEF.field];
export const CONTRACT_PERMANENT_RIGHT_COLUMN_KEYS = [DEF_CONTRACT_MENU_COLUMN_DEF];

export const CONTRACT_TABLE_CONFIG = {
  defaultSorting: CONTRACT_SORTING,
  abstractColumnKeys: CONTRACT_ABSTRACT_COLUMN_KEYS,
  permanentRightColumns: CONTRACT_PERMANENT_RIGHT_COLUMN_KEYS,
};

export const CONTRACT_ARCHIVED_COLUMNS = [
  DEF_CONTRACT_NAME_COLUMN_DEF,
  DEF_CONTRACT_GROWER_COLUMN_DEF,
  DEF_CONTRACT_CONTACT_COLUMN_DEF,
  DEF_CONTRACT_REQUIRED_COLUMN_DEF,
  DEF_CONTRACT_ON_MAP_COLUMN_DEF,
  DEF_CONTRACT_DROPS_COLUMN_DEF,
  DEF_CONTRACT_BLOCKS_COLUMN_DEF,
  DEF_CONTRACT_CROP_TYPES_COLUMN_DEF,
  DEF_CONTRACT_CREATED_ON_COLUMN_DEF,
  DEF_CONTRACT_BEES_IN_COLUMN_DEF,
  DEF_CONTRACT_BEES_OUT_COLUMN_DEF,
  DEF_CONTRACT_PRICE_COLUMN_DEF,
  { ...DEF_CONTRACT_ARCHIVED_ON_COLUMN_DEF, flex: 1 }, // TODO: Remove this flex prop once the menu column is enabled.
  // DEF_CONTRACT_MENU_COLUMN_DEF, TODO: Re-enable once delete contract is enabled.
];

export const CONTRACT_ARCHIVED_ABSTRACT_COLUMN_KEYS = []; //TODO: Re-enable once delete contract is enabled.
export const CONTRACT_ARCHIVED_PERMANENT_RIGHT_COLUMN_KEYS = []; //TODO: Re-enable once delete contract is enabled.

export const CONTRACT_ARCHIVED_TABLE_CONFIG = {
  defaultSorting: CONTRACT_SORTING,
  abstractColumnKeys: CONTRACT_ARCHIVED_ABSTRACT_COLUMN_KEYS,
  permanentRightColumns: CONTRACT_ARCHIVED_PERMANENT_RIGHT_COLUMN_KEYS,
};
