import React, { useCallback } from 'react';

import { AlertCard } from '@components/common/AlertCard';
import { DataStatusUpdateAlertProps } from '@components/common/DataStatusUpdateAlert/types';
import { useTranslation } from '@hooks/useTranslation';
import { DataStatusType } from '@redux/DataStatus/types';

import { Gutter } from '../Gutter';

import { StyledAlertButton, StyledAlertText, StyledAlertTextWrapper } from './styles';

export const DataStatusUpdateAlert: React.VFC<DataStatusUpdateAlertProps> = ({ dataStatus }) => {
  const t = useTranslation();
  const isOutdated = dataStatus.status?.type === DataStatusType.DATA_OUTDATED;

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  if (!isOutdated) {
    return null;
  }

  return (
    <Gutter>
      <AlertCard storageKey={'timeline-update-warning-storage-state'} dismissible warning>
        <StyledAlertTextWrapper>
          <StyledAlertText typography={'CaptionSmall'}>{t('timeline_update_alert_part1')}</StyledAlertText>
          <StyledAlertButton tertiary suppressPadding onClick={refreshPage}>
            <StyledAlertText typography={'CaptionSmall'} weight={'600'} color={'orange03'}>
              {t('timeline_update_alert_part2')}
            </StyledAlertText>
          </StyledAlertButton>
          <StyledAlertText typography={'CaptionSmall'}>{t('timeline_update_alert_part3')}</StyledAlertText>
        </StyledAlertTextWrapper>
      </AlertCard>
    </Gutter>
  );
};
