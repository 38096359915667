import React, { useCallback, useEffect } from 'react';

import { StyledButton } from './styles';
import { ButtonProps } from './types';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      unstyled,
      primary,
      secondary,
      tertiary,
      danger,
      elevated,
      iconOnly,
      withLeadingIcon,
      withTrailingIcon,
      suppressPaddingHorizontal,
      suppressPaddingVertical,
      suppressPadding,
      withMargin,
      withMarginLeft,
      withMarginRight,
      grow,
      ...props
    },
    ref
  ) => {
    // This will bypass the native form validation,
    // leaving it to our form library to handle.
    if (props.type === 'submit') {
      props['formNoValidate'] = true;
    }

    const validateButtonProps = useCallback(() => {
      if (iconOnly && !props['aria-label']) {
        console.error(`Icon-only buttons must provide an aria-label prop.`);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iconOnly, props['aria-label']]);

    useEffect(() => validateButtonProps(), [validateButtonProps]);

    return (
      <StyledButton
        ref={ref}
        $unstyled={unstyled}
        $primary={primary}
        $secondary={secondary}
        $tertiary={tertiary}
        $danger={danger}
        $elevated={elevated}
        $iconOnly={iconOnly}
        $withLeadingIcon={withLeadingIcon}
        $withTrailingIcon={withTrailingIcon}
        $suppressPaddingHorizontal={suppressPadding || suppressPaddingHorizontal}
        $suppressPaddingVertical={suppressPadding || suppressPaddingVertical}
        $withMarginLeft={withMargin || withMarginLeft}
        $withMarginRight={withMargin || withMarginRight}
        $grow={grow}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
