import React, { useCallback, useMemo, useState } from 'react';

import { useGridApi } from '@components/common/AgGrid';
import { YardsListTooltipsContext, YardsListTooltipsContextValue } from '@components/yard/YardsList/context/context';
import { YardTooltipRow } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { URLUtil } from '@helpers/URL';

const DEF_TOOLTIP_LOADING_DELAY = 120;
const DEF_TOOLTIP_MAX_CACHE_AGE = 60000; // 1 minute.

export const YardsListTooltipsProvider: React.FC = ({ children }) => {
  const [tooltipsByYard, setTooltipsByYard] = useState<Record<number, YardTooltipRow>>({});
  const [isFetchingByYard, setIsFetchingByYard] = useState<Record<number, boolean>>({});

  const { gridApi } = useGridApi();

  const fetchTooltipData = useCallback(
    async (yardId: number) => {
      // Deferring the fetching state. In case the request
      // is cached and is returned too fast, the fetching won't
      // be updated, so avoiding unnecessary loading animations.
      const isFetchingSetTimeout = setTimeout(() => {
        setIsFetchingByYard((curr) => ({ ...curr, [yardId]: true }));
      }, DEF_TOOLTIP_LOADING_DELAY);

      const visibleColumns = GridApiUtil.getVisibleColumnKeys(gridApi?.getColumnDefs());
      const url = URLUtil.buildURL(ENDPOINTS.yardsListWhiteboardTooltips, {
        yard_ids: [String(yardId)],
        columns: visibleColumns.map(CaseAdapter.toSnakeCase),
      });
      const response = await CachedRequest.performRequest(url, (url) => Api.get(url), DEF_TOOLTIP_MAX_CACHE_AGE);

      if (!response.error) {
        const data = CaseAdapter.objectToCamelCase(await response.json(), ['key']).rows[0];
        if (data) {
          setTooltipsByYard((curr) => ({ ...curr, [yardId]: data }));
        }
      }

      clearTimeout(isFetchingSetTimeout);
      setIsFetchingByYard((curr) => ({ ...curr, [yardId]: false }));
    },
    [gridApi]
  );

  const isFetching = useCallback<YardsListTooltipsContextValue['isFetching']>(
    (yardId) => {
      return Boolean(isFetchingByYard[yardId]);
    },
    [isFetchingByYard]
  );

  const getTooltipData = useCallback<YardsListTooltipsContextValue['getTooltipData']>(
    (yardId, column, view) => {
      return tooltipsByYard[yardId]?.data[column.key]?.views[view.key]?.data;
    },
    [tooltipsByYard]
  );

  const context = useMemo<YardsListTooltipsContextValue>(
    () => ({
      isFetching,
      fetchTooltipData,
      getTooltipData,
    }),
    [fetchTooltipData, getTooltipData, isFetching]
  );

  return <YardsListTooltipsContext.Provider value={context}>{children}</YardsListTooltipsContext.Provider>;
};
