import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const HiveLost = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path fill={'transparent'} fillOpacity=".01" d="M0 0h24v24H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.5a5.5 5.5 0 0 1 9-4.239V7a.994.994 0 0 0-.137-.5l-1.75-3a1 1 0 0 0-.863-.5H5.75a1 1 0 0 0-.863.5l-1.75 3A.994.994 0 0 0 3 7v12a2.015 2.015 0 0 0 2 2h8.261A5.477 5.477 0 0 1 12 17.5ZM6.324 5h11.352l1.166 2H5.158l1.166-2ZM10 9a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2h-4Z"
        fill={useColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm3.984.686v.27h.966v-.218c0-.102.013-.189.04-.26a.63.63 0 0 1 .16-.218c.078-.073.19-.163.336-.27.194-.14.356-.274.484-.4.132-.13.229-.268.292-.416.066-.15.098-.328.098-.532 0-.384-.143-.7-.43-.948-.284-.25-.686-.375-1.206-.375-.338 0-.649.042-.933.127a3.762 3.762 0 0 0-.791.328l.397.799c.212-.107.42-.194.623-.26.207-.065.415-.098.624-.098.209 0 .372.042.488.124a.415.415 0 0 1 .175.361.62.62 0 0 1-.066.288.901.901 0 0 1-.2.252c-.09.085-.21.18-.36.288-.169.121-.303.24-.405.357a1.11 1.11 0 0 0-.292.801Zm.07 1.036c-.125.092-.187.252-.187.48 0 .22.062.377.186.474a.726.726 0 0 0 .456.146.7.7 0 0 0 .444-.145c.127-.098.19-.256.19-.474 0-.229-.063-.39-.19-.481a.723.723 0 0 0-.444-.139.75.75 0 0 0-.456.139Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
