import React from 'react';

import { Box } from '@components/common/Box';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { LastInspectionIntervalDot } from '@components/yard/AvgVisitsStatusDot';
import { LastVisitTooltipContent } from '@components/yard/YardsList/cells/LastVisitTooltipContent';
import { YardsListCellProps } from '@components/yard/YardsList/cells/types';
import { YardsListTooltip } from '@components/yard/YardsList/YardsListTooltip';
import { getDate } from '@helpers/deprecated/time';

export type LastVisitProps = YardsListCellProps<{ occurredAt: string; workerNames: Array<string> } | null>;

export const LastVisit: React.FC<LastVisitProps> = ({ data, ...props }) => {
  return data ? (
    <DefaultCellWrapper alignItems={'center'}>
      <DefaultCellWrapper alignItems={'flex-start'}>
        <Box marginRight_050 marginVertical_025>
          <LastInspectionIntervalDot lastInspectionDatetime={data.occurredAt} />
        </Box>
        <DefaultCellWrapper column gap_025 alignItems={'flex-start'}>
          <DefaultCellText dashed>
            {getDate(data.occurredAt)}
            <YardsListTooltip interactive trigger="mouseenter focus" {...props}>
              {(data) => <LastVisitTooltipContent data={data} />}
            </YardsListTooltip>
          </DefaultCellText>
          <DefaultCellText color={'grey06'}>{data.workerNames.join(', ')}</DefaultCellText>
        </DefaultCellWrapper>
      </DefaultCellWrapper>
    </DefaultCellWrapper>
  ) : null;
};
