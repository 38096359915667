import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type PracticesReducer = Reducer<PracticesState, PracticesAction>;

export type PracticesAction =
  | { type: PracticesActionType.PRACTICES_FETCH_START; payload?: { practiceId?: number; actionId?: number } }
  | {
      type: PracticesActionType.PRACTICES_FETCH_FINISH;
      payload: {
        practiceId?: number;
        actionId?: number;
        data: Record<number, BeePracticeDefinition> | null;
        error: ApiResponseError | null;
      };
    };

export interface PracticesState {
  practiceDefinitions: Record<number, BeePracticeDefinition>;
  isFetching: boolean;
  hasFetchError: boolean;

  // Loading status for each practice/action.
  // Used for create/update/disable/enable.
  isFetchingPractice: Record<number, boolean>;
  isFetchingAction: Record<number, boolean>;
}

export enum PracticesActionType {
  PRACTICES_FETCH_START = 'PRACTICES_FETCH_START',
  PRACTICES_FETCH_FINISH = 'PRACTICES_FETCH_FINISH',
}

export interface NameGetter {
  /**
   * This generic type will make the return type to be a string in case
   * defaultValue is given, and a nullable string otherwise.
   * */
  <DV>(id: number | null | undefined, defaultValue?: DV): DV extends string ? string : string | null;
}
