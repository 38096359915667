import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { from } from '@style/mediaQueries';

export const StyledPageHeader = styled(Box)<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    flex-shrink: 0;
    align-items: center;
    padding: 0 ${theme.spacing._100};
    background-color: ${theme.primitives.colors.white};

    height: ${theme.layout.pageHeaderHeight}px;
    transition: ${theme.animations.transitionFast('height', 'opacity', 'border')};
    border-bottom: ${theme.primitives.colors.grey03} 1px solid;

    ${!$isVisible &&
    css`
      height: 0;
      opacity: 0;
      border: none;
    `}

    ${from('tablet')} {
      padding: 0 ${theme.spacing._150};
    }
  `
);

export const StyledPageTitle = styled(Text).attrs({
  typography: 'Heading2',
  htmlTag: 'h1',
  weight: '600',
})<{ $enableTruncate?: boolean }>(
  ({ $enableTruncate }) => css`
    ${$enableTruncate &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  `
);
