import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';

// TOP CONTAINER
export const MetricName = styled.span``;

export const QuestionMarkContainer = styled.div``;

export const TopContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
    order: -1;

    ${MetricName} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      order: -1;
    }
    ${QuestionMarkContainer} {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      order: 1;

      svg {
        &:hover {
          path {
            fill: ${theme.primitives.colors.grey06};
          }
        }
      }
    }
  `
);

// MIDDLE CONTAINER

export type TrendArrowProps = {
  color: string;
  direction: string;
};

export const TrendArrow = styled.svg<TrendArrowProps>(
  ({}) => `
  `
);

export type TrendValueProps = {
  color: string;
};

export const TrendValue = styled.span<TrendValueProps>(
  ({ color }) => `
  color: ${color};

`
);

export const RatioValue = styled.div`
  display: flex;
`;

export const TrendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  ${RatioValue} {
    align-items: center;
    order: -2;
  }

  ${TrendValue} {
    display: flex;
    align-items: center;
    order: 1;
  }
  ${TrendArrow} {
    display: flex;
    align-items: center;
    order: -1;
  }
`;

export type BulletPointProps = {
  color?: string | null;
};

export const BulletPoint = styled.div<BulletPointProps>(
  ({ color }) => `
  background: ${color};
  width: 10px;
  height: 10px;
  border-radius: 1px;
`
);

export const BulletText = styled.span``;

export const BulletValue = styled.span``;

export const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const CategoriesContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    order: -1;
    gap: ${theme.spacing._025};
  `
);

export const CenterContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    order: -1;
    flex-grow: 0;
    gap: ${theme.spacing._025};

    & > span:first-child {
      display: flex;
      order: -1;
      align-self: flex-start;
    }

    ${TrendContainer} {
      display: flex;
      order: 1;
      padding-left: ${theme.spacing._025};
    }
    ${CategoriesContainer} {
      width: 100%;
      ${BulletPointContainer} {
        display: flex;
        order: -1;
        gap: ${theme.spacing._050};
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;

        ${BulletPoint} {
          display: flex;
          flex-direction: row;
          order: -1;
        }
        ${BulletText} {
          display: flex;
          flex-direction: row;
          order: 0;
        }
        ${BulletValue} {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          gap: ${theme.spacing._025};
          order: 1;
        }
      }
    }
  `
);

export const PreviousMetricValue = styled.span``;
export const PreviousValueDelta = styled.span``;

export const BottomContainer = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  width: 100%;

  min-height: 16px;

  order: 1;
  ${PreviousMetricValue} {
    display: flex;
    order: -1;
    align-self: flex-start;
  }
  ${PreviousValueDelta} {
    display: flex;
    order: 1;
    align-self: flex-end;
    padding-left: 3px;
  }
`;

export const MetricCardContainer = styled.button(
  ({ theme }) => css`
    cursor: pointer;
    box-sizing: border-box;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-evenly;
    align-items: stretch;

    padding: ${theme.spacing._025} 12px; // Hard-coded from designs.
    border: 1px solid ${theme.primitives.colors.grey04};
    background: ${theme.primitives.colors.white};
    border-radius: ${theme.shape.paperRadius02}px;

    min-width: 0;
    flex-basis: 231px;
    height: 108px;

    &:hover:not(:disabled) {
      background: ${theme.primitives.colors.grey02};
    }

    h3 {
      line-height: 0.875rem;
    }
  `
);

export const NAText = styled(Text).attrs({
  typography: 'Heading1',
  weight: '600',
  dashed: true,
})(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  &:hover {
    color: ${theme.primitives.colors.grey07};
  }
`
);
