import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '@helpers/Thunk/hooks';
import {
  makeCloseContractModal,
  makeDisposeContractAction,
  makeFetchContractThunk,
  makeOpenContractModal,
} from '@redux/Contract/actions';

export function useContractModalController() {
  const dispatch = useDispatch();
  const contract = useSelector((state) => state.contractReducer.contract);

  const openToCreate = useCallback(() => {
    dispatch(makeDisposeContractAction());
    dispatch(makeOpenContractModal());
  }, [dispatch]);

  const openToEdit = useCallback(
    (contractId: number) => {
      if (contractId !== contract?.id) {
        dispatch(makeDisposeContractAction());
        dispatch(makeFetchContractThunk(contractId));
      }
      dispatch(makeOpenContractModal());
    },
    [dispatch, contract]
  );

  const close = useCallback(() => {
    dispatch(makeCloseContractModal());
  }, [dispatch]);

  return {
    openToCreate,
    openToEdit,
    close,
  };
}
