import React, { useCallback, useEffect, useState } from 'react';

import Slide1 from '@assets/auth/slide-1.png';
import Slide2 from '@assets/auth/slide-2.png';
import Slide3 from '@assets/auth/slide-3.png';
import {
  StyledCarrousel,
  StyledCarrouselBackground,
  StyledCarrouselStepperContainer,
  StyledCarrouselTextContainer,
  StyledNoteBook,
  StyledNoteBookKeyboard,
  StyledNoteBookLid,
  StyledNoteBookScreen,
  StyledNoteBookScreenContent,
  StyledNoteBookScreenFrame,
} from '@components/auth/AuthCarrousel/styles';
import { Box } from '@components/common/Box';
import { StepsProgress } from '@components/common/StepsProgress';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { useTranslation } from '@hooks/useTranslation';

export const AuthCarrousel: React.VFC = () => {
  const t = useTranslation();
  const [autoSlideChange, setAutoSlideChange] = useState(true);
  const [contentList] = useState(() => [
    { title: t('login_slide_1_title'), text: t('login_slide_1_text'), screen: Slide1 },
    { title: t('login_slide_2_title'), text: t('login_slide_2_text'), screen: Slide2 },
    { title: t('login_slide_3_title'), text: t('login_slide_3_text'), screen: Slide3 },
  ]);

  const [contentIndex, setContentIndex] = useState(-1);

  const forwardContent = useCallback(() => {
    setContentIndex((curr) => (curr + 1) % contentList.length);
  }, [contentList.length]);

  const goToStep = useCallback((index: number) => {
    setContentIndex(index);
    setAutoSlideChange(false);
  }, []);

  useEffect(() => {
    if (autoSlideChange) {
      const t1Id = setTimeout(() => forwardContent(), 400);
      const t2Id = setInterval(() => forwardContent(), 10000);
      return () => {
        clearTimeout(t1Id);
        clearInterval(t2Id);
      };
    }
  }, [autoSlideChange, contentList, forwardContent]);

  return (
    <StyledCarrousel>
      <StyledCarrouselBackground />

      <StyledNoteBook>
        <StyledNoteBookScreen>
          <StyledNoteBookScreenFrame>
            <StyledNoteBookScreenContent relative>
              <ContentChangeTransition center animationDuration={'long'} content={contentList[contentIndex]}>
                {({ screen }) => <img width={'100%'} src={screen} alt={'Feature screenshot'} />}
              </ContentChangeTransition>
            </StyledNoteBookScreenContent>
          </StyledNoteBookScreenFrame>
        </StyledNoteBookScreen>
        <StyledNoteBookLid />
        <StyledNoteBookKeyboard />
      </StyledNoteBook>

      <StyledCarrouselTextContainer>
        <Box relative>
          <ContentChangeTransition animationDuration={'long'} content={contentList[contentIndex]}>
            {({ title, text }) => (
              <Box column gap_100>
                <Text typography={'Heading2'} weight={'700'}>
                  {title}
                </Text>
                <Text typography={'SmallParagraph'}>{text}</Text>
              </Box>
            )}
          </ContentChangeTransition>
        </Box>
      </StyledCarrouselTextContainer>

      <StyledCarrouselStepperContainer>
        <StepsProgress
          currentStep={contentIndex}
          stepCount={3}
          defaultStepDotColor={'contentSecondary'}
          onStepClick={goToStep}
        />
      </StyledCarrouselStepperContainer>
    </StyledCarrousel>
  );
};
