import styled, { css } from 'styled-components';

import { PrimaryButton } from '@components/common/Button';
import { Modal } from '@components/common/Modal';
import { PRESETS } from '@components/common/Text/styles';
import { from } from '@style/mediaQueries';

import { CenterContainer } from '../MetricCard/style';

export const ModalCenterContainer = styled(CenterContainer)`
  margin-bottom: ${(props) => props.theme.spacing._150};
`;

export const StyledModal = styled(Modal)(
  () => `
  overflow: hidden;
  `
);

export const ChartWrapper = styled.div(
  ({ theme }) => css`
    overflow: scroll hidden;
    max-width: 100%;
    width: 590px;
    height: 280px;
    border: 1px solid ${theme.primitives.colors.grey03};
    border-radius: ${theme.shape.paperRadius02}px;

    ${from('tablet')} {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  `
);

export const ModalChart = styled.svg(
  ({ theme }) => `
    background-color: ${theme.primitives.colors.white};
    width: 590px;
  `
);

export const Legend = styled.svg(
  ({}) => `
  background-color: none;
  height: 35px;
  `
);

export const DropDownWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  overflow:hidden;
  margin-left: auto;
  background: ${theme.primitives.colors.white};
  border: 1px solid ${theme.primitives.colors.grey04};
  border-radius: 3px; 
  order: 99;

  display: flex;
  position: relative;

  padding: 0px 16px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  `
);
export const DropDownCategories = styled.select(
  ({ theme }) => `
  background: ${theme.primitives.colors.white};
  height: 40px;
  width: 184px;
  outline: none;
  border: 0px;

  font-family: ${theme.font.family};
  font-style: normal;
  font-weight: 400;
  
  appearance: none;
  
  option {
    background: ${theme.primitives.colors.white};
    white-space: pre;
    font-style: normal;
    font-weight: 600;
    color: ${theme.primitives.colors.grey08};
    font-size: ${PRESETS.SmallParagraph.fontSize};
    line-height: ${PRESETS.SmallParagraph.lineHeight};
    font-family: ${theme.font.family};
  }
`
);

export const StyledClose = styled(PrimaryButton).attrs({
  flat: true,
})(
  () => `
  padding: 0;
  width: 24px;
  height: 24px;
  `
);

export const StyledWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: ${theme.shape.paperRadius01}px;
  gap: ${theme.spacing._050};
  padding: ${theme.spacing._150};
`
);
