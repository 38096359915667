import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { EmptyView } from '@components/common/EmptyView';
import { Add } from '@components/common/Icon/presets/Add';
import { Loading } from '@components/common/Loading';
import { Menu } from '@components/common/Menu';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { PageContent, PageContentHeader, PageContentInfoSlim, PageContentTitle } from '@components/common/PageContent';
import { StyledTableHeightSetter } from '@components/common/Table/styles';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import WorkerModal from '@components/deprecated/DynamicModals/WorkerModal';
import { SortTriangle } from '@components/deprecated/Table_deprecated/SortTriangle';
import { Tooltip } from '@components/deprecated/Tooltip_deprecated/Tooltip';
import { InviteWorkerModal } from '@components/operation/InviteWorkerModal';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { getMoment } from '@helpers/deprecated/time';
import { useWorkers } from '@hooks/useWorkers';
import {
  handleSortWorkerList,
  makeFetchWorkerRequestThunk,
  makeOpenWorkerModalAction,
  makeResendInviteWorkerRequestThunk,
} from '@redux/deprecated/actions';

import {
  HiddenOnMobileTd,
  HiddenOnMobileTh,
  StatusSpan,
  StyledLastTd,
  StyledTable,
  StyledTableHeading,
  StyledTr,
} from './styles';
import { OpenWorkerModalArgs } from './types';

export const TabWorkers: React.FC = () => {
  const { t } = useTranslation();
  const { workers, order_by, order_direction } = useWorkers();

  const dispatch = useDispatch();

  const inviteWorkerModal = useDynamicModal();

  const handleSort = (orderBy: Array<string>) => dispatch(handleSortWorkerList(orderBy, order_direction));

  const openWorkerModal = (data: OpenWorkerModalArgs) => {
    dispatch(makeOpenWorkerModalAction(data));
  };

  const sort = useMemo(
    () => ({
      order_by: order_by[0],
      order_direction,
    }),
    [order_by, order_direction]
  );

  useEffect(() => {
    dispatch(makeFetchWorkerRequestThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!workers) return <Loading />;

  return (
    <>
      {workers?.length === 0 ? (
        <EmptyView heading={t('no_workers')} text={t('no_workers_msg')}>
          <Box fullWidth center marginTop_150>
            <Button withLeadingIcon primary onClick={() => inviteWorkerModal.open()}>
              <Add />
              {t('invite_worker')}
            </Button>
          </Box>
        </EmptyView>
      ) : (
        <PageContent>
          <PageContentHeader>
            <Box justifyContent="space-between" alignItems="center">
              <PageContentTitle>{maybePluralize(workers.length, 'worker', t)}</PageContentTitle>
              <Button withLeadingIcon primary onClick={() => inviteWorkerModal.open()}>
                <Add />
                {t('invite_worker')}
              </Button>
            </Box>
            <PageContentInfoSlim>{t('worker_section_description')}</PageContentInfoSlim>
          </PageContentHeader>
          <StyledTableHeightSetter $maxViewHeightOffset={370}>
            <StyledTable>
              <thead>
                <tr>
                  <StyledTableHeading onClick={() => handleSort(['first_name', 'last_name'])}>
                    {t('name')}
                    <SortTriangle id="first_name" sort={sort} />
                  </StyledTableHeading>

                  <HiddenOnMobileTh onClick={() => handleSort(['phone_number', 'first_name', 'last_name'])}>
                    {t('phone')}
                    <SortTriangle id="phone_number" sort={sort} />
                  </HiddenOnMobileTh>

                  <StyledTableHeading onClick={() => handleSort(['status', 'first_name', 'last_name'])}>
                    {t('status')}
                    <SortTriangle id="status" sort={sort} />
                  </StyledTableHeading>

                  <HiddenOnMobileTh onClick={() => handleSort(['last_report', 'first_name', 'last_name'])}>
                    {t('last_report')}
                    <SortTriangle id="last_report" sort={sort} />
                  </HiddenOnMobileTh>

                  <StyledTableHeading />
                </tr>
              </thead>

              <tbody>
                {workers.map((worker) => {
                  const { first_name, last_name, phone_number, status, last_report, id } = worker;

                  const no_data = '-';

                  return (
                    <StyledTr key={id}>
                      <td>{first_name + ' ' + last_name}</td>

                      <HiddenOnMobileTd style={{ letterSpacing: '1px' }}>
                        <NumberFormat
                          value={phone_number}
                          displayType={'text'}
                          format={formatPhoneNumber(phone_number)}
                        />
                      </HiddenOnMobileTd>

                      <td>
                        <span data-for={'status' + id} data-tip>
                          <StatusSpan isActive={status === 'active'}>{status}</StatusSpan>
                        </span>
                        <Tooltip
                          id={'status' + id}
                          disable={'active' === status}
                          tooltipText={t('tooltip_pending_status')}
                        />
                      </td>
                      <HiddenOnMobileTd>{last_report ? getMoment(last_report) : no_data}</HiddenOnMobileTd>
                      <StyledLastTd>
                        <ThreeDotsMenuTrigger id={`menu-trigger-${worker.id}`} />
                        <Menu
                          target={`menu-trigger-${worker.id}`}
                          placement="bottom-start"
                          items={[
                            {
                              title: t('resend_invite'),
                              onClick: () => dispatch(makeResendInviteWorkerRequestThunk(id)),
                            },
                            {
                              title: t('remove'),
                              onClick: () => openWorkerModal({ modalType: 'remove-worker', worker }),
                            },
                          ]}
                        />
                      </StyledLastTd>
                    </StyledTr>
                  );
                })}
              </tbody>
            </StyledTable>
          </StyledTableHeightSetter>
        </PageContent>
      )}

      <WorkerModal />
      <InviteWorkerModal {...inviteWorkerModal.modalProps} />
    </>
  );
};
