import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';

import {
  DEFAULT_GOOGLE_MAPS_API_OPTIONS,
  DEFAULT_GOOGLE_MAPS_RETRY_INTERVAL,
} from '@components/common/Map/apis/GoogleMaps/constants';

export interface GoogleMapsContextValue {
  google: typeof google;
  map: google.maps.Map | null;
  setMap: (map: google.maps.Map | null) => void;
}
export const GoogleMapsContext = React.createContext<GoogleMapsContextValue | null>(null);

const Provider: React.FC<GoogleMapsContextValue> = ({ google, children }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const contextValue = useMemo(() => ({ google, map, setMap }), [google, map]);
  return useMemo(
    () => <GoogleMapsContext.Provider value={contextValue}>{children}</GoogleMapsContext.Provider>,
    [children, google, map, setMap]
  );
};

const GoogleMapsApiWrapper = GoogleApiWrapper(DEFAULT_GOOGLE_MAPS_API_OPTIONS)(Provider);

export const GoogleMapsContextProvider: React.FC<GoogleMapsContextValue> = (props) => {
  const [retryIndex, setRetryIndex] = useState(0);

  useEffect(() => {
    // Reloads google script in case it fails.
    setInterval(() => {
      if (!('google' in window)) {
        Array.from(document.scripts).forEach((script) => {
          if (script.src.includes('maps.googleapis.com/maps/api')) {
            script.remove();
            const nextScript = document.createElement('script');
            nextScript.src = script.src.split('&ts=')[0] + `&ts=${+new Date()}`;
            nextScript.onload = () => {
              setRetryIndex((curr) => curr + 1);
            };
            document.body.appendChild(nextScript);
          }
        });
      }
    }, DEFAULT_GOOGLE_MAPS_RETRY_INTERVAL);
  }, []);

  return (
    <Fragment key={retryIndex}>
      <GoogleMapsApiWrapper {...props} />
    </Fragment>
  );
};
