import { Reducer } from 'redux';

export type RenewContractReducer = Reducer<RenewContractState, RenewContractAction>;

export type RenewContractAction = {
  type:
    | RenewContractActionType.RENEW_CONTRACT_FETCH_START
    | RenewContractActionType.RENEW_CONTRACT_FETCH_SUCCESS
    | RenewContractActionType.RENEW_CONTRACT_FETCH_FAILURE;
};

export interface RenewContractState {
  isFetchingRenewContract: boolean;
}

export enum RenewContractActionType {
  RENEW_CONTRACT_FETCH_START = 'RENEW_CONTRACT_FETCH_START',
  RENEW_CONTRACT_FETCH_SUCCESS = 'RENEW_CONTRACT_FETCH_SUCCESS',
  RENEW_CONTRACT_FETCH_FAILURE = 'RENEW_CONTRACT_FETCH_FAILURE',
}
