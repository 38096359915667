import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { Box } from '@components/common/Box';
import { Card, CardContent } from '@components/common/Card';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import { Toggle } from '@components/form/inputs/Toggle';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makePreferencesPatchMembershipThunk } from '@redux/Preferences/actions';

const API_PATCH_DEBOUNCE = 800;

export const ToggleDisabledCard: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { user, isFetchingUser } = useSelector((state) => state.authReducer);

  const [showDisabledPractices, setShowDisabledPractices] = useState(
    !user?.membershipPreferences.settingsPracticesHideDisabled
  );

  const patchPreferencesToApi = useMemo(
    () =>
      debounce((showDisabledPractices) => {
        dispatch(
          makePreferencesPatchMembershipThunk(
            CaseAdapter.objectToSnakeCase({
              settingsPracticesHideDisabled: !showDisabledPractices,
            })
          ) as any
        );
      }, API_PATCH_DEBOUNCE),
    [dispatch]
  );

  const handleToggle = useCallback(
    (value: boolean) => {
      setShowDisabledPractices(value);
      patchPreferencesToApi(value);
    },
    [patchPreferencesToApi]
  );

  return (
    <Card marginBottom_150 relative>
      <CardContent>
        <Box alignItems={'center'} justifyContent={'space-between'}>
          <Text typography={'SmallParagraph'} weight={'600'}>
            {t('show_disabled_categories')}
          </Text>

          <Box center gap_050>
            <Toggle value={showDisabledPractices} name={'toggle-show-disabled-practices'} onChange={handleToggle} />
            <Text typography={'SmallParagraph'} weight={'600'}>
              {showDisabledPractices ? t('on') : t('off')}
            </Text>
          </Box>
        </Box>
      </CardContent>

      <LoadingSkeleton visible={isFetchingUser} absolutelyFitParent backgroundColor={'overlayWhiteBackground'}>
        <LoadingSkeletonRectangle width={'100%'} height={'100%'} borderRadius_150 backgroundColor={'grey02'} />
      </LoadingSkeleton>
    </Card>
  );
};
