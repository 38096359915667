import React, { useCallback } from 'react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { useNonPropagatedOnClick } from '@components/common/CTA/hooks';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { DefaultEmptyCell } from '@components/common/DynamicTable/DefaultCell/DefaultEmptyCell';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { useTimelineByActivityRequestModalOpener } from '@components/timeline/TimelineByActivityRequest/hooks';
import { useDateUtil } from '@helpers/Date/hooks';
import { getMoment } from '@helpers/deprecated/time';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

import { ActionCell } from '../types';

export const LastAction: React.VFC<ActionCell> = ({ id, actionId, occurredAt }) => {
  const t = useTranslation();
  const dateUtil = useDateUtil();
  const { getActionName, getPracticeByAction } = usePractices();

  const { openTimelineByActivityRequestModal } = useTimelineByActivityRequestModalOpener();

  const openVisitModalButtonProps = useNonPropagatedOnClick<HTMLButtonElement>(
    useCallback(() => {
      if (!id || !occurredAt) {
        return;
      }
      openTimelineByActivityRequestModal({
        hiveId: id,
        date: dateUtil.getFormattedISODate(occurredAt).slice(0, 10),
        alertTypes: ['new_inspection'],
        practiceCategoryId: getPracticeByAction(actionId)?.id,
      });
    }, [actionId, dateUtil, getPracticeByAction, id, occurredAt, openTimelineByActivityRequestModal])
  );

  if (!id || !actionId) {
    return <DefaultEmptyCell />;
  }

  return (
    <DefaultCellWrapper block>
      <Box gap_025 column>
        <Button tertiary suppressPadding {...openVisitModalButtonProps}>
          <DefaultCellText>{getMoment(occurredAt)}</DefaultCellText>

          <Tooltip>
            <Text typography={'CaptionSmall'}>{t('yard_visit_click_to_open_tooltip')}</Text>
          </Tooltip>
        </Button>
        <DefaultCellText typography="TooltipSmall" color={'contentTertiary'}>
          {getActionName(actionId, '-')}
        </DefaultCellText>
      </Box>
    </DefaultCellWrapper>
  );
};
