import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';

export const StyledForm = styled.form(
  () => `
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`
);

export const StyledFormHead = styled.div(
  ({ theme }) => `
  margin: ${theme.spacing._100};
`
);

export const StyledFormContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow: visible auto;
  padding: ${theme.spacing._150} ${theme.spacing._100} ${theme.spacing._100} ${theme.spacing._100};
  border-top: 1px solid ${theme.primitives.colors.grey03};
`
);

export const StyledFormFooter = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing._100};
  background: ${theme.primitives.colors.white};
`
);

export const StyledPoiTypeButtons = styled(Box)(
  ({ theme }) => `
  flex-wrap: wrap;
  margin: -${theme.spacing._025};
`
);

export const StyledPoiTypeButton = styled(PrimaryButton)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: calc(33% - ${theme.spacing._050});
  height: 64px;
  padding: 0;
  margin: ${theme.spacing._025};
  border: 1px solid ${theme.primitives.colors.grey03};
  border-radius: ${theme.shape.paperRadius01}px;
  text-transform: none;
  letter-spacing: normal;
  
  svg {
    margin-bottom: ${theme.spacing._025};
  }
`
);
