import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/common/CTA';
import { HelpAdd } from '@components/common/Icon/presets/HelpAdd';
import { PollinationAdd } from '@components/common/Icon/presets/PollinationAdd';
import { YardAdd } from '@components/common/Icon/presets/YardAdd';
import { MapSearchBox, MapToolbar } from '@components/common/Map';
import { useMap } from '@components/common/Map/hooks';
import { Address } from '@components/common/Map/types';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';

import { StyledMapToolbarContent, StyledMapToolbarIconButton, StyledMapToolbarIconButtonGroup } from './styles';

export const PolliMapToolbar: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { instance: mapInstance } = useMap();
  const {
    isIdle,
    isStatic,
    isReadonly,
    isLoading,
    isManaging,
    isManagingDrops,
    isManagingBlocks,
    isManagingPois,
    isManagingAnElement,
    isManagingElements,
    isDraggingElements,
    clearAnyElementSelection,
    mode,
    setMode,
    submit,
    cancel,
  } = usePolliMap();

  const isManagingAnItem = isManagingAnElement || isDraggingElements;
  const shouldDisableToolbarButtons = isIdle || isManagingAnItem || isLoading;
  const shouldDisableCancelAndDone = isManagingAnItem || isLoading;
  const canSearch = isManaging || isManagingElements;

  const buildModeSetter = useCallback(
    (nextMode: PolliMapMode) => () => {
      if (nextMode === mode) {
        setMode(PolliMapMode.MANAGING);
      } else {
        setMode(nextMode);
      }

      if (nextMode === PolliMapMode.MANAGING_BLOCKS) {
        clearAnyElementSelection();
      }
    },
    [mode, setMode, clearAnyElementSelection]
  );

  const adjustMapBounds = useCallback(
    ({ path }: Address) => {
      mapInstance?.fitCoordinates(path);
    },
    [mapInstance]
  );

  const toolbarButtons = useMemo(
    () => [
      {
        Icon: PollinationAdd,
        tooltip: t('pollination_create_blocks'),
        onClick: buildModeSetter(PolliMapMode.MANAGING_BLOCKS),
        active: isManagingBlocks,
        disabled: shouldDisableToolbarButtons,
      },
      {
        Icon: YardAdd,
        tooltip: t('pollination_create_drops'),
        onClick: buildModeSetter(PolliMapMode.MANAGING_DROPS),
        active: isManagingDrops,
        disabled: shouldDisableToolbarButtons,
      },
      {
        Icon: HelpAdd,
        tooltip: t('pollination_create_poi'),
        onClick: buildModeSetter(PolliMapMode.MANAGING_POIS),
        active: isManagingPois,
        disabled: shouldDisableToolbarButtons,
      },
    ],
    [buildModeSetter, isManagingBlocks, isManagingDrops, isManagingPois, shouldDisableToolbarButtons, t]
  );

  if (isStatic || isReadonly) {
    return <>{children}</>;
  }

  return (
    <MapToolbar expanded={!isIdle}>
      {children}

      <StyledMapToolbarContent>
        <MapSearchBox
          onAddressFound={adjustMapBounds}
          disabled={!canSearch}
          placeholder={t('pollination_map_search_placeholder')}
        />
      </StyledMapToolbarContent>

      <StyledMapToolbarContent>
        <StyledMapToolbarIconButtonGroup>
          {toolbarButtons.map(({ Icon, tooltip, ...buttonProps }, key) => (
            <StyledMapToolbarIconButton key={key} {...buttonProps}>
              <Icon size={24} />
              <Tooltip placement={'bottom'}>
                <Text typography={'CaptionSmall'}>{tooltip}</Text>
              </Tooltip>
            </StyledMapToolbarIconButton>
          ))}
        </StyledMapToolbarIconButtonGroup>
      </StyledMapToolbarContent>

      <StyledMapToolbarContent>
        <Button tertiary onClick={cancel} disabled={shouldDisableCancelAndDone}>
          {t('cancel')}
        </Button>
        <Button onClick={submit} primary withMargin disabled={shouldDisableCancelAndDone}>
          {t('done')}
        </Button>
      </StyledMapToolbarContent>
    </MapToolbar>
  );
};
