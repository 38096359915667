import React from 'react';

import { TextProps } from '@components/common/Text/types';
import { getDate } from '@helpers/deprecated/time';

import { DefaultCellText } from './DefaultCellText';
import { DefaultCellWrapper } from './DefaultCellWrapper';
import { DefaultEmptyCell } from './DefaultEmptyCell';

export const DefaultCell: React.VFC<{ data: string | number | null } & Partial<TextProps>> = ({
  data,
  ...textProps
}) => {
  if (data === null) return <DefaultEmptyCell />;
  let value = String(data);
  const isDate = /^\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}/.test(String(data));

  if (isDate) {
    value = getDate(data);
  }

  return (
    <DefaultCellWrapper>
      <DefaultCellText {...textProps}>{value}</DefaultCellText>
    </DefaultCellWrapper>
  );
};
