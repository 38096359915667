import { hexToCSSFilter } from 'hex-to-css-filter';
import { Color, DefaultTheme, Globals, Primitives } from 'styled-components';

import theme_deprecated from './theme_deprecated';
// rename to primitive
// rename colors to colors
// add in spacing etc
// delete storybook cache files

const primitives: Primitives = {
  colors: {
    white: '#FFFFFF',
    background: '#F0F3F6',
    black: '#000000',
    grey01: '#F7F7F7',
    grey02: '#F2F2F2',
    grey03: '#E6E6E6',
    grey04: '#E1E3E3',
    grey05: '#D4D4D4',
    grey06: '#878E8D',
    grey07: '#555A65',
    grey08: '#252629',
    orange01: '#F7BD13',
    orange02: '#D8A348',
    orange03: '#78500D',
    green01: '#DBF0C4',
    green02: '#A4D96C',
    yellow01: '#FEF7EA',
    yellow02: '#FFCF80',
    red01: '#F8EBE8',
    red02: '#E29A93',
    red03: '#D9766D',
    blue01: '#E5EFFA',
    blue02: '#0079FF',
    focus01: '#BFD4F9',
    focus02: '#71A3FB',
    chartPurple: '#D7BBF3',
    chartBlue: '#B4D2F1',
    turquoise: '#12ABA2',
    overlayBackground: 'rgba(0, 0, 0, 0.4)',
    overlayWhiteBackground: 'rgba(255, 255, 255, 0.5)',
  },
  rem: {
    _0125: 0.125,
    _025: 0.25,
    _050: 0.5,
    _0625: 0.625,
    _075: 0.75,
    _100: 1.0,
    _125: 1.25,
    _150: 1.5,
    _200: 2.0,
    _250: 2.5,
    _300: 3.0,
    _350: 3.5,
    _600: 6.0,
  },
  px: {
    _0125: 2,
    _025: 4,
    _050: 8,
    _0625: 10,
    _075: 12,
    _100: 16,
    _125: 20,
    _150: 24,
    _200: 32,
    _250: 40,
    _300: 48,
    _350: 56,
    _600: 96,
  },
};

const globals: Globals = {
  colors: {
    surfaceDefault: primitives.colors.background,
    surfaceDisabled: primitives.colors.grey05,
    surfacePrimary: primitives.colors.grey08,
    surfacePrimaryHover: primitives.colors.grey07,
    surfacePrimaryReverse: primitives.colors.white,
    surfaceSecondary: primitives.colors.white,
    surfaceSecondaryHover: primitives.colors.grey01,
    surfaceTertiary: primitives.colors.grey06,
    surfaceTertiaryHover: primitives.colors.grey05,
    surfaceSubtle: primitives.colors.grey02,
    surfaceAccent: primitives.colors.orange02,
    surfaceWarning: primitives.colors.orange01,
    surfaceWarningLight: primitives.colors.yellow01,
    surfaceDanger: primitives.colors.red03,
    surfaceDangerHover: primitives.colors.red02,
    surfaceDangerLight: primitives.colors.red01,
    surfacePositive: primitives.colors.green02,
    surfacePositiveLight: primitives.colors.green01,
    surfaceInfo: primitives.colors.blue02,
    surfaceInfoLight: primitives.colors.blue01,
    surfaceComponentDisabled: primitives.colors.grey01,

    contentDisabled: primitives.colors.grey06,
    contentPrimary: primitives.colors.grey08,
    contentPrimaryHover: primitives.colors.grey07,
    contentPrimaryReverse: primitives.colors.white,
    contentSecondary: primitives.colors.grey07,
    contentTertiary: primitives.colors.grey06,
    contentLight: primitives.colors.grey05,
    contentHighlight: primitives.colors.orange02,
    contentHighlightBright: primitives.colors.orange01,
    contentPositive: primitives.colors.green02,
    contentDanger: primitives.colors.red03,
    contentInfo: primitives.colors.blue02,

    borderPrimary: primitives.colors.grey08,
    borderPrimaryHover: primitives.colors.grey07,
    borderSecondary: primitives.colors.grey04,
    borderSecondaryStateHover: primitives.colors.grey05,
    borderPrimaryReverse: primitives.colors.white,
    borderTertiary: primitives.colors.grey06,
    borderDisabled: primitives.colors.grey05,
    borderDanger: primitives.colors.red03,
    borderDangerHover: primitives.colors.red02,
    borderPositive: primitives.colors.green02,
    borderWarning: primitives.colors.yellow02,
    borderInfo: primitives.colors.blue02,
    borderFocus: primitives.colors.focus02,
    borderFocusLight: primitives.colors.focus01,
  },
};

/**
 * Default theme object.
 * It must be in sync with the last specification from ZeroHeight.
 * https://zeroheight.com/33a5fe70c/p/2551b5-colors
 * */
const index: DefaultTheme = {
  // Old theme specification, spread here for backward compatibility.
  ...theme_deprecated,

  primitives,

  colors: {
    /**
     *
     * @todo change filter & alpha functions to only accept globals
     * when all primitives have been removed from the site
     */
    ...globals.colors,
    getPrimitiveOrGlobalColor(color: Color) {
      return (globals.colors as any)[color] ?? (primitives.colors as any)[color];
    },
    filter(color: Color) {
      const themeColor = this.getPrimitiveOrGlobalColor(color);
      return hexToCSSFilter(themeColor || color).filter;
    },
    alpha(color: Color, alpha: number) {
      const themeColor = this.getPrimitiveOrGlobalColor(color);

      alpha = Math.max(0, Math.min(255, Math.round(alpha * 255)));
      return themeColor + alpha.toString(16).padStart(2, '0');
    },
  },

  spacing: {
    _0: `0px`,
    _0125: `${primitives.px._0125}px`,
    _025: `${primitives.px._025}px`,
    _050: `${primitives.px._050}px`,
    _0625: `${primitives.px._0625}px`,
    _075: `${primitives.px._075}px`,
    _100: `${primitives.px._100}px`,
    _125: `${primitives.px._125}px`,
    _150: `${primitives.px._150}px`,
    _200: `${primitives.px._200}px`,
    _250: `${primitives.px._250}px`,
    _300: `${primitives.px._300}px`,
    _350: `${primitives.px._350}px`,
    _600: `${primitives.px._600}px`,
  },

  // border radius shouldn't grow as font size grows, so we use px
  borderRadius: {
    _025: `${primitives.px._025}px`,
    _050: `${primitives.px._050}px`,
    _100: `${primitives.px._100}px`,
  },
  sizeIcon: {
    _050: `${primitives.rem._050}rem`,
    _100: `${primitives.rem._100}rem`,
    _150: `${primitives.rem._150}rem`,
  },
  lineHeight: {
    _100: `${primitives.rem._100}rem`,
    _150: `${primitives.rem._150}rem`,
  },

  shadows: {
    boxShadow01: '0 1px 3px rgba(0,0,0,0.05),  0 1px 3px rgba(0,0,0,0.10)',
    boxShadow02: '0 3px 6px rgba(0,0,0,0.05),  0 3px 6px rgba(0,0,0,0.10)',
    boxShadow03: '0 10px 20px rgba(0,0,0,0.075),  0 6px 6px rgba(0,0,0,0.075)',
    boxShadow04: '0 14px 28px rgba(0,0,0,0.10),  0 10px 10px rgba(0,0,0,0.15)',
  },

  font: {
    family: `
      Open Sans,
      -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto, Helvetica,
        Arial, sans-serif
    `,

    heading3FontSize: '0.88rem', // 14px
  },

  shape: {
    paperRadius01: 4,
    paperRadius02: 8,
    paperRadius03: 16,
  },

  navigation: {
    headerHeight: 72,
    headerHeightMobile: 56,
    tabBarHeight: 56,
    tabBarHeightMobile: 56,
  },

  modals: {
    width: {
      base: 540,
      trends: 638,
    },
  },

  layout: {
    siteHeaderHeight: primitives.px._350,
    pageHeaderHeight: 72,
    tabBarHeight: 56,
    sideNavCollapsedWidth: '72px',
    sideNavExpandedWidth: '256px',
    sideNavExpandedWidthMobile: '324px',
  },

  zIndexes: [
    'base', // 0
    'content', // 1
    'lowLevelIndex', // to do: update this to a different name
    'table',
    'tableBody',
    'tableCell',
    'tableHeaderLeft',
    'tableHeaderTop',
    'tableHeaderTopLeft',
    'tableShadow',
    'tableOverlay',
    'tabs',
    'stickyAlert',
    'siteHeader', // will i regret switching loading, sidenav, and siteheader :shrug:
    'sidenavOverlay',
    'sidenav',
    'highLevelIndex', // to do: update this to a different name
    'modal',
    'modalContent',
    'modalFooter',
    'modalHeader',
    'modalMobileHeader',
    'loading',
    'tooltip',
    'snackbar',
  ],
  getZIndexOf(item) {
    return this.zIndexes.indexOf(item);
  },

  animations: {
    durationFast: 150,
    durationMedium: 250,
    durationLong: 350,

    easing: 'cubic-bezier(0.75, 0.35, 0.35, 0.75)',

    transitionFast(...props) {
      return props.map((p) => `${p} ${this.durationFast}ms ${this.easing}`).join(', ');
    },

    transitionMedium(...props) {
      return props.map((p) => `${p} ${this.durationMedium}ms ${this.easing}`).join(', ');
    },

    transitionLong(...props) {
      return props.map((p) => `${p} ${this.durationLong}ms ${this.easing}`).join(', ');
    },
  },
};

export default index;
