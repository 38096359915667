import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { parseRawResponseToJson, parseRejectedToJson } from '@helpers/deprecated/parseFetchResponse';
import { now } from '@helpers/deprecated/time';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

import { makeUnauthorizedFetchFailureAction } from './index';

/**
 *
 * @returns {{type: string}}
 *
 */
export const makeTranslationsRequestStartAction = () => ({
  type: types.TRANSLATIONS_START,
});

/**
 *
 * @returns {{type: string}}
 * @returns {{translations: array}}
 *
 */
export const makeFetchTranslationsRequestSuccessAction = (translations) => ({
  type: types.FETCH_TRANSLATIONS_SUCCESS,
  translations,
});

export const updateSpanishTranslationsRequestSuccessAction = (practice) => ({
  type: types.UPDATE_SPANISH_TRANSLATIONS_SUCCESS,
  practice,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeTranslationsRequestFailureAction = () => ({
  type: types.TRANSLATIONS_FAILURE,
});

/**
 *
 * "thunk" translations creator
 * @see https://en.wikipedia.org/wiki/Thunk
 *
 * @param translationsName
 * @param translationsPhone
 * @returns {Function}
 */

export const makeFetchTranslationsRequestThunk = () => {
  return (dispatch, getState) => {
    const fetchState = getState().translationsReducer;
    const { isFetching, fetchedAt } = fetchState;
    if (isFetching || now - fetchedAt < APP.longerCacheForSeconds * 1000) return;

    dispatch(makeTranslationsRequestStartAction());

    fetch(ENDPOINTS.translations, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'default', // *default, no-store, reload, no-cache, force-cache
      headers: {
        Accept: 'application/json',
      },
    })
      .then(parseRawResponseToJson, parseRejectedToJson)
      .then((jsonObj = {}) => {
        if ('error' in jsonObj) {
          if ('unauthorized' === jsonObj.error) {
            dispatch(makeUnauthorizedFetchFailureAction());
            return;
          } else {
            dispatch(makeTranslationsRequestFailureAction());
            dispatch(makeShowSnackbarAction(jsonObj.error, { status: jsonObj.status }));
            return;
          }
        } else if ('results' in jsonObj) {
          dispatch(makeFetchTranslationsRequestSuccessAction(jsonObj.results));
        }
      });
  };
};
