import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { InputAutocomplete, Option } from '@components/form/inputs/InputAutocomplete';
import { OptionText } from '@components/form/inputs/InputAutocomplete/OptionText';
import { InputAutocompleteProps } from '@components/form/inputs/InputAutocomplete/types';
import { Sorting } from '@helpers/Sorting';
import { useContractSummaries } from '@hooks/useContract';

export const InputContractAutocomplete = ({ disabled, ...autoCompleteProps }: InputAutocompleteProps) => {
  const { t } = useTranslation();
  const contractSummaries = useContractSummaries();
  const noContracts = !contractSummaries?.length;

  const contractSummariesSorted = useMemo(
    () => Sorting.sortAlphanumerically(contractSummaries ?? [], 'name'),
    [contractSummaries]
  );

  if (!contractSummaries) {
    return (
      <LoadingSkeleton fit>
        <LoadingSkeletonRectangle width={'100%'} />
      </LoadingSkeleton>
    );
  }

  return (
    <InputAutocomplete
      label={t('contract_name_input_label')}
      placeholder={t('contract_name_input_placeholder')}
      disabled={disabled || noContracts}
      {...autoCompleteProps}
    >
      {contractSummariesSorted.map((contract) => (
        <Option key={contract.id} value={contract.id} title={contract.name}>
          <OptionText>{contract.name}</OptionText>
        </Option>
      ))}
    </InputAutocomplete>
  );
};
