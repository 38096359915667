import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';

export const StyledGaugeSVG = styled.svg<{ $zeroed: boolean }>(
  ({ theme, $zeroed }) => css`
    transform: rotate(90deg);

    .circle-back {
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dashoffset: -10;
      stroke-dasharray: 80, 100;
    }

    .circle-front {
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dashoffset: -10;
      stroke-dasharray: 0, 100;

      ${$zeroed
        ? css`
            opacity: 0;
            transition: stroke-dasharray 1.5s ${theme.animations.easing}, stroke 0.8s ${theme.animations.easing},
              opacity 0.3s 1.2s linear;
          `
        : css`
            opacity: 1;
            transition: stroke-dasharray 1.5s ${theme.animations.easing}, stroke 0.8s ${theme.animations.easing},
              opacity 0.3s 0s linear;
          `}
    }
  `
);

export const StyledGaugeText = styled(Text)`
  position: absolute;
`;
