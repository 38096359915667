import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useTheme } from 'styled-components';

import {
  DEFAULT_GOOGLE_MAPS_DEBOUNCE,
  DEFAULT_GOOGLE_MAPS_IDLE_POLYGON,
  DEFAULT_GOOGLE_MAPS_MIN_POLYGON_LENGTH,
  DEFAULT_GOOGLE_MAPS_SELECTED_POLYGON,
} from '@components/common/Map/apis/GoogleMaps/constants';
import { useGoogle, useGoogleMap } from '@components/common/Map/apis/GoogleMaps/hooks';
import {
  StyledPolygonTooltip,
  StyledPolygonTooltipBackground,
  StyledPolygonTooltipContent,
} from '@components/common/Map/apis/GoogleMaps/styles';
import { useMap } from '@components/common/Map/hooks';
import { Coordinate, Path, PolygonProps } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { useColor } from '@style/theme/hooks';

export function PolygonRenderer({
  path,
  paths,
  fillColor: propFillColor,
  strokeColor: propStrokeColor,
  fillOpacity,
  strokeOpacity,
  strokeWeight,
  editable,
  selected,
  cancelable,
  disabled,
  onClick,
  onPathChange,
  onDrawingComplete,
  onDrawingCancel,
  onDraggingChange,
}: PolygonProps) {
  const { t } = useTranslation();
  const { instance } = useMap();
  const google = useGoogle();
  const map = useGoogleMap();
  const theme = useTheme();
  const pathKey = useMemo(() => MapUtil.getPathKey(path), [path]);
  const pathsKey = useMemo(() => (paths ?? []).map(MapUtil.getPathKey).join('-'), [paths]);

  const lastOnchangePathKey = useRef(pathKey);

  const fillColor = useColor(propFillColor, DEFAULT_GOOGLE_MAPS_IDLE_POLYGON.fillColor);
  const strokeColor = useColor(propStrokeColor, DEFAULT_GOOGLE_MAPS_IDLE_POLYGON.strokeColor);
  const polygonStyleProps = useMemo(
    () =>
      ({
        fillColor,
        strokeColor,
        fillOpacity: fillOpacity ?? DEFAULT_GOOGLE_MAPS_IDLE_POLYGON.fillOpacity,
        strokeOpacity: strokeOpacity ?? DEFAULT_GOOGLE_MAPS_IDLE_POLYGON.strokeOpacity,
        strokeWeight: strokeWeight ?? DEFAULT_GOOGLE_MAPS_IDLE_POLYGON.strokeWeight,
      } as google.maps.PolygonOptions),
    [fillColor, fillOpacity, strokeColor, strokeOpacity, strokeWeight]
  );

  const [polygon] = useState<google.maps.Polygon>(
    () => new google.maps.Polygon({ ...DEFAULT_GOOGLE_MAPS_IDLE_POLYGON, ...polygonStyleProps, map })
  );
  const tooltipRef = useRef<HTMLDivElement>(null);
  const drawingManager = useRef<google.maps.drawing.DrawingManager | null>(null);

  const pathToLatLngArray = useCallback(
    (path: Path) => new google.maps.MVCArray(path.map((coord) => new google.maps.LatLng(coord))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const latLngArrayToPath = useCallback((googlePath: google.maps.MVCArray) => {
    const path: Array<Coordinate> = [];
    googlePath.forEach((coord) => path.push(coord.toJSON()));
    return path;
  }, []);

  const updatePolygonPath = useCallback(
    (nextPath: Path) => {
      const nextPathKey = MapUtil.getPathKey(nextPath);
      if (nextPathKey !== lastOnchangePathKey.current) {
        lastOnchangePathKey.current = nextPathKey;
        onPathChange && onPathChange(nextPath);
      }

      const currentPath = latLngArrayToPath(polygon.getPath());
      const currentPathKey = MapUtil.getPathKey(currentPath);

      if (nextPathKey !== currentPathKey) {
        polygon.setPath(pathToLatLngArray(nextPath));
      }
    },
    [latLngArrayToPath, polygon, pathToLatLngArray, onPathChange]
  );

  const updatePolygonPaths = useCallback(
    (nextPaths: Array<Path>) => {
      const currentPaths = polygon.getPaths().getArray().map(latLngArrayToPath);
      const currentPathsKey = currentPaths.map(MapUtil.getPathKey).join('-');
      const pathsKey = nextPaths.map(MapUtil.getPathKey).join('-');
      if (pathsKey !== currentPathsKey) {
        polygon.setPaths(nextPaths.map(pathToLatLngArray));
      }
    },
    [latLngArrayToPath, polygon, pathToLatLngArray]
  );

  const updatePolygonPathFromProps = useCallback(() => {
    path && updatePolygonPath(path);
    paths && updatePolygonPaths(paths);
  }, [path, paths, updatePolygonPath, updatePolygonPaths]);

  const stopDrawing = useCallback(() => {
    drawingManager.current?.setMap(null);
  }, []);

  const handleDrawingComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      const latLngArray = polygon.getPath();

      if (latLngArray.getLength() < DEFAULT_GOOGLE_MAPS_MIN_POLYGON_LENGTH) {
        if (cancelable) {
          onDrawingCancel && onDrawingCancel();
        } else {
          stopDrawing();
          startDrawing();
        }
      } else {
        const nextPath = latLngArrayToPath(polygon.getPath());
        updatePolygonPath(nextPath);
        onDrawingComplete && onDrawingComplete(nextPath);
        stopDrawing();
      }

      polygon.setMap(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelable, onDrawingCancel, stopDrawing, latLngArrayToPath, updatePolygonPath, onDrawingComplete]
  );

  const startDrawing = useCallback(() => {
    drawingManager.current = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        ...DEFAULT_GOOGLE_MAPS_SELECTED_POLYGON,
        ...polygonStyleProps,
        editable: true,
        draggable: true,
      },
      map,
    });
    const listener = drawingManager.current?.addListener('polygoncomplete', handleDrawingComplete);
    return () => {
      listener?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDrawingComplete, map]);

  const handleVertexHover = useCallback(
    (event: google.maps.PolyMouseEvent) => {
      const vertexCount = polygon.getPath().getLength();
      const canDeleteVertexes = vertexCount > DEFAULT_GOOGLE_MAPS_MIN_POLYGON_LENGTH;

      if (canDeleteVertexes && typeof event.vertex === 'number') {
        const vertexCoords = polygon.getPath().getAt(event.vertex).toJSON();
        const vertexPixel = instance?.coordinateToPixel(vertexCoords);
        if (vertexPixel && tooltipRef.current) {
          const mapDiv = map.getDiv();
          const mapRect = mapDiv.getBoundingClientRect();

          tooltipRef.current.style.top = `${vertexPixel.y + mapRect.y}px`;
          tooltipRef.current.style.left = `${vertexPixel.x + mapRect.x - theme.primitives.px._100}px`;
          tooltipRef.current.style.opacity = '1';
        }
      }
    },
    [instance, polygon, theme.primitives.px._100, map]
  );

  const handleVertexOut = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.style.opacity = '0';
    }
  }, []);

  const handleVertexRightClick = useCallback(
    (event: google.maps.PolyMouseEvent) => {
      if (event.vertex !== undefined) {
        if (polygon.getPath().getLength() > DEFAULT_GOOGLE_MAPS_MIN_POLYGON_LENGTH) {
          polygon.getPath().removeAt(event.vertex);
          updatePolygonPath(latLngArrayToPath(polygon.getPath()));
          handleVertexOut();
        }
      }
    },
    [handleVertexOut, latLngArrayToPath, polygon, updatePolygonPath]
  );

  useEffect(() => {
    if (!path && !paths) {
      return startDrawing();
    }
    updatePolygonPathFromProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey, pathsKey]);

  useEffect(() => {
    const addListener = (target: google.maps.Polygon | google.maps.MVCArray, eventName: string, handler: any) => {
      const bind = target.addListener(
        eventName,
        debounce((event) => {
          if (!(map as any).skipInteractions) {
            handler(event);
          }
        }, DEFAULT_GOOGLE_MAPS_DEBOUNCE)
      );
      return () => bind.remove();
    };

    const unbindListenerFunctions = [
      addListener(polygon, 'click', () => onClick && onClick()),
      addListener(polygon, 'rightclick', handleVertexRightClick),
      addListener(polygon, 'dragstart', () => onDraggingChange && onDraggingChange(true)),
      addListener(polygon, 'dragend', () => onDraggingChange && onDraggingChange(false)),
      addListener(polygon, 'mouseover', handleVertexHover),
      addListener(polygon, 'mouseout', handleVertexOut),
      addListener(polygon.getPath(), 'set_at', () => updatePolygonPath(latLngArrayToPath(polygon.getPath()))),
      addListener(polygon.getPath(), 'insert_at', () => updatePolygonPath(latLngArrayToPath(polygon.getPath()))),
      addListener(polygon.getPath(), 'remove_at', () => updatePolygonPath(latLngArrayToPath(polygon.getPath()))),
    ];

    return () => unbindListenerFunctions.forEach((unbindListenerFunction) => unbindListenerFunction());
  }, [
    map,
    disabled,
    editable,
    selected,
    handleVertexHover,
    handleVertexOut,
    handleVertexRightClick,
    latLngArrayToPath,
    onClick,
    onDraggingChange,
    polygon,
    updatePolygonPath,
  ]);

  useEffect(() => {
    polygon.setEditable(!!editable);
    polygon.setDraggable(!!editable);
    polygon.setOptions({
      ...(selected ? DEFAULT_GOOGLE_MAPS_SELECTED_POLYGON : DEFAULT_GOOGLE_MAPS_IDLE_POLYGON),
      ...polygonStyleProps,
      clickable: !disabled,
    });
  }, [disabled, editable, polygon, polygonStyleProps, selected]);

  useEffect(
    () => () => {
      polygon.setMap(null);
      stopDrawing();
    },
    [polygon, stopDrawing]
  );

  return createPortal(
    <StyledPolygonTooltip ref={tooltipRef}>
      <StyledPolygonTooltipBackground />
      <StyledPolygonTooltipContent>{t('polygon_right_click_to_delete')}</StyledPolygonTooltipContent>
    </StyledPolygonTooltip>,
    document.body
  );
}
