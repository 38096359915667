import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'moment-timezone';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Card, CardContent, CardContentRow } from '@components/common/Card';
import { Button } from '@components/common/CTA';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { PageContentSlim } from '@components/common/PageContent';
import { StyledHeading3, StyledParagraphSmall } from '@components/deprecated/Elements/TypographyElements';
import { Dropdown } from '@components/form/inputs/Dropdown';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { Sorting } from '@helpers/Sorting';
import { useEnsuredUser } from '@redux/Auth/hooks';
import { makeOpenModalAction, makeOpenPasswordModalAction } from '@redux/deprecated/actions';
import { makePreferencesPatchUserThunk } from '@redux/Preferences/actions';
import { ProfileChangeNameModal } from '@scenes/admin/Profile/ProfileChangeNameModal';

import { UnderlineSpan } from './styles';

export const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateUtil = useDateUtil();
  const hasSearched = useRef(false);

  const user = useEnsuredUser();
  const isFetchingUser = useSelector((state) => state.authReducer.isFetchingUser);

  const openContactModal = () => dispatch(makeOpenModalAction({ modalType: 'contact' }));
  const handleChangePassword = () => dispatch(makeOpenPasswordModalAction({ modalType: 'change-pass' }));

  const changeNameModal = useDynamicModal();

  const { email, fullName, phoneNumber } = user;
  const { timezone } = user.userPreferences;

  const [timezoneState, setTimeZoneState] = useState({ timezone: timezone });

  const timezoneDropdownOptions = useMemo(() => {
    const options = dateUtil.timezones.map(({ name, city, countryCode, offsetReadable }) => ({
      text: `${city}, ${countryCode} (GMT${offsetReadable})`,
      value: name,
    }));
    const sortedOptions = Sorting.sort(options, 'text');

    const userHasKnownTimezone = !!timezone;
    const userHasUnknownOption = options.every(({ value }) => value !== timezone);

    if (userHasKnownTimezone && userHasUnknownOption) {
      const userSetTimezoneOffset = moment.tz(timezone)?.format('Z');
      const userSetTimezone = {
        text: userSetTimezoneOffset ? `${timezone} (GMT${userSetTimezoneOffset})` : timezone,
        value: timezone,
      };
      sortedOptions.unshift(userSetTimezone);
    }
    return sortedOptions;
  }, [dateUtil.timezones, timezone]);

  const updateTimezone = useCallback(
    (newTimezone: string | null) => {
      if (newTimezone === null) {
        return;
      }
      const previousTimezone = timezone;
      const newState = { ...timezoneState, timezone: newTimezone };
      setTimeZoneState(newState);
      dispatch(makePreferencesPatchUserThunk(newState));

      Analytics.sendEvent({
        event: AnalyticsEventType.SETTINGS_TIMEZONE_CHANGE,
        eventData: { timezone: newTimezone, previous_timezone: previousTimezone, has_searched: hasSearched.current },
      });
    },
    [dispatch, timezoneState, timezone]
  );

  const onTimezoneSearchChange = useCallback(() => (hasSearched.current = true), []);

  const overview = useMemo(
    () => [
      {
        label: t('name'),
        data: fullName,
      },
      {
        label: t('email'),
        data: email || '',
      },
      {
        label: t('phone'),
        data: phoneNumber || (
          <p>
            {t('add_phone')}
            <UnderlineSpan onClick={openContactModal}>{t('contact_us')}</UnderlineSpan>.
          </p>
        ),
      },
      {
        label: t('time-zone'),
        data: (
          <Dropdown
            id={'timezone'}
            name={'timezone'}
            options={timezoneDropdownOptions}
            defaultValue={timezone}
            onChange={updateTimezone}
            onSearchChange={onTimezoneSearchChange}
            searchable
          />
        ),
      },
      {
        label: t('password'),
        data: (
          <Box justifyContent="space-between" alignItems="center">
            <span
              style={{
                display: 'flex',
                whiteSpace: 'nowrap',
              }}
            >
              •••••••••••••{' '}
            </span>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, user]
  );

  return (
    <PageContentSlim>
      <Card id="about">
        <CardContent>
          {overview.map((item) => (
            <CardContentRow key={item.label}>
              <Row align="center">
                <Col xs={12} sm={3}>
                  <StyledHeading3>{t(item.label)}</StyledHeading3>
                </Col>
                <Col xs={8} sm={7}>
                  {item.label === t('time-zone') ? item.data : <StyledParagraphSmall>{item.data}</StyledParagraphSmall>}
                </Col>
                <Col xs={4} sm={2}>
                  <Box fit justifyContent={'flex-end'}>
                    {item.label === t('name') && (
                      <Button tertiary type="button" disabled={isFetchingUser} onClick={() => changeNameModal.open()}>
                        {t('edit')}
                      </Button>
                    )}
                    {item.label === t('password') && (
                      <Button tertiary type="button" onClick={handleChangePassword}>
                        {t('change')}
                      </Button>
                    )}
                  </Box>
                </Col>
              </Row>
            </CardContentRow>
          ))}
        </CardContent>
      </Card>

      {!!user && <ProfileChangeNameModal {...changeNameModal.modalProps} />}
    </PageContentSlim>
  );
};
