import { Reducer } from 'redux';

export type AuthReducer = Reducer<AuthState, AuthAction>;

export type AuthAction =
  | { type: AuthActionType.AUTH_FETCH_USER_START }
  | { type: AuthActionType.AUTH_FETCH_USER_FINISH; payload: { user: BeeUser | null } }
  | { type: AuthActionType.AUTH_FETCH_SIGN_IN_START }
  | { type: AuthActionType.AUTH_FETCH_SIGN_IN_FINISH; payload: { user: BeeUser | null } }
  | { type: AuthActionType.AUTH_FETCH_SIGN_OUT_START }
  | { type: AuthActionType.AUTH_FETCH_SIGN_OUT_FINISH };

export type AuthState = {
  user: BeeUser | null;
  isFetchingUser: boolean;
  isFetchingSignIn: boolean;
  isFetchingSignOut: boolean;
};

export enum AuthActionType {
  AUTH_FETCH_USER_START = 'AUTH_FETCH_USER_START',
  AUTH_FETCH_USER_FINISH = 'AUTH_FETCH_USER_FINISH',

  AUTH_FETCH_SIGN_IN_START = 'AUTH_FETCH_SIGN_IN_START',
  AUTH_FETCH_SIGN_IN_FINISH = 'AUTH_FETCH_SIGN_IN_FINISH',

  AUTH_FETCH_SIGN_OUT_START = 'AUTH_FETCH_SIGN_OUT_START',
  AUTH_FETCH_SIGN_OUT_FINISH = 'AUTH_FETCH_SIGN_OUT_FINISH',
}
