import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Page404 } from '@components/common/Page404';
import GroupModal from '@components/deprecated/DynamicModals/GroupModal';
import UpdateYardModal from '@components/deprecated/DynamicModals/UpdateYardModal';
import StaticModal from '@components/deprecated/StaticModals/StaticModal';
import { HiveModal } from '@components/hive/HiveModal';
import { LayoutProvider } from '@components/layout/context/provider';
import { SideNav } from '@components/layout/SideNav';
import { SiteHeader } from '@components/layout/SiteHeader';
import { TimelineByActivityRequestModal } from '@components/timeline/TimelineByActivityRequest/TimelineByActivityRequestModal';
import { YardCreateOrUpdateModal } from '@components/yard/YardCreateOrUpdateModal';
import { YardsManagement } from '@components/yard/YardsManagement';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsPerformanceMetricEventType } from '@helpers/Analytics/types';
import { useGlobalProperty } from '@helpers/Globals/hooks';
import { QueryParams } from '@helpers/QueryParams';
import { URLUtil } from '@helpers/URL';
import Alerts from '@scenes/admin/Alerts/Alerts';
import Groups from '@scenes/admin/Groups/Groups';
import { LostHives } from '@scenes/admin/LostHives';
import { Operation } from '@scenes/admin/Operation';
import { PolliContract, PolliContractsListPage, PolliContractsPrintable } from '@scenes/admin/Pollination';
import { ProfilePage } from '@scenes/admin/Profile/ProfilePage';
import { Reports } from '@scenes/admin/Reports';
import { SettingsPage } from '@scenes/admin/Settings/SettingsPage';
import { Whiteboard } from '@scenes/admin/Whiteboard';
import { YardDetails } from '@scenes/admin/YardDetails';

const StyledMainContent = styled(Box).attrs({ column: true })(
  () => css`
    overflow: hidden;
    flex: 1 0 0;
  `
);

export const Admin: React.VFC = () => {
  const history = useHistory();

  const hasUser = useSelector((state) => !!state.authReducer.user);
  const hasChosenOperation = useGlobalProperty('operationId');

  useEffect(() => {
    if (history.location.pathname === URLUtil.buildPagePath(APP.routes.whiteboardYardsList)) {
      // Start point for the whiteboard metrics and table.
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD, {
        stackSize: 2,
      });
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_METRICS);
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_TABLE);
    } else if (history.location.pathname === URLUtil.buildPagePath(APP.routes.whiteboardActivities)) {
      // Start point for the activities page.
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES);
    }
    return () => {
      Analytics.cancelPerformanceMetric(
        AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD,
        AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_METRICS,
        AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_TABLE,
        AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES
      );
    };
  }, [history.location.pathname]);

  if (!hasUser || !hasChosenOperation) {
    return null;
  }

  return (
    <>
      <Box fit stretch>
        <LayoutProvider>
          <SideNav />
          <StyledMainContent>
            <SiteHeader />
            <Switch>
              {[...APP.mainRedirects, ...APP.backwardCompatibilityRedirects].map(({ from, to }, index) => (
                <Route
                  key={index}
                  exact
                  path={from}
                  render={({ match }) => {
                    QueryParams.popLatestPathname();
                    return <Redirect to={URLUtil.buildPagePath(to, { pathParams: match.params })} />;
                  }}
                />
              ))}

              <Route
                path={[APP.routes.whiteboardYardsList, APP.routes.whiteboardYardsMap, APP.routes.whiteboardActivities]}
                component={Whiteboard}
                exact
              />
              <Route path={APP.routes.whiteboardYardsMapManagement} component={YardsManagement} exact />
              <Route
                path={[
                  APP.routes.whiteboardYardsDetailDashboard,
                  APP.routes.whiteboardYardsDetailHives,
                  APP.routes.whiteboardYardsDetailDeadouts,
                  APP.routes.whiteboardYardsDetailActivities,
                ]}
                component={YardDetails}
              />
              <Route path={APP.routes.reports} component={Reports} />
              <Route
                path={APP.routes.pollinationContractsDetailsPDF}
                render={(props: any) => <PolliContractsPrintable {...props} />}
              />
              <Route
                path={[
                  APP.routes.pollinationContractsDetailsInformation,
                  APP.routes.pollinationContractsDetailsDrops,
                  APP.routes.pollinationContractsDetailsPollinationMap,
                ]}
                render={(props: any) => <PolliContract {...props} />}
              />
              <Route
                path={[APP.routes.pollinationContractsOngoingList, APP.routes.pollinationContractsArchivedList]}
                render={(props: any) => <PolliContractsListPage {...props} />}
              />

              <Route path={APP.routes.settings} render={(props: any) => <SettingsPage {...props} />} />
              <Route path={APP.routes.profile} render={(props: any) => <ProfilePage {...props} />} />

              <Route path={APP.routes.operation} render={(props: any) => <Operation {...props} />} />
              <Route path={APP.routes.alerts} render={(props: any) => <Alerts {...props} />} />
              <Route path={APP.routes.groups} render={(props: any) => <Groups {...props} />} />
              <Route path={APP.routes.lostHives} render={(props: any) => <LostHives {...props} />} />

              <Route component={Page404} />
            </Switch>
          </StyledMainContent>
        </LayoutProvider>
      </Box>

      <StaticModal />

      <UpdateYardModal />
      <YardCreateOrUpdateModal />

      <GroupModal />
      <TimelineByActivityRequestModal />
      <HiveModal />
    </>
  );
};
