import 'moment-timezone';
import CityZones from 'city-timezones';
import moment from 'moment';

export interface Timezone {
  name: string;
  city: string;
  country: string;
  countryCode: string;
  offset: number;
  offsetReadable: string;
}

export class DateUtil {
  public readonly timezone: string;

  constructor(options: { timezone?: string }) {
    this.timezone = options.timezone ?? 'UTC';
  }

  public isDateToday(date: string | Date): boolean {
    const dateObj = this.getLocalizedDate(date);
    return dateObj.isSame(new Date(), 'day');
  }

  public isDateYesterday(date: string | Date): boolean {
    const dateObj = this.getLocalizedDate(date);
    const yesterday = moment(new Date()).subtract(1, 'day');
    return dateObj.isSame(yesterday, 'day');
  }

  public getFormattedDate(date: string | Date, format: string): string {
    return this.getLocalizedDate(date).format(format);
  }

  public getFormattedISODate(date: string | Date): string {
    return this.getLocalizedDate(date).format();
  }

  public getLocalizedDate(date: string | Date): moment.Moment {
    return moment(date).tz(this.timezone);
  }

  public get timezoneReadable(): string {
    return this.timezone.replace(/_/g, ' ');
  }

  public get timezoneOffset(): number {
    return moment().tz(this.timezone).utcOffset();
  }

  public get browserTimezone(): string {
    return moment.tz.guess(true);
  }

  public get browserTimezoneReadable(): string {
    return moment.tz.guess(true).replace(/_/g, ' ');
  }

  public get browserTimezoneOffset(): number {
    return moment().tz(this.browserTimezone).utcOffset();
  }

  public isMismatchingBrowserTimezone(): boolean {
    return this.timezoneOffset !== this.browserTimezoneOffset;
  }

  public get timezones(): Array<Timezone> {
    const timezonesMap: Record<string, Timezone> = {};
    const timezoneRef = +new Date();
    CityZones.cityMapping
      // Make sure the timezone is known by moment.
      .filter(({ timezone }) => !!timezone && !!moment.tz.zone(timezone))
      .forEach(({ country, iso2, city, city_ascii, timezone }) => {
        const tz = {
          name: timezone,
          city,
          country,
          countryCode: iso2,
          offset: moment.tz.zone(timezone)?.utcOffset(timezoneRef) ?? 0,
          offsetReadable: moment.tz(timezone).format('Z'),
        };
        const isTZAlreadyAdded = timezone in timezonesMap;
        const isTZPrincipal = this.getNormalizedCityName(timezone).includes(this.getNormalizedCityName(city_ascii));
        (!isTZAlreadyAdded || isTZPrincipal) && (timezonesMap[timezone] = tz);
      });
    return Object.values(timezonesMap);
  }

  public getNormalizedCityName(cityName: string) {
    // Removes accents, underscores, and converts it to lowercase.
    // E.g.
    //     "São_Paulo" > "sao paulo"
    return cityName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/_/g, ' ');
  }

  public static getTimeDeltaInMilliseconds(options: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  }) {
    return (
      (options.milliseconds ?? 0) +
      (options.seconds ?? 0) * 1000 +
      (options.minutes ?? 0) * 1000 * 60 +
      (options.hours ?? 0) * 1000 * 60 * 60
    );
  }

  public static getElapsedTimeInMilliseconds(since: number) {
    return +new Date() - since;
  }
}
