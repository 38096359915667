import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { StyledAuthActions, StyledFormWrapper } from '@components/auth/AuthForms/styles';
import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Hide } from '@components/common/Icon/presets/Hide';
import { Show } from '@components/common/Icon/presets/Show';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputText } from '@components/form/inputs/InputText';
import APP from '@config/constants';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeRecreatePasswordRequestThunk } from '@redux/deprecated/actions';

export const CreatePasswordForm: React.VFC = () => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(null);
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: useFormDefaultValues({ password: '', passwordConfirmation: '', uid, token }),
    resolver: useFormSchemaResolver((schema) => ({
      password: schema.string().min(12, t('create_password_error')).required(),
      passwordConfirmation: schema
        .string()
        .required()
        .test({
          test: (passwordConfirmation, { parent: { password } }) => {
            return passwordConfirmation === password;
          },
          message: t('create_password_confirmation_error'),
        }),
    })).resolver,
  });

  const attemptToSendEmail = useCallback(async () => {
    const response = await dispatch(makeRecreatePasswordRequestThunk(form.getValues() as any) as any);
    if (response.errors) {
      let error: any = t('snack_default_msg');

      /** Errors related to an invalid new password. */
      if ('new_password2' in response.errors) {
        error = response.errors.new_password2[0];
      }

      /** The token might be expired. */
      if ('token' in response.errors) {
        error = (
          <span>
            {t('create_password_invalid_token_error_1')}{' '}
            <NavRouterLink to={APP.routes.passwordForgotten} suppressPadding>
              {t('create_password_invalid_token_error_2')}
            </NavRouterLink>{' '}
            {t('create_password_invalid_token_error_3')}
          </span>
        );
      }

      setError(error);
    } else {
      history.replace(APP.routes.login);
    }
  }, [dispatch, form, history, t]);

  useEffect(() => {
    if (!uid || !token) {
      history.replace(APP.routes.login);
    }
  }, [history, token, uid]);

  return (
    <>
      <StyledFormWrapper justifyContent={'center'}>
        <Form relative form={form} onValidSubmit={attemptToSendEmail} gap_150 column>
          <ContentChangeTransition content={error}>
            {(error) => <AlertCard error>{error}</AlertCard>}
          </ContentChangeTransition>

          <Box gap_050 column>
            <Text typography={'Heading1'} weight={'700'}>
              {t('create_password_title')}
            </Text>
            <Text typography={'SmallParagraph'} dangerouslySetInnerHTML={{ __html: t('create_password_message') }} />
          </Box>

          <InputText
            name={'password'}
            label={t('create_password_label')}
            hintText={t('create_password_hint')}
            placeholder={'**********'}
            type={showPassword ? 'text' : 'password'}
            outerRightContent={
              <Box marginLeft_100>
                <Button type={'button'} suppressPadding onClick={() => setShowPassword((curr) => !curr)}>
                  {showPassword ? <Show size={24} /> : <Hide size={24} />}
                </Button>
              </Box>
            }
          />
          <InputText
            name={'passwordConfirmation'}
            label={t('create_password_confirmation_label')}
            placeholder={'**********'}
            type={showPasswordConfirmation ? 'text' : 'password'}
            outerRightContent={
              <Box marginLeft_100>
                <Button type={'button'} suppressPadding onClick={() => setShowPasswordConfirmation((curr) => !curr)}>
                  {showPasswordConfirmation ? <Show size={24} /> : <Hide size={24} />}
                </Button>
              </Box>
            }
          />

          <StyledAuthActions gap_150>
            <Button primary type={'submit'}>
              {t('create')}
            </Button>
          </StyledAuthActions>
        </Form>
      </StyledFormWrapper>
    </>
  );
};
