import React from 'react';

import { StyledAnchor } from './styles';
import { AnchorProps } from './types';

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      children,
      primary,
      secondary,
      tertiary,
      elevated,
      withLeadingIcon,
      withTrailingIcon,
      suppressPaddingHorizontal,
      suppressPaddingVertical,
      suppressPadding,
      withMargin,
      withMarginLeft,
      withMarginRight,
      grow,
      ...props
    },
    ref
  ) => {
    return (
      <StyledAnchor
        ref={ref}
        $primary={primary}
        $secondary={secondary}
        $tertiary={tertiary}
        $elevated={elevated}
        $withLeadingIcon={withLeadingIcon}
        $withTrailingIcon={withTrailingIcon}
        $suppressPaddingHorizontal={suppressPadding || suppressPaddingHorizontal}
        $suppressPaddingVertical={suppressPadding || suppressPaddingVertical}
        $withMarginLeft={withMargin || withMarginLeft}
        $withMarginRight={withMargin || withMarginRight}
        $grow={grow}
        {...props}
      >
        {children}
      </StyledAnchor>
    );
  }
);

Anchor.displayName = 'Anchor';
