export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOCAL_STORAGE_LOGIN_SUCCESS = 'LOCAL_STORAGE_LOGIN_SUCCESS';
export const REMOVE_USER_AND_PERMISSIONS = 'REMOVE_USER_AND_PERMISSIONS';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const FORGOTTEN_PASSWORD_START = 'FORGOTTEN_PASSWORD_START';
export const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILURE = 'FORGOTTEN_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const RECREATE_PASSWORD_START = 'RECREATE_PASSWORD_START';
export const RECREATE_PASSWORD_SUCCESS = 'RECREATE_PASSWORD_SUCCESS';
export const RECREATE_PASSWORD_FAILURE = 'RECREATE_PASSWORD_FAILURE';

export const ACCOUNT_START = 'ACCOUNT_START';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';
export const SET_USER_SUCCESS_ACTION = 'SET_USER_SUCCESS_ACTION';

export const FETCH_HHT_YARD_LIST_START = 'FETCH_HHT_YARD_LIST_START';
export const FETCH_HHT_YARD_LIST_SUCCESS = 'FETCH_HHT_YARD_LIST_SUCCESS';
export const FETCH_HHT_YARD_LIST_FAILURE = 'FETCH_HHT_YARD_LIST_FAILURE';

export const FETCH_HHT_YARD_LIST_SUMMARY_START = 'FETCH_HHT_YARD_LIST_SUMMARY_START';
export const FETCH_HHT_YARD_LIST_SUMMARY_SUCCESS = 'FETCH_HHT_YARD_LIST_SUMMARY_SUCCESS';
export const FETCH_HHT_YARD_LIST_SUMMARY_FAILURE = 'FETCH_HHT_YARD_LIST_SUMMARY_FAILURE';

export const FETCH_HHT_YARD_MAP_START = 'FETCH_HHT_YARD_MAP_START';
export const FETCH_HHT_YARD_MAP_SUCCESS = 'FETCH_HHT_YARD_MAP_SUCCESS';
export const FETCH_HHT_YARD_MAP_FAILURE = 'FETCH_HHT_YARD_MAP_FAILURE';

export const UPDATE_YARD_ACTION_START = 'UPDATE_YARD_ACTION_START';
export const UPDATE_YARD_ACTION_SUCCESS = 'UPDATE_YARD_ACTION_SUCCESS';
export const UPDATE_YARD_ACTION_FAILURE = 'UPDATE_YARD_ACTION_FAILURE';

export const DELETE_YARD_ACTION_START = 'DELETE_YARD_ACTION_START';
export const DELETE_YARD_ACTION_SUCCESS = 'DELETE_YARD_ACTION_SUCCESS';
export const DELETE_YARD_ACTION_FAILURE = 'DELETE_YARD_ACTION_FAILURE';

export const BATCH_UPDATE_YARDS_ACTION_START = 'BATCH_UPDATE_YARDS_ACTION_START';
export const BATCH_UPDATE_YARDS_ACTION_SUCCESS = 'BATCH_UPDATE_YARDS_ACTION_SUCCESS';
export const BATCH_UPDATE_YARDS_ACTION_FAILURE = 'BATCH_UPDATE_YARDS_ACTION_FAILURE';

export const SET_UPDATE_MULTIPLE_YARDS = 'SET_UPDATE_MULTIPLE_YARDS';
export const SET_REMOVE_MULTIPLE_YARDS = 'SET_REMOVE_MULTIPLE_YARDS';
export const SET_ADD_MULTIPLE_YARDS = 'SET_ADD_MULTIPLE_YARDS';
export const RESET_YARD_DATA = 'RESET_YARD_DATA';
export const RESET_YARD_MODAL_DATA = 'RESET_YARD_MODAL_DATA';

export const FETCH_HHT_YARD_START = 'FETCH_HHT_YARD_FAILURE';
export const FETCH_HHT_YARD_SUCCESS = 'FETCH_HHT_YARD_SUCCESS';
export const FETCH_HHT_YARD_FAILURE = 'FETCH_HHT_YARD_FAILURE';

export const WORKER_START = 'WORKER_START';
export const FETCH_WORKER_SUCCESS = 'FETCH_WORKER_SUCCESS';
export const ADD_WORKER_SUCCESS = 'ADD_WORKER_SUCCESS';
export const ADD_WORKER_FAILURE = 'ADD_WORKER_FAILURE';
export const UPDATE_WORKER_SUCCESS = 'UPDATE_WORKER_SUCCESS';
export const UPDATE_WORKER_FAILURE = 'UPDATE_WORKER_FAILURE';
export const DEACTIVATE_WORKER_SUCCESS = 'DEACTIVATE_WORKER_SUCCESS';
export const WORKER_FAILURE = 'WORKER_FAILURE';
export const SORT_WORKER_LIST = 'SORT_WORKER_LIST';

export const ACTION_START = 'ACTION_START';
export const FETCH_ACTION_SUCCESS = 'FETCH_ACTION_SUCCESS';
export const ADD_ACTION_SUCCESS = 'ADD_ACTION_SUCCESS';
export const UPDATE_ACTION_SUCCESS = 'UPDATE_ACTION_SUCCESS';
export const ACTION_FAILURE = 'ACTION_FAILURE';

export const OPEN_MAIN_MENU = 'OPEN_MAIN_MENU';
export const CLOSE_MAIN_MENU = 'CLOSE_MAIN_MENU';

export const NAV_BAR_TITLE_CHANGE = 'NAV_BAR_TITLE_CHANGE';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_PASSWORD_MODAL = 'OPEN_PASSWORD_MODAL';
export const CLOSE_PASSWORD_MODAL = 'CLOSE_PASSWORD_MODAL';

export const OPEN_WORKER_MODAL = 'OPEN_WORKER_MODAL';
export const CLOSE_WORKER_MODAL = 'CLOSE_WORKER_MODAL';

export const OPEN_ACTION_MODAL = 'OPEN_ACTION_MODAL';
export const CLOSE_ACTION_MODAL = 'CLOSE_ACTION_MODAL';

export const OPEN_UPDATE_YARD_MODAL = 'OPEN_UPDATE_YARD_MODAL';
export const CLOSE_UPDATE_YARD_MODAL = 'CLOSE_UPDATE_YARD_MODAL';

export const OPEN_CREATE_YARD_MODAL = 'OPEN_CREATE_YARD_MODAL';
export const CLOSE_CREATE_YARD_MODAL = 'CLOSE_CREATE_YARD_MODAL';

export const SET_ZOOM = 'SET_ZOOM';
export const RESET_YARD_ZOOM = 'RESET_YARD_ZOOM';

export const OPEN_HIVE_MODAL = 'OPEN_HIVE_MODAL';
export const CLOSE_HIVE_MODAL = 'CLOSE_HIVE_MODAL';

export const OPEN_DELETE_YARD_MODAL = 'OPEN_DELETE_YARD_MODAL';
export const CLOSE_DELETE_YARD_MODAL = 'CLOSE_DELETE_YARD_MODAL';

export const OPEN_GROUPS_MODAL = 'OPEN_GROUPS_MODAL';
export const CLOSE_GROUPS_MODAL = 'CLOSE_GROUPS_MODAL';

export const OPEN_MANY_TO_MANY_GROUPS_MODAL = 'OPEN_MANY_TO_MANY_GROUPS_MODAL';
export const CLOSE_MANY_TO_MANY_GROUPS_MODAL = 'CLOSE_MANY_TO_MANY_GROUPS_MODAL';

export const OPEN_GROUP_MODAL = 'OPEN_GROUP_MODAL';
export const CLOSE_GROUP_MODAL = 'CLOSE_GROUP_MODAL';

export const API_REQUEST_START = 'API_REQUEST_START';
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS';
export const API_REQUEST_FAILURE = 'API_REQUEST_FAILURE';
export const API_UNAUTHORIZED_REQUEST_FAILURE = 'API_UNAUTHORIZED_REQUEST_FAILURE';

export const BY_KEY_REQUEST_START = 'BY_KEY_REQUEST_START';
export const BY_KEY_REQUEST_SUCCESS = 'BY_KEY_REQUEST_SUCCESS';
export const BY_KEY_REQUEST_FAILURE = 'BY_KEY_REQUEST_FAILURE';

export const EXPORT_START = 'EXPORT_START';
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
export const EXPORT_FAILURE = 'EXPORT_FAILURE';

export const ORDER_YARD_LIST_BY = 'ORDER_YARD_LIST_BY';
export const ORDER_YARD_LIST_BY_DIRECTION = 'ORDER_YARD_LIST_BY_DIRECTION';

export const RESET_NOTIFICATIONS_COUNT_TO_ZERO = 'RESET_NOTIFICATIONS_COUNT_TO_ZERO';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';

export const FETCH_HHT_EVENT_ACTION_START = 'FETCH_HHT_EVENT_ACTION_START';
export const FETCH_HHT_EVENT_ACTION_SUCCESS = 'FETCH_HHT_EVENT_ACTION_SUCCESS';
export const FETCH_HHT_EVENT_ACTION_FAILURE = 'FETCH_HHT_EVENT_ACTION_FAILURE';
export const HANDLE_SORT_HIVE_LIST = 'HANDLE_SORT_HIVE_LIST';
export const HANDLE_ACTIVITY_FILTER_CHANGE = 'HANDLE_ACTIVITY_FILTER_CHANGE';
export const FETCH_EVENTS_BY_YARD_ACTION_START = 'FETCH_EVENTS_BY_YARD_ACTION_START';
export const FETCH_EVENTS_BY_YARD_ACTION_SUCCESS = 'FETCH_EVENTS_BY_YARD_ACTION_SUCCESS';
export const FETCH_EVENTS_BY_YARD_ACTION_FAIL = 'FETCH_EVENTS_BY_YARD_ACTION_FAIL';
export const FETCH_EVENTS_BY_HIVE_ACTION_START = 'FETCH_EVENTS_BY_HIVE_ACTION_START';
export const FETCH_EVENTS_BY_HIVE_ACTION_SUCCESS = 'FETCH_EVENTS_BY_HIVE_ACTION_SUCCESS';
export const FETCH_EVENTS_BY_HIVE_ACTION_FAIL = 'FETCH_EVENTS_BY_HIVE_ACTION_FAIL';

export const FETCH_HHT_HIVES_ACTION_START = 'FETCH_HHT_HIVES_ACTION_START';
export const FETCH_HHT_HIVES_ACTION_SUCCESS = 'FETCH_HHT_HIVES_ACTION_SUCCESS';
export const FETCH_HHT_HIVES_ACTION_FAILURE = 'FETCH_HHT_HIVES_ACTION_FAILURE';

export const FETCH_HIVE_ACTION_START = 'FETCH_HIVE_ACTION_START';
export const FETCH_HIVE_ACTION_SUCCESS = 'FETCH_HIVE_ACTION_SUCCESS';
export const FETCH_HIVE_ACTION_FAILURE = 'FETCH_HIVE_ACTION_FAILURE';

export const TRANSLATIONS_START = 'TRANSLATIONS_START';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const UPDATE_SPANISH_TRANSLATIONS_SUCCESS = 'UPDATE_SPANISH_TRANSLATIONS_SUCCESS';
export const TRANSLATIONS_FAILURE = 'TRANSLATIONS_FAILURE';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const TOGGLE_MAP_TYPE = 'TOGGLE_MAP_TYPE';
export const SET_ZOOM_GLOBAL = 'SET_ZOOM_GLOBAL';
export const SET_CENTER_GLOBAL = 'SET_CENTER_GLOBAL';
export const SET_BEE_FLY_ZONES = 'SET_BEE_FLY_ZONES';

export const DISPATCH_PAGINATION_ACTION = 'DISPATCH_PAGINATION_ACTION';
export const DISPATCH_LIMIT_ACTION = 'DISPATCH_LIMIT_ACTION';

export const GROUPS_ACTION_START = 'GROUPS_ACTION_START';
export const GROUPS_ACTION_SUCCESS = 'GROUPS_ACTION_SUCCESS';
export const GROUPS_ACTION_FAILURE = 'GROUPS_ACTION_FAILURE';
export const MANY_GROUPS_ACTION_SUCCESS = 'MANY_GROUPS_ACTION_SUCCESS';
export const SORT_GROUP_LIST = 'SORT_GROUP_LIST';
export const REMOVE_GROUP_SUCCESS = 'REMOVE_GROUP_SUCCESS';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const SET_SEARCH_HISTORY_LIST = 'SET_SEARCH_HISTORY_LIST';
