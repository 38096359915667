import React, { useCallback, useMemo, useRef } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';

import { AgGrid, useGridApi } from '@components/common/AgGrid';
import { useDynamicTableUtil } from '@components/common/DynamicTable/hooks/useDynamicTableUtil';

import { DefaultCell } from './DefaultCell/DefaultCell';
import { useDynamicTableHeightSetter } from './hooks/useDynamicTableHeightSetter';
import {
  DEF_CACHE_BLOCK_COUNT,
  DEF_CACHE_BLOCK_SIZE,
  DEF_COLUMN_MIN_WIDTH,
  DEF_HEADER_GROUP_HEIGHT,
  DEF_HEADER_HEIGHT,
  DEF_INITIAL_ROW_COUNT,
  DEF_MAX_CONCURRENT_REQUESTS,
  DEF_PAGINATION_SIZE,
  DEF_ROW_HEIGHT,
  DEF_UPDATE_UPDATE_DEBOUNCE,
} from './constants';
import { DynamicTableHeader } from './DynamicTableHeader';
import { useDynamicTablePaginationHelper, useDynamicTableScrollingEffects } from './hooks';
import { LoadingCell } from './LoadingCell';
import { DynamicTableStyleSetter } from './styles';
import { CellRenderParams, DynamicTableProps, DynamicTableRow, RowColumnData } from './types';

export const DynamicTable: React.VFC<AgGridReactProps & DynamicTableProps> = ({
  headerComponentParams,
  renderStaticCell,
  renderDynamicCell,
  defaultColDef,
  enableTableInteraction = true,
  paginationOptions,
  config,
  onGridReady: onGridReadyProp,
  ...agGridProps
}) => {
  const { setGridApi } = useGridApi();
  const gridWrapperRef = useRef<HTMLDivElement>(null);
  const tableUtil = useDynamicTableUtil(config);

  useDynamicTableScrollingEffects();
  useDynamicTableHeightSetter(gridWrapperRef);
  useDynamicTablePaginationHelper(paginationOptions);

  const onGridReady = useCallback(
    ({ api }) => {
      tableUtil.updateGridColumns(api, agGridProps.columnDefs ?? []);
      onGridReadyProp && onGridReadyProp(api);
      setGridApi(api);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setGridApi]
  );

  const renderCell = useCallback(
    (gridParams: ICellRendererParams<DynamicTableRow, RowColumnData>) => {
      const id = gridParams.data?.meta.id;
      const gridParamData = gridParams.data;

      const columnDef = gridParams.column?.getColDef();
      const columnDefWithMetadata = columnDef ? tableUtil.getColumnDefWithMetadata(columnDef) : null;
      const column = columnDefWithMetadata?.metadata.column;
      const columnKey = columnDefWithMetadata?.field;

      const view = columnDefWithMetadata ? tableUtil.getActiveView(columnDefWithMetadata) : null;

      if (!column || !columnDef || !columnKey || !view || !gridParamData) return <LoadingCell visible={true} />;

      const staticCellRender = renderStaticCell && renderStaticCell({ gridParamData, field: columnKey });
      if (staticCellRender) return staticCellRender;

      const rowMeta = gridParams.data ? gridParams.data.meta : undefined;
      const columnData = gridParams.data ? gridParams.data.data[columnKey] : undefined;
      const columnViews = columnData?.views;
      const viewData = columnKey && columnViews ? columnViews[view.key]?.data : undefined;

      const isLoading = viewData === undefined;

      const cellRenderParams: CellRenderParams = {
        field: columnKey,
        data: viewData,
        meta: rowMeta,
        column,
        view,
        id,
      };

      const cellRender = (renderDynamicCell && renderDynamicCell(cellRenderParams)) ?? <DefaultCell data={viewData} />;

      return <>{isLoading ? <LoadingCell visible={isLoading} /> : cellRender}</>;
    },
    [renderDynamicCell, renderStaticCell, tableUtil]
  );

  const _defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      lockPinned: true,
      lockPosition: true,
      headerComponent: DynamicTableHeader,
      headerComponentParams,
      cellRenderer: renderCell,
      minWidth: DEF_COLUMN_MIN_WIDTH,
      ...defaultColDef,
    }),
    [defaultColDef, headerComponentParams, renderCell]
  );

  return (
    <DynamicTableStyleSetter ref={gridWrapperRef} $enableInteraction={enableTableInteraction}>
      <div className="ag-theme-alpine">
        <AgGrid
          onGridReady={onGridReady}
          rowModelType={'infinite'}
          rowSelection={'multiple'}
          pagination={true}
          suppressRowVirtualisation={true}
          suppressColumnVirtualisation={true}
          suppressRowClickSelection={true}
          rowHeight={DEF_ROW_HEIGHT}
          headerHeight={DEF_HEADER_HEIGHT}
          groupHeaderHeight={DEF_HEADER_GROUP_HEIGHT}
          cacheBlockSize={DEF_CACHE_BLOCK_SIZE}
          maxBlocksInCache={DEF_CACHE_BLOCK_COUNT}
          paginationPageSize={DEF_PAGINATION_SIZE}
          blockLoadDebounceMillis={DEF_UPDATE_UPDATE_DEBOUNCE}
          maxConcurrentDatasourceRequests={DEF_MAX_CONCURRENT_REQUESTS}
          infiniteInitialRowCount={DEF_INITIAL_ROW_COUNT}
          sortingOrder={['asc', 'desc']}
          defaultColDef={_defaultColDef}
          {...agGridProps}
        />
      </div>
    </DynamicTableStyleSetter>
  );
};
