import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@components/form/inputs/CheckBox';

import { StyledCheckboxWrapper, StyledGridRow, StyledText } from './styles';
import { NotificationRowProps } from './types';

// eslint-disable-next-line react/prop-types
export const NotificationRow = ({ idMail, idSms, valueMail, valueSms, onChange, label }: NotificationRowProps) => {
  const { t } = useTranslation();

  return (
    <StyledGridRow>
      <StyledText typography="SmallParagraph">{t(label)}</StyledText>

      <StyledCheckboxWrapper>
        {idMail ? (
          <Checkbox
            id={idMail}
            onChange={onChange}
            name={idMail}
            hiddenLabelText={t(idMail)}
            value={valueMail === true}
          />
        ) : null}
      </StyledCheckboxWrapper>

      <StyledCheckboxWrapper>
        {idSms ? (
          <Checkbox id={idSms} name={idSms} onChange={onChange} hiddenLabelText={t(idSms)} value={valueSms === true} />
        ) : null}
      </StyledCheckboxWrapper>
    </StyledGridRow>
  );
};
