import React from 'react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';
import { useQueryParamState } from '@helpers/QueryParams/hooks';
import { useTranslation } from '@hooks/useTranslation';

export const ForgotPasswordFormSuccess: React.VFC<{ onGoBackClick: () => void }> = ({ onGoBackClick }) => {
  const t = useTranslation();
  const [email] = useQueryParamState('email');

  return (
    <Box column gap_150 alignItems={'flex-start'} justifyContent={'center'}>
      <Box gap_050 column>
        <Text typography={'Heading1'} weight={'700'}>
          {t('forgot_password_success_title')}
        </Text>

        <Text
          typography={'SmallParagraph'}
          dangerouslySetInnerHTML={{ __html: t('forgot_password_success_message_1', { email }) }}
        />
        <Text
          typography={'SmallParagraph'}
          dangerouslySetInnerHTML={{ __html: t('forgot_password_success_message_2') }}
        />
      </Box>

      <Button primary onClick={onGoBackClick}>
        {t('go_back')}
      </Button>
    </Box>
  );
};
