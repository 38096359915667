import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CellClickedEvent, ColDef, GetRowIdFunc, ICellRendererParams } from 'ag-grid-community';

import { HealthScore } from '@components//common/HealthScore/HealthScore';
import { DynamicTable } from '@components/common/DynamicTable';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import {
  useDynamicTableDataCycle,
  useDynamicTableEmptyState,
  useDynamicTableHasLoaded,
  useDynamicTableScrollingEffects,
} from '@components/common/DynamicTable/hooks';
import { HealthScoreTooltip } from '@components/common/HealthScore/HealthScoreTooltip';
import { YardName } from '@components/yard/YardName';
import { GroupsCell } from '@components/yard/YardsList/cells/GroupsCell';
import { LoadingCell } from '@components/yard/YardsList/cells/LoadingCell';
import { MenuCell } from '@components/yard/YardsList/cells/MenuCell';
import { PracticeCell } from '@components/yard/YardsList/cells/PracticeCell';
import { Status } from '@components/yard/YardsList/cells/Status';
import { YardTypeCell } from '@components/yard/YardsList/cells/YardTypeCell';
import { DEF_ABSTRACT_COLUMNS_KEYS } from '@components/yard/YardsList/constants';
import { useYardsListAnalytics } from '@components/yard/YardsList/hooks/useYardsListAnalytics';
import { ColumnType, RowColumnData, YardRow } from '@components/yard/YardsList/types';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { YardsListEmpty, YardsListEmptyWithFilters } from '@components/yard/YardsList/YardsListEmpty';
import APP from '@config/constants';
import { getDate } from '@helpers/deprecated/time';
import { URLUtil } from '@helpers/URL';
import { usePractices } from '@redux/Practices/hooks';

import { AvgVisits } from './cells/AvgVisits';
import { Grading } from './cells/Grading';
import { LastVisit } from './cells/LastVisit';
import { YardSelectCheckbox } from './cells/YardSelectCheckbox';
import { YardsListHeader } from './YardsListHeader';
import { YardsSelectAllAcrossPages } from './YardsSelectAllAcrossPages';

export const YardsList: React.VFC = () => {
  const [didFirstLoad, setDidFirstLoad] = useState(false);
  const history = useHistory();

  const { isListEmpty } = useDynamicTableEmptyState();
  const enableTableInteraction = didFirstLoad && !isListEmpty;
  const hasLoaded = useDynamicTableHasLoaded();
  const hasAppliedFilters = useSelector((state) => state.yardsFiltersReducer.appliedFiltersCount > 0);
  const showEmptyYards = hasLoaded && isListEmpty && !hasAppliedFilters;
  const showFilteredEmptyYards = hasLoaded && isListEmpty && hasAppliedFilters;

  usePractices();

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      setDidFirstLoad(true);
    }, []),
  });

  useDynamicTableScrollingEffects();

  useYardsListAnalytics();

  const getRowId = useCallback<GetRowIdFunc<YardRow>>(({ data }) => String(data.meta.id), []);

  const onCellClicked = useCallback(
    (e: CellClickedEvent<YardRow>) => {
      if (e.column.getId() === 'select') return;

      // If we don't use a timeout, the screen freezes.
      // Why? No idea. Maybe another AgGrid issue.
      setTimeout(() => {
        if (e.data?.meta.id) {
          const path = URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: e.data.meta.id } });
          const event = e.event as MouseEvent;
          const hasControlOrCommandPressed = event.ctrlKey || event.metaKey;
          if (hasControlOrCommandPressed) {
            window.open(path);
          } else {
            history.push(path);
          }
        }
      });
    },
    [history]
  );

  const getCellRenderParams = useCallback(({ colDef, data: rowData }: ICellRendererParams<YardRow, RowColumnData>) => {
    const colDefWithMetadata = colDef ? GridApiUtil.getColumnDefWithMetadata(colDef) : null;

    if (
      colDef &&
      !DEF_ABSTRACT_COLUMNS_KEYS.includes(colDef.field) &&
      colDefWithMetadata &&
      colDefWithMetadata.field &&
      rowData
    ) {
      const id = rowData.meta.id;
      const { column } = colDefWithMetadata.metadata;
      const view = GridApiUtil.getActiveView(colDefWithMetadata);

      if (view) {
        const data = rowData.data[colDefWithMetadata.field]?.views[view.key].data;
        return { id, column, view, data, colDef };
      }
    }

    return null;
  }, []);

  const renderCell = useCallback(
    (gridParams: ICellRendererParams<YardRow, RowColumnData>) => {
      const params = getCellRenderParams(gridParams);

      if (gridParams.colDef?.field === 'select') {
        return <YardSelectCheckbox yard={gridParams.data} />;
      }

      if (gridParams.colDef?.field === 'menu') {
        return <MenuCell data={gridParams.data ?? null} />;
      }

      let cellRender: any;
      const isLoading = params?.data === undefined;

      if (params?.data === undefined) {
        cellRender = null;
      } else {
        const { column, data } = params;
        switch (column.key) {
          case 'composedName':
            cellRender = (
              <YardName
                name={data.name}
                contractName={data.contractName}
                typography={'SmallParagraph'}
                weight={'600'}
                autoWidth
              />
            );
            break;
          case 'composedStatus':
            cellRender = <Status data={data} />;
            break;
          case 'healthScore':
            cellRender = (
              <HealthScore data={data} naTextProps={{ typography: 'SmallParagraph', dashed: true }}>
                <HealthScoreTooltip data={data} />
              </HealthScore>
            );
            break;
          case 'averageVisits':
            cellRender = <AvgVisits data={data} />;
            break;
          case 'lastVisit':
            cellRender = <LastVisit {...params} />;
            break;
          case 'grading':
            cellRender = <Grading data={data} />;
            break;
          case 'groups':
            cellRender = <GroupsCell data={data} />;
            break;
          case 'type':
            cellRender = <YardTypeCell data={data} />;
            break;
          default:
            if (column.type == ColumnType.PRACTICE || column.type == ColumnType.PRACTICE_CATEGORY) {
              cellRender = <PracticeCell {...params} />;
            } else if (data !== null) {
              // TODO: Move it to its own cell component.
              let value = String(data);
              const isDate = /^\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}/.test(String(data));

              if (isDate) {
                value = getDate(data);
              }

              cellRender = (
                <DefaultCellWrapper>
                  <DefaultCellText>{value}</DefaultCellText>
                </DefaultCellWrapper>
              );
            } else {
              cellRender = null;
            }
            break;
        }
      }

      return (
        <>
          {cellRender}
          <LoadingCell visible={isLoading} />
        </>
      );
    },
    [getCellRenderParams]
  );

  const defaultColDef = useMemo<ColDef<YardRow>>(
    () => ({
      headerComponent: YardsListHeader,
      cellRenderer: renderCell,
    }),
    [renderCell]
  );

  return (
    <>
      <YardsSelectAllAcrossPages />
      <DynamicTable
        enableTableInteraction={enableTableInteraction}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        onCellClicked={onCellClicked}
      />

      <YardsListEmptyWithFilters visible={showFilteredEmptyYards} />
      <YardsListEmpty visible={showEmptyYards} />
    </>
  );
};
