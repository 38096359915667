import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';

import { Box } from '@components/common/Box';
import { useOnYardSaveListener } from '@components/yard/YardCreateOrUpdateModal/hooks';
import { YardDetailsPage } from '@components/yard/YardDetails';
import { YardDetailsHeader } from '@components/yard/YardDetails/YardDetailsHeader';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeYardDetailsDisposeAction, makeYardDetailsFetchThunk } from '@redux/YardDetails/actions';
import { YardDetails404 } from '@scenes/admin/YardDetails/YardDetails404';
import { YardDetailsLoading } from '@scenes/admin/YardDetails/YardDetailsLoading';

export const YardDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { uid } = useParams<{ uid: string }>();

  useOnYardSaveListener(
    useCallback(() => {
      dispatch(makeYardDetailsFetchThunk(parseInt(uid)));
    }, [dispatch, uid])
  );

  useEffect(() => {
    dispatch(makeYardDetailsFetchThunk(parseInt(uid)));
    return () => {
      dispatch(makeYardDetailsDisposeAction());
    };
  }, [dispatch, uid]);

  return (
    <Box relative column stretch>
      <YardDetailsHeader />
      <YardDetailsPage />
      <YardDetailsLoading />
      <YardDetails404 />
    </Box>
  );
};
