import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';

export const StyledMetricCardContainer = styled.button<{ $clickable?: boolean }>(
  ({ theme, $clickable }) => css`
    cursor: ${$clickable ? 'pointer' : 'default'};
    box-sizing: border-box;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-evenly;
    align-items: stretch;

    padding: 14px 12px; // Hard-coded from designs.
    border: 1px solid ${theme.primitives.colors.grey04};
    background: ${theme.primitives.colors.white};
    border-radius: ${theme.shape.paperRadius02}px;

    min-width: 0;
    flex-basis: 231px;
    height: 108px;

    ${$clickable &&
    css`
      &:hover:not(:disabled) {
        background: ${theme.primitives.colors.grey02};
      }
    `}

    h3 {
      line-height: 0.875rem;
    }
  `
);

export const StyledArrowButton = styled(PrimaryButton)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  padding: 0;
  align-items: center;
  justify-content: center;
  z-index: ${theme.getZIndexOf('lowLevelIndex')}; 
  transition: ${theme.animations.transitionMedium('background-color')}; 
`
);

const CardAppearingKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledCardWrapper = styled.div<{ $index: number; $hidden: boolean; $animate: boolean }>(
  ({ theme, $index, $hidden, $animate }) => css`
    position: absolute;
    display: flex;
    top: 0;
    opacity: ${$hidden ? 0 : 1};
    pointer-events: ${$hidden ? 'none' : 'all'};

    transition: ${theme.animations.transitionMedium('left', 'opacity')};
    animation: ${$animate ? CardAppearingKeyframes : 'none'} ${theme.animations.durationLong + 100 * $index}ms
      ${theme.animations.easing};

    padding: 0 ${theme.spacing._050};
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const StyledCardsContainer = styled.div(
  ({ theme }) => css`
    display: block;
    position: relative;
    // brings down the z-index of the loading spinner so it doesnt over lap things like the search
    z-index: ${theme.getZIndexOf('content')};
    width: 100%;
    height: 100%;
  `
);

export const StyledCarrouselContainer = styled(Box).attrs({
  alignItems: 'center',
})<{ $isVisible: boolean; $hasOverflow: boolean }>(
  ({ theme, $isVisible, $hasOverflow }) => css`
    overflow: hidden;
    padding: 0 ${$hasOverflow ? theme.spacing._150 : `calc(${theme.spacing._150} - ${theme.spacing._050})`};
    margin: 0 -${theme.spacing._150};
    gap: ${$hasOverflow ? theme.spacing._050 : 0};

    width: calc(100% + 2 * ${theme.spacing._150});
    height: 0;

    transition: ${theme.animations.transitionMedium('height')};

    ${$isVisible &&
    css`
      height: 108px;
    `}
  `
);

export const StyledToggleMetricsButton = styled.button<{ $areMetricsVisible: boolean }>(
  ({ theme, $areMetricsVisible }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-top: ${-theme.spacing._050};

    transition: ${theme.animations.transitionMedium('margin-top')};

    &:before {
      content: '';
      position: absolute;
      inset: 50% 0 auto 0;
      height: 1px;
      background-color: ${theme.primitives.colors.grey05};
    }

    div {
      position: relative;
      background-color: ${theme.primitives.colors.background};
      padding: 0 ${theme.spacing._100};
      gap: ${theme.spacing._050};
    }

    svg {
      transition: ${theme.animations.transitionMedium('transform')};
      transform: rotate(180deg);
    }

    ${$areMetricsVisible &&
    css`
      margin-top: ${theme.spacing._050};

      svg {
        transform: rotate(0deg);
      }
    `}
  `
);

export const StyledTrendArrowWrapper = styled.div<{ $trendDown?: boolean; $trendDanger?: boolean }>(
  ({ theme, $trendDown, $trendDanger }) => css`
    transform: rotate(${$trendDown ? 45 : -45}deg);
    color: ${$trendDanger ? theme.colors.contentDanger : theme.colors.contentPositive};
  `
);
