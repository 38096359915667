import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '@helpers/Thunk/hooks';
import { makePracticesFetchOnceThunk } from '@redux/Practices/actions';
import { NameGetter } from '@redux/Practices/types';

const fetchPracticesThunk = makePracticesFetchOnceThunk();

export function useBackwardCompatiblePractices() {
  const { practiceDefinitions } = usePractices();

  return useMemo(
    () =>
      Object.values(practiceDefinitions).map(({ name, actions, ...props }) => ({
        ...props,
        name: name.EN,
        actions: actions.map(({ name, ...props }) => ({
          ...props,
          detail: name.EN,
          detail_spanish: name.ES,
        })),
      })),
    [practiceDefinitions]
  );
}

export function usePractices() {
  const dispatch = useDispatch();
  const { ...state } = useSelector((state) => state.practicesReducer);

  const loadPractices = useCallback(() => {
    dispatch(fetchPracticesThunk);
  }, [dispatch]);

  const getAction = useCallback(
    (actionId: number | null | undefined): BeeAction | null => {
      return (
        Object.values(state.practiceDefinitions)
          .flatMap(({ actions }) => actions)
          .find(({ id }) => id === actionId) ?? null
      );
    },
    [state.practiceDefinitions]
  );

  const getActions = useCallback(
    (actionIds: Array<number>): Array<BeeAction> => {
      return Object.values(state.practiceDefinitions)
        .flatMap(({ actions }) => actions)
        .filter(({ id }) => actionIds.includes(id));
    },
    [state.practiceDefinitions]
  );

  const getPractice = useCallback(
    (practiceId: number | null | undefined): BeePracticeDefinition | null => {
      return Object.values(state.practiceDefinitions).find(({ id }) => id === practiceId) ?? null;
    },
    [state.practiceDefinitions]
  );

  const getPracticeByAction = useCallback(
    (actionId: number | null | undefined): BeePracticeDefinition | null => {
      return (
        Object.values(state.practiceDefinitions).find(({ actions }) => actions.some(({ id }) => id === actionId)) ??
        null
      );
    },
    [state.practiceDefinitions]
  );

  const getPracticeByFlag = useCallback(
    (practiceFlag: string | null | undefined): BeePracticeDefinition | null => {
      return Object.values(state.practiceDefinitions).find(({ flag }) => flag === practiceFlag) ?? null;
    },
    [state.practiceDefinitions]
  );

  const getActionName = useCallback<NameGetter>(
    (actionId, defaultValue) => {
      const practice = getAction(actionId);
      return (practice?.name.EN ?? defaultValue ?? null) as string;
    },
    [getAction]
  );

  const getPracticeName = useCallback<NameGetter>(
    (practiceId, defaultValue) => {
      const practice = getPractice(practiceId);
      return (practice?.name.EN ?? defaultValue ?? null) as string;
    },
    [getPractice]
  );

  const getPracticeNameByAction = useCallback<NameGetter>(
    (actionId, defaultValue) => {
      return getPracticeName(getPracticeByAction(actionId)?.id, defaultValue);
    },
    [getPracticeByAction, getPracticeName]
  );

  useEffect(() => {
    loadPractices();
  }, [dispatch, loadPractices]);

  return {
    ...state,
    loadPractices,
    getAction,
    getActions,
    getActionName,
    getPractice,
    getPracticeByAction,
    getPracticeByFlag,
    getPracticeName,
    getPracticeNameByAction,
  };
}
