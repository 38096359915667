import React from 'react';
import { FieldValues, PathValue, useController, UseControllerProps, useFormContext } from 'react-hook-form';

import { useFormFieldError } from '@components/form/core/Form/hooks';

export type InputBaseProps<FV extends FieldValues = FieldValues> =
  | ({ useTextArea?: false } & InputSimpleBaseProps<FV>)
  | ({ useTextArea: true } & InputMultilineBaseProps<FV>);

export type InputSimpleBaseProps<FV extends FieldValues> = Omit<UseControllerProps<FV>, 'control'> &
  Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'onKeyUp' | 'onKeyDown' | InputCommonProps>;

export type InputMultilineBaseProps<FV extends FieldValues> = Omit<UseControllerProps<FV>, 'control'> &
  Pick<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onKeyUp' | 'onKeyDown' | 'rows' | 'cols' | InputCommonProps>;

export type InputCommonProps =
  | 'id'
  | 'placeholder'
  | 'disabled'
  | 'className'
  | 'required'
  | 'autoComplete'
  | 'readOnly'
  | 'autoFocus';

export const InputBase = <FV extends FieldValues = FieldValues>({
  /** Form API props */
  name,
  rules,
  shouldUnregister,
  defaultValue,
  disabled,

  /** Native props */
  ...nativeProps
}: InputBaseProps<FV>) => {
  // Disabled false seems to cause the input to don't update.
  // Maybe a bug in the library.
  disabled = disabled || undefined;

  const { control } = useFormContext<FV>();
  const { field } = useController<FV>({ name, rules, shouldUnregister, defaultValue, disabled, control });

  const { error } = useFormFieldError(name);
  const ariaValid = !error ? undefined : error.message ? 'true' : 'false';

  const useTextArea = nativeProps.useTextArea;
  delete nativeProps.useTextArea;

  if (useTextArea) {
    return <textarea aria-invalid={ariaValid} {...nativeProps} {...field} />;
  }

  // If numeric, keep it as text.
  const isNumeric = nativeProps.type === 'number';
  isNumeric && (nativeProps.type = 'text');

  if (typeof field.value === 'undefined') field.value = '' as PathValue<any, any>;

  return <input aria-invalid={ariaValid} {...nativeProps} {...field} />;
};
