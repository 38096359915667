import React, { useMemo } from 'react';
import { Color } from 'styled-components';

import { usePractices } from '@redux/Practices/hooks';
import { useColor } from '@style/theme/hooks';

const DEFAULT_ICON_CLASS = 'show';
const DEFAULT_ICON_SIZE = 16;
const FEEDING_FLAG = 'feeding';
const TREATMENT_FLAG = 'treatment';
const HIVE_GRADING_FLAG = 'hive grading';

export interface PracticeIconProps {
  practiceId?: number;
  actionId?: number;
  size?: number;
  color?: Color | 'currentColor';

  usePresetFeeding?: boolean;
  usePresetTreatment?: boolean;
  usePresetHiveGrading?: boolean;
}

export const PracticeIcon: React.VFC<PracticeIconProps> = ({
  practiceId,
  actionId,
  size,
  color,
  usePresetFeeding,
  usePresetTreatment,
  usePresetHiveGrading,
}) => {
  const { getPractice, getPracticeByAction, getPracticeByFlag } = usePractices();

  const iconClassName = useMemo(() => {
    const practice =
      getPractice(practiceId) ||
      getPracticeByAction(actionId) ||
      (usePresetFeeding
        ? getPracticeByFlag(FEEDING_FLAG)
        : usePresetTreatment
        ? getPracticeByFlag(TREATMENT_FLAG)
        : usePresetHiveGrading
        ? getPracticeByFlag(HIVE_GRADING_FLAG)
        : null);
    return `icon-${practice?.icon ?? DEFAULT_ICON_CLASS}`.toLowerCase();
  }, [
    actionId,
    getPractice,
    getPracticeByAction,
    getPracticeByFlag,
    practiceId,
    usePresetFeeding,
    usePresetTreatment,
    usePresetHiveGrading,
  ]);

  return <i className={iconClassName} style={{ color: useColor(color), fontSize: size ?? DEFAULT_ICON_SIZE }} />;
};
