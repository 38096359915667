import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Archive = ({ color, size, ...svgProps }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 16 16" size={size} {...svgProps}>
      <path
        d="m13.693 3.487-.926-1.12A.968.968 0 0 0 12 2H4a.99.99 0 0 0-.773.367l-.92 1.12A1.305 1.305 0 0 0 2 4.333v8.334C2 13.4 2.6 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V4.333c0-.32-.113-.62-.307-.846Zm-5.926 7.946L4.333 8h2.334V6.667h2.666V8h2.334l-3.434 3.433a.334.334 0 0 1-.466 0Zm-4.354-8.1.54-.666h8l.627.666H3.413Z"
        fill={useColor(color)}
      />
    </IconSVGView>
  );
};
