import styled from 'styled-components';

import { Button, NavRouterLink } from '@components/common/CTA';
import { Text } from '@components/common/Text';

export const StyledAlertTextWrapper = styled.span`
  line-height: 0;
  & * {
    line-height: 0.875rem;
    border: none;
  }
`;

export const StyledAlertText = styled(Text)`
  b,
  strong {
    font-weight: 600;
  }
`;

export const StyledAlertLink = styled(NavRouterLink)`
  display: inline;
`;

export const StyledAlertButton = styled(Button)`
  display: inline;
`;
