import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getFirstNonFalsyStyle } from '@helpers/Styling';

import { PRESETS } from '../Text/styles';

import { StyledCTAProps } from './types';

const unstyledStyles = css`
  border: none;
  margin: 0;
  padding: 0;
  background-color: initial;
  text-align: initial;
  text-decoration: none;

  &:focus-visible {
    border-color: ${({ theme }) => theme.primitives.colors.focus01};
    outline: 1px solid ${({ theme }) => theme.primitives.colors.focus02};
  }
`;

const paddingHorizontalStyles = css<StyledCTAProps>(
  ({ theme }) => css`
    padding-left: ${theme.spacing._100};
    padding-right: ${theme.spacing._100};
  `
);

const paddingHorizontalIconOnlyStyles = css<StyledCTAProps>(
  ({ theme }) => css`
    padding-left: ${theme.spacing._050};
    padding-right: ${theme.spacing._050};
  `
);

const paddingVerticalStyles = css(
  ({ theme }) => css`
    padding-top: ${theme.spacing._050};
    padding-bottom: ${theme.spacing._050};
  `
);

const marginLeftStyles = css(
  ({ theme }) => css`
    margin-left: ${theme.spacing._100};
  `
);
const marginRightStyles = css(
  ({ theme }) => css`
    margin-right: ${theme.spacing._100};
  `
);

const sharedStyles = css(
  ({ theme }) => css`
    border-radius: ${theme.spacing._025};
    font-size: ${PRESETS.CTA.fontSize};
    line-height: ${PRESETS.CTA.lineHeight};
    font-weight: 600;
    border: 1px solid transparent;
    display: inline-flex;

    align-items: center;
    position: relative;

    &:focus-visible {
      border-color: ${theme.primitives.colors.focus01};
      outline: 1px solid ${theme.primitives.colors.focus02};
    }

    &:disabled {
      cursor: default;
    }
  `
);
const growStyles = css`
  flex: 1;
  justify-content: center;
`;
const withIconLeftStyles = css(
  ({ theme }) => css`
    & > svg {
      margin-right: ${theme.spacing._050};
      height: ${theme.spacing._100};
      width: ${theme.spacing._100};
    }
  `
);
const withIconRightStyles = css(
  ({ theme }) => css`
    & > svg {
      margin-left: ${theme.spacing._050};
      height: ${theme.spacing._100};
      width: ${theme.spacing._100};
    }
  `
);

const primaryStyles = css(
  ({ theme }) => css`
    color: ${theme.primitives.colors.white};
    background-color: ${theme.primitives.colors.grey08};

    &:hover {
      background-color: ${theme.primitives.colors.grey07};
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
      background-color: ${theme.primitives.colors.grey05};
    }
  `
);

const primaryDangerStyles = css(
  ({ theme }) => css`
    color: ${theme.colors.contentPrimaryReverse};
    background-color: ${theme.colors.surfaceDanger};

    &:hover {
      background-color: ${theme.primitives.colors.red03};
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
      background-color: ${theme.primitives.colors.grey05};
    }
  `
);

const secondaryStyles = css<StyledCTAProps>(
  ({ theme }) => css`
    color: ${theme.primitives.colors.grey08};
    background-color: transparent;
    border-color: ${theme.colors.borderPrimary};

    &:hover {
      color: ${theme.primitives.colors.white};
      background-color: ${theme.primitives.colors.grey08};
      border-color: transparent;
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
      background-color: transparent;
      border-color: ${theme.primitives.colors.grey06};
    }
  `
);

const secondaryDangerStyles = css<StyledCTAProps>(
  ({ theme }) => css`
    color: ${theme.colors.contentDanger};
    background-color: transparent;
    border-color: ${theme.colors.borderDanger};

    &:hover {
      color: ${theme.colors.contentPrimaryReverse};
      background-color: ${theme.colors.surfaceDanger};
      border-color: transparent;
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
      background-color: transparent;
      border-color: ${theme.primitives.colors.grey06};
    }
  `
);

const secondaryIconOnlyStyles = css<StyledCTAProps>(
  ({ theme }) => css`
    ${() => secondaryStyles}
    border-color: ${theme.colors.borderSecondary};
  `
);

const tertiaryStyles = css<{ $suppressPaddingHorizontal?: boolean; $suppressPaddingVertical?: boolean }>(
  ({ theme, $suppressPaddingHorizontal, $suppressPaddingVertical }) => css`
    color: ${theme.primitives.colors.grey08};
    text-decoration: underline;

    ${!$suppressPaddingHorizontal &&
    css`
      padding-left: ${theme.spacing._025};
      padding-right: ${theme.spacing._025};
    `}
    ${!$suppressPaddingVertical &&
    css`
      padding-top: ${theme.spacing._025};
      padding-bottom: ${theme.spacing._025};
    `}
    &:hover {
      color: ${theme.primitives.colors.grey07};
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
    }
  `
);

const elevatedStyles = css(
  ({ theme }) => css`
    color: ${theme.primitives.colors.grey08};
    background-color: ${theme.primitives.colors.white};
    box-shadow: ${theme.shadows.boxShadow01};

    &:hover {
      color: ${theme.primitives.colors.grey08};
      background-color: ${theme.primitives.colors.grey01};
      box-shadow: ${theme.shadows.boxShadow02};
    }

    &:focus-visible {
      box-shadow: ${theme.shadows.boxShadow02};
    }

    &:disabled {
      color: ${theme.primitives.colors.grey06};
      background-color: ${theme.primitives.colors.grey05};
      box-shadow: initial;
    }
  `
);

const ctaStyles = css<StyledCTAProps>(
  ({
    $unstyled,
    $primary,
    $secondary,
    $tertiary,
    $elevated,
    $danger,
    $iconOnly,
    $withLeadingIcon,
    $withTrailingIcon,
    $suppressPaddingHorizontal,
    $suppressPaddingVertical,
    $withMarginLeft,
    $withMarginRight,
    $grow,
  }) => {
    if ($unstyled) return unstyledStyles;

    return css`
      ${sharedStyles}

      ${getFirstNonFalsyStyle(
        !$suppressPaddingHorizontal && $iconOnly && paddingHorizontalIconOnlyStyles,
        !$suppressPaddingHorizontal && paddingHorizontalStyles
      )}
      ${!$suppressPaddingVertical && paddingVerticalStyles}

      ${$withMarginLeft && marginLeftStyles}
      ${$withMarginRight && marginRightStyles}

      ${$grow && growStyles}

      ${getFirstNonFalsyStyle(
        $primary && $danger && primaryDangerStyles,
        $primary && primaryStyles,
        $secondary && $danger && secondaryDangerStyles,
        $secondary && $iconOnly && secondaryIconOnlyStyles,
        $secondary && secondaryStyles,
        $tertiary && tertiaryStyles
      )}

      ${$elevated && elevatedStyles}
      ${$withLeadingIcon && withIconLeftStyles}
      ${$withTrailingIcon && withIconRightStyles}
    `;
  }
);

export const StyledButton = styled.button`
  ${() => ctaStyles}
`;

export const StyledAnchor = styled.a`
  ${() => ctaStyles}
  display: inline-flex; // no idea why the anchor is taking the full width of the parent, with the same styles as the button
`;

export const StyledNavLink = styled(NavLink)`
  ${() => ctaStyles}
`;

// todo: will implement this later if it makes sense to
// export const StyledLink = styled(Link)`
//   ${ctaStyles}
// `;
