import styled, { css } from 'styled-components';

export const StyledTooltipRoot = styled.span`
  display: none;
`;

export const StyledTooltipView = styled.div<{ arrow?: boolean; light?: boolean }>(
  ({ theme, arrow, light }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${light ? theme.primitives.colors.white : theme.primitives.colors.grey08};
    color: ${light ? theme.primitives.colors.grey08 : theme.primitives.colors.white};
    border-radius: ${theme.shape.paperRadius01}px;
    box-shadow: ${theme.shadows.boxShadow04};
    padding: ${theme.spacing._050};
    transition: ${theme.animations.transitionLong('opacity')};
    max-width: 350px;
    opacity: 0;

    strong {
      font-weight: 700;
    }

    &::before {
      content: ${arrow ? '""' : 'none'};
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
      width: 14px;
      height: 14px;
      border-radius: 14px ${theme.shape.paperRadius01}px ${theme.shape.paperRadius01}px
        ${theme.shape.paperRadius01}px;
      background-color: ${light ? theme.primitives.colors.white : theme.primitives.colors.grey08};
    }
  `
);
