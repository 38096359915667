import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { PageContentHeader, PageContentInfo, PageContentSlim, PageContentTitle } from '@components/common/PageContent';
import { Text } from '@components/common/Text';
import BeePracticesModal from '@components/deprecated/DynamicModals/BeekPracticesModal';
import { PracticeDefinitionCard } from '@components/operation/PracticeDefinitionCard';
import { ToggleDisabledCard } from '@components/operation/ToggleDisabledCard';
import { PracticesConfig } from '@config/Practices';
import { Sorting } from '@helpers/Sorting';
import { useBackwardCompatiblePractices, usePractices } from '@redux/Practices/hooks';

const BackwardCompatibleBeePracticesModal = BeePracticesModal as any;

export const TabPractices: React.FC = () => {
  const { t } = useTranslation();
  const { isFetching, practiceDefinitions, getPracticeName } = usePractices();

  const nonDeletedPracticeDefinitions = useMemo(
    () => Object.values(practiceDefinitions).filter((p) => !p.isDeleted),
    [practiceDefinitions]
  );

  const getPracticeRestrictionCount = useCallback((practice: BeePracticeDefinition) => {
    return Object.keys(PracticesConfig.PRACTICE_DEFINITIONS[practice.flag] ?? {}).length;
  }, []);

  const getActionRestrictionCount = useCallback((action: BeeAction) => {
    return Object.keys(PracticesConfig.ACTION_DEFINITIONS[action.flag] ?? {}).length;
  }, []);

  const sortedPractices = useMemo(
    () =>
      Sorting.sort(Object.values(nonDeletedPracticeDefinitions), (a, b) => {
        const aRestrictions = getPracticeRestrictionCount(a);
        const bRestrictions = getPracticeRestrictionCount(b);
        const aActionsRestrictions = a.actions.reduce((acc, a) => acc + getActionRestrictionCount(a), 0);
        const bActionsRestrictions = b.actions.reduce((acc, a) => acc + getActionRestrictionCount(a), 0);

        if (aRestrictions !== bRestrictions) {
          return bRestrictions - aRestrictions;
        } else if (aActionsRestrictions !== bActionsRestrictions) {
          return bActionsRestrictions - aActionsRestrictions;
        }

        return Sorting.alphanumericSortFunc(getPracticeName(a.id), getPracticeName(b.id));
      }),
    [getActionRestrictionCount, getPracticeName, getPracticeRestrictionCount, practiceDefinitions]
  );

  const backwardCompatibleActionList = useBackwardCompatiblePractices();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <PageContentSlim>
      <PageContentHeader>
        <PageContentTitle>{t('beek_practices')}</PageContentTitle>
        <PageContentInfo>{t('beek_practices_desc')}</PageContentInfo>
      </PageContentHeader>

      <ToggleDisabledCard />

      {sortedPractices.map(({ id }, i) => (
        <PracticeDefinitionCard key={id} practiceDefinitionId={id} marginBottom_150 marginTop_150={i !== 0} />
      ))}

      {sortedPractices.length === 0 && (
        <Box marginVertical_150>
          <Text typography={'CaptionSmall'}>{t('practices_empty')}</Text>
        </Box>
      )}

      <BackwardCompatibleBeePracticesModal actionList={backwardCompatibleActionList} />
    </PageContentSlim>
  );
};
