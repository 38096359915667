import { useEffect, useState } from 'react';

import { useGridApi } from '@components/common/AgGrid';

export function useDynamicTableHasLoaded() {
  const { gridApi } = useGridApi();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!gridApi) return;

    function onModelUpdated() {
      if (!gridApi) {
        return;
      }
      setHasLoaded(gridApi.getInfiniteRowCount() === 0 || gridApi.getRenderedNodes()[0]?.data != undefined);
    }
    gridApi.addEventListener('modelUpdated', onModelUpdated);

    return () => gridApi.removeEventListener('modelUpdated', onModelUpdated);
  }, [gridApi]);

  return hasLoaded;
}
