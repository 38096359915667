import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import { Box } from '@components/common/Box';
import { Card, CardContent, CardContentRow, CardHeader } from '@components/common/Card';
import { Loading } from '@components/common/Loading';
import { PageContent } from '@components/common/PageContent';
import { Text } from '@components/common/Text';
import { PointsOfInterest } from '@components/pollination/PolliContractDetails/PointsOfInterest';
import { useDateUtil } from '@helpers/Date/hooks';
import { formatPhoneNumber } from '@helpers/deprecated/formatPhoneNumber';
import { useContract, useContractId } from '@hooks/useContract';
import { useCropTypes } from '@hooks/useCropTypes';

import { ContractDetailInstructions } from './ContractDetailInstructions';
import { Pricing } from './Pricing';
import { StyledGrid, StyledNote } from './styles';

export const PolliContractDetails: React.FC = () => {
  const uid = useContractId();
  const { contract } = useContract(uid);
  const { getCropTypesVisualString } = useCropTypes();
  const { t } = useTranslation();

  const dateUtil = useDateUtil();

  if (!contract || contract.id !== uid) {
    return (
      <Box paddingHorizontal_200 block relative fullHeight fullWidth>
        <Loading />
      </Box>
    );
  }

  return (
    <PageContent>
      <StyledGrid>
        <Card>
          <CardHeader>
            <Text typography="Heading3" weight={'600'}>
              {t('pollination_contract_details')}
            </Text>
          </CardHeader>
          <CardContent>
            {!!contract.archivedAt && (
              <CardContentRow>
                <Box justifyContent={'space-between'}>
                  <Text typography="SmallParagraph" weight={'600'}>
                    {t('archived_since')}
                  </Text>
                  <Text typography="SmallParagraph">
                    {dateUtil.getFormattedDate(contract.archivedAt, 'MMM DD, YYYY')}
                  </Text>
                </Box>
              </CardContentRow>
            )}
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('grower')}
                </Text>
                <Text typography="SmallParagraph">{contract.grower.name}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('contact')}
                </Text>
                <Text typography="SmallParagraph">{contract.grower.contactName}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('phone')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.grower.contactPhoneNumber ? (
                    <NumberFormat
                      value={contract.grower.contactPhoneNumber}
                      displayType={'text'}
                      format={formatPhoneNumber(contract.grower.contactPhoneNumber)}
                    />
                  ) : (
                    '-'
                  )}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('email')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.grower.contactEmail ? contract.grower.contactEmail : '-'}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('crop_type')}
                </Text>
                <Text typography="SmallParagraph">{getCropTypesVisualString(contract.cropTypesIds)}</Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('pollination_bees_in')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.beesIn ? moment(contract.beesIn).format('MMM DD, YYYY') : '-'}
                </Text>
              </Box>
            </CardContentRow>
            <CardContentRow>
              <Box justifyContent={'space-between'}>
                <Text typography="SmallParagraph" weight={'600'}>
                  {t('pollination_bees_out')}
                </Text>
                <Text typography="SmallParagraph">
                  {contract.beesOut ? moment(contract.beesOut).format('MMM DD, YYYY') : '-'}
                </Text>
              </Box>
            </CardContentRow>
          </CardContent>
        </Card>
        <Box gap={'24px'} column>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('price')}
              </Text>
            </CardHeader>
            <Pricing contract={contract} />
          </Card>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('location')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box marginVertical_050>
                {contract.address ? (
                  <Text typography="SmallParagraph">{contract.address}</Text>
                ) : (
                  <ContractDetailInstructions />
                )}
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_notes')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box marginVertical_050>
                {contract.notes ? (
                  <StyledNote typography="SmallParagraph" dangerouslySetInnerHTML={{ __html: contract.notes }} />
                ) : (
                  <ContractDetailInstructions />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box gap={'24px'} column>
          <Card>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_hives_operations')}
              </Text>
            </CardHeader>
            <CardContent>
              <Box column stretch>
                <Box justifyContent={'space-between'} marginVertical_025>
                  <Text typography={'Heading3'} weight={'600'}>
                    {t('pollination_hives_on_map')}
                  </Text>
                  <Text typography={'Heading3'}>
                    {contract.nbTargetedHives ?? 0}/{contract.nbRequiredHives ?? 0}
                  </Text>
                </Box>
                <Box justifyContent={'space-between'} marginVertical_025>
                  <Text typography={'Heading3'} weight={'600'}>
                    {t('pollination_hives_on_site')}
                  </Text>
                  <Text typography={'Heading3'}>
                    {contract.nbTrackedHives ?? 0}/{contract.nbRequiredHives ?? 0}
                  </Text>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card fit>
            <CardHeader>
              <Text typography="Heading3" weight="600">
                {t('pollination_pois')}
              </Text>
            </CardHeader>
            <PointsOfInterest />
          </Card>
        </Box>
      </StyledGrid>
    </PageContent>
  );
};
