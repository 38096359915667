import React, { useContext } from 'react';

import { PageHeaderContext } from '@components/common/PageHeader/contex';
import { StyledPageHeader } from '@components/common/PageHeader/styles';

export const PageHeader: React.FC = ({ children }) => {
  const context = useContext(PageHeaderContext);
  return (
    <StyledPageHeader $isVisible={!context || context?.isHeaderVisible} gap_100>
      {children}
    </StyledPageHeader>
  );
};
