import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { InputAutocomplete, Option } from '@components/form/inputs/InputAutocomplete';
import { OptionText } from '@components/form/inputs/InputAutocomplete/OptionText';
import { InputAutocompleteProps } from '@components/form/inputs/InputAutocomplete/types';
import { YardTypeIcon } from '@components/yard/YardTypeIcon';
import APP from '@config/constants';
import { useTranslation } from '@hooks/useTranslation';

export const InputYardTypeAutocomplete: React.VFC<InputAutocompleteProps> = ({ ...props }) => {
  const [focused, setFocused] = useState(false);

  const t = useTranslation();
  const form = useFormContext();
  const value = form.watch(props.name);
  const selectedTypeIcon =
    value && !focused ? (
      <Box marginRight_050>
        <YardTypeIcon type={value} />
      </Box>
    ) : null;

  return (
    <InputAutocomplete
      label={t('type_yard')}
      placeholder={t('choose_one')}
      innerLeftContent={selectedTypeIcon}
      onDropdownShown={() => setFocused(true)}
      onDropdownHidden={() => setFocused(false)}
      {...props}
    >
      {APP.YARD_TYPES.map((type) => (
        <Option key={type} value={type}>
          <YardTypeIcon type={type as YardType} />
          <OptionText>{capitalize(type)}</OptionText>
        </Option>
      ))}
    </InputAutocomplete>
  );
};
