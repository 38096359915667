import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { useNonPropagatedOnClick } from '@components/common/CTA/hooks';
import { ChevronRight } from '@components/common/Icon/presets/ChevronRight';
import { Logout } from '@components/common/Icon/presets/Logout';
import { Profile } from '@components/common/Icon/presets/Profile';
import { Replace } from '@components/common/Icon/presets/Replace';
import { Settings } from '@components/common/Icon/presets/Settings';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipRef } from '@components/common/Tooltip/types';
import { StyledSideNavMenu, StyledSideNavOperationSelector } from '@components/layout/SideNav/styles';
import { OperationIcon } from '@components/operation/OperationIcon';
import { OperationSelectorRef } from '@components/operation/OperationSelector';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { makeAuthSignOutThunk } from '@redux/Auth/actions';

export const SideNavMenu: React.VFC<{ expanded?: boolean }> = ({ expanded }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const [menuOpen, setMenuOpen] = useState(false);
  const [switchOperationMenuOpen, setSwitchOperationMenuOpen] = useState(false);

  const operationSelectorRef = useRef<OperationSelectorRef>(null);

  const user = useSelector((state) => state.authReducer.user);
  const tooltipRef = useRef<TooltipRef>(null);
  const canSwitchOperation = !!user && user.memberships.length > 1;
  const hasManyOperations = !!user && user.memberships.length > 4;

  const tooltipOffset = useMemo<[number, number] | undefined>(() => (expanded ? [0, 12] : [8, 8]), [expanded]);
  const switchOperationTooltipOffset = useMemo<[number, number]>(() => [50, 24], []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const switchOperationMenuItem = useNonPropagatedOnClick<HTMLButtonElement>(useCallback(() => null, [menuOpen]));

  const goToSelectOperationPage = useCallback(() => {
    // This will kick the user back to the select operation page.
    history.push(
      URLUtil.buildPagePath(APP.routes.selectOperation, {
        queryParams: { switching: true, next: encodeURIComponent(history.location.pathname) },
      })
    );
  }, [history]);

  const attemptToSignOut = useCallback(async () => {
    tooltipRef.current?.hide();
    // Clear out any present query param.
    await dispatch(makeAuthSignOutThunk());
    setTimeout(() => history.replace(APP.routes.login, {}), 20);
  }, [dispatch, history]);

  const handleMenuOpen = useCallback(() => setMenuOpen(true), []);
  const handleMenuClose = useCallback(() => setMenuOpen(false), []);
  const handleSwitchOperationMenuOpen = useCallback(() => setSwitchOperationMenuOpen(true), []);
  const handleSwitchOperationMenuClose = useCallback(() => setSwitchOperationMenuOpen(false), []);

  // Effect to autofocus or clear the selector
  // search when the selector is toggled.
  useEffect(() => {
    // Make sure the tooltip animation is finished,
    // then autofocus the search input;
    const delay = theme.animations.durationMedium;

    setTimeout(() => {
      switchOperationMenuOpen
        ? operationSelectorRef.current?.focusSearch()
        : operationSelectorRef.current?.clearSearch();
    }, delay);
  }, [switchOperationMenuOpen, theme.animations.durationMedium]);

  return (
    <Tooltip
      ref={tooltipRef}
      light
      interactive
      trigger="click"
      hideOnClick
      toggleMode={switchOperationMenuOpen}
      renderContent={(children) => <StyledSideNavMenu>{children}</StyledSideNavMenu>}
      placement={'top-start'}
      offset={tooltipOffset}
      onShow={handleMenuOpen}
      onHide={handleMenuClose}
    >
      <Box column gap_050 padding_100>
        <OperationIcon />

        <Box column>
          <Text typography={'Heading3'} weight={'600'}>
            {user?.fullName ?? '-'}
          </Text>
          <Text typography={'CaptionSmall'}>{user?.email ?? '-'}</Text>
        </Box>
      </Box>

      <Box column stretch padding_050>
        <NavRouterLink to={APP.routes.profile} unstyled>
          <Box padding_050 gap_050 alignItems={'center'}>
            <Profile />
            <Text typography={'SmallParagraph'} weight={'600'}>
              {t('profile')}
            </Text>
          </Box>
        </NavRouterLink>

        <NavRouterLink to={APP.routes.settings} unstyled>
          <Box padding_050 gap_050 alignItems={'center'}>
            <Settings />
            <Text typography={'SmallParagraph'} weight={'600'}>
              {t('settings')}
            </Text>
          </Box>
        </NavRouterLink>

        {canSwitchOperation && (
          <>
            <ResponsiveRender from={'desktopSM'}>
              <Button ref={switchOperationMenuItem.ref} unstyled>
                <Box padding_050 gap_050 alignItems={'center'}>
                  <Replace />
                  <Text typography={'SmallParagraph'} weight={'600'}>
                    {t('switch_operation')}
                  </Text>
                  <ChevronRight />
                </Box>
                <Tooltip
                  light
                  interactive
                  placement={'right-end'}
                  offset={switchOperationTooltipOffset}
                  renderContent={(children) => children}
                  onShow={handleSwitchOperationMenuOpen}
                  onHide={handleSwitchOperationMenuClose}
                >
                  <StyledSideNavOperationSelector ref={operationSelectorRef} $hasManyOperations={hasManyOperations} />
                </Tooltip>
              </Button>
            </ResponsiveRender>

            <ResponsiveRender until={'tablet'}>
              <Button onClick={goToSelectOperationPage} unstyled>
                <Box padding_050 gap_050 alignItems={'center'}>
                  <Replace />
                  <Text typography={'SmallParagraph'} weight={'600'}>
                    {t('switch_operation')}
                  </Text>
                  <ChevronRight />
                </Box>
              </Button>
            </ResponsiveRender>
          </>
        )}

        <Button onClick={attemptToSignOut} unstyled>
          <Box padding_050 gap_050 alignItems={'center'}>
            <Logout />
            <Text typography={'SmallParagraph'} weight={'600'}>
              {t('logout')}
            </Text>
          </Box>
        </Button>
      </Box>
    </Tooltip>
  );
};
