import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const EmptyYard = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        d="M2.943 12.248C3.03264 12.0944 3.19714 11.9999 3.375 12H9.625C9.80286 11.9999 9.96736 12.0944 10.057 12.248L10.932 13.748C10.9766 13.8245 11 13.9115 11 14V20C10.9962 20.5507 10.5507 20.9962 10 21H3C2.4493 20.9962 2.00382 20.5507 2 20V14C1.99996 13.9115 2.02342 13.8245 2.068 13.748L2.943 12.248ZM9.338 13H3.662L3.079 14H9.921L9.338 13ZM5.5 15H7.5C7.77614 15 8 15.2239 8 15.5C8 15.7761 7.77614 16 7.5 16H5.5C5.22386 16 5 15.7761 5 15.5C5 15.2239 5.22386 15 5.5 15ZM13.943 12.248C14.0326 12.0944 14.1971 11.9999 14.375 12H20.625C20.8029 11.9999 20.9674 12.0944 21.057 12.248L21.932 13.748C21.9766 13.8245 22 13.9115 22 14V20C21.9962 20.5507 21.5507 20.9962 21 21H14C13.4493 20.9962 13.0038 20.5507 13 20V14C13 13.9115 13.0234 13.8245 13.068 13.748L13.943 12.248ZM20.338 13H14.662L14.079 14H20.921L20.338 13ZM16.5 15H18.5C18.7761 15 19 15.2239 19 15.5C19 15.7761 18.7761 16 18.5 16H16.5C16.2239 16 16 15.7761 16 15.5C16 15.2239 16.2239 15 16.5 15Z"
      />
      <path
        fill={useColor(color)}
        d="M12.4899 9.48706C12.7656 9.48706 12.9891 9.26357 12.9891 8.98787L12.9891 4.99436L14.7363 4.99436C14.8372 4.99434 14.9281 4.93361 14.9668 4.84044C15.0054 4.74727 14.9842 4.63999 14.913 4.56856C14.908 4.56307 14.9025 4.55857 14.897 4.55358L12.3512 2.15049L12.3347 2.13501C12.1427 1.95496 11.8438 1.95496 11.6518 2.13501L11.6453 2.1405L11.6403 2.14649L9.09446 4.54859L9.07999 4.56257L9.07599 4.56556L9.073 4.56856C9.00233 4.63941 8.98082 4.74562 9.01834 4.83839C9.05586 4.93117 9.14516 4.99256 9.24522 4.99436L10.9924 4.99436L10.9924 8.98787C10.9924 9.26357 11.2159 9.48706 11.4916 9.48706L12.4899 9.48706Z"
      />
    </IconSVGView>
  );
};
