import React, { useLayoutEffect, useState } from 'react';

import {
  StyledBulkActionItem,
  StyledBulkActionItemStatusWrapper,
  StyledBulkActionsList,
} from '@components/common/BulkActionsProgress/styles';
import { BulkActionsProgressProps } from '@components/common/BulkActionsProgress/types';
import { Check, Close } from '@components/common/Icon';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import { ContentChangeTransition } from '@components/common/Transition/ContentChangeTransition';

export const BulkActionsProgress: React.VFC<BulkActionsProgressProps> = ({
  actions,
  visible: propVisible,
  initiallyVisible,
}) => {
  const [visible, setVisible] = useState(!!initiallyVisible);

  useLayoutEffect(() => setVisible(!!propVisible), [propVisible]);

  return (
    <StyledBulkActionsList
      style={{
        opacity: visible ? 1.0 : 0.0,
        height: visible ? 36 * actions.length : 0.0,
      }}
    >
      {actions.map(({ title, status }, index) => (
        <StyledBulkActionItem key={index}>
          <Text
            typography={'Heading3'}
            weight={status === 'success' ? '600' : '400'}
            color={status === 'fail' ? 'contentDanger' : undefined}
          >
            {title}
          </Text>
          <StyledBulkActionItemStatusWrapper>
            <ContentChangeTransition content={status}>
              {(_status) =>
                _status === 'idle' ? (
                  <Text typography={'Heading3'}>...</Text>
                ) : _status === 'loading' ? (
                  <Loading visible size={'24px'} />
                ) : _status === 'fail' ? (
                  <Close color={'contentDanger'} size={24} />
                ) : (
                  <Check color={'contentPositive'} size={24} />
                )
              }
            </ContentChangeTransition>
          </StyledBulkActionItemStatusWrapper>
        </StyledBulkActionItem>
      ))}
    </StyledBulkActionsList>
  );
};
