import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import * as types from '../actionTypes';

/**
 *
 * @returns {{type: string}}
 */
export const makeRecreatePasswordRequestStartAction = () => ({
  type: types.RECREATE_PASSWORD_START,
});

/**
 *
 * @returns {{type: string}}
 */
export const makeRecreatePasswordRequestSuccessAction = () => ({
  type: types.RECREATE_PASSWORD_SUCCESS,
});

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export const makeRecreatePasswordRequestFailureAction = () => ({
  type: types.RECREATE_PASSWORD_FAILURE,
});

export const makeRecreatePasswordRequestThunk = ({ password, passwordConfirmation, uid, token }) => {
  const payload = {
    new_password1: password,
    new_password2: passwordConfirmation,
    uid,
    token,
  };
  const jsonData = JSON.stringify(payload);

  return function (dispatch) {
    // show the spinning screen:
    dispatch(makeRecreatePasswordRequestStartAction());

    return fetch(ENDPOINTS.passwordRecreate, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, same-origin
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
      .then((res) => res.json())
      .then((jsonObj = {}) => {
        if ('errors' in jsonObj) {
          dispatch(makeRecreatePasswordRequestFailureAction());
        } else {
          dispatch(makeRecreatePasswordRequestSuccessAction());
          dispatch(makeShowSnackbarAction('snack_password_changed_msg'));
        }
        return jsonObj;
      });
  };
};
