import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import * as uuid from 'uuid';

import { MapApiProviders } from '@components/common/Map/apis';
import { StyledPolygonChildren, StyledPolygonChildrenWrapper } from '@components/common/Map/components/styles';
import { useMap } from '@components/common/Map/hooks';
import { Path, PolygonProps } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { Children } from '@helpers/Children';
import { useAnimationLoop } from '@hooks/useAnimationLoop';

interface PixelSize {
  width: number;
  height: number;
}
interface PolygonSizeRegistry {
  polygonSize?: PixelSize;
  childrenSize?: PixelSize;
}

/** Stores the size of all currently drawn polygons. */
const POLYGON_SIZE_REGISTRY = {} as Record<string, PolygonSizeRegistry>;

/**
 * Renders a polygon in the map.
 * */
export const Polygon: React.VFC<PolygonProps> = ({
  alwaysShowChildren,
  alwaysShowChildrenIfFits,
  childrenMarkerProps,
  children,
  ...props
}) => {
  const [uid] = useState(() => uuid.v4());
  const [childrenElement, setChildrenElement] = useState<HTMLDivElement | null>(null);

  const { api, instance: mapInstance } = useMap();
  const PolygonRenderer = useMemo(() => MapApiProviders[api].polygonRenderer, [api]);
  const MarkerRenderer = useMemo(() => MapApiProviders[api].markerRenderer, [api]);

  const hasChildren = Children.isNotEmpty(children);
  const childrenCenter = props.path ? MapUtil.getPathCenter(props.path) : null;

  const updateSizeRegistry = useCallback(
    ({ polygonSize, childrenSize }: PolygonSizeRegistry) => {
      POLYGON_SIZE_REGISTRY[uid] = POLYGON_SIZE_REGISTRY[uid] ?? {};
      POLYGON_SIZE_REGISTRY[uid].polygonSize = polygonSize ?? POLYGON_SIZE_REGISTRY[uid].polygonSize;
      POLYGON_SIZE_REGISTRY[uid].childrenSize = childrenSize ?? POLYGON_SIZE_REGISTRY[uid].childrenSize;
    },
    [uid]
  );

  const getPolygonPixelSize = useCallback(
    (path: Path | null | undefined): PixelSize | undefined => {
      if (mapInstance && path) {
        const pathBounds = MapUtil.getPathBounds(path);
        if (pathBounds) {
          const { west, south, east, north } = pathBounds;
          const bottomLeft = mapInstance.coordinateToPixel({ lat: south, lng: west });
          const topRight = mapInstance.coordinateToPixel({ lat: north, lng: east });
          if (bottomLeft && topRight) {
            return {
              width: topRight.x - bottomLeft.x,
              height: bottomLeft.y - topRight.y,
            };
          }
        }
      }
      return undefined;
    },
    [mapInstance]
  );

  const mayRenderChildren = useCallback(() => {
    const registry = POLYGON_SIZE_REGISTRY[uid];

    if (!registry || !registry.childrenSize || !registry.polygonSize) {
      return false;
    } else if (alwaysShowChildren) {
      return true;
    }

    const { polygonSize, childrenSize } = registry;
    let polygonWidthMin;
    let polygonHeightMin;
    let childrenWidthMax;
    let childrenHeightMax;

    if (alwaysShowChildrenIfFits) {
      // Taking into account only current polygon and children sizes.
      polygonWidthMin = polygonSize.width;
      polygonHeightMin = polygonSize.height;
      childrenWidthMax = childrenSize.width;
      childrenHeightMax = childrenSize.height;
    } else {
      // Taking into account all current polygons and its children.
      const polygonSizes = Object.values(POLYGON_SIZE_REGISTRY)
        .map((p) => p.polygonSize)
        .filter((size) => !!size) as PixelSize[];
      const childrenSizes = Object.values(POLYGON_SIZE_REGISTRY)
        .map((p) => p.childrenSize)
        .filter((size) => !!size) as PixelSize[];

      polygonWidthMin = Math.min(...polygonSizes.map((p) => p.width));
      polygonHeightMin = Math.min(...polygonSizes.map((p) => p.height));
      childrenWidthMax = Math.max(...childrenSizes.map((p) => p.width));
      childrenHeightMax = Math.max(...childrenSizes.map((p) => p.height));
    }

    const offsetWidth = polygonWidthMin / childrenWidthMax;
    const offsetHeight = polygonHeightMin / childrenHeightMax;
    const offsetScale = Math.min(offsetWidth, offsetHeight);

    // Bellow hardcoded value is adjusted from design decision.
    // It affects when the text is hidden based on the zoom level.
    return offsetScale >= 1.15;
  }, [alwaysShowChildren, alwaysShowChildrenIfFits, uid]);

  const animationHandler = useMemo(() => {
    if (hasChildren) {
      return () => {
        updateSizeRegistry({ polygonSize: getPolygonPixelSize(props.path) });
        if (childrenElement) {
          childrenElement.style.opacity = mayRenderChildren() ? '1.0' : '0.0';
        }
      };
    }

    // If no children, return null to stop the animation.
    return null;
  }, [childrenElement, getPolygonPixelSize, hasChildren, mayRenderChildren, props.path, updateSizeRegistry]);

  // Possible performance bottleneck.
  // Sets the current polygon children appearance
  // depending on the zoom level.
  useAnimationLoop(animationHandler);

  useLayoutEffect(() => {
    if (childrenElement) {
      const handler = ({ width, height }: any) => updateSizeRegistry({ childrenSize: { width, height } });
      handler(childrenElement.getBoundingClientRect());
      const observer = new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
        requestAnimationFrame(() => {
          entries.forEach(({ contentRect: { width, height } }) => {
            if (width && height) {
              handler({ width, height });
            }
          });
        });
      });
      observer.observe(childrenElement);
      return () => {
        observer.unobserve(childrenElement);
      };
    }
  }, [uid, updateSizeRegistry, children, childrenElement]);

  useLayoutEffect(
    () => () => {
      delete POLYGON_SIZE_REGISTRY[uid];
    },
    [uid]
  );

  return (
    <>
      <PolygonRenderer {...props} />
      {hasChildren && (
        <MarkerRenderer {...childrenMarkerProps} position={childrenCenter}>
          <StyledPolygonChildrenWrapper>
            <StyledPolygonChildren ref={setChildrenElement}>{children}</StyledPolygonChildren>
          </StyledPolygonChildrenWrapper>
        </MarkerRenderer>
      )}
    </>
  );
};
