import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { AnalyticsLoader } from '@components/common/AnalyticsLoader';
import { Loading } from '@components/common/Loading';
import { Page404 } from '@components/common/Page404';
import { Snackbar } from '@components/common/Snackbar';
import { useGlobalFormConfig } from '@components/form/core/Form/hooks';
import APP from '@config/constants';
import { Globals } from '@helpers/Globals';
import { useGlobalProperty } from '@helpers/Globals/hooks';
import { GlobalsUtil } from '@helpers/Globals/util';
import { useIntercomInitializer } from '@helpers/Intercom/hooks';
import { QueryParams } from '@helpers/QueryParams';
import { URLUtil } from '@helpers/URL';
import { useAuthInitialChecks } from '@redux/Auth/hooks';
import { Admin } from '@scenes/admin';
import { AuthPage } from '@scenes/auth/AuthPage';
import AppStore from '@scenes/public/AppStore';

const App = () => {
  useAuthInitialChecks();
  useIntercomInitializer();
  useGlobalFormConfig();

  const history = useMemo(() => createBrowserHistory(), []);

  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = !!user;

  const operationId = useGlobalProperty('operationId');
  const hasChosenAnOperation = !!operationId;

  const isFetching = useSelector((state) => {
    const isChangingPassword = state.changePasswordReducer.isFetching;
    const isFetchingUserForTheFirstTime = !state.authReducer.user && state.authReducer.isFetchingUser;
    const isSigningOut = state.authReducer.isFetchingSignOut;
    return isChangingPassword || isFetchingUserForTheFirstTime || isSigningOut;
  });

  const getNextPath = useCallback((location: RouteComponentProps['location']) => {
    const existingNextPath = QueryParams.getCurrentQueryParams().next;
    const existingDecodedNextPath = existingNextPath ? decodeURIComponent(existingNextPath) : null;
    const currentPath = !['/', APP.routes.home, APP.routes.login, APP.routes.selectOperation].includes(
      location.pathname
    )
      ? location.pathname
      : null;
    return existingDecodedNextPath || currentPath;
  }, []);

  const replacePath = useCallback((history: RouteComponentProps['history'], path) => {
    setTimeout(() => history.replace(path));
  }, []);

  const maybeRedirectToLogin = useCallback(
    ({ location, history }: RouteComponentProps, render: () => any) => {
      const isAtLogin = location.pathname === APP.routes.login;

      // If the user is NOT authenticated, it
      // will auto redirect to the login page.
      if (!isFetching && !isAuthenticated && !isAtLogin) {
        const next = getNextPath(location);
        replacePath(history, URLUtil.buildPagePath(APP.routes.login, { queryParams: { next } }));
      }

      return render();
    },
    [getNextPath, isAuthenticated, isFetching, replacePath]
  );

  const maybeRedirectToOperationSelection = useCallback(
    ({ location, history }: RouteComponentProps, render: () => any) => {
      const isAtSelectOperation = location.pathname === APP.routes.selectOperation;

      // If the user did NOT select an operation,
      // redirect to the selection page.
      if (!isFetching && !hasChosenAnOperation && isAuthenticated && !isAtSelectOperation) {
        const next = getNextPath(location);
        replacePath(history, URLUtil.buildPagePath(APP.routes.selectOperation, { queryParams: { next } }));
        return null;
      }

      return render();
    },
    [getNextPath, hasChosenAnOperation, isAuthenticated, isFetching, replacePath]
  );

  const maybeRedirectToHome = useCallback(
    ({ location, history }: RouteComponentProps, render: () => any) => {
      const isSwitchingOperation = QueryParams.getCurrentQueryParams().switching === 'true';
      const isAtLogin = location.pathname === APP.routes.login;
      const isAtSelectOperation = location.pathname === APP.routes.selectOperation;

      // If the user is authenticated AND has chosen an
      // operation, it will auto redirect to the home page.
      if (
        !isFetching &&
        !isSwitchingOperation &&
        isAuthenticated &&
        hasChosenAnOperation &&
        (isAtLogin || isAtSelectOperation)
      ) {
        const next = decodeURIComponent(QueryParams.getCurrentQueryParams().next) || APP.routes.home;
        replacePath(history, next);
        return null;
      }

      return render();
    },
    [hasChosenAnOperation, isAuthenticated, isFetching, replacePath]
  );

  const maybeRedirectToAnotherOperation = useCallback(
    ({ history }: RouteComponentProps, render: () => any) => {
      const operationIdFromPathname = GlobalsUtil.getOperationIdFromPathname();
      const hasToUpdatePathname = !operationIdFromPathname || GlobalsUtil.getOperationId() !== Globals.operationId;

      // If the user changed the operation, redirect to the respective admin.
      if (!isFetching && hasToUpdatePathname && isAuthenticated) {
        const isDetailsPath = history.location.pathname.includes('detail');
        const backwardCompatiblePath = `/:operation${history.location.pathname}`;
        const lackedOperationId = Object.values(APP.routes).includes(backwardCompatiblePath);

        // Case where the user is using an old path (e.g. from bookmarks bar)
        // so we redirect to a backward compatible path.
        if (lackedOperationId) {
          replacePath(history, URLUtil.buildPagePath(backwardCompatiblePath));
        }
        // Case where the user is NOT in a 'details' path, so it can safely redirect.
        else if (!isDetailsPath) {
          replacePath(history, URLUtil.replaceOperationIdInPath(history.location.pathname));
        }
        // Case where the user was in a 'details' path, so we fall back to the home page.
        else {
          replacePath(history, URLUtil.buildPagePath(APP.routes.home));
        }
        return null;
      }

      return render();
    },
    [isAuthenticated, isFetching, replacePath]
  );

  const composeRedirects = useCallback((Component: any, ...redirects: Array<typeof maybeRedirectToHome>) => {
    return function Screen(props: RouteComponentProps): any {
      return redirects.length === 0 ? (
        <Component />
      ) : (
        redirects[0](props, () => composeRedirects(Component, ...redirects.slice(1))(props))
      );
    };
  }, []);

  return (
    <AnalyticsLoader>
      <Router key={operationId} history={history}>
        <Switch>
          <Route path={APP.routes.appStore} component={AppStore} />

          <Route
            path={APP.routes.login}
            render={composeRedirects(AuthPage, maybeRedirectToOperationSelection, maybeRedirectToHome)}
          />
          <Route
            path={APP.routes.selectOperation}
            render={composeRedirects(AuthPage, maybeRedirectToLogin, maybeRedirectToHome)}
          />
          <Route
            path={[APP.routes.passwordForgotten, APP.routes.passwordForgottenSuccess, APP.routes.passwordCreate]}
            component={AuthPage}
          />

          <Route
            path={[
              ...APP.mainRedirects.flatMap(({ from }) => from),
              APP.routes.whiteboardYardsList,
              APP.routes.whiteboardYardsMap,
              APP.routes.whiteboardYardsMapManagement,
              APP.routes.whiteboardYardsDetailDashboard,
              APP.routes.whiteboardYardsDetailHives,
              APP.routes.whiteboardYardsDetailDeadouts,
              APP.routes.whiteboardYardsDetailActivities,
              APP.routes.whiteboardActivities,

              APP.routes.pollination,
              APP.routes.pollinationContractsOngoingList,
              APP.routes.pollinationContractsArchivedList,
              APP.routes.pollinationContractsDetails,
              APP.routes.pollinationContractsDetailsInformation,
              APP.routes.pollinationContractsDetailsDrops,
              APP.routes.pollinationContractsDetailsPollinationMap,
              APP.routes.pollinationContractsDetailsPDF,

              APP.routes.settings,
              APP.routes.operation,
              APP.routes.alerts,
              APP.routes.groups,
              APP.routes.lostHives,
              APP.routes.reports,
            ]}
            render={composeRedirects(
              Admin,
              maybeRedirectToLogin,
              maybeRedirectToOperationSelection,
              maybeRedirectToAnotherOperation
            )}
          />

          <Route component={Page404} />
        </Switch>
        <Snackbar />
      </Router>
      <Loading defaultBackground visible={isFetching} />
    </AnalyticsLoader>
  );
};

export default App;
