import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { Color, ColorGlobal } from 'styled-components';

import { Box } from '@components/common/Box';
import { DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { Text } from '@components/common/Text';

export const GradingRoundedSquare = styled(Box)<{ $color: ColorGlobal }>(
  ({ theme, $color }) => `
      min-width: 24px;
      height: 24px;
      border-radius: ${theme.shape.paperRadius01}px;
      border: ${theme.colors[$color]} 1px solid;
      padding: 0 ${theme.spacing._025};
      margin-right: ${theme.spacing._050};
      
      &:not(:first-child) {
        margin-left: ${theme.spacing._100};
      }
    `
);

export const Grading: React.FC<{
  data: { nbStrongHives: number; nbMediumHives: number; nbWeakHives: number } | null;
}> = ({ data }) => {
  const { t } = useTranslation();
  return data ? (
    <DefaultCellWrapper>
      <GradingRoundedSquare center $color={'borderPositive'}>
        <Text typography={'CaptionSmall'}>{data.nbStrongHives ?? 0}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('strong')}</Text>

      <GradingRoundedSquare center $color={'borderWarning'}>
        <Text typography={'CaptionSmall'}>{data.nbMediumHives ?? 0}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('medium')}</Text>

      <GradingRoundedSquare center $color={'borderDanger'}>
        <Text typography={'CaptionSmall'}>{data.nbWeakHives ?? 0}</Text>
      </GradingRoundedSquare>
      <Text typography={'SmallParagraph'}>{t('weak')}</Text>
    </DefaultCellWrapper>
  ) : null;
};
