import React, { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { groupBy } from 'lodash';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Box } from '@components/common/Box';
import { Check, IconIMG } from '@components/common/Icon';
import { ChevronRight } from '@components/common/Icon/presets/ChevronRight';
import { Tooltip } from '@components/common/Tooltip';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';

import { ModalHeader } from '../ModalBase';
import { ModalMeerkat } from '../ModalMeerkat';
import { ResponsiveRender } from '../ResponsiveRender';

import { MenuItemTextView, StyledMenuItemGroupView, StyledMenuItemView, StyledMenuView } from './styles';
import { MenuItem, MenuProps, SubMenuProps } from './types';

const MenuItemButton: React.VFC<{ item: MenuItem; subMenuProps?: SubMenuProps; closeMenu?: () => void }> = ({
  item,
  subMenuProps,
  closeMenu,
}) => {
  const { isMobile } = useGetScreenWidth();
  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, item: MenuItem) => {
      event.preventDefault();
      event.stopPropagation();
      item.onClick && item.onClick(event);
      closeMenu && closeMenu();
    },
    [closeMenu]
  );

  const renderIcon = useCallback((item: MenuItem) => {
    if (item.icon) {
      if (typeof item.icon === 'string') {
        return <IconIMG size={18} src={item.icon} />;
      }

      return <item.icon size={18} />;
    }
    return null;
  }, []);

  const uniqueId = useMemo(() => `${item.title}-submenu-${uuid()}`, [item.title]);

  return (
    <StyledMenuItemView
      disabled={item.disabled}
      onClick={(event) => handleMenuClick(event, item)}
      $danger={item.danger}
      id={uniqueId}
    >
      {renderIcon(item)}
      <MenuItemTextView typography={'Heading3'} weight={'600'}>
        {item.title}
      </MenuItemTextView>

      {item.checked ? (
        <Box marginLeft_025>
          <Check size={16} />
        </Box>
      ) : null}

      {item.subItems?.length ? (
        <>
          <Box marginLeft_025>
            <ChevronRight size={16} />
          </Box>
          <Menu
            trigger={'mouseenter focus click'}
            items={item.subItems}
            target={isMobile ? uniqueId : ''}
            mobileTitle={subMenuProps?.mobileTitle || item.title}
            {...subMenuProps}
          />
        </>
      ) : null}
    </StyledMenuItemView>
  );
};

export const Menu: React.FC<MenuProps> = ({ items, mobileTitle, placement = 'bottom', subMenuProps, ...props }) => {
  const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);

  const modalMenu = useRef<HTMLDivElement>(null);
  const modalMenuTrigger = useRef<HTMLButtonElement | null>(null);

  const t = useTranslation();

  const groupedItems = useMemo(() => {
    const groupsKeys = new Set(items.map((item) => item.group ?? ''));
    const groups = groupBy(items, (item) => item.group ?? '');
    return Array.from(groupsKeys).map((key) => groups[key]);
  }, [items]);

  const renderContent = useCallback((children: ReactNode) => children, []);

  const closeModalMenu = useCallback(() => {
    setIsModalMenuOpen(false);
  }, []);

  useEffect(() => {
    if (props.target) {
      modalMenuTrigger.current = document.getElementById(props.target) as HTMLButtonElement;
    }

    if (modalMenuTrigger.current) {
      modalMenuTrigger.current.onclick = (e) => {
        e.stopPropagation();
        setIsModalMenuOpen(true);
      };
    }
  }, [props.target]);

  return (
    <>
      <ResponsiveRender until="mobile">
        <ModalMeerkat ref={modalMenu} isOpen={isModalMenuOpen} onRequestClose={closeModalMenu}>
          <ModalHeader mobileTitle={mobileTitle || t('more_options')} />
          <StyledMenuView>
            {groupedItems.map((items, index) => (
              <StyledMenuItemGroupView key={index}>
                {(items || []).map((item, key) =>
                  item.render ? (
                    <React.Fragment key={key}> {item.render()} </React.Fragment>
                  ) : (
                    <MenuItemButton item={item} key={key} subMenuProps={subMenuProps} closeMenu={closeModalMenu} />
                  )
                )}
              </StyledMenuItemGroupView>
            ))}
          </StyledMenuView>
        </ModalMeerkat>
      </ResponsiveRender>

      <ResponsiveRender from="tablet">
        <Tooltip interactive hideOnClick placement={placement} renderContent={renderContent} {...props}>
          <StyledMenuView>
            {groupedItems.map((items, index) => (
              <StyledMenuItemGroupView key={index}>
                {(items || []).map((item, key) => (
                  <MenuItemButton item={item} key={key} subMenuProps={subMenuProps} />
                ))}
              </StyledMenuItemGroupView>
            ))}
          </StyledMenuView>
        </Tooltip>
      </ResponsiveRender>
    </>
  );
};
