import { LastInspectionIntervalConfig, LastInspectionIntervalKey } from './types';

export const LastInspectionsConfig = {
  INTERVALS_SPECS: {
    between_91_inf: {
      key: LastInspectionIntervalKey.BETWEEN_91_INF,
      labelKey: 'last_visit_range_upper_limit',
      tooltipLabelKey: 'last_visit_range_upper_limit_tooltip',
      interval: [91, Infinity],
      color: 'surfaceTertiary',
      priority: 0,
      order: 3,
    },
    between_31_90: {
      key: LastInspectionIntervalKey.BETWEEN_31_90,
      labelKey: 'last_visit_range',
      tooltipLabelKey: 'last_visit_range_tooltip',
      interval: [31, 90],
      color: 'surfaceDanger',
      priority: 3,
      order: 2,
    },
    between_16_30: {
      key: LastInspectionIntervalKey.BETWEEN_16_30,
      labelKey: 'last_visit_range',
      tooltipLabelKey: 'last_visit_range_tooltip',
      interval: [16, 30],
      color: 'surfaceWarning',
      priority: 2,
      order: 1,
    },
    between_00_15: {
      key: LastInspectionIntervalKey.BETWEEN_00_15,
      labelKey: 'last_visit_range',
      tooltipLabelKey: 'last_visit_range_tooltip',
      interval: [0, 15],
      color: 'surfacePositive',
      priority: 1,
      order: 0,
    },
  } as Record<LastInspectionIntervalKey, LastInspectionIntervalConfig>,
};
