import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@components/common/Box';
import { Card, CardContent, CardContentRow, CardHeader } from '@components/common/Card';
import { NotificationBell } from '@components/common/Icon';
import { PageContentHeader, PageContentInfo, PageContentSlim, PageContentTitle } from '@components/common/PageContent';
import { Text } from '@components/common/Text';
import { Toggle } from '@components/form/inputs/Toggle';
import { useEnsuredUser } from '@redux/Auth/hooks';
import { makePreferencesPatchMembershipThunk } from '@redux/Preferences/actions';

import { NotificationRow } from './NotificationRow';
import { StyledGridRow, StyledVisibleBox } from './styles';

const shouldEnableAll = (preferences: Partial<BeeMembershipPreferences>): boolean => {
  let shouldEnable = false;
  for (const alert of Object.values(preferences)) {
    if (alert === true) {
      shouldEnable = true;
      break;
    }
  }
  return shouldEnable;
};

const TopRow = () => {
  const { t } = useTranslation();
  return (
    <StyledGridRow>
      <div>
        <Text typography="SmallParagraph" weight={'600'}>
          {t('settings')}
        </Text>
      </div>

      <Text typography="SmallParagraph" weight={'600'} style={{ textAlign: 'center' }}>
        <span>{t('email')}</span>
      </Text>
      <Text typography="SmallParagraph" weight={'600'} style={{ textAlign: 'center' }}>
        <span>{t('sms')}</span>
      </Text>
    </StyledGridRow>
  );
};

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const user = useEnsuredUser();
  const dispatch = useDispatch();

  const [notificationsState, setNotificationsState] = useState<Partial<BeeMembershipPreferences>>(() => {
    const alertSettings: Array<keyof BeeMembershipPreferences> = [
      'alertsNewYardCreatedSms',
      'alertsNewYardCreatedMail',
      'alertsHiveMovedSms',
      'alertsHiveMovedMail',
      'alertsHiveStatusSms',
      'alertsHiveStatusMail',
      'alertsDailyReportSms',
      'alertsDailyReportMail',
    ];
    return alertSettings.reduce(
      (pref, key) => ({ ...pref, [key]: Boolean(user.membershipPreferences[key]) }),
      {} as Partial<BeeMembershipPreferences>
    );
  });

  const enableAll = useMemo(() => shouldEnableAll(notificationsState), [notificationsState]);

  const updateAllAlerts = (value: boolean) => {
    const newState = {} as BeeMembershipPreferences;

    Object.keys(notificationsState).forEach((key) => ((newState as any)[key] = value));

    setNotificationsState(newState);
    dispatch(makePreferencesPatchMembershipThunk(newState));
  };

  const updateSingleAlert = (value: boolean, checkboxName: string) => {
    const newState = { ...notificationsState, [checkboxName]: value };
    setNotificationsState(newState);
    dispatch(makePreferencesPatchMembershipThunk(newState));
  };

  return (
    <PageContentSlim>
      <PageContentHeader>
        <PageContentTitle>{t('beetrack_alerts')}</PageContentTitle>
        <PageContentInfo dangerouslySetInnerHTML={{ __html: t('beetrack_alerts_desc_1') }} />
        <PageContentInfo
          dangerouslySetInnerHTML={{
            __html: t('beetrack_alerts_desc_2', { name: user.currentMembership?.operation.name ?? '-' }),
          }}
        />
      </PageContentHeader>
      <Card>
        <CardHeader>
          <Text typography="SmallParagraph" weight={'600'}>
            <span style={{ paddingRight: '10px' }}>
              <NotificationBell />
            </span>
            {t('Notifications')}
          </Text>
          <Box>
            <StyledVisibleBox marginRight_050 isVisible={!enableAll}>
              <Text typography="SmallParagraph" weight={'600'}>
                {t('off')}
              </Text>
            </StyledVisibleBox>
            <Toggle id="toggleAll" name="toggleAll" value={enableAll} onChange={(value) => updateAllAlerts(value)} />

            <StyledVisibleBox marginLeft_050 isVisible={enableAll}>
              <Text typography="SmallParagraph" weight={'600'}>
                {t('on')}
              </Text>
            </StyledVisibleBox>
          </Box>
        </CardHeader>
        <CardContent>
          <CardContentRow>
            <TopRow key="heading" />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_new_yard"
              label="alert_label_new_yard"
              idMail="alertsNewYardCreatedMail"
              idSms="alertsNewYardCreatedSms"
              valueMail={notificationsState['alertsNewYardCreatedMail']}
              valueSms={notificationsState['alertsNewYardCreatedSms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_hive_moved"
              label="alert_label_hive_moved"
              idMail="alertsHiveMovedMail"
              idSms="alertsHiveMovedSms"
              valueMail={notificationsState['alertsHiveMovedMail']}
              valueSms={notificationsState['alertsHiveMovedSms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alert_label_hive_status"
              label="alert_label_hive_status"
              idMail="alertsHiveStatusMail"
              idSms="alertsHiveStatusSms"
              valueMail={notificationsState['alertsHiveStatusMail']}
              valueSms={notificationsState['alertsHiveStatusSms']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
          <CardContentRow>
            <NotificationRow
              key="alerts_label_daily_report_mail"
              label="alerts_label_daily_report_mail"
              idMail="alertsDailyReportMail"
              valueMail={notificationsState['alertsDailyReportMail']}
              onChange={updateSingleAlert}
            />
          </CardContentRow>
        </CardContent>
      </Card>
    </PageContentSlim>
  );
};
