import React from 'react';
import { useSelector } from 'react-redux';

import { GridApiProvider } from '@components/common/AgGrid';
import {
  DynamicTableEmpty,
  DynamicTableEmptyHeader,
  DynamicTableEmptyText,
} from '@components/common/DynamicTable/DynamicTableEmpty';
import { useDynamicTableStates } from '@components/common/DynamicTable/hooks';
import { PageContent, PageContentHeader, PageContentTitle } from '@components/common/PageContent';
import { useTranslation } from '@hooks/useTranslation';

import { HIVE_COL_DEFS, HIVE_COLS_KEYS, HIVE_PERMANENT_RIGHT_COL_DEFS } from './constants';
import { HivesList } from './HivesList';

export const HivesListEmpty: React.VFC = () => {
  const { hasLoaded, isListEmpty } = useDynamicTableStates();
  const { detail } = useSelector((state) => state.yardDetailsReducer);
  const t = useTranslation();
  const showEmptyList = hasLoaded && isListEmpty;
  return (
    <DynamicTableEmpty visible={showEmptyList} backgroundColor="surfaceDefault">
      <DynamicTableEmptyHeader>{t('no_hives')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText>{t('no_hives_msg', { yard: detail?.name })}</DynamicTableEmptyText>
    </DynamicTableEmpty>
  );
};

export const HivesListPage: React.VFC = () => {
  const t = useTranslation();
  const { detail } = useSelector((state) => state.yardDetailsReducer);
  return (
    <PageContent>
      <PageContentHeader>
        <PageContentTitle>{t('Hives')}</PageContentTitle>
      </PageContentHeader>

      <GridApiProvider>
        <HivesList
          columnDefinitions={HIVE_COL_DEFS}
          apiParams={{ yard_id: detail?.id, hive_status: 'alive', columns: HIVE_COLS_KEYS }}
          config={{
            permanentRightColumns: HIVE_PERMANENT_RIGHT_COL_DEFS,
            defaultSorting: [{ field: 'status', sort: 'asc' }],
          }}
        />
        <HivesListEmpty />
      </GridApiProvider>
    </PageContent>
  );
};
