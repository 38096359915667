import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import {
  ContractMetric,
  ContractMetricName,
  ContractsMetricsAction,
  ContractsMetricsActionType,
} from '@redux/ContractsMetrics/types';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

export const makeContractsMetricsFetchStart = (metricNames: Array<ContractMetricName>): ContractsMetricsAction => ({
  type: ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_START,
  payload: { metricNames },
});

export const makeContractsMetricsFetchFinish = (metrics: Array<ContractMetric>): ContractsMetricsAction => ({
  type: ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_FINISH,
  payload: {
    metrics,
  },
});

export const makeContractMetricsFetchThunk = Thunk.createTakeFirst((metricNames: Array<ContractMetricName>) => {
  return async (dispatch) => {
    dispatch(makeContractsMetricsFetchStart(metricNames));

    const response = await Api.get(
      URLUtil.buildURL(ENDPOINTS.contractMetrics, {
        metric_name: metricNames.map((m) => CaseAdapter.toSnakeCase(m)),
      })
    );

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      const metrics = parseMetricFromApi(await response.json());
      dispatch(makeContractsMetricsFetchFinish(metrics));
    }
  };
});

function parseMetricFromApi(data: any): Array<ContractMetric> {
  data = CaseAdapter.objectToCamelCase(data['results']);
  return data.map((metric: any) => {
    const current = metric.value.find(({ name }: any) => name == 'current');
    const previous = metric.value.find(({ name }: any) => name == 'previous');
    return {
      name: CaseAdapter.toCamelCase(metric.name),
      current,
      previous,
    };
  });
}
