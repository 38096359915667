import React from 'react';
import { useTheme } from 'styled-components';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const AccessPin = ({ size }: IconSVGProps) => {
  const { colors } = useTheme();
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <rect fill={colors.surfacePrimary} width="16" height="16" rx="8" />
      <path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45853 4.96363L11.0348 7.54428C11.157 7.66469 11.2258 7.82912 11.2258 8.00073C11.2258 8.17233 11.157 8.33677 11.0348 8.45718L8.45853 11.0378C8.33757 11.159 8.17344 11.227 8.00228 11.227C7.83113 11.227 7.66699 11.159 7.54604 11.0378L4.96333 8.45718C4.84224 8.33617 4.7742 8.17196 4.7742 8.00073C4.7742 7.8295 4.84224 7.66529 4.96333 7.54428L7.54604 4.96363C7.66699 4.84248 7.83113 4.77441 8.00228 4.77441C8.17344 4.77441 8.33757 4.84248 8.45853 4.96363ZM8.7616 8.52807L9.49998 7.78936C9.52973 7.75912 9.54613 7.71821 9.54552 7.67579C9.54492 7.63336 9.52735 7.59294 9.49676 7.56356L8.75838 6.82485C8.71232 6.77967 8.64378 6.7663 8.58413 6.79087C8.52448 6.81545 8.48522 6.87322 8.48431 6.93775V7.35388H7.03335C6.85528 7.35388 6.71092 7.4983 6.71092 7.67646V8.6442C6.71092 8.82236 6.85528 8.96678 7.03335 8.96678C7.21143 8.96678 7.35579 8.82236 7.35579 8.6442V7.99904H8.48753V8.41517C8.48844 8.4797 8.52771 8.53747 8.58735 8.56205C8.647 8.58662 8.71554 8.57325 8.7616 8.52807Z"
      />
    </IconSVGView>
  );
};
