import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Fuse from 'fuse.js';
import * as uuid from 'uuid';

import { Box } from '@components/common/Box';
import { BoxRef } from '@components/common/Box/types';
import { Button } from '@components/common/CTA';
import { Close } from '@components/common/Icon';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { InputAutocompleteContext } from '@components/form/inputs/InputAutocomplete/context';
import { InputAutocompleteDropdownDesktop } from '@components/form/inputs/InputAutocomplete/InputAutocompleteDropdownDesktop';
import { InputAutocompleteDropdownMobile } from '@components/form/inputs/InputAutocomplete/InputAutocompleteDropdownMobile';
import {
  StyledDropdownFooter,
  StyledEmptyFilter,
  StyledInputWrapper,
  StyledOptionChip,
  StyledOptionChipContainer,
  StyledOptionChipContainerWrapper,
  StyledOptionContainer,
} from '@components/form/inputs/InputAutocomplete/styles';
import {
  InputAutocompleteContextValue,
  InputAutocompleteDropdownRef,
  InputAutocompleteProps,
  OptionRegistry,
} from '@components/form/inputs/InputAutocomplete/types';
import { InputText } from '@components/form/inputs/InputText';
import { Sorting } from '@helpers/Sorting';
import { useDebounce } from '@helpers/Throttling/hooks';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useKeyboardNavigation } from '@hooks/useKeyboardNavigation';
import { useTranslation } from '@hooks/useTranslation';

const MIN_OPTIONS_TO_SHOW_MOBILE_FILTER = 12;
const ADJUST_KEYBOARD_NAVIGATION_DELAY = 80;
const FILTERING_DEBOUNCE = 220;
const FILTERING_IGNORABLE_KEYS = ['Tab', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'Shift'];

export const InputAutocomplete: React.FC<InputAutocompleteProps> = (props) => {
  const {
    id,
    isMultiple,
    name,
    label,
    disabled,
    readOnly,
    placeholder,
    hintText,
    innerLeftContent,
    children,
    ...boxProps
  } = props;
  const innerInputOptions = { label, disabled, readOnly, placeholder, hintText, innerLeftContent };

  const t = useTranslation();
  const { isDesktop } = useGetScreenWidth();

  // Form which uses this input.
  const outerForm = useFormContext();

  // Auxiliary internal form.
  const innerForm = useForm();

  const dropdownRef = useRef<InputAutocompleteDropdownRef>(null);
  const filterInputWrapperRef = useRef<BoxRef<'div'>>(null);
  const optionsWrapperRef = useRef<BoxRef<'div'>>(null);
  const filteringDebounce = useDebounce(FILTERING_DEBOUNCE);

  // Becomes true after the user deletes all the filter input content.
  // If this is true while the dropdown is closing, the outer value is
  // set to null. This is only considered for single valued autocomplete.
  const wasFilterCleared = useRef(false);

  const uid = useMemo(() => uuid.v4().replace(/-/, '_'), []);
  const dropdownAnchorId = id ?? `dropdown_anchor_${uid}`;
  const innerFilterInputId = `innerFilterInput_${uid}`;
  const innerFilterInputName = `innerFilterInput_${uid}`;

  const outerVal = outerForm.watch(name);
  const filterVal = innerForm.watch(innerFilterInputName);

  const [options, setOptions] = useState<Record<string, OptionRegistry>>({});

  const filterValClean = (filterVal ?? '').trim();
  const hasFilter = !!filterValClean;
  const optionsCount = Object.values(options).length;
  const optionsVisibleCount = useMemo(() => Object.values(options).filter(({ visible }) => visible), [options]).length;
  const isFilteringEmpty = !!optionsCount && !optionsVisibleCount;
  const showMobileOptionsFilter = optionsCount >= MIN_OPTIONS_TO_SHOW_MOBILE_FILTER;

  const closeDropdown = useCallback(() => {
    setTimeout(() => dropdownRef.current?.hide());
  }, []);

  const keyboardNavigation = useKeyboardNavigation({
    elements: [filterInputWrapperRef, optionsWrapperRef],
    onEscape: () => {
      closeDropdown();
      focusInput();
    },
  });

  const focusInput = useCallback(() => {
    if (isDesktop) {
      keyboardNavigation.goToIndex(0);
    }
  }, [isDesktop, keyboardNavigation]);

  const optionsFilterIndex = useMemo(() => {
    const optionsList = Object.entries(options).map(([value, { label }]) => ({ value, label }));
    return new Fuse(optionsList, {
      keys: ['label'],
      threshold: 0.2,
      ignoreLocation: true,
    });
  }, [options]);

  const optionsSorted = useMemo(
    () =>
      Sorting.sortAlphanumerically(
        Object.entries(options).map(([, op]) => op),
        'index'
      ),
    [options]
  );

  const outerValAsObject = useMemo((): Record<string, boolean> => {
    if ([null, undefined].includes(outerVal)) return {};
    const normalizedVal: Array<any> = isMultiple ? outerVal : [outerVal];
    return normalizedVal.reduce((acc, val) => ({ ...acc, [val]: true }), {});
  }, [isMultiple, outerVal]);

  const outerValAsLabel = useMemo(() => {
    return Object.entries(outerValAsObject)
      .filter(([val, selected]) => options[val] && selected)
      .map(([val]) => options[val].label)
      .join(', ');
  }, [options, outerValAsObject]);

  const outerValLength = useMemo(() => {
    return Object.values(outerValAsObject).length;
  }, [outerValAsObject]);

  const setOuterFormVal = useCallback(
    (next: Record<string, boolean>) => {
      const valList = Object.entries(next)
        .filter(([, selected]) => selected)
        .map(([val]) => options[val].value);
      const val = isMultiple ? valList : valList[0] ?? null;
      outerForm.setValue(name, val);
    },
    [isMultiple, name, options, outerForm]
  );

  const setInnerFilterVal = useCallback(
    (next: string) => {
      innerForm.setValue(innerFilterInputName, next);
    },
    [innerFilterInputName, innerForm]
  );

  const registerOption = useCallback((value: any, label: string) => {
    setOptions((curr) => ({
      ...curr,
      [value]: {
        ...curr[value],
        value,
        label,
        visible: curr[value]?.visible ?? true,
        index: Object.entries(curr).length,
      },
    }));
    return () => {
      setOptions((curr) => {
        delete curr[value];
        return curr;
      });
    };
  }, []);

  const toggleOption = useCallback(
    (value: any) => {
      if (isMultiple) {
        if (isDesktop) {
          // The multiselect adds a chip for each selected options,
          // so we have to compensate the selected index.
          setTimeout(() => {
            const togglingOn = !outerValAsObject[value];
            togglingOn ? keyboardNavigation.goToNextIndex() : keyboardNavigation.goToPreviousIndex();
          }, ADJUST_KEYBOARD_NAVIGATION_DELAY);
        }

        return setOuterFormVal({ ...outerValAsObject, [value]: !outerValAsObject[value] });
      }

      focusInput();
      closeDropdown();
      return setOuterFormVal({ [value]: true });
    },
    [isMultiple, focusInput, closeDropdown, setOuterFormVal, outerValAsObject, isDesktop, keyboardNavigation]
  );

  const applyFiltering = useCallback(() => {
    const filteredOptions = optionsFilterIndex
      .search(filterValClean)
      .map(({ item }) => item.value)
      .reduce((acc, val) => ({ ...acc, [val]: true }), {} as Record<any, boolean>);
    setOptions(({ ...curr }) => {
      for (const [val, option] of Object.entries(curr)) {
        curr[val] = { ...option, visible: filteredOptions[val] || !hasFilter };
      }
      return curr;
    });
  }, [filterValClean, hasFilter, optionsFilterIndex]);

  const unapplyFiltering = useCallback(() => {
    setOptions(({ ...curr }) => {
      for (const [val, option] of Object.entries(curr)) {
        curr[val] = { ...option, visible: true };
      }
      return curr;
    });
  }, []);

  const clearOuterValue = useCallback(() => {
    setOuterFormVal({});
    if (!isMultiple) {
      closeDropdown();
    }
  }, [closeDropdown, isMultiple, setOuterFormVal]);

  const onDropdownShown = useCallback(() => {
    // If multiple, clear the filtering input so the user
    // doesn't have to clear it manually.
    if (isMultiple || !isDesktop) {
      setInnerFilterVal('');
    }

    keyboardNavigation.updateEvents();
  }, [isDesktop, isMultiple, keyboardNavigation, setInnerFilterVal]);

  const onFilterKeyUp = useCallback(
    ({ key, target }: KeyboardEvent) => {
      if (FILTERING_IGNORABLE_KEYS.includes(key)) return;
      filteringDebounce(() => applyFiltering());
      wasFilterCleared.current = !isMultiple && !(target as HTMLInputElement).value.trim();
    },
    [applyFiltering, filteringDebounce, isMultiple]
  );

  const onDropdownHidden = useCallback(() => {
    unapplyFiltering();

    if (wasFilterCleared.current) {
      clearOuterValue();
    } else {
      setInnerFilterVal(outerValAsLabel);
    }

    wasFilterCleared.current = false;
  }, [clearOuterValue, outerValAsLabel, setInnerFilterVal, unapplyFiltering]);

  const context = useMemo<InputAutocompleteContextValue>(
    () => ({
      options,
      toggleOption,
      registerOption,
      selectedOptions: outerValAsObject,
      isMultiple: !!isMultiple,
    }),
    [outerValAsObject, isMultiple, options, registerOption, toggleOption]
  );

  useEffect(() => {
    if (!dropdownRef.current?.isOpen) {
      setInnerFilterVal(outerValAsLabel);
    }
  }, [outerValAsLabel, setInnerFilterVal]);

  const optionsChildren = (
    <StyledOptionContainer animationDuration={'fast'} column stretch>
      {children}
      <StyledEmptyFilter $visible={isFilteringEmpty} paddingVertical_050 paddingHorizontal_075>
        <Text typography={'SmallParagraph'} weight={'600'} color={'contentSecondary'}>
          {t('no_results_simple')}
        </Text>
      </StyledEmptyFilter>
    </StyledOptionContainer>
  );

  const multiSelectedOptionsChildren = isMultiple ? (
    <StyledOptionChipContainerWrapper $isVisible={!!outerValLength}>
      <StyledOptionChipContainer>
        <Box marginLeft_050 marginRight_050>
          {optionsSorted.map(({ value, label }) => {
            const selected = outerValAsObject[value];
            return (
              <StyledOptionChip
                key={value}
                $visible={selected}
                disabled={!selected}
                onClick={() => toggleOption(value)}
              >
                <Box center gap_050>
                  <Text typography={'SmallParagraph'} weight={'600'}>
                    {label}
                  </Text>
                  <Close />
                </Box>
              </StyledOptionChip>
            );
          })}
        </Box>
      </StyledOptionChipContainer>
    </StyledOptionChipContainerWrapper>
  ) : null;

  return (
    <InputAutocompleteContext.Provider value={context}>
      <FormProvider {...innerForm}>
        <Box id={dropdownAnchorId} ref={filterInputWrapperRef} column stretch {...boxProps}>
          <StyledInputWrapper>
            <InputText
              id={innerFilterInputId}
              name={innerFilterInputName}
              onKeyUp={onFilterKeyUp}
              {...innerInputOptions}
            />
          </StyledInputWrapper>

          <ResponsiveRender until={'tablet'}>
            <InputAutocompleteDropdownMobile
              ref={dropdownRef}
              target={dropdownAnchorId}
              title={innerInputOptions.label}
              filterName={innerFilterInputName}
              filterVisible={showMobileOptionsFilter}
              onFilterChange={applyFiltering}
              hasSelection={!!outerValLength}
              headerContent={multiSelectedOptionsChildren}
              footerVisible={isMultiple}
              onClearRequest={clearOuterValue}
              onShown={onDropdownShown}
              onHidden={onDropdownHidden}
              disabled={innerInputOptions.disabled}
            >
              <Box ref={optionsWrapperRef} column stretch>
                {optionsChildren}
              </Box>
            </InputAutocompleteDropdownMobile>
          </ResponsiveRender>

          <ResponsiveRender from={'desktopSM'}>
            <InputAutocompleteDropdownDesktop
              ref={dropdownRef}
              target={dropdownAnchorId}
              onShown={onDropdownShown}
              onHidden={onDropdownHidden}
            >
              <Box ref={optionsWrapperRef} column stretch>
                {multiSelectedOptionsChildren}
                {optionsChildren}
                {isMultiple && (
                  <StyledDropdownFooter>
                    <Button primary onClick={closeDropdown}>
                      {t('apply')}
                    </Button>
                  </StyledDropdownFooter>
                )}
              </Box>
            </InputAutocompleteDropdownDesktop>
          </ResponsiveRender>
        </Box>
      </FormProvider>
    </InputAutocompleteContext.Provider>
  );
};
