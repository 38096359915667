import React from 'react';
import { useTheme } from 'styled-components';

import { ModalBase } from '@components/common/ModalBase';
import { useModalDeferredOpenState } from '@components/common/ModalBase/hooks';
import { useMeerkatDragToDismiss } from '@components/common/ModalMeerkat/hooks';
import { ModalMeerkatProps } from '@components/common/ModalMeerkat/types';
import { useColor } from '@style/theme/hooks';

import { StyledModalMeerkat } from './styles';

export const ModalMeerkat = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ModalMeerkatProps>>(
  ({ backgroundColor: propBackgroundColor, suppressAutoScroll, useFullHeight, children, ...props }, ref) => {
    const theme = useTheme();
    const backgroundColor = useColor(propBackgroundColor, theme.primitives.colors.white);
    const isOpen = useModalDeferredOpenState(props.isOpen);

    const { modalBodyRef } = useMeerkatDragToDismiss({
      enabled: isOpen && !props.nonDismissible,
      onDismissRequest: props.onRequestClose,
    });

    return (
      <ModalBase ref={ref} {...props}>
        <StyledModalMeerkat
          ref={modalBodyRef}
          $width={props.width}
          $backgroundColor={backgroundColor}
          $useFullHeight={useFullHeight}
          $suppressAutoScroll={suppressAutoScroll}
          open={isOpen}
        >
          {children}
        </StyledModalMeerkat>
      </ModalBase>
    );
  }
);

ModalMeerkat.displayName = 'ModalMeerkat';
