import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '@helpers/Thunk/hooks';
import {
  makeYardCreateOrUpdateFetchDetailThunk,
  makeYardCreateOrUpdateOpenModalAction,
} from '@redux/YardCreateOrUpdate/actions';

export function useYardCreateOrUpdateModalOpener() {
  const dispatch = useDispatch();

  const openForCreate = useCallback(() => {
    dispatch(makeYardCreateOrUpdateOpenModalAction());
  }, [dispatch]);

  const openForUpdate = useCallback(
    (id: number) => {
      dispatch(makeYardCreateOrUpdateOpenModalAction());
      dispatch(makeYardCreateOrUpdateFetchDetailThunk(id));
    },
    [dispatch]
  );

  return { openForCreate, openForUpdate };
}

export function useOnYardSaveListener(listener: () => void) {
  const { isSavingDetails, didLastSaveSucceed } = useSelector((state) => state.yardCreateOrUpdateReducer);

  useEffect(() => {
    if (!isSavingDetails && didLastSaveSucceed) {
      listener();
    }
  }, [isSavingDetails, didLastSaveSucceed, listener]);
}
