import React, { FormEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';
import { InputTextBackwardCompatible } from '@components/form/backwardCompatible/InputTextBackwardCompatible';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';

import { StyledForm, StyledFormContent, StyledFormFooter, StyledFormHead } from './styles';

export const PolliMapPaneDropForm: React.VFC = () => {
  const { t } = useTranslation();
  const {
    selectedDrop,
    selectDrop,
    isAdding,
    isManagingADrop,
    isDraggingElements,
    drops,
    submitDropChange,
    cancelDropChange,
  } = usePolliMap();

  const targetHiveNumberValue = useMemo(() => {
    if (selectedDrop && selectedDrop.targetHiveNumber !== null) {
      return String(selectedDrop.targetHiveNumber);
    }
    return '';
  }, [selectedDrop]);

  const handleTargetHiveNumberChange = useCallback(
    (value: number | string) => {
      if (selectedDrop) {
        const cleanValue = String(value).replace(/[^0-9]/g, '');
        const targetHiveNumber = cleanValue ? parseInt(cleanValue) : null;
        selectDrop({ ...selectedDrop, targetHiveNumber });
      }
    },
    [selectDrop, selectedDrop]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      selectedDrop &&
        Analytics.sendEvent({
          event: isAdding(selectedDrop, drops)
            ? AnalyticsEventType.POLLINATION_MAP_DROP_ADD
            : AnalyticsEventType.POLLINATION_MAP_DROP_EDIT,
        });

      event.preventDefault();
      event.stopPropagation();
      submitDropChange();
    },
    [drops, isAdding, selectedDrop, submitDropChange]
  );

  const handleCancel = useCallback(() => {
    cancelDropChange();
  }, [cancelDropChange]);

  if (!(isDraggingElements || isManagingADrop)) {
    return null;
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormHead>
        <Text typography={'Heading3'} weight={'600'}>
          {t('pollination_new_drop_name', selectedDrop)}
        </Text>
      </StyledFormHead>
      <StyledFormContent>
        <Box marginBottom_100>
          <Text typography={'SmallParagraph'}>{t('pollination_new_drop_hint')}</Text>
        </Box>

        <InputTextBackwardCompatible
          id="drop-target-hive-number"
          name="drop-target-hive-number"
          type="number"
          label={t('number_of_hives')}
          value={targetHiveNumberValue}
          onTextChange={handleTargetHiveNumberChange}
          required
          autoFocus
        />
      </StyledFormContent>
      <StyledFormFooter>
        <Button secondary grow withMarginRight type={'button'} onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button primary grow>
          {t(isAdding(selectedDrop, drops) ? 'create' : 'done')}
        </Button>
      </StyledFormFooter>
    </StyledForm>
  );
};
