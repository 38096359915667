import produce from 'immer';

import APP from '@config/constants';
import { getParsedFromLocalStorage } from '@helpers/deprecated/storage';

import * as actionTypes from '../actionTypes';

const user = getParsedFromLocalStorage('user');
const permissions = getParsedFromLocalStorage('permissions');

const defaultState = {
  isFetching: false,
  didUpdateUser: false,
  user,
  permissions,
};

export const accountReducer = (state = defaultState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.ACCOUNT_START:
        st.isFetching = true;
        break;
      case actionTypes.ACCOUNT_SUCCESS:
        st.isFetching = false;
        break;
      case actionTypes.ACCOUNT_FAILURE:
        st.isFetching = false;
        break;
      case actionTypes.LOCAL_STORAGE_LOGIN_SUCCESS:
        const { user } = action;
        let names = [];

        (user.feature_hht_manager || user.feature_hht_worker) && names.push(APP.permissions.hht);
        user.feature_sensor && names.push(APP.permissions.sensor);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem(
          'permissions',
          JSON.stringify({
            names,
            role: user.feature_hht_manager ? 'manager' : 'worker',
          })
        );
        st.user = user;
        st.permissions = {
          names,
          role: user.feature_hht_manager ? 'manager' : 'worker',
        };
        break;
      case actionTypes.REMOVE_USER_AND_PERMISSIONS:
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        st.user = null;
        st.permissions = null;
        break;
      case actionTypes.SET_USER_SUCCESS_ACTION:
        localStorage.setItem('user', JSON.stringify(action.user));
        st.user = action.user;
        st.didUpdateUser = true;
        break;
      default:
        break;
    }
  });
};
