import styled, { css } from 'styled-components';

import { from } from '@style/mediaQueries';

export const RadioButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;

  ${from('tablet')} {
    width: 20px;
    height: 20px;
  }
`;

export const RadioButtonHover = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: ${-theme.spacing._050};
    left: ${-theme.spacing._050};
    right: ${-theme.spacing._050};
    bottom: ${-theme.spacing._050};
    border-radius: 50%;
    transition: ${theme.animations.transitionFast('transform')};
    pointer-events: none;
    transform: scale(0);

    ${RadioButtonWrapper}:hover && {
      background-color: ${theme.primitives.colors.grey03};
      transform: scale(1);
    }
  `
);

export const RadioButtonInput = styled.input(
  ({ theme }) => css`
    appearance: none;
    cursor: pointer;
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid ${theme.primitives.colors.grey05};
    background-color: ${theme.primitives.colors.white};
    transition: ${theme.animations.transitionFast('border')};

    ::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 50%;
      top: 2px;
      left: 2px;
      pointer-events: none;
    }

    :checked {
      border: 2px solid ${theme.primitives.colors.grey08};
    }

    :checked::after {
      background-color: ${theme.primitives.colors.grey08};
    }
  `
);

export const RadioButtonHiddenLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
