import styled from 'styled-components';

export const StyledLoaderWrapper = styled.div(
  ({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgb(255 255 255 / 100%);
    z-index: ${theme.getZIndexOf('highLevelIndex')};

    & > div {
      width: 192px;
    }
  `
);
