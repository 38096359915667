import { ColDef } from 'ag-grid-community';

import { ColDefWithMetadata, Column, ColumnType, ViewKey } from '@components/yard/YardsList/types';
import { YardsSelectAllCheckbox } from '@components/yard/YardsList/YardsSelectAllCheckbox';

export const DEF_SELECT_COLUMN_DEF: ColDefWithMetadata = {
  field: 'select',
  pinned: 'left',
  lockPosition: true,
  headerName: '',
  maxWidth: 52,
  resizable: false,
  suppressAutoSize: true,
  headerComponent: YardsSelectAllCheckbox,
  metadata: {
    groupId: 'select',
    groupName: 'select',
    column: {
      type: ColumnType.DEFAULT,
    } as Column,
  },
};

export const DEF_NAME_COLUMN_DEF: ColDefWithMetadata = {
  field: 'composedName',
  pinned: 'left',
  lockPosition: true,
  minWidth: 192,
  maxWidth: window.innerWidth * 0.4,
  resizable: true,
  suppressAutoSize: true,
  metadata: {
    groupId: 'composedName',
    groupName: 'composedName',
    column: {
      type: ColumnType.DEFAULT,
    } as Column,
  },
};

export const DEF_MENU_COLUMN_DEF: ColDefWithMetadata = {
  field: 'menu',
  flex: 1,
  headerName: '',
  lockPosition: 'right',
  resizable: false,
  suppressAutoSize: true,
  metadata: {
    groupId: 'menu',
    groupName: 'menu',
    column: {
      type: ColumnType.DEFAULT,
    } as Column,
  },
};
export const DEF_STATUS_COLUMN_DEF: ColDefWithMetadata = {
  field: 'composedStatus',
  metadata: {
    groupId: 'composedStatus',
    groupName: 'composedStatus',
    column: {
      type: ColumnType.DEFAULT,
    } as Column,
  },
};

export const DEF_PERMANENT_LEFT_COLUMNS = [DEF_SELECT_COLUMN_DEF, DEF_NAME_COLUMN_DEF, DEF_STATUS_COLUMN_DEF];
export const DEF_PERMANENT_COLUMNS_WITH_MENU = [DEF_STATUS_COLUMN_DEF];
export const DEF_PERMANENT_RIGHT_COLUMNS = [DEF_MENU_COLUMN_DEF];

export const DEF_PERMANENT_COLUMNS = [...DEF_PERMANENT_LEFT_COLUMNS, ...DEF_PERMANENT_RIGHT_COLUMNS];
export const DEF_PERMANENT_COLUMNS_KEYS = DEF_PERMANENT_COLUMNS.map((col) => col.field);
export const DEF_PERMANENT_COLUMNS_WITH_MENU_KEYS = DEF_PERMANENT_COLUMNS_WITH_MENU.map((col) => col.field);

export const DEF_ABSTRACT_COLUMNS = [DEF_SELECT_COLUMN_DEF, DEF_MENU_COLUMN_DEF];
export const DEF_ABSTRACT_COLUMNS_KEYS = DEF_ABSTRACT_COLUMNS.map((col) => col.field);

export const DEF_VISIBLE_COLUMNS = [
  ...DEF_PERMANENT_LEFT_COLUMNS,
  { field: 'location' },
  { field: 'type' },
  { field: 'hives' },
  { field: 'introduced' },
  { field: 'queenless' },
  { field: 'deadout' },
  { field: 'healthScore' },
  {
    field: 'lastVisit',

    // This metadata is needed for the initial sorting to work,
    // and it needs to be in sync with what the API will return.
    metadata: {
      activeView: 'default',
      column: {
        views: {
          default: {
            key: 'default',
            sortableKey: 'composed_last_visit__occurred_at',
          },
        },
      },
    },
  },
  { field: 'averageVisits' },
  { field: 'grading' },
  { field: 'groups' },
  ...DEF_PERMANENT_RIGHT_COLUMNS,
] as Array<ColDefWithMetadata>;

/**
 * Default columns used for sorting.
 * Only the first column here is used by default, and only in
 * case it's currently hidden, the next ones are attempted.
 * */
export const DEF_SORTING: Array<ColDef> = [
  { field: 'lastVisit', sort: 'desc' },
  { field: 'composedName', sort: 'asc' },
];

/**
 * These columns are auto-resized after loaded from API or
 * after changing view.
 * Not in use, but kept here in case we change it again.
 * */
export const DEF_AUTO_RESIZED_COLUMNS: Array<string> = [];

export const DEF_COLUMN_MIN_WIDTH = 132;
export const DEF_COLUMN_MIN_WIDTHS: Record<string, number> = {
  select: 68,
  menu: 68,
  composedName: 240,
  location: 192,
  type: 172,
  lastVisit: 172,
  averageVisits: 172,
  grading: 316,
};

/**
 * Sets which view mode is set as default. In case the first view
 * mode is not present, the next is taken.
 * */
export const DEF_VIEW_MODES_PRECEDENCE: Array<ViewKey> = [
  'occurrencesSinceSeasonStart',
  'lastOccurrenceValue',
  'lastOccurrenceDatetime',
  'periodSinceLastOccurrence',
  'workerLastOccurrence',
  'default',
];
