import styled, { css, keyframes } from 'styled-components';

import CarrouselBackground from '@assets/auth/auth-carrousel-background.svg';
import { Box } from '@components/common/Box';
import { from } from '@style/mediaQueries';

const DEF_APPEARING_ANIMATION_DURATION = '3s';
const DEF_NOTEBOOK_SIZE = 250;
const DEF_GOLDEN_RATIO = 1.618;

export const StyledCarrousel = styled(Box).attrs({ center: true, column: true })(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.surfacePrimary};
    border-radius: 24px;
    margin: 40px;
    width: calc(100vw / ${DEF_GOLDEN_RATIO} - 80px);
    display: none;

    ${from('desktopSM')} {
      display: flex;
    }
  `
);

export const StyledCarrouselTextContainer = styled(Box).attrs({
  column: true,
})(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.colors.contentPrimaryReverse};
    text-align: center;
    height: 150px;
    & * {
      max-width: 450px;
    }
  `
);

export const StyledCarrouselStepperContainer = styled(Box).attrs({ center: true })(
  () => css`
    position: absolute;
    inset: calc(100% - 64px) 0 0 0;
  `
);

export const CarrouselBackgroundAnimation = keyframes`
  0%, 20% { opacity: 0 }
  60%, 100% { opacity: 0.2 }
`;
export const StyledCarrouselBackground = styled.div(
  () => css`
    position: absolute;
    inset: 0;
    opacity: 0.2;

    animation-name: ${CarrouselBackgroundAnimation};
    animation-duration: ${DEF_APPEARING_ANIMATION_DURATION};
    animation-iteration-count: 1;
    animation-timing-function: linear;

    &::before {
      content: '';
      position: absolute;
      inset: 0 0 calc(50% - 16px) 0;
      background: url(${CarrouselBackground}) 50% 100% / 700px repeat-x;
    }
  `
);

export const NoteBookAnimation = keyframes`
  0%, 30% {
    opacity: 0;
    transform: translateY(33px);
  }
  75%, 100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;
export const StyledNoteBook = styled.div(
  () => css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    margin-bottom: 78px;

    animation-name: ${NoteBookAnimation};
    animation-timing-function: cubic-bezier(0.21, 0.65, 0.47, 0.9);
    animation-duration: ${DEF_APPEARING_ANIMATION_DURATION};
    animation-iteration-count: 1;
  `
);

export const NoteBookScreenAnimation = keyframes`
  0%, 55% { transform: perspective(1350px) rotate3d(1, 0, 0, -90deg); }
  100% { transform: perspective(1350px) rotate3d(1, 0, 0, 0deg); }
`;
export const StyledNoteBookScreen = styled.div(
  () => css`
    position: relative;
    width: ${1.48 * DEF_NOTEBOOK_SIZE}px;
    height: ${DEF_NOTEBOOK_SIZE}px;
    border-radius: ${0.0278 * DEF_NOTEBOOK_SIZE}px ${0.0278 * DEF_NOTEBOOK_SIZE}px 0 0;
    background: #d6d6d6;

    transform: perspective(1350px) rotate3d(1, 0, 0, 0deg);
    transform-origin: bottom;
    transform-style: unset;

    animation-name: ${NoteBookScreenAnimation};
    animation-timing-function: cubic-bezier(0.21, 0.65, 0.47, 0.9);
    animation-duration: ${DEF_APPEARING_ANIMATION_DURATION};
    animation-iteration-count: 1;
  `
);

export const StyledNoteBookScreenFrame = styled.div(
  () => css`
    position: absolute;
    inset: 1px 0 0 1px;
    width: calc(100% - 2px);
    height: calc(100% - 1px);
    border-radius: ${0.0253 * DEF_NOTEBOOK_SIZE}px ${0.0253 * DEF_NOTEBOOK_SIZE}px 0 0;
    background: #050505;
    box-shadow: 0 0 1.407px 0.704px rgba(0, 0, 0, 0.1) inset, 0 0 0.176px 0.176px rgba(255, 255, 255, 0.6);
  `
);

export const NoteBookScreenContentAnimation = keyframes`
  0%, 55% { opacity: 0.0; }
  90% { opacity: 1; }
`;
export const StyledNoteBookScreenContent = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    inset: 7px;
    background-color: ${theme.colors.surfaceSecondary};

    animation-name: ${NoteBookScreenContentAnimation};
    animation-timing-function: linear;
    animation-duration: ${DEF_APPEARING_ANIMATION_DURATION};
    animation-iteration-count: 1;

    & * {
      white-space: nowrap;
    }
  `
);

export const NoteBookLidAnimation = keyframes`
  0%, 30% {
    opacity: 1.0;
    height: ${0.012 * DEF_NOTEBOOK_SIZE}px;
  }
  55% {
    opacity: 1.0;
    height: ${0.012 * DEF_NOTEBOOK_SIZE}px;
  }
  60% {
    opacity: 0;
    height: 0;
  }
`;
export const StyledNoteBookLid = styled.div(
  () => css`
    width: ${1.848 * DEF_NOTEBOOK_SIZE}px;
    height: 0;
    background-color: white;
    border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
    border-radius: ${0.004 * DEF_NOTEBOOK_SIZE}px ${0.004 * DEF_NOTEBOOK_SIZE}px 0 0;
    opacity: 0;

    animation: ${NoteBookLidAnimation} ${DEF_APPEARING_ANIMATION_DURATION} cubic-bezier(0.25, 0.1, 0.25, 1) 1;
  `
);

export const NoteBookKeyboardAnimation = keyframes`
  0%, 30% {
    box-shadow: 0px -0.704px 0.352px 0px rgba(255, 255, 255, 0.7) inset,
      0px -0.352px 0.352px 0px rgba(0, 0, 0, 0.3) inset, 0px 0px 13px rgba(0, 0, 0, 0.7), 0px 0px 13px rgba(0, 0, 0, 0.7);
  }
  75%, 100% {
    box-shadow: 0px -0.704px 0.352px 0px rgba(255, 255, 255, 0.7) inset,
      0px -0.352px 0.352px 0px rgba(0, 0, 0, 0.3) inset, 0px 8px 13px rgba(0, 0, 0, 0.7), 0px 18px 13px rgba(0, 0, 0, 0.7);
  }
`;
export const StyledNoteBookKeyboard = styled.div(
  () => css`
    width: ${1.852 * DEF_NOTEBOOK_SIZE}px;
    height: ${0.036 * DEF_NOTEBOOK_SIZE}px;
    border-radius: ${0.004 * DEF_NOTEBOOK_SIZE}px;
    background: linear-gradient(180deg, rgba(239, 239, 239, 0.2) 0%, rgba(219, 219, 219, 0.2) 100%),
      linear-gradient(90deg, #c6c5c5 0%, #fefefe 0.87%, #e3e3e3 3.11%, #e7e6e6 96.25%, #fff 99.21%, #c6c5c5 100%);
    box-shadow: 0px -0.704px 0.352px 0px rgba(255, 255, 255, 0.7) inset,
      0px -0.352px 0.352px 0px rgba(0, 0, 0, 0.3) inset, 0px 8px 13px rgba(0, 0, 0, 0.7),
      0px 18px 13px rgba(0, 0, 0, 0.7);

    animation-name: ${NoteBookKeyboardAnimation};
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-duration: ${DEF_APPEARING_ANIMATION_DURATION};
    animation-iteration-count: 1;
  `
);
