import styled from 'styled-components';

import { Box } from '@components/common/Box';

export const StyledActivitiesTab = styled(Box).attrs({
  fit: true,
  column: true,
  stretch: true,
})`
  overflow: hidden;
`;
