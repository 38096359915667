import React, { useLayoutEffect, useRef } from 'react';
import Lottie from 'lottie-web';

import LoadingDownloadAnimation from '@assets/animations/loading-download.json';

import { StyledLoaderWrapper } from './styles';

export const LoadingDownload: React.FC = () => {
  const loadAnimRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (loadAnimRef.current) {
      const anim = Lottie.loadAnimation({
        container: loadAnimRef.current,
        animationData: LoadingDownloadAnimation,
        renderer: 'svg',
        autoplay: true,
        loop: true,
      });

      return () => anim.destroy();
    }
  });

  return (
    <StyledLoaderWrapper>
      <div ref={loadAnimRef} />
    </StyledLoaderWrapper>
  );
};
