import { useContext } from 'react';

import { DynamicTableSelectionContext, DynamicTableSelectionContextValue } from '../context/context';

export function useDynamicTableSelection<RowMetaType>(): DynamicTableSelectionContextValue<RowMetaType> {
  const dynamicTableSelection = useContext(DynamicTableSelectionContext);

  if (!dynamicTableSelection) {
    throw new Error("Can't find a parent dynamic table list context.");
  }

  return dynamicTableSelection;
}
