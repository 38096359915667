import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalBaseProps } from '@components/common/ModalBase/types';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { makeArchiveContractThunk } from '@redux/ArchiveContract/actions';
import { makeActiveDropsDisposeAction, makeFetchActiveDropsThunk } from '@redux/Contract/actions';

import { ClearOutDropsAndAction } from './ClearOutDropsAndAction';

export const ArchiveModal: React.VFC<{ contractId: number; modalProps: ModalBaseProps; contractName?: string }> = ({
  contractId,
  modalProps,
  contractName,
}) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);
  const activeDrops = useSelector((state) => state.contractReducer.contractActiveDrops);

  const hasActiveDrops = activeDrops !== null && activeDrops.length;

  const archiveModalTitle = useMemo(
    () => (contractName ? t('archive_contract_name', { contract: contractName }) : t('archive_contract')),
    [contractName, t]
  );

  const archiveContract = useCallback(async () => {
    await dispatch(makeArchiveContractThunk(contractId));
    Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_CONTRACT_ARCHIVE });
  }, [contractId, dispatch]);

  const closeModal = useCallback(() => {
    modalProps.onRequestClose && modalProps.onRequestClose();
  }, [modalProps]);

  const onSuccess = useCallback(() => {
    closeModal();
    if (history.location.pathname === URLUtil.buildPagePath(APP.routes.pollinationContractsOngoingList)) {
      history.push(URLUtil.buildPagePath(APP.routes.pollination));
    } else {
      history.push(URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, { pathParams: { uid: contractId } }));
    }
  }, [closeModal, contractId, history]);

  const performArchiveAction = useCallback(async () => {
    try {
      await archiveContract();
      onSuccess();
    } catch (_) {
      closeModal();
    }
  }, [archiveContract, closeModal, onSuccess]);

  const onAcceptClick = useCallback(() => {
    if (hasActiveDrops) {
      setIsBulkActionsOpen(true);
      return;
    }
    performArchiveAction();
  }, [hasActiveDrops, performArchiveAction]);

  useEffect(() => {
    if (modalProps.isOpen) {
      dispatch(makeFetchActiveDropsThunk(contractId));
    } else {
      dispatch(makeActiveDropsDisposeAction());
    }
    setIsBulkActionsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.isOpen]);

  return (
    <Modal {...modalProps}>
      {isBulkActionsOpen && activeDrops ? (
        <ClearOutDropsAndAction
          closeModal={closeModal}
          contractId={contractId}
          activeDrops={activeDrops}
          secondActionProps={{
            dispatchAction: archiveContract,
            acceptButtonText: t('clear_out_and_archive'),
            progressTitle: t('archiving'),
            warningText: t('clear_out_contract_warn_archive'),
            subtitle: t('clear_out_archive_contract_desc'),
          }}
          onSuccess={onSuccess}
        />
      ) : (
        <>
          <ModalHeader
            title={archiveModalTitle}
            subtitle={<span dangerouslySetInnerHTML={{ __html: t('archive_contract_desc') }} />}
          />
          <ModalFooter
            autoCloseOnReject
            rejectText={t('cancel')}
            acceptText={t('archive')}
            onAcceptClick={onAcceptClick}
            acceptButtonProps={{ danger: true, primary: true }}
          />
        </>
      )}
    </Modal>
  );
};
