import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { capitalize } from 'lodash';

import { Box } from '@components/common/Box';
import { NavRouterLink } from '@components/common/CTA';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { Delete } from '@components/common/Icon/presets/Delete';
import { HiveAdded } from '@components/common/Icon/presets/HiveAdded';
import { HiveDefault } from '@components/common/Icon/presets/HiveDefault';
import { HiveLost } from '@components/common/Icon/presets/HiveLost';
import { HiveMoved } from '@components/common/Icon/presets/HiveMoved';
import { NotesNavigator } from '@components/common/NotesNavigator';
import { PracticeIcon } from '@components/common/PracticeIcon';
import { Text } from '@components/common/Text';
import { HiveActivityCardExpandableTags } from '@components/hive/HiveActivityCard/HiveActivityCardExpandableTags';
import { StyledInspectionCard, StyledInspectionCardItem } from '@components/hive/HiveActivityCard/styles';
import { HiveActivityCardProps } from '@components/hive/HiveActivityCard/types';
import { YardName } from '@components/yard/YardName';
import APP from '@config/constants';
import { Children } from '@helpers/Children';
import { Number } from '@helpers/Number';
import { Sorting } from '@helpers/Sorting';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

export const HiveActivityCard: React.VFC<HiveActivityCardProps> = ({ activity, highlightedHiveId, ...props }) => {
  const t = useTranslation();

  const { getPractice, getActionName, getPracticeName, getPracticeByAction } = usePractices();

  const innerCards = useMemo(() => {
    const hives = activity.hiveIdentities;
    const rows = Sorting.sort(
      [
        ...activity.practices
          .filter(({ actionId, categoryId }) => !!actionId || !!categoryId)
          .map(({ actionId, categoryId, value }) => {
            const practice = getPractice(categoryId);
            return {
              icon: <PracticeIcon actionId={actionId} />,
              title: capitalize(getPracticeName(categoryId) ?? ''),
              value:
                practice?.fieldType === 'slider'
                  ? Number.getReadableNumber(value)
                  : capitalize(getActionName(actionId, '-')),
              isHiveData: !!getPracticeByAction(actionId)?.isHiveData,
            };
          }),
      ],
      ['title', 'value']
    );

    const yardsLabel = Children.createWithSeparators(activity.yards, {
      itemWrapper: (item, separator) => {
        const yardName = <YardName name={item.name} contractName={item.contractName} suppressStyling />;
        return (
          <>
            {item.isDeleted ? (
              <Text typography={'Heading3'}>{yardName}</Text>
            ) : (
              <NavRouterLink
                to={URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: item.id } })}
                suppressPadding
              >
                <Text typography={'Heading3'}>{yardName}</Text>
              </NavRouterLink>
            )}
            {separator}
          </>
        );
      },
    });

    if (activity.alertType === 'new_inspection') {
      return [
        {
          title: t('hives_included'),
          icon: HiveDefault,
          hives: hives,
          rows: rows.filter(({ isHiveData }) => isHiveData),
        },
        {
          title: t('yard_included'),
          icon: HiveDefault,
          hives: hives,
          rows: rows.filter(({ isHiveData }) => !isHiveData),
          yardsLabel,
        },
      ].filter(({ rows }) => rows.length);
    } else if (activity.alertType === 'hive_managed_to_yard') {
      return [{ title: t('reassigned_hives'), icon: HiveMoved, hives: hives, rows }];
    } else if (activity.alertType === 'yard_cleared_out') {
      return [{ title: t('lost_hives'), icon: HiveLost, hives: hives, rows: [] }];
    } else if (activity.alertType === 'lost_hive_marked_as_deadout') {
      return [{ title: t('deadout_hives'), icon: Deadout, hives: hives, rows: [] }];
    } else if (activity.alertType === 'lost_hive_marked_as_removed') {
      return [{ title: t('removed_hives'), icon: Delete, hives: hives, rows: [] }];
    }
    return [
      { title: t('moved_hives'), icon: HiveMoved, hives: hives.filter(({ isNew }) => !isNew), rows },
      { title: t('new_hives'), icon: HiveAdded, hives: hives.filter(({ isNew }) => isNew), rows },
    ].filter(({ hives }) => hives.length);
  }, [
    activity.alertType,
    activity.hiveIdentities,
    activity.practices,
    activity.yards,
    getActionName,
    getPractice,
    getPracticeByAction,
    getPracticeName,
    t,
  ]);

  return (
    <Box column stretch gap_100 {...props}>
      {innerCards.map(({ title, hives, yardsLabel, icon: Icon, rows }, index) => (
        <StyledInspectionCard key={index} column stretch>
          <StyledInspectionCardItem padding_050 column stretch>
            <Box gap_050 alignItems={'center'}>
              <Icon size={16} />
              <Text typography={'Heading3'} weight={'600'}>
                {title}
              </Text>
            </Box>
            <Text typography={'SmallParagraph'}>
              {yardsLabel ? (
                <Text typography={'Heading3'}>{yardsLabel}</Text>
              ) : (
                <HiveActivityCardExpandableTags hives={hives} highlightedHiveId={highlightedHiveId} />
              )}
            </Text>
          </StyledInspectionCardItem>
          {rows.map(({ icon, title, value }, index) => (
            <StyledInspectionCardItem key={index} padding_050 gap_050 alignItems={'flex-start'}>
              <Box fit alignItems={'center'} gap_050>
                {icon}
                <Text typography={'Heading3'} weight={'600'}>
                  {title}
                </Text>
              </Box>
              <Text typography={'Heading3'} align={'right'}>
                {value}
              </Text>
            </StyledInspectionCardItem>
          ))}
        </StyledInspectionCard>
      ))}

      {!!activity.notes && (
        <StyledInspectionCard column stretch>
          <NotesNavigator notes={[{ text: activity.notes }]} />
        </StyledInspectionCard>
      )}
    </Box>
  );
};
