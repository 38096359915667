import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { TimelineByActivityDate } from '@components/timeline/TimelineByActivityDate/TimelineByActivityDate';
import { StyledSectionHeader } from '@components/yard/YardsMapDetailsPane/styles';
import { useDateUtil } from '@helpers/Date/hooks';

export const YardsMapDetailsPaneVisit: React.VFC<{ activities: Array<BeeActivityByDate> }> = ({ activities }) => {
  const { t } = useTranslation();
  const dateUtil = useDateUtil();

  const itemTimestampRenderer = useCallback(
    (item: BeeActivityByDate) => {
      const formattedDate = [
        dateUtil.getFormattedDate(item.occurredAt, 'MMM DD, YYYY'),
        t('at'),
        dateUtil.getFormattedDate(item.occurredAt, 'H:mm'),
      ].join(' ');
      return (
        <Box alignItems={'center'} marginBottom_100>
          <Text typography={'SmallParagraph'}>{formattedDate}</Text>
        </Box>
      );
    },
    [dateUtil, t]
  );

  return (
    <>
      <StyledSectionHeader typography={'CaptionSmall'} weight={'600'} color={'grey06'}>
        {t('last_visit')}
      </StyledSectionHeader>

      <TimelineByActivityDate
        items={activities}
        itemsTotalCount={activities.length}
        itemTimestampRenderer={itemTimestampRenderer}
        suppressInfinityScrolling
        suppressDefaultPadding
        suppressDayMarks
        suppressYardName
      />
    </>
  );
};
