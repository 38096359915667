import React, { useEffect, useMemo, useState } from 'react';

import { DataStatusContentDotProps } from '@components/common/DataStatusContentDot/types';
import { useCurrentTabInfo } from '@components/common/Tabs/hooks';
import { DataStatusType } from '@redux/DataStatus/types';

import { StyledStatusDot } from './styles';

export const DataStatusContentDot: React.VFC<DataStatusContentDotProps> = ({ timestamp, dataStatus }) => {
  const [forceHide, setForceHide] = useState(false);

  const { isActive, hasBeenVisited } = useCurrentTabInfo();

  const isDotVisible = useMemo(() => {
    const updatedStatuses = dataStatus.statuses.filter((status) => status.type === DataStatusType.DATA_UPDATED);
    const lastStatus = updatedStatuses.slice(-1)[0];
    const previousStatus = updatedStatuses.slice(-2)[0];

    return lastStatus && previousStatus && timestamp > previousStatus.timestamp;
  }, [dataStatus.statuses, timestamp]);

  useEffect(() => {
    if (!isActive && hasBeenVisited) {
      setForceHide(true);
    }
  }, [hasBeenVisited, isActive]);

  return <StyledStatusDot $isVisible={isDotVisible && !forceHide}></StyledStatusDot>;
};
