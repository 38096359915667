import { useCallback } from 'react';

import { useDispatch } from '@helpers/Thunk/hooks';
import { makeContractMetricsFetchThunk } from '@redux/ContractsMetrics/actions';
import { ContractMetricName } from '@redux/ContractsMetrics/types';

export function useContractsMetricsLoader() {
  const dispatch = useDispatch();

  const loadMetrics = useCallback(() => {
    dispatch(
      makeContractMetricsFetchThunk([
        ContractMetricName.CONTRACTS,
        ContractMetricName.DROPS,
        ContractMetricName.HIVES_IN_STOCK,
        ContractMetricName.INCOME,
      ])
    );
  }, [dispatch]);

  return {
    loadMetrics,
  };
}
