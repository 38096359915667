// vendor
import styled from 'styled-components';

export const Fieldset = styled.fieldset<{ error?: boolean }>`
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 1px;
  background-color: ${(props) =>
    props.disabled ? props.theme.primitives.colors.grey02 : props.theme.primitives.colors.white};
  border: 1px solid
    ${(props) => (props.error ? props.theme.primitives.colors.red02 : props.theme.primitives.colors.grey03)};
  border: ${(props) => props.disabled && `1px solid ${props.theme.primitives.colors.grey05}`};
  &:hover {
    border: ${(props) => !props.disabled && !props.error && `1px solid ${props.theme.primitives.colors.grey05}`};
  }

  &:focus,
  &:focus-within {
    box-shadow: 0 0 4px 2px #71a3fb;
    outline: none;
  }
`;
