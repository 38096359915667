import React from 'react';
import { useTheme } from 'styled-components';

import { ModalBase } from '@components/common/ModalBase';
import { useModalDeferredOpenState } from '@components/common/ModalBase/hooks';
import { ModalMeerkat } from '@components/common/ModalMeerkat';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { useColor } from '@style/theme/hooks';

import { StyledModal } from './styles';
import { ModalProps } from './types';

export * from '@components/common/ModalBase';

export const Modal: React.FC<ModalProps> = ({
  backgroundColor: propBackgroundColor,
  useFullHeight,
  suppressAutoScroll,
  children,
  ...props
}) => {
  const theme = useTheme();
  const backgroundColor = useColor(propBackgroundColor, theme.primitives.colors.white);
  const isOpen = useModalDeferredOpenState(props.isOpen);

  return (
    <>
      <ResponsiveRender until={'mobile'}>
        <ModalMeerkat suppressAutoScroll={suppressAutoScroll} useFullHeight={useFullHeight} {...props}>
          {children}
        </ModalMeerkat>
      </ResponsiveRender>
      <ResponsiveRender from={'tablet'}>
        <ModalBase {...props}>
          <StyledModal
            $width={props.width}
            $backgroundColor={backgroundColor}
            $useFullHeight={useFullHeight}
            $suppressAutoScroll={suppressAutoScroll}
            open={isOpen}
          >
            {children}
          </StyledModal>
        </ModalBase>
      </ResponsiveRender>
    </>
  );
};
