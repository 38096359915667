import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RenderWithPermissionProps } from '@components/common/RenderWithPermission/types';
import APP from '@config/constants';

export const RenderWithPermission: React.FC<RenderWithPermissionProps> = ({
  require,
  requireAll,
  requireSome,
  children,
}) => {
  const permissions = useSelector(
    (state) => {
      const _perms = [] as Array<string>;
      state.authReducer.user?.currentMembership?.isManager && _perms.push(APP.permissions.hht);
      return _perms;
    },
    (prev, next) => prev.join(',') === next.join(',')
  );

  const hasRequiredPermissions = useMemo(() => {
    return require || requireAll || requireSome;
  }, [require, requireAll, requireSome]);

  const isRequireSatisfied = useMemo(() => {
    return !require || permissions.includes(require);
  }, [permissions, require]);

  const isRequireAllSatisfied = useMemo(() => {
    return !requireAll || (requireAll || []).every((perm) => permissions.includes(perm));
  }, [permissions, requireAll]);

  const isRequireSomeSatisfied = useMemo(() => {
    return !requireSome || (requireSome || []).some((perm) => permissions.includes(perm));
  }, [permissions, requireSome]);

  const canRender = useMemo(() => {
    return hasRequiredPermissions && isRequireSatisfied && isRequireAllSatisfied && isRequireSomeSatisfied;
  }, [hasRequiredPermissions, isRequireAllSatisfied, isRequireSatisfied, isRequireSomeSatisfied]);

  return <>{canRender ? children : null}</>;
};
