import React, { useCallback, useMemo, useRef } from 'react';
import { CalendarProps, ViewCallbackProperties } from 'react-calendar';
import moment from 'moment';

import ArrowIcon from '@assets/xSmall_Arrow-dropdown.svg';
import { Box } from '@components/common/Box';
import { IconIMG } from '@components/common/Icon';
import { Calendar } from '@components/common/Icon/presets/Calendar';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipRef } from '@components/common/Tooltip/types';
import {
  InputCalendarRow,
  InputCalendarText,
  InputCalendarTooltipCalendar,
  InputCalendarTooltipView,
  InputCalendarView,
} from '@components/form/inputs/InputCalendar/styles';
import { InputCalendarProps } from '@components/form/inputs/InputCalendar/types';

const DEF_FORMAT = 'MMM DD, YYYY';
const DEF_NY_FORMAT = 'MMM DD';
export const DEF_PLACEHOLDER = '--/--';

export const InputCalendar: React.FC<InputCalendarProps> = ({
  value,
  placeholder,
  dateFormat,
  disableYearSelector,
  name,
  id = name,
  label,
  onChange,
  maxDate,
  minDate,
  ...props
}) => {
  const tooltipRef = useRef<TooltipRef>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const isEmpty = useMemo(() => value === null, [value]);
  const placeholderSafe = useMemo(() => placeholder || DEF_PLACEHOLDER, [placeholder]);
  const dateFormatFallback = useMemo(() => (disableYearSelector ? DEF_NY_FORMAT : DEF_FORMAT), [disableYearSelector]);
  const dateFormatSafe = useMemo(() => dateFormat || dateFormatFallback, [dateFormatFallback, dateFormat]);
  const valueReadable = useMemo(
    () => (value ? moment(value).format(dateFormatSafe) : placeholderSafe),
    [dateFormatSafe, placeholderSafe, value]
  );
  const valueFallback = useMemo(() => value || new Date(), [value]);
  const valueCalendar = useMemo(() => {
    const date = new Date(valueFallback);
    if (disableYearSelector) {
      date.setFullYear(1970);
    }
    return date;
  }, [disableYearSelector, valueFallback]);
  const hasError = useMemo(
    () => !!((value && maxDate && value > maxDate) || (value && minDate && value < minDate)),
    [minDate, maxDate, value]
  );

  const formatDate = useCallback((date: Date, format: string) => moment(date).format(format), []);

  const calendarProps = useMemo(() => {
    const calendarProps = {
      formatMonthYear: (_, date) => formatDate(date, 'MMM, YYYY'),
      formatShortWeekday: (_, date) => formatDate(date, 'dd').slice(0, 1),
      maxDate,
      minDate,
      ...props,
    } as CalendarProps;
    if (disableYearSelector) {
      calendarProps.minDate = moment('1970-01-01').toDate();
      calendarProps.maxDate = moment('1970-12-31').toDate();
      calendarProps.minDetail = 'year';
      calendarProps.formatMonthYear = (_, date) => formatDate(date, 'MMMM');
      calendarProps.formatYear = () => '';
      calendarProps.className = 'hide-weekdays hide-double-arrows';
      calendarProps.onViewChange = ({ view }: ViewCallbackProperties) => {
        const nav = calendarRef.current?.querySelector('.react-calendar__navigation');
        if (nav) {
          (nav as HTMLElement).style.display = view === 'year' ? 'none' : 'flex';
        }
      };
    }
    return calendarProps;
  }, [disableYearSelector, formatDate, maxDate, minDate, props]);

  const handleChange = useCallback(
    (next: Date) => {
      if (disableYearSelector) {
        next.setFullYear(valueFallback.getFullYear());
      }
      tooltipRef.current?.hide();
      onChange(next, name);
    },
    [disableYearSelector, onChange, valueFallback, name]
  );

  const renderPopover = useCallback(() => {
    return (
      <InputCalendarTooltipView ref={calendarRef}>
        <InputCalendarTooltipCalendar value={valueCalendar} onChange={handleChange} {...calendarProps} />
      </InputCalendarTooltipView>
    );
  }, [handleChange, calendarProps, valueCalendar]);

  return (
    <InputCalendarView role="datepicker" hasError={hasError}>
      {label && (
        <InputCalendarRow>
          <Box marginVertical_025>
            <Text typography={'CaptionSmall'} color={'grey07'}>
              {label}
            </Text>
          </Box>
        </InputCalendarRow>
      )}
      <InputCalendarRow>
        <Calendar size={15} />
        <InputCalendarText typography={'SmallParagraph'} weight={'400'} color={isEmpty ? 'grey06' : 'grey08'}>
          {valueReadable}
        </InputCalendarText>
        <IconIMG src={ArrowIcon} alt={'arrow'} size={15} />
      </InputCalendarRow>

      <Tooltip ref={tooltipRef} interactive placement={'bottom-start'} renderContent={renderPopover} />
    </InputCalendarView>
  );
};
