import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Color } from 'styled-components';

import { AccordionContent } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { ProgressBar } from '@components/common/ProgressBar';
import { Text } from '@components/common/Text';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import {
  StyledAccordion,
  StyledAccordionHeader,
  StyledCardWrapper,
  StyledProgressCard,
} from '@components/yard/YardsMapCard/styles';
import { Sorting } from '@helpers/Sorting';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makePatchAppliedFiltersAction } from '@redux/YardsFilters/actions';
import { useYardsFilters } from '@redux/YardsFilters/hooks';

import { YardsMapCardContentProps } from './types';

export const YardsMapCardContent: React.VFC<YardsMapCardContentProps> = ({
  title,
  icon,
  filterCategoryKey,
  yardAttributeConfigs,
  getYardAttributeStats,
  shouldShowYardAttributeStats,
  getToggledFilters,
}) => {
  const { yards } = useYardsMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);

  const { appliedFilters, appliedFiltersCount } = useYardsFilters();
  const appliedCategoryFilters = useMemo(
    () => appliedFilters[filterCategoryKey] ?? [],
    [appliedFilters, filterCategoryKey]
  );

  const hasAppliedFilters = appliedFiltersCount > 0;
  const areYardsEmpty = yards.length === 0;
  const emptyMessage =
    areYardsEmpty && hasAppliedFilters
      ? t('no_results_simple')
      : areYardsEmpty
      ? t('no_yards_simple')
      : t('not_available_with_slash');

  const rows = useMemo(() => {
    return Object.values(yardAttributeConfigs).filter((spec) =>
      shouldShowYardAttributeStats(spec, appliedCategoryFilters)
    );
  }, [appliedCategoryFilters, yardAttributeConfigs, shouldShowYardAttributeStats]);

  const rowCounts = useMemo(() => {
    const sortedRows = Sorting.sort(rows, 'order');
    return sortedRows.map((sortedRow) => getYardAttributeStats(sortedRow, emptyMessage));
  }, [rows, emptyMessage, getYardAttributeStats]);

  const isFilterApplied: (filter: string) => boolean = useCallback(
    (filter: string) => {
      return appliedCategoryFilters.includes(filter);
    },
    [appliedCategoryFilters]
  );

  const toggleFilter = useCallback(
    async (filter: string) => {
      const isAlreadyApplied = isFilterApplied(filter);
      const nextFilters = getToggledFilters(appliedCategoryFilters, filter, isAlreadyApplied);
      await dispatch(makePatchAppliedFiltersAction({ [filterCategoryKey]: nextFilters }));
    },
    [isFilterApplied, getToggledFilters, appliedCategoryFilters, dispatch, filterCategoryKey]
  );

  return (
    <StyledCardWrapper>
      <StyledAccordion initiallyExpanded expanded={expanded} onExpandedChange={setExpanded}>
        <StyledAccordionHeader $expanded={expanded}>
          {icon}
          <Text typography={'Heading3'} weight={'600'}>
            {title}
          </Text>
        </StyledAccordionHeader>
        <AccordionContent>
          <Box fit column stretch>
            {rowCounts.map(({ key, color, labelLeft, labelRight, progress }, index) => (
              <StyledProgressCard
                key={key}
                $marginTop={index > 0}
                title={isFilterApplied(key) ? t('click_to_remove_filter') : t('click_to_apply_filter')}
                onClick={() => toggleFilter(key)}
              >
                <Box justifyContent={'space-between'} marginBottom_050>
                  <Text typography={'CaptionSmall'}>{labelLeft}</Text>
                  <Text typography={'CaptionSmall'}>{labelRight}</Text>
                </Box>
                <ProgressBar color={color as Color} progress={progress ?? 0} />
              </StyledProgressCard>
            ))}
          </Box>
        </AccordionContent>
      </StyledAccordion>
    </StyledCardWrapper>
  );
};
