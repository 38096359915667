import produce from 'immer';

import * as actionTypes from '../actionTypes';

const initState = {
  isFetching: false,
  actionList: null,
  fetchedAt: null,
};

export const actionReducer = (state = initState, action) => {
  return produce(state, (st) => {
    switch (action.type) {
      case actionTypes.ACTION_START:
        st.isFetching = true;
        break;
      case actionTypes.FETCH_ACTION_SUCCESS:
        st.isFetching = false;
        st.actionList = action.actionList;
        st.fetchedAt = new Date().getTime();
        break;
      case actionTypes.ADD_ACTION_SUCCESS:
        let newActionList = (state.actionList ?? []).map((category) => {
          if (category.category_id === action.action.hht_actions_category) {
            let object = category;
            let array = category.actions;
            let deepCopyObject = Object.assign({}, object);
            let deepCopyArray = Object.assign([], array);
            deepCopyArray.push(action.action);
            return (deepCopyObject = {
              ...deepCopyObject,
              actions: [...deepCopyArray],
            });
          } else return category;
        });
        st.isFetching = false;
        st.actionList = newActionList;
        break;
      case actionTypes.UPDATE_ACTION_SUCCESS:
        let list = (state.actionList ?? []).map((category) => {
          if (category.category_id === action.action.hht_actions_category) {
            let object = category;
            let array = category.actions;
            let deepCopyObject = Object.assign({}, object);
            let deepCopyArray = Object.assign([], array);
            let newActionArray = deepCopyArray.map((item) => {
              if (item.id === action.action.id) return action.action;
              else return item;
            });
            return (deepCopyObject = {
              ...deepCopyObject,
              actions: [...newActionArray],
            });
          } else return category;
        });
        st.isFetching = false;
        st.actionList = list;
        break;
      case actionTypes.ACTION_FAILURE:
        st.isFetching = false;
        break;
      default:
    }
  });
};
