/**
 * This theme object is deprecated.
 * It will be removed once its usage is completely replaced.
 * */

const theme = {
  // general:
  backgroundColor: '#f9f7f3',
  modalOverlayBgColor: 'rgba(153,153,153,.9)',
  scrollbarThumbColor: '#CCCCCC',
  nectarColor: '#D8A348',
  nectarLogoFilter: 'invert(64%) sepia(98%) saturate(303%) hue-rotate(355deg) brightness(91%) contrast(85%)',

  // colors:
  primaryColor: '#252629', // charcoal
  primaryColorFilter: 'invert(23%) sepia(9%) saturate(719%) hue-rotate(120deg) brightness(93%) contrast(89%)',
  primaryBackgroundColor: '#FFFFFF',
  primaryBackgroundColorFilter:
    'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);',
  supportingColor: '#878E8D',
  supportingColorFilter: 'invert(35%) sepia(82%) saturate(0%) hue-rotate(232deg) brightness(76%) contrast(53%)',
  hoverColorFilter: 'invert(59%) sepia(4%) saturate(385%) hue-rotate(122deg) brightness(94%) contrast(86%)',
  inputColor: '#D4D4D4',
  inputColorFilter: 'invert(81%) sepia(0%) saturate(0%) hue-rotate(232deg) brightness(102%) contrast(102%)',
  cardColor: '#E6E6E6',
  selectedColor: '#F7BD13',
  boxShadowColor: '#71A3FB',
  pendingTextBackground: '#FFF4D0',
  defaultBeekPracticeColor: '#E1E3E3',
  requiringAttentionColor: '#78500D',
  requiringAttentionBackgroundColor: '#FFF4D0',

  /* @see https://codepen.io/sosuke/pen/Pjoqqp for filter conversion*/
  goodColor: '#A4D96C', //good
  gColor: '#A4D96C', //good
  goodSvgPictoFilter: 'invert(97%) sepia(69%) saturate(891%) hue-rotate(28deg) brightness(88%) contrast(93%)',
  worryingColor: '#FFCF80', // worrying
  wColor: '#FFCF80', // worrying
  worryingSvgPictoFilter: 'invert(92%) sepia(54%) saturate(7356%) hue-rotate(305deg) brightness(108%) contrast(105%)',
  criticalColor: '#D9766D', // critical
  cColor: '#D9766D', // critical
  criticalSvgPictoFilter: 'invert(65%) sepia(38%) saturate(3953%) hue-rotate(319deg) brightness(100%) contrast(71%)',
  unknownColor: '#878E8D', // unknown
  unknownSvgPictoFilter: 'invert(41%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(94%) contrast(85%)',

  noGoodYardColor: '#D9766D80', //beehub or all beecons not transmitting is critical color with opacity 50%
  snackFilter: 'invert(88%) sepia(30%) saturate(661%) hue-rotate(33deg) brightness(89%) contrast(91%)',
  iconFilter: 'invert(60%) sepia(9%) saturate(157%) hue-rotate(122deg) brightness(91%) contrast(93%)',
  iconUncheckedFilter: 'invert(99%) sepia(1%) saturate(3048%) hue-rotate(216deg) brightness(111%) contrast(66%)',
  iconDisabledFilter: 'invert(99%) sepia(1%) saturate(3048%) hue-rotate(216deg) brightness(111%) contrast(66%)',

  // buttons:
  buttonPrimaryColor: '#252629',
  buttonPrimaryTextColor: '#FFFFFF',
  buttonPrimaryDisabledColor: '#D4D4D4',
  buttonPrimaryDisabledTextColor: '#878E8D',
  buttonSecondaryColor: '#F9F7F3',
  buttonSecondaryDisabledColor: '#AFB3B3',
  buttonStaticTextColor: '#4A4A4A',

  buttonIconColor: 'transparent',
  buttonIconHoverColor: 'rgba(0, 0, 0, 0.12)',
  buttonIconFocusColor: 'rgba(0, 0, 0, 0.12)',
  buttonIconActiveColor: 'rgba(0, 0, 0, 0.24)',

  // text
  primaryTextColor: '#252629',
  secondaryColorFilter: 'invert(41%) sepia(7%) saturate(0%) hue-rotate(190deg) brightness(86%) contrast(70%)',
  mutedTextColor: '#aaaaaa',
  hoverColor: '#F7F7F7',

  // alerts and events
  timelineColor: '#F2F2F2',
  alertsColor: '#262E2D',
  queenlessColor: '#D9766D',
  queenlessFilter: 'invert(58%) sepia(19%) saturate(1402%) hue-rotate(316deg) brightness(93%) contrast(81%)',
  deadoutColor: '#878E8D',
  deadoutFilter: 'invert(25%) sepia(8%) saturate(637%) hue-rotate(120deg) brightness(95%) contrast(98%);',
  queenrightColor: '#252629',
  queenrightColorFilter: 'invert(23%) sepia(9%) saturate(719%) hue-rotate(120deg) brightness(93%) contrast(89%)',

  yardBoundariesColor: 'rgb(140,182,253)',
  editableYardBoundariesColor: 'rgb(247,189,19)',

  // typography:
  fontStack:
    "'Open Sans',\n" +
    '-apple-system,\n' +
    '  BlinkMacSystemFont,\n' +
    '  "Segoe UI",\n' +
    '  Roboto, Helvetica,\n' +
    '  Arial, sans-serif',
  tooltipSmallFontSize: '0.625rem', // 10px
  captionSmallFontSize: '0.75rem', // 12px
  primaryCTAFontSize: '1rem', // 16px
  rowsNumbersFontSize: '0.88rem', // 14px
  paragraphSmallFontSize: '0.88rem', // 14px
  paragraphFontSize: '1rem', // 16px
  heading3MobileFontSize: '0.7rem',
  heading3FontSize: '0.88rem', // 14px
  heading2MobileFontSize: '1.25rem', // 20px
  heading2FontSize: '1.5rem', // 24px
  heading1MobileFontSize: '1.5rem', // 24px
  heading1FontSize: '2rem', // 32px

  fontWeightRegular: '400',
  fontWeightSemiBold: '600',
  fontWeightBold: '700',
  defaultLetterSpacing: '1px',
  defaultLineHeight: '1',
  normalLineHeight: '1.5',
  defaultHoverOpacity: '60%',

  // drop shadows
  boxShadow1: '0 1px 3px rgba(0,0,0,0.05),  0 1px 3px rgba(0,0,0,0.10)',
  boxShadow2: '0 3px 6px rgba(0,0,0,0.05),  0 3px 6px rgba(0,0,0,0.10)',
  boxShadow3: '0 10px 20px rgba(0,0,0,0.075),  0 6px 6px rgba(0,0,0,0.075)',
  boxShadow4: '0 14px 28px rgba(0,0,0,0.10),  0 10px 10px rgba(0,0,0,0.15)',

  selectFocusBoxShadow: '0 0 4px 2px #71A3FB',
  navigationBoxShadow: 'inset 0 -4px #D8A348',
  formSelectorBoxShadow: 'inset 0 -2px #252629',
  buttonFocusBoxShadow: '0 0 4px 2px #71A3FB',
  tableRowBoxShadow: 'inset 0 -1px 0 0 #E6E6E6',

  defaultBorderRadius: '3px',

  primaryBorderStyle: '1px solid #E6E6E6',
  secondaryBorderStyle: '1px solid #D4D4D4',
  tertiaryBorderStyle: '1px solid #f2f2f2',
  buttonDarkBorderStyle: '2px solid #878E8D',
  buttonLightBorderStyle: '2px solid #D4D4D4',
  buttonHoverStyle: '2px solid white',
  buttonLightHoverStyle: '2px solid #252629',
  errorBorderStyle: '1px solid #D9766D',
  tooltipBorderStyle: '1px solid #5F6867',
  tooltipDashedBorderStyle: '1px dashed rgba(55,67,65,0.6)',
  tooltipHoveredBorderStyle: '1px dashed #252629',
  dropdownHoverStyle: 'rgba(102, 102, 102, 0.05)',

  // interface
  maxDesktopWidth: '640px',

  // modal specs
  modalFooterHeight: '64px',
  maxModalWidth: '540px',
  maxSmallModalWidth: '420px',
  hiveModalHeight: '90vh',
  smallHiveModalHeight: '560px',
  mapModalHeight: '264px',

  topBarHeight: 72,
  topBottomMargin: 96,
  tabBarHeight: 56,
  weatherCardHeight: 308,
  errorBarHeight: 48,
  activityHeaderHeight: 64,
  defaultHeaderHeight: 88,

  deadoutStyle: {
    color: '#252629',
    backgroundColor: '#E1E3E3',
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    letterSpacing: '1px',
    borderRadius: '4px',
  },
  queenlessStyle: {
    color: '#D9766D',
    backgroundColor: '#F8EBE8',
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    letterSpacing: '1px',
    borderRadius: '4px',
  },
  queenrightStyle: {
    color: '#252629',
    backgroundColor: '#DBF0C4',
  },
  weakGradingColor: '#F8EBE8',
  mediumGradingColor: '#FFF4D0',
  strongGradingColor: '#DBF0C4',

  animationDurationFast: '200ms',
  animationDurationMedium: '250ms',
  animationDurationLong: '300ms',
  animationEasing: 'cubic-bezier(0.35, 0.75, 0.75, 0.35)',

  snackbarLevelIndex: 9999,
};

export default theme;
