import React from 'react';

import { NavRouterLink } from '@components/common/CTA';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { DefaultEmptyCell } from '@components/common/DynamicTable/DefaultCell/DefaultEmptyCell';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';

export const PreviousYard: React.VFC<{ id: number | null; name: string | null; contractName: string | null }> = ({
  id,
  name,
}) => {
  if (!id || !name) {
    return <DefaultEmptyCell />;
  }

  return (
    <DefaultCellWrapper>
      <NavRouterLink tertiary to={URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: id } })}>
        <DefaultCellText htmlTag="span">{name}</DefaultCellText>
      </NavRouterLink>
    </DefaultCellWrapper>
  );
};
