import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputCalendar } from '@components/form/inputs/InputCalendar';
import { InputCropTypeAutocomplete } from '@components/form/inputs/InputCropTypeAutocomplete';
import { InputNumber } from '@components/form/inputs/InputNumber';
import { InputText } from '@components/form/inputs/InputText';
import { StyledErrorMessage } from '@components/form/inputs/InputText/styles';

import { StyledBorderWrapper, StyledInputPairWrapper, StyledInputWrapper, StyledOverviewForm } from './styles';
import { OverviewFormSectionProps } from './types';

export const FormSectionOverview: React.FC<OverviewFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  defaultState,
  handleFormSectionSubmit,
}) => {
  const { t } = useTranslation();
  const [localFormState, setLocalFormState] = useState<BeeContractOverview>(defaultState);
  const firstInputWrapperRef = useRef<HTMLFieldSetElement>(null);

  const defaultValues = useFormDefaultValues<BeeContractOverview>(defaultState);
  const { resolver, shape } = useFormSchemaResolver<BeeContractOverview>((schema) => ({
    name: schema.string().required(),
    nbRequiredHives: schema
      .number()
      .min(1, t('pollination_required_hives_error'))
      .integer()
      .required(t('pollination_required_hives_error')),
    beesIn: schema.date().nullable(),
    beesOut: schema.date().nullable(),
    cropTypesIds: schema.array(),
  }));
  const form = useForm({ defaultValues, resolver });

  const handleFormFieldChange = useCallback((value: unknown, name: string) => {
    setLocalFormState((oldState) => ({ ...oldState, [name]: value }));
  }, []);

  const hasCalendarErrors = useMemo(
    () => localFormState['beesIn'] && localFormState['beesOut'] && localFormState['beesIn'] > localFormState['beesOut'],
    [localFormState]
  );

  const onFormValidSubmit = useCallback(
    (data) => {
      const { name, nbRequiredHives, cropTypesIds } = shape.cast(data);
      if (!hasCalendarErrors) {
        handleFormSectionSubmit({ ...localFormState, name, nbRequiredHives, cropTypesIds } as BeeContractOverview);
      }
    },
    [localFormState, shape, hasCalendarErrors, handleFormSectionSubmit]
  );

  return (
    <StyledOverviewForm
      id={`contractForm-section-${section}`}
      form={form}
      onValidSubmit={onFormValidSubmit}
      $isActive={isActive}
      $activeSection={activeSection}
    >
      <fieldset ref={firstInputWrapperRef}>
        <InputText
          id="contractForm-name"
          name="name"
          label={t('pollination_contract_name')}
          placeholder={t('pollination_contract_name')}
          readOnly={!isActive}
          autoFocus
          required
        />
      </fieldset>

      <fieldset>
        <Text typography="Legend" htmlTag="legend" weight={'600'} color="grey06">
          {t('pollination_details')}
        </Text>

        <StyledInputWrapper>
          <InputCropTypeAutocomplete name="cropTypesIds" readOnly={!isActive} />
        </StyledInputWrapper>
      </fieldset>

      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('Hives')}
        </Text>

        <StyledInputWrapper>
          <InputNumber
            id="contractForm-nbHives"
            name="nbRequiredHives"
            label={t('pollination_required_hives')}
            placeholder={t('pollination_required_hives_placeholder')}
            readOnly={!isActive}
            required
          />
        </StyledInputWrapper>
      </fieldset>

      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('dates')}
        </Text>

        <StyledInputPairWrapper>
          <InputCalendar
            label={t('pollination_bees_in')}
            id={'contractForm-beesIn'}
            name={'beesIn'}
            value={localFormState['beesIn']}
            onChange={handleFormFieldChange}
            maxDate={localFormState['beesOut'] ?? undefined}
          />
          <InputCalendar
            label={t('pollination_bees_out')}
            id={'contractForm-beesOut'}
            name={'beesOut'}
            value={localFormState['beesOut']}
            onChange={handleFormFieldChange}
            minDate={localFormState['beesIn'] ?? undefined}
          />
        </StyledInputPairWrapper>
        {hasCalendarErrors && <StyledErrorMessage>{t('pollination_bees_in_greater_than_bees_out')}</StyledErrorMessage>}
      </fieldset>
    </StyledOverviewForm>
  );
};
