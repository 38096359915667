import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { isFunction } from 'lodash';

import { ModalOpenerReturn } from '@components/common/ModalBase/hooks';
import { PolliMapContext } from '@components/pollination/PolliMap/context';
import {
  AnyInstance,
  isUncommitedInstance,
  PolliMapContextValue,
  PolliMapMode,
  TransientInstance,
  TransientState,
} from '@components/pollination/PolliMap/types';
import { PolliMapUtil } from '@components/pollination/PolliMap/util';
import { HexID } from '@helpers/HexID';
import { useQueryParamsState } from '@helpers/QueryParams/hooks';

export function usePolliMap(): PolliMapContextValue {
  const polliMap = useContext(PolliMapContext);

  if (!polliMap) {
    throw new Error('PolliMap elements must be rendered inside a PolliMap context provider.');
  }

  return polliMap;
}

export function useQueryParamsSelector<T extends TransientInstance>(
  prefix: string,
  elements: TransientState<T>
): [AnyInstance<T> | null, Dispatch<SetStateAction<AnyInstance<T> | null>>] {
  const paramName = `${prefix}-item`;
  const [params, setParams] = useQueryParamsState();
  const [selectedElement, setSelectedElement] = useState<AnyInstance<T> | null>(null);
  const { getKey } = useTransientHelperMethods();

  const getItemFromParams = useCallback(
    (params) => {
      const key: any = params[paramName] ?? '';
      const selected = selectedElement && key === getKey(selectedElement) ? selectedElement : null;
      const item = (selected || elements.added[key] || elements.edited[key]) ?? null;
      return { key, item };
    },
    [elements.added, elements.edited, getKey, paramName, selectedElement]
  );

  const setter = useCallback(
    (valueOrFunction: SetStateAction<AnyInstance<T> | null>) => {
      setParams((currentParams) =>
        produce(currentParams, (currentParams) => {
          let value;
          if (isFunction(valueOrFunction)) {
            const { item } = getItemFromParams(currentParams);
            value = valueOrFunction(item);
          } else {
            value = valueOrFunction;
          }
          if (value === null) {
            currentParams[paramName] = '';
            setSelectedElement(null);
          } else {
            currentParams[paramName] = getKey(value);
            setSelectedElement(value);
          }
        })
      );
    },
    [getItemFromParams, getKey, paramName, setParams]
  );

  // Clear any existing transient id.
  useEffect(() => {
    const { key } = getItemFromParams(params);
    if (HexID.isHexID(key)) {
      setter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { item } = getItemFromParams(params);
    setSelectedElement(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params[paramName], elements]);

  return [selectedElement, setter];
}

export function useTransientState<T extends TransientInstance>(
  prefix: string
): [
  TransientState<T>,
  Dispatch<SetStateAction<TransientState<T>>>,
  AnyInstance<T> | null,
  Dispatch<SetStateAction<AnyInstance<T> | null>>
] {
  const [elements, setElements] = useState<TransientState<T>>({ added: {}, edited: {}, removed: {} });
  const [selectedElement, setSelectedElement] = useQueryParamsSelector(prefix, elements);
  return [elements, setElements, selectedElement, setSelectedElement];
}

export function useTransientStateList<T>(state: TransientState<T>): AnyInstance<T>[] {
  return useMemo(() => [...Object.values(state.added), ...Object.values(state.edited)], [state.added, state.edited]);
}

export function useTransientHelperMethods() {
  const getKey = useCallback(
    (instance: AnyInstance<TransientInstance>) =>
      isUncommitedInstance(instance) ? instance.tempHexID : String(instance.id),
    []
  );

  const isAdding = useCallback(
    (instance: AnyInstance<any>, state: TransientState<any>) =>
      Boolean(instance && isUncommitedInstance(instance) && !state.added[instance.tempHexID]),
    []
  );

  const isEditing = useCallback(
    (instance: AnyInstance<any>, state: TransientState<any>) =>
      Boolean(
        instance && (isUncommitedInstance(instance) ? state.added[instance.tempHexID] : state.edited[instance.id])
      ),
    []
  );

  return { getKey, isAdding, isEditing };
}

export function useTransientSelector<T extends TransientInstance>(
  selectionSetter: Dispatch<SetStateAction<AnyInstance<T> | null>>,
  selectionCleaner: (instance: AnyInstance<T> | null) => void
) {
  return useCallback(
    (instance: AnyInstance<T> | null) => {
      selectionCleaner(instance);
      selectionSetter(instance);
    },
    [selectionCleaner, selectionSetter]
  );
}

export function useTransientUpdater<T extends TransientInstance>(
  stateSetter: Dispatch<SetStateAction<TransientState<T>>>
) {
  return useCallback(
    (instance: AnyInstance<T>) =>
      stateSetter((curr: TransientState<any>) =>
        produce(curr, (curr) => {
          if (isUncommitedInstance(instance)) {
            curr.added[instance.tempHexID] = instance;
          } else {
            const hasChanged = !PolliMapUtil.areInstancesEquals(instance, curr.edited[instance.id]);
            curr.edited[instance.id] = { ...instance, hasChanged };
          }
        })
      ),
    [stateSetter]
  );
}

export function useTransientRemover<T extends TransientInstance>(
  stateSetter: Dispatch<SetStateAction<TransientState<T>>>,
  selectionCleaner: () => void,
  modalTrigger: ModalOpenerReturn['open'],
  modalTranslationBase: string,
  onAcceptCallback: () => void
) {
  const { t } = useTranslation();

  return useCallback(
    (instance: AnyInstance<any>) => {
      modalTrigger({
        headerProps: {
          title: t(modalTranslationBase + '_title'),
          subtitle: <span dangerouslySetInnerHTML={{ __html: t(modalTranslationBase + '_message', instance) }} />,
        },
        footerProps: {
          acceptText: t('delete'),
          rejectText: t('cancel'),
          autoCloseOnReject: true,
          autoCloseOnAccept: true,
          onAcceptClick: () => {
            stateSetter((curr: TransientState<any>) =>
              produce(curr, (curr) => {
                if (isUncommitedInstance(instance)) {
                  delete curr.added[instance.tempHexID];
                } else {
                  curr.removed[instance.id] = instance;
                  delete curr.edited[instance.id];
                }
              })
            );
            selectionCleaner();
            onAcceptCallback();
          },
        },
      });
    },
    [modalTranslationBase, modalTrigger, onAcceptCallback, selectionCleaner, stateSetter, t]
  );
}

export function useTransientFormHandlers<T extends TransientInstance>(
  state: TransientState<T>,
  updater: (instance: AnyInstance<T>) => void,
  selector: (instance: AnyInstance<T>) => void,
  selectedElement: AnyInstance<T> | null,
  selectionCleaner: () => void,
  targetMode: PolliMapMode,
  modeSetter: (mode: PolliMapMode) => void
) {
  const { getKey, isAdding } = useTransientHelperMethods();

  const submitChange = useCallback(() => {
    if (selectedElement) {
      updater({ ...selectedElement });
      modeSetter(targetMode);

      if (isAdding(selectedElement, state)) {
        selectionCleaner();
      }
    }
  }, [isAdding, modeSetter, selectedElement, selectionCleaner, state, targetMode, updater]);

  const cancelChange = useCallback(() => {
    modeSetter(targetMode);
    if (selectedElement) {
      if (isAdding(selectedElement, state)) {
        selectionCleaner();
      } else {
        const key = getKey(selectedElement) as any;
        const originalState = state.added[key] || state.edited[key];
        selector(originalState);
      }
    }
  }, [getKey, isAdding, modeSetter, selectedElement, selectionCleaner, selector, state, targetMode]);

  return [submitChange, cancelChange];
}
