import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';
import {
  LoadingSkeletonCircleProps,
  LoadingSkeletonProps,
  LoadingSkeletonRectProps,
} from '@components/common/LoadingSkeleton/types';
import { from } from '@style/mediaQueries';

const fadeInKeyframes = keyframes`
  0% { opacity: 0; } 100% { opacity: 1; }
`;

const fadeOutKeyframes = keyframes`
  0% { opacity: 1; } 100% { opacity: 0; }
`;

const fadeInAnimation = css(
  ({ theme }) => css`
    opacity: 1;
    animation: ${fadeInKeyframes} 400ms ${theme.animations.easing};
  `
);

const fadeOutAnimation = css(
  ({ theme }) => css`
    opacity: 0;
    animation: ${fadeOutKeyframes} 400ms ${theme.animations.easing};
  `
);

const flashingKeyframes = keyframes`
  0%, 100% { opacity: 0.2; }
  30%, 70% { opacity: 1; }
`;

const flashingAnimation = css(
  ({ theme }) => css`
    opacity: 0.2;
    animation: ${flashingKeyframes} 2000ms ${theme.animations.easing} infinite;
  `
);

export const StyledLoadingSkeleton = styled(Box)<LoadingSkeletonProps>(
  ({ theme, visible, fitParent, absolutelyFitParent, enableNavbarMargin }) => css`
    ${fitParent &&
    css`
      width: 100%;
      height: 100%;
    `}

    ${absolutelyFitParent &&
    css`
      position: absolute;
      inset: 0;
    `}

    ${enableNavbarMargin &&
    css`
      margin-top: ${theme.navigation.headerHeightMobile}px;
      ${from('tablet')} {
        margin-top: ${theme.navigation.headerHeight}px;
      }
    `}

    ${visible ? fadeInAnimation : fadeOutAnimation};
    pointer-events: none;
  `
);

export const StyledLoadingSkeletonRectangle = styled(Box)<Required<LoadingSkeletonRectProps>>(
  ({ theme, backgroundColor, borderRadius, width, height }) => css`
    border-radius: ${theme.shape[borderRadius]}px;
    background-color: ${theme.colors.getPrimitiveOrGlobalColor(backgroundColor)};

    ${css({ width, height })}
    ${flashingAnimation}
  `
);

export const StyledLoadingSkeletonCircle = styled(Box)<Required<LoadingSkeletonCircleProps>>(
  ({ theme, backgroundColor, size }) => css`
    border-radius: 50%;
    background-color: ${theme.colors.getPrimitiveOrGlobalColor(backgroundColor)};

    ${css({ width: size, height: size })}
    ${flashingAnimation}
  `
);
