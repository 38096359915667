import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';
import { from, until } from '@style/mediaQueries';

export const StyledDashboardContainer = styled(Box).attrs({
  fit: true,
  gap_100: true,
})(
  () => css`
    ${until('tablet')} {
      flex-direction: column;
    }
  `
);

export const StyledResponsiveRow = styled(Box)(
  () => css`
    min-width: 145px;
    flex-direction: row;

    ${until('desktopSM')} {
      flex-direction: column;
      align-items: stretch;
    }
  `
);

export const StyledCard = styled(Box).attrs({
  paper01: true,
  backgroundColor: 'white',
  borderColor: 'grey03',
  borderWidth: 1,
})<{ $isFlex?: boolean }>(
  ({ $isFlex }) => css`
    overflow: visible;

    ${$isFlex &&
    css`
      flex: 1 1 0;
    `}

    ${from('desktopSM')} {
      overflow: visible;
    }
  `
);

export const StyledAboutItem = styled(Box)(
  () => css`
    min-width: 145px;
  `
);

export const StyledNotesUpdatedLabel = styled(Text).attrs({
  typography: 'SmallParagraph',
  color: 'grey07',
})(
  () => css`
    font-style: italic;
  `
);

export const StyledMapWrapper = styled(Box)(
  () => css`
    position: relative;
    width: 100%;
    min-height: 316px;
    pointer-events: none;
  `
);

export const StyledOpenOnMapButton = styled(Button).attrs({
  primary: true,
  suppressPadding: true,
})(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing._100};
    bottom: ${theme.spacing._100};
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    pointer-events: auto;
  `
);

export const StyledSummary = styled(Box)(
  () => css`
    width: 100%;

    ${from('desktopSM')} {
      width: 350px;
      min-height: 640px;
    }
  `
);

export const StyledSummaryItem = styled(Box).attrs({
  alignItems: 'center',
  gap_050: true,
})(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing._100};

    &:not(:last-child) {
      border-bottom: ${theme.primitives.colors.grey03} 1px solid;
    }

    svg {
      min-width: 16px;
    }
  `
);

export const StyledSummaryValueTitleWrapper = styled(Box)(
  ({ theme }) => css`
    flex: 1;

    // Takes into account two 'xs' gaps plus the icon size.
    max-width: calc(50% - 2 * ${theme.spacing._050} + 16px);
  `
);

export const StyledSummaryValueTitle = styled(Text).attrs({ typography: 'Heading3', weight: '600' })(() => css``);

export const StyledSummaryValueTextWrapper = styled(Box)(
  () => css`
    justify-content: flex-end;
    width: 50%;
  `
);

export const StyledSummaryValueText = styled(Text)(
  () => css`
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  `
);

export const StyledGroupDot = styled.span<{ $color: string }>(
  ({ theme, $color }) => css`
    background-color: ${$color};
    border-radius: ${theme.shape.paperRadius01}px;
    margin: ${theme.spacing._050} 0;
    width: 8px;
    height: 8px;
  `
);
