import { AssignContractState } from '@redux/AssignContract/types';
import { AuthState } from '@redux/Auth/types';
import { BulkClearOutYardsState } from '@redux/BulkClearOutYards/types';
import { BulkDeleteYardsState } from '@redux/BulkDeleteYards/types';
import { ContractState } from '@redux/Contract/types';
import { ContractsMetricsState } from '@redux/ContractsMetrics/types';
import { CropTypeState } from '@redux/CropType/types';
import { DataStatusState } from '@redux/DataStatus/types';
import { HiveDetailsState } from '@redux/HiveDetails/types';
import { OperationState } from '@redux/Operation/types';
import { PracticesState } from '@redux/Practices/types';
import { RenewContractState } from '@redux/RenewContract/types';
import { ReportsState } from '@redux/Reports/types';
import { SnackbarState } from '@redux/Snackbar/types';
import { TimelineState } from '@redux/Timeline/types';
import { YardClearOrDeleteState } from '@redux/YardClearOrDelete/types';
import { YardCreateOrUpdateState } from '@redux/YardCreateOrUpdate/types';
import { YardDetailsState } from '@redux/YardDetails/types';
import { YardsState } from '@redux/Yards/types';
import { YardsFiltersState } from '@redux/YardsFilters/types';
import { YardsGlobalSearchState } from '@redux/YardsGlobalSearch/types';

type AccountState = {
  isFetching: boolean;
  user: User;
  permissions: BeePermissions;
};

type WorkerState = {
  isFetching: boolean;
  workers: Worker[];
  sortedWorkerList: Worker[];
  order_by: 'status' | string; // todo: fill order_by and order_direction out with all possible values
  order_direction: 'DESC' | 'ASC' | string;
};

type BeeTrackYardListState = {
  // TODO: Add fix prop typings
  yard: Yard | null;
  isFetching: boolean;

  isFetchingYard: boolean;
  isFetchingUpdateYard: boolean;
  isFetchingDeleteYard: boolean;
  isFetchingYardsListSummary: boolean;
  yardsList: any;
  yardsListSummary: any;
  yardsSearchHistory: any;
  yardsMap: any;
  yards: any;
  hives: any;
  fetchedAt: any;
  order_by: any;
  order_direction: any;
  patch: any;
  remove: any;
  add: any;
  offset: any;
  limit: any;
  reduced_offset: any;
  totalNumOfYards: any;
  numOfPages: any;
};

type GroupsState = {
  // TODO: Add remaining props as needed.
  isFetching: boolean;
  groups: Array<BeeGroup> | null;
};

export interface RootState {
  authReducer: AuthState;

  yardsReducer: YardsState;
  yardsFiltersReducer: YardsFiltersState;
  yardsGlobalSearchReducer: YardsGlobalSearchState;
  yardDetailsReducer: YardDetailsState;
  yardClearOrDeleteReducer: YardClearOrDeleteState;
  yardCreateOrUpdateReducer: YardCreateOrUpdateState;
  operationReducer: OperationState;
  cropTypeReducer: CropTypeState;
  contractReducer: ContractState;
  contractsMetricsReducer: ContractsMetricsState;
  assignContractReducer: AssignContractState;
  renewContractReducer: RenewContractState;
  hiveDetailsReducer: HiveDetailsState;
  timelineReducer: TimelineState;
  reportsReducer: ReportsState;
  snackbarReducer: SnackbarState;
  dataStatusReducer: DataStatusState;
  practicesReducer: PracticesState;
  bulkClearOutYardsReducer: BulkClearOutYardsState;
  bulkDeleteYardsReducer: BulkDeleteYardsState;

  /** deprecated reducers */
  accountReducer: AccountState;
  actionReducer: any;
  appReducer: any;
  batchYardsReducer: any;
  beeTrackHiveListReducer: any;
  beeTrackYardListReducer: BeeTrackYardListState;
  changePasswordReducer: any;
  eventListReducer: any;
  exportReducer: any;
  fetchReducer: any;
  forgottenPasswordReducer: any;
  groupsPasswordReducer: any;
  loginReducer: any;
  mapReducer: any;
  modalReducer: any;
  navBarTitleReducer: any;
  notificationCountReducer: any;
  recreatePasswordReducer: any;
  translationsReducer: any;
  workerReducer: WorkerState;
  groupsReducer: GroupsState;
}

export type RootAction = { type: RootActionType.ROOT_DISPOSE_STATE } | { type: RootActionType.ROOT_DISPOSE_STORAGE };

export enum RootActionType {
  ROOT_DISPOSE_STATE = 'ROOT_DISPOSE_STATE',
  ROOT_DISPOSE_STORAGE = 'ROOT_DISPOSE_STORAGE',
}
