import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useGridApi } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { DynamicTableRow } from '@components/common/DynamicTable/types';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Menu } from '@components/common/Menu';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useTranslation } from '@hooks/useTranslation';
import { makeRetireHiveThunk } from '@redux/HiveRetire/actions';

export const MenuCell: React.FC<DynamicTableRow> = (gridParamData) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { gridApi } = useGridApi();
  const retireAsDeadoutModal = useDynamicModal();
  const removeHiveModal = useDynamicModal();

  const onAcceptClick = useCallback(
    async (status: 'deadout' | 'removed') => {
      await dispatch(makeRetireHiveThunk({ status, ids: [gridParamData.meta.id] }));

      gridApi?.refreshInfiniteCache();
      Analytics.sendEvent({
        event: status === 'deadout' ? AnalyticsEventType.LOST_HIVE_DEADOUT : AnalyticsEventType.LOST_HIVE_REMOVE,
      });
    },
    [dispatch, gridApi, gridParamData.meta.id]
  );

  const retireAsDeadoutModalProps = useMemo(
    () => ({
      headerProps: {
        title: t('flag_1_hive_as_deadout'),
        subtitle: t('flag_hive_deadout_desc'),
      },
      footerProps: {
        acceptText: t('flag_as_deadout'),
        acceptButtonProps: { danger: true, primary: true },
        onAcceptClick: () => onAcceptClick('deadout'),
        autoCloseOnAccept: true,
        rejectText: t('cancel'),
        rejectButtonProps: { tertiary: true },
        autoCloseOnReject: true,
      },
    }),
    [onAcceptClick, t]
  );

  const removeHiveModalProps = useMemo(
    () => ({
      headerProps: {
        title: t('remove_1_hive'),
        subtitle: t('remove_hive_desc'),
      },
      footerProps: {
        acceptText: t('remove_hive'),
        acceptButtonProps: { danger: true, primary: true },
        onAcceptClick: () => onAcceptClick('removed'),
        autoCloseOnAccept: true,
        rejectText: t('cancel'),
        rejectButtonProps: { tertiary: true },
        autoCloseOnReject: true,
      },
    }),
    [onAcceptClick, t]
  );

  const handleFlagAsDeadout = useCallback(
    (e) => {
      e.stopPropagation();
      retireAsDeadoutModal.open(retireAsDeadoutModalProps);
    },
    [retireAsDeadoutModalProps, retireAsDeadoutModal]
  );

  const handleRemoveHive = useCallback(
    (e) => {
      e.stopPropagation();
      removeHiveModal.open(removeHiveModalProps);
    },
    [removeHiveModal, removeHiveModalProps]
  );

  const menuItems = [
    { title: t('flag_as_deadout'), icon: Deadout, onClick: handleFlagAsDeadout },
    { title: t('remove_hive'), icon: Delete, onClick: handleRemoveHive },
  ];

  return gridParamData.meta.id ? (
    <Box fit justifyContent={'flex-end'} relative>
      <ThreeDotsMenuTrigger id={`menu-${gridParamData.meta.id}`} />
      <Menu items={menuItems} placement={'bottom-end'} target={`menu-${gridParamData.meta.id}`} />
      {retireAsDeadoutModal.content}
      {removeHiveModal.content}
    </Box>
  ) : null;
};
