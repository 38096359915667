import React from 'react';

import { GridApiProvider } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { DynamicTableSelectAllAcrossPages } from '@components/common/DynamicTable';
import { DynamicTableSelectionProvider } from '@components/common/DynamicTable/context/DynamicTableSelectionProvider';
import {
  DynamicTableEmpty,
  DynamicTableEmptyHeader,
  DynamicTableEmptyText,
} from '@components/common/DynamicTable/DynamicTableEmpty';
import { useDynamicTableStates } from '@components/common/DynamicTable/hooks';
import { Link } from '@components/common/Icon/presets/Link';
import { PageContent, PageContentHeader } from '@components/common/PageContent';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { Text } from '@components/common/Text';
import { HivesList } from '@components/yard/HivesList';
import {
  LOST_HIVES_COL_DEFS,
  LOST_HIVES_COLS_KEYS,
  LOST_HIVES_PERMANENT_LEFT_COL_DEFS,
  LOST_HIVES_PERMANENT_RIGHT_COL_DEFS,
} from '@components/yard/HivesList/constants';
import APP from '@config/constants';
import { useIntercomArticleOpener } from '@helpers/Intercom/hooks';
import { useTranslation } from '@hooks/useTranslation';

import { LostHivesBulkActions } from './LostHivesBulkActions';

const HivesListEmpty: React.VFC = () => {
  const { hasLoaded, isListEmpty } = useDynamicTableStates();
  const t = useTranslation();

  const showEmptyList = hasLoaded && isListEmpty;
  return (
    <DynamicTableEmpty visible={showEmptyList} backgroundColor="surfaceDefault">
      <DynamicTableEmptyHeader>{t('no_lost_hives')}</DynamicTableEmptyHeader>
      <DynamicTableEmptyText>{t('no_lost_hives_msg')}</DynamicTableEmptyText>
    </DynamicTableEmpty>
  );
};

export const LostHives: React.VFC = () => {
  const t = useTranslation();
  return (
    <Box column stretch>
      <PageHeader>
        <PageHeaderTitle>{t('lost_hives_page_title')}</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <PageContentHeader>
          <Text typography="SmallParagraph" lineHeight="24px">
            <span>
              {t('lost_hives_page_info_part1')} <br />
            </span>
            <span>{t('lost_hives_page_info_part2')}</span>
          </Text>
          <Box marginTop_050 gap_025 alignItems="center">
            <Button onClick={useIntercomArticleOpener(APP.intercomArticles.MANAGE_LOST_HIVES)} tertiary suppressPadding>
              {t('lost_hives_help_center')}
            </Button>
            <Link />
          </Box>
        </PageContentHeader>
        <Box relative block>
          <GridApiProvider>
            <DynamicTableSelectionProvider>
              <DynamicTableSelectAllAcrossPages
                allPagesSelectedMessage={'hive_all_x_hives_are_selected'}
                somePagesSelectedMessage={'hive_x_hives_selected'}
                selectButtonTitle={'hive_select_all_x_hives'}
                deselectButtonTitle={'hive_clear_selection'}
              />
              <HivesList
                columnDefinitions={LOST_HIVES_COL_DEFS}
                apiParams={{ is_lost: true, hive_status: 'alive', columns: LOST_HIVES_COLS_KEYS }}
                config={{
                  permanentRightColumns: LOST_HIVES_PERMANENT_RIGHT_COL_DEFS,
                  permanentLeftColumns: LOST_HIVES_PERMANENT_LEFT_COL_DEFS,
                  defaultSorting: [{ field: 'lostSince', sort: 'desc' }],
                }}
              />

              <HivesListEmpty />
              <LostHivesBulkActions />
            </DynamicTableSelectionProvider>
          </GridApiProvider>
        </Box>
      </PageContent>
    </Box>
  );
};
