import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { PRESETS } from '@components/common/Text/styles';
import { ContentAppearingTransition } from '@components/common/Transition/ContentAppearingTransition';
import { from, until } from '@style/mediaQueries';

export const StyledOperationSelector = styled(ContentAppearingTransition)(
  ({ theme }) => css`
    flex-direction: column;
    border-radius: ${theme.borderRadius._050};

    ${from('tablet')} {
      overflow: hidden auto;
    }
  `
);

export const StyledOperationSelectorItemsWrapper = styled(ContentAppearingTransition)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius._050};

    ${until('mobile')} {
      border: 1px ${theme.colors.borderSecondary} solid;
    }

    & > button:not(:nth-last-child(-n + 2)) {
      border-bottom-color: ${theme.colors.borderSecondary};
      border-bottom-style: solid;
    }
  `
);

export const StyledOperationItem = styled(Button).attrs({ suppressPadding: true })<{
  $isSelected: boolean;
  $visible: boolean;
}>(
  ({ theme, $isSelected, $visible }) => css`
    transition: ${theme.animations.transitionFast('background-color', 'height', 'opacity')};
    overflow: hidden;
    flex-shrink: 0;
    z-index: ${theme.getZIndexOf('base')};
    border-radius: 0;

    ${$visible
      ? css`
          height: 56px;
          border-top-width: 1px;
          border-bottom-width: 1px;
          opacity: 1;
        `
      : css`
          height: 0;
          border-top-width: 0;
          border-bottom-width: 0;
          opacity: 0;
        `};

    ${$isSelected &&
    css`
      background-color: ${theme.colors.surfaceDefault};
      & * {
        opacity: 0.7;
      }
    `}

    &:hover {
      background-color: ${theme.colors.surfaceDefault};
    }
  `
);

export const StyledOperationEmptyMessageWrapper = styled(Box)<{
  $visible: boolean;
}>(
  ({ theme, $visible }) => css`
    transition: ${theme.animations.transitionFast('height', 'opacity', 'padding')};
    overflow: hidden;

    ${$visible
      ? css`
          height: 40px;
          opacity: 1;
        `
      : css`
          height: 0;
          opacity: 0;
          padding-top: 0;
          padding-bottom: 0;
        `};
  `
);

export const StyledStyledOperationItemWrapper = styled(Box)(
  () => css`
    overflow: hidden;
    width: 108px;

    h3,
    span {
      display: block;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0;
    }
  `
);

export const StyledOperationSearch = styled(Box).attrs({
  alignItems: 'center',
})<{ $hasSearchScrollOffset: boolean }>(
  ({ theme, $hasSearchScrollOffset }) => css`
    top: 0;
    position: sticky;
    background-color: ${theme.colors.surfaceSecondary};
    z-index: ${theme.getZIndexOf('content')};
    border-radius: ${theme.borderRadius._050} ${theme.borderRadius._050} 0 0;
    margin: 0 -${theme.spacing._100};
    padding: 0 ${theme.spacing._100};
    transition: ${theme.animations.transitionMedium('border-bottom')};
    border-bottom: transparent 1px solid;

    ${$hasSearchScrollOffset &&
    css`
      border-bottom: ${theme.colors.borderSecondary} 1px solid;
    `}

    ${from('tablet')} {
      margin: 0;
      padding: 0;
      border-bottom: ${theme.colors.borderSecondary} 1px solid;
    }

    & > div {
      min-height: 40px;
      border-radius: ${theme.borderRadius._025};
      border: ${theme.colors.borderSecondary} 1px solid;
      gap: ${theme.spacing._050};
      margin: ${theme.spacing._100} 0;
      padding: ${theme.spacing._050};

      ${from('tablet')} {
        margin: ${theme.spacing._050};
      }

      &:focus-within {
        border: ${theme.colors.borderPrimary} 1px solid;
      }
    }

    input {
      flex: 1;
      font-size: ${PRESETS.SmallParagraph.fontSize};
      border: none;
      outline: none;

      &::placeholder {
        color: ${theme.colors.contentTertiary};
      }
    }
  `
);
