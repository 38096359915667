import styled, { css } from 'styled-components';

import { Accordion, AccordionContent } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { ModalContent } from '@components/common/ModalBase/ModalContent';

export const StyledModalContent = styled(ModalContent)(
  () => css`
    overflow: hidden;
  `
);

export const StyledModalContentInner = styled(Box)(
  () => css`
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
  `
);

export const StyledBorderedCard = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.paperRadius02}px;
    border: 1px solid ${theme.primitives.colors.grey04};
  `
);

export const StyledBorderedScrollableCard = styled(StyledBorderedCard)(
  () => css`
    overflow: hidden scroll;
  `
);

export const StyledColumnOption = styled(Box)<{ $sticky?: boolean }>(
  ({ theme, $sticky }) => css`
    position: relative;
    background-color: ${theme.colors.surfaceSecondary};
    border-bottom: 1px solid ${theme.primitives.colors.grey04};
    padding-top: ${theme.spacing._100};
    padding-bottom: ${theme.spacing._100};
    width: 100%;
    z-index: ${theme.getZIndexOf('base')};

    ${$sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: ${theme.getZIndexOf('content')};
    `}
  `
);

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.primitives.colors.grey04};
    }

    button {
      padding: 0;
      margin: 0;

      &::before {
        content: initial;
      }
    }
  `
);

export const StyledAccordionContent = styled(AccordionContent)(
  () => css`
    padding: 0;
    margin: 0;
  `
);

export const StyledAccordionItem = styled(Box)<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    position: relative;
    transition: ${theme.animations.transitionMedium('height', 'padding-bottom', 'opacity')};
    z-index: ${theme.getZIndexOf('base')};

    ${$isVisible
      ? css`
          height: 40px;
          padding-bottom: ${theme.spacing._100};
          opacity: 1;
        `
      : css`
          height: 0;
          padding-bottom: 0;
          opacity: 0;
          overflow: hidden;
        `}
  `
);
