import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { from } from '@style/mediaQueries';

export const StyledAccordion = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${theme.primitives.colors.white};
`
);

export const StyledAccordionHeader = styled.button(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: none;
    margin: ${theme.spacing._025} 10px;
    padding: 8px 0 8px 6px;

    &:active {
      color: ${theme.primitives.colors.grey06};
      path {
        fill: currentColor;
      }
    }

    ${from('desktopSM')} {
      &:hover {
        color: ${theme.primitives.colors.grey06};
        path {
          fill: currentColor;
        }
      }
    }

    &:focus-visible {
      box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02};
      border-color: transparent;
      outline: none;
    }

    &::before {
      content: '';
      position: absolute;
      left: 6px;
      right: 6px;
      bottom: -${theme.spacing._025};
      border-bottom: 1px solid ${theme.primitives.colors.grey04};
    }
  `
);

export const StyledAccordionHeaderIconWrapper = styled.div<{ expanded: boolean }>(
  ({ theme, expanded }) => `
  transition: ${theme.animations.transitionFast('transform')};
  transform: rotate(${expanded ? 180 : 0}deg);
  padding: ${theme.spacing._025};
`
);

export const StyledAccordionContent = styled.div<{ height: number; expanded: boolean; showOverflow: boolean }>(
  ({ theme, height, expanded, showOverflow }) => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: content-box;
  height: ${expanded ? height : 0}px;
  margin: 0 ${theme.spacing._100};
  padding: ${expanded ? theme.spacing._100 : 0} 0;
  opacity: ${expanded ? 1 : 0};
  overflow: ${showOverflow ? 'visible' : 'hidden'};
  transition: 
    ${theme.animations.transitionMedium('height', 'padding', 'opacity')};
`
);

export const StyledAccordionContentInner = styled.div(
  () => `
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
);
