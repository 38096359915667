import { truncate } from 'lodash';

import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { Api } from '@helpers/Api';
import { URLUtil } from '@helpers/URL';
import { makeFetchYardListRequestThunk } from '@redux/deprecated/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { ThunkAction } from '@redux/types';

import { AssignContractAction, AssignContractActionType, AssignContractYard } from './types';

export const makeOpenAssignContractModal = (selectedYards: Array<AssignContractYard>): AssignContractAction => ({
  type: AssignContractActionType.OPEN_ASSIGN_CONTRACT_MODAL,
  payload: selectedYards,
});
export const makeCloseAssignContractModal = (): AssignContractAction => ({
  type: AssignContractActionType.CLOSE_ASSIGN_CONTRACT_MODAL,
});

export const makePatchAssignContractBatchStartAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_START,
});

export const makePatchAssignContractBatchSuccessAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_SUCCESS,
});

export const makePatchAssignContractBatchFailureAction = (): AssignContractAction => ({
  type: AssignContractActionType.PATCH_ASSIGN_CONTRACT_BATCH_FAILURE,
});

export const makePatchAssignContractBatchThunk = (options: {
  contractId: number;
  contractName: string;
  yardIds: number[];
}): ThunkAction => {
  return async (dispatch): Promise<boolean> => {
    dispatch(makePatchAssignContractBatchStartAction());

    const { contractId, contractName, yardIds } = options;
    const patchURL = URLUtil.buildURL(ENDPOINTS.assignContract, { contract_id: contractId });
    const response = await Api.patch(patchURL, { yard_ids: yardIds });

    if (response.error) {
      dispatch(makePatchAssignContractBatchFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      return false;
    } else {
      dispatch(makePatchAssignContractBatchSuccessAction());
      dispatch(makeCloseAssignContractModal());
      dispatch(
        makeShowSnackbarAction({
          messageTranslation: yardIds.length === 1 ? 'assign_contract_success_single' : 'assign_contract_success',
          messageTranslationParams: { name: truncate(contractName, { length: 24 }) },
          actionLinkToTranslation: 'view',
          actionLinkToPath: URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, {
            pathParams: { uid: contractId },
          }),
        })
      );
      return true;
    }
  };
};
