import React, { useCallback, useLayoutEffect, useRef } from 'react';

import { BoxRef } from '@components/common/Box/types';
import { StyledSwipeArea } from '@components/common/SwipeArea/styles';
import { SwipeAreaProps } from '@components/common/SwipeArea/types';

/**
 * The user have to swipe at least this amount for the swipe event
 * to start, so it doesn't conflict with normal clicks.
 *  */
const MIN_SWIPE_DELTA = 8;

/**
 * Applied class to disable all events while swiping.
 * */
const DISABLE_POINTER_EVENTS_CLASS = 'disable-pointer-events';

export const SwipeArea: React.FC<SwipeAreaProps> = ({ onSwipeStart, onSwipeMove, onSwipeEnd, children, ...props }) => {
  const boxRef = useRef<BoxRef<'div'>>(null);
  const swipingState = useRef<{ started: boolean; startX: number; startY: number } | null>();

  const getEventPosition = useCallback((event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent) {
      return { x: event.pageX, y: event.pageY };
    }
    const touches = event.touches.length ? event.touches : event.changedTouches.length ? event.changedTouches : null;
    return touches ? { x: touches[0].pageX, y: touches[0].pageY } : null;
  }, []);

  useLayoutEffect(() => {
    const boxElement = boxRef.current;
    const handleMouseDown = (event: MouseEvent | TouchEvent) => {
      const eventPos = getEventPosition(event);
      if (eventPos) {
        const { x, y } = eventPos;
        swipingState.current = { started: false, startX: x, startY: y };
      }
    };
    boxElement?.addEventListener('mousedown', handleMouseDown);
    boxElement?.addEventListener('touchstart', handleMouseDown);

    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      const eventPos = getEventPosition(event);
      if (eventPos && boxRef.current && swipingState.current) {
        const { x, y } = eventPos;
        const deltaX = x - swipingState.current.startX;
        const deltaY = y - swipingState.current.startY;
        const swipeEvent = { deltaX, deltaY };

        if (!swipingState.current.started) {
          const didMoveEnough = Math.abs(deltaX) + Math.abs(deltaY) >= MIN_SWIPE_DELTA;
          if (didMoveEnough) {
            swipingState.current.started = true;
            onSwipeStart && onSwipeStart(swipeEvent);

            if (!boxRef.current.className.includes(DISABLE_POINTER_EVENTS_CLASS)) {
              boxRef.current.className += ` ${DISABLE_POINTER_EVENTS_CLASS}`;
            }
          }
        } else {
          onSwipeMove && onSwipeMove(swipeEvent);
        }
      }
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleMouseMove);

    const handleMouseUp = (event: MouseEvent | TouchEvent) => {
      const eventPos = getEventPosition(event);
      if (!eventPos) {
        return;
      }

      if (swipingState.current?.started) {
        const { x, y } = eventPos;
        onSwipeEnd &&
          onSwipeEnd({
            ...event,
            deltaX: x - swipingState.current.startX,
            deltaY: y - swipingState.current.startY,
          });
      }
      swipingState.current = null;
      if (boxRef.current) {
        boxRef.current.className = boxRef.current.className.replace(` ${DISABLE_POINTER_EVENTS_CLASS}`, '');
      }
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);
    document.addEventListener('touchcancel', handleMouseUp);

    return () => {
      boxElement && boxElement.removeEventListener('mousedown', handleMouseDown);
      boxElement && boxElement.removeEventListener('touchstart', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('touchcancel', handleMouseUp);
    };
  }, [onSwipeStart, onSwipeMove, onSwipeEnd, getEventPosition]);

  return (
    <StyledSwipeArea ref={boxRef} {...props}>
      {children}
    </StyledSwipeArea>
  );
};
