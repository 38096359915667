import produce from 'immer';

import { BulkDeleteYardsActionType, BulkDeleteYardsReducer, BulkDeleteYardsState } from './types';

const defaultState: BulkDeleteYardsState = {
  isModalOpen: false,
};

export const bulkDeleteYardsReducer: BulkDeleteYardsReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case BulkDeleteYardsActionType.BULK_DELETE_YARDS_OPEN_MODAL:
        state.isModalOpen = true;
        break;

      case BulkDeleteYardsActionType.BULK_DELETE_YARDS_CLOSE_MODAL:
        state.isModalOpen = false;
        break;
    }

    return state;
  });
};
