import React from 'react';
import { useTranslation } from 'react-i18next';

import moreIcon from '@assets/xSmall_More.svg';

import { Icon, MoreButton } from './styles';

export const ThreeDotsMenuTrigger: React.FC<{ id: string; title?: string }> = ({ id, title }) => {
  const { t } = useTranslation();
  return (
    <MoreButton id={id} title={title ? title : t('open_menu')}>
      <Icon src={moreIcon} />
    </MoreButton>
  );
};
