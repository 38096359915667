import styled, { css, ModalWidth } from 'styled-components';

import { Box } from '@components/common/Box';
import { StyledDialog } from '@components/common/ModalBase/styles';
import { from } from '@style/mediaQueries';

export const StyledModalMeerkat = styled(StyledDialog)<{
  $width?: ModalWidth;
  $backgroundColor: string;
  $useFullHeight?: boolean;
  $suppressAutoScroll?: boolean;
  open: boolean;
}>(
  ({ theme, $width, $backgroundColor, $useFullHeight, $suppressAutoScroll }) => css`
    bottom: 0;
    background-color: ${$backgroundColor};
    border-top-left-radius: ${theme.shape.paperRadius03}px;
    border-top-right-radius: ${theme.shape.paperRadius03}px;

    // This negative margin and border adds an extra spacing to the bottom.
    // This will cover a small empty space at the bottom while the modal is
    // animating to stack.
    margin: auto auto -${theme.spacing._150} auto;
    border-bottom: ${$backgroundColor} ${theme.spacing._150} solid;

    width: 100%;
    ${$useFullHeight
      ? css`
          height: calc(100vh - ${theme.spacing._200});
        `
      : css`
          max-height: calc(100vh - ${theme.spacing._200});
        `}

    ${$suppressAutoScroll &&
    css`
      overflow: hidden;
    `}

    ${from('tablet')} {
      width: ${theme.modals.width[$width ?? 'base']}px;
      ${$useFullHeight
        ? css`
            height: calc(100vh - ${theme.spacing._350});
          `
        : css`
            max-height: calc(100vh - ${theme.spacing._350});
          `}
    }

    transform: translateY(110vh) scaleX(1);
    transition: ${theme.animations.transitionLong('transform')} 80ms;

    &[open] {
      transform: translateY(0);
    }
  `
);
