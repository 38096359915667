import React from 'react';
import { ColDef } from 'ag-grid-community';

import { AccordionHeader } from '@components/common/Accordion';
import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/inputs/CheckBox';
import { GridApiUtil } from '@components/yard/YardsList/util';
import { useColumnVisibilityChecker } from '@components/yard/YardsListColumnsModal/hooks';
import { useTranslation } from '@hooks/useTranslation';

import { useColumnNameGetter } from '../YardsList/hooks/useColumnNameGetter';

import { StyledAccordion, StyledAccordionContent, StyledAccordionItem } from './styles';

export const YardsListCategoryAccordion: React.VFC<{
  group: Array<ColDef>;
  toggleColumn: (col: ColDef | ColDef[], shouldHide?: boolean) => void;
  showDisabledPractices?: boolean;
}> = ({ group, toggleColumn, showDisabledPractices }) => {
  const t = useTranslation();
  const [category, ...practices] = group;

  const getColumnName = useColumnNameGetter();
  const isColumnAvailable = useColumnVisibilityChecker(showDisabledPractices);

  return (
    <StyledAccordion initiallyExpanded>
      <AccordionHeader>
        <Box key={category.colId} alignItems="center" paddingVertical_100>
          <Text typography={'Heading3'} weight={'600'}>
            {getColumnName(category, category.headerName)}
          </Text>
        </Box>
      </AccordionHeader>
      <StyledAccordionContent>
        <StyledAccordionItem
          key={category.colId}
          tag={'label'}
          alignItems="center"
          paddingBottom_100
          $isVisible={isColumnAvailable(GridApiUtil.getColumnDefWithMetadata(category))}
        >
          <Checkbox value={!category.hide} name={category.colId as string} onChange={() => toggleColumn(category)} />
          <Box marginLeft_100>
            <Text typography={'Heading3'}>
              <span>{getColumnName(category, category.headerName)}</span>{' '}
              <Text typography="Heading3" htmlTag="span" color="grey06">
                [{t('category')}]
              </Text>
            </Text>
          </Box>
        </StyledAccordionItem>
        {practices.map((columnDef: ColDef) => {
          return (
            <StyledAccordionItem
              key={columnDef.colId}
              tag={'label'}
              alignItems="center"
              $isVisible={isColumnAvailable(GridApiUtil.getColumnDefWithMetadata(columnDef))}
            >
              <Checkbox
                value={!columnDef.hide}
                name={columnDef.colId as string}
                onChange={() => toggleColumn(columnDef)}
              />
              <Box marginLeft_100>
                <Text typography={'Heading3'}>{getColumnName(columnDef, columnDef.headerName)}</Text>
              </Box>
            </StyledAccordionItem>
          );
        })}
      </StyledAccordionContent>
    </StyledAccordion>
  );
};
