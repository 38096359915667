import { RefObject, useMemo } from 'react';
import throttle from 'lodash/throttle';

import { useAnimationLoop } from '@hooks/useAnimationLoop';

const DEF_TABLE_HEIGHT_OFFSET = 24;
const DEF_UPDATE_TABLE_HEIGHT_THROTTLE = 200;

export function useDynamicTableHeightSetter(gridWrapperRef: RefObject<HTMLDivElement | null>) {
  const updateTableHeight = useMemo(
    () =>
      throttle(() => {
        const gridWrapperElement = gridWrapperRef.current;
        const scrollElement = document.getElementById('root');
        const themedElement = gridWrapperElement?.querySelector<HTMLDivElement>('.ag-theme-alpine');

        if (gridWrapperElement && scrollElement && themedElement) {
          // Make sure the scroll position is at top.
          if (scrollElement.scrollTop > 0) {
            scrollElement.scrollTop = 0;
          }

          const gridRect = themedElement.getBoundingClientRect();
          const nextTableHeight = window.innerHeight - gridRect.top - DEF_TABLE_HEIGHT_OFFSET;

          const nextTableHeightStr = `${nextTableHeight}px`;
          const currentTableHeightStr = themedElement.style.getPropertyValue('--tableHeight');

          if (nextTableHeightStr !== currentTableHeightStr) {
            themedElement.style.setProperty('--tableHeight', nextTableHeightStr);
          }
        }
      }, DEF_UPDATE_TABLE_HEIGHT_THROTTLE),
    [gridWrapperRef]
  );

  useAnimationLoop(updateTableHeight);
}
