/**
 * Map layer to render yards, yard geometries and hives.
 * */

import React from 'react';

import { MarkerOptimizer } from '@components/common/Map/components/MarkerOptimizer';
import { YardHivesRenderer } from '@components/yard/YardsMapLayers/elements/YardHivesRenderer';
import { YardMarkerRenderer } from '@components/yard/YardsMapLayers/elements/YardMarkerRenderer';
import { YardPolygonRenderer } from '@components/yard/YardsMapLayers/elements/YardPolygonRenderer';
import {
  useYardDescriptors,
  useYardElementsDescriptors,
  useYardElementsVisibilityFilter,
  useYardElementZIndexGetter,
} from '@components/yard/YardsMapLayers/hooks';
import { useYardStatusConfigGetter } from '@config/YardStatusConfig/hooks';

import { YardDotRenderer } from './elements/YardDotRenderer';

const MIN_ZOOM_TO_SHOW_YARD_ELEMENTS = 14;
const MAX_ZOOM_TO_SHOW_YARD_DOTS = 12.75;

export interface YardsMapDefaultLayerProps {
  yards: Array<BeeYardOnMapInfo>;
  isYardSelected?: (yard: BeeYardOnMapInfo) => boolean;
  onYardPinClick?: (yard: BeeYardOnMapInfo) => void;
}

export const YardsMapDefaultLayer: React.VFC<YardsMapDefaultLayerProps> = ({
  yards,
  isYardSelected,
  onYardPinClick,
}) => {
  const yardDescriptors = useYardDescriptors(yards);
  const yardElementsDescriptors = useYardElementsDescriptors(yards);
  const visibleMarkersFilter = useYardElementsVisibilityFilter();
  const specGetter = useYardStatusConfigGetter();
  const yardElementZIndexGetter = useYardElementZIndexGetter();

  return (
    <>
      <MarkerOptimizer
        enableSmartBounds
        markers={yardElementsDescriptors}
        markerRenderer={(props) => (
          <>
            <YardPolygonRenderer {...props} />
            <YardHivesRenderer {...props} />
          </>
        )}
        visibleMarkersFilter={visibleMarkersFilter}
        minZoomToRender={MIN_ZOOM_TO_SHOW_YARD_ELEMENTS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => (
          <YardMarkerRenderer isYardSelected={isYardSelected} onYardPinClick={onYardPinClick} {...props} />
        )}
        minZoomToRender={MAX_ZOOM_TO_SHOW_YARD_DOTS}
      />
      <MarkerOptimizer
        enableSmartBounds
        markers={yardDescriptors}
        markerRenderer={(props) => {
          // eslint-disable-next-line react/prop-types
          const yard = props.data;
          const { color, priority } = specGetter(yard);
          const zIndex = yardElementZIndexGetter(yard, priority);
          return <YardDotRenderer color={color} zIndex={zIndex} {...props} />;
        }}
        maxZoomToRender={MAX_ZOOM_TO_SHOW_YARD_DOTS}
      />
    </>
  );
};
