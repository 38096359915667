import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { ArrowUp } from '@components/common/Icon/presets/ArrowUp';
import { Loading } from '@components/common/Loading';
import { SwipeArea } from '@components/common/SwipeArea';
import { SwipeEvent } from '@components/common/SwipeArea/types';
import { Text } from '@components/common/Text';
import { Children } from '@helpers/Children';
import { useSessionStorageState } from '@helpers/Storage/hooks';
import { useTranslation } from '@hooks/useTranslation';

import {
  StyledArrowButton,
  StyledCardsContainer,
  StyledCardWrapper,
  StyledCarrouselContainer,
  StyledToggleMetricsButton,
} from './style';
import { MetricCarrouselProps } from './types';

const MIN_CARD_WIDTH = 230;

export const MetricCarrousel: React.VFC<MetricCarrouselProps> = ({ isFetching, storageKey, children }) => {
  const t = useTranslation();
  const theme = useTheme();
  const cardsContainerRef = useRef<HTMLDivElement>(null);

  const cards = Children.asList(children);
  const cardsCount = cards.length;

  const canToggleVisibility = !!storageKey;

  const [cardWidth, setCardWidth] = useState(0);
  const [initiallyVisibleCardsCount, setInitiallyVisibleCardCount] = useState(0);
  const [visibleCardsCount, setVisibleCardCount] = useState(0);
  const [leftmostCardIndex, setLeftmostCardIndex] = useState(0);
  const [metricsState, setMetricsState] = useSessionStorageState(storageKey ?? '', { isVisible: true });

  const leftmostCardMaxIndex = Math.max(cardsCount - visibleCardsCount, 0);
  const canSlideLeft = leftmostCardIndex > 0;
  const canSlideRight = leftmostCardIndex < leftmostCardMaxIndex;
  const hasOverflowCards = !isFetching && cardsCount > visibleCardsCount;

  const toggleMetricsVisibility = useCallback(() => {
    if (!canToggleVisibility) return;
    setMetricsState((curr) => ({ ...curr, isVisible: !curr.isVisible }));
  }, [canToggleVisibility, setMetricsState]);

  useLayoutEffect(() => {
    const handleCardWidth = () => {
      requestAnimationFrame(() => {
        if (cardsContainerRef.current) {
          const { width } = cardsContainerRef.current.getBoundingClientRect();
          const visibleCardCount = Math.min(Math.floor(width / MIN_CARD_WIDTH), cardsCount);

          if (visibleCardCount > 0) {
            const cardsWidth = Math.floor(width / visibleCardCount);
            setVisibleCardCount(visibleCardCount);
            initiallyVisibleCardsCount === 0 && setInitiallyVisibleCardCount(visibleCardCount);
            setCardWidth(cardsWidth);
          }
        }
      });
    };

    const cardsContainerElement = cardsContainerRef.current;
    if (cardsContainerElement) {
      const resizeObserver = new ResizeObserver(handleCardWidth);
      resizeObserver.observe(cardsContainerElement);
      return () => resizeObserver.unobserve(cardsContainerElement);
    }
  }, [cardsCount, initiallyVisibleCardsCount]);

  const slideLeft = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.max(curr - 1, 0));
  }, []);

  const slideRight = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.min(curr + 1, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  const onSwipeMove = useCallback(
    (event: SwipeEvent) => {
      const cardsContainer = cardsContainerRef.current;
      if (!cardsContainer) {
        return 0;
      }
      Array.from<HTMLElement>(cardsContainer.querySelectorAll(StyledCardWrapper)).forEach((card, index) => {
        card.style.left = `${(index - leftmostCardIndex) * cardWidth + event.deltaX}px`;
        card.style.transition = theme.animations.transitionMedium('opacity');
        card.style.opacity = '1.0';
      });
    },
    [cardWidth, leftmostCardIndex, theme.animations]
  );

  const onSwipeEnd = useCallback(
    (event: SwipeEvent) => {
      const cardsContainer = cardsContainerRef.current;
      if (!cardsContainer) {
        return 0;
      }
      const offset = Math.round(event.deltaX / cardWidth);
      const nextLeftmostCardIndex = Math.min(Math.max(0, leftmostCardIndex - offset), leftmostCardMaxIndex);
      Array.from<HTMLElement>(cardsContainer.querySelectorAll(StyledCardWrapper)).forEach((card, index) => {
        const isCardHidden = index < nextLeftmostCardIndex || index >= nextLeftmostCardIndex + visibleCardsCount;

        card.style.left = `${(index - nextLeftmostCardIndex) * cardWidth}px`;
        card.style.transition = theme.animations.transitionMedium('left', 'opacity');
        card.style.opacity = isCardHidden ? '0.0' : '1.0';
      });
      setLeftmostCardIndex(nextLeftmostCardIndex);
    },
    [cardWidth, leftmostCardIndex, leftmostCardMaxIndex, theme.animations, visibleCardsCount]
  );

  useEffect(() => {
    setLeftmostCardIndex((curr) => Math.min(curr, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  const isCardHidden = useCallback(
    (cardIndex: number) => {
      return cardIndex < leftmostCardIndex || cardIndex >= leftmostCardIndex + visibleCardsCount;
    },
    [leftmostCardIndex, visibleCardsCount]
  );

  const isCalculatingCardsWidth = cardWidth === 0;
  const isLoading = isFetching || isCalculatingCardsWidth;
  const isEmpty = cardsCount === 0;

  return (
    <SwipeArea fit column stretch onSwipeMove={onSwipeMove} onSwipeEnd={onSwipeEnd}>
      <StyledCarrouselContainer $isVisible={metricsState.isVisible} $hasOverflow={hasOverflowCards}>
        {hasOverflowCards && (
          <StyledArrowButton onClick={slideLeft} disabled={!canSlideLeft}>
            <ArrowLeft size={18} />
          </StyledArrowButton>
        )}
        <StyledCardsContainer ref={cardsContainerRef}>
          {isLoading ? (
            <Loading />
          ) : isEmpty ? (
            <Box fit center>
              <Text typography={'SmallParagraph'}>{t('no_result')}</Text>
            </Box>
          ) : (
            cards.map((card, index) => (
              <StyledCardWrapper
                key={index}
                $index={index - leftmostCardIndex}
                $hidden={isCardHidden(index)}
                $animate={index < initiallyVisibleCardsCount}
                style={{
                  left: (index - leftmostCardIndex) * cardWidth,
                  width: cardWidth,
                  opacity: isCardHidden(index) ? '0.0' : '1.0',
                }}
              >
                {card}
              </StyledCardWrapper>
            ))
          )}
        </StyledCardsContainer>
        {hasOverflowCards && (
          <StyledArrowButton onClick={slideRight} disabled={!canSlideRight}>
            <ArrowRight size={18} />
          </StyledArrowButton>
        )}
      </StyledCarrouselContainer>

      {canToggleVisibility && (
        <StyledToggleMetricsButton $areMetricsVisible={metricsState.isVisible} onClick={toggleMetricsVisibility}>
          <Box alignItems={'center'}>
            <Text typography={'CaptionSmall'}>{metricsState.isVisible ? t('hide_metrics') : t('show_metrics')}</Text>{' '}
            <ArrowUp size={16} />
          </Box>
        </StyledToggleMetricsButton>
      )}
    </SwipeArea>
  );
};
