import { produce } from 'immer';

import { DataStatusActionType, DataStatusReducer, DataStatusState, DataStatusType } from '@redux/DataStatus/types';

// For how long and how many statuses to keep per key.
const MAX_STATUS_AGE = 30 * 24 * 60 * 60 * 1000; // 30 days
const MAX_STATUSES_LENGTH = 3;

const defaultState: DataStatusState = {
  statuses: {},
};

restoreState(defaultState);

export const dataStatusReducer: DataStatusReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case DataStatusActionType.DATA_STATUS_NOTIFY_UPDATED:
        state.statuses[action.payload.key] = [
          ...(state.statuses[action.payload.key] || []),
          { type: DataStatusType.DATA_UPDATED, timestamp: action.payload.timestamp },
        ];
        clearSurplusStatuses(state);
        persistState(state);
        break;
      case DataStatusActionType.DATA_STATUS_NOTIFY_OUTDATED:
        state.statuses[action.payload.key] = [
          ...(state.statuses[action.payload.key] || []),
          { type: DataStatusType.DATA_OUTDATED, timestamp: action.payload.timestamp },
        ];
        clearSurplusStatuses(state);
        persistState(state);
        break;
    }

    return state;
  });
};

function clearSurplusStatuses(state: DataStatusState) {
  for (const [key, statuses] of Object.entries(state.statuses)) {
    state.statuses[key] = statuses
      // Keep it at the maximum length.
      .slice(-MAX_STATUSES_LENGTH);
  }
}

function persistState(state: DataStatusState) {
  try {
    const data = JSON.stringify(removeStaleStatuses(state));
    sessionStorage.setItem('data-update-status-storage-state', data);
  } catch (_) {
    // ...
  }
}

function restoreState(state: DataStatusState) {
  try {
    const { statuses } = JSON.parse(
      sessionStorage.getItem('data-update-status-storage-state') ?? ''
    ) as DataStatusState;
    state.statuses = statuses;
  } catch (_) {
    // ...
  }
}

function removeStaleStatuses(state: DataStatusState) {
  return produce(state, (state) => {
    for (const [key, statuses] of Object.entries(state.statuses)) {
      const cleanStatuses = statuses.filter(({ timestamp }) => {
        return +new Date() - timestamp <= MAX_STATUS_AGE;
      });
      if (cleanStatuses.length) {
        state.statuses[key] = cleanStatuses;
      } else {
        delete state.statuses[key];
      }
    }
  });
}
