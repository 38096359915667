import React from 'react';
import styled, { css } from 'styled-components';

import { ModalFooter } from '@components/common/ModalBase';
import { StepsPages } from '@components/common/StepsPages';
import { Form, FormProps } from '@components/form/core/Form';

export const StyledFormPages = styled(StepsPages)(
  () => css`
    height: 460px;
  `
);

export const StyledBorderWrapper = styled.div(
  ({ theme }) => `
      background-color: ${theme.primitives.colors.white};
      border: 1px solid ${theme.primitives.colors.grey04};

      &:focus,
      &:focus-within {
        box-shadow: 0 0 4px 2px ${theme.primitives.colors.focus02}; 
        outline: none;
      }
    `
);

export const StyledForm = styled(Form)<StyledFormProps>(
  ({ theme }) => `
      display: grid;
      grid-row-gap: 24px;
      width: calc(${theme.modals.width.base}px - ${theme.spacing._150} * 2);
      transition: all 0.3s ease-out;
      margin-bottom: auto;
    `
);
export const StyledOverviewForm = StyledForm as React.FC<FormProps<BeeContractOverview> & StyledFormProps>;
export const StyledGrowerForm = StyledForm as React.FC<FormProps<BeeGrower> & StyledFormProps>;
export const StyledFinancesForm = StyledForm as React.FC<FormProps<BeeContractFinances> & StyledFormProps>;
interface StyledFormProps {
  $activeSection?: number;
  $isActive: boolean;
}

export const StyledInputWrapper = styled.div(
  ({ theme }) => `
      margin-top: ${theme.spacing._050};
    `
);

export const StyledInputPairWrapper = styled.div(
  ({ theme }) => `
      margin-top: ${theme.spacing._050};
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    `
);

export const StyledFooter = styled(ModalFooter)(
  () => `
      display: flex;
      justify-content: space-between;
    `
);

export const Dot = styled.span<{ isActive: boolean }>(
  ({ theme, isActive }) => `
    margin-right: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${isActive ? theme.primitives.colors.grey08 : theme.primitives.colors.grey05}
    `
);
