export type EnvironmentName = 'production' | 'staging' | 'development' | 'local';

export const Environment = {
  getName,
  isLocal,
};

function getName(): EnvironmentName {
  const environmentMap: Record<string, EnvironmentName> = {
    'app.nectar.buzz': 'production',
    'nectar-fe2-staging.eastus.azurecontainer.io': 'staging',
    'nectar-fe2-dev.eastus.azurecontainer.io': 'development',
  };
  return environmentMap[window.location.hostname] ?? 'local';
}

function isLocal(): boolean {
  return getName() === 'local';
}
