import { isArray } from 'lodash';

import { CaseAdapter } from '@helpers/CaseAdapter';
import { Globals } from '@helpers/Globals';

import {
  ApiRequestData,
  ApiRequestHeaders,
  ApiRequestOptions,
  ApiResponse,
  ApiResponseError,
  ApiResponseErrorDetail,
} from './types';

export const ERROR_TRANSLATION_MAP = {
  400: 'snack_non_field_errors',
  401: 'unauthorized',
  504: 'snack_timeout_error',
  500: 'snack_network_error',
} as Record<number, string>;

export const Api = {
  get,
  patch,
  post,
  put,
  delete_,
};

async function get(url: string, options?: ApiRequestOptions) {
  return await performRequest(url, { method: 'GET', ...options });
}

async function patch(url: string, data: ApiRequestData, options?: ApiRequestOptions) {
  return await performDataRequest(url, data, { method: 'PATCH', ...options });
}
async function put(url: string, data: ApiRequestData, options?: ApiRequestOptions) {
  return await performDataRequest(url, data, { method: 'PUT', ...options });
}

async function post(url: string, data: ApiRequestData, options?: ApiRequestOptions) {
  return await performDataRequest(url, data, { method: 'POST', ...options });
}

async function delete_(url: string, data?: ApiRequestData, options?: ApiRequestOptions) {
  return await performDataRequest(url, data ?? {}, { method: 'DELETE', ...options });
}

async function performDataRequest(url: string, data: ApiRequestData, options?: ApiRequestOptions) {
  const body = JSON.stringify(data);
  return await performRequest(url, { body, ...options });
}

async function performRequest(url: string, options?: ApiRequestOptions): Promise<ApiResponse> {
  try {
    const response = (await fetch(url, prepareOptions(options))) as ApiResponse;

    if (!options?.suppressBodyConsumption) {
      let jsonData: any = null;
      try {
        jsonData = await response.json();
      } catch (_) {
        // ...
      }
      response.json = () => jsonData;
    }

    // Case where the current operation become inaccessible.
    if (response.status === 403) {
      // Clear it up.
      Globals.operationId = null;
    }

    response.error = await getResponseError(response, null);
    return response;
  } catch (error) {
    return {
      error: await getResponseError(null, error),
    } as ApiResponse;
  }
}

function prepareOptions(options?: ApiRequestOptions): ApiRequestOptions {
  return {
    ...options,
    headers: prepareHeaders(options?.headers),
    credentials: 'include',
    mode: 'cors',
    cache: 'no-cache',
  };
}

function prepareHeaders(headers?: ApiRequestHeaders): ApiRequestHeaders {
  const _headers: any = {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (Globals.operationId) {
    _headers['Operation-Id'] = String(Globals.operationId);
  }

  return _headers;
}

async function getResponseError(response: Response | null, error: unknown | null): Promise<ApiResponseError | null> {
  if (response && response.status < 400) {
    return null;
  }

  const status = response?.status ?? 500;
  const isAborted = String(error).includes('aborted');
  const detail = await getResponseErrorDetail(response);
  const messageTranslation = ERROR_TRANSLATION_MAP[status] ?? 'snack_default_msg';

  return {
    detail,
    status,
    isAborted,
    snackbarOptions: {
      type: 'error',
      messageTranslation,
      actionRefresh: true,
      persistent: true,
    },
  };
}

async function getResponseErrorDetail(response: Response | null): Promise<ApiResponseErrorDetail | null> {
  if (response) {
    const data = await response.json();
    const errors = data?.errors;
    if (errors) {
      const flattenError = getFlattenError(errors);
      return flattenError ? CaseAdapter.objectToCamelCase(flattenError) : null;
    }
  }

  return null;
}

/**
 * Flattens a given error object.
 * E.g:
 *     getFlattenError({ fieldA: { fieldB: ['Error'] } })
 *     output >> { 'fieldA.fieldB': ['Error] }
 * */
function getFlattenError(errors: any): any {
  if (isKeyValueObject(errors)) {
    const flatten = {} as any;
    for (const [key, val] of Object.entries(errors)) {
      if (isKeyValueObject(val)) {
        const flatenVal = getFlattenError(val);
        for (const [innerKey, innerVal] of Object.entries(flatenVal)) {
          flatten[`${key}.${innerKey}`] = innerVal;
        }
      } else {
        flatten[key] = val;
      }
    }
    return flatten;
  } else if (isArray(errors)) {
    return errors;
  }

  return errors;
}

function isKeyValueObject(object: any) {
  return typeof object === 'object' && !isArray(object) && object !== null;
}
