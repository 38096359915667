import styled, { Color, css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { from } from '@style/mediaQueries';

export const StyledCategoryActions = styled(Box)`
  flex-wrap: wrap;
`;

export const StyledPlusButton = styled(Button)(
  ({ theme }) => `
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${theme.colors.borderSecondary};
`
);

export const StyledStyledHeader = styled(Box)(
  () => css`
    flex-direction: column-reverse;
    align-items: flex-start;
    ${from('tablet')} {
      flex-direction: row;
      align-items: center;
    }
  `
);

export const StyledHeaderPillWrapper = styled(Box)(
  ({ theme }) => css`
    gap: ${theme.spacing._050};
    ${from('tablet')} {
      gap: ${theme.spacing._100};
    }
  `
);

export const StyledHeaderPill = styled(Box).attrs({
  paddingVertical_025: true,
  paddingHorizontal_050: true,
  gap_025: true,
  borderRadius_025: true,
  borderWidth: 1,
})<{ $color?: Color }>(
  ({ theme, $color }) => css`
    background-color: ${$color ? theme.colors.alpha($color, 0.3) : 'inherit'};
  `
);
