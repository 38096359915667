import { Reducer } from 'redux';

export interface SnackbarOptions {
  messageTranslation: string;
  messageTranslationParams?: Record<string, string>;

  // Adds an action button with this text.
  actionTranslation?: string;
  actionTranslationParams?: Record<string, string>;
  actionProps?: any; // This is meant to be ButtonProps. But I can't get it working.

  // Adds a hyperlink action button with this text and destination.
  actionLinkToTranslation?: string;
  actionLinkToTranslationParams?: Record<string, string>;
  actionLinkToPath?: string;

  // Adds an action button to refresh the page.
  actionRefresh?: boolean;

  onActionClick?: () => void;
  onCloseClick?: () => void;

  type?: SnackbarType;
  duration?: SnackbarDuration;
  persistent?: boolean;
}
export interface SnackbarQueuedItem extends SnackbarOptions {
  id: string;
}

export type SnackbarType = 'success' | 'warning' | 'error';
export type SnackbarDuration = 'normal';

export type SnackbarReducer = Reducer<SnackbarState, SnackbarAction>;

export interface SnackbarState {
  queue: Array<SnackbarQueuedItem>;
}

export type SnackbarAction = {
  type: SnackbarActionType.SNACKBAR_SHOW;
  payload: SnackbarOptions;
};

export enum SnackbarActionType {
  SNACKBAR_SHOW = 'SNACKBAR_SHOW',
}
