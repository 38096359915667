import React from 'react';

import { Box } from '@components/common/Box';
import { DefaultCellText, DefaultCellWrapper } from '@components/common/DynamicTable/DefaultCell';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { useTranslation } from '@hooks/useTranslation';

export const AvgVisits: React.FC<{ data: number }> = ({ data }) => {
  const t = useTranslation();

  if (data > 0) {
    const roundedAvgVisits = Math.round(data);
    return (
      <Box center>
        <DefaultCellText typography={'CaptionSmall'}>{maybePluralize(roundedAvgVisits, 'day', t)}</DefaultCellText>
      </Box>
    );
  } else {
    return (
      <DefaultCellWrapper alignItems={'center'}>
        <span>
          <DefaultCellText color={'grey06'} dashed>
            {t('not_available')}
            <Tooltip>
              <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: t('avg_visits_nodata') }} />
            </Tooltip>
          </DefaultCellText>
        </span>
      </DefaultCellWrapper>
    );
  }
};
