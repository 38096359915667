import { Reducer } from 'redux';

import { ApiResponseError } from '@helpers/Api/types';

export type HiveDetailsReducer = Reducer<HiveDetailsState, HiveDetailsAction>;

export type HiveDetailsAction =
  | { type: HiveDetailsActionType.HIVE_DETAILS_DISPOSE; payload: { id: number } }
  | { type: HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_START; payload: { id: number } }
  | {
      type: HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_FINISH;
      payload: {
        id: number;
        data: BeeHiveOnModalDetailedInfo | null;
        error: ApiResponseError | null;
      };
    }
  | { type: HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_START; payload: { id: number } }
  | {
      type: HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_FINISH;
      payload: {
        id: number;
        data: ActivityByRequestSet | null;
        error: ApiResponseError | null;
      };
    };

export interface HiveDetailsState {
  hives: Record<
    number,
    {
      detail: BeeHiveOnModalDetailedInfo | null;
      isFetchingDetail: boolean;

      activitySet: ActivityByRequestSet | null;
      isFetchingActivitySet: boolean;
    }
  >;
}

export interface ActivityByRequestSet {
  activities: Array<BeeActivityByRequest>;
  totalCount: number;
}

export enum HiveDetailsActionType {
  HIVE_DETAILS_DISPOSE = 'HIVE_DETAILS_DISPOSE',

  HIVE_DETAILS_FETCH_DETAIL_START = 'HIVE_DETAILS_FETCH_DETAIL_START',
  HIVE_DETAILS_FETCH_DETAIL_FINISH = 'HIVE_DETAILS_FETCH_DETAIL_FINISH',

  HIVE_DETAILS_FETCH_ACTIVITY_START = 'HIVE_DETAILS_FETCH_ACTIVITY_START',
  HIVE_DETAILS_FETCH_ACTIVITY_FINISH = 'HIVE_DETAILS_FETCH_ACTIVITY_FINISH',
}
