import React from 'react';

import { Box } from '@components/common/Box';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { StyledTrendArrowWrapper } from '@components/common/Metrics/style';
import { MetricCardSimpleValueProps } from '@components/common/Metrics/types';
import { Text } from '@components/common/Text';

export const MetricCardSimpleValue: React.VFC<MetricCardSimpleValueProps> = ({
  currentValue,
  previousValue,
  trendValue,
  trendDown,
  trendDanger,
}) => {
  return (
    <Box column alignItems={'start'} gap_050>
      <Box center gap_025>
        <Text typography={'Heading2'} weight={'600'}>
          {currentValue}
        </Text>
        {trendValue !== undefined && (
          <Box center>
            <StyledTrendArrowWrapper $trendDown={trendDown} $trendDanger={trendDanger}>
              <ArrowRight />
            </StyledTrendArrowWrapper>
            <Text typography={'CaptionSmall'} weight={'700'} color={trendDanger ? 'contentDanger' : 'contentPositive'}>
              {trendValue}
            </Text>
          </Box>
        )}
      </Box>

      {!!previousValue && (
        <Text typography={'CaptionSmall'} color={'grey06'}>
          {previousValue}
        </Text>
      )}
    </Box>
  );
};
