import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useGridApi } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Loading } from '@components/common/Loading';
import { MessageBox } from '@components/common/MessageBox';
import { Modal, ModalFooter } from '@components/common/Modal';
import { Text } from '@components/common/Text';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputContractAutocomplete } from '@components/form/inputs/InputContractAutocomplete';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useContractSummaries } from '@hooks/useContract';
import { useTranslation } from '@hooks/useTranslation';
import { makeCloseAssignContractModal, makePatchAssignContractBatchThunk } from '@redux/AssignContract/actions';
import { AssignContractYard } from '@redux/AssignContract/types';

import { useYardsListSelection } from '../YardsList/hooks/useYardsListSelectionContext';

const StyledLink = styled(Link)`
  color: inherit;
`;

enum ModalStep {
  SELECT_CONTRACT = 1,
  CONFIRM = 2,
}

const DropReassignWarningMessage: React.FC<{ yardsThatAreDrops: AssignContractYard[] }> = ({ yardsThatAreDrops }) => {
  const t = useTranslation();
  const yardsThatAreDropsLength = useMemo(() => yardsThatAreDrops.length, [yardsThatAreDrops]);

  const title =
    yardsThatAreDropsLength === 1
      ? t('assign_contract_warning_title_single')
      : t('assign_contract_warning_title', { amount: yardsThatAreDropsLength });

  const moveThan5YardsText = `${yardsThatAreDropsLength} ${t('yards')} `;

  const andTranslated = t('and');
  return (
    <Box block marginBottom_150>
      <MessageBox>
        <Box block marginBottom_025>
          <Text typography="CaptionSmall" weight="700">
            {title}
          </Text>
        </Box>

        <Text typography="CaptionSmall">
          {yardsThatAreDropsLength > 5
            ? moveThan5YardsText
            : yardsThatAreDrops.map((yard, index) => (
                <React.Fragment key={yard.id}>
                  {yardsThatAreDropsLength > 1 && index === yardsThatAreDropsLength - 1 && `${andTranslated} `}
                  <StyledLink
                    key={yard.id}
                    to={URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: yard.id } })}
                    target="_blank"
                  >
                    {yard.contractName} {yard.name}
                  </StyledLink>
                  {yardsThatAreDropsLength > 2 && index < yardsThatAreDropsLength - 2 ? ', ' : ' '}
                </React.Fragment>
              ))}
          {yardsThatAreDropsLength === 1
            ? t('assign_contract_warning_amount_single')
            : t('assign_contract_warning_amount')}
        </Text>
      </MessageBox>
    </Box>
  );
};
export const BulkAssignContractModal: React.FC = () => {
  const {
    isAssignContractModalOpen: isOpen,
    selectedYards,
    isFetching,
  } = useSelector((state) => state.assignContractReducer);

  const contracts = useContractSummaries();
  const numberOfYards = useMemo(() => selectedYards.length, [selectedYards]);
  const [step, setStep] = useState<ModalStep>(ModalStep.SELECT_CONTRACT);
  const t = useTranslation();
  const { gridApi } = useGridApi();
  const { deselectAllOnPage } = useYardsListSelection();

  const form = useForm({
    defaultValues: useFormDefaultValues({
      contract: null,
    }),
    resolver: useFormSchemaResolver<{ contract: number | null }>((schema) => ({
      contract: schema.mixed(),
    })).resolver,
  });

  const contractId = form.watch('contract');
  const hasSelectedContract = !!contractId;

  const yardsThatAreDrops = useMemo(() => selectedYards.filter((yard) => !!yard.contractName), [selectedYards]);

  const selectedYardIds = useMemo(() => selectedYards.map((yard) => yard.id), [selectedYards]);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(makeCloseAssignContractModal());
    form.reset({ contract: null });
  }, [dispatch, form]);

  const assignYardsToContract = useCallback(async () => {
    const contractId = form.getValues('contract');
    if (contractId) {
      const contractName = contracts?.find(({ id }) => id === contractId)?.name ?? '-';

      const isSuccessful = await dispatch(
        makePatchAssignContractBatchThunk({ contractId, contractName, yardIds: selectedYardIds })
      );
      if (!isSuccessful) return;
      deselectAllOnPage();
      gridApi?.refreshInfiniteCache();
    }
  }, [contracts, deselectAllOnPage, dispatch, form, gridApi, selectedYardIds]);

  const forwardStep = useCallback(async () => {
    if (step === ModalStep.SELECT_CONTRACT) {
      return setStep(ModalStep.CONFIRM);
    }
    await assignYardsToContract();
  }, [assignYardsToContract, step]);

  useEffect(() => {
    if (numberOfYards === 0) closeModal();
  }, [closeModal, numberOfYards]);

  useEffect(() => {
    if (isOpen) {
      setStep(ModalStep.SELECT_CONTRACT);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <Form column form={form} onValidSubmit={forwardStep}>
        {step === ModalStep.SELECT_CONTRACT ? (
          <>
            <Box block marginTop_200 marginHorizontal_150>
              {yardsThatAreDrops.length > 0 && <DropReassignWarningMessage yardsThatAreDrops={yardsThatAreDrops} />}
              <Text typography="Heading2">
                {numberOfYards > 1
                  ? t('assign_x_yards_contract', { number: numberOfYards })
                  : t('assign_yard_contract')}
              </Text>
              <Box marginVertical_150 block>
                <InputContractAutocomplete id="yard-contract-id" name="contract" />
              </Box>
            </Box>
            <ModalFooter>
              <Box fit justifyContent={'flex-end'}>
                <Button tertiary withMarginRight type="button" onClick={closeModal}>
                  {t('cancel')}
                </Button>
                <Button primary disabled={!hasSelectedContract}>
                  {t('assign')}
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            {isFetching && <Loading whiteBackground />}
            <Box paddingTop_200 marginHorizontal_150 marginBottom_150 column>
              <Text typography="Heading2" weight="600">
                {t('assign_contract_confirmation_title')}
              </Text>
              <Box block marginTop_100>
                <Text typography="Paragraph">{t('assign_contract_confirmation_desc')}</Text>
              </Box>
            </Box>
            <ModalFooter>
              <Box fit justifyContent={'flex-end'}>
                <Button tertiary withMarginRight type="button" onClick={() => setStep(ModalStep.SELECT_CONTRACT)}>
                  {t('cancel')}
                </Button>
                <Button primary disabled={!hasSelectedContract}>
                  {t('assign_contract_confirmation_submit')}
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </Form>
    </Modal>
  );
};
