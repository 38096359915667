import React from 'react';

import { useGetScreenWidth } from '@hooks/useGetScreenWidth';

import { Box } from '../Box';
import { BoxProps } from '../Box/types';

export const Gutter: React.FC<BoxProps<'div'>> = ({ children, ...boxProps }) => {
  const { isMobile } = useGetScreenWidth();
  return (
    <Box paddingVertical_150={!isMobile} paddingHorizontal_150={!isMobile} padding_100={isMobile} block {...boxProps}>
      {children}
    </Box>
  );
};
