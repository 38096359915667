import { useCallback, useState } from 'react';

import { useGridApi } from '@components/common/AgGrid/hooks';
import { useDynamicTableDataCycle } from '@components/common/DynamicTable/hooks';

export function useDynamicTableEmptyState() {
  const [isListEmpty, setIsListEmpty] = useState(false);
  const { gridApi } = useGridApi();

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
    onDataServerReload: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
  });

  return { isListEmpty };
}
