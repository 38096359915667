import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputNumber } from '@components/form/inputs/InputNumber';
import { InputText } from '@components/form/inputs/InputText';

import { StyledFinancesForm, StyledInputWrapper } from './styles';
import { FinancesFormSectionProps } from './types';

export const FormSectionFinances: React.FC<FinancesFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  handleFormSectionSubmit,
  formState,
  defaultState,
}) => {
  const { t } = useTranslation();
  const firstInputWrapperRef = useRef<HTMLFieldSetElement>(null);

  const defaultValues = useFormDefaultValues<BeeContractFinances>(defaultState);
  const { shape, resolver } = useFormSchemaResolver<BeeContractFinances>((schema) => ({
    costPerHive: schema
      .number()
      .nullable()
      .min(0)
      .transform((_, val) => (val === '' ? null : Number(val))),
    totalPrice: schema
      .number()
      .nullable()
      .min(0)
      .transform((_, val) => (val === '' ? null : Number(val))),
    address: schema.string(),
    notes: schema.string(),
  }));
  const form = useForm<BeeContractFinances>({ defaultValues, resolver });
  const isDirtyTotalPrice = form.getFieldState('totalPrice').isDirty;
  const costPerHive = form.watch('costPerHive');

  useEffect(() => {
    const parsedCostPerHive = costPerHive ? Number(costPerHive) : NaN;
    if (!isNaN(parsedCostPerHive) && formState.nbRequiredHives && !isDirtyTotalPrice) {
      const totalPrice = parsedCostPerHive * formState.nbRequiredHives;
      form.setValue('totalPrice', totalPrice, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.nbRequiredHives, costPerHive]);

  return (
    <StyledFinancesForm
      id={`contractForm-section-${section}`}
      form={form}
      $isActive={isActive}
      $activeSection={activeSection}
      onValidSubmit={(data) => handleFormSectionSubmit(shape.cast(data) as BeeContractFinances)}
    >
      <fieldset ref={firstInputWrapperRef}>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('price')}
        </Text>
        <StyledInputWrapper>
          <InputNumber
            id="contractForm-costPerHive"
            name="costPerHive"
            label={t('pollination_cost_per_hive')}
            readOnly={!isActive}
            placeholder={t('pollination_cost_per_hive_placeholder')}
            innerLeftContent={'$'}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputNumber
            id="contractForm-totalPrice"
            name="totalPrice"
            readOnly={!isActive}
            label={t('pollination_total_price')}
            placeholder={t('pollination_total_price_placeholder')}
            innerLeftContent={'$'}
          />
        </StyledInputWrapper>
      </fieldset>
      <fieldset>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('location')}
        </Text>

        <StyledInputWrapper>
          <InputText
            id="contractForm-address"
            name="address"
            label={t('address')}
            readOnly={!isActive}
            placeholder={t('address_placeholder')}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputText
            id="contractForm-address"
            rows={3}
            name="notes"
            useTextArea
            suppressResizing
            readOnly={!isActive}
            label={t('pollination_notes')}
          />
        </StyledInputWrapper>
      </fieldset>
    </StyledFinancesForm>
  );
};
