import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useGridApi } from '@components/common/AgGrid';
import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { UnstyledButton } from '@components/common/UnstyledButton';
import { useTranslation } from '@hooks/useTranslation';

import { useYardsListSelection } from './hooks/useYardsListSelectionContext';

const TableHeaderBottom = styled(Box)<{ visible?: boolean }>(
  ({ theme, visible }) => `
      background: ${theme.primitives.colors.yellow01};
      transition: 
          ${theme.animations.transitionMedium('max-height')} 120ms,
          ${theme.animations.transitionMedium('padding')} 120ms;
      padding: ${visible ? theme.spacing._050 : 0} ${theme.spacing._050};
      max-height: ${visible ? 66 : 0}px;
      opacity: ${visible ? 1 : 0}px;
      overflow: hidden;
      
      & > * {
        margin-right: ${theme.spacing._050};
      }

      button {
        color: ${theme.primitives.colors.orange02};
      }

      button:hover {
        text-decoration: underline;
      }
    `
);

export const YardsSelectAllAcrossPages: React.VFC = () => {
  const { gridApi } = useGridApi();

  const {
    hasSelectedAllOnPage,
    allYardsAcrossPagesAreSelectedManually,
    selectAllAcrossPages,
    deselectAllAcrossPages,
    hasClickedSelectAllAcrossPages,
    selectionCount,
  } = useYardsListSelection();
  const t = useTranslation();
  const allYardsAcrossPagesSelected = useMemo(
    () => hasClickedSelectAllAcrossPages || allYardsAcrossPagesAreSelectedManually,
    [hasClickedSelectAllAcrossPages, allYardsAcrossPagesAreSelectedManually]
  );
  const hasMultiplePages = (gridApi?.paginationGetTotalPages() ?? 0) > 1;

  const isVisible = hasSelectedAllOnPage && hasMultiplePages;

  const toggleSelection = useCallback(() => {
    if (allYardsAcrossPagesSelected) {
      deselectAllAcrossPages();
    } else {
      selectAllAcrossPages();
    }
  }, [deselectAllAcrossPages, allYardsAcrossPagesSelected, selectAllAcrossPages]);

  return (
    <TableHeaderBottom fit center visible={isVisible}>
      <Text typography={'CaptionSmall'}>
        {t(allYardsAcrossPagesSelected ? 'yards_all_selected_header' : 'yards_selected_header', {
          number: selectionCount,
        })}
      </Text>
      <UnstyledButton onClick={toggleSelection}>
        <Text typography={'CaptionSmall'} color={'orange02'} weight={'700'}>
          {t(allYardsAcrossPagesSelected || hasClickedSelectAllAcrossPages ? 'yards_deselect' : 'yards_all_select', {
            number: gridApi?.paginationGetRowCount() ?? 0,
          })}
        </Text>
      </UnstyledButton>
    </TableHeaderBottom>
  );
};
