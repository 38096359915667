import React from 'react';
import { IHeaderGroupParams } from 'ag-grid-community';
import styled from 'styled-components';

import { Text } from '@components/common/Text';

const StyledHeaderGroupText = styled(Text).attrs({
  typography: 'Heading3',
  weight: '600',
})(
  ({ theme }) => `
  margin-left: calc(${theme.spacing._050} - 1)px;  /** -1 to balance with the border. */
  padding-left: ${theme.spacing._100};
  border-left: ${theme.primitives.colors.grey06} 1px solid;
`
);

export const YardsListHeaderGroup: React.VFC<IHeaderGroupParams> = (props) => {
  const { displayName } = props;

  return <StyledHeaderGroupText>{displayName}</StyledHeaderGroupText>;
};
