import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Fuse from 'fuse.js';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { Pin } from '@components/common/Icon/presets/Pin';
import { Search } from '@components/common/Icon/presets/Search';
import { Yard } from '@components/common/Icon/presets/Yard';
import { Loading } from '@components/common/Loading';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { URLUtil } from '@helpers/URL';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { HTMLInteractiveElement, useKeyboardNavigation } from '@hooks/useKeyboardNavigation';
import { useTranslation } from '@hooks/useTranslation';
import { makeMapFetchYardsThunk } from '@redux/Yards/actions';
import { makeClearAppliedFiltersAction } from '@redux/YardsFilters/actions';
import { makeGlobalSearchFetchYardsThunk, makeGlobalSearchPushToHistoryAction } from '@redux/YardsGlobalSearch/actions';

import { useLayoutContext } from '../context/hooks';

import {
  ActiveStatus,
  SearchInput,
  Spacer,
  StyledClearButton,
  StyledDrawerWrapper,
  StyledNbHives,
  StyledResult,
  StyledResults,
  StyledResultsDrawer,
  StyledResultsFooter,
  StyledSearch,
  StyledSearchInputWrapper,
  StyledSearchWrapper,
  StyledViewOnMapButton,
  StyledYardButton,
  StyledYardName,
} from './styles';

const DEFAULT_NUM_RESULTS = 5;

export const GlobalSearch: React.VFC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSearchExpanded, collapseSearch } = useLayoutContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [showAllResults, setShowAllResults] = useState(false);

  const { isTabletOrSmaller } = useGetScreenWidth();
  const { goToIndex } = useKeyboardNavigation({ elements: [wrapperRef] });

  const yardsListSummary = useSelector((state) => state.yardsGlobalSearchReducer.yards ?? []);
  const yardsSearchHistory = useSelector((state) => state.yardsGlobalSearchReducer.history ?? []);
  const isLoading = useSelector((state) => state.yardsGlobalSearchReducer.isFetching);

  const searchHistory = useMemo(
    () => yardsListSummary.filter((yard) => yardsSearchHistory.includes(yard.id)),
    [yardsListSummary, yardsSearchHistory]
  );

  // Creates a fuse search index, which can be used to search
  // yards by name and contract.
  const searchIndex = useMemo(() => {
    return new Fuse(yardsListSummary, {
      keys: ['contractName', 'name'],
      threshold: 0.2,
      ignoreLocation: true, // Ignores where the match appears in the string.
    });
  }, [yardsListSummary]);

  const searchResults = useMemo(() => {
    return searchIndex.search(searchValue).map(({ item }) => item);
  }, [searchIndex, searchValue]);

  const haveResults = useMemo(() => searchResults.length > 0, [searchResults]);

  const showHistory = useMemo(() => searchHistory.length > 0 && searchValue === '', [searchHistory, searchValue]);

  const list = useMemo(() => {
    const results = showAllResults || isTabletOrSmaller ? searchResults : searchResults.slice(0, DEFAULT_NUM_RESULTS);
    return showHistory ? [...searchHistory].reverse() : results;
  }, [isTabletOrSmaller, searchHistory, searchResults, showAllResults, showHistory]);

  const addYardToSearchHistory = useCallback(
    (yardId) => {
      dispatch(makeGlobalSearchPushToHistoryAction({ id: yardId }));
    },
    [dispatch]
  );

  const clearYardFilters = useCallback(() => {
    dispatch(makeClearAppliedFiltersAction());
  }, [dispatch]);

  const onClearSearch = useCallback(() => {
    setSearchValue('');
    inputRef?.current?.focus();
  }, []);

  const onCancel = useCallback(() => {
    const activeElement = document.activeElement as HTMLInteractiveElement;
    activeElement && activeElement.blur();
    setSearchValue('');
  }, []);

  const onYardSelect = useCallback(
    (yardId: number) => {
      history.push(URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: yardId } }));
      addYardToSearchHistory(yardId);
      onCancel();

      Analytics.sendEvent({ event: AnalyticsEventType.GLOBAL_SEARCH_YARD_NAME_CLICK });
    },
    [history, addYardToSearchHistory, onCancel]
  );

  const onMapClick = useCallback(
    (yardId) => {
      clearYardFilters();
      addYardToSearchHistory(yardId);
      onCancel();

      // Reload the map yards just in case the clicked yard is new.
      dispatch(makeMapFetchYardsThunk());

      // Cleaning and setting selection again to make sure the navigation
      // occurs even if the same item is re-selected.
      history.push(URLUtil.buildPagePath(APP.routes.yardsMap));
      setTimeout(() => history.push(URLUtil.buildPagePath(APP.routes.yardsMap, { queryParams: { yard: yardId } })));

      Analytics.sendEvent({ event: AnalyticsEventType.GLOBAL_SEARCH_YARD_PIN_CLICK });
    },
    [clearYardFilters, addYardToSearchHistory, onCancel, dispatch, history]
  );

  const onSearchFocused = useCallback(() => {
    goToIndex(0);
    dispatch(makeGlobalSearchFetchYardsThunk());

    Analytics.sendEvent({ event: AnalyticsEventType.GLOBAL_SEARCH_FOCUS });
  }, [dispatch, goToIndex]);

  return (
    <StyledSearch ref={wrapperRef} isSearchOpen={isSearchExpanded}>
      <StyledSearchWrapper>
        <StyledSearchInputWrapper alignItems="center">
          <ResponsiveRender from="desktopSM">
            <Box>
              <Search />
            </Box>
          </ResponsiveRender>
          <ResponsiveRender until="tablet">
            <Button
              data-omit-keyboard-navigation
              iconOnly
              suppressPaddingHorizontal
              aria-label={t('search_close')}
              onClick={collapseSearch}
            >
              <ArrowLeft size={24} />
            </Button>
          </ResponsiveRender>
          <SearchInput
            type="text"
            ref={inputRef}
            placeholder={t('search')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={onSearchFocused}
          />

          <StyledClearButton disabled={!searchValue} $visible={!!searchValue} onClick={onClearSearch}>
            <Text typography="CaptionSmall">{t('clear')}</Text>
          </StyledClearButton>
        </StyledSearchInputWrapper>
        <StyledDrawerWrapper block fullWidth>
          <ResponsiveRender until={'tablet'}>
            <Loading visible={isLoading} size="1.5rem" />
          </ResponsiveRender>

          {(!!searchValue || showHistory) && (
            <StyledResultsDrawer>
              <ResponsiveRender from={'desktopSM'}>
                <Loading visible={isLoading} size="1.5rem" whiteLoader />
              </ResponsiveRender>

              {(haveResults || showHistory) && (
                <Box paddingVertical_050 block paddingHorizontal_100>
                  <Text typography="CaptionSmall" weight="700">
                    {showHistory ? t('recent_searches') : t('yards_capitalized')}
                  </Text>
                </Box>
              )}

              {!haveResults && !!searchValue && (
                <Box paddingTop_050 paddingBottom_100 block paddingHorizontal_100>
                  <Text weight="700" typography="CaptionSmall">
                    {t('no_results', { searchValue })}
                  </Text>
                </Box>
              )}

              {(haveResults || showHistory) && !!list.length && (
                <StyledResults>
                  {list.map((yard) => (
                    <StyledResult key={yard.id}>
                      <StyledYardButton onClick={() => onYardSelect(yard.id)}>
                        <Yard />

                        <ActiveStatus $active={yard.nbHives > 0} />

                        <StyledYardName
                          id={`yard-row-${yard.id}`}
                          name={yard.name}
                          autoWidth
                          contractName={yard.contractName}
                          typography={'SmallParagraph'}
                        />
                        <ResponsiveRender from="desktopSM">
                          <Tooltip target={`yard-row-${yard.id}`} placement={'left-start'} offset={[-4, 68]}>
                            <Text typography={'CaptionSmall'}>
                              <StyledYardName
                                name={yard.name}
                                contractName={yard.contractName}
                                suppressTruncation
                                suppressStyling
                              />
                            </Text>
                          </Tooltip>
                        </ResponsiveRender>
                        <StyledNbHives>{maybePluralize(yard.nbHives, 'hive', t)}</StyledNbHives>
                      </StyledYardButton>
                      <Spacer />
                      <StyledViewOnMapButton onClick={() => onMapClick(yard.id)}>
                        <ResponsiveRender from="tablet">
                          <>
                            <Text typography="CaptionSmall">{t('view_on_map')}</Text>
                            <Box tag="span" paddingLeft_025></Box>
                          </>
                        </ResponsiveRender>

                        <Pin color="contentTertiary" />
                      </StyledViewOnMapButton>
                    </StyledResult>
                  ))}
                </StyledResults>
              )}
              {haveResults && searchResults.length > DEFAULT_NUM_RESULTS && (
                <Box block padding_100>
                  <Button unstyled onClick={() => setShowAllResults((state) => !state)}>
                    <Text typography="CaptionSmall" color="focus01">
                      {showAllResults
                        ? t('show_less')
                        : t('show_more', { results: maybePluralize(searchResults.length, 'result', t) })}
                    </Text>
                  </Button>
                </Box>
              )}

              {(haveResults || showHistory) && (
                <ResponsiveRender from="desktopSM">
                  <StyledResultsFooter>
                    <Text
                      typography={'CaptionSmall'}
                      dangerouslySetInnerHTML={{ __html: t('keyboard_navigation_instructions') }}
                    />
                  </StyledResultsFooter>
                </ResponsiveRender>
              )}
            </StyledResultsDrawer>
          )}
        </StyledDrawerWrapper>
      </StyledSearchWrapper>
    </StyledSearch>
  );
};
