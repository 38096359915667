import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { useHiveModalInnerContext } from '@components/hive/HiveModal/hooks';
import { StyledTabButton, StyledTabContent, StyledTabsWrapper } from '@components/hive/HiveModalTabs/styles';
import { HiveModalTabActivity } from '@components/hive/HiveModalTabsActivity';
import { HiveModalTabSummary } from '@components/hive/HiveModalTabsSummary';
import { useTranslation } from '@hooks/useTranslation';

export const HiveModalTabs: React.VFC = () => {
  const t = useTranslation();
  const theme = useTheme();
  const [tabs] = useState(() => [
    { key: 'summary', title: t('info').toUpperCase(), component: HiveModalTabSummary },
    { key: 'activity', title: t('activity').toUpperCase(), component: HiveModalTabActivity },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [visitedTabs, setVisitedTabs] = useState<Record<string, boolean>>(() => ({ [activeTab]: true }));
  const activeIndex = tabs.findIndex((tab) => tab.key === activeTab);

  const { isOpen } = useHiveModalInnerContext();

  const goToTab = useCallback((key) => {
    setActiveTab(key);
    setVisitedTabs((curr) => ({ ...curr, [key]: true }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      // Wait the modal closing animation before clearing the state.
      const timeout = setTimeout(() => {
        setVisitedTabs({});
      }, theme.animations.durationMedium);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOpen, theme.animations.durationMedium]);

  return (
    <Box fit column stretch>
      <Box stretch>
        {tabs.map(({ key, title }) => (
          <StyledTabButton key={key} $active={key === activeTab} onClick={() => goToTab(key)}>
            <Text typography={'SmallParagraph'} weight={'700'}>
              {title}
            </Text>
          </StyledTabButton>
        ))}
      </Box>

      <StyledTabsWrapper column stretch fit>
        {tabs.map(({ key, component: Component }, index) =>
          visitedTabs[key] ? (
            <StyledTabContent key={key} $isHiddenAtLeft={index < activeIndex} $isHiddenAtRight={index > activeIndex}>
              <Component />
            </StyledTabContent>
          ) : null
        )}
      </StyledTabsWrapper>
    </Box>
  );
};
