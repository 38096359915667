import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

import { Button } from '@components/common/CTA';
import { PrimaryButton } from '@components/deprecated/Buttons/PrimaryButton';
import { TextButton } from '@components/deprecated/Buttons/TextButton';
import { from } from '@style/mediaQueries';

export const StyledCol = styled(Col)`
  padding: ${(props) => (props.isMobile ? '1.5rem' : '3rem')};
`;

export const AlertsContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.height};
  text-align: center;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-left: 1.5rem;
`;

export const StyledHeader = styled.div`
  display: block;
  line-height: ${(props) => props.theme.activityHeaderHeight}px;
  color: ${(props) => props.theme.primaryTextColor};
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
  letter-spacing: ${(props) => props.theme.defaultLetterSpacing};
  padding-left: 1.5rem;
  padding-right: 30px;
  border-bottom: ${(props) => props.theme.primaryBorderStyle};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const DropShadowContainer = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow1};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  margin-left: ${(props) => !props.isMobile && '2rem'};
  margin-top: ${(props) => props.isMobile && '2rem'};
`;

export const MapContainer = styled.div`
  height: 100%;
  position: relative;
  border-radius: ${(props) => props.theme.defaultBorderRadius};
  border: ${(props) => props.theme.secondaryBorderStyle};
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  margin-left: 1rem;
`;

export const StyledBottomDirectionsNav = styled.div`
  position: absolute;
  right: 2.5rem;
  bottom: 1.5rem;
`;

export const SeparatorRow = styled(Row)`
  height: 1.5rem;
`;

export const StyledEmptyWarning = styled(Row)`
  height: ${(props) => props.height};
  text-align: center;
  text-align: -webkit-center;
  align-items: center;
  align-content: center;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-radius: ${(props) => props.theme.defaultBorderRadius};
`;

export const StyledBottomNav = styled.div(
  ({ theme, visible }) => `
  position: fixed;
  left: 50%;
  bottom: 1.5rem;  
  transition: ${theme.animations.transitionMedium('opacity', 'transform')};
  opacity: ${visible ? 1 : 0};
  transition-delay: ${visible ? '0ms' : '400ms'};
  transform: translateX(-50%) ${visible ? 'translateY(0px) scale(1)' : 'translateY(64px) scale(0.5)'};
  z-index: ${theme.getZIndexOf('tabs')};

  ${from('desktopSM')} {
    bottom: 2.5rem;
  }
`
);

export const StyledBottomRightNav = styled.div`
  position: fixed;
  right: ${(props) => (props.isMobile ? '1.5rem' : '2.5rem')};
  bottom: ${(props) => (props.isMobile ? '1.5rem' : '2.5rem')};
`;

export const StyledTopLeftNav = styled.div`
  position: fixed;
  left: ${(props) => (props.isMobile ? '1.5rem' : '3rem')};
  top: ${(props) => (props.isMobile ? '136px' : '120px')};
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
`;

export const StyledEmptyContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 320px;
`;

export const StyledIcon = styled.img`
  filter: ${(props) => props.theme.deadoutFilter};
`;

export const StyledEditIcon = styled.img`
  float: right;
  transform: rotate(90deg);
  filter: ${(props) => props.theme.iconFilter};
  cursor: pointer;
  z-index: ${(props) => props.theme.getZIndexOf('tabs')};
  &:hover {
    filter: ${(props) => props.theme.primaryColorFilter};
  }
`;

export const AlertIcon = styled.img`
  margin-right: 1rem;
  filter: ${(props) => props.theme.primaryColorFilter};
`;

export const StyledErrorTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  line-height: 1.5rem;
  letter-spacing: 1px;
  font-size: ${(props) => props.theme.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeightSemiBold};
`;

export const StyledCloseButton = styled(Button)`
  text-align: center;
`;

export const StyledSubmitButton = styled(Button)`
  width: 110px;
  margin-right: 1.5rem;
  padding: 0 14px;
`;

export const StyledInput = styled.input`
  display: block;
  height: 2rem;
  background-color: inherit;
  color: ${(props) => props.theme.primaryTextColor};
  border: none;
  outline: none;
  font-family: 'Open Sans';
  font-size: ${(props) => props.theme.paragraphFontSize};
  line-height: 22px;
  letter-spacing: 0;
  ::placeholder {
    font-family: 'Open Sans';
    color: ${(props) => props.theme.deadoutColor};
  }
`;
