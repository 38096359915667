import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Yard } from '@components/common/Icon/presets/Yard';
import { useYardsMap } from '@components/yard/YardsMap/hooks';
import { YardAttributeConfig } from '@config/types';
import { YardStatusConfig } from '@config/YardStatusConfig';
import { YardStatusKey, YardStatusValueConfig } from '@config/YardStatusConfig/types';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { YardsFiltersCategoryKey, YardsFiltersStatusValuesKey } from '@redux/YardsFilters/types';

import { YardsMapCardContent } from './YardsMapCardContent';

export const AllYardsStats: React.VFC = () => {
  const { yards } = useYardsMap();
  const { t } = useTranslation();

  const nbYardsPerStatus = useMemo(() => {
    const nbYards = yards.length;
    const nbInactiveYards = yards.filter((yard) => !yard.nbHives).length;
    const nbActiveYards = nbYards - nbInactiveYards;
    return {
      nbActiveYards,
      nbInactiveYards,
      nbYards,
    };
  }, [yards]);

  const shouldShowYardStatusStats = useCallback(
    ({ key }: YardAttributeConfig, appliedYardStatusFilters: string[]) =>
      appliedYardStatusFilters.includes(YardsFiltersStatusValuesKey.ALL_YARDS) ||
      appliedYardStatusFilters.includes(key),
    []
  );

  const getYardStatusStats = useCallback(
    ({ key, labelKey }: YardAttributeConfig, emptyMessage: string) => {
      const { nbYards, nbActiveYards, nbInactiveYards } = nbYardsPerStatus;
      const count = key === YardStatusKey.ACTIVE ? nbActiveYards : nbInactiveYards;
      return {
        key,
        color: YardStatusConfig[key as YardStatusKey].alternativeColor,
        labelLeft: t(labelKey),
        labelRight: count === null ? emptyMessage : maybePluralize(count, 'yard', t),
        progress: count / nbYards ?? 0,
      };
    },
    [nbYardsPerStatus, t]
  );

  const getToggledFilters = useCallback(
    (currentFilters: string[], selectedFilter: string, isAlreadyApplied: boolean) =>
      isAlreadyApplied ? [YardsFiltersStatusValuesKey.ALL_YARDS] : [selectedFilter],
    []
  );

  return (
    <YardsMapCardContent
      title={t('all_yards')}
      icon={<Yard size={16} />}
      filterCategoryKey={YardsFiltersCategoryKey.YARD_STATUS}
      yardAttributeConfigs={Object.values(YardStatusConfig) as YardStatusValueConfig[]}
      getYardAttributeStats={getYardStatusStats}
      shouldShowYardAttributeStats={shouldShowYardStatusStats}
      getToggledFilters={getToggledFilters}
    />
  );
};
