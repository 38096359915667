import React from 'react';

import { TimelineBase } from '@components/timeline/TimelineBase';
import { useItemContentRenderer, useItemHeadingRender } from '@components/timeline/TimelineByActivityDate/hooks';
import { TimelineByActivityDateProps } from '@components/timeline/TimelineByActivityDate/types';
import { useTranslation } from '@hooks/useTranslation';
import { usePractices } from '@redux/Practices/hooks';

export const TimelineByActivityDate: React.VFC<TimelineByActivityDateProps> = ({
  suppressYardName,
  highlightedYardId,
  ...props
}) => {
  const t = useTranslation();

  usePractices();

  const itemHeadingRenderer = useItemHeadingRender({ suppressYardName, highlightedYardId });
  const itemContentRenderer = useItemContentRenderer();

  return (
    <>
      <TimelineBase
        enableGoToTopArrow
        emptyMessage={t('timeline_empty_message')}
        {...{ itemHeadingRenderer, itemContentRenderer }}
        {...props}
      />
    </>
  );
};
