import moment from 'moment/moment';

import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { File } from '@helpers/File';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { ReportsAction, ReportsActionType } from './types';

const DATE_FORMAT = 'YYYY-MM-DD';

export const makeFetchElapReportsStartAction = (): ReportsAction => ({
  type: ReportsActionType.FETCH_START,
});

export const makeFetchElapReportsFinishAction = (error: ApiResponseError | null): ReportsAction => ({
  type: ReportsActionType.FETCH_FINISH,
  payload: {
    error,
  },
});

export const makeFetchElapReportsThunk = Thunk.createTakeFirst((fromDate: Date, toDate: Date) => {
  return async (dispatch) => {
    dispatch(makeFetchElapReportsStartAction());
    const queryParams = {
      from: moment(fromDate).format(DATE_FORMAT),
      to: moment(toDate).format(DATE_FORMAT),
    };
    const response = await Api.get(URLUtil.buildURL(ENDPOINTS.elapReport, queryParams), {
      suppressBodyConsumption: true,
    });
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction(error.snackbarOptions));
    } else {
      const fileName = `elap_report_${queryParams['from']}_to_${queryParams['to']}.xlsx`;
      await File.downloadFileFromApiResponse(fileName, response);
    }

    dispatch(makeFetchElapReportsFinishAction(error));
  };
});
