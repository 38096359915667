import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { Loading } from '@components/common/Loading';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { Settings } from '@scenes/admin/Settings/Settings';

export const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const hasUser = useSelector((state) => !!state.authReducer.user);

  return (
    <Box column stretch flexGrow={1}>
      <PageHeader>
        <PageHeaderTitle>{t('settings')}</PageHeaderTitle>
      </PageHeader>
      {hasUser && <Settings />}
      <Loading visible={!hasUser} />
    </Box>
  );
};
