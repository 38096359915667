import React, { useRef } from 'react';

import { Box } from '@components/common/Box';
import { PoiPinIcon } from '@components/common/PoiPinIcon';

import { StyledBoxBordered, StyledDetailText, StyledPoiCardWrapper, StyledPoiDescription } from './styles';

export const POICard: React.FC<{
  poi: BeePointOfInterest;
  lang: BeeSupportedLangCode;
  halfWidth?: boolean;
}> = ({ poi, lang, halfWidth }) => {
  const poiRef = useRef<HTMLDivElement>(null);
  const { name, category, descriptionEnglish, descriptionSpanish } = poi;
  const description = lang === 'es' ? descriptionSpanish : descriptionEnglish;

  return (
    <StyledPoiCardWrapper ref={poiRef} id={`poi-${poi.id}`} halfWidth={halfWidth} className="js-poi-card">
      <Box gap={'12px'}>
        <PoiPinIcon category={category} size={24} color={'grey08'} />

        <StyledDetailText bold>{name}</StyledDetailText>
      </Box>

      <StyledBoxBordered paddingLeft_150 marginTop_025>
        <StyledPoiDescription>{description}</StyledPoiDescription>
      </StyledBoxBordered>
    </StyledPoiCardWrapper>
  );
};
