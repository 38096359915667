import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { ProgressBar } from '@components/common/ProgressBar';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { ProgressValue } from '@components/pollination/PolliMapPaneSummary/styles';
import { useContractState } from '@hooks/useContract';

export const PolliMapPaneDropsProgress: React.VFC = () => {
  const { t } = useTranslation();
  const { contract, isFetchingContract } = useContractState();
  const { dropsList } = usePolliMap();

  const requiredHives = contract?.nbRequiredHives ?? 0;
  const trackedHives = useMemo(() => {
    return dropsList.reduce((total, drop) => total + (drop.targetHiveNumber ?? 0), 0);
  }, [dropsList]);

  const trackedHivesProgress = trackedHives / requiredHives;
  const tooltipText =
    trackedHives < requiredHives
      ? t('pollination_hives_on_map_underflow_hint')
      : trackedHives === requiredHives
      ? t('pollination_hives_on_map_complete_hint')
      : t('pollination_hives_on_map_overflow_hint');

  const progressBarColorGetter = useCallback((progress) => {
    return progress == 1.0 ? 'green02' : 'yellow02';
  }, []);

  if (!contract || isFetchingContract) {
    return null;
  }

  return (
    <Box column stretch>
      <Box justifyContent={'space-between'} marginBottom_050>
        <Text typography={'CaptionSmall'} weight={'600'}>
          {t('pollination_hives_on_map')}
        </Text>
        <ProgressValue typography={'CaptionSmall'}>
          {trackedHives}/{requiredHives} {t('hives')}
          <Tooltip>
            <Text typography={'CaptionSmall'} dangerouslySetInnerHTML={{ __html: tooltipText }} />
          </Tooltip>
        </ProgressValue>
      </Box>
      <Box column stretch marginBottom_100>
        <ProgressBar progress={trackedHivesProgress} color={progressBarColorGetter} />
      </Box>
    </Box>
  );
};
