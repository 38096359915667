import { useCallback, useEffect, useState } from 'react';

import { useGridApi } from '@components/common/AgGrid';

import { useDynamicTableDataCycle } from './useDynamicTableDataCycle';

export function useDynamicTableStates() {
  const { gridApi } = useGridApi();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);

  useDynamicTableDataCycle({
    onDataServerLoad: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
    onDataServerReload: useCallback(() => {
      setIsListEmpty(gridApi?.getInfiniteRowCount() === 0);
    }, [gridApi]),
  });

  useEffect(() => {
    if (!gridApi) return;

    function onModelUpdated() {
      if (!gridApi) {
        return;
      }
      setHasLoaded(gridApi.getInfiniteRowCount() === 0 || gridApi.getRenderedNodes()[0]?.data != undefined);
    }
    gridApi.addEventListener('modelUpdated', onModelUpdated);

    return () => gridApi.removeEventListener('modelUpdated', onModelUpdated);
  }, [gridApi]);

  return { hasLoaded, isListEmpty };
}
