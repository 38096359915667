import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Loading } from '@components/common/Loading';
import { Modal } from '@components/common/Modal';
import { useContractState } from '@hooks/useContract';
import { useContractModalController } from '@redux/Contract/hooks';

import { FormContract } from './FormContract';

export const ModalFormContract: React.VFC = () => {
  const theme = useTheme();
  const { close } = useContractModalController();
  const { isContractModalOpen, contract, isFetchingContract } = useContractState();

  // The contract form needs to be completely re-mounted
  // in order to work properly. This arbitrary ID will
  // guarantee that it happens everytime the modal is
  // opened for a contract.
  const [modalId, setModalId] = useState('');

  useEffect(() => {
    // Give enough time for the modal to open/close.
    const delay = isContractModalOpen ? 0 : theme.animations.durationLong;
    setTimeout(() => setModalId(`${contract?.id}-${isContractModalOpen}`), delay);
  }, [contract, isContractModalOpen, theme.animations.durationLong]);

  return (
    <Modal isOpen={isContractModalOpen} onRequestClose={close}>
      <FormContract key={modalId} onCancel={close} contract={contract} />
      <Loading visible={isFetchingContract} whiteBackground roundedCorners />
    </Modal>
  );
};
