import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';

export const StyledVisitCardNavArrow = styled.button(
  () => css`
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  `
);

export const StyledNotesText = styled(Text)(
  () => css`
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  `
);
