import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { BulkClearOutYardsAction, BulkClearOutYardsActionType } from './types';

export const makeBulkClearOutYardsModalOpenAction = (): BulkClearOutYardsAction => ({
  type: BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_OPEN_MODAL,
});

export const makeBulkClearOutYardsModalCloseAction = (): BulkClearOutYardsAction => ({
  type: BulkClearOutYardsActionType.BULK_CLEAR_OUT_YARDS_CLOSE_MODAL,
});

export const makeBulkClearOutYardsThunk = Thunk.createTakeFirst((yardIds: Array<number>) => {
  return async (dispatch) => {
    const response = await Api.post(ENDPOINTS.yardsClearOut, {
      yard_ids: yardIds,
    });

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw response.error;
    } else {
      dispatch(makeShowSnackbarAction({ messageTranslation: 'clear_out_x_yards_success_snackbar' }));
    }
  };
});
