import styled from 'styled-components/macro';

import { Box } from '@components/common/Box';

export const StyledActionPillView = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing._025} ${theme.spacing._075};
  border: ${theme.colors.borderSecondary} 1px solid;
  border-radius: 192px;
`
);

export const StyledActionPillDot = styled(Box)(
  ({ theme }) => `
  width: ${theme.spacing._050};
  height: ${theme.spacing._050};
  border-radius: 192px;
`
);
