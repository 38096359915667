import config from '@config/config';

export const API_URL = config.REACT_APP_API_URL;
export const API_VERSION = 'v3-14';

export const ENDPOINTS = _prepareUrls({
  login: `auth/login`,
  logout: `auth/logout`,
  passwordChange: `auth/password/change`,
  passwordForgotten: `auth/password/reset`,
  passwordRecreate: `auth/password/reset/confirm`,

  currentUser: `users/current`,
  currentUserPreferences: `users/current/preferences`,
  currentMembershipPreferences: `memberships/current/preferences`,

  operation: `operation/current`,

  yardList: `yardlist`,
  hhtYards: `hht-yards/`,
  hhtYardDetail: `hht-yards/{{id}}/`,
  hhtYardDetailDeletable: `hht-yards/{{id}}/deletable`,
  yardsListSummary: `hht-yards/summary`,
  yardsBatch: `hht-yards/batch`,
  yardsDelete: `hht-yards/delete/`,
  yardsClearOut: `hht-yards/empty/`,
  yardsList: `hht-yards/list`,
  yardsListWhiteboard: `hht-yards/whiteboard`,
  yardsListWhiteboardTooltips: `hht-yards/whiteboard-tooltips`,
  yardsMap: `hht-yards/map`,
  yardsMetricAvailability: `hht-yards/metrics/availability`,
  yardsMetric: `hht-yards/metrics`,
  yardType: `yardtype`,

  metricTrend: `hht-yards/metric/trend/{{name}}`,

  hives: `hivelist`,
  hhtHiveList: `hht-hivelist/`,
  hhtHivesRetire: `hht-hives/retire`,
  hiveListDynamicTable: `hht-hives/dynamic-table`,
  hhtHiveDetail: `hht-hivelist/{{id}}/`,
  hhtActivitiesByRequest: `activities-by-request`,
  hhtActivitiesByDate: `activities-by-date`,
  hhtActivitiesStatus: `new-activities`,

  chart: `hivechart`,

  export: `csvexport`,

  eventList: `eventlist`,

  elapReport: `reports/elap`,

  workerList: `workerlist/`,
  workerDetail: `workerlist/{{id}}/`,

  practices: `practices/`,
  action: `action`,
  actionDetail: `action/{{id}}`,

  activities: `activities`,

  translations: `translations`,

  filters: `hht-yards/filters`,

  groups: `groups/`,
  groupsDetails: `groups/list`,
  groupsBatch: `groups/batch`,
  assignedGroups: `groups/assignment`,

  cropTypes: `hht-crop-types/`,

  contract: `contract/`,
  contractDynamicTable: `contract/dynamic-table`,
  contractSummary: `contract/summary`,
  contractMetrics: `contract/metrics`,
  contractWithId: `contract/{{id}}/`,
  assignContract: `contract/{{contract_id}}/drops/`,
  archiveContract: `contract/{{contractId}}/archive/`,
  renewContract: `contract/{{contractId}}/renew/`,

  pollinationBatch: `contract/{{id}}/map_batch/`,
  dropsList: `hht-yards/list`,
  dropsMap: `hht-yards/pollinationmap`,
  blocks: `block/`,
  pois: `point-of-interest/`,
});

function _prepareUrls<T extends Record<string, string>>(urls: T): T {
  return Object.entries(urls).reduce((acc, [key, val]) => ({ ...acc, [key]: _url(val) }), {} as T) as T;
}

function _url(path: string) {
  return [API_URL, API_VERSION, path].join('/').replace(/\/\//g, '/').replace(/:\//g, '://');
}
