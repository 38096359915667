import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DataStatusUpdateAlert } from '@components/common/DataStatusUpdateAlert';
import { useCurrentTabFocus } from '@components/common/Tabs/hooks';
import { TimelineByActivityDate } from '@components/timeline/TimelineByActivityDate/TimelineByActivityDate';
import { TimelineByActivityDateYardPageProps } from '@components/timeline/TimelineByActivityDate/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { useYardTimelineDataStatus } from '@redux/DataStatus/hooks';
import { DataStatusType } from '@redux/DataStatus/types';
import { makeTimelineDisposeActivityAction, makeTimelineFetchActivityThunk } from '@redux/Timeline/actions';

export const TimelineByActivityDateYardPage: React.VFC<TimelineByActivityDateYardPageProps> = ({ yardId }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const dateUtil = useDateUtil();
  const [hasBeenVisited, setHasBeenVisited] = useState(false);
  const [timelineVersion, setTimelineVersion] = useState(0);

  const { activitySet, isFetchingActivitySet } = useSelector((state) => state.timelineReducer);
  const isLoadingInitialItems = Boolean(isFetchingActivitySet && !activitySet?.activities.length);
  const isLoadingMoreItems = Boolean(isFetchingActivitySet && activitySet?.activities.length);

  const dataStatus = useYardTimelineDataStatus(yardId, { autoUpdate: true });

  const loadItems = useCallback(
    async ({ timelineLoadedAt }) => {
      await dispatch(makeTimelineFetchActivityThunk(dateUtil.getFormattedISODate(timelineLoadedAt), yardId));
      dataStatus.notifyDataUpdated(+timelineLoadedAt);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, yardId, dateUtil, dataStatus.notifyDataUpdated]
  );

  useEffect(
    () => () => {
      dispatch(makeTimelineDisposeActivityAction());
    },
    [dispatch]
  );

  useCurrentTabFocus(
    useCallback(async () => {
      // If the user just opened the timeline for a second time
      // and the current data is outdated, just reload it.
      if (dataStatus.status?.type === DataStatusType.DATA_OUTDATED && hasBeenVisited) {
        // This will remove all outdated items.
        dispatch(makeTimelineDisposeActivityAction());

        // This will rebuild the timeline.
        setTimelineVersion((curr) => curr + 1);
      }

      setHasBeenVisited(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataStatus.status?.type, dispatch])
  );

  if (!hasBeenVisited) {
    return null;
  }

  return (
    <>
      {!isFetchingActivitySet && <DataStatusUpdateAlert dataStatus={dataStatus} />}
      <TimelineByActivityDate
        key={timelineVersion}
        items={activitySet?.activities ?? []}
        itemsTotalCount={activitySet?.totalCount ?? 0}
        onMoreItemsRequest={loadItems}
        isLoadingInitialItems={isLoadingInitialItems}
        isLoadingMoreItems={isLoadingMoreItems}
        footerMessage={t('timeline_bottom_message')}
        suppressYardName
        highlightedYardId={yardId}
      />
    </>
  );
};
