import React from 'react';
import { Col } from 'react-grid-system';
import PropTypes from 'prop-types';
// vendor:
import styled from 'styled-components';
import { Oval } from 'svg-loaders-react';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { IsFetching, ModalContainer } from '@components/deprecated/Elements/ModalElements';
import { StyledCloseButton, StyledSubmitButton } from '@components/deprecated/Elements/SummaryElements';
import { StyledHeading2, StyledParagraph } from '@components/deprecated/Elements/TypographyElements';
import theme from '@style/theme';

// nectar:
import { TextInput } from '../TextInput/TextInput';

const StyledErrorColumn = styled(Col)`
  color: ${(props) => props.theme.criticalColor};
  font-size: ${(props) => props.theme.heading3FontSize};
  line-height: 19px;
`;

const textInputStyle = {
  borderRadius: 0,
  border: theme.primaryBorderStyle,
  marginBottom: '1rem',
};

export const ChangePasswordModalView = ({
  t,
  isMobile,
  contentObj,
  handleChange,
  errorMsg,
  handleClose,
  handleSubmit,
  isDisabledBtn,
  isFetching,
}) => {
  return (
    <ModalContainer isMobile={isMobile}>
      <section>
        <StyledHeading2 style={{ marginBottom: '1rem' }} mobile={isMobile}>
          {t('change_password')}
        </StyledHeading2>

        <StyledParagraph style={{ marginBottom: '1.5rem' }}>{t('change_password_instructions')}</StyledParagraph>

        <form style={{ width: '100%' }}>
          <TextInput
            id="oldPassword"
            name="oldPassword"
            autocomplete="current-password"
            required
            value={contentObj.oldPassword}
            label={t('current_password')}
            handleChange={handleChange}
            type="password"
            styles={textInputStyle}
          />

          <TextInput
            id="newPassword"
            name="newPassword"
            autocomplete="new-password"
            required
            value={contentObj.newPassword}
            label={t('new_password')}
            handleChange={handleChange}
            type="password"
            styles={textInputStyle}
          />

          <TextInput
            id="confirmationPassword"
            name="confirmationPassword"
            autocomplete="new-password"
            required
            value={contentObj.confirmationPassword}
            label={t('confirm_password')}
            handleChange={handleChange}
            type="password"
            styles={{
              ...textInputStyle,
              marginBottom: 0,
            }}
          />
        </form>
      </section>

      <footer style={{ paddingLeft: '1.5rem' }}>
        {isFetching ? (
          <IsFetching role="button">
            <Oval stroke="#252629" width={20} height={20} />
          </IsFetching>
        ) : (
          <StyledErrorColumn xs={6}>{errorMsg}</StyledErrorColumn>
        )}
        <Box alignItems="center" paddingRight_100>
          <Button tertiary withMarginRight type="button" onClick={handleClose}>
            {t('cancel')}
          </Button>

          <Button primary type="button" onClick={handleSubmit} disabled={isDisabledBtn}>
            {t('submit')}
          </Button>
        </Box>
      </footer>
    </ModalContainer>
  );
};

ChangePasswordModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDisabledBtn: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    oldPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmationPassword: PropTypes.string.isRequired,
  }).isRequired,
  errorMsg: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
