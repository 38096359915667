import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box } from '@components/common/Box';
import { InputContractAutocomplete } from '@components/form/inputs/InputContractAutocomplete';
import { InputCropTypeAutocomplete } from '@components/form/inputs/InputCropTypeAutocomplete';
import { InputText } from '@components/form/inputs/InputText';
import { StyledErrorMessage } from '@components/form/inputs/InputText/styles';
import { InputYardTypeAutocomplete } from '@components/form/inputs/InputYardTypeAutocomplete';
import { createDropName } from '@helpers/Drop';
import { useContractSummary } from '@hooks/useContract';
import { useTranslation } from '@hooks/useTranslation';

export const FormSummaryInfo: React.VFC = () => {
  const t = useTranslation();
  const form = useFormContext();
  const contract = useContractSummary(form.watch('contract'));
  const contractFieldState = form.getFieldState('contract');

  const detail = useSelector((state) => state.yardCreateOrUpdateReducer.detail);

  const disableContractRelatedFields = !!contract || !!detail?.contractId;

  const handleContractChange = useCallback(() => {
    form.clearErrors('contract');

    if (contract) {
      form.setValue('contract', contract.id);
      form.setValue('name', createDropName(contract.latest_drop_name), { shouldValidate: true });
      form.setValue('cropTypes', []);
      form.setValue('type', 'pollination');
    } else {
      form.setValue('contract', null, { shouldValidate: false });

      const isDrop = !!detail?.contractId;
      if (isDrop) {
        form.setError('contract', { message: t('edit_contract_drop_error') });
      } else {
        form.setValue('name', detail?.name ?? '', { shouldValidate: true });
        form.setValue('cropTypes', detail?.cropTypesIds ?? []);
        form.setValue('type', detail?.yardType ?? null);
      }
    }
  }, [contract, detail?.contractId, detail?.cropTypesIds, detail?.name, detail?.yardType, form, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleContractChange(), [contract]);

  return (
    <Box column stretch gap_100>
      <InputText
        name={'name'}
        label={t('name')}
        placeholder={t('yard_modal_name_input_placeholder')}
        readOnly={disableContractRelatedFields}
        required
      />

      <Box column stretch>
        <InputContractAutocomplete name={'contract'} />
        {contractFieldState.invalid && <StyledErrorMessage>{t('edit_contract_drop_error')}</StyledErrorMessage>}
      </Box>

      <Box column stretch>
        <InputYardTypeAutocomplete name={'type'} disabled={disableContractRelatedFields} />
      </Box>

      <Box column stretch>
        <InputCropTypeAutocomplete name={'cropTypes'} disabled={disableContractRelatedFields} />
      </Box>
    </Box>
  );
};
