import React, { useCallback, useEffect } from 'react';

import { Gutter } from '@components/common/Gutter';
import { useHiveModalInnerContext } from '@components/hive/HiveModal/hooks';
import { StyledActivitiesTab } from '@components/hive/HiveModalTabsActivity/styles';
import { OnMoreItemsRequestCallback } from '@components/timeline/TimelineBase/types';
import { TimelineByActivityRequest } from '@components/timeline/TimelineByActivityRequest';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsPerformanceMetricEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeHiveFetchActivityThunk } from '@redux/HiveDetails/actions';
import { useHiveDetailsData } from '@redux/HiveDetails/hooks';

export const HiveModalTabActivity: React.VFC = () => {
  const t = useTranslation();
  const { id, isFocused, isOpen } = useHiveModalInnerContext();
  const { activitySet, isFetchingActivitySet } = useHiveDetailsData();
  const dispatch = useDispatch();
  const dateUtil = useDateUtil();

  const isLoadingInitialItems = isFetchingActivitySet && !activitySet?.activities.length;
  const isLoadingMoreItems = Boolean(isFetchingActivitySet && activitySet?.activities.length);

  const loadMoreItems = useCallback<OnMoreItemsRequestCallback>(
    async ({ timelineLoadedAt, isFirstBatch }) => {
      await dispatch(makeHiveFetchActivityThunk(id, dateUtil.getFormattedISODate(timelineLoadedAt)));

      if (isFirstBatch) {
        Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_HIVE_MODAL);
      }
    },
    [dispatch, id, dateUtil]
  );

  useEffect(() => {
    if (isOpen) {
      Analytics.startPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_HIVE_MODAL);
      return () => {
        Analytics.cancelPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_ACTIVITIES_HIVE_MODAL);
      };
    }
  }, [isOpen]);

  if (!isFocused) {
    return null;
  }

  return (
    <StyledActivitiesTab>
      <TimelineByActivityRequest
        items={activitySet?.activities ?? []}
        itemsTotalCount={activitySet?.totalCount ?? 0}
        highlightedHiveId={id}
        onMoreItemsRequest={loadMoreItems}
        isLoadingInitialItems={isLoadingInitialItems}
        isLoadingMoreItems={isLoadingMoreItems}
        footerMessage={t('timeline_bottom_hive_message')}
      />
    </StyledActivitiesTab>
  );
};
