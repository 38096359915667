import produce from 'immer';

import { RenewContractActionType, RenewContractReducer, RenewContractState } from './types';

const defaultState: RenewContractState = {
  isFetchingRenewContract: false,
};

export const renewContractReducer: RenewContractReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case RenewContractActionType.RENEW_CONTRACT_FETCH_START:
        state.isFetchingRenewContract = true;
        break;

      case RenewContractActionType.RENEW_CONTRACT_FETCH_SUCCESS:
      case RenewContractActionType.RENEW_CONTRACT_FETCH_FAILURE:
        state.isFetchingRenewContract = false;
        break;
    }

    return state;
  });
};
