import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@components/common/CTA';
import { HeaderAction, HeaderActions } from '@components/common/HeaderActions';
import { Add } from '@components/common/Icon/presets/Add';
import { Refresh } from '@components/common/Icon/presets/Refresh';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { Text } from '@components/common/Text';
import { YardsFiltersTrigger } from '@components/yard/YardsFilters';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';
import { useGlobalTimelineDataStatus } from '@redux/DataStatus/hooks';
import { DataStatusType } from '@redux/DataStatus/types';

export const WhiteboardHeader: React.VFC = () => {
  const t = useTranslation();
  const history = useHistory();

  const { status: timelineDataStatus } = useGlobalTimelineDataStatus();

  // TODO: Remove related logic once filters
  //  become functional in activities tab.
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);

  const [isTimelineRefreshInfoVisible, setIsTimelineRefreshInfoVisible] = useState(false);

  const goToYardManagement = useCallback(() => {
    history.push(URLUtil.buildPagePath(APP.routes.yardsMapManagement));
  }, [history]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  const actions: HeaderAction[] = useMemo(() => {
    return [
      {
        render: () => <YardsFiltersTrigger />,
      },
      {
        title: t('create_new_yard'),
        icon: Add,
        onClick: goToYardManagement,
        desktopOnly: true,
      },
    ];
  }, [goToYardManagement, t]);

  useEffect(() => {
    const tabsWithNormalButtons = [APP.routes.whiteboardYardsList, APP.routes.whiteboardYardsMap].map((path) =>
      URLUtil.buildPagePath(path)
    );
    setAreButtonsVisible(tabsWithNormalButtons.includes(history.location.pathname));

    const tabsWithRefreshInfo = [APP.routes.whiteboardActivities].map((path) => URLUtil.buildPagePath(path));
    setIsTimelineRefreshInfoVisible(tabsWithRefreshInfo.includes(history.location.pathname));
  }, [history.location.pathname]);

  return (
    <PageHeader>
      <PageHeaderTitle>{t('whiteboard')}</PageHeaderTitle>

      {areButtonsVisible && <HeaderActions actions={actions} />}

      {isTimelineRefreshInfoVisible &&
        (timelineDataStatus?.type === DataStatusType.DATA_OUTDATED ? (
          <Button onClick={reload} withLeadingIcon tertiary>
            <Refresh size={16} />
            {t('refresh_to_update')}
          </Button>
        ) : (
          <Text typography={'Heading3'} weight={'600'} color={'contentDisabled'}>
            {t('activities_up_to_date')}
          </Text>
        ))}
    </PageHeader>
  );
};
