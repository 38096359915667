import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import config from '@config/config';
import { Environment } from '@helpers/Environment';
import { QueryParams } from '@helpers/QueryParams';
import './util';

export function useIntercomArticleOpener(articleId: number) {
  const isEnabled = useIsIntercomEnabled();

  return useCallback(() => {
    if (isEnabled) {
      Intercom('showArticle', articleId);
    }
  }, [articleId, isEnabled]);
}

export function useIntercomInitializer(): void {
  const user = useSelector((state) => state.authReducer.user);
  const isIntercomEnabled = useIsIntercomEnabled();

  const intercomSettings = useMemo<Intercom_.IntercomSettings | null>(() => {
    if (!user || !user.currentMembership || !isIntercomEnabled) {
      return null;
    }
    const userEnv = Environment.getName();
    const userType = user.isStaff ? 'staff' : 'customer';
    const userName = [user.firstName, user.lastName].filter(Boolean).join(' ');

    return {
      app_id: config.REACT_APP_INTERCOM_APP_ID,
      user_id: user.intercomSettings.userId,
      user_hash: user.intercomSettings.userHash,
      name: userName,
      email: user.email,
      'Nectar User Env': userEnv,
      'Nectar User Type': userType,
      'Nectar Operation Id': user.currentMembership.operation.id,
      'Nectar Operation Name': user.currentMembership.operation.name,
      'Nectar Application': "Manager's Portal",
      custom_launcher_selector: '#intelcom-launcher',
      hide_default_launcher: true,
      alignment: 'left',
    };
  }, [isIntercomEnabled, user]);

  // Intercom boot, to execute once.
  useEffect(() => {
    if (!intercomSettings) {
      return;
    }

    Intercom('boot', intercomSettings);
  }, [intercomSettings, user]);

  // Per page update. It will flush any pending message/survey.
  useEffect(() => {
    if (!intercomSettings || !Intercom.booted) {
      return;
    }

    const handlePageChange = () => {
      Intercom('update', intercomSettings);
    };
    return QueryParams.addListener(handlePageChange);
  }, [intercomSettings]);

  // If the feature is disabled, clear up the intercom instance.
  useEffect(() => {
    if (!!user && !isIntercomEnabled) {
      Intercom('shutdown');
    }
  }, [isIntercomEnabled, user]);
}

export function useIntercomOnUnreadMessages() {
  const [count, setCount] = useState(0);
  const isIntercomEnabled = useIsIntercomEnabled();

  useEffect(() => {
    if (isIntercomEnabled) {
      return Intercom('onUnreadCountChange', setCount);
    }
  }, [isIntercomEnabled]);

  return { count };
}

export function useIsIntercomEnabled() {
  const { isFetchingSignIn, isFetchingSignOut, isFetchingUser, user } = useSelector((state) => state.authReducer);
  const notFetching = !isFetchingSignIn && !isFetchingSignOut && !isFetchingUser;
  return !!user?.featureFlag.intercomIntegration && notFetching;
}
