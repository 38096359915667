import React, { useCallback, useState } from 'react';

import { Button } from '@components/common/CTA';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { InputCalendar } from '@components/form/inputs/InputCalendar';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeFetchElapReportsThunk } from '@redux/Reports/actions';

export const ReportsHeader: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const today = new Date();
  const defaultFromDate = new Date(today.getFullYear(), 0, 1);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(today);

  const dispatchGetElapReport = useCallback(async () => {
    Analytics.sendEvent({
      event: AnalyticsEventType.ELAP_EXPORT_CLICK,
      eventData: { from_date: fromDate.toISOString(), to_date: toDate.toISOString() },
    });
    const start = +new Date();

    await dispatch(makeFetchElapReportsThunk(fromDate, toDate));

    const elapsed = (+new Date() - start) / 1000.0;
    Analytics.sendEvent({ event: AnalyticsEventType.ELAP_EXPORT_SUCCESS, eventData: { elapsed } });
  }, [dispatch, fromDate, toDate]);

  return (
    <PageHeader>
      <PageHeaderTitle>{t('reports')}</PageHeaderTitle>

      <InputCalendar
        id={'input-report-from-date'}
        name={'input-report-from-date'}
        value={fromDate}
        maxDate={toDate}
        onChange={(next) => setFromDate(next || defaultFromDate)}
      />
      {t('to')}
      <InputCalendar
        id={'input-report-to-date'}
        name={'input-report-to-date'}
        value={toDate}
        minDate={fromDate}
        onChange={(next) => setToDate(next || today)}
      />
      <Button primary onClick={dispatchGetElapReport}>
        {t('export')}
      </Button>
    </PageHeader>
  );
};
