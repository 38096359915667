import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useGridApi } from '@components/common/AgGrid';
import {
  BulkActionButton,
  BulkActionsNav,
  BulkActionTooltip,
  BulkActionWrapper,
} from '@components/common/BulkActionsNav';
import { useDynamicTableSelection } from '@components/common/DynamicTable/hooks';
import { Close } from '@components/common/Icon';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { Delete } from '@components/common/Icon/presets/Delete';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { Text } from '@components/common/Text';
import { Tooltip } from '@components/common/Tooltip';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useTranslation } from '@hooks/useTranslation';
import { makeRetireHiveThunk } from '@redux/HiveRetire/actions';

export const LostHivesBulkActions: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const { gridApi } = useGridApi();
  const { selectedRowIds, selectionCount, deselectAllAcrossPages, hasClickedSelectAllAcrossPages } =
    useDynamicTableSelection();
  const retireAsDeadoutModal = useDynamicModal();
  const removeHiveModal = useDynamicModal();

  const onAcceptClick = useCallback(
    async (status: 'deadout' | 'removed') => {
      await dispatch(makeRetireHiveThunk({ status, ids: hasClickedSelectAllAcrossPages ? [] : selectedRowIds }));
      deselectAllAcrossPages();
      gridApi?.refreshInfiniteCache();
      Analytics.sendEvent({
        event:
          status === 'deadout' ? AnalyticsEventType.LOST_HIVE_BULK_DEADOUT : AnalyticsEventType.LOST_HIVE_BULK_REMOVE,
      });
    },
    [deselectAllAcrossPages, dispatch, gridApi, hasClickedSelectAllAcrossPages, selectedRowIds]
  );
  const deadoutModalTitle =
    selectionCount === 1 ? t('flag_1_hive_as_deadout') : t('flag_count_hives_as_deadout', { count: selectionCount });

  const removeHiveModalTitle =
    selectionCount === 1 ? t('remove_1_hive') : t('remove_count_hives', { count: selectionCount });
  const removeHiveModalDesc =
    selectionCount === 1 ? t('remove_hive_desc') : t('remove_hives_desc', { count: selectionCount });
  const removeHiveModalAccept = selectionCount === 1 ? t('remove_hive') : t('remove_hives', { count: selectionCount });

  const hivesSelectedCountText = selectionCount === 1 ? 'hive_selected_1' : 'hives_selected_count';
  const removeHiveTooltipText = selectionCount === 1 ? 'remove_hive' : 'remove_hives';

  const retireAsDeadoutModalProps = useMemo(
    () => ({
      headerProps: {
        title: deadoutModalTitle,
        subtitle: t('flag_hive_deadout_desc'),
      },
      footerProps: {
        acceptText: t('flag_as_deadout'),
        acceptButtonProps: { danger: true, primary: true },
        onAcceptClick: () => onAcceptClick('deadout'),
        autoCloseOnAccept: true,
        rejectText: t('cancel'),
        rejectButtonProps: { tertiary: true },
        autoCloseOnReject: true,
      },
    }),
    [deadoutModalTitle, onAcceptClick, t]
  );

  const removeHiveModalProps = useMemo(
    () => ({
      headerProps: {
        title: removeHiveModalTitle,
        subtitle: removeHiveModalDesc,
      },
      footerProps: {
        acceptText: removeHiveModalAccept,
        acceptButtonProps: { danger: true, primary: true },
        onAcceptClick: () => onAcceptClick('removed'),
        autoCloseOnAccept: true,
        rejectText: t('cancel'),
        rejectButtonProps: { tertiary: true },
        autoCloseOnReject: true,
      },
    }),
    [onAcceptClick, removeHiveModalAccept, removeHiveModalDesc, removeHiveModalTitle, t]
  );

  const handleDeadoutsModal = useCallback(
    (e) => {
      e.stopPropagation();
      retireAsDeadoutModal.open(retireAsDeadoutModalProps);
    },
    [retireAsDeadoutModal, retireAsDeadoutModalProps]
  );

  const handleRemoveModal = useCallback(
    (e) => {
      e.stopPropagation();
      removeHiveModal.open(removeHiveModalProps);
    },
    [removeHiveModal, removeHiveModalProps]
  );

  return (
    <BulkActionsNav isVisible={!!selectionCount} leftHandText={t(hivesSelectedCountText, { count: selectionCount })}>
      <BulkActionButton onClick={handleDeadoutsModal}>
        <BulkActionWrapper id="assign-to-group-icon">
          <Deadout size={24} />
        </BulkActionWrapper>

        <Tooltip target="assign-to-group-icon">
          <Text typography="CaptionSmall">{t('flag_as_deadout')}</Text>
        </Tooltip>
      </BulkActionButton>

      <BulkActionButton onClick={handleRemoveModal}>
        <BulkActionWrapper id="assign-to-contract-icon">
          <Delete size={24} />
        </BulkActionWrapper>

        <BulkActionTooltip target="assign-to-contract-icon">
          <Text typography="CaptionSmall">{t(removeHiveTooltipText)}</Text>
        </BulkActionTooltip>
      </BulkActionButton>

      <BulkActionButton onClick={(e) => deselectAllAcrossPages && deselectAllAcrossPages()}>
        <BulkActionWrapper id="clear-selection-icon">
          <Close size={24} />
        </BulkActionWrapper>

        <Tooltip target="clear-selection-icon">
          <Text typography="CaptionSmall">{t('clear_selection')}</Text>
        </Tooltip>
      </BulkActionButton>
      {retireAsDeadoutModal.content}
      {removeHiveModal.content}
    </BulkActionsNav>
  );
};
