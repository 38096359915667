import { useCallback, useMemo } from 'react';

import { MarkerDescriptor } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';

const YARD_ELEMENT_Z_INDEX_LATITUDE_MULTIPLIER = -10000;
const YARD_ELEMENT_Z_INDEX_PRIORITY_MULTIPLIER = 1000000;

/**
 * Used to render yard pins, clustering and other optimizations.
 * */
export function useYardDescriptors(yards: Array<BeeYardOnMapInfo>) {
  return useMemo(() => {
    const descriptors = [] as Array<MarkerDescriptor>;

    yards.forEach((yard) => {
      const [lng, lat] = yard.yardCenter.coordinates;
      descriptors.push({ id: yard.id, position: { lng, lat }, data: yard });
    }, []);

    return descriptors;
  }, [yards]);
}

/**
 * Used to render yard hives, polygons, clustering and other optimizations.
 * */
export function useYardElementsDescriptors(yards: Array<BeeYardOnMapInfo>) {
  return useMemo(() => {
    const descriptors = [] as Array<MarkerDescriptor>;

    yards.forEach((yard) => {
      const { id, geometry } = yard;
      const geometryCoords = (geometry.coordinates[0] ?? []).map(([lng, lat]) => ({ lat, lng }));
      const bounds = MapUtil.getPathBounds(geometryCoords);
      if (bounds) {
        const path = MapUtil.getBoundsPath(bounds);
        path.forEach((position, index) => {
          descriptors.push({ id: `${id}-${index}`, position, data: yard });
        });
      }
    }, []);

    return descriptors;
  }, [yards]);
}

/**
 * Since there are four descriptors per yard elements bounds,
 * we need to make sure only one of them will actually render.
 * */
export function useYardElementsVisibilityFilter() {
  return useCallback((markers: Array<MarkerDescriptor>) => {
    const markersById = {} as Record<any, MarkerDescriptor>;
    markers.forEach((marker) => {
      const [id] = String(marker.id).split('-');
      markersById[id] = { ...marker, id };
    });
    return Object.values(markersById);
  }, []);
}

/**
 * Elements with higher priority will have a higher zIndex.
 * Items with same priority will use the latitude to generate
 * the appropriate zIndex.
 * */
export function useYardElementZIndexGetter() {
  return useCallback((yard: BeeYardOnMapInfo, priority: number) => {
    return (
      priority * YARD_ELEMENT_Z_INDEX_PRIORITY_MULTIPLIER +
      yard.yardCenter.coordinates[1] * YARD_ELEMENT_Z_INDEX_LATITUDE_MULTIPLIER
    );
  }, []);
}
