import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DateUtil } from '@helpers/Date';
import { useDateUtil } from '@helpers/Date/hooks';
import { Environment } from '@helpers/Environment';
import { useDispatch } from '@helpers/Thunk/hooks';
import useInterval from '@hooks/useInterval';
import { makeDataStatusFetchThunk, makeDataStatusNotifyUpdatedAction } from '@redux/DataStatus/actions';
import { DataStatusFilters, DataStatusType } from '@redux/DataStatus/types';

/**
 * These hooks will keep asking periodically the API if the given resource is up-to-date
 * or not. The update check interval has two values, and it works by following these rules:
 * - The check interval is initially set to the smaller value bellow;
 * - If the check returns that the data is up-to-date, the interval is multiplied by 2;
 * - The interval is multiplied on each check until it reaches the greater value, then it keeps constant;
 * - Whenever the check says the data is out-dated, the interval resets to the smaller value.
 *
 * This behavior will avoid excessive API requests when the inspections are idle.
 *
 * Note: Here we have a special value for the local env in order
 * to make testing easier.
 * */
const DEF_UPDATE_CHECK_INTERVAL = Environment.isLocal()
  ? [DateUtil.getTimeDeltaInMilliseconds({ seconds: 5 }), DateUtil.getTimeDeltaInMilliseconds({ seconds: 30 })]
  : [DateUtil.getTimeDeltaInMilliseconds({ seconds: 30 }), DateUtil.getTimeDeltaInMilliseconds({ minutes: 5 })];

// This is a shortcut hook for the global timeline
export function useGlobalTimelineDataStatus(options?: { autoUpdate?: boolean }) {
  return useDataStatusUpdater('global-timeline', options);
}

// This is a shortcut hook for the yard level timeline
export function useYardTimelineDataStatus(yardId: number, options?: { autoUpdate?: boolean }) {
  return useDataStatusUpdater(`yard-timeline-${yardId}`, { ...options, filters: { yardId } });
}

// Although this hook can be used in many places to check
// the current data status for a specific key, only one
// instance of it should enable "autoUpdate".
export function useDataStatusUpdater(key: string, options?: { filters?: DataStatusFilters; autoUpdate?: boolean }) {
  const [checkInterval, setCheckInterval] = useState(DEF_UPDATE_CHECK_INTERVAL[0]);

  const statuses = useSelector((state) => state.dataStatusReducer.statuses[key] ?? []);
  const dispatch = useDispatch();
  const dateUtil = useDateUtil();

  const notifyDataUpdated = useCallback(
    (timestamp: number) => {
      dispatch(makeDataStatusNotifyUpdatedAction(key, timestamp));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, key, options?.filters?.yardId]
  );

  const checkDataStatus = useCallback(() => {
    if (!options?.autoUpdate) {
      return;
    }

    const latestUpdateStatus = [...statuses].reverse().find(({ type }) => type == DataStatusType.DATA_UPDATED);
    const frozenAt = dateUtil.getFormattedISODate(
      latestUpdateStatus ? new Date(latestUpdateStatus?.timestamp) : new Date(0)
    );

    dispatch(makeDataStatusFetchThunk(key, frozenAt, options?.filters))?.then((isOutDated) => {
      if (typeof isOutDated === 'boolean') {
        if (isOutDated) {
          setCheckInterval(DEF_UPDATE_CHECK_INTERVAL[0]);
        } else {
          setCheckInterval((curr) => Math.min(DEF_UPDATE_CHECK_INTERVAL[1], curr * 2));
        }
      }
    });
  }, [dateUtil, dispatch, key, options?.autoUpdate, options?.filters, statuses]);

  useInterval(checkDataStatus, checkInterval);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkDataStatus(), []);

  return {
    // Recent data statuses.
    statuses,

    // The latest data status.
    status: statuses.slice(-1)[0] ?? null,

    // Notifies that the data has just loaded/reloaded.
    notifyDataUpdated,
  };
}
