import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Gutter } from '@components/common/Gutter';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { Clock } from '@components/common/Icon/presets/Clock';
import { Deadout } from '@components/common/Icon/presets/Deadout';
import { Delete } from '@components/common/Icon/presets/Delete';
import { Edit } from '@components/common/Icon/presets/Edit';
import { EmptyYard } from '@components/common/Icon/presets/EmptyYard';
import { HiveAdded } from '@components/common/Icon/presets/HiveAdded';
import { HiveMoved } from '@components/common/Icon/presets/HiveMoved';
import { Inspection } from '@components/common/Icon/presets/Inspection';
import { Yard } from '@components/common/Icon/presets/Yard';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonCircle } from '@components/common/LoadingSkeleton/LoadingSkeletonCircle';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { Text } from '@components/common/Text';
import { useTimelineAnalytics } from '@components/timeline/TimelineBase/hooks';
import {
  StyledGoToTopArrowButton,
  StyledTimeline,
  StyledTimelineFooter,
  StyledTimelineFooterCard,
  StyledTimelineInner,
  StyledTimelineItem,
  StyledTimelineItemGroup,
  StyledTimelineItemIconWrapper,
} from '@components/timeline/TimelineBase/styles';
import { TimelineBaseLoading } from '@components/timeline/TimelineBase/TimelineBaseLoading';
import { TimelineTimezoneAlert } from '@components/timeline/TimelineBase/TimelineTimezoneAlert';
import { BaseActivity, TimelineBaseProps } from '@components/timeline/TimelineBase/types';
import { TimelineDayMark } from '@components/timeline/TimelineDayMark';
import { TimelineEmpty } from '@components/timeline/TimelineEmpty';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useDateUtil } from '@helpers/Date/hooks';
import { useTranslation } from '@hooks/useTranslation';

const GO_TO_TOP_ARROW_OFFSET = 128;
const LOAD_MORE_OFFSET = 192;
const ICON_PRESETS: Record<BeeActivityByDateAlertType | BeeActivityByRequestAlertType, any> = {
  new_inspection: Inspection,
  hive_added_to_yard: HiveAdded,
  hive_managed_to_yard: HiveMoved,
  lost_hive_marked_as_deadout: Deadout,
  lost_hive_marked_as_removed: Delete,
  new_yard_visit: Yard,
  new_yard_created: Yard,
  yard_edited_by_manager: Edit,
  yard_cleared_out: EmptyYard,
  yard_deleted: Delete,
};

export const TimelineBase = <I extends BaseActivity>({
  items,
  itemHeadingRenderer,
  itemTimestampRenderer,
  itemContentRenderer,
  itemsTotalCount,
  onMoreItemsRequest,
  isLoadingInitialItems,
  isLoadingMoreItems,
  enableGoToTopArrow,
  suppressDayMarks,
  suppressInfinityScrolling,
  suppressDefaultPadding,
  footerMessage,
  emptyMessage,
}: TimelineBaseProps<I>) => {
  const [showGoToTopArrow, setShowGoToTopArrow] = useState(false);
  const [showGoToTopCard, setShowGoToTopCard] = useState(false);

  const t = useTranslation();
  const dateUtil = useDateUtil();
  const scrollRef = useRef<HTMLDivElement>(null);
  const loadMoreCalledFor = useRef<Record<number, boolean>>({ 0: true });
  const hasMoreToLoad = items.length < itemsTotalCount;
  const isEmpty = itemsTotalCount === 0;

  const timelineLoadedAt = useMemo(() => new Date(), []);

  useTimelineAnalytics(scrollRef);

  const groups = useMemo(() => {
    const groups: Array<Array<I>> = [];
    items.forEach((item, index, arr) => {
      if (index === 0) {
        groups.push([item]);
        return;
      }

      const prevDate = dateUtil.getFormattedDate(arr[index - 1].occurredAt, 'YYYY-MM-DD');
      const currDate = dateUtil.getFormattedDate(item.occurredAt, 'YYYY-MM-DD');

      if (prevDate !== currDate) {
        groups.push([item]);
      } else {
        groups[groups.length - 1].push(item);
      }
    });
    return groups;
  }, [dateUtil, items]);

  const scrollToTop = useCallback(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    Analytics.sendEvent({
      event: AnalyticsEventType.TIMELINE_GO_TO_TOP,
      eventData: Analytics.getPageData(),
    });
  }, []);

  const getIcon = useCallback((alertType: BeeActivityByDateAlertType | BeeActivityByRequestAlertType) => {
    return ICON_PRESETS[alertType];
  }, []);

  useLayoutEffect(() => {
    if (suppressInfinityScrolling) {
      return;
    }

    const scrollElement = scrollRef.current;
    const handler = throttle(() => {
      if (!scrollElement) {
        return;
      }

      const scrollableArea = scrollElement.scrollHeight - scrollElement.offsetHeight;
      const scrollTop = scrollElement.scrollTop;
      const scrollToGo = scrollableArea - scrollTop;
      const hasReachedScrollOffset = scrollableArea && scrollToGo <= LOAD_MORE_OFFSET;
      const hasLoadedForCurrentItems = loadMoreCalledFor.current[items.length];
      if (hasMoreToLoad && hasReachedScrollOffset && !hasLoadedForCurrentItems) {
        loadMoreCalledFor.current[items.length] = true;
        onMoreItemsRequest && onMoreItemsRequest({ timelineLoadedAt, isFirstBatch: false });
      }

      const isScrollNearTop = scrollTop < GO_TO_TOP_ARROW_OFFSET;
      const isScrollNearBottom = scrollToGo < GO_TO_TOP_ARROW_OFFSET;

      const hasScroll = scrollableArea > 0;
      const showGoToTopCard = hasScroll && !hasMoreToLoad && !isEmpty && !suppressInfinityScrolling;
      const showGoToTopArrow = !!enableGoToTopArrow && !isScrollNearTop && (!isScrollNearBottom || hasMoreToLoad);

      setShowGoToTopArrow(showGoToTopArrow);
      setShowGoToTopCard(showGoToTopCard);
    }, 120);

    scrollElement?.addEventListener('scroll', handler);
    handler();

    return () => scrollElement?.removeEventListener('scroll', handler);
  }, [
    enableGoToTopArrow,
    hasMoreToLoad,
    isEmpty,
    items.length,
    onMoreItemsRequest,
    suppressInfinityScrolling,
    timelineLoadedAt,
  ]);

  useEffect(() => {
    onMoreItemsRequest && onMoreItemsRequest({ timelineLoadedAt, isFirstBatch: true });
  }, [onMoreItemsRequest, timelineLoadedAt]);

  useEffect(() => {
    // If the list of items is emptied, reset the scrolling history.
    if (items.length) {
      loadMoreCalledFor.current = {};
    }
  }, [items.length]);

  return (
    <StyledTimeline ref={scrollRef} column stretch $suppressScrolling={suppressInfinityScrolling}>
      <Gutter padding_0={suppressDefaultPadding}>
        <StyledGoToTopArrowButton $isVisible={showGoToTopArrow} onClick={scrollToTop}>
          <ArrowRight size={24} />
        </StyledGoToTopArrowButton>

        <StyledTimelineInner column stretch $isVisible={!isLoadingInitialItems} $suppressDayMarks={suppressDayMarks}>
          <TimelineTimezoneAlert />

          {groups.map((group, groupIndex) => (
            <Box column stretch key={groupIndex}>
              {!suppressDayMarks && <TimelineDayMark date={group[0].occurredAt} />}

              <StyledTimelineItemGroup column stretch gap_150 $suppressDayMarks={suppressDayMarks}>
                {group.map((item, index) => {
                  const Icon = getIcon(item.alertType);
                  return (
                    <StyledTimelineItem column stretch key={index}>
                      {itemHeadingRenderer(item, index)}

                      {itemTimestampRenderer ? (
                        itemTimestampRenderer(item, index)
                      ) : (
                        <Box alignItems={'center'} marginBottom_100>
                          <Clock size={16} />
                          <Box paddingLeft_025 />
                          <Text typography={'SmallParagraph'}>
                            {dateUtil.getFormattedDate(item.occurredAt, 'H:mm')}
                          </Text>
                        </Box>
                      )}

                      {itemContentRenderer(item, index)}

                      <StyledTimelineItemIconWrapper>
                        {Icon && <Icon size={12} color={'white'} />}
                      </StyledTimelineItemIconWrapper>
                    </StyledTimelineItem>
                  );
                })}
              </StyledTimelineItemGroup>
            </Box>
          ))}

          {isLoadingMoreItems && (
            <LoadingSkeleton column stretch paddingTop_050 visible>
              {!suppressDayMarks && (
                <Box marginVertical_150 center>
                  <LoadingSkeletonRectangle width={120} height={32} borderRadius={'paperRadius03'} />
                </Box>
              )}
              <Box marginVertical_150 alignItems={'center'} gap_100>
                <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
                <LoadingSkeletonRectangle width={128} height={24} />
              </Box>
            </LoadingSkeleton>
          )}

          {showGoToTopCard && (
            <StyledTimelineFooter marginVertical_150>
              <StyledTimelineFooterCard>
                <Box center gap_050>
                  <Text typography={'Heading3'}>{footerMessage}</Text>
                  <Button tertiary suppressPadding onClick={scrollToTop}>
                    {t('timeline_bottom_go_to_top')}
                  </Button>
                </Box>
              </StyledTimelineFooterCard>
            </StyledTimelineFooter>
          )}

          {isEmpty && <TimelineEmpty message={emptyMessage} suppressDefaultPadding={suppressDefaultPadding} />}
        </StyledTimelineInner>

        <TimelineBaseLoading
          suppressDayMarks={suppressDayMarks}
          visible={isLoadingInitialItems}
          absolutelyFitParent
          marginHorizontal_025
          paddingHorizontal_100
          paddingVertical_050
        />
      </Gutter>
    </StyledTimeline>
  );
};
