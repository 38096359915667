import React from 'react';

import { useColor } from '@style/theme/hooks';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const Assign = ({ color, size }: IconSVGProps) => {
  return (
    <IconSVGView viewBox="0 0 24 24" size={size}>
      <path
        fill={useColor(color)}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.89453 2 3 2.89453 3 4V13C3 13.5508 3.44922 14 4 14C4.55078 14 5 13.5508 5 13V5C5 4.44922 5.44922 4 6 4H13C13 2.89453 12.1055 2 11 2H5ZM9 6C7.89453 6 7 6.89453 7 8V17C7 17.5508 7.44922 18 8 18C8.55078 18 9 17.5508 9 17V9C9 8.44922 9.44922 8 10 8H17C17 6.89453 16.1055 6 15 6H9ZM11 12C11 10.8945 11.8945 10 13 10H19C20.1055 10 21 10.8945 21 12V21C21 21.5508 20.5508 22 20 22C19.4492 22 19 21.5508 19 21V20H13V21C13 21.5508 12.5508 22 12 22C11.4492 22 11 21.5508 11 21V12ZM13.5 12H14.5C14.7773 12 15 12.2227 15 12.5V13.5C15 13.7773 14.7773 14 14.5 14H13.5C13.2227 14 13 13.7773 13 13.5V12.5C13 12.2227 13.2227 12 13.5 12ZM18.5 12H17.5C17.2227 12 17 12.2227 17 12.5V13.5C17 13.7773 17.2227 14 17.5 14H18.5C18.7773 14 19 13.7773 19 13.5V12.5C19 12.2227 18.7773 12 18.5 12ZM13.5 16H14.5C14.7773 16 15 16.2227 15 16.5V17.5C15 17.7773 14.7773 18 14.5 18H13.5C13.2227 18 13 17.7773 13 17.5V16.5C13 16.2227 13.2227 16 13.5 16ZM18.5 16H17.5C17.2227 16 17 16.2227 17 16.5V17.5C17 17.7773 17.2227 18 17.5 18H18.5C18.7773 18 19 17.7773 19 17.5V16.5C19 16.2227 18.7773 16 18.5 16Z"
      />
    </IconSVGView>
  );
};
