import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { ActivityByRequestSet, HiveDetailsAction, HiveDetailsActionType } from '@redux/HiveDetails/types';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

const DEF_HIVE_DETAIL_CACHE_MAX_AGE = 60 * 1000;
const DEF_HIVE_ACTIVITIES_PAGE_SIZE = 20;

export const makeHiveDisposeAction = (id: number): HiveDetailsAction => ({
  type: HiveDetailsActionType.HIVE_DETAILS_DISPOSE,
  payload: { id },
});

export const makeHiveFetchDetailStartAction = (id: number): HiveDetailsAction => ({
  type: HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_START,
  payload: { id },
});

export const makeHiveFetchDetailFinishAction = (
  id: number,
  data: BeeHiveOnModalDetailedInfo | null,
  error: ApiResponseError | null
): HiveDetailsAction => ({
  type: HiveDetailsActionType.HIVE_DETAILS_FETCH_DETAIL_FINISH,
  payload: {
    id,
    data,
    error,
  },
});

export const makeHiveFetchDetailThunk = Thunk.createTakeFirst((id: number) => {
  return async (dispatch) => {
    dispatch(makeHiveFetchDetailStartAction(id));

    const response = await CachedRequest.performRequest(
      URLUtil.buildURL(ENDPOINTS.hhtHiveDetail, { id }),
      (url) => Api.get(url),
      DEF_HIVE_DETAIL_CACHE_MAX_AGE
    );

    let data: BeeHiveOnModalDetailedInfo | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction(error.snackbarOptions));
    } else {
      data = parseHiveFromApi(await response.json());
    }

    dispatch(makeHiveFetchDetailFinishAction(id, data, error));
  };
});

export const makeHiveFetchActivityStartAction = (id: number): HiveDetailsAction => ({
  type: HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_START,
  payload: { id },
});

export const makeHiveFetchActivityFinishAction = (
  id: number,
  data: ActivityByRequestSet | null,
  error: ApiResponseError | null
): HiveDetailsAction => ({
  type: HiveDetailsActionType.HIVE_DETAILS_FETCH_ACTIVITY_FINISH,
  payload: {
    id,
    data,
    error,
  },
});

export const makeHiveFetchActivityThunk = Thunk.createTakeFirst((id: number, frozenAt?: string) => {
  return async (dispatch, getState) => {
    dispatch(makeHiveFetchActivityStartAction(id));

    const offset = getState().hiveDetailsReducer.hives[id].activitySet?.activities.length ?? 0;
    const limit = DEF_HIVE_ACTIVITIES_PAGE_SIZE;

    const response = await CachedRequest.performRequest(
      URLUtil.buildURL(ENDPOINTS.hhtActivitiesByRequest, {
        hive_identity: id,
        limit,
        offset,
        frozen_at: frozenAt ?? null,
      }),
      (url) => Api.get(url),
      DEF_HIVE_DETAIL_CACHE_MAX_AGE
    );

    let data: ActivityByRequestSet | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      error = response.error;
      dispatch(makeShowSnackbarAction(error.snackbarOptions));
    } else {
      data = parseTimelineVisitActivityFromApi(await response.json());
    }

    dispatch(makeHiveFetchActivityFinishAction(id, data, error));
  };
});

function parseHiveFromApi(data: any): BeeHiveOnModalDetailedInfo {
  return CaseAdapter.objectToCamelCase(data);
}

export function parseTimelineVisitActivityFromApi({ count, results }: any): ActivityByRequestSet {
  return {
    totalCount: count,
    activities: results.map((entry: any) => CaseAdapter.objectToCamelCase(entry)),
  };
}
