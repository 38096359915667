import React from 'react';
import styled from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';
import { UnstyledButton } from '@components/common/UnstyledButton';
import { from } from '@style/mediaQueries';

import { Tooltip } from '../Tooltip';

const StyledBottomNav = styled.div<{ visible?: boolean }>(
  ({ theme, visible }) => `
  position: fixed;
  left: 50%;
  bottom: 1.5rem;  
  transition: ${theme.animations.transitionMedium('opacity', 'transform')};
  opacity: ${visible ? 1 : 0};
  transition-delay: ${visible ? '0ms' : '400ms'};
  transform: translateX(-50%) ${visible ? 'translateY(0px) scale(1)' : 'translateY(64px) scale(0.5)'};
  z-index: ${theme.getZIndexOf('tabs')};

  ${from('desktopSM')} {
    bottom: 2.5rem;
  }
`
);

export const BulkActionWrapper = styled.span(
  ({ theme }) => `
    padding: ${theme.spacing._050};
    display: block;
    border-radius: 3px;

    &:hover {
      background-color: ${theme.primitives.colors.white};
      color: ${theme.primitives.colors.grey08};
    }
  
  `
);

export const BulkActionButton = styled(UnstyledButton)(
  ({ theme }) => `
    &:disabled ${BulkActionWrapper} {
      color: ${theme.primitives.colors.grey06};
      &:hover {
        background-color: initial;
        color: ${theme.primitives.colors.grey06};
      }
    }
  `
);

export const BatchActionBar = styled(Box)(
  ({ theme }) => `
    border-radius: 10000px;
    padding: ${theme.spacing._050} ${theme.spacing._150}; 
    
    background-color: ${theme.primitives.colors.grey08};
    color: ${theme.primitives.colors.white};
    
    white-space: nowrap;
    cursor: pointer;

  `
);

export const BatchActionVerticalLine = styled.div(
  ({ theme }) => `
    width: 0px;
    height: 24px;
    border: 1px solid ${theme.primitives.colors.white};
    margin: 0 12px;
  `
);

export const BulkActionTooltip = styled(Tooltip)`
  max-width: 200px;
`;

export const BulkActionsNav: React.FC<{ leftHandText?: string; isVisible: boolean }> = ({
  leftHandText,
  isVisible,
  children,
}) => {
  return (
    <StyledBottomNav visible={isVisible}>
      <BatchActionBar center>
        {leftHandText ? (
          <>
            <Text typography={'SmallParagraph'} weight={'600'}>
              {leftHandText}
            </Text>
            <BatchActionVerticalLine />
          </>
        ) : null}

        {children}
      </BatchActionBar>
    </StyledBottomNav>
  );
};
