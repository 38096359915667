/* eslint-disable no-undef */
import PACKAGE from '../../package.json';

const config = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_GMAP_API_KEY: process.env.REACT_APP_GMAP_API_KEY,
  REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID,
  REACT_APP_INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  VERSION: `v${PACKAGE.version}`,
};

export default config;
