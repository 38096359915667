import styled from 'styled-components';

import { Box } from '@components/common/Box';

const DEF_DURATION = 1800;
const DEF_SIZE = '50%';

export const Wave = styled(Box)<{ delay: number }>(
  ({ theme, delay }) => `
  @keyframes waves-animation-position {
    0% { left: -${DEF_SIZE}; }
    100% { left: 100%; }
  }
  
  @keyframes waves-animation-opacity {
    0% { opacity: 0; }
    10% { opacity: 0.12; }
    90% { opacity: 0.12; }
    100% { opacity: 0; }
  }
  
  pointer-events: none;
  background: linear-gradient(90deg, transparent, ${theme.primitives.colors.grey08}, transparent);
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -${DEF_SIZE};
  width: ${DEF_SIZE};
  height: 100%;
  animation: 
    waves-animation-position ${DEF_DURATION}ms ${theme.animations.easing} infinite ${delay}ms,
    waves-animation-opacity ${DEF_DURATION}ms linear infinite ${delay}ms;
`
);

export const LoadingWavesView = styled(Box)<{ loading?: boolean }>(
  ({ loading }) => `
  position: absolute;
  overflow: hidden;
  pointer-events: ${loading ? 'none' : 'all'};
  
  ${Wave} {
    display: ${loading ? 'flex' : 'none'};
  }
`
);
