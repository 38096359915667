import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { useTheme } from 'styled-components';

import { ReactComponent as TrendArrow } from '@assets/arrow.svg';
import { ArrowDown } from '@components/common/Icon/presets/ArrowDown';
import { Loading } from '@components/common/Loading';
import { ModalHeader } from '@components/common/ModalBase';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { Text } from '@components/common/Text';
import { DropdownArrowSpan } from '@components/form/inputs/Dropdown/styles';
import { useCheckTrendDirectionGetter } from '@config/GlobalMetricsConfig/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { makeCloseMetricModal, makeFetchMetricTrendThunk } from '@redux/Yards/actions';
import { MetricTrendValues, MetricValue, TrendValues } from '@redux/Yards/types';
import { useYardsFilters } from '@redux/YardsFilters/hooks';

import { NAText, TrendContainer, TrendValue } from '../MetricCard/style';
import { CardType } from '../MetricCard/type';

import {
  ChartWrapper,
  DropDownCategories,
  DropDownWrapper,
  Legend,
  ModalCenterContainer,
  ModalChart,
  StyledModal,
} from './style';

const MetricModal: React.VFC = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    isFetching,
    isMetricModalOpen,
    metricToQuery,
    data,
    currentMetricValue,
    positiveDirection,
    cardType,
    categories,
  } = useSelector((state) => state.yardsReducer.trends);
  const { appliedFilters } = useYardsFilters();
  const svgRef = useRef<SVGSVGElement>(null);
  const legendRef = useRef(null);
  const tooltipRef = useRef(null);

  const [category, setCategory] = useState('');
  const [displayedMetricValue, setDisplayedMetricValue] = useState(currentMetricValue);

  useEffect(() => {
    if (currentMetricValue.length > 0) {
      if (cardType == CardType.CATEGORY) {
        if (category == '' && categories != null) {
          setDisplayedMetricValue([
            {
              value: (currentMetricValue as any[])?.find((element: MetricValue) => element.name == categories[0]).value,
              previousValue: (currentMetricValue as any[])?.find(
                (element: MetricValue) => element.name == categories[0]
              ).previousValue,
            },
          ]);
        } else {
          setDisplayedMetricValue([
            {
              value: (currentMetricValue as any[])?.find(
                (element: MetricValue) => element.name == category.toLowerCase()
              ).value,
              previousValue: (currentMetricValue as any[])?.find(
                (element: MetricValue) => element.name == category.toLowerCase()
              ).previousValue,
            },
          ]);
        }
      } else {
        setDisplayedMetricValue([...currentMetricValue]);
      }
    }
  }, [category, currentMetricValue, cardType, categories]);

  const checkTrendDirection = useCheckTrendDirectionGetter();

  const [trendMeta, setTrendMeta] = useState(() => checkTrendDirection(displayedMetricValue, positiveDirection));

  const trendValue = useMemo(() => {
    if (displayedMetricValue[0].previousValue && displayedMetricValue[0].value) {
      return Math.round(Math.abs(displayedMetricValue[0].value - displayedMetricValue[0].previousValue) * 100) / 100;
    }
    return 0;
  }, [displayedMetricValue]);

  function handleSelectChange(event: React.FormEvent<HTMLSelectElement>) {
    setCategory((event.target as HTMLInputElement).value);
  }

  useEffect(() => {
    setTrendMeta(checkTrendDirection(currentMetricValue, positiveDirection));
  }, [checkTrendDirection, currentMetricValue, positiveDirection]);

  const retrieveTrends = useMemo(
    () =>
      debounce((thunkMethod: any) => {
        dispatch(thunkMethod);
      }),
    [dispatch]
  );

  useEffect(() => {
    if (!isMetricModalOpen) {
      return;
    }

    if (cardType == CardType.CATEGORY) {
      if (category == '') {
        if (categories != null) {
          retrieveTrends(makeFetchMetricTrendThunk(metricToQuery + '_' + categories[0].toLowerCase()));
        }
      } else {
        retrieveTrends(makeFetchMetricTrendThunk(metricToQuery + '_' + category.toLowerCase()));
      }
    } else {
      retrieveTrends(makeFetchMetricTrendThunk(metricToQuery, displayedMetricValue));
    }
  }, [
    dispatch,
    appliedFilters,
    metricToQuery,
    category,
    categories,
    cardType,
    displayedMetricValue,
    isMetricModalOpen,
    retrieveTrends,
  ]);

  const closeModal = useCallback(() => {
    dispatch(makeCloseMetricModal());
  }, [dispatch]);

  useEffect(() => {
    const margin = { top: 25, right: 60, bottom: 30, left: 70 };
    const axisSpacing = 20;
    const width = 590 + 56 - margin.left - margin.right - axisSpacing;
    const height = 280 - margin.top - margin.bottom - axisSpacing;
    const legendHeight = 2;
    const legendWidth = 10;
    const legendStartPosition = 20;
    const legendSpacing = 100;
    const circleRadius = 6;
    const scaleFactor = 1.5;
    const spacingLegendLabelFactor = 1.5;

    if (data[0].trend.length > 0) {
      const xDomain = d3.extent(data[1].trend, (d) => d.ts) as Iterable<Date>;
      const xScale = d3.scaleTime().domain(xDomain).range([0, width]).nice();

      const yMin = d3.min(data[0].trend.concat(data[1].trend), (d) => d.value / scaleFactor);
      const yMax = d3.max(data[0].trend.concat(data[1].trend), (d) => d.value * scaleFactor);

      const keys: string[] = data.map((x) => x.name);

      const yScale = d3
        .scaleLinear()
        .domain([yMin, yMax] as Iterable<number>)
        .range([height, 0])
        .nice();

      const color = d3
        .scaleOrdinal()
        .domain(keys)
        .range([theme.primitives.colors.orange01, theme.primitives.colors.focus02]);

      // Create legend SVG
      const legendSVG = d3.select<SVGSVGElement | null, unknown>(legendRef.current);
      const originalLegendRect = legendSVG.selectAll<SVGRectElement, number[]>('label-rect').data(keys);

      const newLegend = originalLegendRect.enter().append('rect');

      originalLegendRect
        .merge(newLegend)
        .attr('y', legendStartPosition)
        .attr('x', function (d, i) {
          return i * (legendWidth + legendSpacing);
        })
        .attr('width', legendWidth)
        .attr('height', legendHeight)
        .style('fill', function (d: string) {
          return color(d) as string;
        });

      originalLegendRect.exit().remove();

      const originalLegendLabel = legendSVG.selectAll<SVGTextElement, number[]>('label-text').data(keys);

      const newLegendLabel = originalLegendLabel.enter().append('text');

      originalLegendLabel
        .merge(newLegendLabel)
        .attr('y', legendStartPosition * 1.1)
        .attr('x', function (d, i) {
          return i * (legendWidth + legendSpacing) + legendWidth * spacingLegendLabelFactor;
        })
        .style('fill', theme.primitives.colors.grey06)
        .style('font-size', '12px')
        .style('font-weight', '400')
        .text(function (d) {
          return t(d);
        })
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle');

      originalLegendLabel.exit().remove();

      // Create chart SVG
      const svgEl = d3.select(svgRef.current).attr('width', '100%').attr('height', '100%');

      // Remove Lines and axis on data update
      svgEl.selectAll('.line-group').remove();
      svgEl.selectAll('.y-axis-grid').remove();
      svgEl.selectAll('.x-axis').remove();
      svgEl.selectAll('.y-axis').remove();

      const svg = svgEl.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // this rectangle is needed to catch mouse events on the chart
      const rect = svg
        .append('rect')
        .attr('class', 'listening-rect')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', 'transparent');

      // Create line
      const line = d3
        .line<TrendValues>()
        .x((d) => xScale(d.ts))
        .y((d) => yScale(d.value))
        .curve(d3.curveMonotoneX);

      const lines = svg.append('g').attr('class', 'lines');

      // Zero full line (not dotted)
      if (yScale.domain()[0] != null) {
        lines
          .append('g')
          .append('line')
          .attr('class', 'zero-line')
          .attr('x1', xScale(data[1].trend[0].ts))
          .attr('x2', xScale(data[1].trend[11].ts))
          .attr('y1', yScale(yScale.domain()[0]))
          .attr('y2', yScale(yScale.domain()[0]))
          .style('stroke', theme.primitives.colors.grey03)
          .style('border', '1px solid');
      }

      const originalLines = lines.selectAll<SVGPathElement, number[]>('.line-group').data(data);

      const newLines = originalLines
        .enter()
        .append('g')
        .attr('class', 'line-group')
        .append('path')
        .attr('class', 'line');

      originalLines
        .merge(newLines)
        .attr('pointer-events', 'none')
        .attr('d', (d: MetricTrendValues) => line(d.trend))
        .style('stroke', (d: MetricTrendValues) => color(d.name) as string)
        .style('fill', 'none')
        .attr('border', '1px solid');

      originalLines.exit().remove();

      // Create tooltip, line and bubble on mouse over

      // Tooltip (Fix the tooltip BOX )
      const tooltipEl = d3.select(tooltipRef.current);

      tooltipEl.exit().remove();

      const tooltip = tooltipEl
        .append<any>('g')
        .attr('class', 'tooltip-wrapper')
        .attr('display', 'none')
        .attr('pointer-events', 'none');

      const tooltipBackground = tooltip.append<SVGRectElement>('rect').attr('fill', theme.primitives.colors.grey08);
      const tooltipContent = tooltip.append<SVGTextElement>('text');

      tooltip.merge(tooltipBackground);
      tooltip.merge(tooltipContent);

      const onMouseMoveOrOver = function (event: React.MouseEvent<HTMLElement>) {
        lines.style('cursor', 'none').transition().duration(250).selectAll('.circle').remove();
        lines.selectAll('.tooltipLine').remove();
        lines.selectAll('.text').remove();
        lines.selectAll('.circle').remove();
        tooltip.attr('display', 'none');

        const target = rect.node();
        if (!target) return;
        const x = event.clientX - target.getBoundingClientRect().left - target.clientLeft;
        const xDate = xScale.invert(x) as Date;
        const bisectDate = d3.bisector((d: TrendValues) => d.ts).left;

        const index = bisectDate(data[1].trend, xDate, 1);
        const hoveredTrendsData = data.map(({ trend }) => {
          if (index >= trend.length) return null;

          const previousData = trend[index - 1];
          const currentData = trend[index];
          const isCloserToCurrentData = x - xScale(previousData.ts) > xScale(currentData.ts) - x;

          return isCloserToCurrentData ? currentData : previousData;
        });
        if (!hoveredTrendsData[1]) return; // last year's hovered value cannot be null

        const thisYearHoveredData: TrendValues | null = hoveredTrendsData[0];
        const lastYearHoveredData: TrendValues = hoveredTrendsData[1];

        // Appear tooltip box
        tooltip.attr('display', 'flex');
        tooltipContent.selectAll('.tooltip-text-line').remove();

        const formatMonth = d3.timeFormat('%B');
        const fullMonthName = formatMonth(lastYearHoveredData.ts);

        tooltipContent
          .append('tspan')
          .attr('class', 'tooltip-text-line')
          .attr('x', '10')
          .attr('dy', '18px')
          .attr('font-size', '10px')
          .attr('font-weight', '600')
          .attr('font-weight', 'bold')
          .attr('fill', theme.primitives.colors.white)
          .text(`${fullMonthName} ${currentYear}`);

        tooltipContent
          .append('tspan')
          .attr('class', 'tooltip-text-line')
          .attr('x', '10')
          .attr('y', '20')
          .attr('dy', `15px`)
          .attr('font-size', '10px')
          .attr('font-weight', '400')
          .attr('fill', theme.primitives.colors.white)
          .text(`${t('current_season_trend')}: ${thisYearHoveredData?.value ?? t('not_available')}`);

        tooltipContent
          .append('tspan')
          .attr('class', 'tooltip-text-line')
          .attr('x', '10')
          .attr('y', '35')
          .attr('dy', `15px`)
          .attr('font-size', '10px')
          .attr('font-weight', '400')
          .attr('fill', theme.primitives.colors.white)
          .text(`${t('last_season_trend')}: ${lastYearHoveredData.value}`);

        const n = tooltipContent.node();
        if (n) {
          const tooltipWidth = n.getBBox().width;
          const tooltipHeight = n.getBBox().height;
          tooltipBackground.attr('width', tooltipWidth + 20).attr('height', tooltipHeight + 20);
        }
        if (thisYearHoveredData?.value) {
          if (lastYearHoveredData.value > thisYearHoveredData.value) {
            tooltip.attr('transform', `translate(${x / 1.5}, ${yScale(lastYearHoveredData.value) / 4.0})`);
          } else {
            tooltip.attr('transform', `translate(${x / 1.5}, ${yScale(thisYearHoveredData.value) / 4.0})`);
          }
        } else {
          tooltip.attr('transform', `translate(${x / 1.5}, ${yScale(lastYearHoveredData.value) / 4.0})`);
        }

        //  Vertical line
        lines
          .append('line')
          .attr('class', 'tooltipLine')
          .attr('pointer-events', 'none')
          .attr('x1', xScale(lastYearHoveredData.ts))
          .attr('x2', xScale(lastYearHoveredData.ts))
          .attr('y1', -margin.top)
          .attr('y2', height)
          .style('stroke', theme.primitives.colors.grey03)
          .style('border', '1px solid');

        //  Dot on horizontal line 1
        if (thisYearHoveredData != null) {
          lines
            .append('circle')
            .attr('pointer-events', 'none')
            .attr('class', 'circle')
            .attr('cx', xScale(thisYearHoveredData.ts))
            .attr('cy', yScale(thisYearHoveredData.value))
            .attr('r', circleRadius)
            .style('opacity', '1.0')
            .style('fill', color(data[0].name) as string);
        }

        //  Dot on horizontal line 2

        lines
          .append('circle')
          .attr('pointer-events', 'none')
          .attr('order', 2)
          .attr('class', 'circle')
          .attr('cx', xScale(lastYearHoveredData.ts))
          .attr('cy', yScale(lastYearHoveredData.value))
          .attr('r', circleRadius)
          .style('opacity', '1.0')
          .style('fill', color(data[1].name) as string);

        // Tooltip on top
        tooltipEl.raise();
      };

      // Create axis
      const xAxis = d3
        .axisBottom<Date>(xScale)
        .tickPadding(10)
        .tickSize(0)
        .tickFormat(d3.timeFormat('%b'))
        .tickPadding(axisSpacing);

      const yAxis = d3.axisLeft(yScale).tickPadding(10).tickSize(0).ticks(5).tickPadding(axisSpacing);

      const yAxisGrid = d3
        .axisLeft(yScale)
        .tickSize(-width)
        .tickFormat(function () {
          return '';
        })
        .ticks(5);

      svg
        .append('g')
        .attr('class', 'y-axis-grid')
        .call(yAxisGrid)
        .call((g) => g.select('.domain').remove())
        .style('stroke-dasharray', '5 5')
        .style('stroke', 'grey04')
        .style('stroke-opacity', 0.2);

      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis)
        .call((g) => g.select('.domain').remove());

      svg
        .append('g')
        .attr('class', 'y-axis')
        .call(yAxis)
        .call((g) => g.select('.domain').remove());

      if (yMin === 0) {
        // removes first tick to avoid having overlapping full and dotted lines
        d3.select('.y-axis-grid .tick:first-child').remove();
      }

      // Activate tooltip on elements
      // Since we want the hover for the full chart not just the line
      svg.on('mousemove', onMouseMoveOrOver).on('mouseout', function () {
        lines.style('cursor', 'none').transition().duration(250).selectAll('.circle').remove();
        lines.selectAll('.tooltipLine').remove();
        lines.selectAll('.text').remove();
        lines.selectAll('.circle').remove();
        tooltip.attr('display', 'none');
      });
    }
  }, [data, t, theme, currentYear, category, metricToQuery]);

  return (
    <StyledModal isOpen={isMetricModalOpen} onRequestClose={closeModal} width="trends">
      <ModalHeader title={t(metricToQuery, { year: currentYear })} />
      <ModalContent>
        <ModalCenterContainer>
          <span id={`metric-value-trend-${metricToQuery}`}>
            {' '}
            {!displayedMetricValue[0].value && <NAText>{t('not_available')}</NAText>}
            {displayedMetricValue[0].value > 0 && (
              <Text typography={'Heading1'} weight={'600'} color={'grey08'}>
                {displayedMetricValue[0].value}{' '}
              </Text>
            )}
          </span>
          <TrendContainer>
            <TrendValue color={trendMeta.color}>
              {' '}
              <Text typography={'CaptionSmall'} weight={'700'}>
                {trendValue ? trendValue : null}
              </Text>{' '}
            </TrendValue>
            <TrendArrow style={{ fill: trendMeta.color, transform: trendMeta.direction }} />
          </TrendContainer>
          {categories != null && cardType == CardType.CATEGORY ? (
            <DropDownWrapper>
              <DropDownCategories value={category} onChange={(e) => handleSelectChange(e)}>
                <label id="category"></label>
                {categories.map((v, i) => (
                  <option key={i}>{t(v)}</option>
                ))}
              </DropDownCategories>
              <DropdownArrowSpan>
                <ArrowDown />
              </DropdownArrowSpan>
            </DropDownWrapper>
          ) : null}
        </ModalCenterContainer>
        <ChartWrapper>
          <ModalChart
            ref={svgRef}
            key={metricToQuery /** Make sure the same chart is not reused for different metrics */}
          >
            <svg ref={tooltipRef}></svg>
          </ModalChart>
        </ChartWrapper>

        <Legend ref={legendRef} />
      </ModalContent>
      <Loading visible={isFetching} whiteBackground roundedCorners />
    </StyledModal>
  );
};

export default MetricModal;
