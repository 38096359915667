import styled, { css } from 'styled-components';

export const StyledStatusDot = styled.span<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    display: inline-block;

    width: 8px;
    height: 8px;
    transform: translateY(-2px) scale(0);

    border-radius: 50%;
    margin: 0 ${theme.spacing._050};
    background-color: ${theme.colors.surfacePositive};
    transition: ${theme.animations.transitionFast('transform')};

    ${$isVisible &&
    css`
      transform: translateY(-2px) scale(1);
    `}
  `
);
