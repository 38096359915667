import { Reducer } from 'redux';

export type ContractsMetricsReducer = Reducer<ContractsMetricsState, ContractsMetricsAction>;

export type ContractsMetricsAction =
  | {
      type: ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_START;
      payload: { metricNames: Array<ContractMetricName> };
    }
  | {
      type: ContractsMetricsActionType.CONTRACTS_METRICS_FETCH_FINISH;
      payload: { metrics: Array<ContractMetric> };
    };

export interface ContractsMetricsState {
  metrics: Partial<Record<ContractMetricName, ContractMetric>>;
  isFetching: Partial<Record<ContractMetricName, boolean>>;
}

export type ContractMetric =
  | {
      name: ContractMetricName.INCOME;
      current: ContractIncomeMetricValue;
      previous: ContractIncomeMetricValue;
    }
  | {
      name: ContractMetricName.CONTRACTS | ContractMetricName.DROPS | ContractMetricName.HIVES_IN_STOCK;
      current: ContractMetricValue;
      previous: ContractMetricValue;
    };

export interface ContractMetricValue {
  name: string;
  value: number | null;
}

export interface ContractIncomeMetricValue {
  name: string;
  value: {
    latestYear: number;
    latestYearTotalIncome: number;
  };
}

export enum ContractMetricName {
  CONTRACTS = 'contracts',
  DROPS = 'drops',
  HIVES_IN_STOCK = 'hivesInStock',
  INCOME = 'income',
}

export enum ContractsMetricsActionType {
  CONTRACTS_METRICS_FETCH_START = 'CONTRACTS_METRICS_FETCH_START',
  CONTRACTS_METRICS_FETCH_FINISH = 'CONTRACTS_METRICS_FETCH_FINISH',
}
