import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Edit } from '@components/common/Icon/presets/Edit';
import { MapControls } from '@components/common/Map';
import { PageHeaderContext } from '@components/common/PageHeader/contex';
import { TabsContext } from '@components/common/Tabs/context';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import { PolliMapMode } from '@components/pollination/PolliMap/types';

import { StyledMapToolbarButtonToggle } from './styles';

export const PolliMapControls: React.VFC = () => {
  const { t } = useTranslation();
  const { setMode, isIdle, isStatic, isReadonly, isLoading, fitMapToElements, isEmpty } = usePolliMap();
  const tabs = useContext(TabsContext);
  const header = useContext(PageHeaderContext);

  const isToolbarToggleVisible = isIdle && !isLoading;
  const recenterMap = isEmpty ? undefined : fitMapToElements;

  const expandMapToolbar = useCallback(() => {
    setMode(PolliMapMode.MANAGING);
  }, [setMode]);

  useEffect(() => {
    tabs?.setTabBarVisible(isIdle || isReadonly);
    header?.setHeaderVisible(isIdle || isReadonly);
  }, [header, isIdle, isReadonly, tabs]);

  if (isStatic) {
    return null;
  }

  return (
    <>
      <MapControls showMapTypeToggle recenterMap={recenterMap} />

      {!isReadonly && (
        <StyledMapToolbarButtonToggle
          primary
          withLeadingIcon
          onClick={expandMapToolbar}
          isVisible={isToolbarToggleVisible}
        >
          <Edit />
          {t('edit_map')}
        </StyledMapToolbarButtonToggle>
      )}
    </>
  );
};
