import styled from 'styled-components';

import { PrimaryButton } from '@components/common/Button';

export const StyledStepButtons = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 24px;
  right: ${theme.spacing._100};
`
);

export const StyledStepButton = styled(PrimaryButton).attrs({ flat: true, type: 'button' })(
  () => `
  padding: 0;
  width: 18px;
  height: 18px;
`
);

export const StyledInputWrapper = styled.div(
  ({ theme }) => `
  position: relative;
  transition: ${theme.animations.transitionFast('opacity')};
  
  ${StyledStepButtons} {
    opacity: 0;
  }
  
  &:hover ${StyledStepButtons} {
    opacity: 1;
  }
`
);
