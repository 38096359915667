import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import editIcon from '@assets/xSmall_Edit.svg';
import { Box } from '@components/common/Box';
import { Archive } from '@components/common/Icon/presets/Archive';
import { Download } from '@components/common/Icon/presets/Download';
import { Refresh } from '@components/common/Icon/presets/Refresh';
import { Menu } from '@components/common/Menu';
import { ThreeDotsMenuTrigger } from '@components/common/ThreeDotsMenuTrigger';
import { useContractModalController } from '@redux/Contract/hooks';

import { ArchiveModal } from '../PolliContractActionsModals/ArchiveModal';
import { ExportModal } from '../PolliContractActionsModals/ExportModal';
import { RenewModal } from '../PolliContractActionsModals/RenewModal';

export const ActionsMenu: React.VFC<{ contractId: number; contractName: string }> = ({ contractId, contractName }) => {
  const { t } = useTranslation();
  const { openToEdit } = useContractModalController();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  if (!contractId) return null;
  return (
    <>
      <Box marginRight_100 fit justifyContent={'flex-end'}>
        <ThreeDotsMenuTrigger id={`menu-${contractId}`} />
        <Menu
          items={[
            {
              title: t('edit_contract'),
              icon: editIcon,
              onClick: () => openToEdit(contractId),
            },
            { title: t('export'), icon: Download, onClick: () => setIsExportModalOpen(true) },
            { title: t('renew'), icon: Refresh, onClick: () => setIsRenewModalOpen(true) },
            { title: t('archive'), icon: Archive, onClick: () => setIsArchiveModalOpen(true) },
          ]}
          placement={'bottom-start'}
          target={`menu-${contractId}`}
        />
      </Box>
      {isExportModalOpen && (
        <ExportModal
          contractId={contractId}
          modalProps={{
            isOpen: isExportModalOpen,
            onRequestClose: () => setIsExportModalOpen(false),
          }}
        />
      )}

      {isRenewModalOpen && (
        <RenewModal
          contractId={contractId}
          contractName={contractName}
          modalProps={{
            isOpen: isRenewModalOpen,
            onRequestClose: () => setIsRenewModalOpen(false),
          }}
        />
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          contractId={contractId}
          contractName={contractName}
          modalProps={{
            isOpen: isArchiveModalOpen,
            onRequestClose: () => setIsArchiveModalOpen(false),
          }}
        />
      )}
    </>
  );
};
