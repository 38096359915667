import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { TabView } from '@components/common/Tabs/styles';
import { TabProps } from '@components/common/Tabs/types';
import { buildShallowEquals } from '@helpers/deprecated/shallowEquals';

import { TabInfoContext, TabsContext } from './context';

export const Tab = React.memo(({ id, path, title, suppressScrollY, preLoad, children }: TabProps) => {
  const [tabHeight, setTabHeight] = useState(0);

  const tabViewRef = useRef<HTMLDivElement>(null);
  const tabContext = useContext(TabsContext);
  const tabInfo = useMemo(() => tabContext?.tabs[id], [id, tabContext?.tabs]);

  useEffect(() => {
    tabContext?.__registerTab({ id, path, title, hasBeenVisited: !!preLoad });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, title]);

  useEffect(
    () => () => {
      tabContext?.__unregisterTab({ id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  // Updates the tab height everytime its layout changes.
  useLayoutEffect(() => {
    const tabViewElement = tabViewRef.current;
    const tabParentElement = tabViewElement?.parentElement;

    if (!tabParentElement) {
      return;
    }

    const resizeHandler = () => {
      requestAnimationFrame(() => {
        const tabTop = tabViewElement.getBoundingClientRect().top;
        const tabHeight = window.innerHeight - tabTop;
        setTabHeight(tabHeight);
      });
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(tabParentElement);

    return () => {
      observer.unobserve(tabParentElement);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabInfo?.hasBeenVisited, tabContext?.isTabBarVisible]);

  if (!tabInfo) {
    return null;
  }

  const { isActive, hasBeenVisited } = tabInfo;

  return hasBeenVisited ? (
    <TabInfoContext.Provider value={tabInfo}>
      <TabView
        ref={tabViewRef}
        column
        stretch
        $isActive={isActive}
        $isFixed={tabContext?.isFixed}
        $isTabBarVisible={!!tabContext?.isTabBarVisible}
        $suppressScrollY={suppressScrollY}
        $height={tabHeight}
      >
        {children}
      </TabView>
    </TabInfoContext.Provider>
  ) : null;
}, buildShallowEquals<TabProps>(['id', 'title', 'children']));

Tab.displayName = 'Tab';
