import { useCallback, useContext, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { TabInfoContext, TabsContext } from '@components/common/Tabs/context';
import { TabInfo, TabsContextValue } from '@components/common/Tabs/types';
import { useQueryParamState } from '@helpers/QueryParams/hooks';
import { URLUtil } from '@helpers/URL';

/**
 * Returns the current Tabs context.
 * E.g:
 *     const tabs = useTabs();
 *     tabs.goToTab('map')
 * */
export function useTabs(): TabsContextValue {
  const tabs = useContext(TabsContext);

  if (!tabs) {
    throw new Error("Can't find tabs context.");
  }

  return tabs;
}

/**
 * Helper to save the active tab to the url query params.
 * E.g.: /?tab=some-tab-key
 * */
export function useActiveTabQueryParamsState(
  tabs: Record<string, TabInfo>,
  defaultTab: string
): [string, (state: string) => void] {
  const [activeTab, setActiveTab] = useQueryParamState('tab', defaultTab);
  return [activeTab && activeTab in tabs ? activeTab : defaultTab, setActiveTab];
}

/**
 * Helper to save the active tab to the url path.
 * E.g.: /some/tab/path
 * */
export function useActiveTabURLPathState(
  tabs: Record<string, TabInfo>,
  defaultTab: string
): [string, (state: string) => void] {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const currentPathParams = useParams();

  const getTabFromCurrentPath = useCallback(() => {
    const activeTabInfo = Object.values(tabs).find(
      ({ path }) => URLUtil.buildPagePath(path ?? '', { pathParams: currentPathParams }) === currentPath
    );
    return activeTabInfo?.id || defaultTab;
  }, [currentPath, currentPathParams, defaultTab, tabs]);

  const [activeTab, internalSetActiveTab] = useState(() => getTabFromCurrentPath());

  const setActiveTab = useCallback(
    (tabId: string) => {
      const tabInfo = Object.values(tabs).find(({ id }) => id === tabId);
      if (tabInfo?.path) {
        history.push(URLUtil.buildPagePath(tabInfo.path, { pathParams: currentPathParams }));
      }
    },
    [currentPathParams, history, tabs]
  );

  useEffect(() => {
    internalSetActiveTab(getTabFromCurrentPath());
  }, [getTabFromCurrentPath, setActiveTab]);

  return [activeTab, setActiveTab];
}

/**
 * Returns info about the current tab.
 * E.g:
 *     const {title, isActive, ...etc} = useCurrentTabInfo();
 * */
export function useCurrentTabInfo(): TabInfo {
  const tab = useContext(TabInfoContext);

  if (!tab) {
    throw new Error("Can't find tabs context.");
  }

  return tab;
}

/**
 * Calls the given handler when the current tab
 * becomes active.
 * E.g:
 *     const reloadTableData = useCallback(...)
 *     useCurrentTabFocus(reloadTableData)
 * */
export function useCurrentTabFocus(handler: () => void) {
  const { isActive } = useCurrentTabInfo();

  useEffect(() => {
    isActive && handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
}
