import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';

import { Box } from '@components/common/Box';
import { BoxRef } from '@components/common/Box/types';
import { Button } from '@components/common/CTA';
import { Check } from '@components/common/Icon';
import { ArrowDown } from '@components/common/Icon/presets/ArrowDown';
import { Search } from '@components/common/Icon/presets/Search';
import { Tooltip } from '@components/common/Tooltip';
import { TooltipRef } from '@components/common/Tooltip/types';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import {
  DropdownButton,
  DropdownItemsWrapper,
  DropdownSearchIconWrapper,
  DropdownSearchInputWrapper,
} from '@components/form/inputs/Dropdown/styles';
import { DropdownProps } from '@components/form/inputs/Dropdown/types';
import { InputText } from '@components/form/inputs/InputText';
import { useTranslation } from '@hooks/useTranslation';

const SEARCH_DEBOUNCE = 200;
const MAX_RENDERED_OPTIONS = 1024; // Limit rendered options to avoid performance issues.

export const Dropdown = <V extends number | string>({
  value: propValue,
  defaultValue,
  options,
  placeholder,
  searchable,
  onChange,
  onSearchChange: propOnSearchChange,
  ...props
}: DropdownProps<V>) => {
  const t = useTranslation();
  const tooltipRef = useRef<TooltipRef>(null);
  const searchInputWrapperRef = useRef<BoxRef<'div'>>(null);

  const form = useForm({
    defaultValues: useFormDefaultValues({ dropdownSearchTerm: '' }),
    resolver: useFormSchemaResolver((schema) => ({
      dropdownSearchTerm: schema.string().optional(),
    })).resolver,
  });
  const formValues = form.watch();

  const [value, setValue] = useState<V | null>(defaultValue ?? null);
  const [searchTerm, setSearchTerm] = useState('');

  const optionsIndex = useMemo(() => {
    return new Fuse(options, { keys: ['text'], threshold: 0.4 });
  }, [options]);

  const optionsFiltered = useMemo(() => {
    const cleanSearchTerm = searchTerm.trim();
    const filteredOptions = cleanSearchTerm ? optionsIndex.search(cleanSearchTerm).map(({ item }) => item) : options;
    return filteredOptions.slice(0, MAX_RENDERED_OPTIONS);
  }, [options, optionsIndex, searchTerm]);

  const currentText = useMemo(
    () => options.find((op) => op.value === value)?.text ?? placeholder,
    [options, placeholder, value]
  );

  const onSearchChange = useMemo(
    () =>
      debounce((value) => {
        setSearchTerm(value ?? '');
        propOnSearchChange && propOnSearchChange(value ?? '');
      }, SEARCH_DEBOUNCE),
    [propOnSearchChange]
  );

  const onOptionClick = useCallback(
    (value: V) => {
      setValue(value);
      onChange && onChange(value);
      tooltipRef.current?.hide();
    },
    [onChange]
  );

  const focusSearch = useCallback(() => {
    const searchInputElement = searchInputWrapperRef.current?.querySelector('input');
    searchInputElement?.focus();
  }, []);

  useEffect(() => {
    typeof propValue !== 'undefined' && setValue(propValue);
  }, [propValue]);

  useEffect(() => {
    onSearchChange(formValues.dropdownSearchTerm);
  }, [formValues, onSearchChange]);

  return (
    <DropdownButton {...props} secondary withTrailingIcon>
      {currentText}
      <ArrowDown />
      <Tooltip
        ref={tooltipRef}
        light
        interactive
        placement={'bottom-start'}
        onShown={focusSearch}
        renderContent={() => (
          <Form form={form} column stretch paper02 shadow={'boxShadow04'}>
            {searchable && (
              <DropdownSearchInputWrapper ref={searchInputWrapperRef} relative column stretch padding_050>
                <DropdownSearchIconWrapper>
                  <Search size={24} />
                </DropdownSearchIconWrapper>
                <InputText name={'dropdownSearchTerm'} placeholder={t('search')} />
              </DropdownSearchInputWrapper>
            )}

            <DropdownItemsWrapper>
              {optionsFiltered.map((item, index) => {
                const isSelected = item.value === value;
                return (
                  <Box key={index} backgroundColor={isSelected ? 'grey02' : undefined} column stretch>
                    <Button onClick={() => onOptionClick(item.value)} withTrailingIcon>
                      <Box fit>{item.text}</Box>
                      {isSelected && <Check />}
                    </Button>
                  </Box>
                );
              })}
            </DropdownItemsWrapper>
          </Form>
        )}
      ></Tooltip>
    </DropdownButton>
  );
};
