import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

import { useGridApi } from '@components/common/AgGrid';
import { useDynamicTableDataCycle } from '@components/common/DynamicTable/hooks';
import { CardType } from '@components/yard/MetricCard/type';
import { CategoryColors, MetricConfigurations, MetricName } from '@config/GlobalMetricsConfig/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsPerformanceMetricEventType } from '@helpers/Analytics/types';
import { useDispatch } from '@helpers/Thunk/hooks';
import { URLUtil } from '@helpers/URL';
import { makeFetchYardMetricsAvailabilityThunk, makeFetchYardMetricsThunk } from '@redux/Yards/actions';
import { MetricValue } from '@redux/Yards/types';
import { useAppliedYardsFilters } from '@redux/YardsFilters/hooks';

const CARDS_RELOAD_DEBOUNCE = 180;

export function useMetricConfigGetter() {
  return useCallback(({ metricName, metricValue }: { metricName: MetricName; metricValue: MetricValue[] }) => {
    const metricConfig = MetricConfigurations[metricName];

    if (!metricValue) {
      metricValue = [];
    } else if (metricConfig.cardType == CardType.CATEGORY) {
      metricValue = metricValue.map((obj) => ({ ...obj, color: CategoryColors[obj.name as string] }));
    } else {
      const previousValue = metricValue.find((element) => element.name == 'previous');
      const currentValue = metricValue.find((element) => element.name == 'current');
      if (previousValue && currentValue) {
        metricValue = [{ value: currentValue['value'], previousValue: previousValue['value'] }];
      }
    }

    return {
      cardType: metricConfig.cardType as CardType,
      metricName: metricName,
      metricValue: metricValue,
      previousValueDelta: metricConfig.previousValueDelta,
      positiveDirection: metricConfig.positiveDirection,
      categories: metricConfig.categories,
    };
  }, []);
}

export function useMetricsLoader(options: { isEnabled: boolean }) {
  const didFirstLoad = useRef(false);
  const didEnable = useRef(options.isEnabled);
  const lastLoadRowCount = useRef(0);
  const lastLoadFiltersQuery = useRef<string | null>(null);

  const dispatch = useDispatch();
  const { gridApi } = useGridApi();
  const currentFilters = useAppliedYardsFilters();

  const updateRowCountAndFiltersQuery = useCallback(() => {
    lastLoadRowCount.current = gridApi?.getInfiniteRowCount() ?? 0;
    lastLoadFiltersQuery.current = URLUtil.buildURL({ ...currentFilters });
  }, [currentFilters, gridApi]);

  const loadMetrics = useCallback(async () => {
    if (!didEnable.current) {
      return;
    }

    await dispatch(makeFetchYardMetricsAvailabilityThunk());
    await dispatch(makeFetchYardMetricsThunk());
    didFirstLoad.current = true;

    Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD_METRICS);
    Analytics.commitPerformanceMetric(AnalyticsPerformanceMetricEventType.PERFORMANCE_METRIC_WHITEBOARD);
  }, [dispatch]);

  const reloadMetrics = useMemo(
    () =>
      debounce(async () => {
        const isGridLoading = (gridApi?.getRenderedNodes() ?? [])[0]?.data === undefined;

        if (!didFirstLoad.current || !gridApi || isGridLoading || !didEnable.current) {
          return;
        }

        const currentRowCount = gridApi.getInfiniteRowCount() ?? 0;
        const currentFilterQuery = URLUtil.buildURL({ ...currentFilters });
        const didRowsChange = lastLoadRowCount.current !== currentRowCount;
        const didFiltersChange = lastLoadFiltersQuery.current !== currentFilterQuery;

        const mayUpdate = didFiltersChange || didRowsChange;

        if (mayUpdate) {
          await dispatch(makeFetchYardMetricsThunk());
          updateRowCountAndFiltersQuery();
        }
      }, CARDS_RELOAD_DEBOUNCE),
    [currentFilters, dispatch, gridApi, updateRowCountAndFiltersQuery]
  );

  useDynamicTableDataCycle({
    onDataPreLoad: loadMetrics,
    onDataServerLoad: updateRowCountAndFiltersQuery,
    onDataServerReload: reloadMetrics,
  });

  useEffect(() => {
    if (options.isEnabled && !didEnable.current) {
      didEnable.current = true;
      loadMetrics().then(null);
    }
  }, [options.isEnabled, loadMetrics]);
}
