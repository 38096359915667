import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { makeAuthFetchUserThunk } from '@redux/Auth/actions';
import { PreferencesAction, PreferencesActionType } from '@redux/Preferences/types';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

export const makePreferencesPatchUserStartAction = (): PreferencesAction => ({
  type: PreferencesActionType.PREFERENCES_PATCH_USER_START,
});

export const makePreferencesPatchUserFinishAction = (preferences: BeeUserPreferences | null): PreferencesAction => ({
  type: PreferencesActionType.PREFERENCES_PATCH_USER_FINISH,
  payload: { preferences },
});

export const makePreferencesPatchUserThunk = Thunk.createTakeFirst((preferences: Partial<BeeUserPreferences>) => {
  return async (dispatch) => {
    dispatch(makePreferencesPatchUserStartAction());

    const response = await Api.patch(ENDPOINTS.currentUserPreferences, parseUserPreferencesToAPI(preferences));
    let patchedPreferences: BeeUserPreferences | null = null;

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
    } else {
      patchedPreferences = parseUserPreferencesFromAPI(await response.json());
      dispatch(makeShowSnackbarAction('snack_account_changed_msg'));

      dispatch(makeAuthFetchUserThunk());
    }

    dispatch(makePreferencesPatchUserFinishAction(patchedPreferences));
  };
});

export const makePreferencesPatchMembershipStartAction = (): PreferencesAction => ({
  type: PreferencesActionType.PREFERENCES_PATCH_MEMBERSHIP_START,
});

export const makePreferencesPatchMembershipFinishAction = (
  preferences: BeeMembershipPreferences | null
): PreferencesAction => ({
  type: PreferencesActionType.PREFERENCES_PATCH_MEMBERSHIP_FINISH,
  payload: { preferences },
});

export const makePreferencesPatchMembershipThunk = Thunk.createTakeFirst(
  (preferences: Partial<BeeMembershipPreferences>) => {
    return async (dispatch) => {
      dispatch(makePreferencesPatchMembershipStartAction());

      const response = await Api.patch(
        ENDPOINTS.currentMembershipPreferences,
        parseMembershipPreferencesToAPI(preferences)
      );
      let patchedPreferences: BeeMembershipPreferences | null = null;

      if (response.error) {
        dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      } else {
        patchedPreferences = parseMembershipPreferencesFromAPI(await response.json());
        dispatch(makeShowSnackbarAction('snack_account_changed_msg'));

        dispatch(makeAuthFetchUserThunk());
      }

      dispatch(makePreferencesPatchMembershipFinishAction(patchedPreferences));
    };
  }
);

function parseUserPreferencesToAPI(preferences: Partial<BeeUserPreferences>) {
  return CaseAdapter.objectToSnakeCase({ preferences });
}

function parseUserPreferencesFromAPI(data: any): BeeUserPreferences {
  return CaseAdapter.objectToCamelCase(data.preferences);
}

function parseMembershipPreferencesToAPI(preferences: Partial<BeeMembershipPreferences>) {
  return CaseAdapter.objectToSnakeCase({ preferences });
}

function parseMembershipPreferencesFromAPI(data: any): BeeMembershipPreferences {
  return CaseAdapter.objectToCamelCase(data.preferences);
}
