import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Marker, Polygon } from '@components/common/Map';
import { DEF_PANE_WIDTH } from '@components/common/Map/constants';
import { useMap } from '@components/common/Map/hooks';
import { Coordinate, MapProps, Path } from '@components/common/Map/types';
import { MapUtil } from '@components/common/Map/util';
import { usePolliMap } from '@components/pollination/PolliMap/hooks';
import {
  AnyInstance,
  PolliMapMode,
  TransientBlock,
  TransientDrop,
  TransientPoi,
  UncommitedInstance,
} from '@components/pollination/PolliMap/types';
import { PolliMapUtil } from '@components/pollination/PolliMap/util';
import { PolliMapPois } from '@components/pollination/PolliMapSurface/PolliMapPois';
import { createDropName, extractDropNumber } from '@helpers/Drop';
import { Geometry } from '@helpers/Geometry';
import { HexID } from '@helpers/HexID';

import { PolliMapBlocks } from './PolliMapBlocks';
import { PolliMapDrops } from './PolliMapDrops';
import { StyledMap, StyledMapWrapper } from './styles';

const DEF_DROPS_Z_INDEX = 3;
const DEF_POIS_Z_INDEX = 2;
const DEF_BLOCKS_Z_INDEX = 1;

export const PolliMapSurface: React.FC<Pick<MapProps, 'onTilesLoaded'>> = ({ onTilesLoaded, children }) => {
  const {
    getKey,
    setMode,
    isStatic,
    isManagingAnElement,
    isManagingDrops,
    isManagingBlocks,
    isManagingPois,
    isDraggingElements,
    selectedDrop,
    selectedBlock,
    selectedPoi,
    dropsList,
    poisList,
    selectDrop,
    selectBlock,
    selectPoi,
    cancelAnyElementChange,
    clearAnyElementSelection,
  } = usePolliMap();

  const theme = useTheme();
  const { t } = useTranslation();
  const { instance: mapInstance } = useMap();
  const mapDivRef = useRef<HTMLDivElement>(null);

  const dropTemplate = useRef<AnyInstance<TransientDrop> | null>(null);
  const blockTemplate = useRef<AnyInstance<TransientBlock> | null>(null);
  const canDrawBlocks = isManagingBlocks;
  const canPlaceDrops = isManagingDrops && !selectedDrop;
  const canPlacePois = isManagingPois && !selectedPoi;

  const selectedDropKey = selectedDrop ? getKey(selectedDrop) : null;
  const selectedBlockKey = selectedBlock ? getKey(selectedBlock) : null;
  const selectedPoiKey = selectedPoi ? getKey(selectedPoi) : null;

  // Adjust map to always show the selected drop.
  const panToContain = useCallback(
    (coordinate: Coordinate) => {
      const dropPixelPos = mapInstance?.coordinateToPixel(coordinate);
      const mapRect = mapDivRef.current?.getBoundingClientRect();

      if (dropPixelPos && mapRect) {
        const padding = theme.primitives.px._200;

        const gapXLeft = Math.max(0, DEF_PANE_WIDTH + padding - dropPixelPos.x);
        const gapXRight = Math.min(0, mapRect.width - padding - dropPixelPos.x);
        const gapYTop = Math.max(0, padding - dropPixelPos.y);
        const gapYBottom = Math.min(0, mapRect.height - padding - dropPixelPos.y);
        const isOutOfScreen = Math.abs(gapXLeft - gapXRight) + Math.abs(gapYBottom - gapYTop) > 0;

        if (isOutOfScreen) {
          mapInstance?.panTo(coordinate);
          mapInstance?.panBy({ x: -DEF_PANE_WIDTH / 2, y: 0 });
        }
      }
    },
    [mapInstance, theme.primitives.px._200]
  );

  const lastDropName = useMemo(() => {
    const allDrops: Array<AnyInstance<TransientDrop>> = [...dropsList];
    if (!allDrops.length) return null;

    const allDropsSorted = allDrops.sort((a, b) => extractDropNumber(b.name) - extractDropNumber(a.name));
    const lastDrop = allDropsSorted[0];
    return lastDrop.name;
  }, [dropsList]);

  const handleAddDrop = useCallback(
    (coordinate: Coordinate) => {
      const drop: UncommitedInstance<TransientDrop> = {
        tempHexID: HexID.generateHexID(),
        name: createDropName(lastDropName),
        targetHiveNumber: dropTemplate.current?.targetHiveNumber ?? 0,
        nbHives: 0,
        isActive: false,
        position: coordinate,
      };
      selectDrop(drop);
      setMode(PolliMapMode.MANAGING_A_DROP);
    },
    [lastDropName, selectDrop, setMode]
  );

  const handleAddBlock = useCallback(
    (path) => {
      if (path.length) {
        selectBlock({
          name: blockTemplate.current?.name ?? null,
          area: Geometry.calculateArea(path),
          path,
          tempHexID: HexID.generateHexID(),
        });
        setMode(PolliMapMode.MANAGING_A_BLOCK);
      }
    },
    [selectBlock, setMode]
  );

  const handleAddPoi = useCallback(
    (coordinate: Coordinate) => {
      const poiNumber = PolliMapUtil.generateNextPoiNumber('access', poisList);
      const poiCategory = t('pollination_poi_category_access');

      const poi: UncommitedInstance<TransientPoi> = {
        tempHexID: HexID.generateHexID(),
        category: 'access',
        name: `${poiCategory} ${poiNumber}`,
        location: coordinate,
        descriptionEnglish: null,
        descriptionSpanish: null,
      };
      selectPoi(poi);
      setMode(PolliMapMode.MANAGING_A_POI);
    },
    [poisList, selectPoi, setMode, t]
  );

  const handleMapClick = useCallback(() => {
    if (isManagingAnElement) {
      cancelAnyElementChange(true);
    } else {
      clearAnyElementSelection();
    }
  }, [cancelAnyElementChange, clearAnyElementSelection, isManagingAnElement]);

  const handleDropClick = useCallback(
    (drop: AnyInstance<TransientDrop>) => {
      selectDrop({ ...drop });
    },
    [selectDrop]
  );

  const handleBlockClick = useCallback(
    (block: AnyInstance<TransientBlock>) => {
      selectBlock({ ...block });
    },
    [selectBlock]
  );

  const handlePoiClick = useCallback(
    (poi: AnyInstance<TransientPoi>) => {
      selectPoi({ ...poi });
    },
    [selectPoi]
  );

  const handleDropPositionChange = useCallback(
    (drop: AnyInstance<TransientDrop>, position: Coordinate) => {
      if (isManagingDrops || isDraggingElements) {
        panToContain(position);
        selectDrop({ ...drop, position });
        setMode(PolliMapMode.MANAGING_A_DROP);
      }
    },
    [panToContain, isDraggingElements, isManagingDrops, selectDrop, setMode]
  );

  const handleBlockPathChange = useCallback(
    (block: AnyInstance<TransientBlock>, path: Path | null) => {
      if (selectedBlock && path) {
        panToContain(MapUtil.getPathCenter(path));
        const area = Geometry.calculateArea(path);
        selectBlock({ ...block, path, area });
      }
    },
    [panToContain, selectBlock, selectedBlock]
  );

  const handlePoiPositionChange = useCallback(
    (poi: AnyInstance<TransientPoi>, location: Coordinate) => {
      if (isManagingPois || isDraggingElements) {
        panToContain(location);
        selectPoi({ ...poi, location });
        setMode(PolliMapMode.MANAGING_A_POI);
      }
    },
    [panToContain, isDraggingElements, isManagingPois, selectPoi, setMode]
  );

  const handleDraggingChange = useCallback(
    (dragging: boolean, targetMode: PolliMapMode) => {
      dragging ? setMode(PolliMapMode.DRAGGING_ELEMENTS) : setMode(targetMode);
    },
    [setMode]
  );

  useEffect(() => {
    if (selectedDrop) {
      dropTemplate.current = selectedDrop;
    }
  }, [selectedDrop]);

  useEffect(() => {
    if (selectedBlock) {
      blockTemplate.current = selectedBlock;
    }
  }, [selectedBlock]);

  useEffect(() => {
    let coordinate: Coordinate | null = null;
    if (selectedDrop) {
      coordinate = selectedDrop.position;
    } else if (selectedBlock) {
      coordinate = MapUtil.getPathCenter(selectedBlock.path);
    } else if (selectedPoi) {
      coordinate = selectedPoi.location;
    }
    coordinate && panToContain(coordinate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropKey, selectedBlockKey, selectedPoiKey]);

  return (
    <StyledMapWrapper ref={mapDivRef}>
      <StyledMap $static={isStatic} onClick={handleMapClick} onTilesLoaded={onTilesLoaded}>
        {children}
        <PolliMapDrops
          zIndex={DEF_DROPS_Z_INDEX}
          onDropClick={handleDropClick}
          onDropPositionChange={handleDropPositionChange}
          onDropDraggingChange={(_, dragging) => handleDraggingChange(dragging, PolliMapMode.MANAGING_A_DROP)}
        />
        <PolliMapBlocks
          zIndex={DEF_BLOCKS_Z_INDEX}
          onBlockClick={handleBlockClick}
          onBlockDraggingChange={(_, dragging) => handleDraggingChange(dragging, PolliMapMode.MANAGING_A_BLOCK)}
          onBlockPathChange={handleBlockPathChange}
        />
        <PolliMapPois
          zIndex={DEF_POIS_Z_INDEX}
          onPoiClick={handlePoiClick}
          onPoiPositionChange={handlePoiPositionChange}
          onPoiDraggingChange={(_, dragging) => handleDraggingChange(dragging, PolliMapMode.MANAGING_A_POI)}
        />
        {canPlaceDrops && <Marker position={null} onFirstPlacementComplete={handleAddDrop} />}
        {canPlacePois && <Marker position={null} onFirstPlacementComplete={handleAddPoi} />}
        {canDrawBlocks && <Polygon path={null} onDrawingComplete={handleAddBlock} />}
      </StyledMap>
    </StyledMapWrapper>
  );
};
