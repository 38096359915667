import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { ArrowUp } from '@components/common/Icon/presets/ArrowUp';
import { Loading } from '@components/common/Loading';
import { SwipeArea } from '@components/common/SwipeArea';
import { SwipeEvent } from '@components/common/SwipeArea/types';
import { Text } from '@components/common/Text';
import MetricCard from '@components/yard/MetricCard';
import { useMetricConfigGetter, useMetricsLoader } from '@components/yard/MetricCarrousel/hooks';
import MetricModal from '@components/yard/MetricModal';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useSessionStorageState } from '@helpers/Storage/hooks';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { makeOpenMetricModal } from '@redux/Yards/actions';
import { MetricValue } from '@redux/Yards/types';

import { CardType } from '../MetricCard/type';

import {
  ArrowButton,
  CardsContainer,
  CardWrapper,
  CarrouselContainer,
  EmptyView,
  StyledToggleMetricsButton,
} from './style';

const MIN_CARD_WIDTH = 230;

const MetricCarrousel: React.VFC = () => {
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isFetching, cards } = useSelector((state) => state.yardsReducer.metrics);

  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [initiallyVisibleCardsCount, setInitiallyVisibleCardCount] = useState(0);
  const [visibleCardsCount, setVisibleCardCount] = useState(0);
  const [leftmostCardIndex, setLeftmostCardIndex] = useState(0);
  const [metricsState, setMetricsState] = useSessionStorageState('metrics-storage-state', { isVisible: true });

  const leftmostCardMaxIndex = Math.max(cards.length - visibleCardsCount, 0);
  const canSlideLeft = leftmostCardIndex > 0;
  const canSlideRight = leftmostCardIndex < leftmostCardMaxIndex;

  useMetricsLoader({ isEnabled: metricsState.isVisible });

  const getMetricConfig = useMetricConfigGetter();
  const openTrendModal = useCallback(
    (
      metricName: string,
      metricValue: MetricValue[],
      positiveDirection: boolean,
      cardType: CardType,
      categories: string[] | null
    ) => {
      Analytics.sendEvent({ event: AnalyticsEventType.METRIC_CHART_VIEW, eventData: { metric: metricName } });
      dispatch(makeOpenMetricModal(metricName, metricValue, positiveDirection, cardType, categories));
    },
    [dispatch]
  );

  const toggleMetricsVisibility = useCallback(() => {
    setMetricsState((curr) => ({ ...curr, isVisible: !curr.isVisible }));
  }, [setMetricsState]);

  useLayoutEffect(() => {
    const handleCardWidth = () => {
      requestAnimationFrame(() => {
        if (cardsContainerRef.current) {
          const { width } = cardsContainerRef.current.getBoundingClientRect();
          const visibleCardCount = Math.min(Math.floor(width / MIN_CARD_WIDTH), cards.length);

          if (visibleCardCount > 0) {
            const cardsWidth = Math.floor(width / visibleCardCount);
            setVisibleCardCount(visibleCardCount);
            initiallyVisibleCardsCount === 0 && setInitiallyVisibleCardCount(visibleCardCount);
            setCardWidth(cardsWidth);
          }
        }
      });
    };

    const cardsContainerElement = cardsContainerRef.current;
    if (cardsContainerElement) {
      const resizeObserver = new ResizeObserver(handleCardWidth);
      resizeObserver.observe(cardsContainerElement);
      return () => resizeObserver.unobserve(cardsContainerElement);
    }
  }, [cards.length, initiallyVisibleCardsCount]);

  const slideLeft = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.max(curr - 1, 0));
  }, []);

  const slideRight = useCallback(() => {
    setLeftmostCardIndex((curr) => Math.min(curr + 1, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  const onSwipeMove = useCallback(
    (event: SwipeEvent) => {
      const cardsContainer = cardsContainerRef.current;
      if (!cardsContainer) {
        return 0;
      }
      Array.from<HTMLElement>(cardsContainer.querySelectorAll(CardWrapper)).forEach((card, index) => {
        card.style.left = `${(index - leftmostCardIndex) * cardWidth + event.deltaX}px`;
        card.style.transition = theme.animations.transitionMedium('opacity');
        card.style.opacity = '1.0';
      });
    },
    [cardWidth, leftmostCardIndex, theme.animations]
  );

  const onSwipeEnd = useCallback(
    (event: SwipeEvent) => {
      const cardsContainer = cardsContainerRef.current;
      if (!cardsContainer) {
        return 0;
      }
      const offset = Math.round(event.deltaX / cardWidth);
      const nextLeftmostCardIndex = Math.min(Math.max(0, leftmostCardIndex - offset), leftmostCardMaxIndex);
      Array.from<HTMLElement>(cardsContainer.querySelectorAll(CardWrapper)).forEach((card, index) => {
        const isCardHidden = index < nextLeftmostCardIndex || index >= nextLeftmostCardIndex + visibleCardsCount;

        card.style.left = `${(index - nextLeftmostCardIndex) * cardWidth}px`;
        card.style.transition = theme.animations.transitionMedium('left', 'opacity');
        card.style.opacity = isCardHidden ? '0.0' : '1.0';
      });
      setLeftmostCardIndex(nextLeftmostCardIndex);
    },
    [cardWidth, leftmostCardIndex, leftmostCardMaxIndex, theme.animations, visibleCardsCount]
  );

  useEffect(() => {
    setLeftmostCardIndex((curr) => Math.min(curr, leftmostCardMaxIndex));
  }, [leftmostCardMaxIndex]);

  const isCardHidden = useCallback(
    (cardIndex: number) => {
      return cardIndex < leftmostCardIndex || cardIndex >= leftmostCardIndex + visibleCardsCount;
    },
    [leftmostCardIndex, visibleCardsCount]
  );

  const isCalculatingCardsWidth = cardWidth === 0;
  const isLoading = isFetching || isCalculatingCardsWidth;
  const isEmpty = cards.length === 0;

  return (
    <SwipeArea fit column stretch onSwipeMove={onSwipeMove} onSwipeEnd={onSwipeEnd}>
      <CarrouselContainer $isVisible={metricsState.isVisible}>
        <ArrowButton onClick={slideLeft} disabled={!canSlideLeft}>
          <ArrowLeft size={18} />
        </ArrowButton>
        <CardsContainer ref={cardsContainerRef}>
          {isLoading ? (
            <Loading />
          ) : isEmpty ? (
            <EmptyView fit center>
              <Text typography={'SmallParagraph'}>{t('no_result')}</Text>
            </EmptyView>
          ) : (
            cards.map((card, index) => (
              <CardWrapper
                key={card.metricName}
                $index={index - leftmostCardIndex}
                $hidden={isCardHidden(index)}
                $animate={index < initiallyVisibleCardsCount}
                style={{
                  left: (index - leftmostCardIndex) * cardWidth,
                  width: cardWidth,
                  opacity: isCardHidden(index) ? '0.0' : '1.0',
                }}
              >
                <MetricCard
                  onClick={() =>
                    !card.isFetching
                      ? openTrendModal(
                          card.metricName,
                          getMetricConfig({ ...card }).metricValue,
                          getMetricConfig({ ...card }).positiveDirection,
                          getMetricConfig({ ...card }).cardType,
                          getMetricConfig({ ...card }).categories
                        )
                      : undefined
                  }
                  {...card}
                  {...getMetricConfig({ ...card })}
                />
              </CardWrapper>
            ))
          )}
        </CardsContainer>
        <ArrowButton onClick={slideRight} disabled={!canSlideRight}>
          <ArrowRight size={18} />
        </ArrowButton>
        <MetricModal />
      </CarrouselContainer>
      <StyledToggleMetricsButton $areMetricsVisible={metricsState.isVisible} onClick={toggleMetricsVisibility}>
        <Box alignItems={'center'}>
          <Text typography={'CaptionSmall'}>{metricsState.isVisible ? t('hide_metrics') : t('show_metrics')}</Text>{' '}
          <ArrowUp size={16} />
        </Box>
      </StyledToggleMetricsButton>
    </SwipeArea>
  );
};

export default MetricCarrousel;
