import React from 'react';
import styled, { ColorGlobal, css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Text } from '@components/common/Text';

const StyledEmptyView = styled(Box)<{ $visible: boolean; $backgroundColor?: ColorGlobal }>(
  ({ theme, $visible, $backgroundColor = 'surfaceSecondary' }) => css`
    position: absolute;
    inset: 0;
    background-color: ${theme.colors[$backgroundColor]};
    transition: ${theme.animations.transitionLong('opacity')} 400ms;
    z-index: ${theme.getZIndexOf('tableOverlay')};
    pointer-events: ${$visible ? 'auto' : 'none'};
    opacity: ${$visible ? 1 : 0};
    visibility: ${$visible ? 'visible' : 'hidden'};

    button {
      pointer-events: ${$visible ? 'auto' : 'none'};
    }
  `
);

const StyledEmptyCardView = styled(Box).attrs({
  gap_050: true,
  column: true,
})(
  ({ theme }) => css`
    padding: 40px;
    background-color: ${theme.primitives.colors.white};
    border-radius: ${theme.shape.paperRadius02}px;
  `
);
export const DynamicTableEmptyHeader = styled(Text).attrs({ typography: 'Heading2', weight: '700', align: 'center' })``;
export const DynamicTableEmptyText = styled(Text).attrs({ typography: 'SmallParagraph', align: 'center' })``;

export const DynamicTableEmpty: React.FC<{ visible: boolean; backgroundColor?: ColorGlobal }> = ({
  visible,
  children,
  backgroundColor,
}) => {
  return (
    <StyledEmptyView center $visible={visible} $backgroundColor={backgroundColor}>
      <StyledEmptyCardView>{children}</StyledEmptyCardView>
    </StyledEmptyView>
  );
};
