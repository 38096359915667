import { RefObject, useCallback, useLayoutEffect, useRef } from 'react';

export type HTMLInteractiveElement = HTMLButtonElement | HTMLInputElement | HTMLAnchorElement;
export const INTERACTIVE_ELEMENTS = ['button', 'a', 'input'];
export const INTERACTIVE_OMIT_ATTRIBUTE = 'data-omit-keyboard-navigation';

export function useKeyboardNavigation(options: {
  elements: Array<RefObject<HTMLElement> | null>;
  onEscape?: (e: any) => void;
}) {
  const index = useRef(0);
  const unbinds = useRef<Array<() => void>>([]);
  const { elements, onEscape } = options;

  const getInteractiveElements = useCallback(() => {
    const nonNullElements = elements.map((ref) => ref?.current).filter(Boolean) as Array<HTMLElement>;
    return nonNullElements.flatMap((element) => [
      ...Array.from(
        element.querySelectorAll(
          INTERACTIVE_ELEMENTS.map((tag) => `${tag}:not(:disabled, [${INTERACTIVE_OMIT_ATTRIBUTE}])`).join(', ')
        )
      ),
    ]) as Array<HTMLInteractiveElement>;
  }, [elements]);

  const goToIndex = useCallback(
    (newIndex: number) => {
      const elements = getInteractiveElements();
      index.current = Math.max(0, Math.min(elements.length, newIndex));
      elements[index.current]?.focus();
    },
    [getInteractiveElements]
  );

  const goToNextIndex = useCallback(() => {
    const elements = getInteractiveElements();
    goToIndex((index.current + 1) % elements.length);
  }, [getInteractiveElements, goToIndex]);

  const goToPreviousIndex = useCallback(() => {
    const elements = getInteractiveElements();
    goToIndex((index.current + (elements.length - 1)) % elements.length);
  }, [getInteractiveElements, goToIndex]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          goToNextIndex();
          return false;
        case 'ArrowUp':
          e.preventDefault();
          goToPreviousIndex();
          return false;
        case 'Escape':
          getInteractiveElements()[index.current]?.blur();
          onEscape && onEscape(e);
          break;
      }
    },
    [getInteractiveElements, goToNextIndex, goToPreviousIndex, onEscape]
  );

  const clearEvents = useCallback(() => {
    unbinds.current.forEach((u) => u());
    unbinds.current = [];
  }, []);

  const updateEvents = useCallback(() => {
    clearEvents();

    const elements = getInteractiveElements();

    const keyHandler = (event: Event) => onKeyDown(event as KeyboardEvent);
    const focusHandler = (event: Event) => {
      const elements = getInteractiveElements();
      const target = elements.find((el) => el.contains(event.target as HTMLElement)) as HTMLInteractiveElement;
      const elementIndex = elements.indexOf(target);
      if (elementIndex !== index.current) {
        goToIndex(elementIndex);
      }
    };

    elements.forEach((el) => {
      el.addEventListener('keydown', keyHandler);
      el.addEventListener('focusin', focusHandler);
      el.addEventListener('click', focusHandler);
      unbinds.current.push(() => {
        el.removeEventListener('keydown', keyHandler);
        el.removeEventListener('focusin', focusHandler);
        el.removeEventListener('click', focusHandler);
      });
    });
  }, [clearEvents, getInteractiveElements, goToIndex, onKeyDown]);

  useLayoutEffect(() => {
    updateEvents();
    return () => clearEvents();
  }, [clearEvents, updateEvents]);

  return { goToIndex, goToNextIndex, goToPreviousIndex, updateEvents };
}
