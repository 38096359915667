import styled, { css } from 'styled-components';

export const Chip = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing._025} ${theme.spacing._050};
    height: 1.5rem;
    border-radius: ${theme.shape.paperRadius01}px;
    font-size: 12px;
  `
);

export const ChipIcon = styled.div`
  margin-right: 0.5rem;
`;

export const ChipIntroduced = styled(Chip)(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  background-color: ${theme.primitives.colors.yellow01};
`
);

export const ChipQueenRight = styled(Chip)(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  background-color: ${theme.primitives.colors.green01};
`
);

export const ChipQueenLess = styled(Chip)(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  background-color: ${theme.primitives.colors.red01};
`
);

export const ChipDeadOut = styled(Chip)(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  background-color: ${theme.primitives.colors.grey04};
`
);

export const ChipRetired = styled(Chip)(
  ({ theme }) => `
  color: ${theme.primitives.colors.grey08};
  background-color: ${theme.primitives.colors.grey04};
`
);
