import { ColDef } from 'ag-grid-community';

import { ColDefWithMetadata } from '@components/common/DynamicTable/types';

export const DEF_COLUMN_MIN_WIDTH = 132;
export const DEF_ROW_HEIGHT = 48;
export const DEF_HEADER_HEIGHT = 56;
export const DEF_HEADER_GROUP_HEIGHT = 42;
export const DEF_PAGINATION_SIZE = 20;
export const DEF_CACHE_BLOCK_SIZE = 20;
export const DEF_INITIAL_ROW_COUNT = 20;
export const DEF_CACHE_BLOCK_COUNT = 1;
export const DEF_UPDATE_UPDATE_DEBOUNCE = 40;
export const DEF_MAX_CONCURRENT_REQUESTS = -1;
export const DEF_PAGE_PARAM_NAME = 'page';

export const DEF_COLUMNS_LOADING_BATCH_SIZE = 5;
export const DEF_COLUMNS_LOADING_THREAD_COUNT = 4;

export const DEF_FALLBACK_COLUMN_TYPE = 'default';

/**
 *
 * Function to generate the metadata that should be the same for all default static columns
 * Needed because if the metadata is wrong then the column won't appear
 */
export function generateDefaultStaticColDef(data: Omit<ColDef, 'field'> & { field: string }): ColDefWithMetadata<any> {
  return {
    ...data,
    metadata: {
      groupId: data.field,
      groupName: data.field,
      activeView: 'default',
      column: {
        key: data.field,
        type: 'default',
        views: {
          default: {
            isDefault: true,
            key: 'default',
            sortableKey: null,
          },
        },
      },
    },
  };
}
