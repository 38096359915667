import React, { useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Fuse from 'fuse.js';

import { Box } from '@components/common/Box';
import { BoxProps, BoxRef } from '@components/common/Box/types';
import { Check } from '@components/common/Icon';
import { ArrowRight } from '@components/common/Icon/presets/ArrowRight';
import { Search } from '@components/common/Icon/presets/Search';
import { Loading } from '@components/common/Loading';
import { Text } from '@components/common/Text';
import { OperationIcon } from '@components/operation/OperationIcon';
import APP from '@config/constants';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { DOM } from '@helpers/DOM';
import { Globals } from '@helpers/Globals';
import { useGlobalProperty } from '@helpers/Globals/hooks';
import { QueryParams } from '@helpers/QueryParams';
import { Sorting } from '@helpers/Sorting';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';

import {
  StyledOperationEmptyMessageWrapper,
  StyledOperationItem,
  StyledOperationSearch,
  StyledOperationSelector,
  StyledOperationSelectorItemsWrapper,
  StyledStyledOperationItemWrapper,
} from './styles';

type OperationSelectorProps = BoxProps<'div'>;
export interface OperationSelectorRef {
  focusSearch: () => void;
  clearSearch: () => void;
}

export const OperationSelector = React.forwardRef<OperationSelectorRef, OperationSelectorProps>(
  function OperationSelector({ ...boxProps }, ref) {
    const t = useTranslation();
    const history = useHistory();
    const searchBoxRef = useRef<BoxRef<'div'>>(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearchScrollOffset, setHasSearchScrollOffset] = useState(false);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const isFetchingOperation = useSelector((state) => state.operationReducer.isFetching);
    const isFetchingUser = useSelector((state) => state.authReducer.isFetchingUser);
    const isFetching = isFetchingOperation || isFetchingUser;

    const operations = useSelector(
      (state) => state.authReducer.user?.memberships.map(({ operation }) => operation) ?? []
    );
    const selectedOperationId = useGlobalProperty('operationId');
    const showSearch = operations.length > 4;
    const hasSearch = !!searchTerm.trim();

    const sendAnalyticsEvent = useCallback(() => {
      const isFromAuthFlow = history.location.pathname === APP.routes.selectOperation;
      Analytics.sendEvent({
        event: AnalyticsEventType.OPERATION_SWITCH,
        eventData: {
          origin: isFromAuthFlow ? 'auth' : 'menu',
        },
      });
    }, [history.location.pathname]);

    const selectOperation = useCallback(
      (operationId) => {
        // Erase 'switching' param in case it's present.
        const queryParams = QueryParams.getCurrentQueryParams();
        delete queryParams.switching;

        // If re-selecting the same operation,
        // just navigate back to home.
        if (Globals.operationId === operationId) {
          history.replace(URLUtil.buildPagePath(history.location.pathname, { queryParams }));
        } else {
          QueryParams.setCurrentQueryParams(queryParams);
          Globals.operationId = operationId;
          sendAnalyticsEvent();
        }
      },
      [sendAnalyticsEvent, history]
    );

    const sortedOperations = useMemo(() => {
      return [...Sorting.sortAlphanumerically(operations, 'name')];
    }, [operations]);

    const searchScores = useMemo(() => {
      if (hasSearch) {
        return new Fuse(sortedOperations, {
          keys: ['name'],
          threshold: 0.2,
          ignoreLocation: true,
          includeScore: true,
        })
          .search(searchTerm.trim())
          .reduce((acc, { item, score }) => ({ ...acc, [item.id]: score }), {} as Record<number, number | undefined>);
      }
      return null;
    }, [hasSearch, searchTerm, sortedOperations]);

    const showEmptyMessage = !!searchScores && Object.values(searchScores).length === 0;

    useImperativeHandle(ref, () => ({
      focusSearch: () => searchInputRef.current?.focus(),
      clearSearch: () => setSearchTerm(''),
    }));

    useLayoutEffect(() => {
      const scrollElement = DOM.getNearestVerticallyScrollableParent(searchBoxRef.current);
      if (!scrollElement) {
        return;
      }

      const handler = () => {
        const searchElement = searchBoxRef.current;
        const searchElementParent = searchElement?.parentElement;
        if (searchElement && searchElementParent) {
          const offset = searchElement.getBoundingClientRect().top - searchElementParent.getBoundingClientRect().top;
          setHasSearchScrollOffset(offset > 0);
        }
      };
      scrollElement.addEventListener('scroll', handler);
      return () => scrollElement.removeEventListener('scroll', handler);
    }, [showSearch]);

    return (
      <Box column stretch {...boxProps}>
        <StyledOperationSelector column stretch marginTop_100={!showSearch} animationDuration={'long'}>
          {showSearch && (
            <StyledOperationSearch ref={searchBoxRef} $hasSearchScrollOffset={hasSearchScrollOffset}>
              <Box fit alignItems={'center'}>
                <Search />
                <input
                  ref={searchInputRef}
                  type={'text'}
                  placeholder={t('select_operation_search_placeholder')}
                  value={searchTerm}
                  onChange={({ target: { value } }) => setSearchTerm(value)}
                />
              </Box>
            </StyledOperationSearch>
          )}

          <StyledOperationSelectorItemsWrapper column stretch>
            {sortedOperations.map(({ id, name, color, hiveCount }, index) => (
              <StyledOperationItem
                key={id + '-' + index}
                onClick={() => selectOperation(id)}
                disabled={isFetching}
                $isSelected={isFetching && selectedOperationId === id}
                $visible={!searchScores || !!searchScores[id]}
              >
                <Box fit gap_050 paddingHorizontal_100 paddingVertical_050 alignItems={'center'}>
                  <OperationIcon operation={{ color, name }} />
                  <StyledStyledOperationItemWrapper fit column alignItems={'flex-start'}>
                    <Text typography={'Heading3'} weight={'600'}>
                      {name}
                    </Text>
                    <Text typography={'CaptionSmall'} color={'contentTertiary'}>
                      {maybePluralize(hiveCount, 'hive', t)}
                    </Text>
                  </StyledStyledOperationItemWrapper>
                  {!selectedOperationId ? <ArrowRight /> : selectedOperationId === id ? <Check /> : null}
                </Box>

                <Loading size={'24px'} visible={isFetching && selectedOperationId === id} />
              </StyledOperationItem>
            ))}

            <StyledOperationEmptyMessageWrapper paddingHorizontal_075 paddingVertical_050 $visible={showEmptyMessage}>
              <Text typography={'SmallParagraph'} color={'contentTertiary'} weight={'600'}>
                {t('no_results_simple')}
              </Text>
            </StyledOperationEmptyMessageWrapper>
          </StyledOperationSelectorItemsWrapper>
        </StyledOperationSelector>
      </Box>
    );
  }
);
