import styled from 'styled-components';

export const ToggleInput = styled.input(
  ({ theme }) => `
    cursor: pointer;
    appearance: none;
    width: 40px;
    height: 20px;
    background-color: ${theme.colors.surfaceDisabled};
    border-radius: 1000px;
    padding: 2px;

    :checked {
      background-color: ${theme.colors.surfaceAccent};
    }

    ::before {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      background-color: white;
      border-radius: 100%;
      cursor: pointer;
      transition: ${theme.animations.transitionFast('all')};
    }

    :checked::before {
      margin-left: 20px;
    }
  `
);

export const ToggleHiddenLabel = styled.label`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
