import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { useGetScreenWidth } from '@hooks/useGetScreenWidth';

import { LayoutContext, LayoutContextValue } from './context';

export const LayoutProvider: React.FC = ({ children }) => {
  const location = useLocation();

  const { isTabletOrSmaller } = useGetScreenWidth();
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [isSideNavExpanded, setIsSideNavExpanded] = useState<boolean>(!isTabletOrSmaller);

  const collapseSearch = useCallback(() => {
    if (isSearchExpanded) setIsSearchExpanded(false);
  }, [isSearchExpanded]);

  const collapseSideNav = useCallback(() => {
    if (isSideNavExpanded) setIsSideNavExpanded(false);
  }, [isSideNavExpanded]);

  const expandSearch = useCallback(() => {
    collapseSideNav();
    setIsSearchExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandSideNav = useCallback(() => {
    collapseSearch();
    setIsSideNavExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSideNav = useCallback(() => {
    collapseSearch();
    setIsSideNavExpanded((curr) => !curr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Make sure to close mobileTablet search drawer when user navigates to a new page
   */
  useEffect(() => {
    if (isTabletOrSmaller) {
      collapseSearch();
      collapseSideNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const context: LayoutContextValue = useMemo(
    () => ({
      isSearchExpanded,
      isSideNavExpanded,
      collapseSearch,
      collapseSideNav,
      expandSearch,
      expandSideNav,
      toggleSideNav,
    }),
    [collapseSearch, collapseSideNav, expandSearch, expandSideNav, isSearchExpanded, isSideNavExpanded, toggleSideNav]
  );

  return <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>;
};
