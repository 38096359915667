import styled from 'styled-components';

export const StyledNoContractsWrapper = styled.div`
  max-width: 516px;
  max-height: 228px;
  padding: 40px;
  background-color: white;
  text-align: center;
  border-radius: 8px;
`;

export const StyledWrapper = styled.div(
  () => `
    padding: 4.5rem 0;
  `
);

export const StyledPDFWrapper = styled.div`
  position: fixed;
  inset: 0;
  overflow: hidden auto;
`;
