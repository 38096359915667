import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { AlertCard } from '@components/common/AlertCard';
import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Loading } from '@components/common/Loading';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { StepsProgress } from '@components/common/StepsProgress';
import { Form } from '@components/form/core/Form';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { FormMap } from '@components/yard/YardCreateOrUpdateModal/FormMap';
import { FormNotes } from '@components/yard/YardCreateOrUpdateModal/FormNotes';
import { FormSummaryInfo } from '@components/yard/YardCreateOrUpdateModal/FormSummaryInfo';
import { StyledYardModalPages } from '@components/yard/YardCreateOrUpdateModal/styles';
import { useDispatch } from '@helpers/Thunk/hooks';
import { useTranslation } from '@hooks/useTranslation';
import {
  makeYardCreateOrUpdateCloseModalAction,
  makeYardCreateOrUpdateDisposeAction,
  makeYardCreateOrUpdateSaveDetailThunk,
} from '@redux/YardCreateOrUpdate/actions';

const STEP_COUNT = 3;
const STEP_SUMMARY = 0;
const STEP_MAP = 1;
const STEP_NOTES = 2;

export const YardCreateOrUpdateModal: React.VFC = () => {
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const isFirstStep = step === 0;
  const isLastStep = step === STEP_NOTES;

  const { detail, isModalOpen } = useSelector((state) => state.yardCreateOrUpdateReducer);
  const isFetching = useSelector(
    (state) => state.yardCreateOrUpdateReducer.isFetchingDetails || state.yardCreateOrUpdateReducer.isSavingDetails
  );

  const defaultValues = useFormDefaultValues({
    name: '',
    notes: '',
    type: null,
    contract: null,
    cropTypes: [],
    geometry: null,
  });
  const form = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: useFormSchemaResolver((schema) => ({
      name: schema.string().required(),
      notes: schema.string(),
      type: schema.mixed(),
      contract: schema.mixed(),
      cropTypes: schema.array(schema.mixed()),
      geometry: schema.mixed(),
    })).resolver,
  });

  const nameFieldState = form.getFieldState('name');
  const geometryFieldState = form.getFieldState('geometry');
  const contractFieldState = form.getFieldState('contract');
  const contract = form.watch('contract');

  const isEditing = !!detail;
  const isTurningIntoADrop = Boolean(!detail?.contractId && contract);

  const hasFormError = useMemo(() => {
    if (step === STEP_SUMMARY) {
      return nameFieldState.invalid || contractFieldState.invalid;
    }
    if (step === STEP_MAP) {
      return geometryFieldState.invalid;
    }
    return false;
  }, [contractFieldState.invalid, geometryFieldState.invalid, nameFieldState.invalid, step]);

  const title = useMemo(() => {
    if (isEditing) {
      return step === 0 ? t('edit_yard') : step === 1 ? t('edit_boundaries') : t('add_field_notes');
    }
    return t('create_new_yard');
  }, [isEditing, step, t]);

  const closeModal = useCallback(() => dispatch(makeYardCreateOrUpdateCloseModalAction()), [dispatch]);

  const stepForward = useCallback(async () => {
    if (isLastStep) {
      const data = form.getValues();
      const patchData: Partial<BeeYardDetails> = {
        id: detail?.id,
        name: data.name,
        fieldNotes: data.notes,
        yardType: data.type,
        cropTypesIds: data.cropTypes,
      };

      if (data.geometry) {
        patchData.geometry = data.geometry;
      }

      if (data.contract) {
        patchData.contractId = data.contract;
      }

      const response = await dispatch(makeYardCreateOrUpdateSaveDetailThunk(patchData));

      if (!response?.error) {
        dispatch(makeYardCreateOrUpdateCloseModalAction());
      }
    } else {
      setStep((curr) => curr + 1);
    }
  }, [detail, dispatch, form, isLastStep]);

  const stepBackward = useCallback(() => {
    setStep((curr) => curr - 1);
  }, []);

  useEffect(() => {
    if (detail) {
      form.reset({
        name: detail.name,
        notes: detail.fieldNotes,
        type: detail.yardType,
        contract: detail.contractId,
        cropTypes: detail.cropTypesIds,
        geometry: undefined, // Avoids it to be sent to server if not edited.
      });
      return () => {
        form.reset(defaultValues);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  useEffect(() => {
    if (!isModalOpen) {
      // Wait the modal to close before disposing.
      setTimeout(() => {
        dispatch(makeYardCreateOrUpdateDisposeAction());
        setStep(STEP_SUMMARY);
      }, theme.animations.durationLong);
    }
  }, [dispatch, isModalOpen, theme.animations.durationLong]);

  const alertText = isTurningIntoADrop ? t('edit_contract_yard_warning') : geometryFieldState.error?.message;
  const progressElement = <StepsProgress stepCount={STEP_COUNT} currentStep={step} markCompletedSteps />;

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} nonDismissible>
      <ModalHeader
        relative
        title={title}
        mobileTitle={isEditing ? t('edit_yard') : t('create_new_yard')}
        mobileLeftContent={
          <ResponsiveRender until={'mobile'}>
            <Box marginLeft_050>{progressElement}</Box>
          </ResponsiveRender>
        }
        alert={
          !!alertText && (
            <AlertCard fit warning>
              {alertText}
            </AlertCard>
          )
        }
      />

      <ModalContent>
        <Form form={form} column stretch>
          <StyledYardModalPages currentStep={step}>
            <FormSummaryInfo />
            <FormMap />
            <FormNotes />
          </StyledYardModalPages>
        </Form>
      </ModalContent>

      <ResponsiveRender from={'tablet'}>
        <ModalFooter
          acceptText={isLastStep ? t('submit') : t('next')}
          onAcceptClick={stepForward}
          acceptButtonProps={{ disabled: hasFormError || isFetching }}
          rejectText={t('back')}
          onRejectClick={stepBackward}
          rejectButtonProps={{ disabled: isFirstStep || isFetching }}
        >
          {progressElement}
        </ModalFooter>
      </ResponsiveRender>

      <ResponsiveRender until={'mobile'}>
        <ModalFooter
          acceptText={isLastStep ? t('submit') : t('next')}
          onAcceptClick={stepForward}
          acceptButtonProps={{ disabled: hasFormError || isFetching }}
        >
          {
            <Button tertiary suppressPaddingHorizontal onClick={stepBackward} disabled={isFirstStep || isFetching}>
              {t('back')}
            </Button>
          }
        </ModalFooter>
      </ResponsiveRender>

      <Loading whiteBackground visible={isFetching} delay={theme.animations.durationFast} roundedCorners />
    </Modal>
  );
};
