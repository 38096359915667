import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import * as uuid from 'uuid';

import { ModalInnerContext } from '@components/common/ModalBase/context';
import {
  useModalConditionalRendering,
  useModalContext,
  useModalDeferredOpenState,
} from '@components/common/ModalBase/hooks';
import { ModalBaseProps } from '@components/common/ModalBase/types';

import { StyledModalBackground, StyledModalContentWrapper, StyledModalWrapper } from './styles';

export const ModalBase = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ModalBaseProps>>(
  ({ isOpen: propIsOpen, alwaysRender, nonDismissible, onRequestClose, onFocus, children }, ref) => {
    const [id] = useState(() => uuid.v4());

    const isOpen = useModalDeferredOpenState(propIsOpen);
    const innerRef = useRef<HTMLDivElement>(null);

    const { addModalToStack, popModalFromStack, getModalIndex, getModalReverseIndex } = useModalContext();

    const close = useCallback(() => {
      onRequestClose && onRequestClose();
    }, [onRequestClose]);

    useEffect(() => {
      if (isOpen) {
        addModalToStack(id);
        return () => popModalFromStack(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const stackIndex = getModalIndex(id);
    const stackReverseIndex = getModalReverseIndex(id);

    useEffect(() => {
      const tId = setTimeout(() => {
        if (isOpen && stackReverseIndex === 0) {
          onFocus && onFocus();
        }
      }, 180);
      return () => clearTimeout(tId);
    }, [isOpen, onFocus, propIsOpen, stackReverseIndex]);

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => innerRef.current);

    if (!useModalConditionalRendering(alwaysRender || propIsOpen)) return null;

    return createPortal(
      <ModalInnerContext.Provider value={{ close }}>
        <StyledModalWrapper
          ref={innerRef}
          $visible={isOpen}
          $stackIndex={stackIndex}
          $stackReverseIndex={stackReverseIndex}
        >
          <StyledModalBackground onClick={nonDismissible ? undefined : onRequestClose} />
          <StyledModalContentWrapper $stackReverseIndex={stackReverseIndex}>{children}</StyledModalContentWrapper>
        </StyledModalWrapper>
      </ModalInnerContext.Provider>,
      document.body,
      id
    );
  }
);
ModalBase.displayName = 'ModalBase';
