import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import produce from 'immer';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Modal, ModalFooter, ModalHeader } from '@components/common/Modal';
import { ModalContent } from '@components/common/ModalBase/ModalContent';
import { ModalBaseProps } from '@components/common/ModalBase/types';
import { Text } from '@components/common/Text';
import { Checkbox } from '@components/form/inputs/CheckBox';
import APP from '@config/constants';
import { URLUtil } from '@helpers/URL';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';

type ExportLanguageOptions = { [K in BeeSupportedLangCode as `worker-${K}`]: boolean };
type ExportLanguageOptionKey = keyof ExportLanguageOptions;
type ExportOptions = { manager: boolean } & ExportLanguageOptions;
type ExportOptionKey = keyof ExportOptions;

const DEFAULT_EXPORT_LANGUAGE_OPTIONS: ExportLanguageOptions = {
  'worker-en': true,
  'worker-es': true,
  // Add more language keys if necessary.
};
const DEFAULT_EXPORT_OPTIONS: ExportOptions = {
  ...DEFAULT_EXPORT_LANGUAGE_OPTIONS,
  manager: true,
};

const EXPORT_LANGUAGE_OPTION_KEYS = Object.keys(DEFAULT_EXPORT_LANGUAGE_OPTIONS) as Array<ExportLanguageOptionKey>;
const EXPORT_OPTION_KEYS = Object.keys(DEFAULT_EXPORT_OPTIONS) as Array<ExportOptionKey>;

const StyledContractBox = styled(Box).attrs({ paper02: true, borderWidth: 1 })<{ hasSelection: boolean }>(
  ({ hasSelection, theme }) => css`
    border-color: ${hasSelection ? theme.colors.borderPrimary : theme.colors.borderSecondary};
    padding: 0.75rem;
  `
);

const StyledCheckBoxRow = styled(Box)(
  ({ theme }) => `
    align-items: center;
    gap: ${theme.spacing._075}
    

  `
);

export const ExportModal: React.VFC<{ contractId: number; modalProps: ModalBaseProps }> = ({
  contractId,
  modalProps,
}) => {
  const t = useTranslation();
  const { isMobile } = useGetScreenWidth();
  const history = useHistory();
  const [exportOptions, setExportOptions] = useState<ExportOptions>(DEFAULT_EXPORT_OPTIONS);

  const hasAnyOptionEnabled = useMemo(() => EXPORT_OPTION_KEYS.some((key) => exportOptions[key]), [exportOptions]);
  const hasAnyLanguageOptionEnabled = useMemo(
    () => EXPORT_LANGUAGE_OPTION_KEYS.some((key) => exportOptions[key]),
    [exportOptions]
  );

  const handleToggle = useCallback((value: boolean, key: string) => {
    setExportOptions((current) =>
      produce<ExportOptions>(current, (current) => {
        // If updating worker, also update all languages.
        if (key === 'worker') {
          EXPORT_LANGUAGE_OPTION_KEYS.forEach((k) => (current[k] = value));
        } else {
          current[key as ExportOptionKey] = value;
        }
      })
    );
  }, []);

  const goToExportPage = useCallback(() => {
    if (!contractId) {
      return;
    }

    const path = URLUtil.buildPagePath(APP.routes.pollinationContractsDetailsPDF, {
      pathParams: { uid: contractId },
      queryParams: {
        id: contractId,
        type: Object.entries(exportOptions)
          .filter(([key, value]) => value && EXPORT_OPTION_KEYS.includes(key as ExportOptionKey))
          .map(([key]) => key)
          .join(','),
      },
    });
    history.replace(path);
  }, [contractId, exportOptions, history]);

  return (
    <Modal {...modalProps}>
      <ModalHeader title={t('export_contract')} subtitle={t('export_contract_desc')} />
      <ModalContent>
        <StyledContractBox marginBottom_100 hasSelection={exportOptions.manager}>
          <StyledCheckBoxRow>
            <Checkbox value={exportOptions.manager} name={'manager'} onChange={handleToggle} />
            <Text weight={'600'} typography={'SmallParagraph'}>
              {t('pollination_pdf_manager_contract')}
            </Text>
          </StyledCheckBoxRow>
        </StyledContractBox>
        <StyledContractBox column hasSelection={hasAnyLanguageOptionEnabled}>
          <StyledCheckBoxRow alignItems="center" gap_0625>
            <Checkbox value={hasAnyLanguageOptionEnabled} name={'worker'} onChange={handleToggle} />
            <Box column={isMobile} gap_025={!isMobile} alignItems={isMobile ? 'initial' : 'center'}>
              <Text typography={'SmallParagraph'} weight={'600'}>
                {t('pollination_pdf_worker_contract')}
              </Text>
              <Text typography={'CaptionSmall'} color="contentTertiary" htmlTag="span">
                {t('export_contract_worker_desc')}
              </Text>
            </Box>
          </StyledCheckBoxRow>
          <Box paddingTop_050 paddingLeft_100 marginLeft_100 column>
            <StyledCheckBoxRow paddingBottom_050>
              <Checkbox value={exportOptions['worker-en']} name={'worker-en'} onChange={handleToggle} />
              <Text weight={'600'} typography={'SmallParagraph'}>
                {t('lang_english')}
              </Text>
            </StyledCheckBoxRow>
            <StyledCheckBoxRow>
              <Checkbox value={exportOptions['worker-es']} name={'worker-es'} onChange={handleToggle} />
              <Text weight={'600'} typography={'SmallParagraph'}>
                {t('lang_spanish')}
              </Text>
            </StyledCheckBoxRow>
          </Box>
        </StyledContractBox>
      </ModalContent>
      <ModalFooter
        autoCloseOnReject
        rejectText={t('cancel')}
        acceptText={t('export')}
        onAcceptClick={goToExportPage}
        acceptButtonProps={{ disabled: !hasAnyOptionEnabled }}
      />
    </Modal>
  );
};
