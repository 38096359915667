import styled, { css } from 'styled-components';

export const StyledStatusDot = styled.span<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    display: inline-block;
    position: absolute;

    top: 50%;
    right: -4px;
    width: 8px;
    height: 8px;
    transform: translateY(-50%) scale(0);

    border-radius: 50%;
    margin: 0 ${theme.spacing._050};
    background-color: ${theme.primitives.colors.green02};
    transition: ${theme.animations.transitionFast('transform')};

    ${$isVisible &&
    css`
      transform: translateY(-50%) scale(1);
    `}
  `
);
