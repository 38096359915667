import React from 'react';

import { InputAutocomplete, Option } from '@components/form/inputs/InputAutocomplete';
import { OptionText } from '@components/form/inputs/InputAutocomplete/OptionText';
import { InputAutocompleteProps } from '@components/form/inputs/InputAutocomplete/types';
import { useCropTypes } from '@hooks/useCropTypes';
import { useTranslation } from '@hooks/useTranslation';

export const InputCropTypeAutocomplete: React.VFC<InputAutocompleteProps> = ({ ...props }) => {
  const t = useTranslation();
  const { cropTypes } = useCropTypes();

  return (
    <InputAutocomplete
      isMultiple
      label={t('crop_type_input_label')}
      placeholder={t('crop_type_input_placeholder')}
      {...props}
    >
      {cropTypes.map(({ id, name }) => (
        <Option key={id} value={id}>
          <OptionText>{name}</OptionText>
        </Option>
      ))}
    </InputAutocomplete>
  );
};
