import moment from 'moment';

import { importAll } from '@helpers/deprecated/importAll';

import i18n from '../../i18n';

const weatherIcons = importAll(require.context('@assets/weather/', false, /\.(svg)$/));

export const prettyTime = (min) => {
  let hours = Math.floor(min / 60);
  const remainder = min % 60;
  // do not display more than 72h!
  if (72 < hours) {
    hours = 72;
  }
  if (hours) {
    return `${hours}h ${remainder} min`;
  }
  return `${remainder} min`;
};

// gets the timezone code and returns string
export const getTimeZone = (tz) => {
  switch (tz) {
    case 'AST':
      return 'Atlantic Time';
    case 'ADT':
      return 'Atlantic Daylight Time';
    case 'EST':
      return 'Eastern Time';
    case 'EDT':
      return 'Eastern Daylight Time';
    case 'CST':
      return 'Central Time';
    case 'CDT':
      return 'Central Daylight Time';
    case 'MST':
      return 'Mountain Time';
    case '_150T':
      return 'Mountain Daylight Time';
    case 'PST':
      return 'Pacific Time';
    case 'PDT':
      return 'Pacific Daylight Time';
    default:
      return tz;
  }
};

export const getMoment = (date) => {
  const no_date = '—';
  return date ? moment(date).local().format('MMM D, YYYY') : no_date;
};

export const getTime = (date) => {
  return moment(date).local().format('HH:mm');
};

export const startYear = () => {
  return moment().startOf('year').format('MMM D, YYYY');
};

export const getRangeDays = (range) => {
  let r = 7;
  switch (range) {
    case 'week':
      r = 7;
      break;
    case 'month':
      r = 30;
      break;
    case 'months':
      r = 90;
      break;
    default:
      break;
  }
  return r;
};

export const getDays = (num) => {
  const days = [];

  for (let i = 0; i < num; i++) {
    const randomNum = Math.floor(Math.random() * 11);
    days.push({
      id: i,
      day: moment().add(i, 'days'),
      src: weatherIcons[randomNum],
      high: Math.floor(Math.random() * 16) + 5,
      low: randomNum,
    });
  }

  return days;
};

const today = moment().endOf('day');

const yesterday = moment().subtract(1, 'day').endOf('day');

const dayBeforeYesterday = moment().subtract(2, 'day').endOf('day');

export const getDate = (analyzed_at) => {
  // UX Guidelines:
  // Today
  // Yesterday
  // [2-5] days ago
  // July 4, 2020
  const time = moment(analyzed_at).local();

  const days = [2, 3, 4, 5, 6].map((day) => moment().subtract(day, 'day').endOf('day'));

  if (time < today && time > yesterday) return i18n.t('today');
  if (time < yesterday && time > dayBeforeYesterday) return i18n.t('yesterday');

  let computedTime = getMoment(time);

  for (let i = 0; i < days.length; i++) {
    if (time < days[i] && time > days[i + 1]) computedTime = i18n.t('days_ago', { number: i + 2 });
  }

  return computedTime;
};

export const now = new Date().getTime();
