import React from 'react';

import { Box } from '@components/common/Box';
import { LoadingSkeleton } from '@components/common/LoadingSkeleton';
import { LoadingSkeletonCircle } from '@components/common/LoadingSkeleton/LoadingSkeletonCircle';
import { LoadingSkeletonRectangle } from '@components/common/LoadingSkeleton/LoadingSkeletonRectangle';
import { LoadingSkeletonProps } from '@components/common/LoadingSkeleton/types';

export const TimelineBaseLoading: React.VFC<LoadingSkeletonProps & { suppressDayMarks?: boolean }> = ({
  suppressDayMarks,
  ...props
}) => {
  return (
    <LoadingSkeleton paper03 backgroundColor={'background'} column stretch {...props}>
      {suppressDayMarks ? (
        <Box marginVertical_050 paddingVertical_025 />
      ) : (
        <Box marginVertical_150 center>
          <LoadingSkeletonRectangle width={120} height={32} borderRadius={'paperRadius03'} />
        </Box>
      )}
      <Box paddingVertical_100 marginBottom_150 alignItems={'center'} gap_100>
        <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
        <LoadingSkeletonRectangle width={128} height={24} />
      </Box>
      <Box paddingVertical_100 marginBottom_150 marginTop_050 alignItems={'center'} gap_100>
        <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
        <LoadingSkeletonRectangle width={220} height={24} />
      </Box>
      <Box paddingVertical_100 marginBottom_150 alignItems={'center'} gap_100>
        <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
        <LoadingSkeletonRectangle width={96} height={24} />
      </Box>
      {!suppressDayMarks && (
        <Box marginVertical_150 center>
          <LoadingSkeletonRectangle width={120} height={32} borderRadius={'paperRadius03'} />
        </Box>
      )}
      <Box paddingVertical_100 marginBottom_150 alignItems={'center'} gap_100>
        <LoadingSkeletonCircle size={24} backgroundColor={'grey06'} />
        <LoadingSkeletonRectangle width={108} height={24} />
      </Box>
    </LoadingSkeleton>
  );
};
