import styled, { css, keyframes } from 'styled-components';

import { Box } from '@components/common/Box';
import { PrimaryButton } from '@components/common/Button';
import { StyledCardsContainer } from '@components/common/Metrics/style';

export const ArrowButton = styled(PrimaryButton)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  padding: 0;
  align-items: center;
  justify-content: center;
  z-index: ${theme.getZIndexOf('lowLevelIndex')}; 
  transition: ${theme.animations.transitionMedium('background-color')}; 
`
);

const CardAppearingKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const EmptyView = styled(Box)(
  () => css`
    position: absolute;
    inset: 0;
  `
);

export const CardWrapper = styled.div<{ $index: number; $hidden: boolean; $animate: boolean }>(
  ({ theme, $index, $hidden, $animate }) => css`
    position: absolute;
    display: flex;
    top: 0;
    opacity: ${$hidden ? 0 : 1};
    pointer-events: ${$hidden ? 'none' : 'all'};

    transition: ${theme.animations.transitionMedium('left', 'opacity')};
    animation: ${$animate ? CardAppearingKeyframes : 'none'} ${theme.animations.durationLong + 100 * $index}ms
      ${theme.animations.easing};

    padding: 0 ${theme.spacing._050};
    z-index: ${theme.getZIndexOf('base')};
  `
);

export const CardsContainer = styled(StyledCardsContainer)(() => css``);

export const CarrouselContainer = styled.div<{ $isVisible: boolean }>(
  ({ theme, $isVisible }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    padding: 0 ${theme.spacing._150};
    margin: 0 -${theme.spacing._150};

    width: calc(100% + 2 * ${theme.spacing._150});
    height: 0;
    gap: ${({ theme }) => theme.spacing._050};

    transition: ${theme.animations.transitionMedium('height')};

    ${$isVisible &&
    css`
      height: 108px;
    `}
  `
);

export const StyledToggleMetricsButton = styled.button<{ $areMetricsVisible: boolean }>(
  ({ theme, $areMetricsVisible }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-top: ${-theme.spacing._050};

    transition: ${theme.animations.transitionMedium('margin-top')};

    &:before {
      content: '';
      position: absolute;
      inset: 50% 0 auto 0;
      height: 1px;
      background-color: ${theme.primitives.colors.grey05};
    }

    div {
      position: relative;
      background-color: ${theme.primitives.colors.background};
      padding: 0 ${theme.spacing._100};
      gap: ${theme.spacing._050};
    }

    svg {
      transition: ${theme.animations.transitionMedium('transform')};
      transform: rotate(180deg);
    }

    ${$areMetricsVisible &&
    css`
      margin-top: ${theme.spacing._050};

      svg {
        transform: rotate(0deg);
      }
    `}
  `
);
