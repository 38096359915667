import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderActions } from '@components/common/HeaderActions';
import { Archive } from '@components/common/Icon/presets/Archive';
import { Download } from '@components/common/Icon/presets/Download';
import { Edit } from '@components/common/Icon/presets/Edit';
import { Refresh } from '@components/common/Icon/presets/Refresh';
import { useContractId } from '@hooks/useContract';
import { useContractModalController } from '@redux/Contract/hooks';

import { ArchiveModal } from '../PolliContractActionsModals/ArchiveModal';
import { ExportModal } from '../PolliContractActionsModals/ExportModal';
import { RenewModal } from '../PolliContractActionsModals/RenewModal';

export const ContractActions: React.VFC = () => {
  const { t } = useTranslation();

  const uid = useContractId();

  const { openToEdit } = useContractModalController();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const actions = useMemo(
    () => [
      { title: t('edit_contract'), icon: Edit, onClick: () => uid && openToEdit(uid) },
      { title: t('export'), icon: Download, onClick: () => setIsExportModalOpen(true) },
      { title: t('renew'), icon: Refresh, onClick: () => setIsRenewModalOpen(true) },
      { title: t('archive'), icon: Archive, onClick: () => setIsArchiveModalOpen(true) },
    ],
    [openToEdit, t, uid]
  );

  if (!uid) return null;
  return (
    <>
      <HeaderActions actions={actions} />

      <ExportModal
        contractId={uid}
        modalProps={{
          isOpen: isExportModalOpen,
          onRequestClose: () => setIsExportModalOpen(false),
        }}
      />

      <RenewModal
        contractId={uid}
        modalProps={{
          isOpen: isRenewModalOpen,
          onRequestClose: () => setIsRenewModalOpen(false),
        }}
      />
      <ArchiveModal
        contractId={uid}
        modalProps={{
          isOpen: isArchiveModalOpen,
          onRequestClose: () => setIsArchiveModalOpen(false),
        }}
      />
    </>
  );
};
