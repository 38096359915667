import React from 'react';
import { useTheme } from 'styled-components';

import { IconSVGView } from '../styles';
import { IconSVGProps } from '../types';

export const SecurityPin = ({ size }: IconSVGProps) => {
  const { colors } = useTheme();
  return (
    <IconSVGView viewBox="0 0 16 16" size={size}>
      <rect fill={colors.surfacePrimary} width="16" height="16" rx="8" />
      <path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.45166C6.92781 4.45166 6.06452 5.31495 6.06452 6.38714V6.70972C5.70968 6.70972 5.41936 7.00005 5.41936 7.35489V10.5807C5.41936 10.9355 5.70968 11.2259 6.06452 11.2259H9.93549C10.2903 11.2259 10.5806 10.9355 10.5806 10.5807V7.35489C10.5806 7.00005 10.2903 6.70972 9.93549 6.70972V6.38714C9.93549 5.31495 9.0722 4.45166 8 4.45166ZM8 5.09689C8.73425 5.09689 9.29032 5.65296 9.29032 6.38721V6.7098H6.70967V6.38721C6.70967 5.65296 7.26574 5.09689 8 5.09689ZM8.48388 8.80646C8.48388 8.97001 8.40274 9.1146 8.27852 9.20219L8.45843 9.56202C8.54422 9.7336 8.41945 9.93549 8.22761 9.93549H7.7724C7.58056 9.93549 7.45579 9.7336 7.54158 9.56202L7.7215 9.20219C7.59728 9.1146 7.51614 8.97001 7.51614 8.80646C7.51614 8.53922 7.73277 8.32259 8.00001 8.32259C8.26724 8.32259 8.48388 8.53922 8.48388 8.80646Z"
      />
    </IconSVGView>
  );
};
