import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { PRESETS, PRESETS_MOBILE, StyledTextComponents } from '@components/common/Text/styles';
import { TextProps, TextWeight } from '@components/common/Text/types';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useColor } from '@style/theme/hooks';

export const Text: React.FC<TextProps> = ({
  weight,
  color,
  align,
  dashed,
  children,
  typography,
  lineHeight: propLineHeight,
  htmlTag: propHtmlTag,
  ...extraProps
}) => {
  const { colors, font } = useTheme();
  const fontColor = useColor(color, 'inherit');
  const { isMobile } = useGetScreenWidth();

  const { props, component } = useMemo(() => {
    if (!(typography in PRESETS)) {
      throw new Error(`Text component requires a valid typography. Given: ${typography || 'none'}`);
    }

    const preset = PRESETS[typography];
    const presetMobile = PRESETS_MOBILE[typography] ?? {};
    const responsivePreset = { ...preset, ...(isMobile ? presetMobile : {}) };
    const { htmlTag: presetHtmlTag, lineHeight: presetLineHeight, ...presetStyles } = responsivePreset;

    const htmlTag = propHtmlTag ?? presetHtmlTag;
    const component = StyledTextComponents[htmlTag] ?? htmlTag;

    const fontWeight: TextWeight = weight ?? '400';
    const fontHeight = propLineHeight ?? presetLineHeight;

    return {
      component,
      props: {
        role: 'text',
        textStyle: {
          fontWeight,
          color: fontColor,
          fontFamily: font.family,
          cursor: dashed ? 'pointer' : undefined,
          borderBottom: dashed ? `1px dashed ${colors.borderPrimaryHover}` : undefined,
          textAlign: align,
          lineHeight: fontHeight,
          ...presetStyles,
        },
        ...extraProps,
      },
    };
  }, [
    align,
    colors.borderPrimaryHover,
    dashed,
    extraProps,
    font.family,
    fontColor,
    propHtmlTag,
    propLineHeight,
    typography,
    weight,
  ]);

  return React.createElement(component, props, children);
};
