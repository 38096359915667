import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';
import { TextProps } from '@components/common/Text/types';

const StyledCellText = styled(Text)(
  () => css`
    max-width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-color: currentColor;
    // It needs to be greater than 1.0,
    // otherwise it will cut some letters.
    line-height: 1.05rem;
  `
);

export const DefaultCellText: React.FC<Partial<TextProps>> = ({ children, typography, ...props }) => {
  const title = typeof children === 'string' ? children : undefined;
  return (
    <StyledCellText title={title} {...props} typography={typography ?? 'SmallParagraph'}>
      {children}
    </StyledCellText>
  );
};
