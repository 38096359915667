import React, { useContext, useLayoutEffect, useRef, useState } from 'react';

import { Box } from '@components/common/Box';
import { Anchor, Button } from '@components/common/CTA';
import { Close } from '@components/common/Icon';
import { Link } from '@components/common/Icon/presets/Link';
import { ModalInnerContext } from '@components/common/ModalBase/context';
import { StyledModalHeader, StyledModalMobileHeader } from '@components/common/ModalBase/styles';
import { ModalHeaderProps } from '@components/common/ModalBase/types';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { Children } from '@helpers/Children';
import { DOM } from '@helpers/DOM';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  mobileTitle,
  mobileLeftContent,
  title,
  subtitle,
  linkText,
  linkTo,
  onLinkClick,
  closeButtonProps,
  suppressCloseButton: propSuppressCloseButton,
  alert,
  children,
  ...boxProps
}) => {
  const t = useTranslation();
  const { isMobile } = useGetScreenWidth();
  const innerContext = useContext(ModalInnerContext);
  const headerRef = useRef<HTMLDivElement>(null);
  const hasOnlyTitle = !!title && !subtitle && !linkText;

  const suppressCloseButton = propSuppressCloseButton && !isMobile;

  const [hasBorder, setHasBorder] = useState(false);

  useLayoutEffect(() => {
    const scrollElement = DOM.getNearestVerticallyScrollableParent(headerRef.current);
    if (scrollElement) {
      const handleScroll = () => {
        const { scrollTop } = scrollElement;
        setHasBorder(scrollTop > 0);
      };
      scrollElement.addEventListener('scroll', handleScroll);
      return () => scrollElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  let link: any = null;
  if (linkText) {
    if (linkTo) {
      link = (
        <Anchor suppressPadding href={linkTo} target={'_blank'} withTrailingIcon>
          {linkText}
          <Link />
        </Anchor>
      );
    } else {
      link = (
        <Button suppressPadding onClick={onLinkClick} withTrailingIcon tertiary>
          {linkText}
          <Link />
        </Button>
      );
    }
  }

  let mobileTitleChildren = mobileTitle;
  if (Children.hasOnlyText(mobileTitle)) {
    mobileTitleChildren = (
      <Text typography={'Heading3'} weight={'600'}>
        {mobileTitle}
      </Text>
    );
  }

  const hasHeaderContent = Boolean(title || subtitle || link || alert);
  const hasHeaderContentOrChildren = Boolean(hasHeaderContent || children);

  return (
    <>
      <ResponsiveRender until={'mobile'}>
        <StyledModalMobileHeader gap_100>
          <Box flex={2} paddingHorizontal_050>
            {mobileLeftContent}
          </Box>
          <Box flex={3} center paddingVertical_075>
            {mobileTitleChildren}
          </Box>
          <Box flex={2} justifyContent={'flex-end'} padding_050>
            {!suppressCloseButton && (
              <Button aria-label={t('close')} iconOnly onClick={() => innerContext?.close()} {...closeButtonProps}>
                <Close />
              </Button>
            )}
          </Box>
        </StyledModalMobileHeader>
      </ResponsiveRender>
      <StyledModalHeader
        ref={headerRef}
        $hasBorder={hasBorder}
        $hasContent={hasHeaderContentOrChildren}
        column
        stretch
        gap_150
        {...boxProps}
      >
        {!!alert && <Box>{alert}</Box>}

        {hasHeaderContent && (
          <Box gap_100 alignItems={hasOnlyTitle ? 'center' : 'flex-start'}>
            <Box fit column gap_050 justifyContent={'center'}>
              {title && (
                <Text typography={'ModalTitle'} weight={'600'}>
                  {title}
                </Text>
              )}

              {subtitle && <Text typography={'SmallParagraph'}>{subtitle}</Text>}

              {link}
            </Box>

            <ResponsiveRender from={'tablet'}>
              {!suppressCloseButton && (
                <Button
                  aria-label={t('close')}
                  iconOnly
                  secondary
                  onClick={() => innerContext?.close()}
                  {...closeButtonProps}
                >
                  <Close />
                </Button>
              )}
            </ResponsiveRender>
          </Box>
        )}

        {children}
      </StyledModalHeader>
    </>
  );
};
