import { ENDPOINTS } from '@config/api';
import { Api } from '@helpers/Api';
import { ApiResponseError } from '@helpers/Api/types';
import { CachedRequest } from '@helpers/CachedRequest';
import { CaseAdapter } from '@helpers/CaseAdapter';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';
import { YardsGlobalSearchAction, YardsGlobalSearchActionType } from '@redux/YardsGlobalSearch/types';

const DEF_FETCH_YARDS_CACHE_MAX_AGE = 10000; // 10 seconds

export const makeGlobalSearchFetchYardsStartAction = (): YardsGlobalSearchAction => ({
  type: YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_START,
});

export const makeGlobalSearchFetchYardsFinishAction = (
  data: Array<BeeGlobalSearchYard> | null,
  error: ApiResponseError | null
): YardsGlobalSearchAction => ({
  type: YardsGlobalSearchActionType.GLOBAL_SEARCH_FETCH_YARDS_FINISH,
  payload: {
    data,
    error,
  },
});

export const makeGlobalSearchPushToHistoryAction = (data: { id: number }): YardsGlobalSearchAction => ({
  type: YardsGlobalSearchActionType.GLOBAL_SEARCH_PUSH_TO_HISTORY,
  payload: { data },
});

export const makeGlobalSearchFetchYardsThunk = Thunk.createTakeFirst(() => {
  return async (dispatch) => {
    dispatch(makeGlobalSearchFetchYardsStartAction());

    const response = await CachedRequest.performRequest(
      URLUtil.buildURL(ENDPOINTS.yardsListSummary),
      (url) => Api.get(url),
      DEF_FETCH_YARDS_CACHE_MAX_AGE
    );

    let data: Array<BeeGlobalSearchYard> | null = null;
    let error: ApiResponseError | null = null;

    if (response.error) {
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      error = response.error;
    } else {
      data = parseYardsFromAPI(await response.json());
    }

    dispatch(makeGlobalSearchFetchYardsFinishAction(data, error));
  };
});

function parseYardsFromAPI(data: any): Array<BeeGlobalSearchYard> {
  return data.results.map((yard: any) => CaseAdapter.objectToCamelCase(yard));
}
