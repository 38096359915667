import styled, { css } from 'styled-components';

import { Text } from '@components/common/Text';
import { until } from '@style/mediaQueries';

export const StyledMenuView = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${theme.primitives.colors.white};
    border-radius: ${theme.shape.paperRadius01}px;
    box-shadow: ${theme.shadows.boxShadow01};

    ${until('tablet')} {
      padding: ${theme.spacing._100} 0;
    }
  `
);

export const StyledMenuItemGroupView = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:not(:first-child) {
      border-top: ${theme.primitives.colors.grey03} 1px solid;
    }
  `
);

export const StyledMenuItemView = styled.button<{ $danger?: boolean }>(
  ({ theme, $danger }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: ${theme.shape.paperRadius01}px;
    padding: 8px 12px; // TODO: Fix it along with the new DS changes
    gap: 12px;

    color: ${$danger ? theme.colors.contentDanger : 'currentColor'};

    &[disabled] {
      pointer-events: none;

      img {
        opacity: 0.24;
      }

      svg,
      *[role='text'] {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: ${theme.primitives.colors.grey02};
    }

    &:active {
      background-color: ${theme.primitives.colors.grey04};
    }
  `
);

export const MenuItemTextView = styled(Text)(
  () => css`
    flex: 1;
    text-align: left;
  `
);
