import produce from 'immer';

import {
  YardClearOrDeleteActionType,
  YardClearOrDeleteReducer,
  YardClearOrDeleteState,
} from '@redux/YardClearOrDelete/types';

const defaultState: YardClearOrDeleteState = {
  isDeletable: true,
  isFetchingDeletable: false,
};

export const yardClearOrDeleteReducer: YardClearOrDeleteReducer = (state = defaultState, action) => {
  return produce(state, (state) => {
    switch (action.type) {
      case YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_START:
        state.isFetchingDeletable = true;
        break;

      case YardClearOrDeleteActionType.YARD_CLEAR_OR_DELETE_DELETABLE_FETCH_FINISH:
        state.isFetchingDeletable = false;
        if (action.payload.deletable !== null) {
          state.isDeletable = action.payload.deletable;
        }
        break;
    }

    return state;
  });
};
