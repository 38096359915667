import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common/Text';
import { useFormDefaultValues, useFormSchemaResolver } from '@components/form/core/Form/hooks';
import { InputText } from '@components/form/inputs/InputText';

import { StyledGrowerForm, StyledInputWrapper } from './styles';
import { GrowerInfoFormSectionProps } from './types';

const PHONE_REGEX = /(^\+(\d{1,3})(\s*\d{2})?(\s*\d{3})(\s*\d{3})(\s*\d{3,4})$)|(^$)/;

export const FormSectionGrowerInfo: React.FC<GrowerInfoFormSectionProps> = ({
  isActive,
  section,
  activeSection,
  handleFormSectionSubmit,
  formErrors,
  defaultState,
}) => {
  const { t } = useTranslation();
  const firstInputWrapperRef = useRef<HTMLFieldSetElement>(null);

  const defaultValues = useFormDefaultValues<BeeGrower>(defaultState);
  const { shape, resolver } = useFormSchemaResolver<BeeGrower>((schema) => ({
    name: schema.string().required(),
    contactName: schema.string().required(),
    contactPhoneNumber: schema
      .string()
      .optional()
      .matches(PHONE_REGEX, t('error_type_phone_number'))
      .transform((_, val) => (val === '' ? undefined : val)),
    contactEmail: schema.string().email(),
  }));
  const form = useForm({ defaultValues, resolver });

  useEffect(() => {
    if (formErrors.growerContactPhoneNumber?.length) {
      form.setError('contactPhoneNumber', { message: formErrors.growerContactPhoneNumber[0] });
    }
  }, [form, formErrors]);

  return (
    <StyledGrowerForm
      id={`contractForm-section-${section}`}
      form={form}
      $isActive={isActive}
      $activeSection={activeSection}
      onValidSubmit={(data) => handleFormSectionSubmit({ grower: shape.cast(data) as BeeGrower })}
    >
      <fieldset ref={firstInputWrapperRef}>
        <Text typography="Legend" weight={'600'} color="grey06">
          {t('pollination_grower_info')}
        </Text>

        <StyledInputWrapper>
          <InputText
            id="contractForm-name"
            name="name"
            label={t('pollination_grower_name')}
            readOnly={!isActive}
            placeholder={t('pollination_grower_name_placeholder')}
            required
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputText
            id="contractForm-contactName"
            name="contactName"
            label={t('pollination_contact_name')}
            readOnly={!isActive}
            placeholder={t('pollination_contact_name_placeholder')}
            required
          />
        </StyledInputWrapper>

        <StyledInputWrapper>
          <InputText
            id="contractForm-contactPhoneNumber"
            name="contactPhoneNumber"
            type="tel"
            label={t('phone_int')}
            readOnly={!isActive}
            placeholder={t('phone_int_placeholder')}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputText
            id="contractForm-contactEmail"
            name="contactEmail"
            label={t('email')}
            readOnly={!isActive}
            placeholder={t('email_placeholder')}
          />
        </StyledInputWrapper>
      </fieldset>
    </StyledGrowerForm>
  );
};
