import React, { Fragment, useCallback } from 'react';
import { lowerCase, toLower } from 'lodash';

import { HiveActivityCard } from '@components/hive/HiveActivityCard';
import { TimelineBase } from '@components/timeline/TimelineBase';
import { StyledHeading, StyledYardBoxInfo, StyledYardNameLink } from '@components/timeline/TimelineBase/styles';
import { TimelineByActivityRequestProps } from '@components/timeline/TimelineByActivityRequest/types';
import { YardName } from '@components/yard/YardName';
import APP from '@config/constants';
import { Children } from '@helpers/Children';
import { maybePluralize } from '@helpers/deprecated/maybePluralize';
import { URLUtil } from '@helpers/URL';
import { useTranslation } from '@hooks/useTranslation';

export const TimelineByActivityRequest: React.VFC<TimelineByActivityRequestProps> = ({
  highlightedHiveId,
  ...props
}) => {
  const t = useTranslation();

  const itemHeadingRenderer = useCallback(
    (item: BeeActivityByRequest) => {
      let alertTypeLabel: any;
      let elements: Array<any>;

      const yardsLabel =
        item.yards.length === 0 ? (
          <strong>{t('unknown_yard')}</strong>
        ) : (
          Children.createWithSeparators(item.yards, {
            itemWrapper: (item, separator) => {
              const yardName = <YardName name={item.name} contractName={item.contractName} suppressStyling />;
              return (
                <>
                  <StyledYardNameLink to={URLUtil.buildPagePath(APP.routes.yard, { pathParams: { uid: item.id } })}>
                    {yardName}
                  </StyledYardNameLink>{' '}
                  <StyledYardBoxInfo typography={'CaptionSmall'}>
                    {item.isDeleted ? t('deleted') : item.nbHives}
                  </StyledYardBoxInfo>
                  {separator}
                </>
              );
            },
          })
        );
      const hivesLabel = <strong>{lowerCase(maybePluralize(item.hiveIdentities.length, 'hive', t))}</strong>;
      const ofHivesLabel = (
        <span>
          {t('of')} {hivesLabel}
        </span>
      );
      const byLabel = `${t('by')} ${item.workerName}`;

      const throughLabel =
        item.alertType === 'hive_managed_to_yard' ? (
          <span dangerouslySetInnerHTML={{ __html: t('through_managers_portal') }} />
        ) : null;

      if (
        item.alertType === 'new_inspection' ||
        (['hive_added_to_yard', 'hive_managed_to_yard'].includes(item.alertType) && highlightedHiveId)
      ) {
        const highlightedHiveIdentity = item.hiveIdentities.find(({ id }) => id === highlightedHiveId);

        const isHiveCreated = highlightedHiveIdentity?.isNew && item.alertType === 'hive_added_to_yard';
        const isHiveMoved = !highlightedHiveIdentity?.isNew && item.alertType !== 'new_inspection';
        const isHiveMovedThroughManagement = isHiveMoved && item.alertType === 'hive_managed_to_yard';

        const alertTypeTranslation = isHiveMovedThroughManagement
          ? 'hive_reassigned_to'
          : isHiveMoved
          ? 'hive_moved_to'
          : isHiveCreated
          ? 'hive_created_at'
          : item.reportType === 'yard'
          ? 'yard_inspection_at'
          : 'hive_inspection_at';

        alertTypeLabel = <span dangerouslySetInnerHTML={{ __html: t(alertTypeTranslation) }} />;
        elements = [alertTypeLabel, yardsLabel, byLabel, throughLabel];
      } else if (item.alertType === 'yard_cleared_out') {
        alertTypeLabel = (
          <span>
            {item.yards.length === 1 ? t('was') : t('were')} <strong>{t('cleared_out')}</strong>{' '}
          </span>
        );
        elements = [yardsLabel, alertTypeLabel, ofHivesLabel, byLabel];
      } else if (['lost_hive_marked_as_removed', 'lost_hive_marked_as_deadout'].includes(item.alertType)) {
        const alertTypeTranslation =
          item.alertType === 'lost_hive_marked_as_removed' ? (
            <strong>{t('removed')}</strong>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: toLower(
                  item.hiveIdentities.length === 1 ? t('flagged_as_deadout_bold_single') : t('flagged_as_deadout_bold')
                ),
              }}
            />
          );
        alertTypeLabel = (
          <span>
            <strong>
              {item.hiveIdentities.length} {t('lost')}{' '}
              {lowerCase(maybePluralize(item.hiveIdentities.length, 'hive', t, 's', false))}
            </strong>{' '}
            {item.hiveIdentities.length === 1 ? t('was') : t('were')} {alertTypeTranslation}
          </span>
        );
        elements = [alertTypeLabel, byLabel, throughLabel];
      } else {
        alertTypeLabel = (
          <span>
            <strong>{t(item.alertType === 'hive_added_to_yard' ? 'added' : 'reassigned')}</strong> {t('to')}
          </span>
        );
        elements = [hivesLabel, alertTypeLabel, yardsLabel, byLabel, throughLabel];
      }

      return (
        <StyledHeading typography={'SmallParagraph'}>
          {elements.filter(Boolean).map((el, index) => (
            <Fragment key={index}>
              {index > 0 ? ' ' : ''}
              {el}
            </Fragment>
          ))}
          {'.'}
        </StyledHeading>
      );
    },
    [highlightedHiveId, t]
  );

  const itemContentRenderer = useCallback(
    (item: BeeActivityByRequest) => {
      return <HiveActivityCard activity={item} highlightedHiveId={highlightedHiveId} />;
    },
    [highlightedHiveId]
  );

  return <TimelineBase {...{ itemHeadingRenderer, itemContentRenderer }} {...props} />;
};
