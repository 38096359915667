import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { DEF_PANE_WIDTH } from '@components/common/Map/constants';
import { MapContextProvider } from '@components/common/Map/context';
import { Coordinate, MapContextValue, Padding } from '@components/common/Map/types';
import { useDynamicModal } from '@components/common/ModalBase/hooks';
import { PolliMapContext } from '@components/pollination/PolliMap/context';
import {
  useTransientFormHandlers,
  useTransientHelperMethods,
  useTransientRemover,
  useTransientSelector,
  useTransientState,
  useTransientStateList,
  useTransientUpdater,
} from '@components/pollination/PolliMap/hooks';
import {
  AnyInstance,
  isTransientBlock,
  isTransientDrop,
  isTransientPoi,
  PolliMapContextValue,
  PolliMapMode,
  PolliMapProps,
  TransientBlock,
  TransientDrop,
  TransientInstance,
  TransientPoi,
} from '@components/pollination/PolliMap/types';
import { PolliMapUtil } from '@components/pollination/PolliMap/util';
import { PolliMapControls } from '@components/pollination/PolliMapControls';
import { PolliMapPane } from '@components/pollination/PolliMapPane';
import { PolliMapSurface } from '@components/pollination/PolliMapSurface';
import { PolliMapToolbar } from '@components/pollination/PolliMapToolbar';
import { Analytics } from '@helpers/Analytics';
import { AnalyticsEventType } from '@helpers/Analytics/types';
import { useAskBeforeExitAlert } from '@hooks/useAskBeforeExitAlert';
import { useContractId } from '@hooks/useContract';
import { useKeyHandler } from '@hooks/useKeyHandler';
import {
  makeFetchBlocksMapThunk,
  makeFetchContractThunk,
  makeFetchDropsMapThunk,
  makeFetchPoisMapThunk,
  makePatchPollinationBatchThunk,
} from '@redux/Contract/actions';
import { ContractState, PollinationBatch } from '@redux/Contract/types';
import { store } from '@redux/store';

export const PolliMap: React.VFC<PolliMapProps> = (props) => {
  const [mode, setMode] = useState<PolliMapMode>(() =>
    props.static ? PolliMapMode.STATIC : props.readonly ? PolliMapMode.READONLY : PolliMapMode.IDLE
  );
  const [isLoading, setLoading] = useState(false);

  const [drops, setDrops, selectedDrop, setSelectedDrop] = useTransientState<TransientDrop>('drop');
  const [blocks, setBlocks, selectedBlock, setSelectedBlock] = useTransientState<TransientBlock>('block');
  const [pois, setPois, selectedPoi, setSelectedPoi] = useTransientState<TransientPoi>('poi');

  const mapEditStartTimestamp = useRef(0);

  const contractId = useContractId();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const confirmDeleteDropModal = useDynamicModal();
  const confirmDeleteBlockModal = useDynamicModal();
  const confirmDeletePoiModal = useDynamicModal();
  const mapInstanceRef = useRef<MapContextValue>(null);

  // Show an alert in case user try to exit the management mode.
  useAskBeforeExitAlert({
    message: t('on_before_lose_changes_prompt'),
    disabled: [PolliMapMode.IDLE, PolliMapMode.STATIC, PolliMapMode.READONLY].includes(mode),
  });

  const clearAnyElementSelection = useCallback(
    (skip?: AnyInstance<TransientInstance> | null) => {
      !isTransientDrop(skip) && setSelectedDrop(null);
      !isTransientBlock(skip) && setSelectedBlock(null);
      !isTransientPoi(skip) && setSelectedPoi(null);
    },
    [setSelectedBlock, setSelectedDrop, setSelectedPoi]
  );

  const clearMode = useCallback(() => {
    // Setting to managing first in order to
    // trigger all necessary state changes.
    setMode(PolliMapMode.MANAGING);
    setTimeout(() => setMode(PolliMapMode.IDLE));
  }, [setMode]);

  const clear = useCallback(() => {
    clearAnyElementSelection();
    clearMode();
  }, [clearMode, clearAnyElementSelection]);

  const isIdle = mode === PolliMapMode.IDLE;
  const isStatic = mode === PolliMapMode.STATIC;
  const isReadonly = mode === PolliMapMode.READONLY;
  const isManaging = mode === PolliMapMode.MANAGING;

  const isManagingDrops = mode === PolliMapMode.MANAGING_DROPS;
  const isManagingBlocks = mode === PolliMapMode.MANAGING_BLOCKS;
  const isManagingPois = mode === PolliMapMode.MANAGING_POIS;
  const isManagingElements = isManagingDrops || isManagingBlocks || isManagingPois;

  const isManagingADrop = mode === PolliMapMode.MANAGING_A_DROP;
  const isManagingABlock = mode === PolliMapMode.MANAGING_A_BLOCK;
  const isManagingAPoi = mode === PolliMapMode.MANAGING_A_POI;
  const isManagingAnElement = isManagingADrop || isManagingABlock || isManagingAPoi;

  const isDraggingElements = mode === PolliMapMode.DRAGGING_ELEMENTS;

  const dropsList = useTransientStateList(drops);
  const blocksList = useTransientStateList(blocks);
  const poisList = useTransientStateList(pois);

  const selectDrop = useTransientSelector(setSelectedDrop, clearAnyElementSelection);
  const selectBlock = useTransientSelector(setSelectedBlock, clearAnyElementSelection);
  const selectPoi = useTransientSelector(setSelectedPoi, clearAnyElementSelection);

  const updateDrop = useTransientUpdater(setDrops);
  const updateBlock = useTransientUpdater(setBlocks);
  const updatePoi = useTransientUpdater(setPois);

  const removeDrop = useTransientRemover(
    setDrops,
    clearAnyElementSelection,
    confirmDeleteDropModal.open,
    'pollination_drop_delete',
    () => Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_DROP_DELETE })
  );
  const removeBlock = useTransientRemover(
    setBlocks,
    clearAnyElementSelection,
    confirmDeleteBlockModal.open,
    'pollination_block_delete',
    () => Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_BLOCK_DELETE })
  );
  const removePoi = useTransientRemover(
    setPois,
    clearAnyElementSelection,
    confirmDeletePoiModal.open,
    'pollination_poi_delete',
    () => Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_POI_DELETE })
  );

  const { getKey, isAdding, isEditing } = useTransientHelperMethods();

  const isEmpty = useMemo(
    () => blocksList.length === 0 && dropsList.length === 0 && poisList.length === 0,
    [blocksList, dropsList, poisList]
  );

  const fitMapToElements = useCallback(() => {
    const mapInstance = mapInstanceRef.current?.instance;
    const coordinates = [] as Array<Coordinate>;
    dropsList.forEach((drop) => coordinates.push(drop.position));
    blocksList.forEach((block) => coordinates.push(...block.path));
    poisList.forEach((poi) => coordinates.push(poi.location));

    const leftPaneAdjust = isStatic ? 0 : DEF_PANE_WIDTH / -2;

    if (coordinates.length && mapInstance) {
      const fitVerticalPadding = theme.primitives.px._200;
      const fitHorizontalPadding = DEF_PANE_WIDTH * 0.5;
      const fitPaddings: Padding = {
        top: fitVerticalPadding,
        bottom: fitVerticalPadding,
        right: fitHorizontalPadding,
        left: fitHorizontalPadding,
      };

      mapInstance.fitCoordinates(coordinates, fitPaddings);
      mapInstance.panBy({ x: leftPaneAdjust, y: 0 });

      // Workaround to make sure the fitting is actually working.
      setTimeout(() => {
        mapInstance.fitCoordinates(coordinates, fitPaddings);
        mapInstance.panBy({ x: leftPaneAdjust, y: 0 });
      });
    }
  }, [blocksList, dropsList, isStatic, poisList, theme.primitives.px._200]);

  const loadFromAPIToRedux = useCallback(async () => {
    if (contractId) {
      await Promise.all([
        dispatch(makeFetchDropsMapThunk(contractId)),
        dispatch(makeFetchBlocksMapThunk(contractId)),
        dispatch(makeFetchPoisMapThunk(contractId)),

        // Updating also the contract info.
        dispatch(makeFetchContractThunk(contractId)),
      ]);
    }
  }, [contractId, dispatch]);

  const loadFromReduxToMap = useCallback(() => {
    const globalState = store.getState() as any;

    const {
      isFetching,
      isFetchingContractDrops,
      isFetchingContractBlocks,
      contractDropsMap,
      contractBlocksMap,
      contractPoisMap,
    } = globalState.contractReducer as ContractState;

    if (!isFetching && !isFetchingContractDrops && !isFetchingContractBlocks) {
      const parsedDrops = PolliMapUtil.parseDropsToTransient(contractDropsMap ?? []);
      const parsedBlocks = PolliMapUtil.parseBlocksToTransient(contractBlocksMap ?? []);
      const parsedPois = PolliMapUtil.parsePoisToTransient(contractPoisMap ?? []);
      setDrops(() => ({ added: {}, removed: {}, edited: parsedDrops }));
      setBlocks(() => ({ added: {}, removed: {}, edited: parsedBlocks }));
      setPois(() => ({ added: {}, removed: {}, edited: parsedPois }));
    }
  }, [setBlocks, setDrops, setPois]);

  const loadFromPropsToMap = useCallback(() => {
    const parsedDrops = PolliMapUtil.parseDropsToTransient(props.drops ?? []);
    const parsedBlocks = PolliMapUtil.parseBlocksToTransient(props.blocks ?? []);
    const parsedPois = PolliMapUtil.parsePoisToTransient(props.pois ?? []);
    setDrops(() => ({ added: {}, removed: {}, edited: parsedDrops }));
    setBlocks(() => ({ added: {}, removed: {}, edited: parsedBlocks }));
    setPois(() => ({ added: {}, removed: {}, edited: parsedPois }));
  }, [props.blocks, props.drops, props.pois, setBlocks, setDrops, setPois]);

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const hasDataFromProps = [props.drops, props.blocks, props.pois].some((val) => val !== undefined);
      if (hasDataFromProps) {
        loadFromPropsToMap();
      } else {
        await loadFromAPIToRedux();
        await loadFromReduxToMap();
      }
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => setLoading(false));
  }, [loadFromAPIToRedux, loadFromPropsToMap, loadFromReduxToMap, props.blocks, props.drops, props.pois]);

  const patchStateToAPI = useCallback(async () => {
    if (contractId) {
      setLoading(true);

      const data: PollinationBatch = {
        contractId: contractId,
        drop: PolliMapUtil.parseDropsStateToGlobal(drops),
        block: PolliMapUtil.parseBlocksStateToGlobal(blocks),
        point_of_interest: PolliMapUtil.parsePoisStateToGlobal(pois),
      };

      const hasChange = [
        data.drop.add,
        data.drop.patch,
        data.drop.remove,
        data.block.add,
        data.block.patch,
        data.block.remove,
        data.point_of_interest.add,
        data.point_of_interest.patch,
        data.point_of_interest.remove,
      ].some((set) => set.length > 0);

      if (hasChange) {
        await dispatch(makePatchPollinationBatchThunk(data));
      }

      setLoading(false);
    }
  }, [contractId, drops, blocks, pois, dispatch]);

  const cancelStateChanges = useCallback(async () => {
    setMode(PolliMapMode.MANAGING);
    await load();
    clear();

    const elapsed = (+new Date() - mapEditStartTimestamp.current) / 1000.0;
    Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_EDIT_CANCEL, eventData: { elapsed } });
  }, [clear, load]);

  const submitStateChanges = useCallback(async () => {
    setMode(PolliMapMode.MANAGING);
    await patchStateToAPI();
    await load();
    clear();

    const elapsed = (+new Date() - mapEditStartTimestamp.current) / 1000.0;
    Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_EDIT_SAVE, eventData: { elapsed } });
  }, [clear, load, patchStateToAPI]);

  const [submitDropChange, cancelDropChange] = useTransientFormHandlers(
    drops,
    updateDrop,
    selectDrop,
    selectedDrop,
    clearAnyElementSelection,
    PolliMapMode.MANAGING_DROPS,
    setMode
  );
  const [submitBlockChange, cancelBlockChange] = useTransientFormHandlers(
    blocks,
    updateBlock,
    selectBlock,
    selectedBlock,
    clearAnyElementSelection,
    PolliMapMode.MANAGING_BLOCKS,
    setMode
  );
  const [submitPoiChange, cancelPoiChange] = useTransientFormHandlers(
    pois,
    updatePoi,
    selectPoi,
    selectedPoi,
    clearAnyElementSelection,
    PolliMapMode.MANAGING_POIS,
    setMode
  );

  const cancelAnyElementChange = useCallback(
    (skipForEdit = false) => {
      isManagingADrop && !(skipForEdit && isEditing(selectedDrop, drops)) && cancelDropChange();
      isManagingABlock && !(skipForEdit && isEditing(selectedBlock, blocks)) && cancelBlockChange();
      isManagingAPoi && !(skipForEdit && isEditing(selectedPoi, pois)) && cancelPoiChange();
    },
    [
      blocks,
      cancelBlockChange,
      cancelDropChange,
      cancelPoiChange,
      drops,
      isEditing,
      isManagingABlock,
      isManagingADrop,
      isManagingAPoi,
      pois,
      selectedBlock,
      selectedDrop,
      selectedPoi,
    ]
  );

  const submit = useCallback(() => submitStateChanges(), [submitStateChanges]);
  const cancel = useCallback(() => cancelStateChanges(), [cancelStateChanges]);

  const context: PolliMapContextValue = useMemo(
    () => ({
      mode,
      setMode,
      isIdle,
      isStatic,
      isReadonly,
      isLoading,
      isManaging,
      isManagingDrops,
      isManagingBlocks,
      isManagingPois,
      isManagingElements,
      isManagingADrop,
      isManagingABlock,
      isManagingAPoi,
      isManagingAnElement,
      isDraggingElements,
      getKey,
      isAdding,
      isEditing,
      drops,
      blocks,
      pois,
      dropsList,
      blocksList,
      poisList,
      selectedDrop,
      selectedBlock,
      selectedPoi,
      selectDrop,
      selectBlock,
      selectPoi,
      updateDrop,
      updateBlock,
      updatePoi,
      removeDrop,
      removeBlock,
      removePoi,
      submitDropChange,
      submitBlockChange,
      submitPoiChange,
      cancelDropChange,
      cancelBlockChange,
      cancelPoiChange,
      cancelAnyElementChange,
      clearAnyElementSelection,
      isEmpty,
      fitMapToElements,
      submit,
      cancel,
    }),
    [
      blocks,
      blocksList,
      cancel,
      cancelAnyElementChange,
      cancelBlockChange,
      cancelDropChange,
      cancelPoiChange,
      clearAnyElementSelection,
      drops,
      dropsList,
      fitMapToElements,
      getKey,
      isAdding,
      isDraggingElements,
      isEditing,
      isEmpty,
      isIdle,
      isLoading,
      isManaging,
      isManagingABlock,
      isManagingADrop,
      isManagingAPoi,
      isManagingAnElement,
      isManagingBlocks,
      isManagingDrops,
      isManagingElements,
      isManagingPois,
      isReadonly,
      isStatic,
      mode,
      pois,
      poisList,
      removeBlock,
      removeDrop,
      removePoi,
      selectBlock,
      selectDrop,
      selectPoi,
      selectedBlock,
      selectedDrop,
      selectedPoi,
      submit,
      submitBlockChange,
      submitDropChange,
      submitPoiChange,
      updateBlock,
      updateDrop,
      updatePoi,
    ]
  );

  const onMapInstance = useCallback(() => {
    fitMapToElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitMapToElements, props.onLoad]);

  const onMapTilesLoaded = useCallback(() => {
    props.onLoad && props.onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onLoad]);

  const keyHandler = useCallback(() => {
    if (isManagingAnElement) {
      cancelAnyElementChange(true);
    } else if (selectedDrop || selectedBlock || selectedPoi) {
      clearAnyElementSelection();
    } else if (isManagingDrops || isManagingBlocks || isManagingPois) {
      setMode(PolliMapMode.MANAGING);
    } else if (isManaging) {
      cancel().then(null);
    }
  }, [
    cancel,
    cancelAnyElementChange,
    clearAnyElementSelection,
    isManaging,
    isManagingAnElement,
    isManagingBlocks,
    isManagingDrops,
    isManagingPois,
    selectedBlock,
    selectedDrop,
    selectedPoi,
  ]);

  useKeyHandler({ handler: keyHandler, keyFilter: ['Escape'] });

  // Reload state in case of contract change.
  useEffect(() => {
    load().then(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId, props.drops, props.blocks, props.pois]);

  useEffect(() => {
    !isLoading && setTimeout(fitMapToElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (typeof props.readonly !== 'undefined') {
      setMode(props.readonly ? PolliMapMode.READONLY : PolliMapMode.IDLE);
    }
  }, [props.readonly]);

  useEffect(() => {
    const showToolbar = !(isIdle || isReadonly || isStatic);
    if (showToolbar) {
      Analytics.sendEvent({ event: AnalyticsEventType.POLLINATION_MAP_EDIT_START });
      mapEditStartTimestamp.current = +new Date();
    }
  }, [isIdle, isReadonly, isStatic]);

  return (
    <>
      <PolliMapContext.Provider value={context}>
        <MapContextProvider ref={mapInstanceRef} onInstance={onMapInstance}>
          <PolliMapToolbar>
            <PolliMapPane>
              <PolliMapSurface onTilesLoaded={onMapTilesLoaded}>
                <PolliMapControls />
              </PolliMapSurface>
            </PolliMapPane>
          </PolliMapToolbar>
        </MapContextProvider>
      </PolliMapContext.Provider>

      {confirmDeleteDropModal.content}
      {confirmDeleteBlockModal.content}
      {confirmDeletePoiModal.content}
    </>
  );
};
