import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { is } from 'immer/dist/utils/common';
import { Color, useTheme } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button, NavRouterLink } from '@components/common/CTA';
import { Check, Close } from '@components/common/Icon';
import { Warning } from '@components/common/Icon/presets/Warning';
import {
  StyledSnackbar,
  StyledSnackbarCard,
  StyledSnackbarCardLoader,
  StyledSnackbarIconWrapper,
  StyledSnackbarStack,
} from '@components/common/Snackbar/styles';
import { Text } from '@components/common/Text';
import { useGetScreenWidth } from '@hooks/useGetScreenWidth';
import { useTranslation } from '@hooks/useTranslation';
import { SnackbarDuration, SnackbarOptions, SnackbarQueuedItem, SnackbarType } from '@redux/Snackbar/types';
import { useColor } from '@style/theme/hooks';

const DEF_APPEARING_DELAY = 80;

const DEF_DURATION: SnackbarDuration = 'normal';
const DEF_DURATIONS: Record<SnackbarDuration, any> = {
  normal: 5000,
};

const DEF_TYPE: SnackbarType = 'success';
const DEF_TYPE_ICONS: Record<SnackbarType, any> = {
  success: Check,
  warning: Warning,
  error: Close,
};

const DEF_TYPE_COLORS: Record<SnackbarType, Color> = {
  success: 'green02',
  warning: 'orange01',
  error: 'red02',
};
const DEF_TYPE_LIGHT_COLORS: Record<SnackbarType, Color> = {
  success: 'green01',
  warning: 'yellow01',
  error: 'red01',
};

const DEF_ENABLE_QUEUE_GROUPING = true;

export const Snackbar: React.VFC = () => {
  const theme = useTheme();

  const timeouts = useRef<Record<string, any>>({});
  const snackBarElements = useRef<Record<string, { element: HTMLElement; index: number; subIndex: number }>>({});
  const [ordering, setOrdering] = useState<Array<string>>([]);

  const [appearingIds, setAppearingIds] = useState<Record<string, boolean>>({});
  const [dismissingIds, setDismissingIds] = useState<Record<string, boolean>>({});
  const [unmountedIds, setUnmountedIds] = useState<Record<string, boolean>>({});

  const [progressStart, setProgressStart] = useState<Record<string, number>>({});
  const [standByStart, setStandByStart] = useState<Record<string, number>>({});
  const [standByTotal, setStandByTotal] = useState<Record<string, number>>({});

  const screenWidthInfo = useGetScreenWidth();

  const queue = useSelector((state) => state.snackbarReducer.queue);
  const queueGrouped = useMemo(() => {
    const groups: Array<Array<SnackbarQueuedItem>> = [];
    queue
      .filter(({ id }) => !unmountedIds[id])
      .forEach((item) => {
        if (!DEF_ENABLE_QUEUE_GROUPING || !groups.length) {
          return groups.push([item]);
        }

        const currentItemKey = item.messageTranslation;

        const existingGroup = groups.find((group) => {
          const lastItem = group[group.length - 1];
          const lastItemKey = lastItem.messageTranslation;
          return lastItemKey === currentItemKey;
        });

        if (existingGroup) {
          existingGroup.push(item);
        } else {
          groups.push([item]);
        }
      });
    return groups;
  }, [queue, unmountedIds]);

  const indexedQueueGroupsMap = useMemo(
    () =>
      [...queueGrouped]
        .reverse()
        .flatMap((queue, indexReversed) =>
          queue.map((item, subIndex) => ({
            item,
            indexReversed,
            subIndex,
          }))
        )
        .reduce(
          (acc, entry) => ({ ...acc, [entry.item.id]: entry }),
          {} as Record<string, { item: SnackbarQueuedItem; indexReversed: number; subIndex: number }>
        ),
    [queueGrouped]
  );

  const getOffset = useCallback(
    ({ id }: SnackbarQueuedItem) => {
      return +new Date() - (progressStart[id] ?? +new Date()) - (standByTotal[id] ?? 0);
    },
    [progressStart, standByTotal]
  );

  const getSiblings = useCallback(
    ({ messageTranslation }: SnackbarQueuedItem) => {
      return queue
        .filter(({ id }) => !(id in unmountedIds))
        .filter((item) => item.messageTranslation === messageTranslation);
    },
    [queue, unmountedIds]
  );

  const scheduleAppearing = useCallback(({ id }: SnackbarQueuedItem) => {
    setAppearingIds((curr) => ({ ...curr, [id]: true }));
    setProgressStart((curr) => ({ ...curr, [id]: +new Date() }));
  }, []);

  const scheduleDismissing = useCallback(
    (item: SnackbarQueuedItem, { immediate } = { immediate: false }) => {
      const { persistent, duration = DEF_DURATION } = item;
      const siblings = getSiblings(item);
      const offset = getOffset(item);

      siblings.forEach(({ id }, index) => {
        (timeouts.current[id] ?? []).map(clearTimeout);

        if (immediate || !persistent) {
          const durationInMilliseconds = DEF_DURATIONS[duration];
          const extraGroupDelay = (siblings.length - index) * theme.animations.durationFast * 0.25;
          const delayUntilDismiss = extraGroupDelay + (immediate ? 0 : durationInMilliseconds - offset);
          const delayUntilUnmount = delayUntilDismiss + theme.animations.durationMedium;

          timeouts.current[id] = [
            setTimeout(() => setDismissingIds((curr) => ({ ...curr, [id]: true })), delayUntilDismiss),
            setTimeout(() => setUnmountedIds((curr) => ({ ...curr, [id]: true })), delayUntilUnmount),
          ];
        }
      });
    },
    [getOffset, getSiblings, theme.animations.durationFast, theme.animations.durationMedium]
  );

  const markAsStandby = useCallback(
    (item: SnackbarQueuedItem) => {
      getSiblings(item).forEach(({ id }) => (timeouts.current[id] ?? []).map(clearTimeout));
      setStandByStart((curr) => ({ ...curr, [item.id]: +new Date() }));
    },
    [getSiblings]
  );

  const unmarkAsStandby = useCallback(
    (item: SnackbarQueuedItem) => {
      setStandByTotal((curr) => {
        const lastStandByStart = standByStart[item.id] ?? +new Date();
        const standByElapsed = +new Date() - lastStandByStart;
        const nextTotal = standByElapsed + (curr[item.id] ?? 0);
        return { ...curr, [item.id]: nextTotal };
      });
      setStandByStart(({ ...curr }) => {
        delete curr[item.id];
        return curr;
      });
    },
    [standByStart]
  );

  const handleCloseClick = useCallback(
    (item: SnackbarQueuedItem) => {
      scheduleDismissing(item, { immediate: true });
      item.onCloseClick && item.onCloseClick();
    },
    [scheduleDismissing]
  );

  const handleActionClick = useCallback(
    (item: SnackbarQueuedItem) => {
      scheduleDismissing(item, { immediate: true });
      item.onActionClick && item.onActionClick();
    },
    [scheduleDismissing]
  );

  // Schedule the appearing and dismiss animations.
  useEffect(() => {
    setOrdering(([...next]) => {
      queue.filter(({ id }) => !next.includes(id)).forEach(({ id }) => next.push(id));
      return next;
    });

    setTimeout(() => {
      const scheduledForDismissing: Record<string, boolean> = {};

      queue
        .filter(({ id }) => !unmountedIds[id])
        .forEach((item) => {
          !appearingIds[item.id] && scheduleAppearing(item);

          if (!scheduledForDismissing[item.messageTranslation]) {
            scheduledForDismissing[item.messageTranslation] = true;
            scheduleDismissing(item);
          }
        });
    }, DEF_APPEARING_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, standByTotal]);

  useEffect(() => {
    Object.keys(unmountedIds).forEach((id) => delete snackBarElements.current[id]);
    const heightsPerIndex = Object.values(snackBarElements.current).reduce(
      (acc, { index, element }) => ({
        ...acc,
        [index]: element.getBoundingClientRect().height,
      }),
      {} as Record<number, number>
    );
    Object.entries(snackBarElements.current).forEach(([id, { element, index, subIndex }]) => {
      const appearing = appearingIds[id];
      const dismissing = dismissingIds[id];
      const height = heightsPerIndex[index];
      const translationY = new Array(index).fill(null).reduce((acc, _, index) => acc + heightsPerIndex[index], 0);

      if (dismissing) {
        element.style.transform = `translate(-100%, -${
          translationY + index * theme.primitives.px._100
        }px) scale(1.5, 1)`;
      } else if (appearing) {
        element.style.transform = `translate(0, -${translationY + index * theme.primitives.px._100}px)`;
      } else {
        element.style.transform = `translate(0, -${height + theme.primitives.px._100}px)`;
      }
    });
  }, [appearingIds, dismissingIds, unmountedIds, theme.primitives.px._100, screenWidthInfo]);

  return createPortal(
    <StyledSnackbarStack>
      {ordering
        .filter((id) => !unmountedIds[id])
        .map((id) => indexedQueueGroupsMap[id])
        .filter(Boolean)
        .map(({ item, indexReversed, subIndex }) => {
          const { id, ...options } = item;
          const index = queueGrouped.length - indexReversed - 1;
          const subIndexReversed = queue.length - subIndex - 1;
          const isStandBy = !!standByStart[id];

          return (
            <StyledSnackbar
              key={id}
              ref={(element) => element && (snackBarElements.current[id] = { element, index, subIndex })}
              $appearing={!!appearingIds[id]}
              $dismissing={dismissingIds[id]}
              $index={index}
              $subIndex={subIndex}
              $subIndexReversed={subIndexReversed}
              onMouseEnter={() => markAsStandby(item)}
              onMouseLeave={() => unmarkAsStandby(item)}
            >
              <SnackbarCard
                {...options}
                persistent={subIndex > 0 || item.persistent}
                onCloseClick={() => handleCloseClick(item)}
                onActionClick={() => handleActionClick(item)}
                isStandBy={isStandBy}
              />
            </StyledSnackbar>
          );
        })}
    </StyledSnackbarStack>,
    document.body
  );
};

export const SnackbarCard: React.VFC<SnackbarOptions & { isStandBy: boolean }> = ({
  type = DEF_TYPE,
  duration = DEF_DURATION,
  isStandBy,
  persistent,
  messageTranslation,
  messageTranslationParams,
  actionTranslation,
  actionTranslationParams,
  actionProps,
  actionLinkToTranslation,
  actionLinkToTranslationParams,
  actionLinkToPath,
  actionRefresh,
  onActionClick,
  onCloseClick,
}) => {
  const t = useTranslation();
  const Icon = DEF_TYPE_ICONS[type];
  const color = DEF_TYPE_COLORS[type];
  const lightColor = DEF_TYPE_LIGHT_COLORS[type];
  const durationInMilliseconds = DEF_DURATIONS[duration];
  const refreshPage = useCallback(() => window.location.reload(), []);

  return (
    <StyledSnackbarCard $color={useColor(color)} $lightColor={useColor(lightColor)} $showProgress={!persistent}>
      <Box gap_100 alignItems={'center'}>
        <StyledSnackbarIconWrapper backgroundColor={color}>
          <Icon size={12} />
        </StyledSnackbarIconWrapper>

        <Text typography={'Heading3'} weight={'600'}>
          {t(messageTranslation, messageTranslationParams)}
        </Text>
      </Box>
      <Box gap_100 alignItems={'center'}>
        {!!actionTranslation && (
          <Button onClick={onActionClick} tertiary {...actionProps}>
            {t(actionTranslation, actionTranslationParams)}
          </Button>
        )}

        {!!actionLinkToTranslation && !!actionLinkToPath && (
          <NavRouterLink onClick={onActionClick} tertiary to={actionLinkToPath}>
            {t(actionLinkToTranslation, actionLinkToTranslationParams)}
          </NavRouterLink>
        )}

        {actionRefresh && (
          <Button tertiary onClick={refreshPage}>
            {t('snack_refresh')}
          </Button>
        )}

        <Button suppressPadding onClick={onCloseClick}>
          <Close size={16} />
        </Button>
      </Box>

      <StyledSnackbarCardLoader
        $color={useColor(color)}
        $duration={persistent ? undefined : durationInMilliseconds}
        $isStandBy={isStandBy}
        $showProgress={!persistent}
      />
    </StyledSnackbarCard>
  );
};
