import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { Text } from '@components/common/Text';
// vendor:
// nectar:
import { ModalContainer } from '@components/deprecated/Elements/ModalElements';

export const DeleteYardModalView = ({ t, isMobile, contentObj, handleSubmit, handleCancel, modalObj, isLoading }) => {
  const { modalType } = contentObj;

  const { title, instructions, nextButton, backButton } = modalObj;

  return (
    <ModalContainer isMobile={isMobile} smallModal={true} disabled={isLoading}>
      <Box paddingHorizontal_150 paddingTop_200 block>
        <Text typography="Heading2">{t(title)}</Text>
        <Box paddingBottom_150 paddingTop_100>
          {instructions && (
            <Text lineHeight="24px" typography="Paragraph">
              {instructions}
            </Text>
          )}
        </Box>
      </Box>
      <footer>
        <Box alignItems="center" paddingHorizontal_150>
          <Button tertiary withMarginRight type="button" onClick={handleCancel}>
            {t(backButton)}
          </Button>

          <Button primary type="button" onClick={handleSubmit}>
            {t(nextButton)}
          </Button>
        </Box>
      </footer>
    </ModalContainer>
  );
};

DeleteYardModalView.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  contentObj: PropTypes.shape({
    modalType: PropTypes.string.isRequired,
  }).isRequired,
  modalObj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    instructions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    nextButton: PropTypes.string.isRequired,
    backButton: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};
