import { YardAttributeConfig } from '@config/types';

/**
 * Defines which spec to be applied to a yard based on how
 * many days since it's last inspection.
 * */
export interface LastInspectionIntervalConfig extends YardAttributeConfig {
  /** Unique key for a spec, used in the filter endpoint. */
  key: LastInspectionIntervalKey;

  /** Translation key for the tooltip */
  tooltipLabelKey: string;

  /** Interval this spec applies to.  */
  interval: [number, number];
}

export enum LastInspectionIntervalKey {
  BETWEEN_00_15 = 'between_00_15',
  BETWEEN_16_30 = 'between_16_30',
  BETWEEN_31_90 = 'between_31_90',
  BETWEEN_91_INF = 'between_91_inf',
}
