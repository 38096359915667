import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@components/common/Box';
import { PageHeader, PageHeaderTitle } from '@components/common/PageHeader';
import { RenderWithPermission } from '@components/common/RenderWithPermission';
import { Tab, Tabs } from '@components/common/Tabs';
import APP from '@config/constants';
import { TabPractices } from '@scenes/admin/Operation/TabPractices';
import { TabProfile } from '@scenes/admin/Operation/TabProfile';

import { TabWorkers } from './TabWorkers';

export const Operation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box column stretch>
      <PageHeader>
        <PageHeaderTitle>{t('operation')}</PageHeaderTitle>
      </PageHeader>
      <Tabs>
        <RenderWithPermission require={APP.permissions.hht}>
          <Tab id={'practices'} title={t('practices')}>
            <TabPractices />
          </Tab>
          <Tab id={'workers'} title={t('workers')}>
            <TabWorkers />
          </Tab>
          <Tab id={'profile'} title={t('operation_profile')}>
            <TabProfile />
          </Tab>
        </RenderWithPermission>
      </Tabs>
    </Box>
  );
};
