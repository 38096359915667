import { ENDPOINTS } from '@config/api';
import APP from '@config/constants';
import { Api } from '@helpers/Api';
import { Thunk } from '@helpers/Thunk';
import { URLUtil } from '@helpers/URL';
import { parseApiToContract } from '@redux/Contract/actions';
import { makeShowSnackbarAction } from '@redux/Snackbar/actions';

import { RenewContractAction, RenewContractActionType } from './types';

const makeRenewContractActionStart = (): RenewContractAction => ({
  type: RenewContractActionType.RENEW_CONTRACT_FETCH_START,
});

const makeRenewContractSuccessAction = (): RenewContractAction => ({
  type: RenewContractActionType.RENEW_CONTRACT_FETCH_SUCCESS,
});

const makeRenewContractFailureAction = (): RenewContractAction => ({
  type: RenewContractActionType.RENEW_CONTRACT_FETCH_FAILURE,
});

export const makeRenewContractThunk = Thunk.createTakeFirst((contractId: number, includeRelatedEntities: boolean) => {
  return async (dispatch) => {
    dispatch(makeRenewContractActionStart());
    const response = await Api.post(URLUtil.buildURL(ENDPOINTS.renewContract, { contractId }), {
      include_related_entities: includeRelatedEntities,
    });

    if (response.error) {
      dispatch(makeRenewContractFailureAction());
      dispatch(makeShowSnackbarAction(response.error.snackbarOptions));
      throw response.error;
    } else {
      const data = parseApiToContract(await response.json());
      dispatch(makeRenewContractSuccessAction());
      dispatch(
        makeShowSnackbarAction({
          persistent: true,
          messageTranslation: 'renew_contract_success',
          actionLinkToTranslation: 'view',
          actionLinkToPath: URLUtil.buildPagePath(APP.routes.pollinationContractsDetails, {
            pathParams: { uid: data.id },
          }),
        })
      );
    }
  };
});
