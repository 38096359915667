import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@components/common/Box';
import { Button } from '@components/common/CTA';
import { ArrowLeft } from '@components/common/Icon/presets/ArrowLeft';
import { ResponsiveRender } from '@components/common/ResponsiveRender';
import { Text } from '@components/common/Text';
import { OperationSelector } from '@components/operation/OperationSelector';
import { Globals } from '@helpers/Globals';
import { GlobalsUtil } from '@helpers/Globals/util';
import { useTranslation } from '@hooks/useTranslation';
import { from } from '@style/mediaQueries';

const StyledOperationSelector = styled(OperationSelector)(
  ({ theme }) => css`
    ${from('tablet')} {
      width: 348px;
      height: 264px;
      margin-top: ${theme.spacing._150};

      & > div {
        border: ${theme.colors.borderSecondary} 1px solid;
        border-radius: ${theme.borderRadius._050};
      }
    }
  `
);

export const SelectOperationForm: React.VFC<{ onGoBackClick: () => void }> = ({ onGoBackClick }) => {
  const t = useTranslation();

  useEffect(() => {
    const nextOperationId = GlobalsUtil.getOperationIdFromNextParam();
    nextOperationId && (Globals.operationId = nextOperationId);
  }, []);

  const goBackButton = (
    <Button withLeadingIcon suppressPadding tertiary onClick={onGoBackClick}>
      <ArrowLeft />
      {t('go_back')}
    </Button>
  );

  return (
    <>
      <Box column stretch>
        <Box column stretch gap_050>
          <ResponsiveRender until={'mobile'}>
            <Box marginBottom_200 marginTop_300>
              {goBackButton}
            </Box>
          </ResponsiveRender>

          <Text typography={'Heading1'} weight={'700'}>
            {t('select_operation_title')}
          </Text>
          <Text typography={'SmallParagraph'} dangerouslySetInnerHTML={{ __html: t('select_operation_message') }} />
        </Box>
        <StyledOperationSelector />
      </Box>

      <ResponsiveRender from={'tablet'}>
        <Box alignItems={'flex-end'}>{goBackButton}</Box>
      </ResponsiveRender>
    </>
  );
};
